import { ComponentsOverrides, Theme } from "@mui/material";

interface muiTextFieldProps {
  styleOverrides: ComponentsOverrides["MuiTextField"];
}

export const muiTextField = (theme: Theme): muiTextFieldProps => ({
  styleOverrides: {
    root: {
      "& .MuiInputBase-root": {
        minWidth: 148,
        minHeight: 40,
        fontSize: "13px",
        borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
      },
      "& .MuiInputLabel-root": {
        backgroundColor: "#fff",
      },
      "& .MuiInput-underline:after": {
        borderBottom: `0.125rem solid ${theme.palette.primary?.dark}`,
      },
      "& .MuiInput-underline:before": {
        display: "none",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
        },
        "&:hover fieldset": {
          borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
        },
        "&.Mui-focused fieldset": {
          borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
        },
      },
      input: {
        color: theme.palette.primary.dark,
        "&::placeholder": {
          color: theme.palette.custom?.grey?.main,
        },
      },
    },
  },
});
