/* eslint-disable @typescript-eslint/no-empty-interface */
import { Exercise } from "@packages/store/models/Exercise/Exercise";
export type ColorVariantType = {
  [key in StatusType]: string;
};

export enum DragItemType {
  ANSWER = "ANSWER",
}

export type AnswerColorVariantType = {
  ["font"]: ColorVariantType;
  ["background"]: ColorVariantType;
  ["border"]: ColorVariantType;
};

export interface ExerciseComponentProps {
  exercise: Exercise;
}

export interface SubExerciseComponentProps extends ExerciseComponentProps {}

export type DiffType = Array<{
  added?: boolean;
  removed?: boolean;
  value: string;
}>;

export type StatusType = "default" | "passed" | "wrong" | "current";

export interface ValueWithLabel {
  value: string;
  label: string;
}
