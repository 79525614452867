import { useEffect, useRef, useState } from "react";

import { Box } from "@mui/material";

export interface StickySectionProps {
  children?: JSX.Element;
  top?: number;
}

export const StickySection = ({
  children,
  top = 0,
}: StickySectionProps): JSX.Element => {
  const [contentHeight, setContentHeight] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [currentScroll, setCurrentScroll] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const section = useRef<Element | null>(null);

  useEffect(() => {
    const { current } = section;
    if (!current) {
      return;
    }
    const { top: currentTop, height, width } = current.getBoundingClientRect();
    const isInTop = currentTop <= top;
    setContentHeight(height);
    setContentWidth(width);
    setIsSticky(isInTop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScroll, section, isSticky]);

  useEffect(() => {
    const onScroll = () => {
      setCurrentScroll(window.scrollY);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window, setCurrentScroll]);

  return (
    <Box
      ref={section}
      sx={
        isSticky
          ? {
              height: `${contentHeight}px`,
              width: `${contentWidth}px`,
            }
          : {}
      }
    >
      <Box
        sx={
          isSticky
            ? {
                position: "fixed",
                top: `${top}px`,
                width: `${contentWidth}px`,
                zIndex: 9999,
              }
            : {}
        }
      >
        {children}
      </Box>
    </Box>
  );
};
