import { Box, styled } from "@mui/material";

export const TextWrapper = styled(Box)(({ theme }) => ({
  ...theme.typography.regularText,

  img: {
    width: "100%",
    height: "auto",
  },

  "table, th, td": {
    border: "1px solid #000",
    borderCollapse: "collapse",
  },

  td: {
    border: "1px solid #279cc4",
    verticalAlign: "middle",
    padding: theme.spacing(3),
  },

  "ol, ul": {
    marginTop: 0,
    marginBottom: 10,
  },

  ul: {
    display: "block",
    listStyleType: "disc",
    marginBlockStart: theme.spacing(2),
    marginBlockEnd: theme.spacing(2),
    marginInlineStart: 0,
    marginInlineEnd: 0,
    paddingInlineStart: theme.spacing(4),
  },
}));
