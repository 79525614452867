import { Box, CardMedia, useTheme } from "@mui/material";

import { Icon } from "components/Icon";

import { ModalContainer, ModalBody } from "./styledComponents";

export interface ModalGroupReportProps {
  isOpen: boolean;
  onClose: () => void;
  link: string;
}

export const ModalVideo = ({
  isOpen,
  onClose,
  link,
}: ModalGroupReportProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ModalContainer disableScrollLock={false} open={isOpen} onClose={onClose}>
      <ModalBody>
        <Box display="flex" padding="1rem" justifyContent="flex-end">
          <Box sx={{ cursor: "pointer", display: "flex" }} onClick={onClose}>
            <Icon
              color={theme.palette.custom.grey.dark}
              type="close"
              width={20}
              height={20}
            />
          </Box>
        </Box>
        <CardMedia component="video" image={link} autoPlay controls />
      </ModalBody>
    </ModalContainer>
  );
};
