import React, { ReactNode, useCallback, useContext } from 'react';

import { observer } from 'mobx-react';
import { ErrorSelectionContext } from './context';

interface ErrorSelectionWordProps {
  index: number;
  children?: ReactNode;
}

export const ErrorSelectionWord = observer((props: ErrorSelectionWordProps) => {
	const {
    index,
    children
  } = props;

  const { onSelectStart, onSelectEnd } = useContext(ErrorSelectionContext);

  const handleSelectStart = useCallback(() => {
    onSelectStart?.(index);
  }, [
    onSelectStart,
    index
  ])

  const handleSelectEnd = useCallback(() => {
    onSelectEnd?.(index);
  }, [
    onSelectEnd,
    index
  ])

  return (
    <span
      onMouseDown={handleSelectStart}
      onMouseUp={handleSelectEnd}
    >
      {children}
    </span>
  );
})
