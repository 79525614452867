import { alpha, ComponentsVariants, Theme } from "@mui/material";

import { radius } from "../../radius/radius";

interface muiButtonProps {
  variants: ComponentsVariants["MuiButton"];
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    smallButton: true;
    primary: true;
    secondary: true;
    checkBtn: true;
    iconBtn: true;
    type?: string;
  }
}

export const muiButton = (theme: Theme): muiButtonProps => ({
  variants: [
    {
      props: { variant: "primary" },
      style: {
        ...theme.typography.h4,
        borderRadius: radius.xxs,
        width: "11rem",
        height: "2.5rem",
        fontWeight: 500,
        textTransform: "none",
        lineHeight: "1rem",
        background: theme.palette.primary.main,
        boxShadow: `0 0.0625rem 0.3125rem ${alpha(
          theme.palette.primary.main,
          0.46
        )}`,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
          color: theme.palette.primary.light,
          background: theme.palette.primary.main,
        },
        "&:disabled": {
          background: theme.palette.custom?.grey?.main,
          color: theme.palette.custom?.grey?.contrastText,
          boxShadow: `0 0.0625rem 0.3125rem ${alpha(
            theme.palette.custom?.grey?.dark as string,
            0.46
          )}`,
        },
      },
    },
    {
      props: { variant: "secondary" },
      style: {
        ...theme.typography.h4,
        borderRadius: radius.xxs,
        width: "11rem",
        height: "2.5rem",
        textTransform: "none",
        background: theme.palette.secondary.contrastText,
        color: theme.palette.primary.dark,
        border: `0.0625rem solid ${theme.palette.primary.light}`,
        fontWeight: 500,
        lineHeight: "1rem",
        "&:hover": {
          background: theme.palette.secondary.contrastText,
        },
        ":disabled": {
          background: theme.palette.custom?.grey?.["menu-tile-inactive"],
          color: theme.palette.custom?.grey?.main,
          opacity: "0.5",
          border: `0.0625rem solid ${theme.palette.custom?.grey?.light}`,
        },
      },
    },
    {
      props: { variant: "smallButton" },
      style: {
        borderRadius: radius.xxs,
        textTransform: "none",
        ...theme.typography.h5,
        background: "none",
        border: `0.0625rem solid ${theme.palette.info.contrastText}`,
        height: "2rem",
        color: theme.palette.info.contrastText,
        display: "flex",
        padding: "0.705rem 0",
        justifyContent: "center",
        alignItems: "center",
        width: "8.625rem",
        ":hover": {
          background: "inherit",
        },
        ":active": {
          background: theme.palette.info.main,
        },
      },
    },
    {
      props: { variant: "checkBtn" },
      style: {
        height: "5.5rem",
        width: "5.5rem",
        borderRadius: radius.s,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: `0.25rem 0.5rem 0.875rem ${alpha(
          theme.palette.custom?.primary?.black as string,
          0.3
        )}`,
        rect: {
          stroke: "none",
        },
      },
    },
    {
      props: { variant: "iconBtn" },
      style: {
        padding: 0,
        minWidth: "initial",
        width: "2rem",
        height: "2rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        "&:hover": {
          background: theme.palette.custom?.grey?.light,
        },
      },
    },
    {
      props: { color: "info" },
      style: {
        background: "transparent",
        "&:hover": {
          background: theme.palette.info.main,
          color: theme.palette.info.contrastText,
        },
        path: {
          stroke: theme.palette.primary.main,
        },
      },
    },
    {
      props: { color: "error" },
      style: {
        background: theme.palette.error.main,
        "&:hover": {
          background: theme.palette.error.main,
        },
        path: {
          stroke: theme.palette.primary.contrastText,
        },
        rect: {
          fill: theme.palette.error.main,
        },
      },
    },
    {
      props: { color: "success" },
      style: {
        background: theme.palette.success.main,
        "&:hover": {
          background: theme.palette.success.main,
        },
        path: {
          stroke: theme.palette.primary.contrastText,
        },
        rect: {
          fill: theme.palette.success.main,
        },
      },
    },
  ],
});
