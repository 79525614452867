import { styled } from "@mui/material";

import { StyledPaper } from "components/StyledPaper";

export const PopupCookiesContainer = styled(StyledPaper)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 5,
  padding: "10px 16px",

  [theme.breakpoints.down(480)]: {
    flexDirection: "column",
    padding: theme.spacing(4),
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,

    "& button": {
      marginTop: theme.spacing(4),
    },
  },
}));
