import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { StatusTypography } from "../StatusTypography";
import { observer } from "mobx-react-lite";
import { StatusType } from "../../types";

export interface ExerciseViewProps {
  exercise: Exercise;
  order: number;
  status: StatusType;
  onClick: () => void;
}

export const ExerciseView = observer(
  ({
    exercise,
    status,
    order,
    onClick,
  }: ExerciseViewProps): JSX.Element | null => {
    const { name } = exercise;

    return (
      <StatusTypography variant="regularText" status={status} onClick={onClick}>
        {`${order}. ${name}`}
      </StatusTypography>
    );
  }
);
