import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Name = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.custom?.grey?.dark,
  fontWeight: 600,
  fontSize: "14px",
  lineHeight: "22px",
  marginTop: theme.spacing(0),
  "& svg": {
    cursor: "pointer",
  },
  "& path": {
    fill: theme.palette.custom?.grey?.light,
  },
}));
