/* eslint-disable react/no-unescaped-entities */
import { Theme as ThemeProps, useTheme } from "@mui/material";

import { LogoContainer } from "./styledComponents";
import { Logo as LogoIcon } from "../../assets/icons";

export interface LogoProps {
  color?: string;
  width?: number;
  height?: number;
}

export interface LogoContainerProps extends LogoProps {
  theme: ThemeProps;
}

export const Logo = ({ color, width, height }: LogoProps): JSX.Element => {
  const theme = useTheme();
  return (
    <LogoContainer color={color} width={width} height={height} theme={theme}>
      <LogoIcon />
    </LogoContainer>
  );
};
