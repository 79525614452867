import { startCase } from "lodash";

import { GenerateDistributorReport } from "@packages/store/models/GenerateReport/GenerateReport";

export const formatReports = (reports: GenerateDistributorReport[]) => {
  return reports.map((report) => ({
    id: report.id,
    name: report.name || "-",
    dateFrom: report.dateFrom || "-",
    dateTo: report.dateTo || "-",
    status: report.status ? startCase(report.status) : "-",
    downloadUrl: report.downloadUrl,
  }));
};
