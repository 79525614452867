import { useContext } from "react";

import {
  DragDropContext,
  DragDropContextType,
} from "../context/DragDropContext";

export const useDragDrop = (): DragDropContextType => {
  const context = useContext(DragDropContext);

  if (!context) {
    throw new Error("useTranslateMode was used outside of its Provider");
  }

  return context;
};
