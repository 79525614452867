import {
  types,
  resolveIdentifier,
  Instance,
  SnapshotOut,
} from "mobx-state-tree";

import { ExerciseAnswer, ExerciseAnswerModel } from "./ExerciseAnswer";

export const ExerciseAnswerStoreModel = types
  .model({
    items: types.array(ExerciseAnswerModel),
    sessionItems: types.array(ExerciseAnswerModel),
  })
  .actions((self) => ({
    addItem(item: ExerciseAnswer, session = false) {
      const instance = resolveIdentifier(ExerciseAnswerModel, self, item.id);
      if (instance) {
        return;
      }
      if (session) {
        self.sessionItems.push(item);
        return;
      }
      self.items.push(item);
    },
  }));

type ExerciseAnswerStoreType = Instance<typeof ExerciseAnswerStoreModel>;
type ExerciseAnswerStoreSnapshotType = SnapshotOut<
  typeof ExerciseAnswerStoreModel
>;

export type ExerciseAnswerStore = ExerciseAnswerStoreType;
export type ExerciseAnswerStoreSnapshot = ExerciseAnswerStoreSnapshotType;
