/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { CompanyReportModel, CompanyReportSnapshot } from "./CompanyReport";
import {
  CompanyReportSummaryModel,
  CompanyReportSummarySnapshot,
} from "./CompanyReportSummary";

export const CompanyReportStoreModel = types
  .model("CompanyReportStore")
  .props({
    summary: types.maybeNull(CompanyReportSummaryModel),
    items: types.optional(types.array(CompanyReportModel), []),
  })
  .actions((self) => ({
    addItem: (item: CompanyReportSnapshot | CompanyReportSummarySnapshot) => {
      if ("totalCompanies" in item) {
        self.summary = item;

        return;
      }

      const instance = resolveIdentifier(CompanyReportModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
    clear() {
      self.summary = null;
      self.items.length = 0;
    },
  }))
  .views((self) => ({
    get totalHours(): number {
      return self.items.reduce(
        (acc, cur) => acc + Number(cur?.totalAcademicHours ?? 0),
        0
      );
    },
  }))
  .views((self) => ({
    get averageHourPrice(): number {
      return Number(self.summary?.totalPayment ?? 0) / self.totalHours;
    },
  }));

type CompanyReportStoreType = Instance<typeof CompanyReportStoreModel>;
export type CompanyReportStore = CompanyReportStoreType;
type CompanyReportStoreSnapshotType = SnapshotOut<
  typeof CompanyReportStoreModel
>;
export type CompanyReportStoreSnapshot = CompanyReportStoreSnapshotType;
