import { styled, Box } from "@mui/material";
import { Theme as ThemeProps } from "@mui/material/styles/createTheme";

interface BodyProps {
  theme: ThemeProps;
  noLessons?: boolean;
}

export const Body = styled(Box)<BodyProps>(
  ({ theme, noLessons }: BodyProps) => ({
    background: theme.palette.primary.contrastText,
    width: noLessons ? "33.5rem" : "67rem",
    height: "80%",
    padding: noLessons
      ? "1rem"
      : `1.5rem ${theme.spacing(4)} 7px ${theme.spacing(4)}`,
    borderRadius: "0.5rem",
    backgroundColor: noLessons
      ? theme.palette.custom.grey?.["emenu-tile-active"]
      : "#fff",
    [theme.breakpoints.down("desktop")]: {
      maxWidth: "33.5rem",
      padding: `1rem`,
      backgroundColor: theme.palette.custom.grey?.["emenu-tile-active"],
    },
  })
);
