import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Controller, UseFormReturn } from "react-hook-form";

import { FilterSelect } from "components/shared/FilterSelect";
import { ValueWithLabel } from "types";

import { FilterContainer } from "./styledComponents";
import { PayrollGroupsFilters as IPayrollGroupsFilters } from "../PayrollGroups";

export interface PayrollGroupsFiltersProps {
  methods: UseFormReturn<IPayrollGroupsFilters>;
  statuses: ValueWithLabel[];
}

export const PayrollGroupsFilters = observer(
  ({ methods, statuses }: PayrollGroupsFiltersProps): JSX.Element | null => {
    const { control } = methods;

    return (
      <FilterContainer>
        <Controller
          name="status"
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (e: SelectChangeEvent<unknown>) => {
              const { value } = e.target;
              // On autofill we get a stringified value.
              onChange(typeof value === "string" ? value.split(",") : value);
            };

            return (
              <FilterSelect
                data={statuses}
                value={
                  typeof value === "string" ? (value as any).split(",") : value
                }
                onChange={handleChange}
                multiple
                placeholder="Статус"
              />
            );
          }}
        />
      </FilterContainer>
    );
  }
);
