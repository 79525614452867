import { styled, Button } from "@mui/material";

export const StyledButton = styled(Button)(() => ({
  display: "flex",
  gap: "0.3rem",
  background: "#5783F1",
  color: "white",
  padding: "0.1rem 1rem",
  borderRadius: "0.5rem",
  fontSize: "0.8rem",
  height: "3rem",
  ":hover": {
    background: "#5783F1",
  },
}));
