import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import PasswordStrengthBar from "react-password-strength-bar";

export interface PasswordStrengthProps {
  password?: string;
  prefferedLanguage?: string;
}

export const PasswordStrength = ({
  password = "",
  prefferedLanguage,
}: PasswordStrengthProps): JSX.Element => {
  const { t } = useTranslation();
  const langSettings = { lng: prefferedLanguage };

  return (
    <Box position="absolute" width="100%" top="3.2rem">
      <PasswordStrengthBar
        password={password}
        scoreWords={[
          t("PasswordChangeStrength:VeryWeak", langSettings),
          t("PasswordChangeStrength:Weak", langSettings),
          t("PasswordChangeStrength:Normal", langSettings),
          t("PasswordChangeStrength:Good", langSettings),
          t("PasswordChangeStrength:Strong", langSettings),
        ]}
        shortScoreWord=""
        scoreWordStyle={{ fontSize: "0.8rem", marginTop: "0.05rem" }}
      />
    </Box>
  );
};
