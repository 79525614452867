/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import { Instance, types, cast } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { SectionModel, SectionModelSnapshot } from "./Section";

export const SectionsStoreModel = types
  .model("Unit")
  .props({
    sections: types.optional(types.array(SectionModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    saveSections: (sections: SectionModelSnapshot[]) => {
      self.sections = cast(sections);
    },
  }))
  .actions((self) => ({
    getSections: async (
      courseId: number,
      unitId: number,
      sectionId: number
    ) => {
      const { sections } = await self.environment.api.getSections(
        courseId,
        unitId,
        sectionId
      );

      self.saveSections(sections);
    },
  }));

type SectionsType = Instance<typeof SectionsStoreModel>;
export type Sections = SectionsType;
export const createSectionsDefaultModel = () =>
  types.optional(SectionsStoreModel, {} as SectionsType);
