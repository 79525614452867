/* eslint-disable import/no-extraneous-dependencies */
import { Box, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";

import { DatePicker } from "components/DatePicker";

export type DateRange = [Date | null, Date | null];

interface DateRangePickerProps {
  dateRange: DateRange;
  onChange: (value: DateRange) => void;
  styles?: SxProps;
}

export const DateRangePicker = ({
  dateRange,
  onChange,
  styles,
}: DateRangePickerProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [startDate, endDate] = dateRange;

  const handleStartDateChange = (newDate: Date | null) => {
    const newDateRange: DateRange = [newDate, endDate];
    onChange(newDateRange);
  };

  const handleEndDateChange = (newDate: Date | null) => {
    const newDateRange: DateRange = [startDate, newDate];
    onChange(newDateRange);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 4,
        ...styles,
      }}
    >
      <DatePicker
        label={t("DateRangePicker:StartDate") ?? ""}
        value={startDate}
        onChange={handleStartDateChange}
      />
      <DatePicker
        label={t("DateRangePicker:EndDate") ?? ""}
        value={endDate}
        onChange={handleEndDateChange}
      />
    </Box>
  );
};
