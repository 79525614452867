/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { Api } from "../services/Api";
import { reactotronSetup } from "../services/Reactotron/Reactotron";
import { isDev } from "../utils/basic";

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    this.api = new Api();
  }

  async setup() {
    // allow each service to setup
    await this.api.setup();
    if (isDev) {
      reactotronSetup();
    }
  }

  /**
   * Reactotron is only available in dev.
   */
  // reactotron: typeof ReactotronDev;

  /**
   * Our api.
   */
  api: Api;
}
