import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { observer } from 'mobx-react';

import { ErrorCreateContext, HoverContext } from '../utils/context';
import { isInRange, isMobileDevice } from '../utils/helpers';
import { EMPTY_WORD_INDEX } from '../utils/constants';
import { ErrorSelectionContext, ErrorSelectionContextProps } from './context';

interface ErrorSelectionWrapperProps {
  children?: ReactNode;
}

export const ErrorSelectionWrapper = observer((props: ErrorSelectionWrapperProps) => {
	const { children } = props;

  const containerRef = useRef<HTMLElement>(null);

  const {
    newErrorRange,
    setSelectedError,
    setNewErrorRange,
    setNewErrorType
  } = useContext(ErrorCreateContext);

  const {
    hoveredWordIndex,
    setHoveredError,
    setHoveredWordIndex
  } = useContext(HoverContext);

  const [startSelectionIndex, setStartSelectionIndex] = useState(0);

  const handleOutsideMouseUp = useCallback(() => {
    window.removeEventListener("mouseup", handleOutsideMouseUp);
  }, []);

  const onSelectStart = useCallback((index: number) => {
    setStartSelectionIndex(index);
    window.addEventListener("mouseup", handleOutsideMouseUp);
  }, []);

  const onSelectEnd = useCallback((index: number) => {
    const selection = window.getSelection()

    const isDesktopSelection = selection && selection.toString().length > 0;
    const isMobileSelection = isMobileDevice() && hoveredWordIndex !== EMPTY_WORD_INDEX;

    if (isDesktopSelection || isMobileSelection) {
      const startPosition = Math.min(startSelectionIndex, index)
      const endPosition = Math.max(startSelectionIndex, index)
      setSelectedError?.(undefined);
      setNewErrorRange?.({ startPosition, endPosition });
      setNewErrorType?.(undefined);
    } else if (newErrorRange && !isInRange(newErrorRange, index)) {
      setNewErrorRange?.(undefined);
    }
    
    selection?.removeAllRanges();
    setStartSelectionIndex(0);
  }, [
    hoveredWordIndex,
    startSelectionIndex,
    newErrorRange,
    setSelectedError,
    setStartSelectionIndex,
    setNewErrorType,
    setNewErrorRange
  ]);

  const selectionContextValue: ErrorSelectionContextProps = useMemo(() => ({
    onSelectStart, onSelectEnd
  }), [onSelectStart, onSelectEnd])

  useEffect(() => {
    if (!newErrorRange) {
      return;
    }

    const mousedownListener = (event: MouseEvent) => {
      if (containerRef.current?.contains(event.target as HTMLElement)) {
        return
      }
      setHoveredError?.(undefined);
      setHoveredWordIndex?.(EMPTY_WORD_INDEX);
      setNewErrorRange?.(undefined);
    }

    window.addEventListener('mousedown', mousedownListener);

    return () => window.removeEventListener('mousedown', mousedownListener);
  }, [
    newErrorRange,
    setHoveredError,
    setHoveredWordIndex,
    setNewErrorRange
  ])

  return (
    <ErrorSelectionContext.Provider value={selectionContextValue}>
      <span ref={containerRef}>
        {children}
      </span>
    </ErrorSelectionContext.Provider>
  );
})
