import { useState, useRef, CSSProperties, useCallback, useEffect } from "react";

export function useDropdown<T extends HTMLElement>() {
  const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef<T | null>(null);
	const [styles, setStyles] = useState<CSSProperties>({});

  const recalculateStyles = useCallback(() => {
		const {
			bottom = 0,
			left = 0,
			width = 0
		} = containerRef.current?.getBoundingClientRect() ?? {}
		setStyles({
      position: 'fixed',
			top: `${bottom}px`,
			left: `${left}px`,
			width: `${width}px`
		});
	}, [
		containerRef,
		setStyles
	]);

	const handleKeyup = useCallback((event: KeyboardEvent) => {
		const { code } = event;
		if (code !== 'Escape') {
			return;
		}
		setIsOpen(false);
	}, [
		setIsOpen
	]);

	const toggle = useCallback(() => {
		setIsOpen(prev => !prev);
		recalculateStyles();
	}, [
		setIsOpen,
		recalculateStyles
	]);

	const handleWindowScroll = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	const handleOutsideClick = useCallback((event: MouseEvent) => {
		const { target } = event;
		if (containerRef.current?.contains(target as Node)) {
			return;
		}
		setIsOpen(false);
	}, [
		containerRef,
		setIsOpen
	]);

  const open = useCallback(() => {
    recalculateStyles();
    setIsOpen(true);
  }, [
    recalculateStyles,
    setIsOpen
  ]);

	const close = useCallback(() => {
		setIsOpen(false);
	}, [setIsOpen]);

	useEffect(() => {
		window.addEventListener('keyup', handleKeyup);
		window.addEventListener('scroll', handleWindowScroll);
		window.addEventListener('click', handleOutsideClick);
		return () => {
			window.removeEventListener('keyup', handleKeyup);
			window.removeEventListener('scroll', handleWindowScroll);
			window.removeEventListener('click', handleOutsideClick);
		}
	}, [
		handleKeyup,
		handleWindowScroll,
		handleOutsideClick
	]);

  return {
    containerRef,
    isOpen,
    styles,
    open,
    toggle,
    close,
  }
}
