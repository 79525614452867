import { Box } from "@mui/material";
import { Unit } from "@packages/store/models/Unit/UnitModel";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Modal } from "../../shared/Modal";
import { DontShowAgain } from "../components/DontShowAgain";
import { Items } from "../components/Items";
import { Summary } from "../components/Summary";

interface ModalUnitResultProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  unit: Unit;
  onBlockChange: (blockId: string) => () => void;
}

export const ModalUnitResult = observer(
  ({
    isOpen,
    onConfirm,
    onClose,
    unit,
    onBlockChange,
  }: ModalUnitResultProps): JSX.Element | null => {
    const { t } = useTranslation();

    const handleRepeatClick = () => {
      if (!unit.firstNotPassedBlock) return;

      onBlockChange(unit.firstNotPassedBlock.id)();
      onClose();
    };

    const handleContinueClick = () => {
      onConfirm();
      onClose();
    };

    const handleItemClick = (exerciseId: string) => {
      onBlockChange(exerciseId)();
      onClose();
    };

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        type="small"
        title={t("ResultsModals:UnitTitle", { name: unit.nameWithoutUnit })}
        titleColor="primary.main"
        containerStyles={{ paddingBottom: "1.5rem" }}
      >
        <Box display="flex" flexDirection="column" gap="24px">
          <Summary
            passedCount={unit.totalBlocksPassed}
            wrongCount={unit.totalBlocksWrong}
            skippedCount={unit.totalBlocksSkipped}
          />

          <Items
            items={unit.sortedBlocks}
            getItemName={(id) => unit.getBlock(id)?.name ?? ""}
            showRepeat={!unit.isPassed}
            type="unit"
            onRepeatClick={handleRepeatClick}
            onContinueClick={handleContinueClick}
            onItemClick={handleItemClick}
          />

          <DontShowAgain />
        </Box>
      </Modal>
    );
  }
);
