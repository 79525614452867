/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  getRoot,
  flow,
  cast,
} from "mobx-state-tree";

import { RootStore } from "..";
import { CreateDistributorReportData, DistributorReportType } from "../../services/Api";

import {
  GenerateDistributorReportModel,
  GenerateDistributorReportSnapshot,
} from "./GenerateReport";
import { withEnvironment } from "../extensions/withEnvironment";

export const GenerateDistributorReportStoreModel = types
  .model("GenerateDistributorReportStore")
  .props({
    items: types.optional(types.array(GenerateDistributorReportModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: GenerateDistributorReportSnapshot) => {
      const instance = resolveIdentifier(
        GenerateDistributorReportModel,
        self,
        item.id
      );
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
    sendToEmails: flow(function* (ids: string[]) {
      const result = yield self.environment.api.sendDistributorReportMails(ids);
      return result.kind === "ok";
    }),
    removeItems: flow(function* (ids: string[]) {
      const result = yield self.environment.api.removeDistributorReports(ids);
      const isSuccess = result.kind === "ok";
      if (isSuccess) {
        self.items = cast(self.items.filter((item) => !ids.includes(item.id)));
      }
      return isSuccess;
    }),
    checkReports: () => {
      self.items.forEach((item) => {
        if (item.status !== "ready") {
          getRoot<RootStore>(self).getDistributorReport(item.id, true);
        }
      });
    },
  }))
  .views((self) => ({
    get sortedReports() {
      return self.items.slice().sort((prev, next) => {
        const prevDate = prev.createdAt || new Date();
        const nextDate = next.createdAt || new Date();
        return prevDate < nextDate ? 1 : -1;
      });
    },
  }))
  .views((self) => ({
    get lessonReports() {
      const { lesson } = DistributorReportType;
      return self.sortedReports.filter(({ type }) => type === lesson);
    },
  }))
  .actions((self) => ({
    createReport: flow(function* (data: CreateDistributorReportData) {
      const item = yield self.environment.api.createDistributorReport(data);
      if (!item.data) {
        return false;
      }
      self.items = cast([item.data, ...self.items]);
      return true;
    }),
  }));

type GenerateDistributorReportStoreType = Instance<
  typeof GenerateDistributorReportStoreModel
>;
export type GenerateDistributorReportStore = GenerateDistributorReportStoreType;
type CoursesSnapshotType = SnapshotOut<
  typeof GenerateDistributorReportStoreModel
>;
export type GenerateDistributorReportStoreSnapshot = CoursesSnapshotType;
export const createGenerateDistributorReportStoreDefaultModel = () =>
  types.optional(
    GenerateDistributorReportStoreModel,
    {} as GenerateDistributorReportStoreSnapshot
  );
