import { Box, styled } from "@mui/material";

export const IconBox = styled(Box)(() => ({
  display: "flex",
  minWidth: "20px",
  minHeight: "20px",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));
