import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardContainerProps } from "../Card";

export const IconContainerCard = styled(Box)(
  ({ iconColor, theme }: CardContainerProps) => ({
    minWidth: "2.5rem",
    height: "2.5rem",
    background: iconColor,
    borderRadius: "0.375rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "2.5rem",
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down(400)]: {
      marginLeft: 0,
    },
  })
);
