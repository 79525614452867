import { Control, Controller } from "react-hook-form";

import { FilterSelect } from "components/shared/FilterSelect";
import { ModalPayrollGroupFormValues } from "pages/PayrollAccountant/components/ModalPayrollGroup/ModalPayrollGroup";
import { ValueWithLabel } from "types";

import { ModalTeacherLangFormValues } from "../ModalTeacherLang";
import { ModalTeacherRateFormValues } from "../ModalTeacherRate";

type FormValues =
  | ModalTeacherLangFormValues
  | ModalTeacherRateFormValues
  | ModalPayrollGroupFormValues;

export interface ClassTypeSelectProps<T extends FormValues> {
  control: Control<T>;
  data: ValueWithLabel[];
  placeholder: string;
  required: string;
  name: string;
}

export const ClassTypeSelect = <T extends FormValues>({
  control,
  data,
  placeholder,
  required,
  name,
}: ClassTypeSelectProps<T>): JSX.Element | null => {
  return (
    <Controller
      control={control}
      name={name as any}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FilterSelect
          value={value || ""}
          onChange={onChange}
          data={data}
          placeholder={placeholder}
          error={!!error}
          helperText={error?.message || " "}
        />
      )}
    />
  );
};
