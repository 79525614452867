/* eslint-disable @typescript-eslint/no-shadow */
import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { AnswerModel } from "@packages/store/models/ExerciseAnswer/Answer";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun } from "mobx";
import { ensure } from "../helpers/ensure";

export const useMovingExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const { answer, possibleAnswers, passingData, isChecked } = exercise;

      if (!answer) {
        return;
      }

      const { setQuestions } = answer;

      if (passingData.length > 0 && isChecked) {
        const initialState = passingData.map(({ id, state, answers }) =>
          QuestionModel.create({
            id,
            state,
            answers: answers.map(({ id, state, place }) =>
              AnswerModel.create({
                id,
                state,
                place,
                text: ensure(
                  possibleAnswers.find(
                    (possibleAnswer) => possibleAnswer.id === id
                  )
                ).text,
              })
            ),
          })
        );

        setQuestions(initialState);
      }
    })
  );

  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty && questions.length) {
        const initialState = questions.map(({ id, answerPlaces }) =>
          QuestionModel.create({
            id,
            emptyFieldsCount: answerPlaces.length,
          })
        );

        setQuestions(initialState);
      }
    })
  );
};
