import type { Theme } from "@mui/material";

type muiTooltipProps = {
  styleOverrides: {
    tooltip: {
      backgroundColor: string;
    };
  };
};

export const muiTooltip = (theme: Theme): muiTooltipProps => ({
  styleOverrides: {
    tooltip: {
      backgroundColor: theme.palette.custom.primary.transparent,
    },
  },
});
