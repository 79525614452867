import { Control, Controller } from "react-hook-form";

import { FilterSelect } from "components/shared/FilterSelect";
import { ValueWithLabel } from "types";

import { ModalTeacherLangFormValues } from "../ModalTeacherLang";

export interface LangSelectProps {
  control: Control<ModalTeacherLangFormValues>;
  data: ValueWithLabel[];
  placeholder: string;
  required: string;
}

export const LangSelect = ({
  control,
  data,
  placeholder,
  required,
}: LangSelectProps): JSX.Element | null => {
  return (
    <Controller
      control={control}
      name="lang"
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <FilterSelect
          placeholder={placeholder}
          data={data}
          value={value || ""}
          onChange={onChange}
          error={!!error}
          helperText={error?.message || " "}
        />
      )}
    />
  );
};
