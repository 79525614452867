import { styled } from "@mui/material";

export const Banner = styled("img")(() => ({
  width: "100%",
  height: 850,
  objectFit: "cover",
  verticalAlign: "top",
  borderTopLeftRadius: "inherit",
  borderBottomLeftRadius: "inherit",
}));
