import { useTranslation } from "react-i18next";

import {
  FooterContainer,
  ContentContainer,
  CopyrightContainer,
  TopContainer,
} from "components/Footer/styledComponents";
import { Logo } from "components/Logo";

export const FooterMobile = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <ContentContainer>
        <TopContainer>
          <Logo width={128} height={32} />
        </TopContainer>

        <CopyrightContainer variant="smallText">
          {t(`Footer:Copyright`)}
        </CopyrightContainer>
      </ContentContainer>
    </FooterContainer>
  );
};
