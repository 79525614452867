import { useEffect, useState } from "react";

import {
  Exercise,
  ExerciseUISubtype,
} from "@packages/store/models/Exercise/Exercise";
import { useTranslation } from "react-i18next";

export const useExerciseHelperText = (exercise: Exercise | undefined) => {
  const { t } = useTranslation();

  const [helperText, setHelperText] = useState<string | null>(null);

  useEffect(() => {
    if (exercise?.isChecked) {
      if (exercise?.uiSubtype === ExerciseUISubtype.RADIO_LINE) {
        const answerOptions =
          exercise.answerData?.questions[0].answers[0].options;
        const { correctOptions } = exercise.correctAnswers[0].answers[0];
        const correctAnswersRestCount = correctOptions.filter(
          (o) => !answerOptions.includes(o)
        ).length;

        if (correctAnswersRestCount > 0 && correctOptions.length > 1) {
          setHelperText(
            // eslint-disable-next-line max-len, prettier/prettier
            `${t(
              "Exercise:RadioLineHelperTextLeft"
            )} ${correctAnswersRestCount} ${t(
              "Exercise:RadioLineHelperTextRight"
            )}`
          );
        }
      } else {
        setHelperText(null);
      }
    } else {
      setHelperText(null);
    }
  }, [
    exercise?.uiSubtype,
    exercise?.isChecked,
    exercise?.answerData?.questions,
    exercise?.correctAnswers,
    t,
  ]);

  return helperText;
};
