import { styled } from "@mui/material/styles";

export const ProgressSteps = styled("div")(({ theme }) => ({
  height: "0.375rem",
  minWidth: "1rem",
  width: "1rem",
  maxWidth: "1rem",
  borderRadius: "0.375rem",
  backgroundColor: theme.palette.custom?.grey?.["emenu-tile-active"],
  marginLeft: theme.spacing(1),
  "&:first-of-type": {
    marginLeft: theme.spacing(0),
  },
}));
