import { styled } from "@mui/material";

export const BlueContainer = styled("table")(({ theme }) => ({
  borderSpacing: 0,
  width: "100%",
  marginBottom: "1.438rem",
  "& table": {
    borderCollapse: "collapse",
  },
  "& thead": {
    background: theme?.gradients?.blue,
  },
  "& thead th": {
    ...theme?.typography?.button,
    cursor: "pointer",
    color: theme?.palette?.primary.contrastText,
    padding: `1.125rem ${theme?.spacing(4) || "1.125rem"}`,
    borderBottom: `0.063rem solid ${theme?.palette?.custom.grey["emenu-tile-active"]}`,
    "&:first-of-type": {
      borderTopLeftRadius: "0.5rem",
    },
    "&:last-of-type": {
      borderTopRightRadius: "0.5rem",
    },
    textAlign: "left",
  },
  "& tbody tr[data-expandable=true]": {
    backgroundColor: theme.palette.custom.grey.accentBlock,
  },
  "& tbody td": {
    cursor: "pointer",
    borderBottom: `0.063rem solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
  },
}));
