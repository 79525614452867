/* eslint-disable no-param-reassign */
import { isNull } from "lodash";
import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { VideoTrackModel } from "../AdditionalContent/VideoTrack";
import { PossibleAnswerModel } from "../Exercise/PossibleAnswer";

import { Answer, AnswerModel } from "./Answer";
import { AnswerPartModel } from "./AnswerPart";

export enum QuestionState {
  PASSED = "passed",
  HAS_ERRORS = "has_errors",
  DEFAULT = "default",
}

export const QuestionModel = types
  .model("Question", {
    id: types.identifier,
    questionString: types.maybeNull(types.string),
    image: types.maybeNull(types.string),
    answerPlaces: types.array(types.number),
    answers: types.array(AnswerModel),
    answerParts: types.array(AnswerPartModel),
    possibleAnswers: types.array(PossibleAnswerModel),
    questionWords: types.array(types.string),
    media: types.array(VideoTrackModel),
  })
  .views((self) => ({
    get isWithImage() {
      return self.image !== null;
    },
    get isWithText() {
      return self.questionString !== null;
    },
    get isWithImageAndText() {
      return self.image !== null && self.questionString !== null;
    },
  }))
  .views((self) => ({
    get text(): string {
      if (isNull(self.questionString)) {
        return "";
      }

      // Иногда с бэка приходит строка типа ...%ANSWER(4)%... поэтому такая проверка
      if (self.questionString.match(/%[a-zA-Z]+\([0-9]+\)%/)) {
        return self.questionString.replace(/%[a-zA-Z]+\([0-9]+\)%/g, "");
      }

      return self.questionString;
    },
    get sortedAnswers(): Answer[] {
      return self.answers
        .slice()
        .sort((a, b) => Number(a.place) - Number(b.place));
    },
    get originalText(): string {
      return self.questionWords.join(" ");
    },
  }));

type QuestionModelType = Instance<typeof QuestionModel>;
type QuestionModelSnapshotType = SnapshotOut<typeof QuestionModel>;

export type Question = QuestionModelType;
export type QuestionSnapshot = QuestionModelSnapshotType;
