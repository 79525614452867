import { styled } from "@mui/material";

export const BottomContainer = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: theme.spacing(7),
  flexWrap: "wrap",
  justifyContent: "space-between",
  "& h5": {
    color: theme.palette.custom?.grey?.main,
  },
}));
