/* eslint-disable consistent-return */

import { useCallback, useEffect, useRef, useState } from "react";

type Interval = null | number | ReturnType<typeof setInterval>;

const DEFAULT_MAX_RECORDER_TIME = 300;

export const useRecorder = (
  maxRecorderTimeInSeconds: number = DEFAULT_MAX_RECORDER_TIME,
  onChangeVolume?: (volume: number) => void
) => {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [chunks, setChunks] = useState<Blob[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingSeconds, setRecordingSeconds] = useState(0);
  const [recordedSeconds, setRecordedSeconds] = useState(0);

  const volumeHandle = useRef(0);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      setMediaRecorder(new MediaRecorder(stream));
      setChunks([]);
      setIsRecording(true);
      setRecordingSeconds(0);
      setRecordedSeconds(0);

      if (onChangeVolume) {
        const context = new AudioContext();
        const analyser = context.createAnalyser();
        const src = context.createMediaStreamSource(stream);
        src.connect(analyser);

        const pcmData = new Float32Array(analyser.fftSize);
        const onFrame = () => {
          analyser.getFloatTimeDomainData(pcmData);

          let sumSquares = 0.0;

          pcmData.forEach((amplitude) => {
            sumSquares += amplitude * amplitude;
          });

          // volumeRef.current = Math.sqrt(sumSquares / pcmData.length);
          onChangeVolume(Math.sqrt(sumSquares / pcmData.length));

          volumeHandle.current = window.requestAnimationFrame(onFrame);
        };
        volumeHandle.current = window.requestAnimationFrame(onFrame);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stopRecording = useCallback(() => {
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
    }

    window.cancelAnimationFrame(volumeHandle.current);

    setIsRecording(false);
    setRecordingSeconds((p) => {
      setRecordedSeconds(p);
      return 0;
    });
  }, [mediaRecorder]);

  useEffect(() => {
    let recordingInterval: Interval = null;

    if (isRecording) {
      recordingInterval = setInterval(() => {
        if (
          recordingSeconds === maxRecorderTimeInSeconds &&
          typeof recordingInterval === "number"
        ) {
          clearInterval(recordingInterval);
          stopRecording();
        }

        setRecordingSeconds((p) => p + 1);
      }, 1000);
    } else if (typeof recordingInterval === "number") {
      clearInterval(recordingInterval);
    }

    return () => {
      if (typeof recordingInterval === "number") {
        clearInterval(recordingInterval);
      }
    };
  });

  useEffect(() => {
    if (!mediaRecorder || mediaRecorder.state !== "inactive") {
      return;
    }

    const newChunks: Blob[] = [];

    mediaRecorder.start();

    mediaRecorder.ondataavailable = (event) => {
      newChunks.push(event.data);
    };

    mediaRecorder.onstop = () => {
      setChunks(newChunks);
    };

    return () => {
      mediaRecorder.stream.getAudioTracks().forEach((track) => track.stop());
    };
  }, [mediaRecorder]);

  // useEffect(() => {
  //   if (!isRecording && chunks.length > 0 && recordedSeconds > 0) {
  //     onStopRecording?.(chunks, recordedSeconds);
  //   }
  // }, [isRecording, chunks, recordedSeconds]);

  return {
    chunks,
    recordingSeconds,
    recordedSeconds,
    isRecording,
    startRecording,
    stopRecording,
  } as const;
};
