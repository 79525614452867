/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { isNull, isUndefined } from "lodash";
import {
  Instance,
  SnapshotOut,
  types,
  cast,
  getSnapshot,
  destroy,
} from "mobx-state-tree";

export enum QuestionState {
  HAS_ERRORS = "hasErrors",
  VERIFIED = "verified",
  PASSED = "passed",
  STARTED = "started",
  NOT_STARTED = "notStarted",
}

import { arrayMoveImmutable } from "../../utils/helpers";

import { Answer, AnswerModel } from "./Answer";

export const QuestionModel = types
  .model("Question", {
    id: types.identifier,
    answers: types.array(AnswerModel),
    emptyFieldsCount: types.maybeNull(types.number),
    state: types.maybeNull(
      types.enumeration<QuestionState>(
        "QuestionState",
        Object.values(QuestionState)
      )
    ),
    text: types.maybeNull(types.string),
    noChanged: types.maybeNull(types.boolean),
    withChangeState: types.optional(types.boolean, false),
  })
  .views((self) => ({
    get firstAnswer(): Answer | undefined {
      return self.answers.slice()[0];
    },
    get isFilled(): boolean {
      if (self.answers.length) {
        return self.answers.every(({ isFilled }) => isFilled);
      }

      if (self.withChangeState) {
        return !isNull(self.noChanged);
      }

      return !isNull(self.noChanged);
    },
    get isChecked(): boolean {
      return (
        self.state === QuestionState.VERIFIED ||
        self.state === QuestionState.HAS_ERRORS ||
        self.state === QuestionState.PASSED
      );
    },
    get isWrong(): boolean {
      return self.state === QuestionState.HAS_ERRORS;
    },
    get isPassed(): boolean {
      return (
        self.state === QuestionState.VERIFIED ||
        self.state === QuestionState.PASSED
      );
    },
    getAnswer(id: string): Answer | undefined {
      return self.answers.find((answer) => answer.id === id);
    },
    getAnswerIndex(id: string): number {
      const answer = self.answers.find((answer) => answer.id === id);
      return answer ? self.answers.indexOf(answer) : -1;
    },
    getAnswerByPlace(place: number): Answer | undefined {
      return self.answers.find((answer) => answer.place === place);
    },
  }))
  .actions((self) => ({
    moveAnswer(from: number, to: number) {
      self.answers = cast(
        arrayMoveImmutable(getSnapshot(self).answers, from, to)
      );
    },
    addAnswer(answer: Answer, index?: number) {
      if (!isUndefined(index)) {
        self.answers.splice(index, 0, answer);
        return;
      }
      self.answers.push(answer);
    },
    removeAnswer(answer: Answer) {
      destroy(answer);
    },
    setText(value: string) {
      self.text = value;
    },
    setNoChanged(value: boolean | null) {
      self.noChanged = value;
    },
  }));

type QuestionModelType = Instance<typeof QuestionModel>;
type QuestionModelSnapshotType = SnapshotOut<typeof QuestionModel>;

export interface Question extends QuestionModelType {}
export type QuestionSnapshot = QuestionModelSnapshotType;
