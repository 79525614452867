import { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { Loading } from "components/Loading";
import { UsefulLink } from "components/UsefulLink";
import { useCancelableFetch } from "hooks";
import { getRandomElementFromArray } from "utils/basic";

import { BlockContainer } from "./styledComponents/BlockContainer";
import {
  ScheduleContainer,
  WrapperMainStudents,
} from "../Student/MainStudents/components/styledComponents";

const colors = ["#470073", "#FF9A03", "#47B347", "#29A3CC"];

export const UsefulLinks = observer((): JSX.Element => {
  const { api, usefulLink } = useStores();
  const { t } = useTranslation();

  const {
    fetch: fetchUsefulLinks,
    loading: loadingUsefulLinks,
    error: usefulLinksError,
  } = useCancelableFetch();

  const renderUsefulLinks = () => {
    return usefulLink.items.map((link) => {
      if (!link.externalUrl) return null;

      const subTitle = link?.groupName
        ? `${t("UsefulLinks:groupName")} ${link?.groupName?.join(", ")}`
        : "";

      return (
        <UsefulLink
          key={link.id}
          date={link.updatedAt}
          title={link.name || ""}
          subTitle={subTitle || ""}
          url={link.externalUrl}
          iconColor={getRandomElementFromArray(colors)}
          newTab
          external
        />
      );
    });
  };

  useEffect(() => {
    fetchUsefulLinks((token) => api.getUsefulLinks(token));
  }, [api, fetchUsefulLinks]);

  return (
    <WrapperMainStudents>
      <BlockContainer>
        <Typography variant="h1" component="span">
          {t(`StartUsefulLinks:title`)}
        </Typography>

        <Loading loading={loadingUsefulLinks} error={usefulLinksError}>
          <ScheduleContainer>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {usefulLink.items.length
                ? renderUsefulLinks()
                : t("UsefulLinks:NoLinks")}
            </Box>
          </ScheduleContainer>
        </Loading>
      </BlockContainer>
    </WrapperMainStudents>
  );
});
