import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { ExerciseResultSpellcheckErrorModel } from "./ExerciseResultSpellcheckError";

export const ExerciseResultPassingModel = types
  .model("ExerciseResultPassing", {
    answer: types.optional(types.string, ""),
    state: types.optional(types.string, ""),
    spellcheckErrors: types.optional(types.array(ExerciseResultSpellcheckErrorModel), []),
  });

type ExerciseResultPassingModelType = Instance<typeof ExerciseResultPassingModel>;
type ExerciseResultPassingModelTypeSnapshotType = SnapshotOut<typeof ExerciseResultPassingModel>;

export interface ExerciseResultPassing extends ExerciseResultPassingModelType {}
export type ExerciseResultPassingSnapshot = ExerciseResultPassingModelTypeSnapshotType;