import { forwardRef } from "react";

import { Tooltip, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { TooltipTitle } from "../TooltipTitle";

export const AdditionalContentResizeHandle = forwardRef(
  (props, ref): JSX.Element | null => {
    const theme = useTheme();

    const { t } = useTranslation();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { handleAxis, ...restProps } = props;
    return (
      <Tooltip
        placement="top"
        followCursor
        title={<TooltipTitle title={t("Exercise:ResizeTooltip")} />}
      >
        <Box
          className={`handle-${handleAxis}`}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={ref}
          {...restProps}
          sx={{
            "&.handle-e": {
              position: "absolute",
              width: 20,
              height: "100%",
              backgroundColor: "custom.grey.light",

              top: 0,
              right: 0,
              borderTopRightRadius: theme.spacing(3),
              borderBottomRightRadius: theme.spacing(3),
              cursor: "ew-resize",

              "&::after, &::before": {
                content: "''",
                position: "absolute",
                width: 6,
                height: 6,
              },
              "&::before": {
                top: "calc(50% - 6px)",
                right: "calc(50% - 2px)",
                transform: "rotate(45deg) translate(100%)",
                borderTop: "2px solid",
                borderRight: "2px solid",
                borderColor: "info.main",
              },
              "&::after": {
                top: "calc(50% - 6px)",
                left: "calc(50% - 2px)",
                transform: "rotate(-45deg) translate(-100%) ",
                borderTop: "2px solid",
                borderLeft: "2px solid",
                borderColor: "info.main",
              },
            },
          }}
        />
      </Tooltip>
    );
  }
);
