/* eslint-disable @typescript-eslint/no-shadow */
import { ChangeEvent, Fragment } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { CorrectAnswer } from "@packages/store/models/CorrectAnswer/CorrectAnswer";
import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { Question } from "@packages/store/models/Question/Question";
import { List } from "../../components/List";
import { observer } from "mobx-react-lite";
import { QuestionOrder } from "../../components/QuestionOrder";
import { lineHeight } from "../../constants/styles";
import { createQuestionContent } from "../../helpers/createQuestionContent";
import { getItemStatus } from "../../helpers/getItemStatus";
import { StatusType } from "../../types";
import { ensure } from "../../helpers/ensure";

import { AnswerView } from "../AnswerView";
import { InputAutoresize } from "../InputAutoresize";

interface WritingQuestionProps {
  question: Question;
  answerData: AnswerData;
  correctAnswer: CorrectAnswer | undefined;
  order?: number;
}

export const WritingQuestion = observer(
  ({
    question,
    answerData,
    correctAnswer,
    order,
  }: WritingQuestionProps): JSX.Element | null => {
    const theme = useTheme();

    const {
      text: questionText,
      image: questionImage,
      sortedAnswers,
    } = question;

    const { getAnswer } = answerData;

    const renderElement = (index: number): JSX.Element | null => {
      const { id } = sortedAnswers[index];

      const { text: answerText, setText, isChecked } = ensure(getAnswer(id));

      const correctOptions =
        correctAnswer
          ?.getAnswer(id)
          ?.correctOptions.map((option) => String(option).toLowerCase()) || [];

      const getAnswerStatus = (): StatusType => {
        if (isChecked) {
          return correctOptions.includes(String(answerText).toLowerCase())
            ? "passed"
            : "wrong";
        }
        return "default";
      };

      const handleChange = (event: ChangeEvent<HTMLSpanElement>) => {
        const { textContent } = event.currentTarget;
        setText(textContent || "");
      };

      if (isChecked) {
        return (
          <AnswerView title={String(answerText)} status={getAnswerStatus()} />
        );
      }

      return (
        <InputAutoresize value={answerText || ""} onChange={handleChange} />
      );
    };

    const content = createQuestionContent(
      questionText,
      sortedAnswers.length,
      (index) => Number(sortedAnswers[index].place),
      renderElement
    );

    return (
      <QuestionOrder order={order} status={getItemStatus(answerData)}>
        <>
          {questionImage && (
            <Box
              component="img"
              src={questionImage}
              alt="question"
              sx={{ display: "block", height: "130px", mb: "0.3rem" }}
            />
          )}
        </>

        <List
          items={content}
          renderItem={({ text, element }, index) => (
            <Fragment key={index}>
              {text && (
                <Typography
                  variant="regularText"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}{" "}
              {element}{" "}
            </Fragment>
          )}
          containerStyles={{
            ...theme.typography.regularText,
            display: "inline",
            lineHeight,
          }}
        />
      </QuestionOrder>
    );
  }
);
