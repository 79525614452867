import { Box, styled } from "@mui/material";

export const MyWordsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "3rem",
  rowGap: "1rem",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
  },
}));
