/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  getRoot,
} from "mobx-state-tree";

import { Lesson, LessonModel, LessonSnapshot } from "./LessonModel";
import { RootStore } from "../RootStore/RootStore";

/**
 * Model description here for TypeScript hints.
 */
export const LessonStoreModel = types
  .model("LessonStore")
  .props({
    items: types.optional(types.array(LessonModel), []),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value;
    },
  }))
  .views((self) => ({
    get teacherLessons(): Lesson[] {
      const {
        auth: { user },
      } = getRoot<RootStore>(self);

      return self.items.filter((item) => item.teacherId === user?.id);
    },
  }))
  .views((self) => ({
    get sort() {
      return self.items
        .slice()
        .sort(
          (a, b) =>
            (b?.startAtLocal?.getTime() || 0) -
            (a?.startAtLocal?.getTime() || 0)
        );
    },
  }))
  .views((self) => ({
    get sortForMainPage() {
      const sortedLesson = self.sort;
      // find index of first lesson that already passed
      const index = sortedLesson.findIndex(
        (lesson) => (lesson?.startAtLocal?.getTime() || Infinity) < Date.now()
      );
      // split array into two parts depending on index
      const firstPart = sortedLesson.slice(0, index);
      const mainPart = sortedLesson.slice(index);
      // take last element from firstPart and put in the beginning of secondPart
      const lastLesson = firstPart.pop();
      if (lastLesson) {
        mainPart.unshift(lastLesson);
      }
      return mainPart;
    },
  }))
  .actions((self) => ({
    addItem: (item: LessonSnapshot) => {
      const instance = resolveIdentifier(LessonModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .actions((self) => ({
    processLessons: async (ids: string[]) => {
      const rootStore = getRoot<RootStore>(self);
      self.setLoading(true);
      await rootStore.processLessons(ids, true);
      const { items } = self;
      const lessons = ids
        .map((id) => {
          return items.find((lesson) => lesson.id === id);
        })
        .filter(Boolean) as Lesson[];
      lessons.forEach((lesson) => lesson.markProcessed());
      self.setLoading(false);
      rootStore.addSuccess("Lessons were successfully processed.");
    },
  }));
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type LessonStoreType = Instance<typeof LessonStoreModel>;
export type LessonStore = LessonStoreType;
type LessonStoreSnapshotType = SnapshotOut<typeof LessonStoreModel>;
export type LessonStoreSnapshot = LessonStoreSnapshotType;
