/* eslint-disable @typescript-eslint/no-empty-interface */
import { uniqBy } from "lodash";
import {
  types,
  Instance,
  SnapshotOut,
  getSnapshot,
  IAnyModelType,
  getRoot,
} from "mobx-state-tree";

import { RootStore } from "..";
import { ClassGroup } from "../ClassGroup/ClassGroup";
import { Lesson, LessonModel } from "../Lesson/LessonModel";
import { Teacher } from "../Teacher/Teacher";

export const ClassScheduleModel = types
  .model({
    id: types.identifier,
    creativeExerciseCheck: types.maybeNull(
      types.union(types.string, types.boolean)
    ),
    duration: types.maybeNull(types.number),
    startTime: types.maybeNull(types.string),
    teachers: types.optional(types.array(types.string), []),
    webinarRoomLink: types.maybeNull(types.string),
    weekDays: types.optional(types.array(types.string), []),
    lessons: types.optional(
      types.array(
        types.reference(types.late((): IAnyModelType => LessonModel))
      ),
      []
    ),
  })
  .views((self) => ({
    get actualTeachers() {
      const teachers = getRoot<RootStore>(self).teacher.items as Teacher[];
      return teachers.filter(({ actualId }) =>
        self.teachers.includes(actualId)
      );
    },
    get lessonCount() {
      return self.lessons.length;
    },
    get classGroup(): ClassGroup | undefined {
      return getRoot<RootStore>(self).classGroup.items.find(
        (classGroup: ClassGroup) =>
          getSnapshot(classGroup).classSchedules.includes(self.id)
      );
    },
    get durationInMinutes() {
      return Number(self.duration) / 60;
    },
  }))
  .views((self) => ({
    get actualTeachers() {
      return self.teachers;
    },
    get students() {
      const students = self.lessons.flatMap(({ students }) => students);
      return uniqBy(students, ({ id }) => id);
    },
    get lessonsGroupByDate(): Array<[number, Lesson[]]> {
      const lessonsByDate: Map<number, Lesson[]> = new Map();
      self.lessons.forEach((lesson) => {
        const date: number = lesson.day.getTime();
        if (date === 0) {
          return;
        }
        const lessons = lessonsByDate.get(date);
        if (!lessons) {
          lessonsByDate.set(date, [lesson]);
        } else {
          lessons.push(lesson);
        }
      });
      const array = Array.from(lessonsByDate);
      return array;
    },
  }));

type ClassScheduleModelType = Instance<typeof ClassScheduleModel>;
export interface ClassSchedule extends ClassScheduleModelType {}
type ClassScheduleSnapshotType = SnapshotOut<typeof ClassScheduleModel>;
export type ClassScheduleSnapshot = ClassScheduleSnapshotType;
