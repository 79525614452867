import { styled, Typography } from "@mui/material";

interface SectionItemProps {
  active?: boolean;
}

export const SectionItem = styled(Typography)(
  ({ active = false }: SectionItemProps) => ({
    textTransform: "uppercase",
    color: active ? "#5783F1" : "#747474",
    borderBottomColor: active ? "#5783F1" : "#D7D7D7",
    padding: "0.8rem 1rem",
    paddingBottom: active ? "0.7rem" : "1rem",
    borderBottomStyle: "solid",
    borderBottomWidth: active ? "0.3rem" : "0.15rem",
    fontWeight: "600",
    fontSize: "0.8rem",
    cursor: "pointer",
    flexShrink: "0",
  })
);
