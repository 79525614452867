import { styled, Button } from "@mui/material";

export const DownloadButton = styled(Button)(() => ({
  background: "#5783F1",
  color: "white",
  padding: "0.8rem 1.5rem",
  borderRadius: "0.5rem",
  fontSize: "0.8rem",
  ":hover": {
    background: "#5783F1",
  },
}));
