import { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { useStores } from "@packages/store/models";

import { Loading } from "components/Loading";

export const Student = observer((): JSX.Element => {
  const store = useStores();

  useEffect(() => {
    store.getInitialStudentState();
  }, [store]);

  return (
    <Loading loading={store.loading}>
      <Outlet />
    </Loading>
  );
});
