import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { ClassGroup, ClassGroupModel } from "./ClassGroup";
/**
 * Model description here for TypeScript hints.
 */
export const ClassGroupStoreModel = types
  .model("ClassGroupStore")
  .props({
    items: types.optional(types.array(ClassGroupModel), []),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    addItem: (item: ClassGroup) => {
      if (resolveIdentifier(ClassGroupModel, self, item.id)) return;
      self.items.push(item);
    },
  }));
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ClassGroupStoreType = Instance<typeof ClassGroupStoreModel>;
export type ClassGroupStore = ClassGroupStoreType;
type ClassGroupStoreSnapshotType = SnapshotOut<typeof ClassGroupStoreModel>;
export type ClassGroupStoreSnapshot = ClassGroupStoreSnapshotType;
