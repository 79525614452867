import { ExerciseResultError, ExerciseErrorType } from "@packages/store/models/ExerciseResult/ExerciseResultError";

const { LEXICAL, GRAMMAR, COMMENT } = ExerciseErrorType;

export function getLexicalErrors(errors: ExerciseResultError[]): ExerciseResultError[] {
  return errors.filter(({ errorTypeId }) => errorTypeId === LEXICAL);
}

export function getGrammarErrors(errors: ExerciseResultError[]): ExerciseResultError[] {
  return errors.filter(({ errorTypeId }) => errorTypeId === GRAMMAR);
}

export function getComments(errors: ExerciseResultError[]): ExerciseResultError[] {
  return errors.filter(({ errorTypeId }) => errorTypeId === COMMENT);
}
