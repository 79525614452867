import { Box, styled } from "@mui/material";

export const DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  [theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));
