import { createContext } from "react";
import { ExerciseResultError } from "@packages/store/models/ExerciseResult/ExerciseResultError";

export interface HoverContextProps {
  hoveredWordIndex?: number;
  hoveredError?: ExerciseResultError;
  setHoveredWordIndex?: (_: number) => void;
  setHoveredError?: (_: ExerciseResultError | undefined) => void;
}

export const HoverContext = createContext<HoverContextProps>({});