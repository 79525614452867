/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-multi-assign */
import { useEffect, useRef } from "react";

import { SxProps, Theme } from "@mui/material";
import OverlayScrollbars from "overlayscrollbars";

import { StyledScrollbarComponent } from "./styledComponents/StyledScrollbarComponent";

import "overlayscrollbars/css/OverlayScrollbars.css";

export type Scrollbar = OverlayScrollbars;

export interface ScrollbarComponentProps {
  children: JSX.Element | null;
  options?: OverlayScrollbars.Options;
  onReady?: (scrollbar: OverlayScrollbars) => void;
  containerStyles?: SxProps<Theme>;
}

export const ScrollbarComponent = ({
  children,
  options = {},
  onReady,
  containerStyles,
}: ScrollbarComponentProps): JSX.Element | null => {
  const scrollbarElRef = useRef<HTMLDivElement | null>(null);
  const scrollbarRef = useRef<OverlayScrollbars | null>(null);

  useEffect(() => {
    if (!scrollbarRef.current) {
      const scrollbarEl = scrollbarElRef.current;

      if (scrollbarEl) {
        const scrollbar = (scrollbarRef.current = OverlayScrollbars(
          scrollbarEl,
          {
            scrollbars: {
              visibility: "auto",
            },
            callbacks: {
              ...options,
              onOverflowChanged: (eventArgs) => {
                if (eventArgs?.yScrollable) {
                  scrollbarEl.classList.add("overflowed");
                } else {
                  scrollbarEl.classList.remove("overflowed");
                }
              },
            },
          }
        ));
        onReady?.(scrollbar);
      }
    } else {
      const scrollbar = scrollbarRef.current;
      scrollbar.options(options);
    }

    return () => {
      const scrollbar = scrollbarRef.current;
      if (scrollbar) {
        scrollbar.destroy();
        scrollbarRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, scrollbarElRef]);

  return (
    <StyledScrollbarComponent sx={containerStyles}>
      <div ref={scrollbarElRef} className="os-host">
        {children}
      </div>
    </StyledScrollbarComponent>
  );
};
