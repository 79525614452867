import { styled, InputBase, Button, ButtonProps } from "@mui/material";

export const StyledInput = styled(InputBase)(({ theme }) => ({
  maxWidth: 120,

  "& .MuiInputBase-input": {
    ...theme.typography.regularText,
    minHeight: "1.5rem",
    padding: theme.spacing(2, 3),
    border: "1px solid",
    borderRadius: theme.spacing(3),

    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.custom.grey.light,

    "&:focus": {
      borderRadius: theme.spacing(3),
    },
  },
}));

interface ToggleButtonProps extends ButtonProps {
  active: boolean;
  small?: boolean;
}

export const ToggleButton = styled(
  (props: ButtonProps) => <Button fullWidth {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "active" && prop !== "small",
  }
)<ToggleButtonProps>(({ theme, active, small }) => ({
  ...theme.typography.regularText,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(3),
  borderRadius: theme.spacing(3),
  transition: theme.transitions.create(["opacity"]),

  color: theme.palette.info.contrastText,
  backgroundColor: theme.palette.info.main,

  "&:hover": {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
    opacity: 0.8,
  },

  ...(active && {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,

    "&:hover": {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.main,
      opacity: 0.8,
    },
  }),

  ...(small && {
    padding: theme.spacing(0, 3),
  }),
}));
