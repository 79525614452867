/* eslint-disable @typescript-eslint/no-empty-interface */
import { styled, Typography, TypographyProps } from "@mui/material";

export interface OverflowTypographyProps extends TypographyProps {}

export const OverflowTypography = styled(Typography)<OverflowTypographyProps>(
  () => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  })
);
