import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { EnglishLevel } from "./EnglishLevel";
import { englishLevels } from "../../../constants/englishLevels";
import { mobile } from "../../../constants/styles";

interface EnglishLevelsProps {
  cefrLevel: string;
}

export const EnglishLevels = observer(
  ({ cefrLevel }: EnglishLevelsProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(mobile));

    if (isMobile) {
      return <EnglishLevel level={cefrLevel as any} gap />;
    }

    return (
      <Box sx={{ overflowX: "auto" }}>
        <Box
          display="grid"
          gridAutoFlow="column"
          gridAutoColumns="minmax(12rem, 1fr)"
          alignItems="flex-end"
          gap="1.5rem"
          p="4rem 10px 10px"
        >
          {Object.keys(englishLevels).map((level) => (
            <EnglishLevel
              level={level as any}
              key={level}
              isHighlighted={level === cefrLevel}
            />
          ))}

          <Typography
            gridRow={2}
            gridColumn="1 / 7"
            color="custom.grey.dark"
            fontSize="1.5rem"
            fontWeight={600}
            textAlign="center"
          >
            {t("ResultsModals:TestCourseHint")}
          </Typography>
        </Box>
      </Box>
    );
  }
);
