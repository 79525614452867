import { alpha, Box, styled } from "@mui/material";

export const PopupCookiesWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: "50%",
  bottom: 32,
  zIndex: "9999 !important",
  maxWidth: 1280,
  width: "100%",
  padding: theme.spacing(0, 4),
  transform: "translateX(-50%)",

  [theme.breakpoints.down(480)]: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "flex-end",
    padding: 0,
    transform: "translateX(0)",
    backgroundColor: alpha(theme.palette.custom.primary.black, 0.5),
  },
}));
