import { observer } from "mobx-react";
import cn from "classnames";

import styles from "./Text.module.scss";
import { MouseEvent, forwardRef } from "react";

interface TextProps {
  containerClassName?: string;
  children: React.ReactNode;
  alignment?: TextAlignment;
  variant?: TextVariant;
  color?: TextColor;
  onClick?: (_: MouseEvent<HTMLSpanElement>) => void;
}

type TextAlignment = "left" | "center" | "right";

export type TextVariant =
  | "h1-desctop"
  | "h1-mob"
  | "h2-mob"
  | "h3-mob"
  | "text-1-regular"
  | "text-1-bold"
  | "text-1-semibold"
  | "text-1-link"
  | "text-2-regular"
  | "text-2-medium"
  | "text-2-link"
  | "text-2-semibold"
  | "text-3-link"
  | "text-3-regular"
  | "text-3-medium"
  | "text-3-semibold"
  | "text-3-bold"
  | "text-4-semibold"
  | "text-4-bold"
  | "text-4-bold-16"
  | "text-4-link";

export type TextColor =
  | "base-d"
  | "base-w"
  | "base-dl"
  | "accsent-1s"
  | "accsent-2s"
  | "accsent-2wd"
  | "accsent-3s"
  | "accsent-4s"
  | "accsent-4wd"
  | "accsent-wd"
  | "faints-s"
  | "faints-s2-back"
  | "wet-asphalt"
  | "gradient-1"
  | "check-error"
  | "blue"
  | "main-color"
  | "header-bg";

export const Text = observer(
  forwardRef<HTMLSpanElement, TextProps>((props: TextProps, ref) => {
    const { containerClassName, children, alignment, variant, color, onClick } =
      props;
  
    const containerClasses = cn(
      styles.container,
      containerClassName,
      {
        [styles.clickable]: !!onClick,
      },
      alignment && styles[alignment],
      variant && styles[variant],
      color && styles[color]
    );
  
    return (
      <span
        ref={ref}
        className={containerClasses}
        onClick={onClick}
      >
        {children}
      </span>
    );
  })
);
