import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BottomContentUnit = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: theme.spacing(1, 0, 4, 0),
  justifyContent: "space-between",
  padding: theme.spacing(0, 4),
}));
