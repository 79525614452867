import { styled, Button } from "@mui/material";

interface StyledButtonProps {
  colorVariant: "repeat" | "continue";
}

export const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "colorVariant",
})<StyledButtonProps>(({ theme, colorVariant }) => ({
  ...theme?.typography?.button,
  width: "100%",
  padding: "8px 20px",
  borderRadius: "1rem",
  backgroundColor: theme.palette.info.main,
  boxShadow:
    "0px 2px 6px 0px rgba(130, 169, 188, 0.08), 0px 5px 50px 0px rgba(0, 67, 101, 0.08)",
  color: theme.palette.info.contrastText,
  ":hover": {
    backgroundColor: theme.palette.info.main,
  },

  ...(colorVariant === "continue" && {
    backgroundColor: theme.palette.success.main,

    ":hover": {
      backgroundColor: theme.palette.success.main,
    },
  }),
}));
