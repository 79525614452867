import React, { observer } from "mobx-react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import { ExerciseResultStatus } from "@packages/store/models/ExerciseResult/ExerciseResult";
import { useStores } from "@packages/store";

import styles from "./TasksFilters.module.scss";

import { Input } from "../Input";
import { Select } from "../Select";
import { useUser } from "../../hooks";
import { ChangeEvent, useCallback, useMemo } from "react";
import { isStringsSimilar } from "../../utils/helpers";

interface TasksFiltersProps {
  containerClassName?: string;
  name?: string;
  onNameChange?: (_: string) => void;
  status?: ExerciseResultStatus;
  onStatusChange?: (_: ExerciseResultStatus | undefined) => void;
}

export const TasksFilters = observer((props: TasksFiltersProps) => {
  const {
    containerClassName,
    name,
    status,
    onNameChange,
    onStatusChange
  } = props;
  
  const { t } = useTranslation();

  const { exerciseResult } = useStores();

  const { isTeacher } = useUser();
  
  const { items: results } = exerciseResult ?? {};

  const namePlaceholder = isTeacher
    ? t("CreativeTasks:StudentSearch")
    : t("CreativeTasks:TeacherSearch")

  const handleStatusChange = useCallback((status: string | undefined) => {
    onStatusChange?.(status as ExerciseResultStatus);
  }, [onStatusChange]);

  const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onNameChange?.(event.target.value);
  }, [onNameChange])

  const suggestions: string[] = useMemo(() => {
    const duplicates = new Set<string>();
    return results
      .map(({ student, teacher }) => isTeacher ? student : teacher)
      .filter((name) => {
        if (duplicates.has(name ?? "")) {
          return false
        }
        duplicates.add(name ?? "")
        return true
      })
      .filter((currentName) => {
        return isStringsSimilar(currentName ?? "", name);
      })
      .filter(Boolean) as string[];
  }, [isTeacher, name, results, results.length]);

  const statuses = useMemo(() => {
    return [
      {
        label: t("CreativeTasks:AllStatuses"),
        value: undefined
      },
      {
        label: t("CreativeTasks:Checked"),
        value: ExerciseResultStatus.CHECKED
      },
      {
        label: t("CreativeTasks:NewCheck"),
        value: ExerciseResultStatus.NEW_CHECK
      },
      {
        label: t("CreativeTasks:Processing"),
        value: ExerciseResultStatus.PROCESSING
      }
    ]
  }, [t])

  const containerClasses = cn(styles.container, containerClassName);

  return (
    <form className={containerClasses}>
      <Input
        containerClassName={styles.input}
        type="search"
        placeholder={namePlaceholder}
        value={name}
        suggestions={suggestions}
        onValueChange={onNameChange}
        onChange={handleNameChange}
      />
      <Select
        variant="secondary"
        items={statuses}
        value={status}
        onChange={handleStatusChange}
        placeholder={t("CreativeTasks:AllStatuses") ?? ""}
        fullWidth
        required
      />
    </form>
  );
});
