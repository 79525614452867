import { styled } from "@mui/material";

import {
  RadioButton,
  RadioProps,
} from "../../../../../../../components/RadioButton";
import { StatusType } from "../../../../../../../types";

interface StyledRadioButtonProps {
  status: StatusType;
}

export const StyledRadioButton = styled(
  (props: RadioProps) => <RadioButton size="small" {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "status",
  }
)<StyledRadioButtonProps>(({ theme, status }) => ({
  marginRight: theme.spacing(3),
  padding: 0,
  color: `${theme.palette.custom.answerColorVariant.font.default} !important`,

  ...(status !== "default" && {
    color: `${theme.palette.custom.answerColorVariant.border[status]} !important`,
  }),
}));
