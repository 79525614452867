import { useCallback, useEffect, useState } from "react";

import {
  Box,
  Stack,
  Container,
  Snackbar,
  Alert,
  useTheme,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useStores } from "@packages/store/models";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { Menu } from "components/Menu";
import { ModalOfferEntranceTest } from "components/ModalOfferEntranceTest";
import { ModalVideo } from "components/ModalVideo";
import { IUserMenuItem } from "components/UserMenu/UserMenuData";
import {
  routerLinks,
  dataMenuStudents,
  dataMenuTeachers,
  dataMenuHR,
  dataMenuDistributor,
  dataMenuPayrollAccountant,
  promoVideoLink,
} from "constants/constants";
import { useError } from "hooks/useError";
import { useSuccess } from "hooks/useSuccess";
import { ROUTES } from "router/constants";
import { MenuItem } from "types";

export const Dashboard = observer((): JSX.Element => {
  const store = useStores();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, notifications } = store;
  const { user } = auth;
  const { openSnackBar, closeSnackBar } = useError(store);
  const { openSuccessSnackBar, closeSuccessSnackBar } = useSuccess(store);
  const [mainMenu, setMainMenu] = useState<MenuItem[]>([]);
  const [promoVideoOpened, setPromoVideoOpened] = useState<boolean>(false);

  const showPromoVideo = () => {
    setPromoVideoOpened(true);
  };

  const hidePromoVideo = () => {
    setPromoVideoOpened(false);
  };

  const getUserMenuItems = useCallback((): MenuItem[] => {
    if (auth.user?.isTeacher) {
      return dataMenuTeachers;
    }

    if (auth.user?.isHR) {
      return dataMenuHR;
    }

    if (auth.user?.isDistributor || auth.user?.isCoordinator) {
      return dataMenuDistributor;
    }

    if (auth.user?.isPayrollAccountant) {
      return dataMenuPayrollAccountant;
    }

    return dataMenuStudents;
  }, [
    auth.user?.isTeacher,
    auth.user?.isHR,
    auth.user?.isDistributor,
    auth.user?.isCoordinator,
    auth.user?.isPayrollAccountant,
  ]);

  useEffect(() => {
    setMainMenu(getUserMenuItems());
  }, [auth.user?.isTeacher, getUserMenuItems]);

  const menuItems: IUserMenuItem[] = [
    {
      label: "Profile",
      onClick: () => {
        navigate(`${ROUTES.PROFILE}`);
      },
    },
    {
      label: "Log Out",
      onClick: () => {
        auth.logout();
      },
    },
  ];

  const isNotExercisePage = !location.pathname.includes(ROUTES.EXERCISES);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        firstName={user?.firstName}
        lastName={user?.lastName}
        userMenuItems={menuItems}
        mainMenu={mainMenu}
        isTeacher={user?.isTeacher}
        isHR={user?.isHR}
        isDistributor={user?.isDistributor}
        isCoordinator={user?.isCoordinator}
        isPayrollAccountant={user?.isPayrollAccountant}
        notifications={notifications}
        onVideoClick={showPromoVideo}
      />
      <Stack direction="row" sx={{ flex: 1 }}>
        <Menu dataMenu={mainMenu} />
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden",
            //  paddingRight: `env(safe-area-inset-right)`,
            [theme.breakpoints.down("tablet")]: {
              padding: "0 1.25rem",
            },
            [theme.breakpoints.down("mobile")]: {
              padding: "0 0.75rem",
            },
          }}
        >
          <Outlet />
          {isNotExercisePage && <Footer routerLinks={routerLinks} />}
          <ModalVideo
            isOpen={promoVideoOpened}
            link={promoVideoLink}
            onClose={hidePromoVideo}
          />
        </Container>
      </Stack>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: "100%" }}>
          {store.error}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccessSnackBar}
        autoHideDuration={1000}
        onClose={closeSuccessSnackBar}
      >
        <Alert
          onClose={closeSuccessSnackBar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {store.success}
        </Alert>
      </Snackbar>
      <ModalOfferEntranceTest />
    </Box>
  );
});
