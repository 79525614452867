import { Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

import { Container } from "./styledComponents";
import { Avatar } from "../Avatar";

export interface AvatarPersonProps {
  name: string;
  showOriginal?: boolean;
  width?: number | string;
  height?: number | string;
  fontSize?: Variant;
}

// get initials from name
export const getInitials = (name: string): string => {
  const names = name.split(" ").slice(0, 2);
  // leave only 2 first names
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const initials = names.map((name) => name.charAt(0).toUpperCase());
  return initials.join("");
};

const colors = [
  "#9A9FAB",
  "#FD625B",
  "#FFC91B",
  "#FE86A5",
  "#FCA433",
  "#7EE390",
  "#69DAF2",
];

function numberFromText(text: string) {
  const charCodes = text
    .split("")
    .map((char: string) => char.charCodeAt(0)) // => [65, 65]
    .join(""); // => "6565"
  return parseInt(charCodes, 10);
}

export const AvatarPerson = ({
  name,
  showOriginal = false,
  width = "2.5rem",
  height = "2.5rem",
  fontSize = "h3",
}: AvatarPersonProps): JSX.Element => {
  return name ? (
    <Container
      sx={{
        backgroundColor:
          colors[numberFromText(getInitials(name)) % colors.length],
      }}
      width={width}
      height={height}
    >
      <Typography variant={fontSize}>
        {showOriginal ? name : getInitials(name)}
      </Typography>
    </Container>
  ) : (
    <Avatar width={width} height={height} isOutline />
  );
};
