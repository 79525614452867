import { ChangeEvent, useEffect, useState } from "react";

import { Box, useTheme } from "@mui/material";

import { WordCard } from "components/WordCard";

import { ListContainer, MaterialPagination } from "./styledComponents";

export interface Word {
  word: string;
  translation: string;
  learned: boolean;
  translated: boolean;
  id: string;
}

export interface WordsProps {
  words?: Word[];
  perPage?: number;
  onBookmark?: (word: Word) => void;
  onLanguage?: (word: Word) => void;
  onCheck?: (word: Word) => void;
  onTrash?: (word: Word) => void;
  onVoice?: (word: Word) => void;
}

export const WordsList = ({
  words = [],
  perPage = 24,
  onBookmark,
  onLanguage,
  onCheck,
  onTrash,
  onVoice,
}: WordsProps): JSX.Element => {
  const theme = useTheme();

  const [page, setPage] = useState(1);
  const [currentWords, setCurrentWords] = useState(words);

  const pagesCount = Math.ceil(words.length / perPage);

  useEffect(() => {
    const from = (page - 1) * perPage;
    const to = from + perPage;
    setCurrentWords(words.slice(from, to));
  }, [setCurrentWords, page, words, perPage]);

  useEffect(() => {
    setPage(1);
  }, [words.length]);

  const handlePageChange = (_: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleBookmark = (word: Word) => () => {
    onBookmark?.(word);
  };

  const handleLanguage = (word: Word) => () => {
    onLanguage?.(word);
  };

  const handleCheck = (word: Word) => () => {
    onCheck?.(word);
  };

  const handleTrash = (word: Word) => () => {
    onTrash?.(word);
  };

  const handleVoice = (word: Word) => () => {
    onVoice?.(word);
  };

  return (
    <Box width="100%">
      <ListContainer>
        {currentWords.map((word) => (
          <WordCard
            key={word.id}
            word={word.word}
            translation={word.translation}
            learned={word.learned}
            translated={word.translated}
            onClickBookmark={handleBookmark(word)}
            onClickLanguage={handleLanguage(word)}
            onClickCheck={handleCheck(word)}
            onClickTrash={handleTrash(word)}
            onClickVoice={handleVoice(word)}
          />
        ))}
      </ListContainer>
      {pagesCount > 1 && (
        <Box py="1rem" justifyContent="center" display="flex">
          <MaterialPagination
            shape="rounded"
            count={pagesCount}
            page={page}
            siblingCount={2}
            onChange={handlePageChange}
            sx={{
              button: {
                padding: theme.spacing(4),
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};
