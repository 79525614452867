import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import type {
  ColumnDef,
  OnChangeFn,
  ExpandedState,
} from "@tanstack/react-table";

import { Icon } from "components/Icon";
import { Pagination } from "components/Pagination";

import { BlueContainer } from "./styledComponents/BlueContainer";

interface TableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  sorting?: SortingState;
  onSortingChange?: OnChangeFn<SortingState>;
  width?: string;
  pageSize?: number;
  expanded?: ExpandedState;
  onExpandedChange?: OnChangeFn<ExpandedState>;
}

export const Table = <T extends object>(props: TableProps<T>) => {
  const {
    data,
    columns,
    sorting = [],
    onSortingChange,
    pageSize = 10,
    expanded,
    onExpandedChange,
  } = props;
  const [page, setPage] = useState(1);

  const table = useReactTable({
    data,
    columns,
    autoResetPageIndex: false,
    state: {
      sorting,
      expanded,
    },
    initialState: {
      pagination: {
        pageSize,
      },
    },
    onExpandedChange,
    onSortingChange,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row: any) => row.subRows,
  });

  useEffect(() => {
    table.setPageIndex(page - 1);
  }, [table, page]);

  return (
    <Box sx={{ overflowX: "auto" }}>
      <BlueContainer>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.getIsSorted() ? (
                        <Icon
                          width={15}
                          height={15}
                          type={
                            header.column.getIsSorted() === "asc"
                              ? "sortAmountUp"
                              : "sortAmountDown"
                          }
                        />
                      ) : null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} data-expandable={row.getCanExpand()}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </BlueContainer>
      <div>
        {data.length > 0 && (
          <Pagination
            page={page + 1}
            dataLength={data.length}
            pageSize={pageSize}
            onChange={(_, p) => setPage(p)}
          />
        )}
      </div>
    </Box>
  );
};
