import { Theme, styled, Box } from "@mui/material";

type BottomContainerProps = {
  theme: Theme;
  noLessons?: boolean;
};

export const BottomContainer = styled(Box)<BottomContainerProps>(
  ({ theme, noLessons }: BottomContainerProps) => ({
    marginRight: "1.5rem",
    borderRight: noLessons
      ? "none"
      : `0.063rem solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
    width: "52%",
    [theme.breakpoints.down("tablet")]: {
      width: "100%",
    },
  })
);
