import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CalendarCellProps {
  isWeekDay?: boolean;
  isMonth?: boolean;
}

export const CalendarCell = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "isWeekDay" && prop !== "isMonth",
})<CalendarCellProps>(({ theme, isWeekDay = false, isMonth = false }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.custom.grey.light,
  },

  ...(!isWeekDay &&
    !isMonth && {
      ...theme.typography.body2,
      borderRadius: "100%",
      height: "30px",
    }),

  ...(isWeekDay && {
    ...theme.typography.body2,
    alignItems: "flex-end",
    cursor: "default",

    "&:hover": {
      backgroundColor: "transparent",
    },
  }),

  ...(isMonth && {
    ...theme.typography.body1,
    borderRadius: "0.5rem",
  }),
}));
