import { styled, Tab } from "@mui/material";

export const StyledTab = styled(Tab)(({ theme }) => ({
  // ...theme.typography.regularText,
  minWidth: 0,
  textTransform: "uppercase",

  "&:first-of-type": {
    paddingLeft: 0,
  },

  "&.Mui-selected": {
    color: theme.palette.info.dark,
  },
}));
