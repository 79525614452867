/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback } from "react";

import { Select as MUISelect, MenuItem, useTheme } from "@mui/material";
import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { AnswerView } from "../../../../../../components/AnswerView";
import {
  ChoiceQuestion,
  ChoiceSelectProps,
} from "../../../../../../components/ChoiceQuestion";
import { StyledInput } from "../../../../../../components/SharedStyledComponents";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Select = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const theme = useTheme();

    const { answer, questions, getCorrectAnswer } = exercise;

    const { getQuestion } = ensure(answer);

    const renderSelect = useCallback(
      ({
        value,
        onChange,
        options,
        isChecked,
      }: ChoiceSelectProps): JSX.Element | null => {
        const handleChange = (event: { target: { value: string } }) => {
          onChange(event.target.value);
        };

        if (isChecked) {
          const { label, status } = ensure(
            options.find((option) => option.value === value[0])
          );

          return <AnswerView title={String(label)} status={status} />;
        }

        return (
          <MUISelect
            value={value[0] || ""}
            onChange={handleChange}
            input={<StyledInput />}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ ...theme.typography.regularText }}
              >
                {option.label}
              </MenuItem>
            ))}
          </MUISelect>
        );
      },
      [theme]
    );

    return (
      <ObserverFlexList
        gap={4}
        items={questions}
        renderItem={(question: Question, index, array: Question[]) => (
          <ChoiceQuestion
            key={question.id}
            variant="inline"
            question={question}
            answerData={ensure(getQuestion(question.id))}
            correctAnswer={getCorrectAnswer(question.id)}
            renderSelect={renderSelect}
            {...(array.length > 1 && {
              order: index + 1,
            })}
          />
        )}
      />
    );
  }
);
