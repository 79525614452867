import { styled } from "@mui/material";

export const BlockContainer = styled("div")(({ theme }) => ({
  flexBasis: "11.5rem",
  flexGrow: 1,
  marginBottom: theme.spacing(4),
  marginTop: "0.85rem",

  "& h5": {
    alignItems: "center",
    lineHeight: "1.5rem",
  },
}));
