/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-shadow */
import { Fragment } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { CorrectAnswer } from "@packages/store/models/CorrectAnswer/CorrectAnswer";
import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { Question } from "@packages/store/models/Question/Question";
import { FlexList } from "../../components/FlexList";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { lineHeight } from "../../constants/styles";
import { createQuestionContent } from "../../helpers/createQuestionContent";
import { getItemStatus } from "../../helpers/getItemStatus";
import { ValueWithLabel, StatusType } from "../../types";
import { ensure } from "../../helpers/ensure";

import { QuestionOrder } from "../QuestionOrder";

export interface ChoiceOptionType extends ValueWithLabel {
  status: StatusType;
}

export interface ChoiceSelectProps {
  value: string[];
  options: ChoiceOptionType[];
  onChange: (value: string) => void;
  isChecked: boolean;
  multipleAnswers?: boolean | null;
  withTitle?: boolean;
}

interface ChoiceQuestionProps {
  question: Question;
  answerData: AnswerData;
  correctAnswer: CorrectAnswer | undefined;
  renderSelect: (props: ChoiceSelectProps) => JSX.Element | null;
  order?: number;
  variant?: "list" | "inline" | "block";
  multipleAnswers?: boolean | null;
}

export const ChoiceQuestion = observer(
  ({
    question,
    answerData,
    correctAnswer,
    renderSelect,
    order,
    variant = "list",
    multipleAnswers,
  }: ChoiceQuestionProps): JSX.Element | null => {
    const theme = useTheme();

    const { sortedAnswers, text: questionText } = question;

    const { getAnswer } = answerData;

    const renderElement = (index: number): JSX.Element | null => {
      const { id, options } = sortedAnswers[index];

      const {
        addOption,
        toggleOption,
        options: selectedOptions,
        isChecked,
      } = ensure(getAnswer(id));

      const correctOptions =
        correctAnswer?.getAnswer(id)?.correctOptions.map(String) || [];

      const getOptionStatus = (optionValue: string): StatusType => {
        if (selectedOptions.includes(optionValue)) {
          if (isChecked) {
            return correctOptions.includes(optionValue) ? "passed" : "wrong";
          }
          return "current";
        }
        return "default";
      };

      const formattedOptions: ChoiceOptionType[] = options.map(
        ({ id, text }) => ({
          value: id,
          label: String(text),
          status: getOptionStatus(id),
        })
      );

      const Select = renderSelect;

      const handleChange = multipleAnswers ? toggleOption : addOption;

      return (
        <Select
          value={toJS(selectedOptions)}
          options={formattedOptions}
          isChecked={isChecked}
          onChange={handleChange}
          multipleAnswers={multipleAnswers}
          withTitle={Boolean(order)}
        />
      );
    };

    const content = createQuestionContent(
      questionText,
      sortedAnswers.length,
      (index) => Number(sortedAnswers[index].place),
      renderElement
    );

    return (
      <QuestionOrder
        variant={["block", "list"].includes(variant) ? "block" : "inline"}
        order={order}
        status={getItemStatus(answerData)}
      >
        <FlexList
          direction={variant === "block" ? "row" : "column"}
          gap={variant === "list" ? 0 : 3}
          items={content}
          renderItem={({ text, element }, index) => (
            <Fragment key={index}>
              {text && (
                <Typography
                  variant="regularText"
                  sx={{
                    // ...(variant === "list" && {
                    //   lineHeight,
                    // }),
                    img: {
                      width: 200,
                      height: "auto",
                      mr: 3,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}{" "}
              {element}{" "}
            </Fragment>
          )}
          containerStyles={{
            ...(variant === "inline" && {
              ...theme.typography.regularText,
              display: "inline",
              lineHeight,
            }),
          }}
        />
      </QuestionOrder>
    );
  }
);

export const ChoiceRadioLineQuestion = observer(
  ({
    question,
    answerData,
    correctAnswer,
    renderSelect,
    order,
    multipleAnswers,
  }: ChoiceQuestionProps): JSX.Element | null => {
    const { answers, text: questionText } = question;

    const { getAnswer } = answerData;

    const { id, options } = answers[0];

    const {
      addOption,
      toggleOption,
      options: selectedOptions,
      isChecked,
    } = ensure(getAnswer(id));

    const correctOptions =
      correctAnswer?.getAnswer(id)?.correctOptions.map(String) || [];

    const getOptionStatus = (optionValue: string): StatusType => {
      if (selectedOptions.includes(optionValue)) {
        if (isChecked) {
          return correctOptions.includes(optionValue) ? "passed" : "wrong";
        }
        return "current";
      }
      return "default";
    };

    const formattedOptions: ChoiceOptionType[] = options.map(
      ({ id, text }) => ({
        value: id,
        label: String(text),
        status: getOptionStatus(id),
      })
    );

    const Select = renderSelect;

    const handleChange = multipleAnswers ? toggleOption : addOption;

    return (
      <QuestionOrder
        variant="block"
        order={order}
        status={getItemStatus(answerData)}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            variant="regularText"
            sx={{
              img: {
                width: 200,
                height: "auto",
                mr: 3,
              },
            }}
            dangerouslySetInnerHTML={{
              __html: questionText,
            }}
          />
          <Select
            value={toJS(selectedOptions)}
            options={formattedOptions}
            isChecked={isChecked}
            onChange={handleChange}
            multipleAnswers={multipleAnswers}
            withTitle={Boolean(order)}
          />
        </Box>
      </QuestionOrder>
    );
  }
);
