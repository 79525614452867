/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { types, SnapshotOut, Instance } from "mobx-state-tree";

export const UsefulLinkModel = types.model("UsefulLinkModel", {
  id: types.identifier,
  externalUrl: types.maybeNull(types.string),
  groupName: types.maybeNull(types.array(types.string)),
  name: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.Date),
});

type UsefulLinkType = Instance<typeof UsefulLinkModel>;
export interface UsefulLink extends UsefulLinkType {}
type UsefulLinkSnapshotType = SnapshotOut<typeof UsefulLinkModel>;
export type UsefulLinkSnapshot = UsefulLinkSnapshotType;
