import {
  SelectProps as MaterialSelectProps,
  FormControl,
  FormHelperText,
  MenuItem,
  useTheme,
  InputLabel,
  MenuItemProps,
  InputLabelProps,
  Box,
  BoxProps,
} from "@mui/material";
import { Icon } from "components/Icon/Icon";

import { MaterialSelect } from "./styledComponents/MaterialSelect";

export interface InputProps {
  data: Array<{ value: string; label: string }>;
  placeholder: string;
  name?: string;
  width?: number | string;
  labelProps?: InputLabelProps;
  iconProps?: BoxProps;
  menuItems?: MenuItemProps;
  helperText?: string;
}

export type SelectProps = InputProps & MaterialSelectProps;

export const Select = ({
  data,
  placeholder,
  value,
  name,
  onChange,
  width,
  menuItems,
  labelProps,
  iconProps,
  error,
  helperText,
  ...props
}: SelectProps): JSX.Element => {
  const theme = useTheme();

  const icon = (): JSX.Element => (
    <Box
      sx={{
        position: "absolute",
        right: "0",
        top: "12px",
        width: "16px",
        height: "16px",
        pointerEvents: "none",
      }}
      {...iconProps}
    >
      <Icon
        type="chevronDown"
        stroke={theme.palette.custom?.grey?.main}
        width={16}
        height={16}
      />
    </Box>
  );

  return (
    <FormControl variant="standard" sx={{ width }} error={error}>
      <InputLabel {...labelProps}>{placeholder}</InputLabel>

      <MaterialSelect
        name={name}
        IconComponent={icon}
        value={value}
        onChange={onChange}
        {...props}
      >
        {data.map((item) => (
          <MenuItem key={item.value} value={item.value} {...menuItems}>
            {item.label}
          </MenuItem>
        ))}
      </MaterialSelect>

      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
