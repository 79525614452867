import { Box, styled } from "@mui/material";

export const WordContainer = styled(Box)(({ theme }) => ({
  height: "4.5rem",
  boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.11)",
  padding: "0.5rem",
  flex: 1,
  flexBasis: "calc(30% - 1rem)",
  [theme.breakpoints.down("mobile")]: {
    flexBasis: "100%",
  },
}));
