/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance } from "mobx-state-tree";

export const CompanyReportSummaryModel = types
  .model("CompanyReportSummaryModel")
  .props({
    id: types.identifier,
    totalCompanies: types.maybeNull(types.number),
    totalGroups: types.maybeNull(types.number),
    totalPayment: types.maybeNull(types.string),
  });

type CompanyReportSummaryModelType = Instance<typeof CompanyReportSummaryModel>;
export interface CompanyReportSummary extends CompanyReportSummaryModelType {}
type CompanyReportSummarySnapshotType = SnapshotOut<
  typeof CompanyReportSummaryModel
>;
export type CompanyReportSummarySnapshot = CompanyReportSummarySnapshotType;
