import { createContext } from "react";
import { ExerciseResultCorrection } from "@packages/store/models/ExerciseResult/ExerciseResultCorrection";
import { ExerciseResultError } from "@packages/store/models/ExerciseResult/ExerciseResultError";

export interface TaskContextProps {
  hasErrors?: boolean;
  errors?: ExerciseResultError[];
  corrections?: ExerciseResultCorrection[];
  setErrors?: (_: ExerciseResultError[]) => void;
  setCorrections?: (_: ExerciseResultCorrection[]) => void;
}

export const TaskContext = createContext<TaskContextProps>({});