import { format } from "date-fns";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";

import { ContentContainer } from "components/ContentContainer";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";

import { CancellationReportByTeachers } from "./components/CancellationReportByTeachers";
import {
  CancellationReportForm,
  CancellationReportFormValues,
} from "./components/CancellationReportForm";
import { formatReportByTeacher } from "./helpers/formatReportByTeacher";
import { sortReportByTeacher } from "./helpers/sortReportByTeacher";

export const CancellationReport = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { api, cancellationReport } = useStores();

  // const [currentTab, setCurrentTab] = useState(1);

  const {
    loading: isCancellationLoading,
    fetch: fetchCancellation,
    error: cancellationError,
  } = useCancelableFetch();

  const items = computed(() =>
    cancellationReport.items
      .slice()
      .sort(sortReportByTeacher)
      .map(formatReportByTeacher)
  ).get();

  // const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
  //   setCurrentTab(newValue);
  // };

  const handleSubmit = (formData: CancellationReportFormValues) => {
    const { startDate, endDate, companyId, teacherId } = formData;

    fetchCancellation(() =>
      api.getCancellationReport({
        // offset: page * PAGE_SIZE,
        // limit: PAGE_SIZE,
        companyId: companyId === "" ? undefined : companyId,
        teacherId: teacherId === "" ? undefined : teacherId,
        dateFrom: format(startDate as Date, "yyyy-MM-dd"),
        dateTo: format(endDate as Date, "yyyy-MM-dd"),
      })
    );
  };

  return (
    <ContentContainer>
      <PageBlock title={t("CancellationReport:Title")}>
        <CancellationReportForm items={items} onSubmit={handleSubmit} />

        {/* <StyledTabs
          variant="scrollable"
          value={currentTab}
          onChange={handleChangeTab}
        >
          <StyledTab label={t("CancellationReport:TabCompanies")} />
          <StyledTab label={t("CancellationReport:TabTeachers")} />
        </StyledTabs> */}

        <Loading loading={isCancellationLoading} error={cancellationError}>
          {/* <>
            <TabPanel value={currentTab} tabValue={0}>
              {t("CancellationReport:TabCompanies")}
            </TabPanel> */}
          {/* <TabPanel value={currentTab} tabValue={1}> */}
          <CancellationReportByTeachers items={items} />
          {/* </TabPanel> */}
          {/* </> */}
        </Loading>
      </PageBlock>
    </ContentContainer>
  );
});
