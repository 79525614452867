import { useEffect } from "react";

import { Typography, Box } from "@mui/material";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { useStores } from "@packages/store/models";
import { GetLearningGroupsParams } from "@packages/store/services/Api";

import { ContentContainer } from "components/ContentContainer";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";

import { LessonFilter, LessonTable } from "./components";
import {
  filterByCompany,
  filterByGroup,
  filterByStatus,
  filterByDateRange,
  sortByDate,
  filterByTeacher,
  filterByGroupExistence,
} from "./helpers";
import { useLessonFilter } from "./hooks";

const COUNT = 10;

export const Classes = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const {
    lesson: lessonStore,
    auth: authStore,
    loading,
    api,
    // addError,
  } = useStores();

  const { user } = authStore;

  const {
    isTeacher = false,
    isCoordinator = false,
    isDistributor = false,
  } = user ?? {};

  const isDistributorOrCoordinator = isDistributor || isCoordinator;

  // const [groupsPage, setGroupsPage] = useState(1);
  // const [isAllGroupsFetched, setIsAllGroupsFetched] = useState(false);
  // const [canFetchMore, setCanFetchMore] = useState(false);

  const {
    fetch: fetchGroups,
    loading: groupsLoading,
    error: groupsError,
  } = useCancelableFetch();

  const lessonFilter = useLessonFilter();

  const {
    tablePage,
    sampleByStatus,
    onlyLessonsWithoutGroup,
    dateRange,
    company,
    group,
    teacher,
    status,
    setTablePage,
  } = lessonFilter;

  const lessons = isTeacher ? lessonStore.teacherLessons : lessonStore.items;

  const isLessonsLoading = groupsLoading || lessonStore.loading;

  const filteredLessons = computed(() => {
    const result = lessons
      .filter(filterByGroupExistence(!onlyLessonsWithoutGroup))
      .filter(filterByCompany(company))
      .filter(filterByGroup(group))
      .filter(filterByTeacher(teacher))
      .filter(filterByStatus(status))
      .filter(filterByDateRange(dateRange))
      .sort(sortByDate);

    return result;
  }).get();

  const handleChangeTablePage = (page: number) => {
    setTablePage(page);

    // if (isLastPage && !isAllGroupsFetched) {
    //   setGroupsPage((p) => p + 1);
    // }
  };

  useEffect(() => {
    if (sampleByStatus) return;

    const filter: GetLearningGroupsParams = {
      includeLessons: true,
    };

    if (company) filter.company = company;
    if (group) filter.ids = [group];
    if (teacher) filter.teacher = teacher;

    if (!filter.company && !filter.ids && !filter.teacher) return;

    fetchGroups((token) => api.getLearningGroups(filter, token));
  }, [api, company, fetchGroups, group, sampleByStatus, teacher]);

  useEffect(() => {
    if (!sampleByStatus) return;

    const filter: GetLearningGroupsParams = {
      // includeLessons: true,
      // limit: 100,
      // page: groupsPage,
    };

    // if (company) filter.company = company;
    // if (group) filter.ids = [group];
    // if (teacher) filter.teacher = teacher;
    if (dateRange[0]) filter.classGroupsFromDate = dateRange[0];
    if (dateRange[1]) filter.classGroupsToDate = dateRange[1];

    if (!filter.classGroupsFromDate || !filter.classGroupsToDate) return;

    fetchGroups((token) =>
      api.getLearningGroupsByLessonsStatus(
        {
          status,
          fromDate: filter.classGroupsFromDate,
          toDate: filter.classGroupsToDate,
        },
        token
      )
    );

    // fetchGroups((token) => api.getLearningGroups(filter, token)).then(
    //   (value: { learningGroups: LearningGroup[] } | null) => {
    //     if (!value) return value;

    //     const { learningGroups } = value;

    //     if (learningGroups.length === 0) {
    //       setIsAllGroupsFetched(true);
    //     }

    //     setCanFetchMore(true);

    //     return value;
    //   }
    // );
  }, [
    api,
    company,
    dateRange,
    fetchGroups,
    group,
    sampleByStatus,
    status,
    teacher,
  ]);

  // autorun(() => {
  //   if (
  //     filteredLessons.length <= COUNT &&
  //     canFetchMore &&
  //     !isAllGroupsFetched
  //   ) {
  //     setCanFetchMore(false);
  //     setGroupsPage((p) => p + 1);
  //   }
  // });

  const showTable = sampleByStatus
    ? dateRange[0] && dateRange[1]
    : company || group || status || teacher;

  return (
    <Loading loading={loading}>
      <ContentContainer>
        <PageBlock title={t("Classes:Title")}>
          <Box sx={{ mb: 4 }} />

          <LessonFilter
            {...lessonFilter}
            lessonsToDownload={filteredLessons}
            showTeacherFilter={isDistributorOrCoordinator}
          />

          <Loading loading={isLessonsLoading} error={groupsError}>
            {showTable ? (
              <LessonTable
                lessons={filteredLessons}
                count={COUNT}
                page={tablePage}
                onChangePage={handleChangeTablePage}
              />
            ) : (
              <Typography variant="h3">
                {sampleByStatus
                  ? t("Classes:ProcessHelper")
                  : t("Classes:StandardHelper")}
              </Typography>
            )}
          </Loading>

          <Outlet />
        </PageBlock>
      </ContentContainer>
    </Loading>
  );
});
