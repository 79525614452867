import { useCallback } from "react";

import { NavigateOptions, useSearchParams } from "react-router-dom";

export const useQueryParam = (key: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(key) ?? undefined;

  const setValue = useCallback(
    (newValue: string | undefined, options?: NavigateOptions) => {
      const newSearchParams = new URLSearchParams(searchParams);
      if (newValue === undefined) {
        newSearchParams.delete(key);
      } else {
        newSearchParams.set(key, newValue);
      }
      setSearchParams(newSearchParams, options);
    },
    [key, searchParams, setSearchParams]
  );

  return [value, setValue] as const;
};
