import { useState } from "react";

import { Avatar, Box, Typography } from "@mui/material";
import { User } from "@packages/store/models/User";
import { observer } from "mobx-react-lite";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";

import { PersonalInfoContainer } from "./styledComponents/PersonalInfoContainer";
import { FormInput } from "../FormInput";

interface PersonalInfoViewProps {
  user: User;
}

interface FormFields {
  firstName: string;
  lastName: string;
  companyName: string;
}

export const PersonalInfoView = observer(
  ({ user }: PersonalInfoViewProps): JSX.Element | null => {
    const [isFormActive, setIsFormActive] = useState(false);

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<FormFields>({
      defaultValues: {
        firstName: user.firstName || "Not found",
        lastName: user.lastName || "Not found",
        companyName: user.companyName || "Not found",
      },
    });

    const { t } = useTranslation();

    const onSubmit: SubmitHandler<FormFields> = (data) => {
      setIsFormActive(false);
    };

    return (
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center" gap="2rem" mb="2rem">
            <Avatar alt={user?.fullName} sx={{ width: 95, height: 95 }} />
            <Box>
              <Typography
                variant="h2"
                component="p"
                color="custom.grey.dark"
                sx={{ textTransform: "uppercase", mb: 3 }}
              >
                {user?.fullName}
              </Typography>
              <Typography variant="h2" component="p" color="custom.grey.dark">
                {user?.email}
              </Typography>
            </Box>
          </Box>
          <PersonalInfoContainer>
            {/* <Box display="flex" flexDirection="column" gap="1.5rem"> */}
            <FormInput
              label={t("Profile:FirstName")}
              {...register("firstName", { required: true, minLength: 2 })}
              required
              disabled={!isFormActive}
              error={!!errors.firstName}
            />
            <FormInput
              label={t("Profile:LastName")}
              {...register("lastName", { required: true, minLength: 5 })}
              required
              disabled={!isFormActive}
            />
            <FormInput
              label={t("Profile:Company")}
              {...register("companyName", { required: true, minLength: 5 })}
              required
              disabled
            />

            {/* <FormInput
                label={t("Profile:Country")}
                disabled={!isFormActive}
              />
              <FormInput
                label={t("Profile:TimeZone")}
                disabled={!isFormActive}
              /> */}
            {/* </Box>
            <Box display="flex" flexDirection="column" gap="1.5rem">
              <FormInput
                label={t("Profile:LastName")}
                {...register("lastName", { required: true })}
                disabled={!isFormActive}
              /> */}
            {/* <FormInput label={t("Profile:City")} disabled={!isFormActive} />
              <FormInput label={t("Profile:Sector")} disabled={!isFormActive} />
              <FormInput
                label={t("Profile:Position")}
                disabled={!isFormActive}
              /> */}
            {/* </Box> */}
          </PersonalInfoContainer>
          {/* Two buttons because form is not working with conditional button type */}
          <Button
            type="submit"
            variant="primary"
            sx={{ display: isFormActive ? "initial" : "none" }}
          >
            {t("Profile:SaveData")}
          </Button>

          <Button
            type="button"
            variant="primary"
            sx={{ display: !isFormActive ? "initial" : "none" }}
            onClick={() => setIsFormActive(true)}
          >
            {t("Profile:Change")}
          </Button>
        </form>
      </Box>
    );
  }
);
