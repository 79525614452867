import { Box, styled } from "@mui/material";

export const CheckboxesContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  marginBottom: theme.spacing(5),

  "& .MuiCheckbox-root": {
    padding: 0,
    paddingRight: theme.spacing(3),
  },
}));
