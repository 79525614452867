import { styled } from "@mui/material/styles";

export const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  height: "4rem",
  ".MuiTypography-root": {
    border: "none",
    padding: theme.spacing(0),
    fontSize: "0.800rem",
  },
  [theme.breakpoints.down(400)]: {
    flexDirection: "column",
    flexWrap: "nowrap",
    height: "auto",
    gap: "8px",
    marginBottom: "24px",
  },
}));
