import { ButtonBaseProps, ButtonBase, styled } from "@mui/material";

interface StyledButtonProps extends ButtonBaseProps {
  variant?: "check" | "default";
  active?: boolean;
  small?: boolean;
  rotatedSvg?: boolean;
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== "variant" &&
    prop !== "active" &&
    prop !== "small" &&
    prop !== "rotatedSvg",
})<StyledButtonProps>(
  ({ theme, variant = "default", active, small, rotatedSvg }) => ({
    ...theme.typography.regularText,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(3),
    width: "100%",
    minHeight: 32,
    padding: theme.spacing(2, 3),
    color: theme.palette.custom.grey.main,
    borderRadius: theme.spacing(4),
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow:
      "0px 5px 50px rgba(0, 67, 101, 0.08), 0px 2px 6px rgba(130, 169, 188, 0.08)",
    transition: theme.transitions.create(["background-color"]),

    "&:hover": {
      backgroundColor: theme.palette.success.light,
    },

    "&.Mui-disabled": {
      color: theme.palette.custom.grey.main,
      backgroundColor: theme.palette.custom.grey.light,
    },

    ...(variant === "check" && {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.success.main,

      "&:hover": {
        backgroundColor: theme.palette.success.main,
      },
    }),

    ...(active && {
      color: theme.palette.info.main,
    }),

    ...(small && {
      width: "auto",
    }),

    ...(rotatedSvg && {
      svg: {
        transform: "rotate(180deg) translateX(5%)",
      },
    }),
  })
);
