import { styled } from "@mui/material";

export const ModuleTypeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& svg": {
    color: theme.palette.custom.grey.main,
    "& path": {
      stroke: theme.palette.custom.grey.main,
    },
    "& line": {
      stroke: theme.palette.custom.grey.main,
    },
  },
}));
