import { useEffect, useState } from "react";

import { RootStore } from "@packages/store/models";

export const useError = (store: RootStore) => {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    // open snackpar if store error
    if (store.error) {
      setOpenSnackBar(true);
    }
  }, [openSnackBar, store.error]);

  const closeSnackBar = () => {
    // clear errror in store after message show in snackbar
    store.clearError();
    setOpenSnackBar(false);
  };

  return { openSnackBar, closeSnackBar };
};
