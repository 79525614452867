import { ButtonBase, ButtonBaseProps, styled } from "@mui/material";

interface StyledButtonProps extends ButtonBaseProps {
  close?: boolean;
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "close",
})<StyledButtonProps>(({ theme, close }) => ({
  ...theme.typography.regularText,
  width: "100%",
  padding: theme.spacing(2, 3),
  textAlign: "center",
  color: theme.palette.success.contrastText,
  backgroundColor: theme.palette.success.main,
  borderRadius: theme.spacing(4),

  "&.Mui-disabled": {
    backgroundColor: theme.palette.custom.grey.light,
  },

  ...(close && {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  }),
}));
