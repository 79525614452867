import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SelectContent = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  width: "60%",
  gap: theme.spacing(4),
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
    flexWrap: "wrap",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  div: {
    flexGrow: 1,
  },
}));
