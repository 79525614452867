import { FormEvent, useState, useEffect, SyntheticEvent } from "react";

import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { useStores } from "@packages/store/models";
import {
  GetLearningGroupsParams,
  ValueWithLabel,
} from "@packages/store/services/Api";
import { useCancelableFetch } from "hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { getAutocompleteFieldValue } from "utils/helpers";

export interface GroupsAutocompleteFilterProps {
  group?: string | null;
  company?: string | null;
  onChange?: (_: string) => void;
}

export const GroupsAutocompleteFilter = observer(
  ({
    group: groupId,
    company,
    onChange,
  }: GroupsAutocompleteFilterProps): JSX.Element => {
    const { t } = useTranslation();

    const [searchGroup, setSearchGroup] = useState("");
    const [groups, setGroups] = useState<ValueWithLabel[]>([]);

    const { learningGroup, api } = useStores();

    const { items } = learningGroup;

    const { fetch: fetchGroups, loading: loadingGroups } = useCancelableFetch();

    const options = searchGroup ? groups : [];

    const selectedGroup = getAutocompleteFieldValue(groups, groupId);

    const searchInputValue =
      searchGroup.length > 0 ? searchGroup : selectedGroup?.label ?? "";

    const handleChange = (
      _event: SyntheticEvent<Element, Event>,
      item: ValueWithLabel | string | null
    ) => {
      const { value = "" } = (item ?? {}) as ValueWithLabel;
      setSearchGroup("");
      onChange?.(value ?? "");
    };

    const handleSearch = (event: FormEvent<HTMLDivElement>) => {
      const { value } = event.target as HTMLInputElement;
      setSearchGroup(value);
    };

    useEffect(() => {
      if (searchGroup.length < 3) {
        return () => false;
      }
      const timerId = setTimeout(() => {
        const filters: GetLearningGroupsParams = {
          name: searchGroup,
        };
        if (company) {
          filters.company = company;
        }
        fetchGroups((token) => api.getLearningGroups(filters, token));
      }, 300);
      return () => clearTimeout(timerId);
    }, [searchGroup, company, fetchGroups]);

    useEffect(() => {
      const groups = items
        .filter((group) => {
          if (!company) {
            return true;
          }
          return group.customerCompany?.id === company;
        })
        .map((group) => {
          return {
            value: group.id,
            label: group.name,
          } as ValueWithLabel;
        });
      setGroups(groups);
    }, [items, items.length, company]);

    return (
      <Box position="relative">
        <Autocomplete
          id="group"
          freeSolo
          openOnFocus
          onInput={handleSearch}
          onChange={handleChange}
          noOptionsText={t("GroupsAutocompleteFilter:EnterGroupName")}
          options={options}
          inputValue={searchInputValue}
          value={selectedGroup}
          sx={{ minWidth: 320, marginTop: "0.5rem" }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              placeholder={t("GroupsAutocompleteFilter:SelectGroup")}
            />
          )}
        />
        {loadingGroups && (
          <Box
            position="absolute"
            width="0.5rem"
            height="0.5rem"
            sx={{ right: "1rem", top: "calc(50% - 0.5rem)" }}
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
    );
  }
);
