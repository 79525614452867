import { Box, Typography } from "@mui/material";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import { StyledNotificationsHeader } from "./StyledNotificationsHeader";

interface NotificationsHeaderProps {
  title: string;
  unreadCount: number;
  onClose: () => void;
}

export function NotificationsHeader({
  title,
  unreadCount,
  onClose,
}: NotificationsHeaderProps): JSX.Element | null {
  return (
    <StyledNotificationsHeader>
      <Box display="flex" alignItems="center" gap="10px">
        <Typography variant="h3">{title}</Typography>
        {unreadCount > 0 && (
          <Typography variant="h3" color="custom.notification">
            +{unreadCount}
          </Typography>
        )}
      </Box>
      <Button variant="iconBtn" onClick={onClose}>
        <Icon type="close" width={24} height={24} />
      </Button>
      {/* <Typography variant="buttonUnderlined" color="info.main">
        Clear all
      </Typography> */}
    </StyledNotificationsHeader>
  );
}
