import { Box, styled } from "@mui/material";

export const SectionsScrollContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  scrollBehavior: "smooth",
  zIndex: 1,
  "::-webkit-scrollbar": {
    display: "none",
  },
}));
