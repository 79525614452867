import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Question } from "@packages/store/models/Question/Question";
import { observer } from "mobx-react-lite";
import { useDragLayer } from "react-dnd";

import { tablet } from "../../../../../../constants/styles";
import { ensure } from "../../../../../../helpers/ensure";
import { SubExerciseComponentProps } from "../../../../../../types";
import { AnswerDragLayer } from "../../../../../AnswerDragLayer";
import { ObserverFlexList } from "../../../../../FlexList";
import { MovingQuestionGaps } from "../../../../../MovingQuestion";
import { PossibleAnswers } from "../../../../../PossibleAnswers";

export const Gaps = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(tablet));

    const { questions, answer, isChecked } = exercise;

    const { allAnswerIds, insertAnswer, getQuestion } = ensure(answer);

    const { item, isDragging } = useDragLayer((monitor) => ({
      isDragging: monitor.isDragging(),
      item: monitor.getItem(),
    }));

    return (
      <Box
        sx={{
          pt: 1,
          ...(!isTablet
            ? {
                maxHeight: "600px",
                overflowY: "auto",
                position: "relative",
              }
            : {}),
        }}
      >
        <AnswerDragLayer />

        <ObserverFlexList
          gap={4}
          items={questions}
          renderItem={(question: Question, index, array: Question[]) => (
            <Box key={question.id}>
              {!isChecked && (
                <PossibleAnswers
                  allAnswerIds={allAnswerIds}
                  answers={question.possibleAnswers}
                  question={question.id}
                  insertAnswer={insertAnswer}
                />
              )}

              <MovingQuestionGaps
                question={question}
                answerData={ensure(getQuestion(question.id))}
                // Нужно показывать поля для ответов только когда пользователь взял ответ
                needToShow={isDragging && item?.question === question.id}
                {...(array.length > 1 && {
                  order: index + 1,
                })}
              />
            </Box>
          )}
          containerStyles={{ pt: 1 }}
        />
      </Box>
    );
  }
);
