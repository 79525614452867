import { styled, Box } from "@mui/material";

export const ModalBody = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  width: "67rem",
  padding: `${theme.spacing(5)} 2.5rem ${theme.spacing(6)}`,
  borderRadius: "0.5rem",
  [theme.breakpoints.down("desktop")]: {
    maxWidth: "100vw",
  },
}));
