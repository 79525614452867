import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const ContentImageModel = types
  .model({
    id: types.identifier,
    purposes: types.optional(types.array(types.string), []),
    sizeTypes: types.optional(types.array(types.string), []),
    url: types.maybeNull(types.string),
  })
  .views((self) => ({
    get isLongForExercise() {
      return self.sizeTypes[0] === "В длину для упражнения";
    },
    get isForPreview() {
      return self.sizeTypes[0] === "Preview";
    },
  }));

type ContentImageModelType = Instance<typeof ContentImageModel>;
export type ContentImage = ContentImageModelType;
type ContentImageSnapshotType = SnapshotOut<typeof ContentImageModel>;
export type ContentImageSnapshot = ContentImageSnapshotType;
