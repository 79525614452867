import { alpha, Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TagActionPopoverContainer = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "10rem",
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(3),
    border: `0.0625rem solid ${theme.palette.custom?.grey?.["emenu-tile-active"]}`,
    boxShadow: `0.125rem 0.1875rem 0.1875rem ${alpha(
      theme.palette.custom?.primary?.black as string,
      0.08
    )}`,
    borderRadius: "0.5rem",
    marginLeft: "2.4rem",
  },
}));
