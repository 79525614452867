import { Lesson } from "@packages/store/models/Lesson/LessonModel";

export const getPivotStudentAttendance = (
  studentIds: number[],
  lesson: Lesson
) => {
  const attendances = lesson.attendance
    .filter((attendance) => studentIds.includes(attendance.studentId ?? 0))
    .sort((prev, next) => {
      const prevAttended = prev.attended ?? false;
      const nextAttended = next.attended ?? false;
      return prevAttended > nextAttended ? -1 : 1;
    });
  return attendances[0];
};
