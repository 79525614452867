import { styled } from "@mui/material";

export const DateTimeContainer = styled("div")(({ theme }) => ({
  width: "8rem",
  marginRight: theme.spacing(4),
  [theme.breakpoints.down("laptop")]: {
    display: "flex",
    width: "auto",
    marginBottom: "0.75rem",
  },
}));
