import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { withTranslation } from "../extensions/withTranslation";

import { TranslationModel } from "../Translation/TranslationModel";

export const TopicModel = types
  .model({
    id: types.identifier,
    code: types.maybeNull(types.string),
    translationsAttributes: types.optional(types.array(TranslationModel), []),
  })
  .extend(withTranslation);

type TopicModelType = Instance<typeof TopicModel>;
export type Topic = TopicModelType;
type TopicModelTypeSnapshotType = SnapshotOut<typeof TopicModel>;
export type TopicModelSnapshot = TopicModelTypeSnapshotType;
