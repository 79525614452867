import { types, Instance, SnapshotOut, getParent } from "mobx-state-tree";

import { Lesson } from "../Lesson/LessonModel";

export const AttendanceModel = types
  .model({
    studentId: types.maybeNull(types.number),
    attended: types.maybeNull(types.boolean),
    comment: types.maybeNull(types.string),
    firstName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
  })
  .views((self) => ({
    lesson: (): Lesson => {
      return getParent<Lesson>(self, 1);
    },
  }));

type AttendanceModelType = Instance<typeof AttendanceModel>;
export type Attendance = AttendanceModelType;
type AttendanceSnapshotType = SnapshotOut<typeof AttendanceModel>;
export type AttendanceSnapshot = AttendanceSnapshotType;
