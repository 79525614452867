import { Box, Typography } from "@mui/material";
import { ExerciseState } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { JetLink } from "../JetLink";

interface CreativeExercisePlugProps {
  state: ExerciseState;
  checkingResultId: number | null;
}

export const CreativeExercisePlug = observer(
  ({
    state,
    checkingResultId,
  }: CreativeExercisePlugProps): JSX.Element | null => {
    const { t } = useTranslation();

    // Если id нет то ссылка на страницу вообще все заданий
    const linkToChecking = `/creative${
      checkingResultId !== null ? `?taskId=${checkingResultId}` : ""
    }`;

    const renderPassed = (): JSX.Element | null => {
      return (
        <Box sx={{ mt: 3, mb: 4 }}>
          <Typography variant="h3">
            {t("Exercise:CreativeExercisePassed")}.
          </Typography>
        </Box>
      );
    };

    const renderVerified = (): JSX.Element | null => {
      return (
        <div>
          <Typography variant="h3">
            {t("Exercise:CreativeExerciseVerified")}{" "}
            <JetLink to={linkToChecking} newTab external>
              {t("Exercise:CreativeExerciseVerifiedLink")}
            </JetLink>
          </Typography>
        </div>
      );
    };

    const renderError = (): JSX.Element | null => {
      return (
        <div>
          <Typography variant="h3">
            {t("Exercise:CreativeExerciseVerified")}{" "}
            <JetLink to={linkToChecking} newTab external>
              {t("Exercise:CreativeExerciseVerifiedLink")}
            </JetLink>
          </Typography>
        </div>
      );
    };

    const render: { [key in ExerciseState]?: () => JSX.Element | null } = {
      [ExerciseState.VERIFIED]: renderVerified,
      [ExerciseState.HAS_ERRORS]: renderError,
      [ExerciseState.PASSED]: renderPassed,
    };

    return render[state]?.() || null;
  }
);
