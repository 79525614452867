import { Typography } from "@mui/material";

import { Button } from "components/Button";
import { useLocalStorage } from "hooks/useLocalStorage";

import { PopupCookiesWrapper, PopupCookiesContainer } from "./styledComponents";

export function PopupCookies(): JSX.Element | null {
  const [hidden, setHidden] = useLocalStorage("jetclass-cookies-accept", false);

  const handleClick = () => {
    setHidden(true);
  };

  if (hidden) {
    return null;
  }

  return (
    <PopupCookiesWrapper>
      <PopupCookiesContainer>
        <Typography
          variant="regularText"
          sx={{
            flex: 1,
            color: "custom.grey.main",
            lineHeight: "30px",
            textDecoration: "underline",
            textDecorationColor: "custom.grey.main",
          }}
        >
          Для улучшения работы сайта и его взаимодействия с пользователями мы
          используем файлы cookie. Продолжая работу с сайтом, Вы разрешаете
          использование cookie-файлов. Вы всегда можете отключить файлы cookie в
          настройках Вашего браузера.
        </Typography>

        <Button
          variant="primary"
          sx={{
            width: "auto",
          }}
          onClick={handleClick}
        >
          Принять, не показывать больше
        </Button>
      </PopupCookiesContainer>
    </PopupCookiesWrapper>
  );
}
