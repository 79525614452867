import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import { useStores } from "@packages/store/models";
import { SectionList } from "components/SectionList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { ROUTES } from "router/constants";

interface SectionRoute {
  title: string;
  route: string;
}

export const ReportSections = observer((): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [routes, setRoutes] = useState<SectionRoute[]>([]);
  const { auth } = useStores();

  const [active, setActive] = useState(0);

  const baseRoutes = [
    {
      title: t("Report:Statistics"),
      route: ROUTES.STUDENTS_REPORTS,
    },
    {
      title: t("Report:CourseProgress"),
      route: ROUTES.TEACHERS_REPORTS,
    },
    {
      title: t("Report:EntranceTest"),
      route: ROUTES.ENTRANCE_REPORTS,
    },
  ];

  useEffect(() => {
    const { isDistributor = false } = auth.user ?? {};

    const distributorRoutes = [
      {
        title: t("Report:LecenseReport"),
        route: ROUTES.LICENSE_REPORTS,
      },
    ];

    const routes = [...baseRoutes, ...(isDistributor ? distributorRoutes : [])];
    setRoutes(routes);
  }, [t]);

  const displayList = routes.map((link) => link.title);

  const onSelect = (index: number) => {
    const { route } = routes[index];
    navigate(route);
  };

  useEffect(() => {
    setActive(
      routes.findIndex(({ route }) => location.pathname.includes(route))
    );
  }, [location, routes]);

  return (
    <Box mt="2rem">
      <SectionList list={displayList} active={active} onSelect={onSelect} />
    </Box>
  );
});
