/* eslint-disable @typescript-eslint/no-shadow */
import { SyntheticEvent, useEffect } from "react";

import { Autocomplete, Box, SelectChangeEvent, TextField } from "@mui/material";
import { observer } from "mobx-react-lite";

import { CheckboxWord } from "components/Checkbox";
import { Loading } from "components/Loading";
import { ValueWithLabel } from "components/SelectWithChips";

import { CustomSelect, Row } from "./styledComponents";

export interface FilterListSelectProps {
  types: ValueWithLabel[];
  type: string;
  list: ValueWithLabel[];
  listLoading?: boolean;
  selected: string[];
  checkboxLabel: string;
  chipsLabel: string;
  onTypeChange: (_: string) => void;
  onSelectedChange: (_: string[]) => void;
}

export const FilterListSelect = observer(
  ({
    types,
    type,
    list,
    selected,
    checkboxLabel,
    listLoading = false,
    chipsLabel,
    onTypeChange,
    onSelectedChange,
  }: FilterListSelectProps): JSX.Element => {
    const isAllSelected = selected.length === list.length && list.length > 0;

    const checkAllSelect = () => {
      const selected = isAllSelected ? [] : list.map((item) => item.value);
      onSelectedChange(selected);
    };

    const handleTypeChange = (e: SelectChangeEvent<unknown>) => {
      onTypeChange(e.target.value as string);
    };

    const handleSelectedChange = (
      _: SyntheticEvent<Element, Event>,
      values: ValueWithLabel[]
    ) => {
      onSelectedChange(values.map(({ value }) => value));
    };

    useEffect(() => {
      onSelectedChange([]);
    }, [type, onSelectedChange]);

    return (
      <>
        <Row>
          <Box minWidth={200} mb="1rem">
            <CustomSelect
              id="group"
              data={types}
              value={type}
              menuItems={{
                sx: {
                  fontSize: "0.8rem",
                },
              }}
              width="100%"
              onChange={handleTypeChange}
              placeholder=""
            />
          </Box>
          <CheckboxWord
            title={checkboxLabel}
            checked={isAllSelected}
            onChange={checkAllSelect}
          />
        </Row>
        <Loading loading={listLoading}>
          <Autocomplete
            multiple
            onChange={handleSelectedChange}
            options={list}
            value={list.filter(({ value }) => selected.includes(value))}
            sx={{ marginTop: "0.5rem" }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={chipsLabel}
              />
            )}
          />
        </Loading>
      </>
    );
  }
);
