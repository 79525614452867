import { styled, Typography, TypographyProps } from "@mui/material";

import { StatusType } from "../../types";

export interface StatusTypographyProps extends TypographyProps {
  status?: StatusType;
  withoutPointer?: boolean;
}

export const StatusTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "status" && prop !== "withoutPointer",
})<StatusTypographyProps>(({ theme, status = "default", withoutPointer }) => ({
  color: theme.palette.custom.colorVariant[status],
  cursor: withoutPointer ? "text" : "pointer",
}));
