import { styled } from "@mui/material";

import { Select } from "components/Select";

export const CustomSelect = styled(Select)(() => ({
  ".MuiSelect-select": {
    width: "100% !important",
    fontSize: "0.8rem",
  },
  "div:last-child": {
    top: "0.4rem",
  },
}));
