/* eslint-disable no-param-reassign */

import { flow, types } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";

export const HRStatisticsStore = types
  .model("HRStatisticsStore", {
    licensesCount: types.maybeNull(types.number),
    studentsCount: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    getStatistics: flow(function* () {
      const response = yield self.environment.api.getHRStatistics();
      if (response.kind !== "ok") {
        return false;
      }

      const { data } = response;

      self.licensesCount = data.licensesCount;
      self.studentsCount = data.studentsCount;

      return true;
    }),
  }));
