import React, { useCallback, useContext, useMemo } from 'react';

import { observer } from 'mobx-react';

import styles from './ErrorPreivews.module.scss';

import { TaskContext } from '../utils/context';
import { getLexicalErrors, getGrammarErrors, getComments, isVisibleErrors } from '../utils/helpers';
import { ErrorPreivewsItem } from './ErrorPreivewsItem';
import { ExerciseResultError } from '@packages/store/models/ExerciseResult/ExerciseResultError';

export const ErrorPreivews = observer(() => {
	const {
		errors = [],
		corrections = []
	} = useContext(TaskContext);

	const visibleErrors = useMemo(() => {
		return isVisibleErrors(errors, corrections);
	}, [errors, corrections])

	const lexicalErrors = getLexicalErrors(visibleErrors);
	const grammarErrors = getGrammarErrors(visibleErrors);
	const comments = getComments(visibleErrors);

	const getErrorList = useCallback((errors: ExerciseResultError[]) => {
		return errors.map((error, index) => (
			<ErrorPreivewsItem
				key={error.currentId || `${error.comment} ${index}`}
				error={error}
			/>
		))
	}, [])

  return (
    <>
      <div className={styles["items"]}>
        {getErrorList(lexicalErrors)}
      </div>
      <div className={styles["items"]}>
        {getErrorList(grammarErrors)}
      </div>
			<div className={styles["items"]}>
				{getErrorList(comments)}
      </div>
    </>
  );
})
