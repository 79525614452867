import { Box, useTheme } from "@mui/material";
import { Theme as ThemeProps } from "@mui/material/styles/createTheme";

import { CardClassProps } from "components/CardClass";
import { LessonStatusesProps } from "components/LessonStatuses/LessonStatuses";

import {
  CardsContainer,
  HeaderUnitCards,
  IconContainerCard,
  InfoCards,
  MainCardsContent,
  UnitContentCards,
  Unit,
  Exercises,
  WrapUnitExercises,
  Results,
  BottomCardsContent,
} from "./styledComponents";
import { Card } from "../Card";
import { Icon, IconType } from "../Icon";

export type VariantWithLink = {
  variant: CardClassProps["variant"];
  url: string;
};

export interface DataCards {
  dateCard: string;
  timeCard: string;
  status: LessonStatusesProps["type"];
  id: number;
  typeIcon: IconType;
  color: string;
  typeButton: VariantWithLink;
  disabledBtn: boolean;
}

export interface CardsProps {
  unit: string;
  exercisesCount: string;
  resultsTest: number;
  totalProgress: number;
  results?: number;
  iconType: IconType;
  iconColor: string;
  dataCard: DataCards[];
  showTestResults?: boolean;
}

export interface CardsContainerProps {
  iconColor: CardsProps["iconColor"];
  theme: ThemeProps;
}

export const Cards = ({
  unit,
  exercisesCount,
  resultsTest,
  iconColor,
  iconType,
  dataCard,
  showTestResults = true,
}: CardsProps): JSX.Element => {
  const theme = useTheme();

  const itemsCard = dataCard.map((item) => {
    return (
      <Box key={item.id}>
        <Card
          dateCard={item.timeCard}
          timeCard={item.dateCard}
          typeStatus={item.status}
          numberCard={item.id}
          iconType={item.typeIcon}
          iconColor={item.color}
          typeButton={item.typeButton.variant || "default"}
          url={item.typeButton.url || ""}
          disabledButton={item.disabledBtn}
        />
      </Box>
    );
  });

  return (
    <CardsContainer>
      <MainCardsContent>
        <HeaderUnitCards>
          {unit && (
            <UnitContentCards>
              <IconContainerCard iconColor={iconColor} theme={theme}>
                <Icon type={iconType} width={20} height={25} />
              </IconContainerCard>

              <InfoCards>
                <WrapUnitExercises>
                  <Unit variant="h3">
                    {unit}, <Exercises>{exercisesCount}</Exercises>
                  </Unit>
                </WrapUnitExercises>
                {showTestResults && (
                  <Results variant="h4">Test results {resultsTest}%</Results>
                )}
              </InfoCards>
            </UnitContentCards>
          )}
        </HeaderUnitCards>
      </MainCardsContent>

      {itemsCard.length > 0 && (
        <BottomCardsContent>{itemsCard}</BottomCardsContent>
      )}
    </CardsContainer>
  );
};
