import { Box, Typography } from "@mui/material";
import {
  AnswerModel,
  RecordType,
} from "@packages/store/models/ExerciseAnswer/Answer";
import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { Question } from "@packages/store/models/Question/Question";
import { Loading } from "../../components/Loading";
import { useFetch } from "../../hooks/useFetch";
import { observer } from "mobx-react-lite";
import { Audio } from "../../components/Audio";
import { AudioRecorder } from "../../components/AudioRecorder";
import { QuestionOrder } from "../../components/QuestionOrder";
import { getFormDataFromAudio } from "../../helpers/getFormDataFromAudio";
import { getItemStatus } from "../../helpers/getItemStatus";
import { SaveRecoding } from "@packages/store/services/Api/ApiTypes";

interface SpeakingQuestionProps {
  question: Question;
  answerData: AnswerData;
  saveRecordingRequest: (formData: FormData) => Promise<SaveRecoding>;
  order?: number;
}

export const SpeakingQuestion = observer(
  ({
    question,
    answerData,
    saveRecordingRequest,
    order,
  }: SpeakingQuestionProps): JSX.Element | null => {
    const { text: questionText } = question;

    const { firstAnswer, addAnswer, removeAnswer } = answerData;

    const { fetch: saveRecording, loading: saveRecordingLoading } = useFetch(
      saveRecordingRequest,
      (data) => {
        const answer = AnswerModel.create({
          // Вообще type как то определяется в старом фронте, надо будет глянуть
          recordType: RecordType.EXISTENT,
          record: data.recordUrl,
        });

        addAnswer(answer);
      }
    );

    const handleStopRecording = (chunks: Blob) => {
      const formData = getFormDataFromAudio(chunks);
      saveRecording(formData);
    };

    const handleStartRecording = () => {
      if (firstAnswer) {
        removeAnswer(firstAnswer);
      }
    };

    const { record = null } = firstAnswer || {};

    return (
      <QuestionOrder order={order} status={getItemStatus(answerData)}>
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: questionText,
            }}
          />
          <Box
            sx={{
              backgroundColor: "custom.grey.menu-tile-inactive",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            <AudioRecorder
              onStartRecording={handleStartRecording}
              onStopRecording={handleStopRecording}
              maxRecorderTimeInSeconds={120}
            />
            <Loading loading={saveRecordingLoading}>
              {record ? (
                <>
                  <Typography>Отлично, система распознала ваш ответ</Typography>
                  <Audio src={record} />
                </>
              ) : null}
            </Loading>
          </Box>
        </Box>
      </QuestionOrder>
    );
  }
);
