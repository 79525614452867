import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Controller, UseFormReturn } from "react-hook-form";

import { FilterSelect } from "components/shared/FilterSelect";
import { SearchFilter } from "components/shared/SearchFilter";
import { TeachersFilters } from "pages/Teachers";
import { ValueWithLabel } from "types";

import { FilterContainer } from "./styledComponents";

export interface GroupFilterProps {
  methods: UseFormReturn<TeachersFilters>;
  // listLangs: ValueWithLabel[];
}

export const TeachersFilter = observer(
  ({ methods /* listLangs */ }: GroupFilterProps): JSX.Element | null => {
    const { control } = methods;

    return (
      <FilterContainer>
        {/* <Controller
          name="langs"
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (e: SelectChangeEvent<unknown>) => {
              const { value } = e.target;
              // On autofill we get a stringified value.
              onChange(typeof value === "string" ? value.split(",") : value);
            };

            return (
              <FilterSelect
                data={listLangs}
                value={
                  typeof value === "string" ? (value as any).split(",") : value
                }
                onChange={handleChange}
                multiple
                placeholder="Языки"
              />
            );
          }}
        />
        <SearchFilter
          control={control}
          name="email"
          inputProps={{
            placeholder: "Поиск электронной почты",
          }}
        /> */}
        <SearchFilter
          control={control}
          name="name"
          inputProps={{
            placeholder: "Поиск преподавателя",
          }}
        />
      </FilterContainer>
    );
  }
);
