import { Box } from "@mui/material";
import { Answer } from "@packages/store/models/Question/Answer";
import { Icon } from "../../components/Icon";
import { Message } from "../../components/DialogChat/Message";
import { StyledButton } from "../../components/DialogChat/styledComponents/StyledButton";
import { useTranslation } from "react-i18next";

interface PossibleDialogAnswersProps {
  possibleAnswers: Answer[];
  recognizedAnswerId: string | null;
  choiceVariant: boolean;
  onConfirm: () => void;
  onReset: () => void;
  onChoice: (answer: Answer) => void;
}

export const PossibleDialogAnswers = ({
  possibleAnswers,
  recognizedAnswerId,
  choiceVariant,
  onConfirm,
  onReset,
  onChoice,
}: PossibleDialogAnswersProps): JSX.Element | null => {
  const { t } = useTranslation();

  const renderPossibleAnswerWithChoice = (answer: Answer, order: number) => {
    return (
      <Box key={answer.id} display="flex" alignItems="center" gap="0.6rem">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <StyledButton variant="confirm" onClick={onConfirm}>
            <Icon type="checkmark" width="1rem" />
            {t("Exercise:DialogExerciseConfirmAnswer")}
          </StyledButton>
          <StyledButton variant="reset" onClick={onReset}>
            <Icon type="mic" stroke="currentColor" width="1rem" />
            {t("Exercise:DialogExerciseResetAnswer")}
          </StyledButton>
        </Box>

        <Message
          text={answer.text || ""}
          direction="right"
          selected
          order={order}
          orderVariant="alphabetical"
        />
      </Box>
    );
  };

  const renderPossibleAnswerWithoutChoice = (answer: Answer, order: number) => {
    return (
      <Message
        key={answer.id}
        text={answer.text || ""}
        direction="right"
        order={order}
        orderVariant="alphabetical"
      />
    );
  };

  const renderPossibleAnswerWithSelect = (answer: Answer, order: number) => {
    return (
      <Box key={answer.id} display="flex" alignItems="center" gap="0.6rem">
        <Box display="flex" alignItems="center" gap="0.5rem">
          <StyledButton variant="confirm" onClick={() => onChoice(answer)}>
            {t("Exercise:DialogExerciseSelectAnswer")}
            <Icon type="arrowRight" width="1rem" stroke="currentColor" />
          </StyledButton>
        </Box>

        <Message
          text={answer.text || ""}
          direction="right"
          selected
          order={order}
          orderVariant="alphabetical"
        />
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
      gap="0.4rem"
      mb="1rem"
    >
      {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
      {possibleAnswers.map((answer, i) => {
        if (choiceVariant) {
          return renderPossibleAnswerWithSelect(answer, i + 1);
        }

        if (answer.id === recognizedAnswerId) {
          return renderPossibleAnswerWithChoice(answer, i + 1);
        }

        return renderPossibleAnswerWithoutChoice(answer, i + 1);
      })}
    </Box>
  );
};
