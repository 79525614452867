import { GenerateDistributorReport } from "@packages/store/models/GenerateReport/GenerateReport";
import { ReportTest } from "@packages/store/models/ReportTest/ReportTestModel";
import { ValueWithLabel } from "@packages/store/services/Api";

export function formatTestsForFilter(reports: ReportTest[]): ValueWithLabel[] {
  return reports.map((report) => ({
    label: report.name ?? "",
    value: report.id ?? "",
  }));
}
