/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import {
  CustomerCompanyModel,
  CustomerCompanySnapshot,
} from "./CustomerCompanyModel";

export const CustomerCompanyStoreModel = types
  .model("CustomerCompanyStore")
  .props({
    items: types.optional(types.array(CustomerCompanyModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: CustomerCompanySnapshot) => {
      const instance = resolveIdentifier(CustomerCompanyModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));

type CustomerCompanyStoreType = Instance<typeof CustomerCompanyStoreModel>;
export type CustomerCompanyStore = CustomerCompanyStoreType;
type CustomerCompanySnapshotType = SnapshotOut<
  typeof CustomerCompanyStoreModel
>;
export type CustomerCompanyStoreSnapshot = CustomerCompanySnapshotType;
export const createCustomerCompanyStoreDefaultModel = () =>
  types.optional(CustomerCompanyStoreModel, {} as CustomerCompanyStoreSnapshot);
