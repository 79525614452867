import { styled, Box } from "@mui/material";

export const MyModulesContainer = styled(Box)(({ theme }) => ({
  margin: `1.5rem 0 ${theme.spacing(4)} 0`,
  display: "flex",
  columnGap: theme.spacing(3),
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
  },
}));
