import { useRef, useState } from "react";

import { Box, Typography } from "@mui/material";

export interface AttendancePivotTableCommentProps {
  comment?: string | null;
}

export const AttendancePivotTableComment = ({
  comment,
}: AttendancePivotTableCommentProps): JSX.Element => {
  const [showMessage, setShowMessage] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);

  const getPosition = () => {
    const container = containerRef.current;
    const { top = 0, left = 0 } = container?.getBoundingClientRect() ?? {};

    return { top, left };
  };

  const onHover = () => {
    const { top, left } = getPosition();
    setPosY(top + 10);
    setPosX(left);
    setShowMessage(true);
  };
  const onBlur = () => setShowMessage(false);

  if (!comment) {
    return <></>;
  }

  return (
    <Box
      ref={containerRef}
      position="absolute"
      top="0"
      right="1rem"
      padding="0.3rem"
      sx={{ cursor: "pointer" }}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
    >
      <Box position="relative">
        <Typography color="green" fontSize="0.8rem">
          i
        </Typography>
        {showMessage && (
          <Box
            position="fixed"
            top={`${posY}px`}
            left={`${posX}px`}
            padding="1rem"
            bgcolor="#ffffff"
            borderRadius="0.3rem"
            zIndex={9}
          >
            {comment}
          </Box>
        )}
      </Box>
    </Box>
  );
};
