/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { statuses } from "../Lesson/LessonModel";

export const CounterModel = types.model("CounterModel").props({
  status: types.enumeration("SummaryStatus", statuses),
  lessons: types.maybeNull(types.number),
  hours: types.maybeNull(types.number),
});

type CounterModelType = Instance<typeof CounterModel>;
export interface Counter extends CounterModelType {}
type CounterSnapshotType = SnapshotOut<typeof CounterModel>;
export type CounterSnapshot = CounterSnapshotType;
