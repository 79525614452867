import React, { FC } from "react";

import cn from "classnames";

import styles from "./Notification.module.scss";
import { Icon } from "../Icon";
import { Text } from "../Text";

interface NotificationProps {
  classNames?: string;
  open: boolean;
  onClose: () => void;
  text: string;
  type: NotificationType;
}

export type NotificationType =  "info" | "error" | "success";

export const Notification: FC<NotificationProps> = (props) => {
  const { classNames, open, onClose, text, type } = props;

  const containerClasses = cn(
    classNames,
    styles.container,
    styles[type],
  );

  if (!open) {
    return null;
  }

  return (
    <div className={containerClasses}>
      <Icon
        icon="close-filters"
        color="white"
        containerClassName={styles.close}
        onClick={onClose}
      />
      <Text variant="text-2-regular" color="base-w">
        {text}
      </Text>
    </div>
  )
};