import { useState, useCallback } from "react";

import { GeneralApiProblem } from "@packages/store/services/Api/ApiProblem";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetch = <T, R extends any[]>(
  callback: (
    ...args: R
  ) => Promise<{ kind: "ok"; data: T } | GeneralApiProblem>,
  thenCallback?: (data: T) => void,
  catchCallback?: (error: GeneralApiProblem) => void,
  finallyCallback?: () => void
) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetch: typeof callback = useCallback(
    async (...args: R) => {
      setError(false);
      setSuccess(false);
      setLoading(true);

      const response = await callback(...args);

      setLoading(false);

      if (response.kind === "ok") {
        thenCallback?.(response.data);
        setSuccess(true);
      } else {
        catchCallback?.(response);
        setError(true);
      }

      finallyCallback?.();

      return response;
    },
    [callback, catchCallback, thenCallback, finallyCallback]
  );

  return { fetch, loading, error, success } as const;
};
