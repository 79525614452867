import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardClassContainerProps } from "../CardClass";

export const IconContainer = styled(Box)(
  ({ iconColor, theme }: CardClassContainerProps) => ({
    minWidth: "2.5rem",
    height: "2.5rem",
    background: iconColor,
    borderRadius: "0.375rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "2.5rem",
    marginRight: theme.spacing(4),

    [theme.breakpoints.down("zeroScreen")]: {
      marginRight: theme.spacing(3),
    },
  })
);
