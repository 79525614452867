import { PaginationProps } from "@mui/material";

import { MaterialPagination } from "./styledComponent";
import { useCurrentBreakpoint } from "../../hooks";

export interface PropsPagination extends PaginationProps {
  dataLength: number;
  pageSize: number;
}

export function Pagination({
  dataLength,
  pageSize,
  page,
  ...other
}: PropsPagination): JSX.Element {
  const breakpoint = useCurrentBreakpoint();

  const pageCount = Math.ceil(dataLength / pageSize);

  return (
    <div>
      {pageCount > 1 && (
        <MaterialPagination
          {...other}
          page={page}
          shape="rounded"
          count={pageCount}
          siblingCount={
            breakpoint === "desktop" ||
            breakpoint === "laptop" ||
            breakpoint === "tablet"
              ? 2
              : 0
          }
        />
      )}
    </div>
  );
}
