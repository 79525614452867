import { Control, Controller } from "react-hook-form";

import { DatePicker } from "components/DatePicker";
import { ModalPayrollGroupFormValues } from "pages/PayrollAccountant/components/ModalPayrollGroup/ModalPayrollGroup";

import { ModalTeacherLangFormValues } from "../ModalTeacherLang";
import { ModalTeacherRateFormValues } from "../ModalTeacherRate";

type FormValues =
  | ModalTeacherLangFormValues
  | ModalTeacherRateFormValues
  | ModalPayrollGroupFormValues;

export interface ActiveFromInputProps<T extends FormValues> {
  control: Control<T>;
  required: string;
  placeholder: string;
  name: string;
}

export const ActiveFromInput = <T extends FormValues>({
  control,
  required,
  placeholder,
  name,
}: ActiveFromInputProps<T>): JSX.Element | null => {
  return (
    <Controller
      control={control}
      name={name as any}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <DatePicker
          value={value}
          onChange={onChange}
          label={placeholder}
          textFieldProps={{
            error: !!error,
            helperText: error?.message || " ",
            sx: { maxWidth: "100%" },
          }}
        />
      )}
    />
  );
};
