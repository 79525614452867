/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotOut } from "mobx-state-tree";

import { ScriptItemModel } from "./ScriptItem";
import { VideoTrackModel } from "./VideoTrack";

export enum AdditionalContentType {
  TEXT = "text/html",
  IMAGE_JPEG = "image/jpeg",
  IMAGE_PNG = "image/png",
  VIDEO = "video",
  PDF = "application/pdf",
}

export const AdditionalContentModel = types
  .model("AdditionalContent", {
    text: types.maybeNull(types.string),
    contentType: types.optional(
      types.enumeration<AdditionalContentType>(
        "AdditionalContentType",
        Object.values(AdditionalContentType)
      ),
      AdditionalContentType.TEXT
    ),
    contentUrl: types.maybeNull(types.string),
    videoTracks: types.array(VideoTrackModel),
    script: types.maybeNull(types.array(ScriptItemModel)),
  })
  .views((self) => ({
    get isVideo() {
      return self.contentType === AdditionalContentType.VIDEO;
    },
    get isText() {
      return self.contentType === AdditionalContentType.TEXT;
    },
    get isImage() {
      return (
        self.contentType === AdditionalContentType.IMAGE_JPEG ||
        self.contentType === AdditionalContentType.IMAGE_PNG
      );
    },
    get isPdf() {
      return self.contentType === AdditionalContentType.PDF;
    },
  }));

type AdditionalContentModelType = Instance<typeof AdditionalContentModel>;
type AdditionalContentModelSnapshotType = SnapshotOut<
  typeof AdditionalContentModel
>;

export interface AdditionalContent extends AdditionalContentModelType {}
export type AdditionalContentSnapshot = AdditionalContentModelSnapshotType;
