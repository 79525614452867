/* eslint-disable react/require-default-props */
import { useEffect } from "react";

import { Box } from "@mui/material";

export interface JetRedirectProps {
  url: string;
  newTab?: boolean;
}

export const JetRedirect = ({
  url,
  newTab = false,
}: JetRedirectProps): JSX.Element => {
  useEffect(() => {
    if (newTab) window.open(url, "_blank");
    window.location.replace(url);
  }, [newTab, url]);

  return <Box>Redirecting</Box>;
};
