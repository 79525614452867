import { observer } from "mobx-react-lite";

import { CreativeTasks as CreativeTasksContent } from "@packages/creative-tasks";

import { PageBlock } from "components/PageBlock";
import { useQueryParam } from "hooks";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

export const CreativeTasks = observer(() => {
  const [taskId, setTaskId] = useQueryParam("taskId");

  return (
    <WrapperMainStudents>
      <PageBlock title="">
        <CreativeTasksContent taskId={taskId} setTaskId={setTaskId} />
      </PageBlock>
    </WrapperMainStudents>
  );
});
