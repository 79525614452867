import {
  Radio as MaterialUIRadioButton,
  RadioProps as MaterialRadioButtonProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export type RadioProps = MaterialRadioButtonProps;

const MaterialRadioButton = styled(MaterialUIRadioButton)({});

export function RadioButton(props: RadioProps): JSX.Element {
  return <MaterialRadioButton {...props} />;
}
