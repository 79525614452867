/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  destroy,
} from "mobx-state-tree";

import { TagModel, TagSnapshot } from "./Tag";

/**
 * Model description here for TypeScript hints.
 */
export const TagStoreModel = types
  .model("TagStore")
  .props({
    items: types.optional(types.array(TagModel), []),
  })
  .actions((self) => ({
    addItem: (item: TagSnapshot) => {
      const instance = resolveIdentifier(TagModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
    removeTag(tagId: string) {
      const tag = self.items.find((tag) => tag.id === tagId);

      if (tag) {
        destroy(tag);
      }
    },
  }));

type TagStoreType = Instance<typeof TagStoreModel>;
export type TagStore = TagStoreType;
type TagStoreSnapshotType = SnapshotOut<typeof TagStoreModel>;
export type TagStoreSnapshot = TagStoreSnapshotType;
