import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { TranslationModel } from "../Translation/TranslationModel";
import { ExerciseGroupsModel } from "./ExerciseGroups";
import { TypeModel } from "./Type";

export const SectionModel = types.model({
  id: types.identifier,
  childrenListUpdatedAt: types.maybeNull(types.Date),
  code: types.maybeNull(types.string),
  exercisesCount: types.maybeNull(types.number),
  maxMark: types.maybeNull(types.number),
  order: types.maybeNull(types.number),
  timeLimit: types.maybeNull(types.number),
  unitId: types.maybeNull(types.number),
  updatedAt: types.maybeNull(types.Date),
  translationsAttributes: types.optional(types.array(TranslationModel), []),
  type: types.maybeNull(TypeModel),
  exerciseGroups: types.optional(types.array(ExerciseGroupsModel), []),
});

type SectionModelType = Instance<typeof SectionModel>;
export type SectionModel = SectionModelType;
type SectionModelTypeSnapshotType = SnapshotOut<typeof SectionModel>;
export type SectionModelSnapshot = SectionModelTypeSnapshotType;
