import { styled } from "@mui/material";

export const TableText = styled("span")(({ theme }) => ({
  color: "#78798B",
  lineHeight: "1rem",
  fontSize: "0.8125rem",
  fontWeight: "500",
  fontFamily: "Golos",
  padding: `0.563rem ${theme.spacing(4)}`,
}));
