import { types, SnapshotOut, Instance } from "mobx-state-tree";

export const PropertiesModel = types.model("Properties", {
  multipleAnswers: types.maybeNull(types.boolean),
  showPossibleAnswersForEachQuestion: types.maybeNull(types.boolean),
  reusableAnswers: types.maybeNull(types.boolean),
});

type PropertiesModelType = Instance<typeof PropertiesModel>;
type PropertiesModelSnapshotType = SnapshotOut<typeof PropertiesModel>;

export type Properties = PropertiesModelType;
export type PropertiesSnapshot = PropertiesModelSnapshotType;
