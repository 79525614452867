/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-shadow */
import { Fragment } from "react";

import { Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";

import { List } from "../../components/List";
import { MovingDropzone } from "../../components/MovingDropzone";
import { QuestionOrder } from "../../components/QuestionOrder";
import { lineHeight } from "../../constants/styles";
import { createQuestionContent } from "../../helpers/createQuestionContent";
import { getItemStatus } from "../../helpers/getItemStatus";

import { AnswerView } from "../AnswerView";
import type { MovingQuestionProps } from "./MovingQuestion";

interface MovingQuestionGapsProps extends MovingQuestionProps {
  needToShow?: boolean;
}

const getAnswerPlaces = (text: string) => {
  const indexes = [0];

  for (let i = 0; i < text.length; i += 1) {
    // Текст вопроса приходит не очень хороший, бывают пробелы там где они не нужны, поэтому такое условие
    // eslint-disable-next-line no-useless-escape
    if (text[i].match(/[\.\s]/) && !text[i + 1]?.match(/[\.\s\/]/)) {
      indexes.push(i);
    }
  }

  return indexes;
};

export const MovingQuestionGaps = observer(
  ({
    question,
    answerData,
    order,
    needToShow,
  }: MovingQuestionGapsProps): JSX.Element | null => {
    const theme = useTheme();

    const { id, text } = question;

    const { getAnswerByPlace, answers, emptyFieldsCount } = answerData;

    const isFilled = answers.length === emptyFieldsCount!;

    // Никуда не выносил эту функцию потому что она нужна Только здесь
    const answerPlaces = getAnswerPlaces(text);

    const renderElement = (index: number): JSX.Element | null => {
      const place = answerPlaces[index];

      const answer = getAnswerByPlace(place);

      if (answer) {
        const { text, isChecked, remove } = answer;

        return (
          <AnswerView
            title={String(text)}
            status={isChecked ? getItemStatus(answer) : "current"}
            {...(!isChecked && {
              onRemove: remove,
            })}
          />
        );
      }

      if (!isFilled && needToShow) {
        return <MovingDropzone question={id} place={place} expandable />;
      }

      return null;
    };

    const content = createQuestionContent(
      text,
      answerPlaces.length,
      (index) => answerPlaces[index],
      renderElement
    );

    return (
      <QuestionOrder order={order} status={getItemStatus(answerData)}>
        <List
          items={content}
          renderItem={({ text, element }, index) => (
            <Fragment key={index}>
              {text && (
                <Typography
                  variant="regularText"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}{" "}
              {element}{" "}
            </Fragment>
          )}
          containerStyles={{
            ...theme.typography.regularText,
            display: "inline",
            lineHeight,
          }}
        />
      </QuestionOrder>
    );
  }
);
