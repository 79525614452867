/* eslint-disable @typescript-eslint/no-empty-interface */

import { format } from "date-fns";
import { types, SnapshotOut, Instance, getRoot } from "mobx-state-tree";

import { RootStore } from "../RootStore/RootStore";

export const NotificationModel = types
  .model("NotificationModel", {
    id: types.identifier,
    read: types.maybeNull(types.boolean),
    canDelete: types.maybeNull(types.boolean),
    subject: types.maybeNull(types.string),
    body: types.maybeNull(types.string),
    source: types.maybeNull(types.string),
    priority: types.maybeNull(types.number),
    notifyType: types.maybeNull(types.string),
    taskId: types.maybeNull(types.string),
    userId: types.maybeNull(types.number),
    createdAt: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.string),
    humanSource: types.maybeNull(types.string),
    humanCreatedAt: types.maybeNull(types.string),
    shortBody: types.maybeNull(types.string),
  })
  .views((self) => ({
    get date() {
      return format(new Date(self.createdAt || ""), "dd.MM.yy");
    },
    get time() {
      return format(new Date(self.createdAt || ""), "HH:mm");
    },
  }))
  .actions((self) => ({
    async markAsRead() {
      const rootStore = getRoot<RootStore>(self);

      await rootStore.markNotificationAsRead(self.id, true);
    },
  }));

type NotificationType = Instance<typeof NotificationModel>;
export interface Notification extends NotificationType {}
type NotificationSnapshotType = SnapshotOut<typeof NotificationModel>;
export type NotificationSnapshot = NotificationSnapshotType;
