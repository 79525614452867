import { parseSearchParams } from "@packages/shared/utils/searchParams";

export const getDefaultValues = (searchParams: URLSearchParams) => {
  const data = parseSearchParams(searchParams);

  return {
    startDate: data?.startDate ?? null,
    endDate: data?.endDate ?? null,
    company: data?.company ?? null,
    module: data?.module ?? "",
    student: data?.student ?? "",
    group: data?.group ?? "",
    level: data?.level ?? "",
    teacher: data?.teacher ?? "",
    inactiveGroups: data?.inactiveGroups ?? "",
    withLessonsWithoutTeachers: data?.withLessonsWithoutTeachers ?? false,
    withLessonsOnlyTechnicalCancellationBefore:
      data?.withLessonsOnlyTechnicalCancellationBefore ?? false,
  } as any;
};
