import {
  CancellationReport,
  Status,
} from "@packages/store/models/CancellationReport/CancellationReport";
import { CountersByCompany } from "@packages/store/models/CancellationReport/CountersByCompany";

export const formatReportByTeacher = (report: CancellationReport) => {
  const getTotals = (status: Status) => {
    return `${report.getTotalHours(status).toFixed(2)}/${report.getTotalLessons(
      status
    )}`;
  };
  const getCompanyTotals = (counters: CountersByCompany, status: Status) => {
    return `${counters.getHours(status).toFixed(2)}/${counters.getLessons(
      status
    )}`;
  };

  return {
    teacher: report.teacher.fullNameReversed,
    company: "Общее",
    completed: getTotals("completed"),
    lateCanceledByCustomer: getTotals("lateCanceledByCustomer"),
    earlyCanceledByCustomer: getTotals("earlyCanceledByCustomer"),
    lateCanceledBySchool: getTotals("lateCanceledBySchool"),
    earlyCanceledBySchool: getTotals("earlyCanceledBySchool"),
    subRows: report.countersByCompany.map((counters) => ({
      company: counters.company.name,
      completed: getCompanyTotals(counters, "completed"),
      lateCanceledByCustomer: getCompanyTotals(
        counters,
        "lateCanceledByCustomer"
      ),
      earlyCanceledByCustomer: getCompanyTotals(
        counters,
        "earlyCanceledByCustomer"
      ),
      lateCanceledBySchool: getCompanyTotals(counters, "lateCanceledBySchool"),
      earlyCanceledBySchool: getCompanyTotals(
        counters,
        "earlyCanceledBySchool"
      ),
    })),
  };
};

export type FormattedReportByTeacher = ReturnType<typeof formatReportByTeacher>;
