import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { Logo } from "components/Logo";
import { routerLinks } from "constants/constants";
import { StyledMuiLink } from "pages/Login/styledComponents";

import {
  LinksContainer,
  IntroContainer,
  IntroWrapper,
} from "./styledComponents";

export const Intro = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <IntroWrapper>
      <IntroContainer>
        <Box>
          <Logo
            color={theme.palette.primary.contrastText}
            width={128}
            height={32}
          />
        </Box>

        <Box>
          <Icon type="loginIcon" />
        </Box>

        <LinksContainer>
          <StyledMuiLink href={routerLinks.About.url} colorVariant="light">
            {t(`Footer:About`, { lng: "ru" })}
          </StyledMuiLink>
          <StyledMuiLink
            href={routerLinks.UserAgreement.url}
            colorVariant="light"
          >
            {t(`Footer:UserAgreement`, { lng: "ru" })}
          </StyledMuiLink>
          <StyledMuiLink href={routerLinks.Terms.url} colorVariant="light">
            {t(`Footer:Terms & Conditions`, { lng: "ru" })}
          </StyledMuiLink>
          <StyledMuiLink href={routerLinks.Privacy.url} colorVariant="light">
            {t(`Footer:Privacy Policy`, { lng: "ru" })}
          </StyledMuiLink>
        </LinksContainer>
      </IntroContainer>
    </IntroWrapper>
  );
};
