import { Course } from "@packages/store/models/Course/CourseModel";

export const filterByName =
  (name: string) =>
  ({ translationsAttributes }: Course) => {
    if (translationsAttributes.length) {
      const isTranslation = translationsAttributes.find((translation) =>
        translation.name?.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
      return isTranslation;
    }
    return false;
  };

export const filterByLevel =
  (level: string) =>
  ({ knowledgeLevel }: Course) => {
    return !level || knowledgeLevel?.code === level;
  };
