import { useState, useRef, useEffect, useCallback } from "react";

import { useTheme } from "@mui/material";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";

import { ScrollbarComponent } from "../../components/ScrollbarComponent";
import {
  additionalContentWidth,
  maxScrollHeight,
  tablet,
} from "../../constants/styles";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface PDFViewProps {
  file: string;
  inWidthChange: boolean | number | string;
}

export const PDFView = ({
  file,
  inWidthChange,
}: PDFViewProps): JSX.Element | null => {
  const theme = useTheme();

  const [numPages, setNumPages] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleContentWidthChange = useCallback(() => {
    const container = containerRef.current;
    if (container) {
      if (container.offsetWidth >= additionalContentWidth) {
        setContentWidth(container.offsetWidth);
        return;
      }
      setContentWidth(additionalContentWidth);
    }
  }, [containerRef]);

  useEffect(() => {
    handleContentWidthChange();
  }, [handleContentWidthChange, inWidthChange]);

  useEffect(() => {
    window.addEventListener("resize", handleContentWidthChange);
    return () => {
      window.removeEventListener("resize", handleContentWidthChange);
    };
  }, [handleContentWidthChange]);

  const handleDocumentLoadSuccess = <T extends { numPages: number }>({
    // eslint-disable-next-line @typescript-eslint/no-shadow
    numPages,
  }: T) => {
    setNumPages(numPages);
  };

  return (
    <ScrollbarComponent
      containerStyles={{
        [theme.breakpoints.down(tablet)]: {
          ".os-host": {
            maxHeight: maxScrollHeight,
          },
        },
      }}
    >
      <div ref={containerRef}>
        <Document file={file} onLoadSuccess={handleDocumentLoadSuccess}>
          {numPages &&
            [...Array(numPages).keys()].map((pageIndex) => (
              <Page
                key={pageIndex}
                pageIndex={pageIndex}
                renderTextLayer={false}
                width={contentWidth}
              />
            ))}
        </Document>
      </div>
    </ScrollbarComponent>
  );
};
