/* eslint-disable @typescript-eslint/no-shadow */
import { useLayoutEffect, useRef } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";

import { mobile } from "../../../../../../constants/styles";
import { SubExerciseComponentProps } from "../../../../../../types";

export const Confirmation = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const theme = useTheme();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const { additionalContent } = exercise;

    const textAdditionalContent = additionalContent.find(
      (content) => content.isText
    );

    useLayoutEffect(() => {
      const container = containerRef.current;

      if (!textAdditionalContent || !container) {
        return;
      }

      if (container) {
        const linkEls = container.querySelectorAll("a");
        const titleEls = container.querySelectorAll(".h3-title");
        const urls = [...linkEls].map(
          (link) => link.getAttribute("href") || "#blank"
        );

        const length = Math.min(urls.length, titleEls.length);

        for (let index = 0; index < length; index += 1) {
          const url = urls[index];
          const titleEl = titleEls[index];
          const parentEl = titleEl.parentElement;

          if (parentEl) {
            const wrapperEl = document.createElement("a");
            wrapperEl.setAttribute("href", url);
            wrapperEl.setAttribute("target", "_blank");
            parentEl.prepend(wrapperEl);
            wrapperEl.appendChild(titleEl);
          }
        }
      }
    }, [containerRef, textAdditionalContent]);

    return (
      <Box ref={containerRef}>
        <Typography
          variant="regularText"
          sx={{
            ".page-light": {
              mb: 4,
              textAlign: "left",
            },
            ".link": {
              textAlign: "left",
            },
            ".lists": {
              mb: 3,
            },
            ".linetwo": {
              fontFamily: "Calibri, sans-serif",
              fontSize: "1.02rem",
            },
            ".pictures": {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
            ".picture": {
              width: "70%",
            },
            ".lessons": {
              display: "flex",
              flexWrap: "wrap",
              [theme.breakpoints.down(mobile)]: {
                flexDirection: "column",
              },
            },
            ".lesson": {
              flex: 1,
              [theme.breakpoints.up(mobile)]: {
                mr: 4,
                "&:last-child": {
                  mr: 0,
                },
              },
              [theme.breakpoints.down(mobile)]: {
                mb: 4,
                "&:last-child": {
                  mb: 0,
                },
              },
            },
            ".prevprez": {
              width: "100%",
              height: "auto",
              transition: theme.transitions.create(["filter, transform"]),
              [theme.breakpoints.up(mobile)]: {
                "&:hover": {
                  filter: "drop-shadow(2px 2px 6px rgba(0, 0, 0, 0.34))",
                  transform: "scale(1.05)",
                },
              },
            },
            ".h1-title": {
              mb: "1rem",
            },
            ".h2-title": {
              ...theme.typography.h3,
              width: "100%",
              mb: "0.5rem",
            },
            ".h3-title": {
              ...theme.typography.regularText,
              mb: 3,
            },
            a: {
              color: "primary.main",
              textDecoration: "underline",
            },
          }}
          dangerouslySetInnerHTML={{
            __html: textAdditionalContent?.text || "",
          }}
        />
      </Box>
    );
  }
);
