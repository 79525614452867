import { styled } from "@mui/material";

export const ActionContainer = styled("div")(({ theme }) => ({
  margin: theme.spacing(2, 0),
  flexBasis: "20%",

  [theme.breakpoints.down("laptop")]: {
    marginTop: "0",
  },

  [theme.breakpoints.between("zeroScreen", 510)]: {
    display: "flex",
    margin: `0 0 0 3.5rem`,
    justifyContent: "flex-end",
  },

  [theme.breakpoints.down("zeroScreen")]: {
    margin: `0 0 0 3rem`,
  },
}));
