import { styled } from "@mui/material";
import { Link, LinkProps } from "react-router-dom";

import { theme } from "@packages/shared/themes/default";

interface StyledRouterLinkProps extends LinkProps {
  colorVariant?: "light" | "dark";
}

const colorVariants = {
  light: theme.palette.primary.contrastText,
  dark: theme.palette.info.main,
};

export const StyledRouterLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "colorVariant",
})<StyledRouterLinkProps>(({ colorVariant = "dark" }) => ({
  color: colorVariants[colorVariant],
  fontSize: 13,
  textDecoration: "underline",
  textDecorationColor: colorVariants[colorVariant],
}));
