import { Outlet } from "react-router";

import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

import { ReportHeader } from "./components/ReportHeader";
import { ReportSections } from "./components/ReportSections";
import { ReportTable } from "./components/ReportTable";

export const Reports = (): JSX.Element => {
  return (
    <WrapperMainStudents>
      <ReportHeader />
      <ReportSections />
      <ReportTable />
      <Outlet />
    </WrapperMainStudents>
  );
};
