/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, SnapshotOut, Instance } from "mobx-state-tree";
import { AnswerState } from "../ExerciseAnswer/Answer";

export const AnswerModel = types.model({
  id: types.maybeNull(types.string),
  options: types.optional(
    types.array(types.union(types.string, types.number)),
    []
  ),
  state: types.maybeNull(
    types.enumeration<AnswerState>("AnswerState", Object.values(AnswerState))
  ),
  place: types.maybeNull(types.number),
  action: types.maybeNull(types.string),
  element: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
});

type AnswerModelType = Instance<typeof AnswerModel>;
type AnswerModelSnapshotType = SnapshotOut<typeof AnswerModel>;

export interface Answer extends AnswerModelType {}
export type AnswerSnapshot = AnswerModelSnapshotType;
