import {
  Instance,
  resolveIdentifier,
  SnapshotOut,
  types,
} from "mobx-state-tree";

import { KnowledgeLevelModel, KnowledgeLevelSnapshot } from "./KnowledgeLevel";
/**
 * Model description here for TypeScript hints.
 */
export const KnowledgeLevelStoreModel = types
  .model("KnowledgeLevelStore")
  .props({
    items: types.optional(types.array(KnowledgeLevelModel), []),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    addItem: (item: KnowledgeLevelSnapshot) => {
      if (resolveIdentifier(KnowledgeLevelModel, self, item.id)) return;
      self.items.push(item);
    },
  }));
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type KnowledgeLevelStoreType = Instance<typeof KnowledgeLevelModel>;
export type KnowledgeLevelStore = KnowledgeLevelStoreType;
type CoursesSnapshotType = SnapshotOut<typeof KnowledgeLevelStoreModel>;
export type KnowledgeLevelStoreSnapshot = CoursesSnapshotType;
