import { Box, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CheckboxWord } from "components/Checkbox";
import { Icon } from "components/Icon";
import { Loading } from "components/Loading";

import { FilterSelect } from "./FilterSelect";
import {
  LinkButton,
  Divider,
  DefaultHeader,
  MobileHeader,
} from "./styledComponent";

export type ValueWithLabel = {
  value: string;
  label: string;
};

export type CheckedValueWithLabel = ValueWithLabel & {
  checked: boolean;
};

export interface WordsFilterProps {
  fromUnit?: boolean;
  addedToMe?: boolean;
  courses?: ValueWithLabel[];
  units?: ValueWithLabel[];
  types?: ValueWithLabel[];
  selectedCourse?: string;
  selectedUnit?: string;
  selectedType?: string;
  onModalClose?: () => void;
  onClear?: () => void;
  onFromUnitToggle?: (checked: boolean) => void;
  onAddedMeToggle?: (checked: boolean) => void;
  onCourseChange?: (value: string) => void;
  onUnitChange?: (value: string) => void;
  onTypeChange?: (type: string) => void;
}

export const WordsFilter = ({
  fromUnit = false,
  addedToMe = false,
  courses = [],
  units = [],
  types = [],
  selectedCourse = "",
  selectedUnit = "",
  selectedType = "",
  onModalClose,
  onClear,
  onFromUnitToggle,
  onAddedMeToggle,
  onCourseChange,
  onUnitChange,
  onTypeChange,
}: WordsFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleCourseChange = (event: SelectChangeEvent<unknown>) => {
    onCourseChange?.(event.target.value as string);
  };

  const handleUnitChange = (event: SelectChangeEvent<unknown>) => {
    onUnitChange?.(event.target.value as string);
  };

  const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
    onTypeChange?.(event.target.value as string);
  };

  return (
    <Box flex={1} padding="1rem" maxWidth="100%">
      <DefaultHeader>
        <Typography variant="h1">{t("MyWords:FilterTitle")}</Typography>
        <LinkButton onClick={onClear}>{t("MyWords:FilterClear")}</LinkButton>
      </DefaultHeader>
      <MobileHeader>
        <LinkButton onClick={onClear}>{t("MyWords:FilterClear")}</LinkButton>
        <Typography variant="h1">{t("MyWords:FilterTitle")}</Typography>
        <Box sx={{ cursor: "pointer", display: "flex" }} onClick={onModalClose}>
          <Icon
            color={theme.palette.custom.grey.dark}
            type="close"
            width={20}
            height={20}
          />
        </Box>
      </MobileHeader>
      <Box my="1rem" display="flex" gap="1rem" flexDirection="column">
        <CheckboxWord
          checked={fromUnit}
          title={t("MyWords:FromUnit")}
          onChange={onFromUnitToggle}
        />
        <CheckboxWord
          checked={addedToMe}
          title={t("MyWords:AddedToMe")}
          onChange={onAddedMeToggle}
        />
      </Box>
      <Divider mt="1rem" mb="1rem" />
      {fromUnit && (
        <>
          <Box mb="1rem">
            <Loading loading={courses.length <= 1}>
              <FilterSelect
                data={courses}
                value={selectedCourse}
                placeholder={t("MyWords:SelectCourse")}
                onChange={handleCourseChange}
              />
            </Loading>
          </Box>
          {units.length > 1 && (
            <Box mb="1rem">
              <FilterSelect
                data={units}
                value={selectedUnit}
                placeholder={t("MyWords:SelectUnit")}
                onChange={handleUnitChange}
              />
            </Box>
          )}
        </>
      )}
      <FilterSelect
        data={types}
        value={selectedType}
        placeholder={t("MyWords:SelectType")}
        onChange={handleTypeChange}
      />
    </Box>
  );
};
