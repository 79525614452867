import { Tooltip, Typography } from "@mui/material";

import { TooltipTitle } from "./styledComponents/TooltipTitle";

export interface ReportTextProps {
  text?: string;
  tooltip?: string;
  onClick?: () => void;
}

export const ReportText = ({
  text,
  tooltip,
  onClick,
}: ReportTextProps): JSX.Element => {
  return (
    <Tooltip
      placement="bottom-start"
      title={<TooltipTitle>{tooltip}</TooltipTitle>}
    >
      <Typography fontSize="0.8rem" onClick={onClick}>
        {text}
      </Typography>
    </Tooltip>
  );
};
