import { Box, Typography } from "@mui/material";

interface TypeStyles {
  [key: string]: string;
}

const typeStyles: TypeStyles = {
  completed: "success.main",
  planned: "info.main",
  earlyCanceledByCustomer: "warning.dark",
  earlyCanceledBySchool: "warning.dark",
  lateCanceledByCustomer: "warning.main",
  lateCanceledBySchool: "warning.main",
};

interface StatusContainerProps {
  children: React.ReactNode;
  type: string;
  withBorder?: boolean;
}

export const StatusContainer = ({
  children,
  type,
  withBorder,
}: StatusContainerProps) => {
  const borderStyles = withBorder
    ? {
        width: "fit-content",
        pt: 1,
        pb: 1,
        pl: 3,
        pr: 3,
        borderRadius: 1,
        border: 1,
        borderColor: typeStyles[type],
      }
    : {};
  return (
    <Box sx={borderStyles}>
      <Typography
        variant="smallText"
        sx={{
          width: "fit-content",
          lineHeight: 1.5,
          color: typeStyles[type],
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};
