const uz = {
  CreativeTasks: {
    Title: "Ijodiy vazifalar",
    UpdateTaskTeacher: "Saqlash",
    SubmitTaskTeacher: "Natijalarni talabaga yuboring",
    UpdateTaskStudent: "Qoralamani saqlash",
    SubmitTaskStudent: "Saqlash va ko'rib chiqish uchun yuborish",
    AnswerHeaderStatistics:
      "Savollar: {{questions}} | Belgilar: {{symbols}} | So'zlar: {{words}} | Jumlalar: {{sentenses}}",
    QuestionsAndComments: "Xatolar va sharhlar:",
    HoverForDisplay: "Ko'rsatish uchun kursorni bosing:",
    CheckingHistory: "Tarixni tekshirish",
    CurrentVersion: "Joriy versiya",
    TeacherComment: "O'qituvchining ishiga sharh:",
    SaveCorrection: "Saqlash",
    CancelCorrection: "Bekor qilish",
    Grammar: "Grammatika",
    Lexical: "Lug'at",
    Comment: "Izoh",
    ErrorRangeFilled:
      "Siz tanlangan diapazondagi barcha xato turlarini to'ldirdingiz",
    SaveError: "Saqlash",
    GrammarErrorTitle: "Grammatika",
    ResultTitle: "Ijodiy topshiriq natijalari",
    Student: "Talaba",
    Teacher: "O'qituvchi",
    CurrentVersionTime: "Joriy versiya: {{time}}",
    TaskText: "Vazifa",
    HasErrors: "Xatolar bor",
    WithoutErrors: "Xatolarsiz bajarildi",
    UpdateSavedTeacher: "Sizning o'zgarishlaringiz saqlandi",
    UpdateSavedStudent: "Qoralamangiz saqlandi",
    UpdateSubmitedTeacher: "Tekshiruv natijalari talabaga yuborildi",
    UpdateSubmitedStudent: "Sizning tahrirlaringiz o'qituvchiga yuborildi",
    Checking: "Tekshirilmoqda",
    Done: "Bajarildi",
    FixStudent: "tuzatish",
    FixTeacher: "Xatolar bor",
    CheckingTime: "tekshirish • {{time}}",
    DoneTime: "bajarildi • {{time}}",
    TeacherName: "O'qituvchi: {{name}}",
    StudentName: "Talaba: {{name}}",
    AllStatuses: "Barcha holatlar",
    Checked: "Tekshirildi",
    NewCheck: "Tekshirilmoqda",
    Processing: "Qayta ishlash",
    StudentSearch: "Talabani qidirish",
    TeacherSearch: "O'qituvchini qidiring",
    EmptyList: "Ayni paytda tegishli vazifalar yo'q",
  },
};

export default uz;
