import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  ModalContainer,
  ModalBody,
  TopHeader,
  DefaultText,
  SectionBox,
  PrimaryInlineText,
  DangerInlineText,
  TitleText,
  SmallText,
  StudentName,
  StudentsBox,
} from "./styledComponents";
import { Icon } from "../Icon";

export interface ModalGroupReportProps {
  isOpen: boolean;
  onClose: () => void;
  onDownload?: () => void;
  onStudentClick?: (_: number) => void;
  groupName?: string;
  classPeriod?: string | null;
  teacherName?: string;
  courseName?: string;
  schedule?: string[];
  totalHours?: number;
  completedHours?: number;
  leftHours?: number;
  lateCustomerCancelHours?: number;
  lateSchoolCancelHours?: number;
  compensationHours?: number;
  earlyCustomerCancelHours?: number;
  earlySchoolCancelHours?: number;
  students?: string[];
}

export const ModalGroupReport = ({
  isOpen,
  onClose,
  onStudentClick,
  groupName = "",
  classPeriod = "",
  teacherName = "",
  courseName = "",
  schedule = [],
  totalHours = 0,
  completedHours = 0,
  leftHours = 0,
  lateCustomerCancelHours = 0,
  lateSchoolCancelHours = 0,
  compensationHours = 0,
  earlyCustomerCancelHours = 0,
  earlySchoolCancelHours = 0,
  students = [],
}: ModalGroupReportProps): JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();

  const clickStudent = (index: number) => () => {
    onStudentClick?.(index);
  };

  return (
    <ModalContainer disableScrollLock={false} open={isOpen} onClose={onClose}>
      <ModalBody>
        <Box
          sx={{ position: "absolute", top: 15, right: 15, cursor: "pointer" }}
          onClick={onClose}
        >
          <Icon
            color={theme.palette.custom.grey.dark}
            type="close"
            width={20}
            height={20}
          />
        </Box>
        <TopHeader>
          <Icon
            type="groupReportPresentation"
            color="#69DAF2"
            width={34}
            height={34}
          />
        </TopHeader>
        <TitleText color="#5783F1">{groupName}</TitleText>
        <Box height={5} />
        <SmallText>{classPeriod}</SmallText>
        <Box height={11} />
        <SectionBox bgcolor="#E8F8E4">
          {teacherName && (
            <DefaultText fontWeight="bold">
              {t("ModalGroupReport:Teacher")}: {teacherName}
            </DefaultText>
          )}
          {courseName && (
            <>
              <Box height={12} />
              <DefaultText>
                {t("ModalGroupReport:Course")}:{" "}
                <PrimaryInlineText>{courseName}</PrimaryInlineText>
              </DefaultText>
            </>
          )}
          {schedule.length > 0 && (
            <>
              <Box height={5} />
              <DefaultText>
                {t("ModalGroupReport:Schedule")}:{" "}
                <PrimaryInlineText>{schedule.join(", ")}</PrimaryInlineText>
              </DefaultText>
            </>
          )}
        </SectionBox>
        <Box height={11} />
        <SectionBox bgcolor="#FFF5E7">
          <DefaultText>
            {t("ModalGroupReport:TotalHours")}:{" "}
            <PrimaryInlineText>
              {totalHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
          <Box height={5} />
          <DefaultText>
            {t("ModalGroupReport:CompletedHours")}:{" "}
            <PrimaryInlineText>
              {completedHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
          <Box height={5} />
          <DefaultText>
            {t("ModalGroupReport:LeftHours")}:{" "}
            <PrimaryInlineText>
              {leftHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
        </SectionBox>
        <Box height={11} />
        <SectionBox bgcolor="#FFF5E7">
          <DefaultText>
            {t("LessonStatuses:lateCanceledByCustomer")}:{" "}
            <DangerInlineText>
              {lateCustomerCancelHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </DangerInlineText>
          </DefaultText>
          <Box height={5} />
          <DefaultText>
            {t("LessonStatuses:lateCanceledBySchool")}:{" "}
            <PrimaryInlineText>
              {lateSchoolCancelHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
          <Box height={5} />
          <DefaultText>
            {t("ModalGroupReport:Compensation")}:{" "}
            <PrimaryInlineText>
              {compensationHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
          <Box height={5} />
          <DefaultText>
            {t("LessonStatuses:earlyCanceledByCustomer")}:{" "}
            <PrimaryInlineText>
              {earlyCustomerCancelHours.toFixed(2)}{" "}
              {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
          <Box height={5} />
          <DefaultText>
            {t("LessonStatuses:earlyCanceledBySchool")}:{" "}
            <PrimaryInlineText>
              {earlySchoolCancelHours.toFixed(2)} {t("ModalGroupReport:Hours")}
            </PrimaryInlineText>
          </DefaultText>
        </SectionBox>
        <Box height={11} />
        {students.length > 0 && (
          <SectionBox>
            <TitleText>{t("ModalGroupReport:Students")}:</TitleText>
            <Box height={11} />
            <StudentsBox>
              {students.map((student, index) => (
                <StudentName key={student} onClick={clickStudent(index)}>
                  {student}
                </StudentName>
              ))}
            </StudentsBox>
          </SectionBox>
        )}
      </ModalBody>
    </ModalContainer>
  );
};
