import { RefObject } from "react";

import { Box, Typography } from "@mui/material";
import { Notification as INotification } from "@packages/store/models/Notifications/NotificationModel";
import DefaultNotificationAvatar from "assets/img/logo-avatar.png";
import { Avatar } from "components/Avatar";
import { JetLink } from "components/JetLink";
import { ROUTES } from "router/constants";

interface NotificationProps {
  notification: INotification;
  intersectingRef?: RefObject<Element>;
}

export function Notification({
  notification,
  intersectingRef,
}: NotificationProps): JSX.Element | null {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        padding: 4,
        bgcolor: "#fff",
      }}
    >
      <Avatar
        src={DefaultNotificationAvatar}
        alt="Notification avatar"
        width={40}
        height={40}
      />
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" gap="10px">
          <Typography variant="menu" color="primary.main">
            {notification.date}
          </Typography>
          <Typography variant="menu" color="primary.main">
            {notification.time}
          </Typography>
        </Box>
        <Typography sx={{ mb: 1 }} variant="regularText">
          {notification.subject}
        </Typography>
        <Box ref={intersectingRef}>
          <Typography variant="smallText">
            Для просмотра результата пройдите по{" "}
            <JetLink
              to={`${ROUTES.CREATIVE_TASKS}?taskId=${notification.taskId}`}
              newTab
              external
            >
              ссылке
            </JetLink>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
