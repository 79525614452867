import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

export function filterGroupsByCompanies(
  groups: LearningGroup[],
  companyIds: string[]
): LearningGroup[] {
  return groups.filter((item) => {
    return companyIds.includes(item.customerCompany?.id ?? "");
  });
}
