import { TeacherRate } from "@packages/store/models/TeacherRate/TeacherRate";

export const formatRate = (rate: TeacherRate) => {
  return {
    id: rate.id,
    languageId: rate.languageId,
    classType: rate.classType,
    amount: rate.amount,
    activeFrom: rate.activeFrom,
  };
};

export type FormattedRate = ReturnType<typeof formatRate>;
