import { styled } from "@mui/material/styles";

export const NumberCard = styled("div")(({ color }) => ({
  width: "1.5rem",
  height: "1.5rem",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "0.300rem",
  border: `0.06rem solid ${color}`,
  color,
}));
