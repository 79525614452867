/* eslint-disable @typescript-eslint/no-empty-interface */
import { uniqBy } from "lodash";
import {
  Instance,
  SnapshotOut,
  IAnyModelType,
  getRoot,
  types,
} from "mobx-state-tree";

import { RootStore } from "..";
import {
  PivotReportStatistics,
  PivotReport,
  PivotReportSchedule,
  PivotReportStudent,
} from "../../services/Api";
import { getLessonDurationInAcademicHours } from "../../utils/helpers";
import { filterLessonsByDateRange } from "../../utils/helpers/filterLessonsByDateRange";
import { ClassGroup, ClassGroupModel } from "../ClassGroup/ClassGroup";
import { ClassSchedule } from "../ClassSchedule/ClassSchedule";
import { CourseModel } from "../Course/CourseModel";
import { CustomerCompanyModel } from "../CustomerCompany/CustomerCompanyModel";
import { withEnvironment } from "../extensions/withEnvironment";
import { Lesson } from "../Lesson/LessonModel";
import { StudentModel, Student } from "../Student/Student";
import { Teacher, TeacherModel } from "../Teacher/Teacher";

export type DateRange = {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
};

export const LearningGroupModel = types
  .model({
    id: types.identifier,
    code: types.maybeNull(types.string),
    createdAt: types.maybeNull(types.Date),
    customerCompany: types.maybeNull(
      types.late(() => types.reference(CustomerCompanyModel))
    ),
    customerCompanyId: types.maybeNull(types.number),
    distributorId: types.maybeNull(types.number),
    languageId: types.maybeNull(types.string),
    learningCoursesLineupId: types.maybeNull(types.number),
    licenseTypeId: types.maybeNull(types.number),
    licenseVarietyId: types.maybeNull(types.number),
    licensesPackageId: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    updatedAt: types.maybeNull(types.Date),
    flexible: types.maybeNull(types.boolean),
    courses: types.optional(
      types.array(types.late(() => types.reference(CourseModel))),
      []
    ),
    // Это группы которые приходят когда запрашиваем группы с уроками
    classGroups: types.optional(
      types.array(
        types.reference(types.late((): IAnyModelType => ClassGroupModel))
      ),
      []
    ),
    // Это группы которые приходят когда запрашиваем группы без уроков
    relatedClassGroups: types.optional(
      types.array(
        types.reference(types.late((): IAnyModelType => ClassGroupModel))
      ),
      []
    ),
    teachers: types.optional(
      types.array(types.late(() => types.reference(TeacherModel))),
      []
    ),
    students: types.array(StudentModel),
    restrictiveDate: types.maybeNull(types.Date),
    unlicensed: types.maybeNull(types.boolean),
  })
  .extend(withEnvironment)
  .views((self) => ({
    /* Бывает так в teachers приходит один и тот же препод но привязан он к разным курсам (learning_course_id)
    в группе препод всё равно получается один */
    get uniqueTeachers() {
      return uniqBy(self.teachers, "id");
    },
    get lessons(): Lesson[] {
      return self.classGroups.flatMap((classGroup) => classGroup.lessons);
    },
  }))
  .views((self) => ({
    get currentTeacher(): Teacher | undefined {
      return self.teachers.find((teacher) => teacher.excludedAt === null);
    },
    get lessonsPeriod() {
      const dates = self.lessons
        .map((lesson) => {
          return lesson.startAtLocal?.getTime() || 0;
        })
        .filter((date) => date > 0);
      if (dates.length === 0) {
        if (self.courses) {
          const minDate = self.courses
            .map((c) => c.startDate)
            .sort((a, b) => (b?.getTime() || 0) - (a?.getTime() || 0))[0];
          return { from: minDate, to: null };
        }
      }
      const from = new Date(Math.min(...dates));
      const to = new Date(Math.max(...dates));
      return { from, to };
    },
  }))
  .views((self) => ({
    get isActive() {
      return !["filling", "archived", "disbanded"].includes(
        String(self.status)
      );
    },
  }))
  .views((self) => ({
    getLessonByStatuses(statuses: Array<Lesson["type"]>) {
      return self.lessons.filter((lesson) => statuses.includes(lesson.status));
    },
  }))
  .views((self) => ({
    //! Тут исключаются компенсации
    get completedLessonsDuration() {
      return self.lessons
        .filter((lesson) => lesson.isCompleted && !lesson.compensation)
        .reduce((duration, lesson) => duration + lesson.durationInMinutes, 0);
    },
  }))
  .views((self) => ({
    get limitSeconds(): number {
      return self.classGroups.reduce(
        (sum, { totalMinutes }) => sum + Number(totalMinutes),
        0
      );
    },
  }))
  .views((self) => ({
    get limitMinutes(): number {
      return self.limitSeconds / 60;
    },
  }))
  .views((self) => ({
    isDurationAdditionPossible(additionInMinutes: number) {
      const expectTotalDuration =
        Math.round((self.completedLessonsDuration + additionInMinutes) * 100) /
        100;

      return expectTotalDuration <= self.limitMinutes;
    },

    getLessonsCountInDateRange(dateRange?: DateRange) {
      const completedLessons = self.lessons.filter(
        (lesson) => lesson.isCompleted
      );

      const filteredLessons = filterLessonsByDateRange(
        completedLessons,
        dateRange
      );

      return filteredLessons.length;
    },

    getLessonsDuration(dateRange?: DateRange) {
      const getLessonsDuration = (lessons: Lesson[]) => {
        return lessons
          .filter((lesson) => !lesson.compensation)
          .reduce((total, lesson) => {
            return total + lesson.exactDuration;
          }, 0);
      };

      const completedLessons = self.lessons.filter(
        (lesson) => lesson.isCompleted
      );

      const filteredLessons = filterLessonsByDateRange(
        completedLessons,
        dateRange
      );

      const totalHours = getLessonDurationInAcademicHours(self.limitSeconds);
      const completedHours = getLessonsDuration(filteredLessons);
      let leftHours = 0;

      if (completedLessons.length !== filteredLessons.length && dateRange) {
        const { endDate } = dateRange ?? {};

        const allCompletedHours = getLessonsDuration(
          filterLessonsByDateRange(completedLessons, {
            endDate,
          })
        );
        leftHours = totalHours - allCompletedHours;
      } else {
        leftHours = totalHours - completedHours;
      }

      return {
        totalHours,
        completedHours,
        leftHours,
        completedLessonsCountInThisPeriod: filteredLessons.length,
      } as const;
    },
  }))
  .views((self) => ({
    get statistics(): PivotReportStatistics {
      const getTeachers = () => {
        return self.uniqueTeachers
          .map((teacher) => `${teacher.fullName}`)
          .join(", ");
      };

      const getLessonsDuration = (lessons: Lesson[]) => {
        return lessons
          .filter((lesson) => !lesson.compensation)
          .reduce((total, lesson) => {
            return total + lesson.exactDuration;
          }, 0);
      };

      const getCourses = () => {
        return self.courses.map((course) => course.nameTranslated).join(", ");
      };

      const { totalHours, completedHours, leftHours } =
        self.getLessonsDuration();

      const lateCustomerCancelLessons = self.getLessonByStatuses([
        "lateCanceledByCustomer",
      ]);
      const lateCustomerCancelHours = getLessonsDuration(
        lateCustomerCancelLessons
      );
      const lateSchoolCancelLessons = self.getLessonByStatuses([
        "lateCanceledBySchool",
      ]);
      const lateSchoolCancelHours = getLessonsDuration(lateSchoolCancelLessons);
      const earlyCustomerCancelLessons = self.getLessonByStatuses([
        "earlyCanceledByCustomer",
      ]);
      const earlyCustomerCancelHours = getLessonsDuration(
        earlyCustomerCancelLessons
      );
      const earlySchoolCancelLessons = self.getLessonByStatuses([
        "earlyCanceledBySchool",
      ]);
      const earlySchoolCancelHours = getLessonsDuration(
        earlySchoolCancelLessons
      );
      const compensationLessons = self.lessons.filter(
        (lesson) => lesson.compensation
      );
      const compensationHours = compensationLessons.reduce(
        (acc, lesson) => acc + lesson.duration,
        0
      );

      const students = self.students.map(
        (student) => `${student.firstName} ${student.lastName}`
      );

      const schedules = self.classGroups.flatMap(
        (classGroup) => classGroup.scheduleTimes
      ) as PivotReportSchedule[];

      return {
        teacherName: getTeachers(),
        schedules,
        courses: getCourses(),
        classPeriod: self.lessonsPeriod,
        totalHours,
        completedHours,
        leftHours,
        lateCustomerCancelHours,
        lateSchoolCancelHours,
        compensationHours,
        earlyCustomerCancelHours,
        earlySchoolCancelHours,
        students,
      };
    },
  }))
  .views((self) => ({
    findSimilarStudents(student: Student | null) {
      const { students = [] } = self;
      return students.filter(
        (similarStudent) =>
          similarStudent?.firstName === student?.firstName &&
          similarStudent?.lastName === student?.lastName
      );
    },
  }))
  .views((self) => ({
    get pivotReport(): PivotReport {
      const {
        auth: { user },
      } = getRoot<RootStore>(self);

      const { isTeacher } = user ?? {};

      const schedules = self.classGroups.flatMap(
        ({ classSchedules }) => classSchedules
      ) as ClassSchedule[];

      const scheduleLessons = schedules
        .filter(({ teachers }) => {
          return isTeacher ? teachers.includes(user?.id ?? "") : true;
        })
        .flatMap((schedule) => schedule.lessons);

      const lessons = uniqBy(scheduleLessons, ({ id }) => id).sort(
        (first, second) => {
          const firstDay = first.day?.getTime() ?? 0;
          const secondDay = second.day?.getTime() ?? 0;
          return firstDay < secondDay ? -1 : 1;
        }
      ) as Lesson[];

      const students: PivotReportStudent[] =
        uniqBy(
          self.students,
          ({ firstName = "", lastName = "" }) => `${firstName} ${lastName}`
        ).map((student) => {
          const similarStudents = self.findSimilarStudents(student);
          return {
            ids: similarStudents.map(({ studentId = 0 }) => Number(studentId)),
            name: `${student?.lastName} ${student?.firstName}`,
          };
        }) ?? [];

      const scheduleTimes = self.classGroups.flatMap(
        ({ scheduleTimes }) => scheduleTimes
      );

      const duration = lessons.reduce(
        (acc, lesson) => acc + lesson.exactDuration,
        0
      ) as number;

      return {
        students,
        scheduleTimes,
        lessons,
        duration,
      };
    },
  }));

type LearningGroupModelType = Instance<typeof LearningGroupModel>;
export interface LearningGroup extends LearningGroupModelType {}
type LearningGroupModelTypeSnapshotType = SnapshotOut<
  typeof LearningGroupModel
>;
export type LearningGroupSnapshot = LearningGroupModelTypeSnapshotType;
