import { Box, Typography } from "@mui/material";

export interface ProgressCardProps {
  current?: number;
  total?: number;
  label?: string | null;
  background?: string;
  children?: JSX.Element;
  onLabelClick?: () => void;
}

export function ProgressCard({
  current,
  total,
  label,
  background,
  children,
  onLabelClick,
}: ProgressCardProps): JSX.Element {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        overflow: "hidden",
        alignItems: "flex-end",
        padding: "1.5rem",
        flex: 1,
        borderRadius: "0.5rem",
        boxShadow: "box-shadow: 6px 13px 20px rgba(7, 7, 7, 0.1)",
        background: "#899ecc",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "150%",
          aspectRatio: "1 / 1",
          transform: "translate(-50%, -50%) rotate(90deg);",
          zIndex: 0,
          background,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "flex-end",
          height: "100%",
          zIndex: 1,
        }}
      >
        <Box>
          <Box display="flex" alignItems="center">
            <Typography
              color="#5678ff"
              variant="h1"
              fontSize="1.5rem"
              lineHeight="1.5rem"
            >
              {current}
            </Typography>
            <Typography color="white" fontSize="1.5rem" lineHeight="1.5rem">
              /{total}
            </Typography>
          </Box>
          <Typography
            pt="1rem"
            onClick={onLabelClick}
            sx={{
              color: "white",
              fontSize: "0.8rem",
              lineHeight: "0.8rem",
            }}
          >
            {label}
          </Typography>
        </Box>
      </Box>
      <Box>{children}</Box>
    </Box>
  );
}
