import { Box, Theme, SxProps } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { observer } from "mobx-react-lite";

import { Message } from "./Message";

export interface ChatItem {
  text: string;
  audioSrc: string;
}

interface DialogChatProps {
  items: ChatItem[];
  containerStyles?: SxProps<Theme> | undefined;
}

export const DialogChat = observer(
  ({ items, containerStyles }: DialogChatProps): JSX.Element | null => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          alignItems: "flex-start",
          "& > div:nth-of-type(even)": {
            alignSelf: "flex-end",
          },
          ...containerStyles,
        }}
      >
        {items.map((item, i) => (
          <Message
            key={item.text}
            direction={i % 2 ? "right" : "left"}
            {...item}
          />
        ))}
      </Box>
    );
  }
);
