import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MyModulesTopContent = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, "auto"),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  marginTop: theme.spacing(4),
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));
