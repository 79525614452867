import { styled } from "@mui/material";

export const SocialLinksContainer = styled("div")(({ theme }) => ({
  flexBasis: "6rem",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),

  "& a": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
}));
