import { Typography, TypographyProps, styled } from "@mui/material";

export const StyledInputAutoresize = styled((props: TypographyProps) => (
  <Typography
    variant="regularText"
    component="span"
    contentEditable
    suppressContentEditableWarning
    {...props}
  />
))(({ theme }) => ({
  display: "inline-block",
  minHeight: "1.5rem",
  minWidth: 50,
  padding: theme.spacing(2, 3),
  wordBreak: "break-word",
  wordWrap: "break-word",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.custom.grey.light}`,
  borderRadius: theme.spacing(3),

  "&:focus": {
    outline: "none",
  },
}));
