import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProgressDefault = styled(LinearProgress)(({ theme }) => ({
  height: "0.125rem",
  borderRadius: "0.125rem",
  flex: 1,
  backgroundColor: theme.palette.custom?.grey?.["emenu-tile-active"],
  ".MuiLinearProgress-bar": {
    backgroundColor: theme.palette.success.main,
    borderRadius: "0.125rem",
  },
}));
