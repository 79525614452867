import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

interface MenuItemProps {
  isActive: boolean;
}

export const MenuItem = styled(Link)<MenuItemProps>(({ theme, isActive }) => ({
  textDecoration: "none",
  width: "100%",
  minWidth: "5.7rem",
  height: "6rem",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "0.7rem",
  backgroundColor: theme.palette.custom?.grey?.lightGray,
  cursor: "pointer",
  justifyContent: "center",
  padding: "0 0.4rem",
  "& .MuiTypography-root": {
    color: theme.palette.custom?.grey?.main,
  },
  "&:hover": {
    "& .MuiTypography-root": {
      color: theme.palette.primary.dark,
    },
    "& svg": {
      color: theme.palette.primary.dark,
      "& path": {
        stroke: theme.palette.primary.dark,
      },
      "& line": {
        stroke: theme.palette.primary.dark,
      },
    },
  },
  "& svg": {
    color: theme.palette.custom?.grey?.main,
    "& path": {
      stroke: theme.palette.custom?.grey?.main,
    },
    "& line": {
      stroke: theme.palette.custom?.grey?.main,
    },
  },

  ...(isActive && {
    backgroundColor: theme.palette.primary.contrastText,
    position: "relative",
    "& .MuiTypography-root": {
      color: theme.palette.primary.dark,
    },
    "& svg": {
      color: theme.palette.primary.dark,
      "& path": {
        stroke: theme.palette.primary.dark,
      },
      "& line": {
        stroke: theme.palette.primary.dark,
      },
    },
  }),
}));
