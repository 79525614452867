import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { JetLink } from "../JetLink";

interface BrokenExerciseProps {
  linkToOldInterface: string;
}

export const BrokenExercise = ({
  linkToOldInterface,
}: BrokenExerciseProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Box display="flex" justifyContent="center">
      <Typography color="error.main">
        {t("Exercise:BrokenError")}{" "}
        <JetLink to={linkToOldInterface} external>
          {t("Exercise:BrokenErrorLink")}
        </JetLink>
      </Typography>
    </Box>
  );
};
