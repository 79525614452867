import { styled } from "@mui/material/styles";

export const UserMenuContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "& .MuiTypography-root": {
    margin: theme.spacing(0, 3),
    color: theme.palette.primary.contrastText,
  },
  "& svg": {
    flex: "none",
    marginRight: theme.spacing(0),
  },
}));
