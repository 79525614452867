/* eslint-disable @typescript-eslint/no-shadow */
import { useState } from "react";

import { Box, Typography } from "@mui/material";
import { groupBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Cell } from "react-table";

import { useStores } from "@packages/store/models";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { BlockContainer } from "components/Footer/styledComponents";
import { LessonStatusesTeacher } from "components/LessonStatusesTeacher";
import { Table } from "components/Table";
import { OnlyFlexibleSwitch } from "pages/Teacher/MainTeacher/components/OnlyFlexibleSwitch";
import { ROUTES } from "router/constants";

import {
  filterByDate,
  filterByEditable,
  filterByFlexible,
  filterByProperty,
} from "./helpers/filterLesson";
import { formatDate } from "./helpers/formatDate";
import { sortByDate, sortByProperty } from "./helpers/sortLesson";
import { LessonCredentials } from "./LessonCredentials";
import { TeacherMyClassesCell } from "./stylesComponents";

interface TeacherMyClassRow {
  [key: string]: unknown;
}

export const TeacherMyClasses = observer((): JSX.Element => {
  const [isOnlyFlexible, setIsOnlyFlexible] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { lesson: lessonStore } = useStores();

  const teacherLessons = lessonStore.teacherLessons.filter(
    filterByEditable(true)
  );

  let lessons: Lesson[] = [];

  if (isOnlyFlexible) {
    const flexibleAndPlannedLessons = teacherLessons
      .filter(filterByFlexible(true))
      .filter(filterByProperty("currentStatus", "planned"));

    const firstLessonsFromEachGroup = Object.values(
      groupBy(flexibleAndPlannedLessons, "groupId")
    ).map((group) => group.sort(sortByProperty("index", "desc"))[0]);

    lessons = firstLessonsFromEachGroup.sort(sortByDate("desc"));
  } else {
    const lessonsFilteredByDate = teacherLessons.filter(filterByDate);

    const plannedLessons = lessonsFilteredByDate.filter(
      filterByProperty("currentStatus", "planned")
    );
    const unplannedLessons = lessonsFilteredByDate.filter(
      filterByProperty("currentStatus", "planned", false)
    );

    lessons = [
      ...plannedLessons.sort(sortByDate("desc")),
      ...unplannedLessons.sort(sortByDate("desc")),
    ];
  }

  const data = lessons.map((lesson) => ({
    id: lesson.id,
    group: lesson.group?.name,
    dateTime: formatDate(lesson.startAtLocal),
    duration: lesson.exactDuration,
    // TODO явно тут не верно
    status: lesson,
    // TODO явно тут не верно
    credentials: lesson,
  }));

  const showAttendanceModal = (id: string) => () => {
    const lesson = data[+id] as TeacherMyClassRow | undefined;
    if (lesson) {
      navigate(`${ROUTES.ATTENDANCE}/${lesson.id}`);
    }
  };

  const handleOnlyFlexibleToggle = () => setIsOnlyFlexible((p) => !p);

  const columns = [
    {
      Header: t("TeacherMyClasses:group"),
      accessor: "group",
      filter: "group",
      Cell: ({ row, value }: Cell<Lesson, string>) => (
        <Box onClick={showAttendanceModal(row.id)}>
          <TeacherMyClassesCell variant="buttonUnderlined">
            {value}
          </TeacherMyClassesCell>
        </Box>
      ),
    },
    {
      Header: t("TeacherMyClasses:status"),
      accessor: "status",
      filter: "status",
      Cell: ({ row, value }: Cell<(typeof data)[0], Lesson>) => {
        // eslint-disable-next-line no-nested-ternary
        const styles: React.CSSProperties | undefined = value.isPrematurely
          ? {
              backgroundColor: "#FF6161",
              borderColor: "#FF6161",
              color: "white",
            }
          : undefined;
        return (
          <Box onClick={showAttendanceModal(row.id)}>
            <LessonStatusesTeacher styles={styles} type={value.currentStatus} />
          </Box>
        );
      },
    },
    {
      Header: t("TeacherMyClasses:dateTime"),
      accessor: "dateTime",
      Cell: ({ value }: { value: string }) => (
        <TeacherMyClassesCell>{value}</TeacherMyClassesCell>
      ),
    },
    {
      Header: t("Classes:Duration"),
      accessor: "duration",
      Cell: ({ value }: { value: string }) => {
        return <TeacherMyClassesCell>{value}</TeacherMyClassesCell>;
      },
    },
    {
      Header: t("TeacherMyClasses:webinarCredentials"),
      accessor: "credentials",
      Cell: ({ value }: { value: Lesson }) => (
        <TeacherMyClassesCell fontSize={13}>
          <LessonCredentials lesson={value} />
        </TeacherMyClassesCell>
      ),
    },
  ];

  return (
    <BlockContainer>
      <Typography variant="h1" component="span">
        {t(`StartPageTeacher:myClasses`)}
      </Typography>
      <OnlyFlexibleSwitch
        active={isOnlyFlexible}
        onToggle={handleOnlyFlexibleToggle}
      />
      {data.length ? (
        <Box my="2.5rem">
          <Table
            columns={columns}
            data={data}
            count={10}
            containerType="blue"
          />
        </Box>
      ) : (
        t(`TeacherMyClasses:StandardHelper`)
      )}
    </BlockContainer>
  );
});
