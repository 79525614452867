import { Box, BoxProps, styled } from "@mui/material";

interface TaskTextProps extends BoxProps {
  variant?: "default" | "confirmation";
}

export const TaskText = styled(Box, {
  shouldForwardProp: (prop) => prop !== "variant",
})<TaskTextProps>(({ theme, variant = "default" }) => ({
  "ul, ol": {
    display: "block",
    margin: theme.spacing(3, 0),
    paddingLeft: theme.spacing(5),
  },

  li: {
    display: "list-item",
  },

  ...(variant === "confirmation" && {
    paddingBottom: theme.spacing(4),
    textAlign: "center",
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
}));
