import { useEffect } from "react";

import { Alert, Box, Input, SelectChangeEvent, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Loading } from "components/Loading";
import {
  CreateDistributorReportData,
  ValueWithLabel,
} from "@packages/store/services/Api";

import { FilterListSelect, FilterListSelectProps } from "./FilterListSelect";
import { FormActions } from "./FormActions";
import { GroupsList, GroupsListProps } from "./GroupsList";
import { Header } from "./Header";
import {
  ModalBody,
  ModalContainer,
  CustomSelect,
  Column,
  Row,
  BetweenLine,
  DatesContent,
  DatesContainer,
} from "./styledComponents";

export interface CreateReportModalProps {
  title: string;
  defaultDate: Pick<CreateDistributorReportData, "from" | "to">;
  types: ValueWithLabel[];
  apiError: string | null;
  type: string;
  dateRange?: boolean;
  loading?: boolean;
  submitLoading?: boolean;
  filterProps: FilterListSelectProps;
  groupProps: Omit<
    GroupsListProps,
    "selectedGroups" | "onSelectedGroupsChange"
  >;
  onTypeChange: (_: string) => void;
  onSubmit: (data: CreateDistributorReportData) => void;
  onClose: () => void;
}

export const CreateReportModal = ({
  title,
  defaultDate,
  apiError,
  types,
  type,
  dateRange,
  loading = false,
  submitLoading = false,
  filterProps,
  groupProps,
  onTypeChange,
  onSubmit,
  onClose,
}: CreateReportModalProps): JSX.Element => {
  const { t } = useTranslation();

  const handleTypeChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string;
    onTypeChange(value);
  };

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<CreateDistributorReportData>({
    defaultValues: {
      title: "Отчет",
      type,
      ...defaultDate,
      groups: [],
    },
  });

  useEffect(() => {
    setValue("type", type);
  }, [type, setValue]);

  return (
    <ModalContainer open onClose={onClose}>
      <ModalBody>
        <Header title={title} onClose={onClose} />
        <Row mb="1rem">
          <Column>
            <CustomSelect
              data={types}
              value={type}
              menuItems={{
                sx: {
                  fontSize: "0.8rem",
                },
              }}
              onChange={handleTypeChange}
              placeholder=""
            />
            <Input
              type="text"
              placeholder={t("CreateReport:ReportTitlePlaceholder") ?? ""}
              error={!!errors.title}
              {...register("title", { required: true })}
            />
          </Column>
          <DatesContainer>
            <DatesContent>
              <TextField
                type="date"
                variant="outlined"
                label={
                  dateRange
                    ? t("CreateReport:FromDate")
                    : t("CreateReport:Date")
                }
                error={!!errors.from}
                InputLabelProps={{ shrink: true }}
                {...register("from", { required: true })}
              />
              {dateRange && (
                <>
                  <BetweenLine />
                  <TextField
                    type="date"
                    variant="outlined"
                    label={t("CreateReport:ToDate")}
                    error={!!errors.to}
                    InputLabelProps={{ shrink: true }}
                    {...register("to", { required: true })}
                  />
                </>
              )}
            </DatesContent>
          </DatesContainer>
        </Row>
        <Loading loading={loading}>
          <>
            <Box mb="1rem">
              <FilterListSelect {...filterProps} />
            </Box>
            <Box mb="1rem">
              <Controller
                control={control}
                name="groups"
                render={({ field: { value, onChange } }) => (
                  <GroupsList
                    {...groupProps}
                    selectedGroups={value}
                    onSelectedGroupsChange={onChange}
                  />
                )}
              />
            </Box>
          </>
        </Loading>
        {apiError && (
          <Box mb="1rem">
            <Alert severity="error">{apiError}</Alert>
          </Box>
        )}
        <FormActions
          submitLoading={submitLoading}
          onSubmit={handleSubmit(onSubmit)}
          onCancel={onClose}
        />
      </ModalBody>
    </ModalContainer>
  );
};
