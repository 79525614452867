import { styled, Box } from "@mui/material";

export const MyModulesContainer = styled(Box)(({ theme }) => ({
  margin: `1.5rem 0 ${theme.spacing(4)} 0`,
  display: "flex",
  flexWrap: "wrap",
  columnGap: theme.spacing(3),
  "&:after": {
    content: `" "`,
    flex: "1 0 49%",
    flexBasis: "49%",
    flexGrow: 1,
  },
}));
