import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActiveGroupsContainer } from "./styledComponents/ActiveGroupsContainer";
import { Filters } from "../../Groups";
import { DatePicker } from "../DatePicker";

export type DateRange = {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
};

export interface GroupDateRangeFilterProps {
  methods: UseFormReturn<Filters>;
}

export const GroupDateRangeFilter = observer(
  ({ methods }: GroupDateRangeFilterProps): JSX.Element => {
    const { t } = useTranslation();

    return (
      <ActiveGroupsContainer>
        <Box display="flex" gap="1rem" flexWrap="wrap">
          <Controller
            control={methods.control}
            name="startDate"
            render={({ field }) => (
              <DatePicker
                value={field.value ?? null}
                onChange={field.onChange}
                label={t("GroupFilter:StartDate")}
              />
            )}
          />

          <Controller
            control={methods.control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                value={field.value ?? null}
                onChange={field.onChange}
                label={t("GroupFilter:EndDate")}
              />
            )}
          />
        </Box>
      </ActiveGroupsContainer>
    );
  }
);
