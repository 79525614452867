/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance, IAnyModelType } from "mobx-state-tree";
import { CustomerCompanyModel } from "../CustomerCompany/CustomerCompanyModel";
import { CounterModel } from "./Counter";
import { Status } from "./CancellationReport";

export const CountersByCompanyModel = types
  .model("CountersByCompanyModel")
  .props({
    company: types.maybeNull(
      types.late(() =>
        types.reference(types.late((): IAnyModelType => CustomerCompanyModel))
      )
    ),
    counters: types.optional(types.array(CounterModel), []),
  })
  .views((self) => ({
    getHours(status: Status): number {
      return (
        self.counters.find((counter) => counter.status === status)?.hours ?? 0
      );
    },
    getLessons(status: Status): number {
      return (
        self.counters.find((counter) => counter.status === status)?.lessons ?? 0
      );
    },
  }));

type CountersByCompanyModelType = Instance<typeof CountersByCompanyModel>;
export interface CountersByCompany extends CountersByCompanyModelType {}
type CountersByCompanySnapshotType = SnapshotOut<typeof CountersByCompanyModel>;
export type CountersByCompanySnapshot = CountersByCompanySnapshotType;
