import { styled } from "@mui/material";

import { Checkbox, CheckboxProps } from "../../../components/Checkbox";

export const StyledCheckbox = styled(
  (props: CheckboxProps) => <Checkbox size="small" {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "status",
  }
)(({ theme }) => ({
  marginRight: theme.spacing(3),
  padding: 0,
  color: `${theme.palette.info.main} !important`,
}));
