import { styled, Box } from "@mui/material";

export const LicenseBody = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "1rem",
  padding: "20px 20px 0",
  marginBottom: "30px",
}));
