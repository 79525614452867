import { styled } from "@mui/material";

export const ButtonContainer = styled("footer")(() => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  "& span": {
    flex: 1,
    textAlign: "center",
    whiteSpace: "pre",
  },
}));
