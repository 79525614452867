import { useEffect, useState } from "react";

import { RootStore } from "@packages/store/models";

export const useSuccess = (store: RootStore) => {
  const [openSuccessSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    // open snackpar if store error
    if (store.success) {
      setOpenSnackBar(true);
    }
  }, [openSuccessSnackBar, store.success]);

  const closeSuccessSnackBar = () => {
    // clear errror in store after message show in snackbar
    store.clearSuccess();
    setOpenSnackBar(false);
  };

  return { openSuccessSnackBar, closeSuccessSnackBar };
};
