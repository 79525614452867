import { Box, styled } from "@mui/material";

export const SectionsScroll = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  overflow: "hidden",
  flex: 1,
}));
