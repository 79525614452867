/* eslint-disable consistent-return */
import { useEffect } from "react";

interface UseInactivityReloadProps {
  inactiveTimeLimit?: number;
  active?: boolean;
}

export function useInactivityReload(props: UseInactivityReloadProps = {}) {
  const { active = false, inactiveTimeLimit = 1 * 60 * 60 * 1000 } = props;
  useEffect(() => {
    if (!active) return;

    function handleBeforeUnload() {
      localStorage.setItem("lastActiveTime", String(new Date().getTime()));
    }

    function handleVisibilityChange() {
      if (document.visibilityState === "visible") {
        const currentTime = new Date().getTime();
        const lastActiveTime = Number(localStorage.getItem("lastActiveTime"));
        const inactiveTime = currentTime - lastActiveTime;
        handleBeforeUnload();
        console.log("inactiveTime", inactiveTime);
        if (inactiveTime >= inactiveTimeLimit) {
          // Refresh the current route
          window.location.reload();
        }
      }
    }
    // Listen for changes to the visibility state and beforeunload event
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Store the current time when the component mounts
    localStorage.setItem("lastActiveTime", String(new Date().getTime()));

    return () => {
      // Clean up event listeners and inactivity timer
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      localStorage.setItem("lastActiveTime", String(new Date().getTime()));
    };
  }, [active, inactiveTimeLimit]);
}
