import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import { HoverContext } from '../utils/context';
import { EMPTY_WORD_INDEX } from '../utils/constants';

interface HoverableWordProps {
  index: number;
  children?: ReactNode;
  className?: string;
}

export const HoverableWord = observer((props: HoverableWordProps) => {
  const { index, children, className } = props;
  const {
    setHoveredError,
    setHoveredWordIndex
  } = useContext(HoverContext);

  const handleWordHover = useCallback(() => {
    setHoveredWordIndex?.(index);
  }, [
    index,
    setHoveredWordIndex
  ]);

  const clearWordHover = useCallback(() => {
    setHoveredWordIndex?.(EMPTY_WORD_INDEX);
    setHoveredError?.(undefined);
  }, [
    setHoveredWordIndex
  ]);

  return (
    <span
      onMouseEnter={handleWordHover}
      onMouseLeave={clearWordHover}
      className={className}
    >
      {children}
    </span>
  )
})
