import { Box, useTheme } from "@mui/material";
import {
  eachMonthOfInterval,
  endOfYear,
  format,
  getMonth,
  isSameMonth,
  startOfYear,
} from "date-fns";

import { getCurrentLocale } from "locales/dateFnsLocale";

import { CalendarCell } from "./styledComponents";

interface CalendarMonthsProps {
  currentDate: Date;
  onMonthChange: (monthIndex: number) => void;
}

export const CalendarMonths = ({
  currentDate,
  onMonthChange,
}: CalendarMonthsProps): JSX.Element | null => {
  const theme = useTheme();

  const months = eachMonthOfInterval({
    start: startOfYear(currentDate),
    end: endOfYear(currentDate),
  });

  const monthBg = (month: Date) => {
    if (isSameMonth(month, new Date())) {
      return `${theme.palette.primary.main}!important`;
    }

    if (isSameMonth(month, currentDate)) {
      return `${theme.palette.info.main}!important`;
    }

    return "transparent";
  };
  const monthColor = (month: Date) => {
    if (isSameMonth(month, currentDate) || isSameMonth(month, new Date())) {
      return theme.palette.primary.contrastText;
    }

    return theme.palette.common.black;
  };

  return (
    <Box
      width="222px"
      height="222px"
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gap="2px"
    >
      {months.map((date, i) => (
        <CalendarCell
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          isMonth
          onClick={() => onMonthChange(getMonth(date))}
          sx={{
            backgroundColor: monthBg(date),
            color: monthColor(date),
          }}
        >
          {format(date, "MMM", { locale: getCurrentLocale() })}
        </CalendarCell>
      ))}
    </Box>
  );
};
