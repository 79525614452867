/* eslint-disable max-len */
import { useState } from "react";

import { Alert, Box, Snackbar } from "@mui/material";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import { Icon } from "components/Icon";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { CredentialLine } from "./stylesComponents";

export type LessonCredentialsProps = {
  lesson: Lesson;
};

export const LessonCredentials = observer(
  ({ lesson }: LessonCredentialsProps): JSX.Element => {
    const { t } = useTranslation();
    const [, copy] = useCopyToClipboard();
    const [showNotify, setShowNotify] = useState(false);
    const { credentials } = lesson;

    const hideNotify = () => {
      setShowNotify(false);
    };

    const showCredentials = async () => {
      lesson.getCredentials();
    };

    const copyToClipboard = (credential: string | null) => () => {
      if (!credential) {
        return;
      }
      setShowNotify(true);
      copy(credential);
    };

    if (!credentials) {
      return (
        <CredentialLine
          onClick={showCredentials}
          sx={{
            justifyContent: "center",
          }}
        >
          <Icon type="eye" width={24} height={24} />
        </CredentialLine>
      );
    }

    const Line = ({ credential }: { credential: string | null }) => (
      <CredentialLine onClick={copyToClipboard(credential)}>
        {credential} <Icon type="copy" width={15} height={15} />
      </CredentialLine>
    );

    const Notification = () => (
      <Snackbar
        open={showNotify}
        autoHideDuration={1000}
        onClose={hideNotify}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={hideNotify} severity="success">
          {t("LessonCredentials:copied")}
        </Alert>
      </Snackbar>
    );

    return (
      <Box>
        <Notification />
        <Line credential={credentials?.login} />
        <Line credential={credentials?.password} />
      </Box>
    );
  }
);
