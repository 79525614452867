import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const ScriptItemModel = types.model({
  id: types.identifier,
  text: types.maybeNull(types.string),
  timeStart: types.maybeNull(types.number),
  timeEnd: types.maybeNull(types.number),
});

type ScriptItemModelType = Instance<typeof ScriptItemModel>;
type ScriptItemModelSnapshotType = SnapshotOut<typeof ScriptItemModel>;

export type ScriptItem = ScriptItemModelType;
export type ScriptItemSnapshot = ScriptItemModelSnapshotType;
