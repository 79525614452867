import { Box, styled } from "@mui/material";

import { tablet } from "../../../constants/styles";

export const StyledContentView = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(4),

  [theme.breakpoints.down(tablet)]: {
    flexDirection: "column",
    paddingBottom: theme.spacing(5),
  },
}));
