/* eslint-disable @typescript-eslint/no-empty-interface */
import { Box } from "@mui/material";
import { Answer } from "@packages/store/models/ExerciseAnswer/Answer";
import { observer } from "mobx-react-lite";

import { MovingQuestionProps } from "./MovingQuestion";
import { ColumnTitle } from "./styledComponents/ColumnTitle";
import { getItemStatus } from "../../helpers/getItemStatus";
import { AnswerView } from "../AnswerView";
import { ObserverFlexList } from "../FlexList";
import { MovingDropzoneColumn } from "../MovingDropzone";

interface MovingQuestionColumnProps extends MovingQuestionProps {}

export const MovingQuestionColumn = observer(
  ({ question, answerData }: MovingQuestionColumnProps): JSX.Element | null => {
    const { id, text, answerPlaces } = question;

    const { answers } = answerData;

    const place = answerPlaces.slice()[0];

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          borderRadius: 3,
        }}
      >
        <ColumnTitle dangerouslySetInnerHTML={{ __html: String(text) }} />

        <MovingDropzoneColumn
          question={id}
          place={place}
          status={getItemStatus(answerData)}
        >
          <ObserverFlexList
            items={answers}
            renderItem={(answer: Answer) => (
              <AnswerView
                key={answer.id}
                title={String(answer.text)}
                status={answer.isChecked ? getItemStatus(answer) : "current"}
                {...(!answer.isChecked && {
                  onRemove: answer.remove,
                })}
                styles={{
                  mr: "auto",
                }}
              />
            )}
          />
        </MovingDropzoneColumn>
      </Box>
    );
  }
);
