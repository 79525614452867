import { Box, BoxProps, Typography, useTheme } from "@mui/material";

interface BoxWithLegendProps extends BoxProps {
  legend: string;
}

export const BoxWithLegend = ({
  legend,
  children,
  sx,
  ...other
}: BoxWithLegendProps): JSX.Element | null => {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      sx={{
        p: "0.5rem 1rem",
        border: `1px solid ${theme.palette.info.main}`,
        borderRadius: "1rem",
        ...sx,
      }}
      {...other}
    >
      <Typography
        variant="button"
        sx={{
          bgcolor: theme.palette.info.contrastText,
          color: theme.palette.info.main,
          lineHeight: 1,
          p: "0 0.3rem",
          position: "absolute",
          top: "-1px",
          left: "5%",
          transform: "translateY(-50%)",
        }}
      >
        {legend}
      </Typography>
      {children}
    </Box>
  );
};
