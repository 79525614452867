const API_URL = "";

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number;

  // Идентификатор клиента для подключения к апи
  clientId: string;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: API_URL,
  timeout: 60000,
  clientId: "0ccf427a-02dc-4c5e-b948-519eb70c19d1",
};
