/* eslint-disable @typescript-eslint/no-empty-interface */

import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { NotificationModel } from "./NotificationModel";

export const NotificationGroupModel = types.model("NotificationGroupModel", {
  source: types.maybeNull(types.string),
  humanSource: types.maybeNull(types.string),
  priority: types.maybeNull(types.number),
  read: types.maybeNull(types.boolean),
  unreadCount: types.maybeNull(types.number),
  notifications: types.maybeNull(types.array(NotificationModel)),
});

type NotificationGroupType = Instance<typeof NotificationGroupModel>;
export interface NotificationGroup extends NotificationGroupType {}
type NotificationGroupSnapshotType = SnapshotOut<typeof NotificationGroupModel>;
export type NotificationGroupSnapshot = NotificationGroupSnapshotType;
