import { forwardRef, useState } from "react";

import { TextField, TextFieldProps } from "@mui/material";

import { Icon } from "components/Icon";

export const PasswordField = forwardRef(({ ...props }: TextFieldProps, ref) => {
  const [isPlain, setIsPlain] = useState(false);

  const toggleIsPlain = () => {
    setIsPlain(!isPlain);
  };

  return (
    <TextField
      ref={ref as React.RefObject<HTMLInputElement>}
      type={isPlain ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <Icon
            type={isPlain ? "passwordHide" : "passwordShow"}
            width={22}
            height={19}
            stroke="#000000"
            onClick={toggleIsPlain}
          />
        ),
      }}
      {...props}
    />
  );
});
