/* eslint-disable @typescript-eslint/no-empty-interface */
import { IAnyModelType, Instance, SnapshotOut, types } from "mobx-state-tree";
import { LearningGroupModel } from "../LearningGroups/LearningGroupModel";

export const SurchargeModel = types.model({
  id: types.identifier,
  amount: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  activeFrom: types.maybeNull(types.Date),
  group: types.maybeNull(
    types.reference(types.late((): IAnyModelType => LearningGroupModel))
  ),
});

type SurchargeModelType = Instance<typeof SurchargeModel>;
export interface Surcharge extends SurchargeModelType {}
type SurchargeModelTypeSnapshotType = SnapshotOut<typeof SurchargeModel>;
export type SurchargeSnapshot = SurchargeModelTypeSnapshotType;
