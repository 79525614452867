import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { StyledButton } from "../styledComponents/StyledButton";
import { StyledPaper } from "../styledComponents/StyledPaper";

interface TestItemsProps {
  unitOrder: number;
  unitName: string;

  percentage: number;
  totalProgress: number;

  onConfirm: () => void;
}

export const TestItems = observer(
  ({
    unitOrder,
    unitName,
    percentage,
    totalProgress,
    onConfirm,
  }: TestItemsProps): JSX.Element | null => {
    const { t } = useTranslation();

    return (
      <StyledPaper
        sx={{
          padding: "0.75rem 1rem 0.625rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="largeText" color="primary.main">
          {t("ResultsModals:TestUnit", {
            order: unitOrder,
            name: unitName,
          })}
        </Typography>

        <Box display="flex" flexDirection="column">
          <Typography variant="largeText" color="custom.grey.accentText">
            {t("ResultsModals:TestUnitTotalProgress")}{" "}
            <Typography variant="largeText" color="info.main">
              {totalProgress}%
            </Typography>
          </Typography>
          <Typography variant="largeText" color="custom.grey.accentText">
            {t("ResultsModals:TestUnitResults")}{" "}
            <Typography variant="largeText" color="success.main">
              {percentage}%
            </Typography>
          </Typography>
        </Box>

        <StyledButton colorVariant="continue" onClick={onConfirm}>
          {t("ResultsModals:TestUnitNext")}
        </StyledButton>
      </StyledPaper>
    );
  }
);
