import { styled } from "@mui/material/styles";

import { Percent } from "./Percent";

export const PercentDefault = styled(Percent)(({ theme }) => ({
  color: theme.palette.info.main,
  fontWeight: 500,
  marginLeft: theme.spacing(1),
  lineHeight: "1.375rem",
  whiteSpace: "nowrap",
}));
