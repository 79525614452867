import { useTheme, useMediaQuery } from "@mui/material";

interface breakpoints {
  types: "zeroScreen" | "mobile" | "tablet" | "laptop" | "desktop";
}

export const useCurrentBreakpoint = (): breakpoints["types"] => {
  const theme = useTheme();

  const isMobile = useMediaQuery(
    theme.breakpoints.between("zeroScreen", "tablet")
  );
  const isTablet = useMediaQuery(theme.breakpoints.between("tablet", "laptop"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("laptop"));

  if (isMobile) return "zeroScreen";
  if (isDesktop) return "desktop";
  if (isTablet) return "tablet";

  return "desktop";
};
