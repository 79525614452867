export const lineHeight = 3;

export const additionalContentWidth = 368;

export const minAdditionalContentWidth = 100;

export const maxAdditionalContentWidth = 500;

export const maxScrollHeight = 200;

export const mobile = 480;

export const tablet = 992;
