import { Typography } from "@mui/material";

import {
  TooltipTeachersContainer,
  ContentContainer,
  AvatarContainer,
  TitleContainer,
  TitleNameContainer,
  TitleСompetenceContainer,
  BlockContainer,
} from "./styledComponents";
import { AvatarPerson } from "../AvatarPerson";

export interface TeachersProps {
  name: string;
  post: string;
}

export interface TooltipTeachersProps {
  dataTeachers: TeachersProps[];
}

export const TooltipTeachers = ({
  dataTeachers,
}: TooltipTeachersProps): JSX.Element => {
  return (
    <TooltipTeachersContainer>
      <BlockContainer>
        {dataTeachers?.map((item, i) => {
          const id = i;
          return (
            <ContentContainer key={id}>
              <AvatarContainer>
                <AvatarPerson name={item.name} />
              </AvatarContainer>
              <TitleContainer>
                <TitleNameContainer>
                  <Typography variant="regularText">{item.name}</Typography>
                </TitleNameContainer>
                <TitleСompetenceContainer>
                  <Typography variant="smallText">{item.post}</Typography>
                </TitleСompetenceContainer>
              </TitleContainer>
            </ContentContainer>
          );
        })}
      </BlockContainer>
    </TooltipTeachersContainer>
  );
};
