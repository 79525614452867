import { PivotReportStudent } from "@packages/store/services/Api";

export function getFilteredStudentsByName(
  students: PivotReportStudent[],
  name?: string | undefined
): PivotReportStudent[] {
  return students.filter((student) => {
    if (!name) {
      return true;
    }
    return student.name.toLowerCase().includes(name.trim().toLowerCase());
  });
}
