export interface PivotTableMonth {
  month: number;
  year: number;
  columns: number;
}

export function getPivotTableMonths(dates: Date[]) {
  return dates.reduce((acc, date) => {
    const month = date.getMonth() + 1;
    const monthInfo = acc.find((item) => item.month === month);
    if (monthInfo) {
      return acc.map((item) => {
        return item === monthInfo
          ? {
              ...monthInfo,
              columns: monthInfo.columns + 1,
            }
          : item;
      });
    }
    return [
      ...acc,
      {
        month,
        columns: 1,
        year: date.getFullYear(),
      },
    ];
  }, [] as PivotTableMonth[]);
}
