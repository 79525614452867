import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledNotificationsHeader = styled(Box)(({ theme }) => ({
  height: "50px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.custom.grey.border}`,
  padding: theme.spacing(0, 4),
}));
