import React, { useCallback, useContext, useMemo } from 'react';

import { observer } from 'mobx-react';
import cn from "classnames";

import styles from './CorrectedWord.module.scss';

interface CorrectedWordProps {
  text: string;
  onClear?: () => void;
}

export const CorrectedWord = observer((props: CorrectedWordProps) => {
  const {
    text,
    onClear
  } = props;
  return (
    <span className={styles.word}>
      {text}
      <span
        className={styles['word-clear']}
        onClick={onClear}
      >
        X
      </span>
    </span>
  );
})
