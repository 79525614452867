/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import { flow, getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";

import { Unit, UnitModel } from '../Unit/UnitModel';
import { RootStore } from "../RootStore/RootStore";

/**
 * Rick and Morty character model.
 */

const Translation = types
  .model("TranslatedWordTranslation")
  .props({
    translation: types.maybeNull(types.string),
    langId: types.maybeNull(types.number),
  })

export const TranslatedWordModel = types
  .model("TranslatedWord")
  .props({
    id: types.identifier,
    word: types.maybeNull(types.string),
    langId: types.maybeNull(types.number),
    unitId: types.maybeNull(types.string),
    pictureUrl: types.maybeNull(types.string),
    translations: types.optional(types.array(Translation), [])
  })
  .views((self) => ({
    get unit(): Unit | undefined {
      const { unit } = getRoot<RootStore>(self)
      const { items } = unit;
      return items.find(({ id }) => id === self.unitId);
    }
  }))

type TranslatedWordType = Instance<typeof TranslatedWordModel>;
export type TranslatedWord = TranslatedWordType;
type TranslatedWordSnapshotType = SnapshotOut<typeof TranslatedWordModel>;
export type TranslatedWordSnapshot = TranslatedWordSnapshotType;
