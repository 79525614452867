import { Component, ErrorInfo, ReactNode } from "react";

import { StoreMismatchError } from "../../types";
import { ErrorHandler } from "./ErrorHandler";

interface Props {
  children?: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    ErrorHandler.setup();
    this.state = {
      hasError: false,
    };
  }

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  invalidateCache() {
    const storeKey = "jetclass";
    const store = JSON.parse(window.localStorage.getItem(storeKey) ?? '{}');
    const { auth = {} } = store;
    window.localStorage.removeItem(storeKey);
    window.localStorage.setItem(storeKey, JSON.stringify({ auth }));
    window.location.reload();
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (error instanceof StoreMismatchError) {
      this.invalidateCache()
    }
    ErrorHandler.logError(error);
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}
