/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { LearningGroup } from "../LearningGroups/LearningGroupModel";

import { Course, CourseModel, CourseSnapshot } from "./CourseModel";
import { withEnvironment } from "../extensions/withEnvironment";
import { CURRENT_STUDENT_COURSE_ID_KEY } from "../../constants/constants";

const getStoredCurrentStudentCourseId = () => {
  if (typeof window === "undefined") {
    return "";
  }
  return window?.localStorage?.getItem(CURRENT_STUDENT_COURSE_ID_KEY) ?? "";
};

export const CourseStoreModel = types
  .model("CourseStore")
  .props({
    items: types.optional(types.array(CourseModel), []),
    currentStudentCourseId: types.optional(
      types.string,
      getStoredCurrentStudentCourseId()
    ),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: CourseSnapshot) => {
      const instance = resolveIdentifier(CourseModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .actions((self) => ({
    rememberCurrentStudentCourseId(courseId: string) {
      window?.localStorage?.setItem(CURRENT_STUDENT_COURSE_ID_KEY, courseId);
      self.currentStudentCourseId = courseId;
    },
  }))
  .views((self) => ({
    get currentStudentCourse() {
      return self.items.find(({ id }) => id === self.currentStudentCourseId);
    },
  }))
  .views((self) => ({
    get activeCoursesWithoutGroup(): Course[] {
      return self.items.filter(
        ({ hasGroup, tags }) => !hasGroup && tags.includes("All")
      );
    },
    getCourse(id: string | undefined): Course | undefined {
      return self.items.find((item) => item.id === id);
    },
    getCoursesByGroup(group: LearningGroup, { active = true } = {}) {
      return self.items
        .filter((course) => group.courses.find(({ id }) => course.id === id))
        .filter(({ showInCourseList }) => !active || showInCourseList);
    },
  }));

type CourseStoreType = Instance<typeof CourseStoreModel>;
export type CourseStore = CourseStoreType;
type CoursesSnapshotType = SnapshotOut<typeof CourseStoreModel>;
export type CourseStoreSnapshot = CoursesSnapshotType;
export const createCourseStoreDefaultModel = () =>
  types.optional(CourseStoreModel, {} as CourseStoreSnapshot);
