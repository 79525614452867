import { useCallback } from "react";

import { observer } from "mobx-react-lite";
import { Outlet, useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { Groups as PackageGroups, Filters } from "@packages/groups";
import { buildSearchParams } from "@packages/shared/utils/searchParams";

import { ROUTES } from "router/constants";

export const Groups = observer((): JSX.Element => {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const showReport = (id: string) => {
    navigate(`/groups/${id}`);
  };

  const syncSearchParams = useCallback(
    (data: Filters) => {
      if (pathname !== ROUTES.GROUPS_PAGE) return;

      const params = buildSearchParams(data);

      if (params.toString() !== searchParams.toString()) {
        setSearchParams(params);
      }
    },
    [pathname, searchParams, setSearchParams]
  );

  return (
    <>
      <PackageGroups
        showReport={showReport}
        searchParams={searchParams}
        syncSearchParams={syncSearchParams}
      />

      <Outlet />
    </>
  );
});
