import { useStores } from "@packages/store";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Table } from "components/Table";
import { TableCell } from "pages/shared/styledComponents";

import { formatGroup, FormattedGroup } from "../../helpers/formatGroup";

interface PayrollGroupsTableProps {
  groups: LearningGroup[];
  onApprove?: (groupId: string) => void;

  page: number;
  onChangePage: (page: number) => void;

  onRequestGroupEdit: (groupId: string) => void;
}

export const PayrollGroupsTable = observer(
  ({
    groups,
    onApprove,
    page,
    onChangePage,
    onRequestGroupEdit,
  }: PayrollGroupsTableProps): JSX.Element | null => {
    const { t } = useTranslation();

    const store = useStores();

    const columns = [
      {
        Header: t("Payroll:GroupsTableName"),
        accessor: "name",
        Cell: ({ value }: Cell<FormattedGroup, string>) => (
          <TableCell>{value}</TableCell>
        ),
      },
      {
        Header: t("Payroll:GroupsTableTeacher"),
        accessor: "teacher",
        Cell: ({ value }: Cell<FormattedGroup, string>) => (
          <TableCell>{value}</TableCell>
        ),
      },
      {
        Header: t("Payroll:GroupsTableClassType"),
        accessor: "classType",
        Cell: ({ value }: Cell<FormattedGroup, string>) => (
          <TableCell>{value}</TableCell>
        ),
      },
      {
        Header: t("Payroll:GroupsTableLang"),
        accessor: "lang",
        Cell: ({ value }: Cell<FormattedGroup, string>) => (
          <TableCell color="custom.grey.main" textTransform="uppercase">
            {value}
          </TableCell>
        ),
      },
      // {
      //   Header: "Носитель",
      //   accessor: "nativeSpeaker",
      //   Cell: ({ value }: Cell<FormattedGroup, string>) => (
      //     <TableCell>
      //       <Typography
      //         variant="button"
      //         bgcolor="info.main"
      //         color="info.contrastText"
      //         padding="1px 8px 3px"
      //         borderRadius="4px"
      //       >
      //         {value}
      //       </Typography>
      //     </TableCell>
      //   ),
      // },
      {
        Header: t("Payroll:GroupsTableRate"),
        accessor: "rate",
        Cell: ({ value }: Cell<FormattedGroup, string>) => (
          <TableCell color="custom.grey.main">{value}</TableCell>
        ),
      },
      {
        Header: "",
        accessor: "edit",
        Cell: ({ row }: Cell<FormattedGroup, string>) => (
          <TableCell
            onClick={() => onRequestGroupEdit(row.original.id)}
            color="info.main"
          >
            <Icon type="edit" sizeSquareIcon={20} />
          </TableCell>
        ),
      },
    ];

    if (onApprove) {
      columns.splice(columns.length - 1, 0, {
        Header: "",
        accessor: "approve",
        Cell: ({ row }: Cell<FormattedGroup, string>) => (
          <TableCell>
            <Button
              onClick={() => onApprove?.(row.original.id)}
              variant="outlined"
              color="info"
            >
              {t("Payroll:GroupsTableApprove")}
            </Button>
          </TableCell>
        ),
      });
    }

    const formattedGroups = computed(() =>
      groups.map((group) => formatGroup(group, store))
    ).get();

    return (
      <Table
        columns={columns}
        data={formattedGroups}
        count={10}
        containerType="blue"
        page={page}
        onChangePage={onChangePage}
      />
    );
  }
);
