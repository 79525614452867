import { styled, Box } from "@mui/material";

export const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(5, 4),

  [theme.breakpoints.down(480)]: {
    padding: theme.spacing(4),
  },

  [theme.breakpoints.down(321)]: {
    alignItems: "flex-start",
  },
}));
