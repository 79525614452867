import { Box, styled } from "@mui/material";

export const IntroWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  maxWidth: "36%",
  backgroundColor: theme.palette.primary.main,
  paddingLeft: "env(safe-area-inset-left)",
  paddingBottom: "env(safe-area-inset-bottom)",

  [theme.breakpoints.down(480)]: {
    display: "none",
    padding: theme.spacing(4),
  },
}));
