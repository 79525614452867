/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import compact from "lodash/compact";
import { types, Instance, SnapshotOut, flow, cast } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";

export const TeacherModel = types
  .model("Teacher", {
    //! Нужно обязательно это учитывать
    // id срощенный с id группы также как у студентов чтобы можно было учитовать поля includedAt и excludedAt
    id: types.identifier,
    // какой id на самом деле
    actualId: types.string,
    firstName: types.maybeNull(types.string),
    middleName: types.maybeNull(types.string),
    lastName: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    photo: types.maybeNull(types.string),
    tags: types.optional(types.array(types.string), []),
    includedAt: types.maybeNull(types.Date),
    excludedAt: types.maybeNull(types.Date),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get fullName() {
      return compact([self.firstName, self.middleName, self.lastName]).join(
        " "
      );
    },
    get fullNameReversed() {
      return compact([self.lastName, self.middleName, self.firstName]).join(
        " "
      );
    },
  }))
  .actions((self) => ({
    updateTags: flow(function* (tagIds: string[]) {
      const response = yield self.environment.api.updateTeacherTags(
        self.actualId,
        tagIds
      );

      if (response.kind !== "ok") {
        return false;
      }

      self.tags = cast(tagIds);

      return true;
    }),
  }));

type TeacherType = Instance<typeof TeacherModel>;
export type Teacher = TeacherType;
type TeacherTypeSnapshotType = SnapshotOut<typeof TeacherModel>;
export type TeacherSnapshot = TeacherTypeSnapshotType;
