import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { CircularProgress } from "components/CircularProgress";
import { ProgressCard } from "components/ProgressCard";

export const MyWordsProgress = observer((): JSX.Element => {
  const { t } = useTranslation();

  const { word: wordStore } = useStores();

  const words = wordStore.items;
  const learned = words.filter((word) => word.isLearned);
  const fromUnits = words.filter((word) => word.isFromUnits);
  const fromUnitsLearned = fromUnits.filter((word) => word.isLearned);
  const addedByMe = words.filter((word) => word.isAddedByMe);
  const addedByMeLearned = addedByMe.filter((word) => word.isLearned);
  const learnProgress =
    words.length > 0 ? (learned.length / words.length) * 100 : 0;

  return (
    <Box display="flex" gap="1rem" flexWrap="wrap">
      <Box flex={1}>
        <ProgressCard
          total={words.length}
          current={learned.length}
          label={t("MyWords:AllWords")}
          background="linear-gradient(180deg, #FEAC5E 0%, #C779D0 50%, #4BC0C8 100%)"
        >
          <CircularProgress
            progress={learnProgress}
            label={t("MyWords:Learned")}
          />
        </ProgressCard>
      </Box>

      <Box display="flex" gap="1rem" flex={1}>
        <ProgressCard
          total={fromUnits.length}
          current={fromUnitsLearned.length}
          label={t("MyWords:WordsFromUnit")}
          background="linear-gradient(180deg, #30E8BF 0%, #FD994F 100%)"
        />

        <ProgressCard
          total={addedByMe.length}
          current={addedByMeLearned.length}
          label={t("MyWords:AddedByMe")}
          background="linear-gradient(180deg, #48DFB2 0%, #7C99D5 100%)"
        />
      </Box>
    </Box>
  );
});
