/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback } from "react";

import { Question } from "@packages/store/models/Question/Question";
import {
  FlexList,
  ObserverFlexList,
} from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { AnswerView } from "../../../../../../components/AnswerView";
import {
  ChoiceQuestion,
  ChoiceSelectProps,
} from "../../../../../../components/ChoiceQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const CheckEmphasis = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, getCorrectAnswer, properties } = exercise;

    const { getQuestion } = ensure(answer);

    const renderSelect = useCallback(
      ({
        options,
        onChange,
        isChecked,
      }: ChoiceSelectProps): JSX.Element | null => {
        const handleClick = (newValue: string) => () => {
          if (isChecked) {
            return;
          }
          onChange(newValue);
        };

        return (
          <FlexList
            direction="row"
            wrap
            items={options}
            renderItem={(option) => (
              <AnswerView
                key={option.value}
                title={option.label}
                status={option.status}
                clickable={!isChecked}
                onClick={handleClick(option.value)}
              />
            )}
          />
        );
      },
      []
    );

    const { multipleAnswers } = properties || {};

    return (
      <ObserverFlexList
        gap={4}
        items={questions}
        renderItem={(question: Question, index, array: Question[]) => (
          <ChoiceQuestion
            key={question.id}
            variant="block"
            question={question}
            answerData={ensure(getQuestion(question.id))}
            correctAnswer={getCorrectAnswer(question.id)}
            renderSelect={renderSelect}
            multipleAnswers={multipleAnswers}
            {...(array.length > 1 && {
              order: index + 1,
            })}
          />
        )}
      />
    );
  }
);
