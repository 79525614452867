import { styled } from "@mui/material";

export const ContentContainer = styled("div")(({ theme }) => ({
  maxWidth: "63.75rem",
  margin: theme.spacing(0, "auto"),
  display: "flex",
  flexWrap: "wrap",
  alignItems: "flex-start",
  justifyContent: "space-between",
  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));
