import {
  Instance,
  resolveIdentifier,
  SnapshotOut,
  types,
} from "mobx-state-tree";

import { ExerciseModel, ExerciseSnapshot } from "./Exercise";

/**
 * Model description here for TypeScript hints.
 */
export const ExerciseStoreModel = types
  .model("ExerciseStore")
  .props({
    items: types.optional(types.array(ExerciseModel), []),
  })
  .actions((self) => ({
    addItem: (item: ExerciseSnapshot) => {
      const instance = resolveIdentifier(ExerciseModel, self, item.id);

      if (instance) {
        Object.assign(instance, item);
        return;
      }

      self.items.push(item);
    },
  }));
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ExerciseStoreType = Instance<typeof ExerciseStoreModel>;
export type ExerciseStore = ExerciseStoreType;
type ExerciseStoreSnapshotType = SnapshotOut<typeof ExerciseStoreModel>;
export type KnowledgeLevelSnapshot = ExerciseStoreSnapshotType;
