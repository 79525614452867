import { ReactNode } from 'react';
import { observer } from 'mobx-react'

import { Text, TextColor, TextVariant } from '../Text';

interface PageItemProps {
  className?: string;
	children?: ReactNode;
  variant?: TextVariant;
  color?: TextColor;
	onClick?: () => void;
}

export const PageItem = observer(function Pagination(props: PageItemProps) {
  const {
    className,
		children,
    variant = 'text-3-medium',
    color = 'wet-asphalt',
		onClick
	} = props

  return (
    <Text
      containerClassName={className}
      variant='text-3-medium'
      color={color}
      onClick={onClick}
    >
      {children}
    </Text>
  )
})
