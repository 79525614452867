import type { Theme } from "@mui/material";

type muiSelectReturn = {
  styleOverrides: {
    select: {
      width: number;
      color: string;
      paddingBottom: string;
    };
  };
};

export const muiSelect = (theme: Theme): muiSelectReturn => ({
  styleOverrides: {
    select: {
      width: 180,
      color: theme.palette.primary.dark,
      paddingBottom: "0.625",
    },
  },
});
