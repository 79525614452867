/* eslint-disable class-methods-use-this */
import * as Sentry from "@sentry/react";
import { sentryConfig } from "../../constants/constants";

export class ErrorHandler {
  static setup() {
    try {
      Sentry.init({
        dsn: sentryConfig.dsn,
        tunnel: sentryConfig.tunnel,
        environment: sentryConfig.environment,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    } catch (e) {
      // console.log("Sentry setup error", e);
    }
  }

  static logError(error: Error) {
    Sentry.captureException(error);
  }
}
