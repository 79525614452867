/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import { flow, Instance, SnapshotOut, types } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { DictionaryWord, WordVocabularySource } from "../../services/Api";

/**
 * Rick and Morty character model.
 */

export const WordModel = types
  .model("Word")
  .props({
    id: types.identifier,
    wordsIdList: types.optional(types.array(types.string), []),
    header: types.maybeNull(types.string),
    translations: types.optional(types.array(types.string), []),
    isLearned: types.maybeNull(types.boolean),
    learnedAt: types.maybeNull(types.Date),
    courseUnitId: types.maybeNull(types.number),
    pathOfSpeech: types.maybeNull(types.string),
    vocabularySourceId: types.maybeNull(types.number),
    dictionary: types.optional(types.array(types.frozen<DictionaryWord>()), []),
    dictionaryLoading: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get isAddedByMe(): boolean {
      return self.vocabularySourceId === WordVocabularySource.custom;
    },
  }))
  .views((self) => ({
    get isFromUnits(): boolean {
      return !!self.courseUnitId && !self.isAddedByMe;
    },
  }))
  .views((self) => ({
    get words() {
      return (
        self.header
          ?.toLowerCase()
          .replace(/([.?,]+)/g, "")
          .split(" ")
          .filter((word) => word.trim().length > 0) ?? []
      );
    },
  }))
  .views((self) => ({
    dictionaryWord(index: number): DictionaryWord | undefined {
      const { words } = self;

      const word = words[index] ?? "";

      return self.dictionary.find((item) => item.word === word);
    },
    get translation() {
      const preliminaryWord = self.translations.join(", ");
      return [...new Set(preliminaryWord.split(", "))].join(", ");
    },
  }))
  .actions((self) => ({
    loadDictionary: flow(function* (wordIndex: number) {
      const { words } = self;

      const word = words[wordIndex] ?? "";

      self.dictionaryLoading = true;

      const dictionaryWord = self.dictionaryWord(wordIndex);
      if (word && !dictionaryWord) {
        const response = yield self.environment.api.getWordTranslation(
          word,
          "en-ru"
        );

        if (response.kind === "ok") {
          const {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            data: { dictionaryWord },
          } = response;

          self.dictionary.push(dictionaryWord);
        }
      }
      self.dictionaryLoading = false;
    }),
  }))
  .actions((self) => ({
    markAsLearned: flow(function* () {
      const responses = yield Promise.all(
        self.wordsIdList.map((id) => self.environment.api.markWorkAsLearned(id))
      );

      const response = responses[0] ?? {};

      if (response.kind !== "ok") {
        return;
      }

      self.isLearned = true;
      self.learnedAt = new Date(response.data.learnedAt);
    }),
  }));

type WordType = Instance<typeof WordModel>;
export type Word = WordType;
type WordSnapshotType = SnapshotOut<typeof WordModel>;
export type WordSnapshot = WordSnapshotType;
export const createWordDefaultModel = () =>
  types.optional(WordModel, {} as WordType);
