/* eslint-disable @typescript-eslint/no-shadow */
import { useMemo } from "react";

import { useMediaQuery, useTheme } from "@mui/material";
import { Question } from "@packages/store/models/Question/Question";
import { observer } from "mobx-react-lite";

import { mobile, tablet } from "../../../../../../constants/styles";
import { ensure } from "../../../../../../helpers/ensure";
import { SubExerciseComponentProps } from "../../../../../../types";
import { AnswerDragLayer } from "../../../../../AnswerDragLayer";
import { ObserverGridList } from "../../../../../GridList";
import { MovingQuestionColumn } from "../../../../../MovingQuestion";
import { PossibleAnswers } from "../../../../../PossibleAnswers";
import { StickySection } from "../../../../../StickySection";

export const Table = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const theme = useTheme();
    const isTouchScreen = useMediaQuery(theme.breakpoints.down(tablet));

    const { answer, questions, possibleAnswers, isChecked } = exercise;

    const { allAnswerIds, getQuestion, insertAnswer } = ensure(answer);

    const columnsTemplate = useMemo(() => {
      if (isTouchScreen) {
        return "1fr";
      }
      const maxColumns = Math.min(questions.length, 3);
      return `repeat(${maxColumns}, 1fr)`;
    }, [isTouchScreen, questions.length]);

    return (
      <div>
        <AnswerDragLayer />

        {!isChecked && (
          <>
            {isTouchScreen ? (
              <StickySection top={5}>
                <PossibleAnswers
                  allAnswerIds={allAnswerIds}
                  answers={possibleAnswers}
                  insertAnswer={insertAnswer}
                />
              </StickySection>
            ) : (
              <PossibleAnswers
                allAnswerIds={allAnswerIds}
                answers={possibleAnswers}
                insertAnswer={insertAnswer}
              />
            )}
          </>
        )}

        <ObserverGridList
          gap={4}
          columnsTemplate={columnsTemplate}
          items={questions}
          renderItem={(question: Question) => (
            <MovingQuestionColumn
              key={question.id}
              question={question}
              answerData={ensure(getQuestion(question.id))}
            />
          )}
        />
      </div>
    );
  }
);
