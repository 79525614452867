import { observer } from "mobx-react-lite";

import {
  Intro,
  LinkDrawer,
  RestorePasswordForm,
  ToastErrorMessage,
} from "./components";
import { Container } from "./styledComponents";

export const RestorePassword = observer((): JSX.Element => {
  return (
    <>
      <Container>
        <Intro />
        <RestorePasswordForm />
        <LinkDrawer />
      </Container>
      <ToastErrorMessage />
    </>
  );
});
