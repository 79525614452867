import { styled, TypographyProps, Typography } from "@mui/material";

import { StatusType } from "../../../../../../../types";

interface StyledOptionProps {
  clickable: boolean;
  status: StatusType;
  withBorder: boolean;
  withoutBorder: boolean;
}

export const StyledOption = styled(
  (props: TypographyProps) => <Typography variant="regularText" {...props} />,
  {
    shouldForwardProp: (prop) =>
      prop !== "withBorder" &&
      prop !== "withoutBorder" &&
      prop !== "clickable" &&
      prop !== "status",
  }
)<StyledOptionProps>(
  ({ theme, clickable, withBorder, withoutBorder, status }) => ({
    display: "inline-block",
    padding: theme.spacing(2, 3),
    borderRight: "1px solid",
    borderRightColor: theme.palette.custom.answerColorVariant.border[status],
    transition: theme.transitions.create(
      ["color", "background-color", "border-color"],
      { duration: 150 }
    ),

    color: theme.palette.custom.answerColorVariant.font[status],
    backgroundColor: theme.palette.custom.answerColorVariant.background[status],

    "&:first-of-type": {
      borderTopLeftRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
    },

    "&:last-of-type": {
      borderRight: "none",
      borderTopRightRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
    },

    ...(withBorder && {
      borderLeft: "1px solid",
      borderLeftColor: theme.palette.custom.answerColorVariant.border[status],
    }),

    ...(withoutBorder && {
      borderRight: "none",
    }),

    ...(clickable && {
      cursor: "pointer",
    }),
  })
);
