/* eslint-disable react-hooks/exhaustive-deps */
import { useState, ChangeEvent, CSSProperties, useEffect } from "react";

import { Box } from "@mui/material";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  HeaderGroup,
  ColumnInstance,
  Cell,
} from "react-table";

import { EmptyTable, EmptyTableType } from "./EmptyTable";
import { Container } from "./styledComponents";
import { BlueContainer } from "./styledComponents/BlueContainer";
import { Wrapper } from "./styledComponents/Wrapper";
import { Icon } from "../Icon";
import { Pagination } from "../Pagination";

const TableStyles = ({
  type,
  children,
}: {
  type: "blue" | "default";
  children: React.ReactNode;
}) => {
  const defaultType = <Container>{children}</Container>;
  const tableTypes: { [key: string]: JSX.Element } = {
    blue: <BlueContainer>{children}</BlueContainer>,
    default: defaultType,
  };
  return tableTypes[type] || defaultType;
};

export type ExtendedColumnInstance = ColumnInstance & {
  cellStyles: (cell: Cell) => CSSProperties;
};

export interface TableProps {
  isSort?: boolean;
  data: Array<{
    [key: string]: unknown;
  }>;
  columns: Array<{
    Header: string | JSX.Element;
    accessor: string;
  }>;
  count: number;
  width?: string;
  containerType?: "blue" | "default";
  emptyTableType?: EmptyTableType;

  page?: number;
  onChangePage?: (page: number) => void;
}

export const Table = ({
  data,
  columns,
  isSort = false,
  count,
  width,
  containerType = "default",
  emptyTableType = "default",
  page: topPage,
  onChangePage,
}: TableProps): JSX.Element => {
  const [innerPaginationPage, setInnerPaginationPage] = useState(0);

  // Если передаётся состояние сверху то использовать его, иначе использовать своё внутреннее
  const paginationPage = topPage ?? innerPaginationPage;
  const setPaginationPage = onChangePage ?? setInnerPaginationPage;

  const defaultColumn = {
    Filter: <div />,
  };
  const defaultPropGetter = () => ({});

  const {
    getTableProps,
    getTableBodyProps,
    getColumnProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
  } = (useTable as any)(
    {
      columns,
      data,
      autoResetPage: false,
      defaultColumn,
      initialState: {
        pageIndex: paginationPage,
        pageSize: count,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const changePage = (newPage: number): void => {
    const selectedPage = newPage ? newPage - 1 : newPage;

    gotoPage(selectedPage);
    setPaginationPage(selectedPage);
  };

  const handleChangePage = (e: ChangeEvent<unknown>, newPage: number): void => {
    if (newPage !== null) {
      changePage(newPage);
    }
  };

  useEffect(() => {
    if (data.length === 0) {
      return;
    }
    const maximumPages = Math.ceil(data.length / count);
    if (paginationPage > maximumPages - 1) {
      changePage(maximumPages);
    }
  }, [data, count, paginationPage, changePage]);

  let content = (
    <tbody {...getTableBodyProps()}>
      {page.map((row: any) => {
        prepareRow(row);
        return (
          <tr {...row.getRowProps()}>
            {row.cells.map((cell: any) => (
              <td
                {...cell.getCellProps([
                  {
                    className: cell.column.className,
                    style:
                      cell.column.style ??
                      (cell.column as ExtendedColumnInstance).cellStyles?.(
                        cell
                      ),
                  },
                  getColumnProps(cell.column),
                  getCellProps(cell),
                ])}
              >
                {cell.render("Cell")}
              </td>
            ))}
          </tr>
        );
      })}
    </tbody>
  );

  if (!data.length && data.length === 0) {
    content = (
      <EmptyTable columnsLength={columns.length} type={emptyTableType} />
    );
  }

  const arrows = (column: any) => {
    if (column.isSorted) {
      return column.isSortedDesc ? (
        <Icon width={15} height={15} type="sortAmountDown" />
      ) : (
        <Icon width={15} height={15} type="sortAmountUp" />
      );
    }
    return "";
  };

  return (
    <Box sx={{ width: width || "100%", maxWidth: "calc(100vw - 2.5rem)" }}>
      <Wrapper>
        <Box {...getTableProps()}>
          {headerGroups.map((group: any) => (
            <Box {...group.getHeaderGroupProps()}>
              {group.headers.map((column: any) => (
                <Box {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        <TableStyles type={containerType} {...getTableProps()}>
          <thead>
            {headerGroups.map((group: any) => (
              <tr {...group.getHeaderGroupProps()}>
                {group.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps(
                      isSort ? column.getSortByToggleProps() : undefined
                    )}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {column.render("Header")}
                      <Box sx={{ marginLeft: "0.625rem" }}>
                        {arrows(column)}
                      </Box>
                    </Box>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {content}
        </TableStyles>
      </Wrapper>
      {data.length > 0 && (
        <Pagination
          page={paginationPage + 1}
          dataLength={data.length}
          pageSize={count}
          onChange={handleChangePage}
        />
      )}
    </Box>
  );
};
