import { Box, BoxProps, styled } from "@mui/material";

interface TabProps extends BoxProps {
  active: boolean;
}

export const Tab = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<TabProps>(({ theme, active }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,

  "&:first-of-type": {
    borderRight: `1px solid ${theme.palette.primary.dark}`,
  },

  ...(active
    ? {
        flex: 1,
      }
    : {
        cursor: "pointer",

        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
}));
