import { types, SnapshotOut, Instance } from "mobx-state-tree";

export const PassingContentModel = types.model({
  id: types.identifier,
  timeLimit: types.maybeNull(types.number),
});

type PassingContentModelType = Instance<typeof PassingContentModel>;
export type PassingContentModel = PassingContentModelType;
type PassingContentModelTypeSnapshotType = SnapshotOut<
  typeof PassingContentModel
>;
export type PassingContentModelSnapshot = PassingContentModelTypeSnapshotType;
