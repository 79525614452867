/* eslint-disable react/no-array-index-key */
import { Label } from "./styledComponents/Label";
import { Line } from "./styledComponents/Line";

export interface IUserMenuItem {
  label: string;
  onClick: () => void;
}

export const UserMenuData = ({
  menuItems,
}: {
  menuItems: IUserMenuItem[];
}): JSX.Element => {
  return (
    <div>
      {menuItems.map(({ label, onClick }, index) =>
        label ? (
          <Label variant="h4" key={index} onClick={() => onClick()}>
            {label}
          </Label>
        ) : (
          <Line key={index} />
        )
      )}
    </div>
  );
};
