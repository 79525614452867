import { styled, Theme, Typography, TypographyProps } from "@mui/material";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";

interface TypeStyles {
  [key: string]: {
    borderColor: string;
    color: string;
    backgroundColor?: string;
  };
}

interface StatusContainerProps {
  theme?: Theme;
  type: Lesson["type"];
}

const getStyles = (type: Lesson["type"]) => {
  const types: TypeStyles = {
    completed: {
      borderColor: "#47B347",
      color: "#47B347",
    },
    planned: {
      borderColor: "#5783F1",
      backgroundColor: "#5783F1",
      color: "white",
    },
    earlyCanceledByCustomer: {
      borderColor: "#FF9A03",
      color: "#FF9A03",
    },
    earlyCanceledBySchool: {
      borderColor: "#FF9A03",
      color: "#FF9A03",
    },
    lateCanceledByCustomer: {
      borderColor: "#FF2E00",
      color: "#FF2E00",
    },
    lateCanceledBySchool: {
      borderColor: "#FF2E00",
      color: "#FF2E00",
    },
  };

  return types[type as string] ?? {};
};

export const StatusContainer = styled(Typography)<
  TypographyProps & StatusContainerProps
>(({ theme, type }) => ({
  width: "fit-content",
  padding: theme?.spacing(1, 3),
  borderRadius: "0.25rem",
  fontSize: 12,
  lineHeight: "1.5rem",
  borderWidth: "0.0625rem",
  borderStyle: "solid",
  ...getStyles(type),
}));
