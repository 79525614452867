import React, { useMemo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import * as Icons from "../../assets/icons";

import styles from "./Icon.module.scss";

interface IconProps {
  containerClassName?: string;
  icon: IconType;
  color?: string;
  stroke?: string;
  width?: number;
  height?: number;
  onClick?: () => void;
  squareSize?: number;
}

type IconDefaultProps = {
  stroke?: string;
  color?: string;
  onClick?: () => void;
  width?: number;
  height?: number;
};

export type IconType =
  | "discovery"
  | "download"
  | "video"
  | "video-slash"
  | "trash"
  | "tick-circle"
  | "tick-circle-active"
  | "tick-circle-done"
  | "down-square"
  | "up-square"
  | "right-square"
  | "notification"
  | "input-error"
  | "input-email"
  | "input-email"
  | "input-eye-close"
  | "input-eye-open"
  | "input-password"
  | "input-person"
  | "input-search"
  | "select-down"
  | "select-up"
  | "phone-kir"
  | "phone-rus"
  | "phone-tjk"
  | "phone-tkm"
  | "phone-uzb"
  | "back-square"
  | "logo-rus"
  | "logo-eng"
  | "prev-arrow"
  | "next-arrow"
  | "display"
  | "back"
  | "calendar"
  | "clock"
  | "filters"
  | "sound"
  | "word-check"
  | "close-filters"
  | "arrow-down"
  | "arrow-up"
  | "close-modal"
  | "checkbox-check"
  | "copy"
  | "profile"
  | "select-arrows"
  | "sort-direction"
  | "mobile-menu-burger"
  | "mobile-menu-close"
  | "mobile-menu-back"
  | "menu-additional"
  | "menu-courses"
  | "menu-lexis"
  | "menu-links"
  | "menu-main"
  | "menu-notifications"
  | "menu-teacher"
  | "menu-video"
  | "envelope"
  | "eye"
  | "eye-closed"
  | "exercise-grammar"
  | "exercise-reading"
  | "exercise-speaking"
  | "exercise-video"
  | "exercise-vocabulary"
  | "exercise-writting"
  | "calendar"
  | "task-micro"
  | "task-book"
  | "task-book-alt"
  | "accordion"
  | "grammar"
  | "vocubalury"
  | "comments"
  | "edit"
  | "add"
  | "play"
  | "stop"
  | "arrow-right";

export const Icon = observer(function Icon(props: IconProps) {
  const {
    containerClassName,
    icon,
    color,
    stroke,
    onClick,
    width: iconWidth,
    height: iconHeight,
    squareSize,
  } = props;

  const containerClasses = cn(styles.container, containerClassName, {
    [styles.clickable]: !!onClick,
  });

  const width = iconWidth ?? squareSize;
  const height = iconHeight ?? squareSize;

  const defaultProps: IconDefaultProps = useMemo(
    () => ({
      stroke,
      color,
      ...(width && { width }),
      ...(height && { height }),
    }),
    [stroke, color, width, height]
  );

  const IconView: Record<IconType, JSX.Element> = useMemo(
    () => ({
      discovery: <Icons.Discovery {...defaultProps} />,
      download: <Icons.Download {...defaultProps} />,
      video: <Icons.Video {...defaultProps} />,
      "video-slash": <Icons.VideoSlash {...defaultProps} />,
      trash: <Icons.Trash {...defaultProps} />,
      "tick-circle": <Icons.TickCircle {...defaultProps} />,
      "tick-circle-active": <Icons.TickCircleActive {...defaultProps} />,
      "tick-circle-done": <Icons.TickCircleDone {...defaultProps} />,
      "down-square": <Icons.DownSquare {...defaultProps} />,
      "up-square": <Icons.UpSquare {...defaultProps} />,
      "right-square": <Icons.RightSquare {...defaultProps} />,
      notification: <Icons.Notification {...defaultProps} />,
      "input-error": <Icons.InputError {...defaultProps} />,
      "input-email": <Icons.InputEmail {...defaultProps} />,
      "input-eye-close": <Icons.InputEyeClose {...defaultProps} />,
      "input-eye-open": <Icons.InputEyeOpen {...defaultProps} />,
      "input-password": <Icons.InputPassword {...defaultProps} />,
      "input-person": <Icons.InputPerson {...defaultProps} />,
      "input-search": <Icons.InputSearch {...defaultProps} />,
      "select-down": <Icons.SelectDown {...defaultProps} />,
      "select-up": <Icons.SelectUp {...defaultProps} />,
      "phone-kir": <Icons.PhoneKir {...defaultProps} />,
      "phone-rus": <Icons.PhoneRus {...defaultProps} />,
      "phone-tjk": <Icons.PhoneTjk {...defaultProps} />,
      "phone-tkm": <Icons.PhoneTkm {...defaultProps} />,
      "phone-uzb": <Icons.PhoneUzb {...defaultProps} />,
      "back-square": <Icons.BackSquare {...defaultProps} />,
      "logo-rus": <Icons.LogoRus {...defaultProps} />,
      "logo-eng": <Icons.LogoEng {...defaultProps} />,
      "prev-arrow": <Icons.PrevArrow {...defaultProps} />,
      "next-arrow": <Icons.NextArrow {...defaultProps} />,
      "arrow-down": <Icons.ArrowDown {...defaultProps} />,
      "arrow-up": <Icons.ArrowUp {...defaultProps} />,
      display: <Icons.Display {...defaultProps} />,
      back: <Icons.Back {...defaultProps} />,
      calendar: <Icons.Calendar {...defaultProps} />,
      clock: <Icons.Clock {...defaultProps} />,
      filters: <Icons.Filters {...defaultProps} />,
      sound: <Icons.Sound {...defaultProps} />,
      "word-check": <Icons.WordCheck {...defaultProps} />,
      "close-filters": <Icons.CloseFilters {...defaultProps} />,
      "close-modal": <Icons.CloseModal {...defaultProps} />,
      "checkbox-check": <Icons.CheckboxCheck {...defaultProps} />,
      copy: <Icons.Copy {...defaultProps} />,
      profile: <Icons.Profile {...defaultProps} />,
      "select-arrows": <Icons.SelectArrows {...defaultProps} />,
      "sort-direction": <Icons.SortDirection {...defaultProps} />,
      "mobile-menu-burger": <Icons.MobileMenuBurger {...defaultProps} />,
      "mobile-menu-close": <Icons.MobileMenuClose {...defaultProps} />,
      "mobile-menu-back": <Icons.MobileMenuBack {...defaultProps} />,
      "menu-additional": <Icons.MenuAdditional {...defaultProps} />,
      "menu-courses": <Icons.MenuCourses {...defaultProps} />,
      "menu-lexis": <Icons.MenuLexis {...defaultProps} />,
      "menu-links": <Icons.MenuLinks {...defaultProps} />,
      "menu-main": <Icons.MenuMain {...defaultProps} />,
      "menu-notifications": <Icons.MenuNotifications {...defaultProps} />,
      "menu-teacher": <Icons.MenuTeacher {...defaultProps} />,
      "menu-video": <Icons.MenuVideo {...defaultProps} />,
      envelope: <Icons.Envelope {...defaultProps} />,
      eye: <Icons.Eye {...defaultProps} />,
      "eye-closed": <Icons.EyeClosed {...defaultProps} />,
      "exercise-grammar": <Icons.ExerciseGrammar {...defaultProps} />,
      "exercise-reading": <Icons.ExerciseReading {...defaultProps} />,
      "exercise-speaking": <Icons.ExerciseSpeaking {...defaultProps} />,
      "exercise-video": <Icons.ExerciseVideo {...defaultProps} />,
      "exercise-vocabulary": <Icons.ExerciseVocabulary {...defaultProps} />,
      "exercise-writting": <Icons.ExerciseWritting {...defaultProps} />,
      "task-micro": <Icons.TaskMicro {...defaultProps} />,
      "task-book": <Icons.TaskBook {...defaultProps} />,
      "task-book-alt": <Icons.TaskBookAlt {...defaultProps} />,
      accordion: <Icons.Accordion {...defaultProps} />,
      grammar: <Icons.Grammar {...defaultProps} />,
      vocubalury: <Icons.Vocubalury {...defaultProps} />,
      comments: <Icons.Comments {...defaultProps} />,
      edit: <Icons.Edit {...defaultProps} />,
      add: <Icons.Add {...defaultProps} />,
      play: <Icons.Play {...defaultProps} />,
      stop: <Icons.Stop {...defaultProps} />,
      "arrow-right": <Icons.arrowRight {...defaultProps} />,
    }),
    [defaultProps]
  );

  return (
    <div className={containerClasses} onClick={onClick}>
      {IconView[icon]}
    </div>
  );
});
