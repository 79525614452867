import { Box, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";

import { StyledPaper } from "../styledComponents/StyledPaper";

interface TestSummaryProps {
  totalPoints: number;
  scoredPoints: number;
  percentage: number;
}

export const TestSummary = observer(
  ({
    totalPoints,
    scoredPoints,
    percentage,
  }: TestSummaryProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const data = [
      {
        value: scoredPoints,
        color: theme.palette.success.main,
      },
      {
        value: totalPoints - scoredPoints,
        color: theme.palette.custom.grey.accentText,
      },
    ];

    return (
      <StyledPaper
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(170px, 1fr))",
          gap: "1rem",
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box maxWidth="250px" maxHeight="250px" position="relative">
            <PieChart lineWidth={35} data={data} />
            <Typography
              variant="largeText"
              color="success.main"
              position="absolute"
              textAlign="center"
              sx={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {t("ResultsModals:TestUnitScoredPoints", {
                count: scoredPoints,
                total: totalPoints,
              })}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="largeText" color="custom.grey.accentText">
            {t("ResultsModals:TestUnitResultTitle")}
            <br />
            <Typography variant="largeText" color="success.main">
              {percentage} %
            </Typography>
          </Typography>
        </Box>
      </StyledPaper>
    );
  }
);
