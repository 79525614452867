import React, { ReactNode, useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react'
import { useTranslation } from "react-i18next";

import styles from '../TaskDetails.module.scss';

import { Text } from '../../Text';
import { useEffect } from 'react';
import { Loading } from '../../Loading';

import { Answer } from '../Answer';
import { Actions } from '../Actions';
import { Statistics } from '../Statistics';
import { ErrorPreivews } from '../ErrorPreivews';
import { Comment } from '../Comment';

import {
	TaskContext,
	HoverContext,
	ErrorCreateContext,
	UserRoleContext,
	UserRoleContextProps,
	HoverContextProps,
	ErrorCreateContextProps,
	TaskContextProps,
  CorrectionCreateContextProps,
  CorrectionCreateContext
} from '../utils/context';
import { getUniqueErrors } from '../utils/helpers';
import { UserRole, WordRange } from '../utils/types';
import { EMPTY_WORD_INDEX } from '../utils/constants';

import { ExerciseErrorType, ExerciseResultError } from '@packages/store/models/ExerciseResult/ExerciseResultError';
import { ExerciseResultPassing } from '@packages/store/models/ExerciseResult/ExerciseResultPassing';
import { ExerciseResultCorrection } from '@packages/store/models/ExerciseResult/ExerciseResultCorrection';
import { ExerciseResultType, ExerciseResultUpdateData } from '@packages/store/models/ExerciseResult/ExerciseResult';
import { Audio } from '../../Audio';

interface AnswerWrapperProps {
  type?: ExerciseResultType;
  comment: string;
	errors: ExerciseResultError[];
	corrections: ExerciseResultCorrection[];
	passings: ExerciseResultPassing[];
  hasErrors?: boolean;
  detailsLoading: boolean;
  updateLoading: boolean;
  submitLoading: boolean;
  isTeacher: boolean;
  onSubmit: (_: ExerciseResultUpdateData) => void;
  onUpdate: (_: ExerciseResultUpdateData) => void;
  renderMessage?: () => ReactNode;
}

export const AnswerWrapper = observer((props: AnswerWrapperProps) => {
	const {
    type,
    comment: commentSource,
    errors: errorsSource,
    corrections: correctionsSource,
    passings,
    hasErrors,
    detailsLoading,
    updateLoading,
    submitLoading,
    isTeacher,
    renderMessage,
    onSubmit,
    onUpdate
  } = props;
  
  const { t } = useTranslation();

	const [ passing ] = passings ?? [];

	const [newErrorType, setNewErrorType] = useState<ExerciseErrorType | undefined>();
  const [newErrorRange, setNewErrorRange] = useState<WordRange>();
  const [selectedError, setSelectedError] = useState<ExerciseResultError | undefined>();
	const [errors, setErrors] = useState<ExerciseResultError[]>([]);
  const [comment, setComment] = useState("");

	const [corrections, setCorrections] = useState<ExerciseResultCorrection[]>([])

	const [hoveredWordIndex, setHoveredWordIndex] = useState(EMPTY_WORD_INDEX);
	const [hoveredError, setHoveredError] = useState<ExerciseResultError | undefined>();

  const [editableCorrection, setEditableCorrection] = useState<ExerciseResultCorrection | undefined>();

  const handleSubmit = useCallback(() => {
    onSubmit?.({ errors, corrections, comment });
  }, [
    errors,
    comment,
    corrections,
    onSubmit,
  ]);

  const handleUpdate = useCallback(() => {
    onUpdate?.({ errors, corrections, comment });
  }, [
    errors,
    comment,
    corrections,
    onUpdate,
  ]);

  const correctionCreateContextValue: CorrectionCreateContextProps = useMemo(() => ({
    editableCorrection,
    setEditableCorrection
  }), [
    editableCorrection,
    setEditableCorrection
  ])

	const userRoleContextValue: UserRoleContextProps = useMemo(() => ({
		currentRole: isTeacher
      ? UserRole.TEACHER
      : UserRole.STUDENT
	}), [isTeacher]);

	const hoverContextValue: HoverContextProps = useMemo(() => ({
		hoveredWordIndex,
		hoveredError,
		setHoveredWordIndex,
		setHoveredError
	}), [
		hoveredWordIndex,
		hoveredError,
		setHoveredWordIndex,
		setHoveredError
	]);

  const errorCreateContext: ErrorCreateContextProps = useMemo(() => ({
    newErrorType,
    newErrorRange,
    selectedError,
    setNewErrorType,
    setNewErrorRange,
    setSelectedError
  }), [
    newErrorType,
    newErrorRange,
		selectedError,
    setNewErrorType,
    setNewErrorRange,
    setSelectedError
  ])

	const taskContextValue: TaskContextProps = useMemo(() => ({
    hasErrors,
		errors,
		corrections,
		setErrors,
		setCorrections
	}), [
    hasErrors,
		errors,
		corrections,
		setErrors,
		setCorrections,
	]);

	useEffect(() => {
    const errorsCopy = JSON.parse(JSON.stringify(errorsSource));
		setErrors(getUniqueErrors(errorsCopy));
	}, [
    errorsSource,
    errorsSource?.length,
    setErrors
  ])
  
  useEffect(() => {

    const correctionsCopy = JSON.parse(JSON.stringify(correctionsSource));
		setCorrections(correctionsCopy);
	}, [
    correctionsSource,
    correctionsSource?.length,
    setCorrections
  ])

  useEffect(() => {
    setComment(commentSource);
  }, [
    commentSource,
    setComment
  ])

  return (
    <UserRoleContext.Provider value={userRoleContextValue}>
      <TaskContext.Provider value={taskContextValue}>
        <CorrectionCreateContext.Provider value={correctionCreateContextValue}>
          <ErrorCreateContext.Provider value={errorCreateContext}>
            <HoverContext.Provider value={hoverContextValue}>
              <div className={styles["answer"]}>
                <div>
                  <Loading loading={detailsLoading}>
                    {passing && type && (
                      <Answer text={passing.answer} type={type}/>
                    )}
                    <Comment
                      comment={comment}
                      onChangeComment={setComment}
                    />
                  </Loading>
                </div>
                {renderMessage?.()}
                <Actions
                  onSubmit={handleSubmit}
                  onUpdate={handleUpdate}
                  disabled={detailsLoading}
                  updateLoading={updateLoading}
                  submitLoading={submitLoading}
                />
              </div>
              <div className={styles["mistakes"]}>
                <div className={styles["mistakes-items"]}>
                  <Text variant="text-2-medium" color="header-bg">
                    {t("CreativeTasks:QuestionsAndComments")}
                  </Text>
                  <Loading loading={detailsLoading}>
                    <Statistics />
                  </Loading>
                </div>

                <div className={styles["mistakes-hover"]}>
                  <Text variant="text-2-medium" color="header-bg">
                    {t("CreativeTasks:HoverForDisplay")}
                  </Text>
                  <div className={styles["mistakes-hover-content"]}>
                    <Loading loading={detailsLoading}>
                      <ErrorPreivews />
                    </Loading>
                  </div>
                </div>
              </div>
            </HoverContext.Provider>
          </ErrorCreateContext.Provider>
        </CorrectionCreateContext.Provider>
      </TaskContext.Provider>
    </UserRoleContext.Provider>
  );
})
