import { isBefore, isEqual, isAfter, startOfDay } from "date-fns";

export const isBetween = (
  date: Date,
  from: Date,
  to: Date,
  exclusivity: "()" | "[]" | "(]" | "[)" = "[]"
) => {
  const d = startOfDay(date);
  const f = startOfDay(from);
  const t = startOfDay(to);

  const isBeforeEqual = exclusivity[0] === "[";
  const isAfterEqual = exclusivity[1] === "]";

  return (
    (isBeforeEqual ? isEqual(f, d) || isBefore(f, d) : isBefore(f, d)) &&
    (isAfterEqual ? isEqual(t, d) || isAfter(t, d) : isAfter(t, d))
  );
};
