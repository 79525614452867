import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import { useTranslation } from "react-i18next";

import { StatusContainer } from "./styledComponents/StatusContainer";

export type LessonStatusesProps = {
  type: Lesson["status"];
  styles?: React.CSSProperties;
};

export const LessonStatusesTeacher = ({
  type,
  styles,
}: LessonStatusesProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StatusContainer type={type} style={styles}>
      {t(`LessonStatuses:${type}`)}
    </StatusContainer>
  );
};
