import { observer } from "mobx-react";
import cn from "classnames";

import styles from "./Input.module.scss";
import { forwardRef, ReactNode, useMemo } from "react";
import { BasicInputProps } from "../BasicInput";
import { SearchInput, SearchInputProps } from "./SearchInput";
import { StandardInput, StandardInputProps } from "./StandardInput";
import React from "react";

export type InputProps = {
  containerClassName?: string;
  wrapperClassName?: string;
  type: InputType;
} & BasicInputProps;

type InputType =
  | "name"
  | "email"
  | "text"
  | "number"
  | "search";

type ConditionalProps =
  | StandardInputProps
  | SearchInputProps;

export const Input = observer(
  forwardRef<HTMLInputElement, InputProps & ConditionalProps>(function Input(
    props,
    ref
  ) {
    const { containerClassName, type } = props;

    const containerClasses = cn(styles.container, containerClassName);

    const InputView: Record<InputType, ReactNode> = useMemo(() => {
      const inputProps = {
        ref,
        ...props,
      };

      return {
        name: <StandardInput {...(inputProps as StandardInputProps)} />,
        email: <StandardInput {...(inputProps as StandardInputProps)} />,
        text: <StandardInput {...(inputProps as StandardInputProps)} />,
        number: <StandardInput {...(inputProps as StandardInputProps)} />,
        search: <SearchInput {...(inputProps as SearchInputProps)} />
      };
    }, [ref, props]);

    const Element = InputView[type as InputType];

    return <div className={containerClasses}>{Element}</div>;
  })
);
