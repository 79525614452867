import { Box } from "@mui/material";
import { License as LicenseModel } from "@packages/store/models/License";

import { License } from "../License";

interface LicensesViewProps {
  licenses: LicenseModel[];
}

export const LicensesView = ({
  licenses,
}: LicensesViewProps): JSX.Element | null => {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
      {licenses?.map((license, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <License key={i} license={license} />
      ))}
    </Box>
  );
};
