import { ReactNode } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import styles from "./List.module.scss";

import { Text } from "../Text";
import { Pagination } from "../Pagination";
import { useState } from "react";
import { paginate } from "../../utils/helpers";

interface ListProps<T> {
  containerClassName?: string;
  data: T[];
  renderItem: (item: T, index: number) => ReactNode;
  emptyText?: string;
  itemsPerPage?: number;
  listClassName?: string;
  emptyClassName?: string;
  emptyView?: ReactNode;
  showAllText?: string;
  onShowAllClick?: () => void;
}

export const List = observer(function List<T>(props: ListProps<T>) {
  const {
    containerClassName,
    data,
    renderItem,
    emptyText,
    itemsPerPage = 10,
    listClassName,
    emptyClassName,
    emptyView,
    showAllText,
    onShowAllClick,
  } = props;

  const [page, setPage] = useState(1);

  const list = paginate<T>(data, page, itemsPerPage);

  const containerClasses = cn(containerClassName, styles.container);
  const listClasses = cn(listClassName, styles.list);
  const emptyClasses = cn(emptyClassName, styles.empty);

  return (
    <div className={containerClasses}>
      {data.length > 0 && (
        <div className={listClasses}>{list.map(renderItem)}</div>
      )}
      {data.length === 0 &&
        (emptyView ?? (
          <div className={emptyClasses}>
            <Text variant="h2-mob">{emptyText}</Text>
          </div>
        ))}
      <Pagination
        currentPage={page}
        totalCount={data.length}
        pageSize={itemsPerPage}
        onPageChange={setPage}
        allText={showAllText}
        onAllClick={onShowAllClick}
      />
    </div>
  );
});
