import React from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";

import styles from './CorrectionContextMenu.module.scss';

import { Button } from '../../Button';

interface CorrectionContextMenuProps {
  onCancel?: () => void;
  onSave?: () => void;
}

export const CorrectionContextMenu = observer((props: CorrectionContextMenuProps) => {
  const {
    onCancel,
    onSave
  } = props;

  const { t } = useTranslation(); 

  return (
    <div className={styles.container}>
      <Button
        variant='soft-secondary'
        onClick={onSave}
      >
        {t("CreativeTasks:SaveCorrection")}
      </Button>
      <Button
        variant='soft'
        onClick={onCancel}
      >
        {t("CreativeTasks:CancelCorrection")}
      </Button>
    </div>
  );
})
