/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { WordModel, WordSnapshot } from "./WordModel";

export const WordStoreModel = types
  .model("WordStore")
  .props({
    items: types.optional(types.array(WordModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: WordSnapshot) => {
      const instance = resolveIdentifier(WordModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));

type WordStoreType = Instance<typeof WordStoreModel>;
export type WordStore = WordStoreType;
type WordStoreSnapshotType = SnapshotOut<typeof WordStoreModel>;
export type WordStoreSnapshot = WordStoreSnapshotType;
