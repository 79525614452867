import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { TranslationModel } from "../Translation/TranslationModel";

export const TypeModel = types.model({
  id: types.identifier,
  code: types.maybeNull(types.string),
  translationsAttributes: types.optional(types.array(TranslationModel), []),
});

type TypeModelType = Instance<typeof TypeModel>;
export type TypeModel = TypeModelType;
type TypeModelTypeSnapshotType = SnapshotOut<typeof TypeModel>;
export type TypeModelSnapshot = TypeModelTypeSnapshotType;
