import { useCallback } from "react";

import { Typography, useTheme, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AvatarPerson } from "components/AvatarPerson";
import { Button } from "components/Button";
import { DateText } from "components/DateText";
import { Icon } from "components/Icon";
import { JetLink } from "components/JetLink";
import { Progress } from "components/Progress";
import {
  TooltipTeachersProps,
  TooltipTeachers,
} from "components/TooltipTeachers";
import { isEmpty } from "utils/basic";

import {
  CardModuleContainer,
  ContentContainer,
  ProgressContainer,
  AvatarsContainer,
  HeaderContainer,
  TitleContainer,
  SoftTextContainer,
  ButtonContainer,
} from "./styledComponents";

export interface BottomProgress {
  completed: number;
  total: number;
}

export interface Teachers {
  name: string;
  post: string;
}

export interface CardModuleProps extends TooltipTeachersProps {
  module: ModuleProps;
  dueDate: Date | number | undefined | null;
  isBottom?: boolean;
  isProgress?: boolean;
  isHide?: boolean;
  resultProgress?: number | null;
  totalProgress?: number | null;
  picture?: string | null;
  unitsProgress?: BottomProgress;
  classesProgress?: BottomProgress;
  teachers?: Teachers[];
  continueUrl?: string | null;
}

export interface ModuleProps {
  id: string;
  title: string;
  subTitle: string;
  url: string;
}

export const CardModule = ({
  module,
  dueDate,
  isBottom = false,
  isProgress = true,
  isHide = false,
  totalProgress = 0,
  resultProgress = 0,
  unitsProgress,
  classesProgress,
  teachers,
  continueUrl,
}: CardModuleProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const onContinueClick = (): void => {
    if (!continueUrl) return;
    window.open(continueUrl, "_blank");
  };

  const renderAvatarNames = useCallback(() => {
    if (!teachers) return null;
    if (teachers.length > 2) {
      return teachers.slice(0, 3).map((teacher, i) => {
        const id = i;
        if (i < 2) {
          return <AvatarPerson key={id} name={teacher.name} />;
        }
        return <AvatarPerson key={id} name={`+ ${teachers.length - 2}`} />;
      });
    }
    return teachers.map((teacher, i) => {
      const id = i;
      return <AvatarPerson key={id} name={teacher.name} />;
    });
  }, [teachers]);

  return (
    <CardModuleContainer isHide={isHide}>
      <ContentContainer isProgress={isProgress}>
        {!isEmpty(teachers) ? (
          <Tooltip
            placement="bottom"
            title={<TooltipTeachers dataTeachers={teachers || []} />}
          >
            <AvatarsContainer>{renderAvatarNames()}</AvatarsContainer>
          </Tooltip>
        ) : (
          <Icon type="bookWithDot" width="40" height="40" />
        )}

        <HeaderContainer>
          <TitleContainer variant="h3">
            <JetLink to={module.url} underline="always">
              {module.title}
            </JetLink>
          </TitleContainer>
          <SoftTextContainer variant="h6">{module.subTitle}</SoftTextContainer>
        </HeaderContainer>
        <ButtonContainer>
          {continueUrl && (
            <Button onClick={onContinueClick} variant="secondary">
              <Typography variant="button">
                {t(`CardModule:continue`)}
              </Typography>
            </Button>
          )}
        </ButtonContainer>
      </ContentContainer>
      {isBottom && (
        <ContentContainer>
          <ProgressContainer>
            <Progress
              type="primary"
              value={totalProgress}
              title={t(`CardModule:progressTitleTotal`)}
            />
          </ProgressContainer>
          <ProgressContainer>
            <Progress
              type="primary"
              value={resultProgress}
              title={t(`CardModule:progressTitleResult`)}
            />
          </ProgressContainer>
        </ContentContainer>
      )}

      {isBottom && isProgress && (
        <ContentContainer isProgress={isProgress}>
          <ProgressContainer>
            <Progress
              type="steps"
              value={unitsProgress?.completed}
              steps={unitsProgress?.total}
              title={t(`CardModule:progressTitleUnits`)}
              colorSteps={palette.warning.dark}
            />
          </ProgressContainer>
          <ProgressContainer>
            <Progress
              type="steps"
              value={classesProgress?.completed}
              steps={classesProgress?.total}
              title={t(`CardModule:progressTitleClasses`)}
              colorSteps={palette.primary.main}
            />
          </ProgressContainer>
        </ContentContainer>
      )}
    </CardModuleContainer>
  );
};
