import { styled } from "@mui/material/styles";

export const RightContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  justifyContent: "space-between",
  width: "45.5%",
  "& button": {
    maxWidth: "9.3rem",
  },
  [theme.breakpoints.down(400)]: {
    width: "100%",
  },
}));
