import { Box, Typography } from "@mui/material";

import { Icon } from "components/Icon";

export interface HeaderProps {
  title: string;
  onClose: () => void;
}

export const Header = ({ title, onClose }: HeaderProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Typography variant="h1">{title}</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={onClose}
      >
        <Icon stroke="gray" type="close" width={16} height={16} />
      </Box>
    </Box>
  );
};
