import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";

export interface GroupStatisticsProps {
  groupName?: string;
  classPeriod?: string | null;
  teacherName?: string;
  courseName?: string;
  schedule?: string[];
  totalHours?: number;
  completedHours?: number;
  notPerformedToPayHours: number;
  leftHours?: number;
}

export const GroupStatistics = ({
  groupName = "",
  classPeriod = "",
  teacherName = "",
  courseName = "",
  schedule = [],
  totalHours = 0,
  completedHours = 0,
  notPerformedToPayHours,
  leftHours = 0,
}: GroupStatisticsProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 2fr 1.3fr"
      padding="0.5rem"
      boxShadow="2px 2px 12px rgba(0, 0, 0, 0.11)"
      overflow="auto"
    >
      <Box borderRight="2px solid #D7D7D7" padding="1rem">
        <Icon
          type="groupReportPresentation"
          color="#5783F1"
          width={34}
          height={34}
        />
        <Typography fontSize="1.1rem" fontWeight="700" color="#5783F1">
          {groupName}
        </Typography>
      </Box>

      <Box padding="1rem">
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Teacher")}: {teacherName}
        </Typography>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Course")}:{" "}
          <Typography display="inline" fontSize="0.8rem" color="#5783F1">
            {courseName}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Schedule")}:{" "}
          <Typography display="inline" fontSize="0.8rem" color="#5783F1">
            {schedule.join(", ")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("ModalGroupReport:Duration")}:{" "}
          <Typography display="inline" fontSize="0.8rem" color="#5783F1">
            {classPeriod}
          </Typography>
        </Typography>
      </Box>

      <Box borderLeft="2px solid #D7D7D7" padding="1rem">
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:TotalInTable")}:{" "}
          <Typography
            display="inline"
            fontSize="0.8rem"
            color="#5783F1"
            fontWeight={500}
          >
            {totalHours.toFixed(2)} {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:CompletedInTable")}:{" "}
          <Typography display="inline" fontSize="0.8rem" color="#5783F1">
            {completedHours.toFixed(2)} {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:NotPerformedToPayHours")}
          <Typography display="inline" fontSize="0.8rem" color="#5783F1">
            {notPerformedToPayHours.toFixed(2)}{" "}
            {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
        <Typography fontSize="0.8rem">
          {t("AttendancePivotTable:LeftInTable")}:{" "}
          <Typography display="inline" fontSize="0.8rem" color="#5783F1">
            {leftHours.toFixed(2)} {t("AttendancePivotTable:MinHours")}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
