import { Theme as ThemeProps, useTheme } from "@mui/material";

import { CardClassProps } from "components/CardClass";
import {
  IconContainer,
  TitleContainer,
  WrapperIconTitleContainer,
  UnitLessonContainer,
  LessonContainer,
  UnitContainer,
  ActionContainer,
  LinkWrapper,
} from "components/CardClass/styledComponents";
import { Icon } from "components/Icon";
import { JetLink } from "components/JetLink";

export interface CardClassMobileProps {
  iconColor: CardClassProps["iconColor"];
  variant: CardClassProps["variant"];
  iconType: CardClassProps["iconType"];
  module: CardClassProps["module"];
  lesson: CardClassProps["lesson"];
  action: React.ReactElement;
}

export interface CardClassContainerProps {
  theme: ThemeProps;
  variant: CardClassMobileProps["variant"];
  iconColor?: CardClassMobileProps["iconColor"];
}

export const CardClassMobile = ({
  iconColor,
  variant,
  iconType,
  module,
  lesson,
  action,
}: CardClassMobileProps): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <WrapperIconTitleContainer>
        <IconContainer iconColor={iconColor} theme={theme} variant={variant}>
          <Icon type={iconType} width={22} height={22} />
        </IconContainer>

        <TitleContainer variant="h3Underlined">
          <LinkWrapper>
            <JetLink to={`/my-courses/${module.id}`}>{module.title}</JetLink>
          </LinkWrapper>
          <UnitLessonContainer>
            <LessonContainer variant="smallText">
              {`${lesson.titleLesson}, `}
            </LessonContainer>

            <UnitContainer variant="smallText">
              {lesson.titleUnit}
            </UnitContainer>
          </UnitLessonContainer>
        </TitleContainer>
      </WrapperIconTitleContainer>
      <ActionContainer>{action}</ActionContainer>
    </>
  );
};
