import { styled, Typography } from "@mui/material";

export const ButtonContainer = styled(Typography)(() => ({
  flexGrow: 1,
  marginBottom: "1.75rem",
  display: "contents",
  "& button": {
    maxWidth: "7.5rem",
  },
}));
