import { styled } from "@mui/material/styles";

import { radius } from "@packages/shared/themes/radius/radius";

export const ContainerText = styled("div")(() => ({
  padding: "1rem",
  borderRadius: radius.xxxxs,
  background: "#fff",
  boxShadow: "0.125rem 0.125rem 0.75rem rgb(0 0 0 / 11%)",
  color: "rgba(0, 0, 0, 0.87)",
}));
