/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance, IAnyModelType } from "mobx-state-tree";
import { TeacherModel } from "../Teacher/Teacher";
import { CountersByCompanyModel } from "./CountersByCompany";

export const statuses = [
  "completed",
  "planned",
  "earlyCanceledByCustomer",
  "earlyCanceledBySchool",
  "lateCanceledByCustomer",
  "lateCanceledBySchool",
] as const;
export type Status = (typeof statuses)[number];

export const CancellationReportModel = types
  .model("CancellationReportModel")
  .props({
    id: types.identifier,
    teacher: types.maybeNull(
      types.late(() =>
        types.reference(types.late((): IAnyModelType => TeacherModel))
      )
    ),
    countersByCompany: types.optional(types.array(CountersByCompanyModel), []),
  })
  .views((self) => ({
    getTotalHours(status: Status): number {
      return self.countersByCompany.reduce((acc, cur) => {
        return (
          acc +
          (cur.counters.find((counter) => counter.status === status)?.hours ??
            0)
        );
      }, 0);
    },
    getTotalLessons(status: Status): number {
      return self.countersByCompany.reduce((acc, cur) => {
        return (
          acc +
          (cur.counters.find((counter) => counter.status === status)?.lessons ??
            0)
        );
      }, 0);
    },
  }));

type CancellationReportModelType = Instance<typeof CancellationReportModel>;
export interface CancellationReport extends CancellationReportModelType {}
type CancellationReportSnapshotType = SnapshotOut<
  typeof CancellationReportModel
>;
export type CancellationReportSnapshot = CancellationReportSnapshotType;
