import { useContext } from "react";

import {
  TranslateModeContext,
  TranslateModeContextType,
} from "../context/TranslateModeContext";

export const useTranslateMode = (): TranslateModeContextType => {
  const context = useContext(TranslateModeContext);

  if (!context) {
    throw new Error("useTranslateMode was used outside of its Provider");
  }

  return context;
};
