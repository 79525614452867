import { Typography } from "@mui/material";

import { BlockContainer } from "components/Footer/styledComponents";

export function PageBlock({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) {
  return (
    <BlockContainer>
      <Typography variant="h1" component="span">
        {title}
      </Typography>
      {children}
    </BlockContainer>
  );
}
