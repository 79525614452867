import { ExerciseUISubtype } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";
import { useEssayExercise } from "../../../../hooks/useEssayExercise";
import {
  SubExerciseComponentProps,
  ExerciseComponentProps,
} from "../../../../types";

import * as subExercises from "./subExercises";

const subExerciseMap: {
  [key in ExerciseUISubtype]?: (
    props: SubExerciseComponentProps
  ) => JSX.Element | null;
} = {
  ...subExercises,
};

export const Essay = observer(
  ({ exercise }: ExerciseComponentProps): JSX.Element | null => {
    const { answer, uiSubtype } = exercise;

    useEssayExercise(exercise);

    if (!answer || answer.isEmpty) {
      return null;
    }

    const SubExerciseComponent = subExerciseMap[uiSubtype];

    if (!SubExerciseComponent) {
      return <></>;
    }

    return <SubExerciseComponent exercise={exercise} />;
  }
);
