import { styled, Modal } from "@mui/material";

export const ModalContainer = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  overflow: "scroll",
  [theme.breakpoints.down("desktop")]: {
    alignItems: "flex-start",
  },
}));
