import { observer } from "mobx-react-lite";

import { useStores } from "@packages/store/models";

import { Loading } from "components/Loading";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";

import { DistributorReportsBlock } from "../components/DistributorReportsBlock";

export const MainDistributor = observer((): JSX.Element => {
  const { loading } = useStores();

  return (
    <WrapperMainStudents>
      <Loading loading={loading}>
        <DistributorReportsBlock />
      </Loading>
    </WrapperMainStudents>
  );
});
