import { ButtonBase, styled } from "@mui/material";

interface StyledButtonProps {
  active: boolean;
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "active",
})<StyledButtonProps>(({ theme, active }) => ({
  ...theme.typography.regularText,
  padding: theme.spacing(0, 3),
  border: "1px solid",
  color: theme.palette.info.main,
  borderColor: theme.palette.info.main,
  borderRadius: theme.spacing(3),
  cursor: "pointer",
  whiteSpace: "nowrap",
  transition: theme.transitions.create(["color", "background-color"]),

  ...(active && {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.info.main,
  }),
}));
