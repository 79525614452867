import { useState } from "react";

import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";

import { ContentContainer } from "components/ContentContainer";
import { PageBlock } from "components/PageBlock";
import { TabPanel } from "components/TabPanel";

import { ChangePasswordView } from "./components/ChangePasswordView";
import { LicensesView } from "./components/LicensesView";
import { PersonalInfoView } from "./components/PersonalInfoView";
import { SettingsView } from "./components/SettingsView";
import { StyledTab, StyledTabs } from "./styledComponents";

export const Profile = observer((): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(0);

  const store = useStores();
  const {
    auth: { user },
  } = store;

  const { t } = useTranslation();

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <ContentContainer>
      <PageBlock title={t("Profile:Title")}>
        <Box sx={{ mb: 4 }} />

        <StyledTabs
          variant="scrollable"
          value={currentTab}
          onChange={handleChangeTab}
        >
          <StyledTab label={t("Profile:PersonalInformation")} />
          <StyledTab label={t("Profile:Licenses")} />
          <StyledTab label={t("Profile:ChangePassword")} />
          <StyledTab label={t("Profile:Settings")} />
        </StyledTabs>

        <Box pt={6}>
          <TabPanel value={currentTab} tabValue={0}>
            {user && <PersonalInfoView user={user} />}
          </TabPanel>
          <TabPanel value={currentTab} tabValue={1}>
            {user?.licenses && <LicensesView licenses={user.licenses} />}
          </TabPanel>
          <TabPanel value={currentTab} tabValue={2}>
            <ChangePasswordView />
          </TabPanel>
          <TabPanel value={currentTab} tabValue={3}>
            <SettingsView />
          </TabPanel>
        </Box>
      </PageBlock>
    </ContentContainer>
  );
});
