/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  getRoot,
} from "mobx-state-tree";

import {
  TeacherRateModel,
  TeacherRateSnapshot,
  TeacherRate,
} from "./TeacherRate";
import { RootStore } from "../RootStore/RootStore";

export const TeacherRateStoreModel = types
  .model("TeacherRateStore")
  .props({
    items: types.optional(types.array(TeacherRateModel), []),
  })
  .actions((self) => ({
    addItem: (item: TeacherRateSnapshot) => {
      const instance = resolveIdentifier(TeacherRateModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getByTeacherId(teacherId: string): TeacherRate[] {
      return self.items.filter((rate) => rate.teacherId === teacherId);
    },
  }))
  .views((self) => ({
    getLastByTeacherId(teacherId: string): TeacherRate | undefined {
      const allRates = self.getByTeacherId(teacherId);

      if (!allRates.length) return;

      return allRates.reduce((a, b) => {
        if (a.activeFrom === null) return b;
        if (b.activeFrom === null) return a;

        return a.activeFrom > b.activeFrom ? a : b;
      });
    },
    getLastByGroupId(groupId: string): TeacherRate | undefined {
      const group = getRoot<RootStore>(self).learningGroup.items.find(
        (group) => group.id === groupId
      );

      if (!group) return;

      const { currentTeacher: teacher } = group;

      if (!teacher) return;

      const allRates = self.getByTeacherId(teacher.actualId);

      if (!allRates.length) return;

      let classType: any;
      try {
        classType = group.relatedClassGroups[0]?.type;
      } catch {
        classType = undefined;
      }
      const filteredByLanguage = allRates.filter(
        (rate) => rate.languageId === group.languageId
      );
      const filteredByClassType = filteredByLanguage.filter(
        (rate) => rate.classType === classType
      );

      if (filteredByClassType.length === 0) return;
      if (filteredByClassType.length === 1) return filteredByClassType[0];

      return filteredByClassType.reduce((a, b) => {
        if (a.activeFrom === null) return b;
        if (b.activeFrom === null) return a;

        return a.activeFrom > b.activeFrom ? a : b;
      });
    },
  }));

type TeacherRateStoreType = Instance<typeof TeacherRateStoreModel>;
export type TeacherRateStore = TeacherRateStoreType;
type TeacherRateStoreSnapshotType = SnapshotOut<typeof TeacherRateStoreModel>;
export type TeacherRateStoreSnapshot = TeacherRateStoreSnapshotType;
