/* eslint-disable no-param-reassign */
import { getRoot, Instance, SnapshotOut, types } from "mobx-state-tree";

import { RootStore } from "../RootStore/RootStore";

import { NotificationGroupModel } from "./NotificationGroupModel";

export const NotificationsStoreModel = types
  .model("NotificationsStore")
  .props({
    unreadCount: types.maybeNull(types.number),
    groups: types.optional(types.array(NotificationGroupModel), []),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value;
    },
  }))
  .actions((self) => ({
    addItem: (item: any) => {
      self.unreadCount = item.unreadCount;
      self.groups = item.groups;
    },
  }))
  .actions((self) => ({
    async loadNotifications() {
      const rootStore = getRoot<RootStore>(self);
      self.setLoading(true);
      await rootStore.getNotifications(true);
      self.setLoading(false);
    },
  }));

type NotificationsStoreType = Instance<typeof NotificationsStoreModel>;
export type NotificationsStore = NotificationsStoreType;
type NotificationsSnapshotType = SnapshotOut<typeof NotificationsStoreModel>;
export type NotificationsStoreSnapshot = NotificationsSnapshotType;
export const createNotificationsStoreDefaultModel = () =>
  types.optional(NotificationsStoreModel, {} as NotificationsStoreSnapshot);
