import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Intro } from "pages/Login/components";
import {
  Container,
  FormWrapper,
  FormContainer,
} from "pages/Login/styledComponents";

/**
 * Страничка которая показывается если токен регистрации устарел
 * {@link usePromocodeAndConfirmation}
 */
export const PromocodeExpired = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Container>
      <Intro />
      <FormWrapper>
        <FormContainer sx={{ textAlign: "center" }}>
          {t("Promocode:Expired")}
        </FormContainer>
      </FormWrapper>
    </Container>
  );
});
