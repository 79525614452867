import { Typography, FormLabel } from "@mui/material";
import { useStores } from "@packages/store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Checkbox } from "../../Checkbox";

export const DontShowAgain = observer((): JSX.Element | null => {
  const { t } = useTranslation();
  const { auth } = useStores();

  return (
    <FormLabel sx={{ cursor: "pointer" }}>
      <Checkbox
        checked={Boolean(auth.dontShowExercisesResults)}
        onChange={(e) => auth.setDontShowExercisesResults(e.target.checked)}
      />
      <Typography variant="smallTextUnderlined" color="custom.grey.light">
        {t("ResultsModals:DontShowAgain")}
      </Typography>
    </FormLabel>
  );
});
