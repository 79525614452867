import { useEffect, useMemo, useState, RefObject } from "react";

export const useIsInViewport = (
  ref: RefObject<Element>,
  options?: IntersectionObserverInit
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        // console.log(entry);

        // if (entry.intersectionRatio > 0.1) {
        setIsIntersecting(entry.isIntersecting);
        // }
      }, options),
    [options]
  );

  useEffect(() => {
    if (!ref.current) return;

    observer.observe(ref.current);

    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};
