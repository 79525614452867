import { styled } from "@mui/material";

import { ModuleItemContainerProps } from "../BlockItem";

export const RatingCountContainer = styled("div")<ModuleItemContainerProps>(
  ({ theme, colorVariant }: ModuleItemContainerProps) => ({
    padding: theme.spacing(2, 3),
    display: "flex",
    border: `0.125rem solid ${colorVariant}`,
    color: colorVariant,
    borderRadius: "0.313rem",
  })
);
