import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { WritingQuestion } from "../../../../../../components/WritingQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Plain = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, getCorrectAnswer } = exercise;

    const { getQuestion } = ensure(answer);

    return (
      <ObserverFlexList
        items={questions}
        renderItem={(question: Question, index, array: Question[]) => (
          <WritingQuestion
            key={question.id}
            question={question}
            answerData={ensure(getQuestion(question.id))}
            correctAnswer={getCorrectAnswer(question.id)}
            {...(array.length > 1 && {
              order: index + 1,
            })}
          />
        )}
      />
    );
  }
);
