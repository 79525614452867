import { Box, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";

import { Row } from "./styledComponents";

export interface FormActionsProps {
  submitLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const FormActions = ({
  submitLoading = false,
  onSubmit,
  onCancel,
}: FormActionsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Row justifyContent="flex-end">
      <Button
        variant="outlined"
        onClick={onCancel}
        sx={{
          height: "2.5rem",
          width: "11rem",
          borderRadius: "1.5rem",
        }}
      >
        {t("CreateReport:Cancel")}
      </Button>
      {submitLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            height: "2.5rem",
            width: "11rem",
            borderRadius: "1.5rem",
            background: "rgba(86, 44, 142, 0.5)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {!submitLoading && (
        <Button variant="primary" onClick={onSubmit}>
          {t("CreateReport:Create")}
        </Button>
      )}
    </Row>
  );
};
