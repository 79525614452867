/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import { useStores } from "@packages/store/models";
import { ChangePasswordForm } from "components/ChangePasswordForm";
import { Logo } from "components/Logo";
import { observer } from "mobx-react-lite";
import {
  FormContainer,
  FormSubTitle,
  FormWrapper,
  FormTitle,
  LogoWrapper,
} from "pages/Login/styledComponents";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { ROUTES } from "router/constants";

import { AccessDenied } from "../AccessDenied";

export const SetupPasswordForm = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const token = params.get("token");

  const [loading, setLoading] = useState(true);

  const { auth } = useStores();

  const { user, isPasswordSetupRequired, loginByToken } = auth;

  const hasAccess = user && isPasswordSetupRequired;

  const loginUser = useCallback(
    async (token: string) => {
      const login = await loginByToken(token);
      if (login) {
        return;
      }
      setLoading(false);
    },
    [loginByToken]
  );

  const onPasswordChanged = () => {
    navigate(ROUTES.HOME);
  };

  useEffect(() => {
    if (user || !token) {
      return;
    }
    loginUser(token);
  }, [user, token, loginUser]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setLoading(false);
  }, [user, setLoading]);

  useEffect(() => {
    if (token || user) {
      return;
    }
    setLoading(false);
  }, [token, user]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
        <CircularProgress />
      </Box>
    );
  }

  if (!hasAccess) {
    return <AccessDenied />;
  }

  return (
    <FormWrapper>
      <FormContainer>
        <LogoWrapper>
          <Logo width={128} height={32} />
        </LogoWrapper>

        <FormTitle>{t(`SetupPassword:Title`, { lng: "ru" })}</FormTitle>

        <FormSubTitle variant="regularText">
          {t(`SetupPassword:Subtitle`, { lng: "ru" })}
        </FormSubTitle>

        <ChangePasswordForm
          prefferedLanguage="ru"
          onPasswordChanged={onPasswordChanged}
        />
      </FormContainer>
    </FormWrapper>
  );
});
