import { ComponentsOverrides } from "@mui/material";

interface muiModalProps {
  styleOverrides: ComponentsOverrides["MuiModal"];
}

export const muiModal = (): muiModalProps => ({
  styleOverrides: {
    root: {
      top: "5px",
    },
  },
});
