import { Box, Typography } from "@mui/material";
import { useStores } from "@packages/store/models";
import { Button } from "components/Button";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ROUTES } from "router/constants";

import { ModalBody, ModalContainer } from "./styledComponents";

export const SetupPasswordRedirect = observer((): JSX.Element => {
  const { pathname } = useLocation();
  const { auth } = useStores();

  const { t } = useTranslation();

  const { isPasswordSetupRequired } = auth ?? {};

  const setupPassword = () => {
    window.open(ROUTES.SETUP_PASSWORD, "_self");
  };

  if (!isPasswordSetupRequired || pathname === ROUTES.SETUP_PASSWORD) {
    return <></>;
  }

  return (
    <ModalContainer disableScrollLock={false} open>
      <ModalBody>
        <Box mb="1rem">
          <Typography variant="h1">
            {t("SetupPasswordRedirect:Title")}
          </Typography>
        </Box>
        <Box mb="1rem">
          <Typography>{t("SetupPasswordRedirect:Description")}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button variant="primary" onClick={setupPassword}>
            {t("SetupPasswordRedirect:Redirect")}
          </Button>
        </Box>
      </ModalBody>
    </ModalContainer>
  );
});
