/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const PartGroupModel = types.model("PartGroup", {
  id: types.identifier,
  text: types.maybeNull(types.string),
});

type PartGroupModelType = Instance<typeof PartGroupModel>;
type PartGroupModelSnapshotType = SnapshotOut<typeof PartGroupModel>;

export interface PartGroup extends PartGroupModelType {}
export type PartGroupSnapshot = PartGroupModelSnapshotType;
