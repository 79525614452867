import { Popover, styled } from "@mui/material";

export const StyledPopover = styled(Popover)(() => ({
  zIndex: 9999,
  userSelect: "none",

  ".MuiPopover-paper": {
    display: "flex",
    flexDirection: "column",
    maxWidth: 240,
    width: "100%",
  },
}));
