import { VideoTrack } from "@packages/store/models/AdditionalContent/VideoTrack";
import { Course } from "@packages/store/models/Course/CourseModel";
import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";

import { StyledMainContent } from "./styledComponents/StyledMainContent";
import { TaskText } from "./styledComponents/TaskText";
import { ExerciseForm } from "../ExerciseForm";
import { HelperText } from "../HelperText";
import { ToolPanel } from "../ToolPanel";
import { VideoContent } from "../VideoContent";
import { VideoPlayerType } from "../VideoPlayer";

interface MainContentProps {
  course: Course | undefined;
  exercise: Exercise | undefined;
  hasScript: boolean;
  scriptHidden: boolean;
  needToShowVideo: boolean;
  videoTracks: VideoTrack[];
  onScriptHiddenToggle: () => void;
  onPlayerReady: (player: VideoPlayerType) => void;
  onNextExercise: () => void;
  onPrevExercise: () => void;
  onCheck: () => void;
}

export const MainContent = observer(
  ({
    course,
    exercise,
    hasScript,
    scriptHidden,
    needToShowVideo,
    videoTracks,
    onScriptHiddenToggle,
    onPlayerReady,
    onNextExercise,
    onPrevExercise,
    onCheck,
  }: MainContentProps): JSX.Element | null => {
    const { taskText, isConfirmation } = exercise || {};

    return (
      <StyledMainContent>
        {needToShowVideo && (
          <VideoContent
            videoTracks={videoTracks}
            hasScript={hasScript}
            scriptHidden={scriptHidden}
            onScriptHiddenToggle={onScriptHiddenToggle}
            onPlayerReady={onPlayerReady}
          />
        )}

        <TaskText
          variant={isConfirmation ? "confirmation" : "default"}
          dangerouslySetInnerHTML={{ __html: String(taskText) }}
        />

        {exercise && <ExerciseForm exercise={exercise} />}

        {exercise && <HelperText exercise={exercise} />}

        <ToolPanel
          course={course}
          exercise={exercise}
          onNextExercise={onNextExercise}
          onPrevExercise={onPrevExercise}
          onCheck={onCheck}
        />
      </StyledMainContent>
    );
  }
);
