import { Instance, SnapshotOut, cast, resolveIdentifier, types } from "mobx-state-tree";
import { SummaryModel, SummarySnapshot } from "./SummaryModel";

export const SummaryStoreModel = types.
  model("SummaryStore", {
    items: types.optional(types.array(SummaryModel), [])
  })
  .actions((self) => ({
    addItem: (item: SummarySnapshot) => {
      self.items.push(item);
    },
    clear() {
      self.items = cast([]);
    }
  }));

type SummaryStoreType = Instance<typeof SummaryStoreModel>;
export type SummaryStore = SummaryStoreType;
type SummaryStoreSnapshotType = SnapshotOut<typeof SummaryStoreModel>;
export type SummaryStoreSnapshot = SummaryStoreSnapshotType;