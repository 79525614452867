import { observer } from "mobx-react-lite";

import { ListProps, ObserverList } from "../List";

interface GridListProps<T> extends ListProps<T> {
  columnsTemplate?: string;
  gap?: number | string;
}

export const ObserverGridList = observer(
  <T,>({
    gap = 3,
    columnsTemplate,
    containerStyles,
    ...rest
  }: GridListProps<T>): JSX.Element | null => {
    return (
      <ObserverList
        containerStyles={{
          display: "grid",
          gridTemplateColumns: columnsTemplate,
          gap,
          ...containerStyles,
        }}
        {...rest}
      />
    );
  }
);
