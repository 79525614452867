import { Box, Typography, Paper, useTheme, useMediaQuery } from "@mui/material";
import { Course } from "@packages/store/models/Course/CourseModel";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { EnglishLevels } from "./EnglishLevels";
import { englishLevels } from "../../../constants/englishLevels";
import { mobile } from "../../../constants/styles";
import { Button } from "../../Button";
import { Modal } from "../../shared/Modal";

interface ModalEnglishEntranceTestResultProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  course: Course | null;
}

export const ModalEnglishEntranceTestResult = observer(
  ({
    isOpen,
    onConfirm,
    onClose,
    course,
  }: ModalEnglishEntranceTestResultProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const { passing, totalProgress, resultProgress } = course ?? {};
    const { cefrLevel } = passing ?? {};

    const isDesktop = useMediaQuery(theme.breakpoints.up(1120));
    const isMobile = useMediaQuery(theme.breakpoints.down(mobile));

    if (!cefrLevel) return null;

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        type="large"
        title={t("ResultsModals:TestCourseTitle")}
        titleColor="primary.main"
        titleVariant="largeText"
        containerStyles={{ paddingBottom: "1.5rem" }}
      >
        <Paper
          sx={{
            backgroundColor: theme.palette.custom.grey.accentBlock,
            borderRadius: theme.spacing(3),
            padding: "1rem",
            boxShadow: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.8rem",
            flexWrap: "wrap",
            mb: "1rem",
          }}
        >
          <Typography variant="largeText">
            {t("ResultsModals:TestCourseRecomendedLevel")}{" "}
            <Typography
              variant="largeText"
              color="primary.main"
              fontWeight={800}
            >
              {englishLevels[cefrLevel as keyof typeof englishLevels]?.name}{" "}
              {cefrLevel}.
            </Typography>
          </Typography>

          <Box
            ml={isDesktop ? "auto" : "none"}
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="center"
            gap="0.5rem"
          >
            <Typography variant="largeText" color="custom.grey.accentText">
              {t("ResultsModals:TestCourseTotalProgress")}{" "}
              <Typography variant="largeText" color="info.main">
                {totalProgress}%
              </Typography>
            </Typography>
            <Typography variant="largeText" color="custom.grey.accentText">
              {t("ResultsModals:TestCourseResultProgress")}{" "}
              <Typography variant="largeText" color="success.main">
                {resultProgress}%
              </Typography>
            </Typography>
            <Button
              sx={{
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.success.main,
                borderRadius: "1rem",
                padding: "1rem 1.25rem",

                "&:hover": {
                  backgroundColor: theme.palette.success.main,
                },

                ...(isMobile ? { width: "100%" } : {}),
              }}
              type="button"
              onClick={onConfirm}
            >
              {t("ResultsModals:TestCourseShowResults")}
            </Button>
          </Box>
        </Paper>

        <EnglishLevels cefrLevel={cefrLevel} />
      </Modal>
    );
  }
);
