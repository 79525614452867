import { Box, styled } from "@mui/material";

export const DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  maxWidth: "260px",
  borderRadius: "8px",
  boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.11)",
  [theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));
