import {
  ModalProps as MuiModalProps,
  Box,
  BoxProps,
  Typography,
} from "@mui/material";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import {
  StyledModal,
  ModalContainer,
  ModalContainerProps,
} from "./styledComponents";

export interface ModalProps
  extends Omit<MuiModalProps, "children" | "title" | "onSubmit"> {
  title?: string | null;
  containerProps?: ModalContainerProps;
  children: BoxProps["children"];
}

// TODO Переписать все модалки на эту
export const Modal = ({
  title = "",
  containerProps,
  children,
  ...other
}: ModalProps): JSX.Element | null => {
  return (
    <StyledModal {...other}>
      <ModalContainer {...containerProps}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: title ? "1rem" : "0",
          }}
          {...other}
        >
          <Typography variant="h2">{title}</Typography>
          {other?.onClose && (
            <Button
              variant="iconBtn"
              onClick={(e) => other.onClose?.(e, "escapeKeyDown")}
            >
              <Icon type="close" width={24} height={24} />
            </Button>
          )}
        </Box>
        {children}
      </ModalContainer>
    </StyledModal>
  );
};
