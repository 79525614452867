/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  getRoot,
} from "mobx-state-tree";

import { RootStore } from "..";
import { withEnvironment } from "../extensions/withEnvironment";
import {
  LearningGroupsApprove,
  LearningGroupsApproveModel,
  LearningGroupsApproveSnapshot,
} from "./LearningGroupsApproveModel";

export const LearningGroupsApproveStoreModel = types
  .model("LearningGroupsApprove")
  .props({
    items: types.optional(types.array(LearningGroupsApproveModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: LearningGroupsApproveSnapshot) => {
      const instance = resolveIdentifier(
        LearningGroupsApproveModel,
        self,
        item.id
      );
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getLastByGroupId(groupId: string): LearningGroupsApprove | undefined {
      const allApproves = self.items.filter((approve) => {
        return approve?.group?.id === groupId;
      });

      if (!allApproves.length) return;

      return allApproves.reduce((a, b) => {
        if (a.createdAt === null) return b;
        if (b.createdAt === null) return a;

        return a.createdAt > b.createdAt ? a : b;
      });
    },
  }))
  .views((self) => ({
    isApproved(groupId: string): boolean {
      const { learningGroup } = getRoot<RootStore>(self);
      const group = learningGroup.getById(groupId);

      if (!group) return false;

      const approve = self.getLastByGroupId(group.id);
      const { currentTeacher } = group;

      if (!approve) return false;
      if (!currentTeacher) return true;

      const includedAt = currentTeacher.includedAt ?? 0;
      const createdAt = approve.createdAt ?? 0;

      if (includedAt > createdAt) return false;

      return true;
    },
  }));

type LearningGroupsApproveStoreType = Instance<
  typeof LearningGroupsApproveStoreModel
>;
export type LearningGroupsApproveStore = LearningGroupsApproveStoreType;
type LearningGroupsApproveStoreSnapshotType = SnapshotOut<
  typeof LearningGroupsApproveStoreModel
>;
export type LearningGroupsApproveStoreSnapshot =
  LearningGroupsApproveStoreSnapshotType;
