import { Box, styled } from "@mui/material";

export const SectionsBottomLine = styled(Box)(() => ({
  height: "0.15rem",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  background: "#D7D7D7",
}));
