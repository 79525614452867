import { alpha, ComponentsOverrides, Theme } from "@mui/material";

interface muiCheckboxProps {
  styleOverrides: ComponentsOverrides["MuiCheckbox"];
}

export const muiCheckbox = (theme: Theme): muiCheckboxProps => ({
  styleOverrides: {
    root: {
      color: alpha(theme.palette.custom?.grey?.main as string, 0.46),
      "&.Mui-checked": {
        color: theme.palette.info.main,
      },
      "&.Mui-disabled": {
        color: theme.palette.custom?.grey?.lightGray,
      },
    },
  },
});
