import { styled, Box } from "@mui/material";

export const ReferenceBookBlock = styled(Box)(({ theme }) => ({
  background: theme.palette.custom.grey.accentBlock,
  padding: theme.spacing(4),
  boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.11)",
  borderRadius: "1rem",
  color: "custom.grey.accentText",
  maxWidth: "150px",
  width: "100%",

  "& svg": {
    color: theme.palette.custom.grey.accentText,
    "& path": {
      stroke: theme.palette.custom.grey.accentText,
    },
    "& line": {
      stroke: theme.palette.custom.grey.accentText,
    },
  },
}));
