import { Box, styled } from "@mui/material";

export const TabletContainer = styled(Box)(({ theme }) => ({
  display: "none",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  [theme.breakpoints.down("tablet")]: {
    display: "flex",
  },
}));
