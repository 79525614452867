/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback } from "react";

import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { List } from "../../../../../../components/List";
import { observer } from "mobx-react-lite";
import {
  ChoiceQuestion,
  ChoiceSelectProps,
} from "../../../../../../components/ChoiceQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

import { StyledOption } from "./styledComponents/StyledOption";

export const RadioButton = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, getCorrectAnswer } = exercise;

    const { getQuestion } = ensure(answer);

    const renderSelect = useCallback(
      ({
        value,
        onChange,
        options,
        isChecked,
      }: ChoiceSelectProps): JSX.Element | null => {
        const selectedOptionIndex = options.findIndex(
          (option) => option.value === value[0]
        );

        const handleClick = (newValue: string) => () => {
          if (isChecked) {
            return;
          }
          onChange(newValue);
        };

        // const getWithBorder = (index: number, array: ChoiceOptionType[]) => {
        //   if (selectedOptionIndex === -1) {
        //     return false;
        //   }

        //   return (
        //     index !== 0 &&
        //     (selectedOptionIndex === index ||
        //       (array[index].status !== "default" &&
        //         selectedOptionIndex !== index - 1))
        //   );
        // };

        // const getWithoutBorder = (index: number, array: ChoiceOptionType[]) => {
        //   if (selectedOptionIndex === -1) {
        //     return false;
        //   }

        //   const closestIndex = array.findIndex(
        //     (option, index) =>
        //       option.status !== "default" && index !== selectedOptionIndex
        //   );

        //   return (
        //     selectedOptionIndex !== index &&
        //     (selectedOptionIndex === index + 1 ||
        //       (closestIndex !== -1 && closestIndex === index + 1))
        //   );
        // };

        const withBorder = (index: number) => {
          return index !== 0 && index === selectedOptionIndex;
        };

        const withoutBorder = (index: number) => {
          return (
            selectedOptionIndex !== -1 && selectedOptionIndex === index + 1
          );
        };

        return (
          <List
            items={options}
            renderItem={(option, index) => (
              <StyledOption
                key={option.value}
                status={option.status}
                clickable={!isChecked}
                withBorder={withBorder(index)}
                withoutBorder={withoutBorder(index)}
                onClick={handleClick(option.value)}
              >
                {option.label}
              </StyledOption>
            )}
            containerStyles={{
              display: "inline",
            }}
          />
        );
      },
      []
    );

    return (
      <ObserverFlexList
        gap={4}
        items={questions}
        renderItem={(question: Question, index, array: Question[]) => (
          <ChoiceQuestion
            key={question.id}
            variant="inline"
            question={question}
            answerData={ensure(getQuestion(question.id))}
            correctAnswer={getCorrectAnswer(question.id)}
            renderSelect={renderSelect}
            {...(array.length > 1 && {
              order: index + 1,
            })}
          />
        )}
      />
    );
  }
);
