import { styled, Box } from "@mui/material";

export const TopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  [theme.breakpoints.down("tablet")]: {
    justifyContent: "center",
    flexDirection: "column-reverse",
  },
  "& .MuiButton-root": {
    width: "18rem",
    height: "2.5rem",
    padding: `0 0 0 1.5rem`,
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 500,
    fontSize: "0.813rem",
    lineHeight: "1rem",
    color: theme.palette.primary.dark,
    border: `0.063rem solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
    background: theme.palette.custom.grey["menu-tile-inactive"],
    [theme.breakpoints.down("tablet")]: {
      marginBottom: "2.5rem",
    },
  },
}));
