import { useState } from "react";

import {
  Box,
  SwipeableDrawer,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { routerLinks } from "constants/constants";
import { StyledMuiLink } from "pages/Login/styledComponents";

const drawerBleeding = 72;

const StyledBox = styled(Box)(() => ({
  backgroundColor: "#fff",
}));

const Puller = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
  width: 30,
  height: 6,
  borderRadius: 3,
  backgroundColor: theme.palette.primary.main,
}));

export const LinkDrawer = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down(480));

  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  if (!isMobile) {
    return null;
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen={false}
      ModalProps={{
        keepMounted: true,
      }}
      sx={{
        ".MuiPaper-root": {
          height: `calc(250px - ${drawerBleeding}px)`,
          overflow: "visible",
        },
      }}
    >
      <StyledBox
        sx={{
          position: "absolute",
          top: -drawerBleeding,
          right: 0,
          left: 0,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          visibility: "visible",
          textAlign: "center",
        }}
      >
        <Puller />
        <StyledMuiLink
          href={routerLinks.About.url}
          colorVariant="primary"
          sx={{ pt: 5, pb: 4, px: 4 }}
        >
          {t(`Footer:About`, { lng: "ru" })}
        </StyledMuiLink>
      </StyledBox>
      <StyledBox
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          px: 4,
          textAlign: "center",
        }}
      >
        <StyledMuiLink
          href={routerLinks.UserAgreement.url}
          colorVariant="primary"
        >
          {t(`Footer:UserAgreement`, { lng: "ru" })}
        </StyledMuiLink>
        <StyledMuiLink href={routerLinks.Terms.url} colorVariant="primary">
          {t(`Footer:Terms & Conditions`, { lng: "ru" })}
        </StyledMuiLink>
        <StyledMuiLink href={routerLinks.Privacy.url} colorVariant="primary">
          {t(`Footer:Privacy Policy`, { lng: "ru" })}
        </StyledMuiLink>
      </StyledBox>
    </SwipeableDrawer>
  );
};
