import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun } from "mobx";

export const useSpeakingExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty) {
        const initialState = questions.map(({ id, questionString }) =>
          QuestionModel.create({
            id,
            text: questionString,
          })
        );

        setQuestions(initialState);
      }
    })
  );
};
