import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export interface EssayStats {
  numberOfWords: number;
  numberOfLetters: number;
}

interface EssayStatsViewProps {
  stats: EssayStats;
  maxWords?: number;
}

export function EssayStatsView({
  stats,
  maxWords = 150,
}: EssayStatsViewProps): JSX.Element | null {
  const { t } = useTranslation();

  const theme = useTheme();

  const { numberOfWords, numberOfLetters } = stats;

  const progress =
    numberOfWords > maxWords
      ? 100
      : Math.floor((numberOfWords / maxWords) * 100);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: 2 }}>
      <Typography variant="regularText" color="custom.grey.main">
        {t("Exercise:TextSize")}
      </Typography>

      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 6,
          borderRadius: theme.spacing(2),
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.custom.grey["menu-tile-inactive"],
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.success.main,
          },
        }}
      />

      <Typography variant="regularText" color="custom.grey.main">
        {`${numberOfLetters} ${t(
          "Exercise:Characters"
        ).toLowerCase()} / ${numberOfWords} ${t(
          "Exercise:Words"
        ).toLowerCase()}`}
      </Typography>
    </Box>
  );
}
