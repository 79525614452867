/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance, getRoot } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { RootStore } from "../RootStore/RootStore";
import { TagCreateData } from "../../services/Api";

import { Tag } from "../Tag/Tag";

export const TaggingListModel = types
  .model("TaggingListModel")
  .props({
    id: types.identifier,
    name: types.maybeNull(types.string),
    appAreas: types.optional(types.array(types.string), []),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get tags(): Tag[] {
      const result =
        getRoot<RootStore>(self).tag.items.filter(
          (tag) => tag.listId === self.id
        ) ?? [];

      return result;
    },
  }))
  .actions((self) => ({
    getTag(id: string | null) {
      return self.tags.find((tag) => tag.id === id);
    },
    createTag(data: TagCreateData) {
      return self.environment.api.createTag(self.id, data);
    },
    deleteTag(tagId: string) {
      return self.environment.api.deleteTag(tagId);
    },
  }));

type TaggingListModelType = Instance<typeof TaggingListModel>;
export interface TaggingList extends TaggingListModelType {}
type TaggingListSnapshotType = SnapshotOut<typeof TaggingListModel>;
export type TaggingListSnapshot = TaggingListSnapshotType;
