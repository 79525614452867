"use client";

import { ComponentProps, forwardRef } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import styles from "./Button.module.scss";

interface ButtonProps {
  containerClassName?: string;
  children?: React.ReactNode;
  text?: string;
  variant?: ButtonType;
  type?: ComponentProps<"button">["type"];
  disabled?: boolean;
  onClick?: () => void;
}

export type ButtonType = "main" | "secondary" | "soft" | "soft-secondary" | "text";

export const Button = observer(
  forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
    const {
      containerClassName,
      text,
      children,
      variant = "main",
      type,
      disabled,
      onClick,
    } = props;

    const buttonClasses = cn(styles.button, styles[variant]);

    const containerClasses = cn(
      styles.container,
      containerClassName,
      {
        [styles.disabled]: disabled,
      },
      buttonClasses
    );

    const handleClick = disabled
      ? () => {}
      : onClick;

    return (
      <button
        ref={ref}
        type={type}
        className={containerClasses}
        onClick={handleClick}
      >
        {text ?? children}
      </button>
    );
  })
);
