import { styled } from "@mui/material";

export const ScheduleContainer = styled("div")(({ theme }) => ({
  marginTop: "1.5rem",
  marginBottom: "2.5rem",
  [theme.breakpoints.down("laptop")]: {
    borderTop: `0.063rem solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
    paddingTop: "1.5rem",
  },
}));
