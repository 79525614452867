import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const ExerciseResultCheckingHistoryModel = types
  .model("ExerciseResultCheckingHistory", {
    checkDate: types.maybeNull(types.Date),
    originDate: types.maybeNull(types.Date),
    hasErrors: types.optional(types.boolean, false),
    teacher: types.optional(types.string, ""),
  });

type ExerciseResultCheckingHistoryModelType = Instance<typeof ExerciseResultCheckingHistoryModel>;
type ExerciseResultCheckingHistoryModelTypeSnapshotType = SnapshotOut<typeof ExerciseResultCheckingHistoryModel>;

export interface ExerciseResultCheckingHistory extends ExerciseResultCheckingHistoryModelType {}
export type ExerciseResultCheckingHistorySnapshot = ExerciseResultCheckingHistoryModelTypeSnapshotType;