import { Box, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SearchFilter } from "components/shared/SearchFilter";

export interface AllPayrollGroupsFormValues {
  teacherId: string;
  group: string;
}

export interface PayrollGroupsFormProps {
  onSubmit: (data: AllPayrollGroupsFormValues) => void;
}

export const AllPayrollGroupsForm = observer(
  ({ onSubmit }: PayrollGroupsFormProps): JSX.Element | null => {
    const { t } = useTranslation();

    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm<AllPayrollGroupsFormValues>();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          gap="0.5rem"
          flexWrap="wrap"
          alignItems="flex-end"
          mt="1.5rem"
          mb="0.5rem"
        >
          <SearchFilter
            control={control}
            name="group"
            inputProps={{
              placeholder: t("GroupFilter:SearchGroup") ?? "",
              error: !!errors.group,
            }}
            rules={{
              required: "required",
            }}
          />

          {/* TODO фильтр по преподавателю */}
          {/* <Controller
            name="teacherId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TeachersAutocompleteFilter
                teacherId={value}
                onChange={onChange}
                error={!!errors.teacherId}
                helperText={errors.teacherId?.message || " "}
              />
            )}
          /> */}

          <Button
            sx={{
              background: "#5783F1",
              color: "white",
              padding: "0.8rem 1.5rem",
              borderRadius: "0.5rem",
              fontSize: "0.8rem",
              mb: "20px",
              ml: "auto",
              ":hover": {
                background: "#5783F1",
              },
            }}
            type="submit"
          >
            {t("CancellationReport:Process")}
          </Button>
        </Box>
      </form>
    );
  }
);
