/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { Answer, AnswerModel } from "./Answer";

export const CorrectAnswerModel = types
  .model("CorrectAnswer", {
    questionId: types.optional(types.string, ""),
    answers: types.optional(types.array(AnswerModel), []),
  })
  .views((self) => ({
    getAnswer(id: string) {
      return self.answers.find((answer) => answer.id === id);
    },
    get firstAnswer(): Answer | undefined {
      return self.answers.slice()[0];
    },
  }));

type CorrectAnswerModelType = Instance<typeof CorrectAnswerModel>;
type CorrectAnswerSnapshotType = SnapshotOut<typeof CorrectAnswerModel>;

export interface CorrectAnswer extends CorrectAnswerModelType {}
export type CorrectAnswerSnapshot = CorrectAnswerSnapshotType;
