import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const DiffModel = types.model({
  action: types.maybeNull(types.string),
  position: types.maybeNull(types.number),
  element: types.maybeNull(types.string),
});

type DiffModelType = Instance<typeof DiffModel>;
type DiffModelSnapshotType = SnapshotOut<typeof DiffModel>;

export type Diff = DiffModelType;
export type DiffSnapshot = DiffModelSnapshotType;
