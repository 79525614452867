import { styled } from "@mui/material/styles";

export const ProgressContainer = styled("div")(({ theme }) => ({
  flexGrow: 1,
  flexShrink: 1,
  marginRight: theme.spacing(4),
  "&:last-child": {
    ".MuiLinearProgress-bar": {
      backgroundColor: theme.palette.success.main,
    },
  },
}));
