import { ReactNode } from "react";

import { BlockContainer } from "./styledComponents/BlockContainer";

interface PageBlockProps {
  children: ReactNode;
}

export function PageBlock({ children }: PageBlockProps) {
  return <BlockContainer>{children}</BlockContainer>;
}
