import { ExerciseResultStatus } from "@packages/store/models/ExerciseResult/ExerciseResult";

export interface ButtonData {
  buttonClass?: string;
  buttonText: string;
}

export type ButtonType = "success" | "error" | "checking";

export const {
  NEW_CHECK,
  PROCESSING,
} = ExerciseResultStatus;