function objectToUrlParams(obj: Record<string, any>, parentKey?: string): string {
  const params = [];

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const paramName = parentKey ? `${parentKey}[${key}]` : key;

      if (typeof value === 'object' && value !== null) {
        params.push(objectToUrlParams(value, paramName));
      } else {
        params.push(`${paramName}=${value}`);
      }
    }
  }

  return params.join('&');
}

export function objectToEncodedURIParams(data: Record<string, any>): string {
  return encodeURI(objectToUrlParams(data));
}