import { useState } from "react";

import { Typography, Box } from "@mui/material";
import { useStores } from "@packages/store/models";
import { RestorePasswordProps } from "@packages/store/models/AuthStore";
import { Button } from "components/Button";
import { Logo } from "components/Logo";
import { TrimInput } from "components/TrimInput";
import {
  FormContainer,
  FormSubTitle,
  FormWrapper,
  InputsContainer,
  FormTitle,
  StyledRouterLink,
  LogoWrapper,
} from "pages/Login/styledComponents";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ROUTES } from "router/constants";

export const RestorePasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RestorePasswordProps>();
  const { t } = useTranslation();

  const { auth } = useStores();

  const [restorePasswordRequest, setRestorePasswordRequest] = useState(false);

  const onSubmit: SubmitHandler<RestorePasswordProps> = ({ email }) => {
    auth.restorePassword(email);
    setRestorePasswordRequest(true);
  };

  return (
    <FormWrapper>
      <FormContainer>
        <LogoWrapper>
          <Logo width={128} height={32} />
        </LogoWrapper>

        {!restorePasswordRequest ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormTitle>
              {t(`Login:restorePasswordTitle`, { lng: "ru" })}
            </FormTitle>

            <FormSubTitle variant="regularText">
              {t(`Login:restorePasswordSubTitle`, { lng: "ru" })}
            </FormSubTitle>

            <InputsContainer sx={{ mb: 5 }}>
              <TrimInput
                variant="standard"
                label={t(`Login:email`, { lng: "ru" })}
                {...register("email", { required: true })}
                error={!!errors.email}
              />
            </InputsContainer>

            <Button type="submit" variant="primary" sx={{ width: "100%" }}>
              {t(`Login:title`, { lng: "ru" })}
            </Button>
          </form>
        ) : (
          <Box>
            <Typography variant="h2">
              {t(`Login:restorePasswordSuccess`, { lng: "ru" })}
            </Typography>
          </Box>
        )}

        <Box sx={{ mt: 4 }}>
          <StyledRouterLink to={ROUTES.SIGN_IN_PAGE}>
            {t(`Login:toSignIn`, { lng: "ru" })}
          </StyledRouterLink>
        </Box>
      </FormContainer>
    </FormWrapper>
  );
};
