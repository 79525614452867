import { useCallback, useLayoutEffect } from "react";

import { Box, TextField } from "@mui/material";
import { Tag } from "@packages/store/models/Tag/Tag";
import { Button } from "components/Button";
import { Modal, ModalProps } from "components/shared/Modal";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface TagModalProps extends Omit<ModalProps, "children" | "onSubmit"> {
  initialData: Tag | null;
  onSubmit: (data: TagModalFormValues) => void;
}

export interface TagModalFormValues {
  name: string;
}

export const TagModal = ({
  initialData,
  onSubmit,
  onClose,
  ...other
}: TagModalProps): JSX.Element | null => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<TagModalFormValues>();

  const resetToInitial = useCallback(() => {
    setValue("name", initialData?.name ?? "");

    clearErrors();
  }, [initialData?.name, setValue, clearErrors]);

  const handleClose = (...args: Parameters<NonNullable<typeof onClose>>) => {
    resetToInitial();

    onClose?.(...args);
  };

  useLayoutEffect(() => {
    resetToInitial();
  }, [resetToInitial]);

  return (
    <Modal onClose={handleClose} {...other}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          type="text"
          label="Tag Name"
          variant="outlined"
          {...register("name", { required: "Required" })}
          error={!!errors.name}
          helperText={errors.name?.message || " "}
        />

        <Box display="flex" justifyContent="flex-end">
          <Button variant="primary" type="submit">
            {t("TaggingList:ModalSubmit")}
          </Button>
        </Box>
      </form>
    </Modal>
  );
};
