import { Box, useTheme } from "@mui/material";
import { useDrop } from "react-dnd";

import { MovingDropItem } from "./MovingDropzone";
import { DragItemType, StatusType } from "../../types";

interface MovingDropzoneColumnProps {
  question: string;
  place: number;
  status: StatusType;
  children?: JSX.Element[] | JSX.Element | null;
}

export const MovingDropzoneColumn = ({
  question,
  place,
  status,
  children,
}: MovingDropzoneColumnProps): JSX.Element | null => {
  const theme = useTheme();

  const [, drop] = useDrop(
    () => ({
      accept: DragItemType.ANSWER,
      drop: (): MovingDropItem => ({
        question,
        place,
      }),
    }),
    [question, place]
  );

  return (
    <Box
      ref={drop as any}
      sx={{
        flex: 1,
        minHeight: 100,
        minWidth: 150,
        p: 3,
        backgroundColor:
          theme.palette.custom.answerColorVariant.background[status],
        borderBottomLeftRadius: theme.spacing(3),
        borderBottomRightRadius: theme.spacing(3),
      }}
    >
      {children}
    </Box>
  );
};
