/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { UsefulLinkModel, UsefulLinkSnapshot } from "./UsefulLink";

/**
 * Model description here for TypeScript hints.
 */
export const UsefulLinkStoreModel = types
  .model("LessonStore")
  .props({
    items: types.optional(types.array(UsefulLinkModel), []),
  })

  .actions((self) => ({
    addItem: (item: UsefulLinkSnapshot) => {
      if (resolveIdentifier(UsefulLinkModel, self, item.id)) return;
      self.items.push({ ...item });
    },
  }));
/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type UsefulLinkStoreType = Instance<typeof UsefulLinkStoreModel>;
export type UsefulLinkStore = UsefulLinkStoreType;
type UsefulLinkStoreSnapshotType = SnapshotOut<typeof UsefulLinkStoreModel>;
export type UsefulLinkStoreSnapshot = UsefulLinkStoreSnapshotType;
