import { Typography, useTheme } from "@mui/material";
import { Theme as ThemeProps } from "@mui/material/styles/createTheme";
import { formatRelative } from "date-fns";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { getCurrentLocale } from "locales/dateFnsLocale";

import {
  ContentContainer,
  NumberCard,
  DateStatusContainer,
  IconContainerCard,
  LeftCardContent,
  MiddleLine,
  NumberContainer,
  RightContainer,
  TitleDateCard,
} from "./styledComponents";
import { ContentDate } from "./styledComponents/ContentDate";
import { StatusCardContent } from "./styledComponents/StatusCardContent";
import { TitleTimeCard } from "./styledComponents/TitleTimeCard";
import { Button } from "../Button";
import { Icon, IconType } from "../Icon";
import { LessonStatuses, LessonStatusesProps } from "../LessonStatuses";

export interface CardProps {
  dateCard: string;
  timeCard: string;
  typeStatus: LessonStatusesProps["type"];
  numberCard: number;
  iconType: IconType;
  iconColor: string;
  typeButton: "default" | "success" | "cancel";
  disabledButton: boolean;
  url: string;
}

export interface CardContainerProps {
  iconColor: CardProps["iconColor"];
  theme: ThemeProps;
}

type statusColor = {
  [key in Lesson["status"]]: string;
};

export const Card = ({
  dateCard,
  timeCard,
  numberCard,
  typeStatus,
  iconType,
  iconColor,
  typeButton,
  url,
}: CardProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const statusColor: statusColor = {
    completed: theme.palette.success.main,
    planned: theme.palette.custom?.grey?.main,
    earlyCanceledByCustomer: theme.palette.warning.dark,
    earlyCanceledBySchool: theme.palette.warning.dark,
    lateCanceledByCustomer: theme.palette.warning.main,
    lateCanceledBySchool: theme.palette.warning.main,
  };

  const variantRender = {
    success: (
      <Link to={url}>
        <Button variant="secondary">
          <Typography variant="button">
            {t(`CardClass:WatchRecording`)}
          </Typography>
        </Button>
      </Link>
    ),
    cancel: (
      <Button variant="secondary" disabled>
        <Typography variant="button">{t(`CardClass:Join`)}</Typography>
      </Button>
    ),
    default: (
      <Link to={url}>
        <Button variant="primary">
          <Typography variant="button">{t(`CardClass:Join`)}</Typography>
        </Button>
      </Link>
    ),
  };

  return (
    <ContentContainer>
      <LeftCardContent>
        <NumberContainer>
          <NumberCard color={statusColor[typeStatus]}>
            <Typography>{numberCard}</Typography>
          </NumberCard>
        </NumberContainer>

        <DateStatusContainer>
          <ContentDate>
            <TitleTimeCard variant="h5">
              {formatRelative(new Date(timeCard), new Date(), {
                locale: getCurrentLocale(),
              })}
            </TitleTimeCard>
            <TitleDateCard variant="h5">{dateCard}</TitleDateCard>
          </ContentDate>

          <StatusCardContent>
            <LessonStatuses type={typeStatus} />
          </StatusCardContent>
        </DateStatusContainer>
      </LeftCardContent>

      <MiddleLine />

      <RightContainer>
        <IconContainerCard iconColor={iconColor} theme={theme}>
          <Icon type={iconType} width={20} height={25} />
        </IconContainerCard>
        {variantRender[typeButton]}
      </RightContainer>
    </ContentContainer>
  );
};
