import { useState, useEffect, useCallback } from "react";

import { useLocation, useSearchParams } from "react-router-dom";

import {
  buildSearchParams,
  parseSearchParams,
} from "@packages/shared/utils/searchParams";

import { DateRange } from "components/DateRangePicker";
import { ROUTES } from "router/constants";

export const useLessonFilter = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [tablePage, setTablePage] = useState(0);
  const [sampleByStatus, setSampleByStatus] = useState(false);
  const [onlyLessonsWithoutGroup, setOnlyLessonsWithoutGroup] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange>([null, null]);
  const [company, setCompany] = useState("");
  const [group, setGroup] = useState("");
  const [teacher, setTeacher] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    const params = parseSearchParams<any>(searchParams);

    const getDateRange = (): DateRange => {
      return [params?.startDate ?? null, params?.endDate ?? null];
    };

    setSampleByStatus(params?.sampleByStatus ?? false);
    setOnlyLessonsWithoutGroup(params?.onlyLessonsWithoutGroup ?? false);
    setDateRange(getDateRange());
    setCompany(params?.company ?? "");
    setGroup(params?.group ?? "");
    setTeacher(params?.teacher ?? "");
    setStatus(params?.status ?? "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pathname !== ROUTES.CLASSES) {
      return;
    }

    const newSearchParams = buildSearchParams({
      sampleByStatus,
      onlyLessonsWithoutGroup,
      ...(company && { company }),
      ...(group && { group }),
      ...(teacher && { teacher }),
      ...(status && { status }),
      ...(dateRange[0] && { startDate: dateRange[0] }),
      ...(dateRange[1] && { endDate: dateRange[1] }),
    });

    setSearchParams(newSearchParams);
  }, [
    company,
    dateRange,
    teacher,
    group,
    pathname,
    status,
    sampleByStatus,
    setSearchParams,
    onlyLessonsWithoutGroup,
  ]);

  const onDateRangeChange = useCallback((value: DateRange) => {
    setDateRange(value);
    setTablePage(0);
  }, []);

  const onCompanyChange = useCallback((value: string) => {
    setCompany(value);
    setTablePage(0);
  }, []);

  const onGroupChange = useCallback((value: string) => {
    setGroup(value);
    setTablePage(0);
  }, []);

  const onTeacherChange = useCallback((value: string) => {
    setTeacher(value);
    setTablePage(0);
  }, []);

  const onStatusChange = useCallback((value: string) => {
    setStatus(value);
    setTablePage(0);
  }, []);

  const onSampleByStatusChange = useCallback((value: boolean) => {
    setSampleByStatus(value);
    setTablePage(0);
  }, []);

  const onOnlyLessonsWithoutGroupChange = useCallback((value: boolean) => {
    setOnlyLessonsWithoutGroup(value);
    setTablePage(0);
  }, []);

  return {
    sampleByStatus,
    dateRange,
    company,
    teacher,
    group,
    status,
    onlyLessonsWithoutGroup,
    onSampleByStatusChange,
    onDateRangeChange,
    onCompanyChange,
    onTeacherChange,
    onGroupChange,
    onStatusChange,
    onOnlyLessonsWithoutGroupChange,
    tablePage,
    setTablePage,
  };
};
