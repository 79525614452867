import { ComponentsOverrides, Theme } from "@mui/material";

interface muiInputProps {
  styleOverrides: ComponentsOverrides["MuiInput"];
}

export const muiInput = (theme: Theme): muiInputProps => ({
  styleOverrides: {
    root: {
      color: theme.palette.primary.dark,
      "&.MuiSelect-root": {
        "&:before": {
          borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
        },
      },
      input: {
        color: theme.palette.primary.dark,
        padding: "0.625rem 0",
        fontWeight: 500,
        fontSize: "0.813rem",
        lineHeight: theme.spacing(1),
        "&::placeholder": {
          color: theme.palette.custom?.grey?.main,
        },
      },
    },
  },
});
