import { ButtonBase, ButtonBaseProps, styled } from "@mui/material";

interface StyledTranslationButtonProps extends ButtonBaseProps {
  active: boolean;
}

export const StyledTranslationButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "active",
})<StyledTranslationButtonProps>(({ theme, active }) => ({
  ...theme.typography.regularText,
  position: "fixed",
  top: "65vh",
  right: theme.spacing(3),
  zIndex: 1000,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 48,
  height: 48,
  borderRadius: "50%",
  backgroundColor: theme.palette.info.contrastText,
  border: "1px solid",
  borderColor: theme.palette.info.main,
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
  transition: theme.transitions.create(["background-color"]),

  ...(active && {
    backgroundColor: theme.palette.info.main,
  }),
}));
