import { Lesson } from "@packages/store/models/Lesson/LessonModel";

export const sortByDate =
  (direction: "asc" | "desc") => (prev: Lesson, next: Lesson) => {
    if (!prev.startAtLocal || !next.startAtLocal) {
      return -1;
    }

    if (direction === "desc") {
      return prev.startAtLocal > next.startAtLocal ? -1 : 1;
    }

    return prev.startAtLocal > next.startAtLocal ? 1 : -1;
  };

export const sortByProperty =
  <T extends keyof Lesson>(propName: T, direction: "asc" | "desc") =>
  (prev: Lesson, next: Lesson) => {
    if (!prev[propName] || !next[propName]) {
      return -1;
    }

    if (direction === "desc") {
      return prev[propName] - next[propName];
    }

    return next[propName] - prev[propName];
  };
