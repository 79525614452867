type DiffPatch = Array<{
  action: string;
  position: number;
  element: string;
}>;

export const applyDiffPatch = (str: string, diffPatch: DiffPatch): string => {
  const strArr = str.split(" ");

  const removeActions = diffPatch
    .filter(({ action }) => action === "-")
    // Нужно удалять слова в обратном порядке чтобы они не мешали друг другу
    .sort((a, b) => b.position - a.position);

  const addActions = diffPatch
    .filter(({ action }) => action === "+")
    .sort((a, b) => a.position - b.position);

  removeActions.forEach(({ position }) => strArr.splice(position, 1));

  addActions.forEach(({ position, element }) =>
    strArr.splice(position, 0, element)
  );

  return strArr.join(" ");
};
