import { Box, Typography } from "@mui/material";
import { useStores } from "@packages/store/models";
import { Course } from "@packages/store/models/Course/CourseModel";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ModalEnglishEntranceTestResult } from "./ModalEnglishEntranceTestResult";
import { Button } from "../../Button";
import { Modal } from "../../shared/Modal";

interface ModalTestResultProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  course: Course | null;
}

export const ModalTestResult = observer(
  (props: ModalTestResultProps): JSX.Element | null => {
    const { isOpen, onConfirm, onClose, course } = props;

    const { t } = useTranslation();
    const { auth } = useStores();

    const { passing, maxMark, code } = course ?? {};
    const { testingKind, cefrLevel, mark, courseMark } = passing ?? {};
    const realMark = courseMark ?? mark ?? 0;
    const realMaxMark = maxMark ?? 0;
    const percentage = ((realMark / realMaxMark) * 100).toFixed(2);
    const { fullName } = auth.user ?? {};

    // Модалка результатов входного теста по Английскому языку
    if (testingKind === "entrance" && code === "89") {
      return <ModalEnglishEntranceTestResult {...props} />;
    }

    const renderContent = () => {
      if (testingKind === "entrance") {
        return t("TestingCourseResult:ModalBodyEntrance", {
          fullName,
          cefrLevel,
        });
      }

      if (testingKind === "final") {
        return t("TestingCourseResult:ModalBodyFinal", {
          fullName,
          courseMark: realMark,
          maxMark: realMaxMark,
          percentage,
        });
      }

      return t("TestingCourseResult:ModalBodyDefault", { fullName });
    };

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        type="small"
        title={t("TestingCourseResult:ModalTitle")}
        containerStyles={{ paddingBottom: "1.5rem" }}
      >
        <Typography sx={{ marginBottom: "1.25rem", whiteSpace: "pre-line" }}>
          {renderContent()}
        </Typography>
        <Box display="flex" justifyContent="flex-end" gap="1rem">
          <Button variant="primary" onClick={onConfirm}>
            {t("TestingCourseResult:Confirm")}
          </Button>
        </Box>
      </Modal>
    );
  }
);
