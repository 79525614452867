import { useStores } from "@packages/store/models";
import { Question } from "@packages/store/models/Question/Question";
import { FlexList } from "../../../../../FlexList";
import { observer } from "mobx-react-lite";
import { CreativeExercisePlug } from "../../../../../CreativeExercisePlug";
import { SpeakingQuestion } from "../../../../../SpeakingQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Plain = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const rootStore = useStores();

    const {
      id: exerciseId,
      questions,
      answer,
      state,
      isChecked,
      checkingResultId,
    } = exercise;

    const { getQuestion } = ensure(answer);

    const renderDefault = (): JSX.Element | null => {
      return (
        <FlexList
          gap={4}
          items={questions}
          renderItem={(question: Question, index, array: Question[]) => (
            <SpeakingQuestion
              key={question.id}
              question={question}
              answerData={ensure(getQuestion(question.id))}
              saveRecordingRequest={(formData: FormData) =>
                rootStore.environment.api.saveRecording(
                  exerciseId,
                  question.id,
                  formData
                )
              }
              {...(array.length > 1 && {
                order: index + 1,
              })}
            />
          )}
        />
      );
    };

    return isChecked ? (
      <CreativeExercisePlug state={state} checkingResultId={checkingResultId} />
    ) : (
      renderDefault()
    );
  }
);
