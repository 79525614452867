import { useEffect, useRef } from "react";

import { throttle } from "lodash";

export interface TimerProps {
  reportInterval?: number;
  idleTimeout?: number;
  onPing?: (_: number) => void;
  onVisit?: () => void;
}

export const Timer = ({
  reportInterval = 2,
  idleTimeout = 20,
  onPing,
  onVisit,
}: TimerProps): JSX.Element => {
  const started = useRef(false);
  const stopped = useRef(false);
  const clockTime = useRef(0);
  const clockTimer = useRef<NodeJS.Timeout | null>();
  const idleTimer = useRef<NodeJS.Timeout | null>();

  const clearClockTimer = () => {
    if (!clockTimer.current) {
      return;
    }
    clearInterval(clockTimer.current);
  };

  const stopClock = () => {
    stopped.current = true;
    clearClockTimer();
  };

  const clearIdleTimer = () => {
    if (!idleTimer.current) {
      return;
    }
    clearTimeout(idleTimer.current);
  };

  const setIdle = () => {
    clearIdleTimer();
    stopClock();
  };

  const onVisibilityChange = () => {
    if (!document.hidden) {
      return;
    }
    setIdle();
  };

  const clock = () => {
    clockTime.current += 1;
    if (clockTime.current <= 0 || clockTime.current % reportInterval !== 0) {
      return;
    }
    onPing?.(clockTime.current);
  };

  const restartClock = () => {
    clearClockTimer();
    clockTimer.current = setInterval(clock, 1000);
  };

  const clear = () => {
    clearClockTimer();
    clearIdleTimer();
  };

  const startLogger = () => {
    started.current = true;
    onVisit?.();
    restartClock();
  };

  const onTrigger = () => {
    if (!started.current) {
      startLogger();
    }

    if (stopped.current) {
      restartClock();
    }

    clearIdleTimer();
    idleTimer.current = setTimeout(setIdle, idleTimeout * 1000 + 100);
  };

  useEffect(() => {
    const throttleTrigger = throttle(onTrigger, 500);

    document.addEventListener("keydown", onTrigger, false);
    document.addEventListener("click", onTrigger, false);
    window.addEventListener("mousemove", throttleTrigger, false);
    window.addEventListener("scroll", throttleTrigger, false);

    document.addEventListener("visibilitychange", onVisibilityChange, false);
    window.addEventListener("blur", setIdle);

    onTrigger();

    return () => {
      document.removeEventListener("keydown", onTrigger);
      document.removeEventListener("click", onTrigger);
      window.removeEventListener("mousemove", throttleTrigger);
      window.removeEventListener("scroll", throttleTrigger);

      document.removeEventListener("visibilitychange", onVisibilityChange);
      window.removeEventListener("blur", setIdle);

      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
