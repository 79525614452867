import { SyntheticEvent } from "react";

import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { StyledNavigationWidget } from "./styledComponents/StyledNavigationWidget";
import { tablet } from "../../constants/styles";
import { Icon, IconType } from "../Icon";
import { OverflowTypography } from "../OverflowTypography";

export interface NavigationWidgetProps {
  title: string | undefined;
  subTitle: string;
  iconType: IconType;
  onClick: (event?: SyntheticEvent) => void;
  loading?: boolean;
  disabled?: boolean;
}

export const NavigationWidget = ({
  title,
  subTitle,
  onClick,
  iconType,
  loading,
  disabled,
}: NavigationWidgetProps): JSX.Element | null => {
  const theme = useTheme();
  const isTouchScreen = useMediaQuery(theme.breakpoints.down(tablet));

  if (loading) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          height: 76,
          py: 3,
          px: 4,
          borderRadius: 3,
          [theme.breakpoints.down(tablet)]: {
            height: 40,
          },
        }}
      />
    );
  }

  const handleClick = (event: SyntheticEvent) => {
    if (!disabled) {
      onClick(event);
    }
  };

  return (
    <StyledNavigationWidget disabled={disabled} onClick={handleClick}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          [theme.breakpoints.up(tablet)]: {
            mb: 2,
          },
        }}
      >
        {!isTouchScreen && (
          <Icon
            type={iconType}
            color={theme.palette.primary.main}
            sizeSquareIcon={20}
          />
        )}

        <Typography variant="h3" color="primary.main" textTransform="lowercase">
          {subTitle}
        </Typography>
      </Box>

      {!isTouchScreen && (
        <OverflowTypography color="inherit">{title}</OverflowTypography>
      )}
    </StyledNavigationWidget>
  );
};
