import { styled, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "1px",
    width: "100%",
    backgroundColor: theme.palette.custom.grey.light,
  },
  "& .MuiTabs-indicator": { backgroundColor: theme.palette.info.dark },
  // "& .MuiTabs-flexContainer": { gap: "1rem" },
}));
