import { observer } from "mobx-react-lite";

import {
  SetupPasswordForm,
  Intro,
  LinkDrawer,
  ToastErrorMessage,
} from "./components";
import { Container } from "./styledComponents";

export const SetupPassword = observer((): JSX.Element => {
  return (
    <>
      <Container>
        <Intro />
        <SetupPasswordForm />
        <LinkDrawer />
      </Container>
      <ToastErrorMessage />
    </>
  );
});
