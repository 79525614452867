// import { Box, Typography } from "@mui/material";
import { ExerciseUISubtype } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";
// import { useTranslation } from "react-i18next";
// import { useLocation } from "react-router";

// import { JetLink } from "components/JetLink";
import { useSpeakingExercise } from "../../../../hooks/useSpeakingExercise";
import {
  SubExerciseComponentProps,
  ExerciseComponentProps,
} from "../../../../types";

import * as subExercises from "./subExercises";

const subExerciseMap: {
  [key in ExerciseUISubtype]?: (
    props: SubExerciseComponentProps
  ) => JSX.Element | null;
} = {
  ...subExercises,
};

export const Speaking = observer(
  ({ exercise }: ExerciseComponentProps): JSX.Element | null => {
    // const { t } = useTranslation();

    // const { pathname, search } = useLocation();

    const { answer, uiSubtype } = exercise;

    useSpeakingExercise(exercise);

    if (!answer || answer.isEmpty) {
      return null;
    }

    const SubExerciseComponent = subExerciseMap[uiSubtype];

    if (!SubExerciseComponent) {
      return <></>;
    }

    // const courseId = pathname.match(/\/my-courses\/(.*)\/[a-zA-Z]+/)?.[1];
    // const unitId = pathname.match(/\/exercises\/(.*)/)?.[1];
    // const blockId = search.match(/blockId=(.*)&/)?.[1];
    // const exerciseId = search.match(/exerciseId=(.*)/)?.[1];

    // return (
    //   <Box>
    //     <Typography variant="h3">
    //       {t("Exercise:DevTooltip")}
    //       <span style={{ marginLeft: 4 }}>
    //         <JetLink
    //           to={`/learn/courses/${courseId}/units/${unitId}?block_id=${blockId}&exercise_id=${exerciseId}`}
    //           external
    //           newTab
    //         >
    //           {t("Exercise:FollowLink").toLowerCase()}
    //         </JetLink>
    //       </span>
    //     </Typography>
    //   </Box>
    // );

    return <SubExerciseComponent exercise={exercise} />;
  }
);
