import { Box, styled } from "@mui/material";

export const DesktopContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "2rem",
  marginBottom: "1rem",
  [theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));
