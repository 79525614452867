/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, SxProps, Theme } from "@mui/material";
import { observer } from "mobx-react-lite";

export interface ListProps<T> {
  items: T[];
  renderItem: (item: T, index: number, array: T[]) => JSX.Element | null;
  containerStyles?: SxProps<Theme>;
  emptyNull?: boolean;
}

export const List = <T,>({
  items,
  renderItem,
  containerStyles,
  emptyNull,
}: ListProps<T>): JSX.Element | null => {
  if (emptyNull) {
    return (
      <>
        {items.length > 0 && (
          <Box sx={containerStyles}>{items.map(renderItem)}</Box>
        )}
      </>
    );
  }

  return (
    <Box sx={containerStyles}>{items.length > 0 && items.map(renderItem)}</Box>
  );
};

export const ObserverList = observer(List);
