import { styled } from "@mui/material/styles";

export const ContainerIcon = styled("div")(() => ({
  display: "flex",
  width: "2.5rem",
  justifyContent: "space-between",
  alignItems: "center",
  height: "1.5rem",
  "& path": {
    stroke: "white",
  },
}));
