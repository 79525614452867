import { useEffect, useState } from "react";

import { Typography, Box } from "@mui/material";
import { format } from "date-fns";
import { chunk } from "lodash";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { useStores } from "@packages/store/models";
import { GetLearningGroupsParams } from "@packages/store/services/Api";

import { Loading } from "components/Loading";
import { useCancelableFetch } from "hooks";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";
import { ROUTES } from "router/constants";

import { AttendanceReportGroups } from "./AttendanceReportGroups";
import {
  ReportsAttendanceFilter,
  ReportsAttendanceDownload,
} from "./components";
import { DateRange } from "./types";

const groupsPerPage = 30;

export const AttendanceReports = observer((): JSX.Element => {
  const { t } = useTranslation();
  const { loading, auth, customerCompany, learningGroup, api } = useStores();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { user } = auth;
  const { items: customerCompanies } = customerCompany;
  const { items: learningGroups } = learningGroup;

  const {
    fetch: fetchGroups,
    loading: loadingGroups,
    error: groupsError,
  } = useCancelableFetch();

  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const [groupId, setGroupId] = useState("");
  const [studentFilter, setStudentFilter] = useState("");
  const [companyFilter, setCompanyFilter] = useState("");
  // Переключалка того чтобы преподавателю показывать только уроки преподавателя
  const [onlyTeachersLessons, setOnlyTeachersLessons] = useState(true);
  const [page, setPage] = useState(1);
  const [maximumGroups, setMaximumGroups] = useState(groupsPerPage);

  const [cachedCompanies, setCachedCompanies] = useState<string[]>([]);

  const { isHR = false, isStudent = false } = user ?? {};

  const downloadButtonVisible = isHR ? true : groupId || companyFilter;

  const filteredGroups = computed(() => {
    return learningGroups
      .filter((item) => {
        if (isStudent) return true;

        return groupId ? item.id === groupId : true;
      })
      .filter((item) => {
        if (isStudent || !companyFilter) return true;

        return item.customerCompany?.id === companyFilter;
      })
      .filter(() => {
        if (isHR || isStudent) return true;

        return groupId || companyFilter;
      })
      .filter((item) => {
        if (!isStudent) return true;

        return item.status === "learning";
      })
      .sort((prev, next) => {
        const prevName = prev.name ?? "";
        const nextName = next.name ?? "";
        return nextName < prevName ? 1 : -1;
      });
  }).get();

  const groupsOnPage = computed(
    () => chunk(filteredGroups, groupsPerPage)[page - 1] ?? []
  ).get();

  // Синхронизация search параметров с состоянием
  useEffect(() => {
    if (pathname !== ROUTES.ATTENDANCE_REPORT) {
      return;
    }
    setGroupId(searchParams.get("groupId") ?? "");
    setCompanyFilter(searchParams.get("companyFilter") ?? "");
    const startDate = searchParams.get("startDate");
    const endDate = searchParams.get("endDate");
    setDateRange({
      startDate: startDate ? new Date(startDate) : undefined,
      endDate: endDate ? new Date(endDate) : undefined,
    });
    setPage(Number(searchParams.get("page")));
  }, [setGroupId, setCompanyFilter, setDateRange, setPage]);

  // Синхронизация состояния с search параметрами
  useEffect(() => {
    if (pathname !== ROUTES.ATTENDANCE_REPORT) {
      return;
    }

    const checkIsChanged = (key: string, value: string) => {
      return value !== null && searchParams.get(key) !== value;
    };

    const getParamValue = (key: string, value: string) => {
      return value && { [key]: value };
    };

    const filters = {
      companyFilter,
      groupId,
      startDate: dateRange?.startDate
        ? format(dateRange.startDate, "yyyy-MM-dd")
        : "",
      endDate: dateRange?.endDate
        ? format(dateRange.endDate, "yyyy-MM-dd")
        : "",
      page,
    };

    const changedFilters = Object.entries(filters).filter(([key, value]) =>
      checkIsChanged(key, value as string)
    );

    if (changedFilters.length === 0) {
      return;
    }

    const params = Object.entries(filters)
      .map(([key, value]) => getParamValue(key, value as string))
      .reduce(
        (param, result) => ({
          ...result,
          ...param,
        }),
        {}
      );
    setSearchParams(params);
  }, [
    setSearchParams,
    companyFilter,
    groupId,
    dateRange,
    searchParams,
    page,
    pathname,
  ]);

  useEffect(() => {
    if (!companyFilter) return;
    if (!cachedCompanies.includes(companyFilter)) {
      setCachedCompanies((prev) => [...prev, companyFilter]);
    }
  }, [cachedCompanies, companyFilter, customerCompanies, setCachedCompanies]);

  // Запрос групп на изменения фильтров
  useEffect(() => {
    const filters: GetLearningGroupsParams = {
      includeLessons: true,
    };
    if (companyFilter) {
      filters.company = companyFilter;
    }
    if (groupId) {
      filters.ids = [groupId];
    }
    if (page) {
      filters.page = page;
      filters.limit = groupsPerPage;
    }
    filters.sortBy = "name";
    // if (!filters.company && !filters.ids) return;
    fetchGroups((token) => api.getLearningGroups(filters, token));
  }, [companyFilter, groupId, learningGroups.length, page, fetchGroups, api]);

  // Отдельный запрос групп для HR
  useEffect(() => {
    if (!isHR) {
      return;
    }

    const filters: GetLearningGroupsParams = {
      includeLessons: true,
      page,
      sortBy: "name",
      limit: groupsPerPage,
    };

    fetchGroups((token) => api.getLearningGroups(filters, token));
  }, [isHR]);

  useEffect(() => {
    setPage(1);
  }, [groupId, studentFilter, companyFilter]);

  useEffect(() => {
    if (filteredGroups.length % groupsPerPage !== 0) {
      setMaximumGroups(filteredGroups.length);
      return;
    }
    setMaximumGroups(filteredGroups.length + groupsPerPage);
  }, [filteredGroups, filteredGroups.length]);

  return (
    <WrapperMainStudents>
      <Box
        my="1.5rem"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h1" component="span">
          {t("ReportsAttendance:Title")}
        </Typography>
        {downloadButtonVisible && (
          <ReportsAttendanceDownload
            groups={filteredGroups}
            dateRange={dateRange}
            onlyTeachersLessons={onlyTeachersLessons}
          />
        )}
        {!downloadButtonVisible && <Box height="3rem" />}
      </Box>
      <Loading loading={loading} error={groupsError}>
        <>
          {/* Студенту показываются только свои группы */}
          {!isStudent && (
            <ReportsAttendanceFilter
              group={groupId}
              company={companyFilter}
              student={studentFilter}
              onlyTeachersLessons={onlyTeachersLessons}
              onGroupChange={setGroupId}
              onStudentChange={setStudentFilter}
              onCompanyChange={setCompanyFilter}
              dateFilterProps={{
                dateRange,
                onDateRangeChange: setDateRange,
              }}
              onOnlyTeachersLessonsChange={setOnlyTeachersLessons}
            />
          )}

          <AttendanceReportGroups
            page={page}
            groups={groupsOnPage}
            groupsPerPage={groupsPerPage}
            loading={loadingGroups}
            student={studentFilter}
            dateRange={dateRange}
            maximumGroups={maximumGroups}
            onPageChange={setPage}
            onlyTeachersLessons={onlyTeachersLessons}
          />
        </>
      </Loading>
    </WrapperMainStudents>
  );
});
