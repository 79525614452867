import { Lesson } from "@packages/store/models/Lesson/LessonModel";

// filter lesson with max startAtLocal + 1 day
// eslint-disable-next-line @typescript-eslint/no-shadow
export const filterByDate = (lesson: Lesson) => {
  const date = new Date();
  date.setDate(date.getDate() + 2);
  return (lesson?.startAtLocal || 0) < date;
};

export const filterByProperty =
  <T extends keyof Lesson>(propName: T, value: Lesson[T], isEqual = true) =>
  (lesson: Lesson) => {
    if (isEqual) return lesson[propName] === value;

    return lesson[propName] !== value;
  };

export const filterByEditable =
  (isEditable: boolean) =>
  ({ editable }: Lesson) => {
    return editable === isEditable;
  };

export const filterByFlexible = (isFlexible: boolean) => (lesson: Lesson) => {
  return lesson.learningGroup?.flexible === isFlexible;
};
