import { Instance, SnapshotOut, resolveIdentifier, types } from "mobx-state-tree";

import { ExerciseResult, ExerciseResultModel } from "./ExerciseResult";

export const ExerciseResultStoreModel = types
  .model("ExerciseResultStore", {
    items: types.optional(types.array(ExerciseResultModel), []),
  })
  .actions((self) => ({
    addItem: (item: ExerciseResult) => {
      const instance = resolveIdentifier(ExerciseResultModel, self, item.id);

      if (instance) {
        Object.assign(instance, item);
        return;
      }

      self.items.push(item);
    },
  }));

type ExerciseResultStoreType = Instance<typeof ExerciseResultStoreModel>;
export type ExerciseResultStore = ExerciseResultStoreType;
type ExerciseResultStoreSnapshotType = SnapshotOut<typeof ExerciseResultStoreModel>;
export type KnowledgeLevelSnapshot = ExerciseResultStoreSnapshotType;