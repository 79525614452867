import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const LicenseModel = types.model({
  activatedAt: types.maybeNull(types.string),
  customerCompany: types.maybeNull(
    types.model({
      id: types.maybeNull(types.string),
      code: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
    })
  ),
  id: types.maybeNull(types.string),
  licenseType: types.maybeNull(
    types.model({
      id: types.maybeNull(types.string),
      code: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
    })
  ),
  licenseTypeId: types.maybeNull(types.number),
  serialNumber: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  remainsDays: types.maybeNull(types.number),
  expiresAt: types.maybeNull(types.string),
});

type LicenseType = Instance<typeof LicenseModel>;
export type License = LicenseType;
type LicenseSnapshotType = SnapshotOut<typeof LicenseModel>;
export type LicenseSnapshot = LicenseSnapshotType;
export const createLicenseDefaultModel = () =>
  types.optional(LicenseModel, {} as LicenseSnapshot);
