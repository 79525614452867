import { Box, Autocomplete, TextField, Button } from "@mui/material";
import { useStores } from "@packages/store";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { DatePicker } from "components/DatePicker";
import { getAutocompleteFieldValue } from "utils/helpers";

export interface CompaniesFormValues {
  startDate: Date | null;
  endDate: Date | null;
  companyId: string;
}

interface CompaniesReportFormProps {
  onSubmit: (data: CompaniesFormValues) => void;
}

export const CompaniesReportForm = observer(
  ({ onSubmit }: CompaniesReportFormProps): JSX.Element | null => {
    const { t } = useTranslation();

    const { customerCompany } = useStores();

    const {
      control,
      formState: { errors },
      handleSubmit,
    } = useForm<CompaniesFormValues>({
      defaultValues: {
        startDate: null,
        endDate: null,
        companyId: "",
      },
    });

    const companies = computed(() =>
      customerCompany.items
        .map((item) => ({
          label: item.name as string,
          value: item.id,
        }))
        .concat([
          {
            label: t("CompaniesReport:FilterAllCompanies"),
            value: "",
          },
        ])
        .sort((prev, next) => {
          return prev.label > next.label ? 1 : -1;
        })
    ).get();

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          gap="1rem"
          flexWrap="wrap"
          alignItems="flex-end"
          mt="1.5rem"
          mb="0.5rem"
        >
          <Controller
            name="startDate"
            control={control}
            rules={{
              required: t("CompaniesReport:FilterDateRequired") ?? "",
            }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={value}
                onChange={onChange}
                label={t("CompaniesReport:FilterStartDate")}
                textFieldProps={{
                  error: !!errors.startDate,
                  helperText: errors.startDate?.message || " ",
                }}
              />
            )}
          />

          <Controller
            name="endDate"
            control={control}
            rules={{
              required: t("CompaniesReport:FilterDateRequired") ?? "",
            }}
            render={({ field: { value, onChange } }) => (
              <DatePicker
                value={value}
                onChange={onChange}
                label={t("CompaniesReport:FilterEndDate")}
                textFieldProps={{
                  error: !!errors.endDate,
                  helperText: errors.endDate?.message || " ",
                }}
              />
            )}
          />

          <Controller
            name="companyId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                disablePortal
                openOnFocus
                id="company"
                onChange={(_, v) => onChange((v as any)?.value)}
                options={companies}
                value={getAutocompleteFieldValue(companies, value)}
                sx={{ minWidth: 200, marginTop: "0.5rem", mr: "auto" }}
                placeholder={t("CompaniesReport:FilterSelectCompany") ?? ""}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" helperText=" " />
                )}
              />
            )}
          />

          <Button
            sx={{
              background: "#5783F1",
              color: "white",
              padding: "0.8rem 1.5rem",
              borderRadius: "0.5rem",
              fontSize: "0.8rem",
              mb: "20px",
              ":hover": {
                background: "#5783F1",
              },
            }}
            type="submit"
          >
            {t("CompaniesReport:Process")}
          </Button>
        </Box>
      </form>
    );
  }
);
