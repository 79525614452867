import { useMemo, useState } from "react";

import { Box } from "@mui/material";
import { ExpandedState, createColumnHelper } from "@tanstack/react-table";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";
import { Table } from "components/TableNew";

import { FormattedReportByTeacher } from "../helpers/formatReportByTeacher";
import { TableText } from "../styledComponents/TableText";

interface CancellationReportByTeachersProps {
  items: FormattedReportByTeacher[];
}

export const CancellationReportByTeachers = observer(
  ({ items }: CancellationReportByTeachersProps): JSX.Element | null => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState<ExpandedState>({});

    const columnHelper = createColumnHelper<FormattedReportByTeacher>();

    const columns = useMemo(
      () => [
        columnHelper.accessor("teacher", {
          header: t("CancellationReport:TableTeacher") as string,
          cell: (info) => (
            <TableText
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {info.getValue()}
            </TableText>
          ),
        }),
        columnHelper.accessor("company", {
          header: t("CancellationReport:TableCompany") as string,
          cell: ({ row, getValue }) => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              minWidth="183px"
              {...(row.getCanExpand()
                ? {
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: "pointer" },
                  }
                : {})}
            >
              <TableText>{getValue()}</TableText>
              {row.getCanExpand() && (
                <Box
                  sx={{
                    rotate: row.getIsExpanded() ? "180deg" : 0,
                    width: "1rem",
                    height: "1rem",
                  }}
                >
                  <Icon type="arrowAccordion" width="1rem" height="1rem" />
                </Box>
              )}
            </Box>
          ),
        }),
        columnHelper.accessor("completed", {
          header: t("CancellationReport:TableCompleted") as string,
          cell: (info) => <TableText>{info.getValue()}</TableText>,
        }),
        columnHelper.accessor("lateCanceledByCustomer", {
          header: t("CancellationReport:TableLateCanceledByCustomer") as string,
          cell: (info) => <TableText>{info.getValue()}</TableText>,
        }),
        columnHelper.accessor("earlyCanceledByCustomer", {
          header: t(
            "CancellationReport:TableEarlyCanceledByCustomer"
          ) as string,
          cell: (info) => <TableText>{info.getValue()}</TableText>,
        }),
        columnHelper.accessor("lateCanceledBySchool", {
          header: t("CancellationReport:TableLateCanceledBySchool") as string,
          cell: (info) => <TableText>{info.getValue()}</TableText>,
        }),
        columnHelper.accessor("earlyCanceledBySchool", {
          header: t("CancellationReport:TableEarlyCanceledBySchool") as string,
          cell: (info) => <TableText>{info.getValue()}</TableText>,
        }),
      ],
      [columnHelper, t]
    );

    if (!items.length)
      return <Box>{t("CancellationReport:StandardHelper")}</Box>;

    return (
      <Box>
        <Table
          data={items}
          columns={columns as any}
          pageSize={9999}
          expanded={expanded}
          onExpandedChange={setExpanded}
        />
      </Box>
    );
  }
);
