/* eslint-disable @typescript-eslint/no-empty-interface */
import { IAnyModelType, Instance, SnapshotOut, types } from "mobx-state-tree";
import { LearningGroupModel } from "../LearningGroups/LearningGroupModel";
import { TeacherModel } from "../Teacher/Teacher";

export const LearningGroupsApproveModel = types.model({
  id: types.identifier,
  createdAt: types.maybeNull(types.Date),
  group: types.maybeNull(
    types.reference(types.late((): IAnyModelType => LearningGroupModel))
  ),
  user: types.maybeNull(
    types.reference(types.late((): IAnyModelType => TeacherModel))
  ),
});

type LearningGroupsApproveModelType = Instance<
  typeof LearningGroupsApproveModel
>;
export interface LearningGroupsApprove extends LearningGroupsApproveModelType {}
type LearningGroupsApproveModelTypeSnapshotType = SnapshotOut<
  typeof LearningGroupsApproveModel
>;
export type LearningGroupsApproveSnapshot =
  LearningGroupsApproveModelTypeSnapshotType;
