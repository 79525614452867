/* eslint-disable @typescript-eslint/no-shadow */
import { ExerciseUISubtype, ExerciseUIType } from "../Exercise/Exercise";
import { ExerciseAnswer } from "../ExerciseAnswer/ExerciseAnswer";

const getConfirmationAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  return {
    questions,
  };
};

const getMatchingAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  return {
    questions: questions.map(({ id, answers }) => ({
      id: Number(id),
      answers: answers.map(({ id, text }) => ({
        id: Number(id),
        text,
      })),
    })),
  };
};

const getOrderAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  return {
    questions: questions?.map(({ id, answers }) => ({
      id: Number(id),
      answers: answers.map(({ id }) => ({
        id: Number(id),
      })),
    })),
  };
};

const getWritingAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  return {
    questions: questions.map(({ id, answers }) => ({
      id: Number(id),
      answers: answers.map(({ id, text }) => ({
        id: Number(id),
        options: [text],
      })),
    })),
  };
};

const getChoiceAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  return {
    questions: questions.map(({ id, answers }) => ({
      id: Number(id),
      answers: answers.map(({ id, options }) => ({
        id: Number(id),
        options: options.map(Number),
      })),
    })),
  };
};

const getMovingAnswersData = (
  answer: ExerciseAnswer,
  exerciseUISubType: ExerciseUISubtype
) => {
  const { questions } = answer;

  return {
    questions: questions.map(({ id, answers }) => ({
      id: Number(id),
      answers: answers.map(({ id, place }) => ({
        id: Number(id),
        ...([ExerciseUISubtype.INLINE, ExerciseUISubtype.GAPS].includes(
          exerciseUISubType
        ) && {
          place,
        }),
      })),
    })),
  };
};

const getEssayAnswersData = (answer: ExerciseAnswer) => {
  const { firstQuestion: { id, firstAnswer: { text = null } = {} } = {} } =
    answer;

  return {
    questions: [
      {
        id: Number(id),
        answer: text || "",
      },
    ],
  };
};

const getMistakesAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  return {
    questions: questions.map(({ id, text, noChanged }) => ({
      id: Number(id),
      no_change: noChanged,
      answers: (text || "").split(" ").map((text, index) => ({
        text,
        index,
      })),
    })),
  };
};

const getSpeakingAnswersData = (answer: ExerciseAnswer) => {
  const { questions } = answer;

  const formData = new FormData();

  questions.forEach(
    ({
      id: questionId,
      firstAnswer: { record = null, recordType = null } = {},
    }) => {
      formData.append("answers_data[questions][][id]", String(questionId));
      formData.append("answers_data[questions][][record]", String(record));
      formData.append("answers_data[questions][][type]", String(recordType));
    }
  );

  return formData;
};

export const getAnswersData = (
  answer: ExerciseAnswer,
  exerciseUIType: ExerciseUIType,
  exerciseUISubType: ExerciseUISubtype
) => {
  if (exerciseUIType === ExerciseUIType.CONFIRMATION) {
    return getConfirmationAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.MATCHING) {
    return getMatchingAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.ORDER) {
    return getOrderAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.WRITING) {
    return getWritingAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.CHOICE) {
    return getChoiceAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.MOVING) {
    return getMovingAnswersData(answer, exerciseUISubType);
  }

  if (exerciseUIType === ExerciseUIType.ESSAY) {
    return getEssayAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.MISTAKES) {
    return getMistakesAnswersData(answer);
  }

  if (exerciseUIType === ExerciseUIType.SPEAKING) {
    return getSpeakingAnswersData(answer);
  }

  return () => null;
};
