import { Control, Controller } from "react-hook-form";

import { NumberInput } from "components/shared/NumberInput";
import { ModalPayrollGroupFormValues } from "pages/PayrollAccountant/components/ModalPayrollGroup/ModalPayrollGroup";

import { ModalTeacherLangFormValues } from "../ModalTeacherLang";
import { ModalTeacherRateFormValues } from "../ModalTeacherRate";

type FormValues =
  | ModalTeacherLangFormValues
  | ModalTeacherRateFormValues
  | ModalPayrollGroupFormValues;

export interface AmountInputProps<T extends FormValues> {
  control: Control<T>;
  placeholder: string;
  required: string;
  name: string;
}

export const AmountInput = <T extends FormValues>({
  control,
  placeholder,
  required,
  name,
}: AmountInputProps<T>): JSX.Element | null => {
  return (
    <Controller
      control={control}
      name={name as any}
      rules={{ required }}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <NumberInput
          value={value ?? ""}
          onChange={onChange}
          step="0.01"
          lengthAfterDot={2}
          min="0"
          variant="standard"
          label={placeholder}
          error={!!error}
          helperText={error?.message || " "}
        />
      )}
    />
  );
};
