import { Theme, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface CardModuleContainerProps {
  theme?: Theme;
  isHide: boolean;
}

export const CardModuleContainer = styled(Box)(
  ({ theme, isHide = false }: CardModuleContainerProps) => ({
    backgroundColor: theme?.palette.primary.contrastText,
    padding: isHide
      ? `0 ${theme?.spacing(4)} 0 1.3rem`
      : `${theme?.spacing(4)} ${theme?.spacing(4)} 1.3rem`,
    border: isHide
      ? "none"
      : `${theme?.spacing(1)} solid ${theme?.palette.custom.grey.light}`,
    borderRadius: "0.5rem",
    overflow: isHide ? "hidden" : "inherit",
    height: isHide ? 0 : "100%",
  })
);
