import { useMemo } from "react";

import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx-js-style";

import { FormattedReportByTeacher } from "../helpers/formatReportByTeacher";

export interface CancellationReportDownloadProps {
  items: FormattedReportByTeacher[];
}

export const CancellationReportDownload = ({
  items,
}: CancellationReportDownloadProps): JSX.Element => {
  const { t } = useTranslation();

  const data = useMemo(() => {
    const res: {
      [x: string]: string;
    }[] = [];

    for (let i = 0; i < items.length; i += 1) {
      const item = items[i];
      res.push({
        [t("CancellationReport:TableTeacher")]: item.teacher,
        [t("CancellationReport:TableCompany")]: "",
        [t("CancellationReport:TableCompleted")]: item.completed,
        [t("CancellationReport:TableLateCanceledByCustomer")]:
          item.lateCanceledByCustomer,
        [t("CancellationReport:TableEarlyCanceledByCustomer")]:
          item.earlyCanceledByCustomer,
        [t("CancellationReport:TableLateCanceledBySchool")]:
          item.lateCanceledBySchool,
        [t("CancellationReport:TableEarlyCanceledBySchool")]:
          item.earlyCanceledBySchool,
      });

      for (let j = 0; j < item.subRows.length; j += 1) {
        const subRow = item.subRows[j];
        res.push({
          [t("CancellationReport:TableTeacher")]: "",
          [t("CancellationReport:TableCompany")]: subRow.company,
          [t("CancellationReport:TableCompleted")]: subRow.completed,
          [t("CancellationReport:TableLateCanceledByCustomer")]:
            subRow.lateCanceledByCustomer,
          [t("CancellationReport:TableEarlyCanceledByCustomer")]:
            subRow.earlyCanceledByCustomer,
          [t("CancellationReport:TableLateCanceledBySchool")]:
            subRow.lateCanceledBySchool,
          [t("CancellationReport:TableEarlyCanceledBySchool")]:
            subRow.earlyCanceledBySchool,
        });
      }
    }

    return res;
  }, [items, t]);

  const download = () => {
    const sheet = utils.json_to_sheet(data);
    const book = utils.book_new();
    utils.book_append_sheet(
      book,
      sheet,
      t("CancellationReport:DownloadName") ?? ""
    );
    writeFile(book, `${t("CancellationReport:DownloadName")}.xls`);
  };

  return (
    <Button
      sx={{
        background: "#5783F1",
        color: "white",
        padding: "0.8rem 1.5rem",
        borderRadius: "0.5rem",
        fontSize: "0.8rem",
        mb: "20px",
        ":hover": {
          background: "#5783F1",
        },
      }}
      type="button"
      onClick={download}
    >
      {t("CancellationReport:Download")}
    </Button>
  );
};
