/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance, flow } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { TeacherRateUpdateData } from "../../services/Api";

export const TeacherRateModel = types
  .model("TeacherRateModel")
  .props({
    id: types.identifier,
    activeFrom: types.maybeNull(types.Date),
    activeTo: types.maybeNull(types.Date),
    amount: types.maybeNull(types.string),
    classType: types.maybeNull(types.string),
    languageId: types.maybeNull(types.string),
    teacherId: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    updateTeacherRate: flow(function* (data: TeacherRateUpdateData) {
      const response = yield self.environment.api.updateTeacherRate(
        self.id,
        data
      );

      if (response.kind !== "ok") {
        return false;
      }

      self.activeFrom = new Date(response.data.activeFrom);
      self.amount = response.data.amount;
      self.classType = response.data.classType;

      return true;
    }),
  }));

type TeacherRateModelType = Instance<typeof TeacherRateModel>;
export interface TeacherRate extends TeacherRateModelType {}
type TeacherRateSnapshotType = SnapshotOut<typeof TeacherRateModel>;
export type TeacherRateSnapshot = TeacherRateSnapshotType;
