import { Box, Tooltip } from "@mui/material";

import { Icon, IconType } from "components/Icon";

import { TooltipTitle } from "./styledComponents/TooltipTitle";

export interface ReportGroupActionProps {
  isActive: boolean;
  activeColor: string;
  inactiveColor: string;
  icon: IconType;
  tooltip: string;
  onClick?: () => void;
}

export const ReportGroupAction = ({
  isActive,
  activeColor,
  inactiveColor,
  icon,
  tooltip,
  onClick,
}: ReportGroupActionProps): JSX.Element => {
  const handleClick = () => {
    if (!isActive) {
      return;
    }
    onClick?.();
  };

  const TooltipText = isActive && <TooltipTitle>{tooltip}</TooltipTitle>;

  return (
    <Tooltip placement="bottom" title={TooltipText}>
      <Box
        padding="0.5rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          cursor: isActive ? "pointer" : "default",
        }}
        onClick={handleClick}
      >
        <Icon
          type={icon}
          width={20}
          height={20}
          color={isActive ? activeColor : inactiveColor}
        />
      </Box>
    </Tooltip>
  );
};
