/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Button } from "@mui/material";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import { format } from "date-fns";
import { computed } from "mobx";
import { formatLesson } from "pages/Classes/helpers";
import { useTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx-js-style";

export interface LessonsDownloadProps {
  lessons: Lesson[];
}

export const LessonsDownload = ({
  lessons,
}: LessonsDownloadProps): JSX.Element => {
  const { t } = useTranslation();

  const data = computed(() => lessons.map(formatLesson))
    .get()
    .map((item) => ({
      [t("Classes:Group")]: item.group.name,
      [t("Classes:Company")]: item.customerCompany.name,
      [t("Classes:ClassDate")]: format(item.startDate!, "dd.MM.yyyy"),
      [t("Classes:ClassIndex")]: item.technicalCancellation
        ? "✓"
        : item.index || "-",
      [t("Classes:Teacher")]: item.teacher || "-",
      [t("Classes:Duration")]: item.duration,
      [t("Classes:Type")]: item.typeShort,
      [t("Classes:Status")]: t(`LessonStatuses:${item.status}`),
    }));

  const download = () => {
    const sheet = utils.json_to_sheet(data);
    const book = utils.book_new();
    utils.book_append_sheet(book, sheet, t("ClassesDownload:Classes") ?? "");
    writeFile(book, `${t("ClassesDownload:Classes")}.xls`);
  };

  return (
    <Button
      sx={{
        background: "#5783F1",
        color: "white",
        padding: "0.8rem 1.5rem",
        borderRadius: "0.5rem",
        fontSize: "0.8rem",
        ":hover": {
          background: "#5783F1",
        },
      }}
      onClick={download}
    >
      {t("ClassesDownload:Download")}
    </Button>
  );
};
