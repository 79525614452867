import { Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useCurrentBreakpoint } from "@packages/shared/themes/breakpoints/breakpoints";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { CardClass, CardClassProps } from "components/CardClass";
import { getCurrentLocale } from "locales/dateFnsLocale";
import { getCardType } from "utils/basic";

import {
  MyClassContainer,
  MarkContainer,
  DateTimeContainer,
  DateContainer,
  TimeContainer,
  ModuleClassContainer,
  ModulesContainer,
} from "./index";

export type CardType = {
  icon: CardClassProps["iconType"];
  color: string;
};

type VariantWithLink = {
  variant: CardClassProps["variant"];
  url: string;
};

type LessonProp = {
  lesson: Lesson;
};

export const LessonItem = observer(({ lesson }: LessonProp): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const breakpoint = useCurrentBreakpoint();

  const getVariantFromStatus = (status: string): VariantWithLink["variant"] => {
    if (status === "completed") return "success";
    if (status === "planned") return "default";
    return "cancel";
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getLesson = (lesson: Lesson): CardClassProps["lesson"] => {
    return {
      titleLesson: `Class ${lesson?.index}`,
      titleUnit: lesson?.unit?.nameTranslated || "",
      status: lesson.currentStatus,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getModule = (lesson: Lesson): CardClassProps["module"] => {
    return {
      title: lesson?.course?.nameTranslated || "",
      url: lesson.link || "",
      id: lesson?.course?.id || "",
      status: lesson.currentStatus,
    };
  };

  // TODO move to shared utils
  const isDateToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() && date.getMonth() === today.getMonth()
    );
  };

  const myClassesDateFormat = (date: Date | undefined | null) => {
    if (!date) return null;
    const currentLocale = getCurrentLocale();
    if (isDateToday(date)) {
      return (
        <>
          {t("Global:Today")},
          {format(date, "dd MMM", {
            locale: currentLocale,
          })}
        </>
      );
    }
    const dayStringShorten: { [key: string]: string } = {
      ru: "EEEEEE",
      "en-US": "EEE",
    };
    return format(
      date,
      `${dayStringShorten[currentLocale.code || "en-US"]}, dd MMM`,
      {
        locale: currentLocale,
      }
    );
  };

  // TODO move to shared utils
  const formatDateToHHmm = (date: Date | undefined) => {
    if (!date) return null;
    return format(date, "HH:mm", {
      locale: getCurrentLocale(),
    });
  };

  // is time now between two dates
  // TODO move to shared utils
  const isTimeBetween = (start: Date, end: Date) => {
    const now = new Date();
    return now.getTime() > start.getTime() && now.getTime() < end.getTime();
  };

  const formatDatesToTimePeriod = ({
    fromDate,
    toDate,
  }: {
    fromDate: Date | undefined | null;
    toDate: Date | undefined | null;
  }) => {
    if (!toDate || !fromDate) return null;
    if (isTimeBetween(fromDate, toDate)) {
      return (
        <Typography
          variant="regularText"
          component="span"
          color={palette.error.main}
        >
          {t(`LessonItem:until`)} {formatDateToHHmm(toDate)}
        </Typography>
      );
    }
    return (
      <Typography variant="regularText" component="span">
        {formatDateToHHmm(fromDate)} - {formatDateToHHmm(toDate)}
      </Typography>
    );
  };
  return (
    <MyClassContainer key={lesson.id}>
      <DateTimeContainer>
        <DateContainer>
          <Typography variant="h3" component="span">
            {myClassesDateFormat(lesson?.startAtLocal)}
          </Typography>
        </DateContainer>

        {(breakpoint === "tablet" || breakpoint === "zeroScreen") && (
          <MarkContainer />
        )}

        <TimeContainer>
          {formatDatesToTimePeriod({
            fromDate: lesson?.startAtLocal,
            toDate: lesson?.endAtLocal,
          })}
        </TimeContainer>
      </DateTimeContainer>

      {breakpoint === "desktop" && <MarkContainer />}

      <ModulesContainer>
        <ModuleClassContainer>
          <CardClass
            module={getModule(lesson)}
            lesson={getLesson(lesson)}
            variant={getVariantFromStatus(lesson?.currentStatus)}
            iconType={getCardType(lesson).icon}
            iconColor={getCardType(lesson).color}
          />
        </ModuleClassContainer>
      </ModulesContainer>
    </MyClassContainer>
  );
});
