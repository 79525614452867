import React, { CSSProperties, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from './ErrorCreateMenu.module.scss';

import { TaskContext, ErrorCreateContext } from '../utils/context';
import { ExerciseErrorType } from '@packages/store/models/ExerciseResult/ExerciseResultError';
import { ErrorCreateMenuEditor } from './ErrorCreateMenuEditor';
import { ErrorCreateMenuItem } from './ErrorCreateMenuItem';
import { Text } from '../../Text';
import { useOffset } from '../utils/hooks';

export const ErrorCreateMenu = observer(() => {

  const { t } = useTranslation();

  const {
    errors = [],
    setErrors
  } = useContext(TaskContext);

  const {
    containerRef,
    leftOffset,
    topOffset
  } = useOffset();

  const containerStyle: CSSProperties = useMemo(() => ({
    left: `${leftOffset}px`, 
    bottom: `calc(100% + ${topOffset}px)`, 
  }), [leftOffset, topOffset]);

  const [currentComment, setCurrentComment] = useState("");
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const {
    selectedError,
    newErrorRange,
    newErrorType,
    setNewErrorRange,
    setSelectedError,
    setNewErrorType
  } = useContext(ErrorCreateContext);

  const rangeMathedErrors = useMemo(() => {
    const { startPosition, endPosition } = newErrorRange ?? {};
    return errors?.filter((error) => {
      return error.startPosition === startPosition && error.endPosition === endPosition;
    })
  }, [errors, newErrorRange]);

  const {
    grammarError,
    lexicalError,
    commentError
  } = useMemo(() => {
    const { COMMENT, LEXICAL, GRAMMAR } = ExerciseErrorType;
    return {
      grammarError: rangeMathedErrors?.find(({ errorTypeId }) => errorTypeId === GRAMMAR),
      lexicalError: rangeMathedErrors?.find(({ errorTypeId }) => errorTypeId === LEXICAL),
      commentError: rangeMathedErrors?.find(({ errorTypeId }) => errorTypeId === COMMENT)
    }
  }, [rangeMathedErrors]);

  const isFilled = grammarError && lexicalError && commentError;

  const contentClasses = cn(
    styles.container
  );

  const handleTypeChange = useCallback((type: ExerciseErrorType) => () => {
    setNewErrorType?.(type);
    setIsEditorVisible(true);
    setIsEditing(true);
  }, [
    setNewErrorType,
    setIsEditorVisible,
    setIsEditing
  ]);

  const handleAddError = useCallback(() => {
    setSelectedError?.(undefined);
    setCurrentComment("");
    setIsEditorVisible(false);
    setIsEditing(false);
  }, [
    setSelectedError,
    setCurrentComment,
    setIsEditorVisible,
    setIsEditing,
  ]);

  const handleChangeError = useCallback(() => {
    setIsEditorVisible(false);
  }, [
    setIsEditorVisible,
  ]);

  const handleErrorSave = useCallback(() => {
    const { startPosition = 0, endPosition = 0 } = newErrorRange ?? {};
    if (selectedError) {
      setErrors?.(
        errors.map((error) => {
          if (error === selectedError) {
            return {
              ...error,
              comment: currentComment,
              errorTypeId: newErrorType ?? ExerciseErrorType.COMMENT
            }
          }
          return error;
        })
      )
    } else {
      setErrors?.([
        ...errors,
        {
          currentId: "",
          startPosition,
          endPosition,
          comment: currentComment,
          errorTypeId: newErrorType ?? ExerciseErrorType.COMMENT
        }
      ])
    }
    setSelectedError?.(undefined)
    setNewErrorType?.(undefined)
    setNewErrorRange?.(undefined)
    setIsEditorVisible(false);
  }, [
    errors,
    selectedError,
    currentComment,
    newErrorRange,
    newErrorType,
    setSelectedError,
    setNewErrorType,
    setNewErrorRange,
    setErrors,
    setIsEditorVisible
  ])

  useEffect(() => {
    if (!selectedError) {
      return;
    }
    setIsEditing(true);
  }, [
    selectedError,
    setIsEditing
  ]);

  useEffect(() => {
    if (!selectedError) {
      return;
    }
    setNewErrorType?.(newErrorType);
    setCurrentComment(selectedError.comment);
    setIsEditorVisible(true)
  }, [
    selectedError,
    setNewErrorType,
    setCurrentComment,
    setIsEditorVisible
  ]);

  if (isEditorVisible) {
    return (
      <ErrorCreateMenuEditor
        type={newErrorType ?? ExerciseErrorType.COMMENT}
        comment={currentComment}
        onChangeError={handleChangeError}
        onAddError={handleAddError}
        onSave={handleErrorSave}
        onCommentInput={setCurrentComment}
      />
    )
  }

  if (isFilled) {
    return (
      <div className={contentClasses}>
        <Text
          variant="text-2-regular"
          color="base-dl"
        >
          {t("CreativeTasks:ErrorRangeFilled")}
        </Text>
      </div>
    )
  }

  return (
    <div
      ref={containerRef}
      className={contentClasses}
      style={containerStyle}
    >
      <>
        {!grammarError && (
          <ErrorCreateMenuItem
            text={t("CreativeTasks:Grammar")}
            isEditing={isEditing}
            type={ExerciseErrorType.GRAMMAR}
            onClick={handleTypeChange(ExerciseErrorType.GRAMMAR)}
          />
        )}
        {!lexicalError && (
          <ErrorCreateMenuItem
            text={t("CreativeTasks:Lexical")}
            isEditing={isEditing}
            type={ExerciseErrorType.LEXICAL}
            onClick={handleTypeChange(ExerciseErrorType.LEXICAL)}
          />
        )}
        {!commentError && (
          <ErrorCreateMenuItem
            text={t("CreativeTasks:Comment")}
            isEditing={isEditing}
            type={ExerciseErrorType.COMMENT}
            onClick={handleTypeChange(ExerciseErrorType.COMMENT)}
          />
        )}
      </>
    </div>
  );
})
