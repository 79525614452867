import { SyntheticEvent, useEffect, useState } from "react";

import {
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { useStores } from "@packages/store/models";
import { GroupsAutocompleteFilter } from "components/GroupsAutocompleteFilter";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { getAutocompleteFieldValue } from "utils/helpers";

import {
  AttendanceReportsDateRangeFilter,
  AttendanceReportsDateRangeFilterProps,
} from "./AttendanceReportsDateRangeFilter";
import { SearchFilter } from "./SearchFilter";
import { FilterContainer } from "./styledComponents";
import { ValueWithLabel } from "../../types";

export interface ReportsAttendanceFilterProps {
  student?: string | null;
  group?: string | null;
  company?: string | null;
  onlyTeachersLessons: boolean;
  dateFilterProps: AttendanceReportsDateRangeFilterProps;
  onCompanyChange?: (_: string) => void;
  onStudentChange?: (_: string) => void;
  onGroupChange?: (_: string) => void;
  onOnlyTeachersLessonsChange: (value: boolean) => void;
}

export const ReportsAttendanceFilter = observer(
  ({
    group,
    student,
    company,
    onlyTeachersLessons,
    dateFilterProps,
    onStudentChange,
    onGroupChange,
    onCompanyChange,
    onOnlyTeachersLessonsChange,
  }: ReportsAttendanceFilterProps): JSX.Element => {
    const { t } = useTranslation();
    const { customerCompany, auth } = useStores();
    const [selectedCompany, setSelectedCompany] = useState<string | null>(
      company || null
    );

    const {
      isHR = false,
      isDistributor = false,
      isCoordinator = false,
      isTeacher = false,
    } = auth.user ?? {};

    const additionalFilterVisible = isHR ? true : group || company;

    const companies = [
      ...customerCompany.items.map(
        (item) =>
          ({
            label: item.name,
            value: item.id,
          } as ValueWithLabel)
      ),
      {
        label: t("ReportsAttendance:AllCompanies"),
        value: "",
      },
    ];

    const sortedCompanies = companies.sort((prev, next) => {
      return prev.label > next.label ? 1 : -1;
    });

    const handleCompanyChange = (
      _event: SyntheticEvent<Element, Event>,
      value: ValueWithLabel | null
    ) => {
      onCompanyChange?.(value?.value as string);
      setSelectedCompany(value?.value as string);
    };

    useEffect(() => {
      if (!company) {
        return;
      }
      setSelectedCompany(company);
    }, [company]);

    return (
      <FilterContainer>
        {!isHR && (
          <Autocomplete
            disablePortal
            openOnFocus
            id="company"
            onChange={handleCompanyChange}
            options={sortedCompanies}
            value={getAutocompleteFieldValue(sortedCompanies, selectedCompany)}
            sx={{ minWidth: 200, marginTop: "0.5rem" }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={t("ReportsAttendance:SelectCompany")}
              />
            )}
          />
        )}
        <GroupsAutocompleteFilter onChange={onGroupChange} group={group} />
        {additionalFilterVisible && (
          <>
            <AttendanceReportsDateRangeFilter {...dateFilterProps} />
            {!isDistributor && !isCoordinator && !isTeacher && (
              <SearchFilter
                placeholder={t("GroupFilter:SearchStudent")}
                value={student}
                onChange={onStudentChange}
              />
            )}

            {isTeacher && (
              <FormControlLabel
                control={
                  <Switch
                    checked={onlyTeachersLessons}
                    onChange={(_, value) => onOnlyTeachersLessonsChange(value)}
                  />
                }
                label={
                  <Typography variant="regularText">
                    {t("ReportsAttendance:OnlyTeachersLessons")}
                  </Typography>
                }
              />
            )}
          </>
        )}
      </FilterContainer>
    );
  }
);
