import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface OnlyFlexibleSwitchProps {
  active: boolean;
  onToggle: () => void;
}

export const OnlyFlexibleSwitch = ({
  active,
  onToggle,
}: OnlyFlexibleSwitchProps): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Box sx={{ mb: 4 }}>
      <FormControlLabel
        control={<Switch value={active} onChange={onToggle} />}
        label={
          <Typography variant="regularText">
            {t(`StartPageTeacher:onlyFlexible`)}
          </Typography>
        }
      />
    </Box>
  );
};
