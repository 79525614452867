import { forwardRef } from "react";

import { SelectProps } from "@mui/material";

import { ValueWithLabel } from "../../types";
import { Select } from "../Select";

interface FilterSelectProps {
  data: Array<ValueWithLabel>;
  placeholder: string;
  error?: boolean;
  helperText?: string;
}

export const FilterSelect = forwardRef(
  (props: FilterSelectProps & SelectProps, ref): JSX.Element => {
    return (
      <Select
        ref={ref}
        labelProps={{
          sx: {
            fontSize: "0.8rem",
            marginTop: "-0.6rem",
          },
        }}
        MenuProps={{
          sx: {
            fontSize: "0.8rem",
          },
        }}
        iconProps={{
          sx: {
            position: "absolute",
            right: "0",
            top: "0.3rem",
            width: "16px",
            height: "16px",
            pointerEvents: "none",
          },
        }}
        menuItems={{
          sx: {
            fontSize: "0.8rem",
          },
        }}
        sx={{
          flexGrow: 1,
          fontSize: "0.8rem",
          paddingBottom: "0.2rem",
          marginTop: "0.5rem !important",
          "& svg": {
            marginBottom: "0.2rem",
          },
          ".MuiSelect-select.MuiInput-input": {
            flex: "auto",
          },
        }}
        {...props}
      />
    );
  }
);
