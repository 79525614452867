import { useCallback, useEffect, useRef, useState } from "react";

import { ButtonBase } from "@mui/material";

import { Icon } from "components/Icon";

import {
  SectionItem,
  SectionsArrowBox,
  SectionsBottomLine,
  SectionsContainer,
  SectionsScroll,
  SectionsScrollContent,
} from "./styledComponents";

export interface SectionListProps {
  list?: string[];
  active?: number;
  onSelect?: (_: number) => void;
}

export const SectionList = ({
  list = [],
  active = 0,
  onSelect,
}: SectionListProps): JSX.Element => {
  const [leftArrowVisible, setLeftArrowVisible] = useState(false);
  const [rightArrowVisible, setRightArrowVisible] = useState(false);
  const [scrollStep] = useState(300);
  const scroll = useRef<HTMLElement | null>();

  const onScroll = useCallback(() => {
    if (!scroll.current) {
      return;
    }
    const scrollWidth = scroll.current?.scrollWidth;
    const width = scroll.current?.clientWidth;
    const scrollLeft = scroll.current?.scrollLeft;
    const scrollLimit = scrollWidth - width;
    const leftVisible = scrollLeft > 0;
    const rightVisible = scrollLimit > scrollLeft;
    if (leftVisible !== leftArrowVisible) {
      setLeftArrowVisible(leftVisible);
    }
    if (rightVisible !== rightArrowVisible) {
      setRightArrowVisible(rightVisible);
    }
  }, [leftArrowVisible, rightArrowVisible]);

  const onClick = (index: number) => () => {
    onSelect?.(index);
  };

  const scrollLeft = () => {
    const scrollX = scroll.current?.scrollLeft ?? 0;
    scroll.current?.scrollTo(scrollX - scrollStep, 0);
  };

  const scrollRight = () => {
    const scrollX = scroll.current?.scrollLeft ?? 0;
    scroll.current?.scrollTo(scrollX + scrollStep, 0);
  };

  useEffect(() => {
    onScroll();
  }, [scroll, onScroll]);

  return (
    <SectionsContainer>
      <SectionsArrowBox>
        {leftArrowVisible && (
          <ButtonBase sx={{ transform: "rotate(180deg)" }} onClick={scrollLeft}>
            <Icon type="arrowLeft" width={20} />
          </ButtonBase>
        )}
      </SectionsArrowBox>
      <SectionsScroll>
        <SectionsBottomLine />
        <SectionsScrollContent ref={scroll} onScroll={onScroll}>
          {list.map((text, index) => (
            <SectionItem
              key={text}
              active={index === active}
              onClick={onClick(index)}
            >
              {text}
            </SectionItem>
          ))}
        </SectionsScrollContent>
      </SectionsScroll>
      <SectionsArrowBox>
        {rightArrowVisible && (
          <ButtonBase onClick={scrollRight}>
            <Icon type="arrowRight" width={20} />
          </ButtonBase>
        )}
      </SectionsArrowBox>
    </SectionsContainer>
  );
};
