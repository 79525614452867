import { styled } from "@mui/material/styles";

export const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
  gap: "64px",
  [theme.breakpoints.down("laptop")]: {
    gap: "16px",
  },
}));
