import React, { useContext, useMemo } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";

import styles from './Answer.module.scss';

import { Text } from '../../Text';
import { AnswerTextTeacher } from './AnswerTextTeacher';
import { AnswerTextStudent } from './AnswerTextStudent';
import { UserRoleContext } from '../utils/context';
import { UserRole } from '../utils/types';
import { ExerciseResultType } from '@packages/store/models/ExerciseResult/ExerciseResult';
import { Audio } from '../../Audio';

interface AnswerProps {
  type: ExerciseResultType,
  text: string;
}

export const Answer = observer((props: AnswerProps) => {
	const { type, text } = props;

  const { t } = useTranslation();

  const isWritting = type === ExerciseResultType.WRITING;

  const { currentRole } = useContext(UserRoleContext);

  const allText = text.trim();

  const allWords = allText.split(' ').filter(Boolean);

  const allSentenses = allText.split('.').filter(Boolean);

  const isTeacher = currentRole === UserRole.TEACHER;

  const AnswerContent = useMemo(() => {
    if (isWritting) {
      return isTeacher
        ? (
          <AnswerTextTeacher
            text={text}
          />
        )
        : (
          <AnswerTextStudent
            text={text}
          />
        )
    }

    return (
      <Audio src={text} />
    )
  }, [text, isTeacher, isWritting])

  return (
    <>
      <div className={styles["top"]}>
        <Text variant="text-2-regular" color="base-d">
         {t("CreativeTasks:AnswerHeaderStatistics", {
          questions: 1,
          symbols: isWritting ? allText.length : 0,
          words: isWritting ? allWords.length : 0,
          sentenses: isWritting ? allSentenses.length : 0
         })}
        </Text>
      </div>
      {AnswerContent}
    </>
  );
})
