import { useEffect } from "react";

import { Outlet } from "react-router-dom";

import { useStores } from "@packages/store/models";

export const Distributor = (): JSX.Element => {
  const store = useStores();

  useEffect(() => {
    store.getInitialDistributorState();
  }, [store]);

  return <Outlet />;
};
