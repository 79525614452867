/* eslint-disable @typescript-eslint/no-shadow */
import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { AnswerModel } from "@packages/store/models/ExerciseAnswer/Answer";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun } from "mobx";

export const useWritingExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const { answer, passingData, isChecked } = exercise;

      if (!answer) {
        return;
      }

      const { setQuestions } = answer;

      if (passingData.length > 0 && isChecked) {
        const initialState = passingData.map(({ id, state, answers }) =>
          QuestionModel.create({
            id,
            state,
            answers: answers.map(({ id, options }) =>
              AnswerModel.create({
                id,
                text: options.map(String)[0],
              })
            ),
          })
        );

        setQuestions(initialState);
      }
    })
  );

  useLayoutEffect(() =>
    autorun(() => {
      const { questions, answer } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty) {
        const initialState = questions.map(({ id, answers }) =>
          QuestionModel.create({
            id,
            answers: answers.map(({ id }) =>
              AnswerModel.create({
                id,
              })
            ),
          })
        );

        setQuestions(initialState);
      }
    })
  );
};
