/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  flow,
  destroy,
  cast,
} from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { Surcharge, SurchargeModel, SurchargeSnapshot } from "./SurchargeModel";
import { SurchargeCreateData } from "../../services/Api";

export const SurchargeStoreModel = types
  .model("Surcharge")
  .props({
    items: types.optional(types.array(SurchargeModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: SurchargeSnapshot) => {
      const instance = resolveIdentifier(SurchargeModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .extend(withEnvironment)
  .actions((self) => ({
    deleteById: flow(function* (surchargeId: string) {
      const response = yield self.environment.api.deleteSurcharge(surchargeId);

      if (response.kind !== "ok") {
        return false;
      }

      destroy(self.items.find((s) => s.id === surchargeId));

      return true;
    }),
    updateById: flow(function* (
      surchargeId: string,
      data: SurchargeCreateData
    ) {
      const surcharge = self.items.find((s) => s.id === surchargeId);

      if (!surcharge) return false;

      const response = yield self.environment.api.updateSurcharge(
        surchargeId,
        data
      );

      if (response.kind !== "ok") {
        return false;
      }

      surcharge.amount = cast(data.amount);
      surcharge.type = cast(data.type);
      surcharge.activeFrom = cast(data.activeFrom);

      return true;
    }),
  }))
  .views((self) => ({
    getByGroupId(groupId: string): Surcharge[] {
      return self.items.filter((surcharge) => {
        try {
          return surcharge.group?.id === groupId;
        } catch (error) {
          return false;
        }
      });
    },
  }));

type SurchargeStoreType = Instance<typeof SurchargeStoreModel>;
export type SurchargeStore = SurchargeStoreType;
type SurchargeStoreSnapshotType = SnapshotOut<typeof SurchargeStoreModel>;
export type SurchargeStoreSnapshot = SurchargeStoreSnapshotType;
