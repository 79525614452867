/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, SnapshotOut, Instance, getRoot } from "mobx-state-tree";

import { AdditionalContentModel } from "../AdditionalContent/AdditionalContent";
import { CorrectAnswerModel } from "../CorrectAnswer/CorrectAnswer";
import { PossibleAnswerModel } from "../Exercise/PossibleAnswer";
import { ExerciseAnswerModel } from "../ExerciseAnswer/ExerciseAnswer";
import { withEnvironment } from "../extensions/withEnvironment";
import { PassingDataModel } from "../PassingData/PassingData";
import { QuestionModel } from "../Question/Question";
import { QuestionRawModel } from "../Question/QuestionRaw";
import { RootStore } from "../RootStore/RootStore";

import { getAnswersData } from "./getAnswersData";
import { PartGroupModel } from "./PartGroup";
import { PropertiesModel } from "./Properties";

export enum ExerciseUIType {
  CONFIRMATION = "Confirmation",
  MATCHING = "Matching",
  MOVING = "Moving",
  WRITING = "Writing",
  CHOICE = "Choice",
  ORDER = "Order",
  ESSAY = "Essay",
  SPEAKING = "Speaking",
  MISTAKES = "Mistakes",
}

export enum ExerciseUISubtype {
  CONFIRMATION = "Confirmation",
  WORD = "Word",
  SELECT = "Select",
  INLINE = "Inline",
  RADIO_BUTTON = "RadioButton",
  PLAIN = "Plain",
  RADIO_LINE = "RadioLine",
  BLOCK = "Block",
  ESSAY = "Essay",
  TABLE = "Table",
  CHECK_EMPHASIS = "CheckEmphasis",
  MEDIA = "Media",
  DIALOG = "Dialog",
  GAPS = "Gaps",
}

export enum ExerciseState {
  NOT_STARTED = "notStarted",
  NOT_REACHED = "notReached",
  HAS_ERRORS = "hasErrors",
  VERIFIED = "verified",
  PASSED = "passed",
  STARTED = "started",
  BEGIN = "begin",
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
}

export const ExerciseModel = types
  .model("Exercise", {
    available: types.maybeNull(types.boolean),
    checkingState: types.maybeNull(types.string),
    checkingResultId: types.maybeNull(types.number),
    id: types.identifier,
    name: types.maybeNull(types.string),
    mark: types.maybeNull(types.number),
    markPercent: types.maybeNull(types.number),
    passedAt: types.maybeNull(types.Date),
    passingDataVersion: types.maybeNull(types.number),
    passingPercent: types.maybeNull(types.number),
    questionDataVersion: types.maybeNull(types.number),
    repassing: types.maybeNull(types.boolean),
    repassingsCount: types.maybeNull(types.number),
    skipped: types.maybeNull(types.boolean),
    startedTimestamp: types.maybeNull(types.Date),
    state: types.optional(
      types.enumeration<ExerciseState>(
        "ExerciseState",
        Object.values(ExerciseState)
      ),
      ExerciseState.NOT_STARTED
    ),
    // timer: types.maybeNull(types.number),
    updatedAt: types.maybeNull(types.Date),
    uiType: types.optional(
      types.enumeration<ExerciseUIType>(
        "ExerciseUIType",
        Object.values(ExerciseUIType)
      ),
      ExerciseUIType.CONFIRMATION
    ),
    uiSubtype: types.optional(
      types.enumeration<ExerciseUISubtype>(
        "ExerciseUISubtype",
        Object.values(ExerciseUISubtype)
      ),
      ExerciseUISubtype.CONFIRMATION,
      [null, undefined]
    ),
    order: types.maybeNull(types.number),
    additionalContent: types.optional(types.array(AdditionalContentModel), []),
    taskText: types.maybeNull(types.string),
    properties: types.maybeNull(PropertiesModel),
    questions: types.array(QuestionModel),
    questionsRaw: types.array(QuestionRawModel),
    passingData: types.array(PassingDataModel),
    correctAnswers: types.array(CorrectAnswerModel),
    possibleAnswers: types.array(PossibleAnswerModel),
    partGroups: types.array(PartGroupModel),
    answer: types.maybeNull(
      types.late(() => types.reference(ExerciseAnswerModel))
    ),
    loading: types.optional(types.boolean, false),
    broken: types.optional(types.boolean, false),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value;
    },
    setAnswer(id: string) {
      if (!self.answer) {
        self.answer = id as any;
      }
    },
  }))
  .views((self) => ({
    get isConfirmation() {
      return self.uiType === ExerciseUIType.CONFIRMATION;
    },
    get isEssay() {
      return (
        self.uiType === ExerciseUIType.ESSAY &&
        self.uiSubtype === ExerciseUISubtype.ESSAY
      );
    },
    get isSpeaking() {
      return (
        self.uiType === ExerciseUIType.SPEAKING &&
        (self.uiSubtype === ExerciseUISubtype.PLAIN ||
          self.uiSubtype === ExerciseUISubtype.DIALOG)
      );
    },
    get isMovingWithImages() {
      return self.questions[0].isWithImage;
    },
  }))
  .views((self) => ({
    get isDialog() {
      return self.isSpeaking && self.uiSubtype === ExerciseUISubtype.DIALOG;
    },
  }))
  .views((self) => ({
    get isCreative() {
      return self.isEssay || self.isSpeaking;
    },
    get canBeUnfilled() {
      return (
        self.uiType === ExerciseUIType.CONFIRMATION ||
        self.uiSubtype === ExerciseUISubtype.CHECK_EMPHASIS
      );
    },
  }))
  .views((self) => ({
    get isPassed() {
      return self.state === "passed" || self.state === "verified";
    },
    get isWrong() {
      return self.state === "hasErrors";
    },
    get isFilled() {
      return Boolean(
        (self.answer && self.answer.isFilled) || self.canBeUnfilled
      );
    },
  }))
  .views((self) => ({
    get isChecked() {
      return self.isPassed || self.isWrong;
    },
    get firstQuestion() {
      return self.questions.slice()[0];
    },
    get answerData() {
      if (self.answer) {
        // тут any потому что проблематично возиться с типами, на каждый тип упражнения тип разный и не описанный
        return getAnswersData(self.answer, self.uiType, self.uiSubtype) as any;
      }

      return null;
    },
    getCorrectAnswer(questionId: string) {
      return self.correctAnswers.find(
        (correctAnswer) => correctAnswer.questionId === questionId
      );
    },
    getQuestionRaw(id: string) {
      return self.questionsRaw.find((questionRaw) => questionRaw.id === id);
    },
  }))
  .actions((self) => ({
    async check(coursePassingId: string) {
      if (self.answer) {
        const rootStore = getRoot<RootStore>(self);
        const answersData = getAnswersData(
          self.answer,
          self.uiType,
          self.uiSubtype
        );

        self.setLoading(true);
        await rootStore.checkExercise(
          { coursePassingId, exerciseId: self.id, answersData },
          true
        );
        self.setLoading(false);
      }
    },
    async reset() {
      const rootStore = getRoot<RootStore>(self);
      self.setLoading(true);
      await rootStore.resetExercise(self.id, true);
      self.setLoading(false);
      self.answer?.clearQuestions();
    },
  }));

type ExerciseModelType = Instance<typeof ExerciseModel>;
type ExerciseModelTypeSnapshotType = SnapshotOut<typeof ExerciseModel>;

export interface Exercise extends ExerciseModelType {}
export type ExerciseSnapshot = ExerciseModelTypeSnapshotType;
