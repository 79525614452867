import { styled, Box } from "@mui/material";

export const Line = styled(Box)(({ theme }) => ({
  background: theme.palette.custom.grey.main,
  flex: 1,
  width: "0.125rem",
  maxWidth: "0.125rem",
  height: "2rem",
  margin: `0 ${theme.spacing(3)}`,
}));
