import {
  types,
  Instance,
  SnapshotOut,
} from "mobx-state-tree";

import { ClassGroupModel } from "../ClassGroup/ClassGroup";

export const ClassGroupRateModel = types
  .model({
    id: types.identifier,
    classGroupId: types.maybeNull(types.string),
    amount: types.number,
    activeFrom: types.maybeNull(types.Date),
    deletedAt: types.maybeNull(types.Date),
  });

type ClassGroupRateType = Instance<typeof ClassGroupRateModel>;
export interface ClassGroupRate extends ClassGroupRateType {}
type ClassGroupRateSnapshotType = SnapshotOut<typeof ClassGroupRateModel>;
export type ClassGroupRateSnapshot = ClassGroupRateSnapshotType;
