import { useState } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import {
  format,
  addMonths,
  subMonths,
  setMonth,
  setYear,
  addYears,
  subYears,
} from "date-fns";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { TabPanel } from "components/TabPanel";
import { getCurrentLocale } from "locales/dateFnsLocale";

import { CalendarDays } from "./CalendarDays";
import { CalendarMonths } from "./CalendarMonths";
import { CalendarYears } from "./CalendarYears";

interface CalendarProps {
  value: Date;
  onChange: (date: Date, shouldClose?: boolean) => void;
}

export const Calendar = ({
  value,
  onChange,
}: CalendarProps): JSX.Element | null => {
  const theme = useTheme();

  const [currentTab, setCurrentTab] = useState(0);

  const handleDayChange = (day: Date) => {
    onChange(day, true);
  };
  const handleMonthChange = (month: number) => {
    onChange(setMonth(value, month), true);
  };
  const handleYearChange = (year: number) => {
    onChange(setYear(value, year), true);
  };

  const handleNextClick = () => {
    if (currentTab === 0) {
      return onChange(addMonths(value, 1));
    }

    if (currentTab === 1) {
      return onChange(addYears(value, 1));
    }

    return onChange(addYears(value, 10));
  };

  const handlePrevClick = () => {
    if (currentTab === 0) {
      return onChange(subMonths(value, 1));
    }

    if (currentTab === 1) {
      return onChange(subYears(value, 1));
    }

    return onChange(subYears(value, 10));
  };

  const handleModeChange = () => {
    setCurrentTab((p) => (p === 2 ? 0 : p + 1));
  };

  return (
    <Box padding={theme.spacing(3, 4)}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          variant="iconBtn"
          sx={{ transform: "rotate(180deg)" }}
          onClick={() => handlePrevClick()}
        >
          <Icon type="arrowRight" sizeSquareIcon={20} stroke="currentColor" />
        </Button>
        <Typography
          onClick={handleModeChange}
          sx={{ cursor: "pointer", userSelect: "none" }}
        >
          {format(value, "PP", { locale: getCurrentLocale() })}
        </Typography>
        <Button variant="iconBtn" onClick={() => handleNextClick()}>
          <Icon type="arrowRight" sizeSquareIcon={20} stroke="currentColor" />
        </Button>
      </Box>

      <TabPanel value={currentTab} tabValue={0}>
        <CalendarDays currentDate={value} onDayChange={handleDayChange} />
      </TabPanel>

      <TabPanel value={currentTab} tabValue={1}>
        <CalendarMonths currentDate={value} onMonthChange={handleMonthChange} />
      </TabPanel>

      <TabPanel value={currentTab} tabValue={2}>
        <CalendarYears currentDate={value} onYearChange={handleYearChange} />
      </TabPanel>
    </Box>
  );
};
