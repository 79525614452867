import React from 'react';

import { observer } from 'mobx-react';
import cn from "classnames";

import styles from './ErrorContextMenu.module.scss';

import { Text, TextColor } from '../../Text';
import { Icon, IconType } from '../../Icon';
import { ExerciseErrorType } from '@packages/store/models/ExerciseResult/ExerciseResultError';

interface ErrorContextMenuItemProps {
  containerClassNames?: string;
  title: string;
  desc: string;
  type: ExerciseErrorType;
  hovered?: boolean;
  onHover?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
}

const icons: Record<ExerciseErrorType, IconType> = {
  [ExerciseErrorType.COMMENT]: "comments",
  [ExerciseErrorType.GRAMMAR]: "grammar",
  [ExerciseErrorType.LEXICAL]: "vocubalury",
}

export const ErrorContextMenuItem = observer((props: ErrorContextMenuItemProps) => {
  const {
    containerClassNames,
    title,
    desc,
    type,
    hovered,
    onHover,
    onBlur,
    onClick
  } = props;

  const containerClasses = cn(
    containerClassNames,
    styles["item"],
    {
      [styles.hovered]: hovered
    }
  );

  const titleColor: TextColor = type === ExerciseErrorType.COMMENT
    ? "accsent-1s"
    : "accsent-wd";

  return (
    <div
      className={containerClasses}
      onMouseEnter={onHover}
      onMouseLeave={onBlur}
      onClick={onClick}
    >
      <div className={styles["icon"]}>
        <Icon icon={icons[type]} />
      </div>
      <div>
        <div className={styles["item-header"]}>
          <Text
            containerClassName={styles["item-title"]}
            variant="text-3-regular"
            color={titleColor}
          >
            {title}
          </Text>
          {onClick && (
            <Icon
              containerClassName={styles["item-action"]}
              icon="edit"
            />
          )}
        </div>
        <Text variant="text-4-semibold" color="base-d">
          {desc}
        </Text>
      </div>
    </div>
  );
})
