import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { ValueWithLabel } from "@packages/store/services/Api";

export function formatGroupsForFilter(
  groups: LearningGroup[]
): ValueWithLabel[] {
  return groups.map((group) => ({
    label: group?.name ?? "",
    value: String(group?.id ?? 0),
  }));
}
