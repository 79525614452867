import { types, Instance, SnapshotOut } from "mobx-state-tree";

import { withTranslation } from "../extensions/withTranslation";

import { TranslationModel } from "../Translation/TranslationModel";

export const KnowledgeLevelModel = types
  .model({
    code: types.maybeNull(types.string),
    id: types.identifier,
    translationsAttributes: types.optional(types.array(TranslationModel), []),
  })
  .extend(withTranslation);

type KnowledgeLevelModelType = Instance<typeof KnowledgeLevelModel>;
export type KnowledgeLevel = KnowledgeLevelModelType;
type KnowledgeLevelModelSnapshotType = SnapshotOut<typeof KnowledgeLevelModel>;
export type KnowledgeLevelSnapshot = KnowledgeLevelModelSnapshotType;
