/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { addSeconds, format } from "date-fns";
import { uniqBy } from "lodash";
import {
  types,
  SnapshotOut,
  Instance,
  flow,
  cast,
  IAnyModelType,
  getRoot,
  getSnapshot,
} from "mobx-state-tree";

import { RootStore } from "..";
import {
  LessonUpdateData,
  LessonUpdateModel,
  Student,
} from "../../services/Api";
import { Attendance } from "../../services/Api/ApiTypes";
import { setMidnight } from "../../utils/basic";
import {
  getLessonDurationInAcademicHours,
  roundToClosest,
} from "../../utils/helpers";
import {
  Attendance as AttendanceModelType,
  AttendanceModel,
} from "../Attendance/Attendance";
import { ClassSchedule } from "../ClassSchedule/ClassSchedule";
import { CourseModel } from "../Course/CourseModel";
import { withEnvironment } from "../extensions/withEnvironment";
import {
  LearningGroup,
  LearningGroupModel,
} from "../LearningGroups/LearningGroupModel";
import { LessonCredentialsModel } from "../LessonCredentials/LessonCredentialsModel";
import { Teacher } from "../Teacher/Teacher";
import { UnitModel } from "../Unit/UnitModel";

export const completedStatuses: Lesson["status"][] = [
  "completed",
  "lateCanceledByCustomer",
];

export const activeLessonStatuses: Lesson["status"][] = [
  "planned",
  "completed",
  "lateCanceledByCustomer",
];

export const statuses = [
  "completed",
  "planned",
  "earlyCanceledByCustomer",
  "earlyCanceledBySchool",
  "lateCanceledByCustomer",
  "lateCanceledBySchool",
  "notPerformedToPay",
];

export const notEditableAttendanceStatuses = [
  "planned",
  "lateCanceledBySchool",
  "earlyCanceledBySchool",
  "lateCanceledByCustomer",
  "earlyCanceledByCustomer",
];

export const notEditableDurationStatusesForTeacher =
  notEditableAttendanceStatuses;

export const NotEditableDateStatusesForTeachersOutsideTheCurrentMonth = [
  "completed",
  "planned",
  "earlyCanceledBySchool",
  "earlyCanceledByCustomer",
];

export const MAX_COMMENT_WORDS_COUNT = 40;

export const LessonModel = types
  .model("Lesson", {
    id: types.identifier,
    compensation: types.maybeNull(types.boolean),
    startAtLocal: types.maybeNull(types.Date),
    endAtLocal: types.maybeNull(types.Date),
    status: types.enumeration("LessonStatus", statuses),
    teacherId: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    webinarRoomLink: types.maybeNull(types.string),
    webinarRecordLink: types.maybeNull(types.string),
    timezone: types.maybeNull(types.string),
    group: types.maybeNull(
      types.reference(types.late((): IAnyModelType => LearningGroupModel))
    ),
    unit: types.maybeNull(
      types.reference(types.late((): IAnyModelType => UnitModel))
    ),
    course: types.maybeNull(
      types.reference(types.late((): IAnyModelType => CourseModel))
    ),
    attendance: types.optional(types.array(AttendanceModel), []),
    credentials: types.optional(types.maybeNull(LessonCredentialsModel), null),
    technicalCancellation: types.maybeNull(types.boolean),
    editable: types.maybeNull(types.boolean),
    dataDuration: types.maybeNull(types.number),
    comment: types.maybeNull(types.string),
    actualUnitId: types.maybeNull(types.number),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    getCredentials: flow(function* () {
      const response = yield self.environment.api.getLessonCredentials(self.id);
      if (response.kind === "ok") {
        self.credentials = response.credentials;
      }
    }),
  }))
  .actions((self) => ({
    updateLesson: flow(function* (data: LessonUpdateModel) {
      const {
        date,
        timeZone,
        duration,
        attendance,
        status,
        comment,
        actualUnitId,
      } = data;
      const timeZoneDate = new Date(
        date?.toLocaleString("en-US", timeZone ? { timeZone } : {}) ?? ""
      );
      const startAt = format(timeZoneDate, "yyyy-MM-dd");
      const startTime = format(timeZoneDate, "HH:mm");

      const lessonSecondsApproximate = 30;

      const sendData: LessonUpdateData = {
        status,
        comment,
        startAt,
        startTime,
        duration: roundToClosest(duration ?? 0, lessonSecondsApproximate),
        attendance,
        actualUnitId,
      };
      const response = yield self.environment.api.updateLesson(
        self.id,
        sendData
      );

      if (response.kind !== "ok") {
        return { isUpdated: false, errors: response.message.errors };
      }

      if (status) {
        self.status = cast(status);
      }

      if (date) {
        self.startAtLocal = date;
        if (duration) {
          self.endAtLocal = addSeconds(timeZoneDate, duration);
        }
      }

      if (attendance) {
        self.attendance = cast(attendance);
      }

      if (duration) {
        self.dataDuration = cast(duration);
      }

      // Нужно сравнивать именно с undefined
      if (comment !== undefined) {
        self.comment = cast(comment);
      }

      self.actualUnitId = actualUnitId ? cast(actualUnitId) : null;

      return { isUpdated: true };
    }),
  }))
  .actions((self) => ({
    markProcessed() {
      self.technicalCancellation = true;
    },
  }))
  .views((self) => ({
    get currentStatus() {
      return self.status;
    },
  }))
  .views((self) => ({
    get isPrematurely() {
      if (!self.startAtLocal) return false;
      if (self.status !== "planned") return false;
      const currentDate = new Date();
      const difference =
        (currentDate.getTime() - self.startAtLocal.getTime()) /
        (1000 * 60 * 60 * 24);

      return difference > 1;
    },
    get classSchedule(): ClassSchedule | undefined {
      return getRoot<RootStore>(self).classSchedule.items.find(
        (item: ClassSchedule) => getSnapshot(item)?.lessons?.includes(self.id)
      );
    },
  }))
  .views((self) => ({
    get classGroup() {
      return self.classSchedule?.classGroup;
    },
    get day() {
      return self.startAtLocal && setMidnight(self.startAtLocal);
    },
  }))
  .views((self) => ({
    get learningGroup(): LearningGroup | undefined {
      return self.classGroup?.learningGroup;
    },
  }))
  .views((self) => ({
    getStartTime(): number {
      return self.startAtLocal?.getTime() ?? 0;
    },
  }))
  .views((self) => ({
    get index(): string | number {
      const { lessons = [] } = self.learningGroup ?? {};
      const index = [...lessons]
        .sort((prev, next) =>
          prev.getStartTime() > next.getStartTime() ? 1 : -1
        )
        .filter((lesson) => lesson.isCompleted && !lesson.compensation)
        .findIndex((lesson) => {
          return lesson.id === self.id;
        }) as number;
      return index >= 0 ? index + 1 : "";
    },
  }))
  .views((self) => ({
    isActiveLearningGroup(): boolean {
      return Boolean(self.learningGroup?.isActive);
    },
  }))
  .views(() => ({
    get availableStatuses(): string[] {
      const statuses = [
        "planned",
        "completed",
        "earlyCanceledByCustomer",
        "earlyCanceledBySchool",
        "lateCanceledByCustomer",
        "lateCanceledBySchool",
      ];
      return statuses;
    },
  }))
  .views((self) => ({
    get exactDuration() {
      if (self.startAtLocal && self.endAtLocal) {
        const timeDifference =
          self.endAtLocal.getTime() - self.startAtLocal.getTime();
        const timeDifferenceSeconds = timeDifference / 1000;
        return getLessonDurationInAcademicHours(timeDifferenceSeconds);
      }
      return 0;
    },
    get durationInSeconds() {
      if (self.startAtLocal && self.endAtLocal) {
        const timeDifference =
          self.endAtLocal.getTime() - self.startAtLocal.getTime();

        return Math.floor(timeDifference / 1000);
      }
      return 0;
    },
    isStudentAttended(studentId: number): boolean {
      return self.attendance.some(
        (attendance) => attendance.studentId === studentId
      );
    },
    getAttendanceByStudentId(
      studentId: number
    ): AttendanceModelType | undefined {
      return self.attendance.find(
        (attendance) => attendance.studentId === studentId
      );
    },
  }))
  .views((self) => ({
    get teacher(): Teacher | undefined {
      const { teacher: teacherStore } = getRoot<RootStore>(self);
      const { items: teachers = [] } = teacherStore ?? {};
      return teachers.find(({ actualId }) => actualId === self.teacherId);
    },
  }))
  .views((self) => ({
    get durationInMinutes() {
      return Number((self.durationInSeconds / 60).toFixed(1));
    },
  }))
  .views((self) => ({
    get studentsAttendance(): Attendance[] | undefined {
      return self.learningGroup?.students
        .filter((student) => {
          if (student.includedAt && self.startAtLocal) {
            const includedDate = new Date(student.includedAt);
            const lessonDate = new Date(self.startAtLocal);

            if (includedDate.getTime() >= lessonDate.getTime()) {
              return false;
            }

            return (
              !student.excludedAt ||
              lessonDate.getTime() <= new Date(student.excludedAt).getTime()
            );
          }

          return false;
        })
        .map(({ studentId = 0, firstName, lastName }) => {
          const attendace = self.getAttendanceByStudentId(Number(studentId));
          const { comment = "", attended = false } = attendace ?? {};

          return {
            studentId: Number(studentId),
            firstName,
            lastName,
            comment,
            attended,
          };
        });
    },
  }))
  .views((self) => ({
    get customerCompany() {
      return self.learningGroup?.customerCompany;
    },
    get link() {
      if (self.currentStatus === "completed") {
        return self.webinarRecordLink;
      }
      return self.webinarRoomLink;
    },
    // duration in hours
    get duration() {
      return Math.round(self.exactDuration);
    },
    get typeShort() {
      if (self.type) {
        if (self.type === "webinar") return "W";
        if (self.type === "face2face") return "FF";
      }

      return "unknown";
    },
    get isNotCompleted() {
      return (
        self.currentStatus === "planned" ||
        self.currentStatus === "completed" ||
        self.currentStatus === "lateCanceledByCustomer"
      );
    },
    get isCompleted() {
      return (
        self.currentStatus === "completed" ||
        self.currentStatus === "lateCanceledByCustomer" ||
        self.currentStatus === "notPerformedToPay"
      );
    },
    get isCanceled() {
      const canceledStatuses = [
        "earlyCanceledByCustomer",
        "earlyCanceledBySchool",
        "lateCanceledBySchool",
      ];
      return canceledStatuses.includes(self.status);
    },
    get students(): Student[] | undefined {
      const { studentsAttendance = [] } = self;
      const students = studentsAttendance
        .map((attendance) => ({
          id: attendance.studentId,
          firstName: attendance.firstName,
          lastName: attendance.lastName,
        }))
        .filter((student) => {
          return student.firstName && student.lastName;
        });
      return uniqBy(students, ({ id }) => id) as Student[];
    },
    get studentIdsWithHours() {
      return self.attendance.reduce((acc, attendance) => {
        const studentId = attendance?.studentId;
        if (!studentId) return acc;
        const hours = this.duration;
        if (acc[studentId]) {
          acc[studentId] += hours;
        } else {
          acc[studentId] = hours;
        }
        return acc;
      }, {} as { [key: string]: number });
    },
  }));

type LessonType = Instance<typeof LessonModel>;
export interface Lesson extends LessonType {}
type LessonSnapshotType = SnapshotOut<typeof LessonModel>;
export type LessonSnapshot = LessonSnapshotType;
export type LessonStudentsAttendance = {
  [key: string]: {
    hours: number;
    attended: boolean;
    compensation: boolean;
  };
};
