import { styled } from "@mui/material";

import { StyledPaper } from "../../StyledPaper";

export const StyledMainContent = styled(StyledPaper)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  minWidth: 0,
  gap: theme.spacing(5),
  marginBottom: "auto",
  padding: theme.spacing(4),
}));
