import { useState } from "react";

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>;

export function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      const hiddenInput = document.createElement("input");
      document.body.appendChild(hiddenInput);
      hiddenInput.value = text;
      hiddenInput.select();
      document.execCommand("copy", false);
      hiddenInput.remove();
      setCopiedText(text);
      return true;
    }
  };

  return [copiedText, copy];
}
