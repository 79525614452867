import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const ExerciseResultSpellcheckErrorModel = types
  .model("ExerciseResultSpellcheckError", {
    text: types.optional(types.string, ""),
    startPosition: types.optional(types.number, 0),
    endPosition: types.optional(types.number, 0),
    wordCount: types.optional(types.number, 0)
  });

type ExerciseResultSpellcheckErrorModelType = Instance<typeof ExerciseResultSpellcheckErrorModel>;
type ExerciseResultSpellcheckErrorModelTypeSnapshotType = SnapshotOut<typeof ExerciseResultSpellcheckErrorModel>;

export interface ExerciseResultSpellcheckError extends ExerciseResultSpellcheckErrorModelType {}
export type ExerciseResultSpellcheckErrorSnapshot = ExerciseResultSpellcheckErrorModelTypeSnapshotType;