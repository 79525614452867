import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";

import { TagActionPopoverContainer } from "./styledComponents/TagActionPopoverContainer";

export interface TagActionPopoverProps {
  anchorEl: Element | null;
  onClose: () => void;
  onChangeClick: () => void;
  onDeleteClick: () => void;
}

export const TagActionPopover = ({
  anchorEl,
  onClose,
  onChangeClick,
  onDeleteClick,
}: TagActionPopoverProps): JSX.Element => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <TagActionPopoverContainer
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "center", horizontal: "right" }}
    >
      <Box display="flex" flexDirection="column">
        <Button
          variant="text"
          sx={{ color: theme.palette.custom.primary.black, fontSize: "0.9rem" }}
          onClick={onChangeClick}
        >
          {t("TaggingList:TableTagChange")}
        </Button>
        <Button
          variant="text"
          sx={{
            color: theme.palette.custom.primary.black,
            fontSize: "0.9rem",
            "&:hover": {
              color: theme.palette.error.contrastText,
              background: theme.palette.error.main,
            },
          }}
          onClick={onDeleteClick}
        >
          {t("TaggingList:TableTagDelete")}
        </Button>
      </Box>
    </TagActionPopoverContainer>
  );
};
