import { useEffect, useState } from "react";

import { Typography, Theme as ThemeProps, useTheme } from "@mui/material";

import { JetLink } from "components/JetLink";
import { ROUTES } from "router/constants";

import {
  ContentContainer,
  IconContainer,
  ModuleTitleContainer,
  TitleContainer,
  TypeTextContainer,
  RatingBlockContainer,
  RatingCountContainer,
  RatingCountWrapperContainer,
  ModuleItemContainer,
  ModuleTypeContainer,
} from "./styledComponents";

import { Icon, IconType } from "../Icon";

export interface ModuleItemProps {
  blockTitle: string;
  moduleType: IconType;
  blockType?: string;
  totalExercises: number;
  completedExcercises: number;
  wrongCompletedExercises: number;
  rating?: number;
  courseId: string;
  unitId: string;
  blockId: string;
  exerciseId: string;
  showRating?: boolean;
  disabled?: boolean;
}
export interface ModuleItemContainerProps {
  theme: ThemeProps;
  colorVariant?: string;
}

type statusType = "wrong" | "success" | "notStarted";
type colorVariants = {
  [key in statusType]: string;
};

export function BlockItem({
  blockTitle,
  moduleType,
  blockType,
  totalExercises,
  completedExcercises,
  wrongCompletedExercises,
  courseId,
  unitId,
  blockId,
  exerciseId,
  showRating = true,
  disabled = false,
}: ModuleItemProps): JSX.Element {
  const [status, setStatus] = useState<statusType>("notStarted");
  const theme = useTheme();
  const colorVariants: colorVariants = {
    notStarted: theme.palette.custom.grey.main,
    success: theme.palette.success.main,
    wrong: theme.palette.warning.main,
  };
  useEffect((): void => {
    if (totalExercises === 0 || completedExcercises === 0) {
      setStatus("notStarted");
      return;
    }
    if (
      completedExcercises === totalExercises ||
      (completedExcercises > 0 && wrongCompletedExercises === 0)
    ) {
      setStatus("success");
      return;
    }
    if (wrongCompletedExercises) {
      setStatus("wrong");
    }
  }, [totalExercises, completedExcercises, wrongCompletedExercises]);
  return (
    <ModuleItemContainer>
      <ContentContainer>
        <ModuleTitleContainer>
          <TitleContainer>
            {disabled ? (
              <Typography variant="h4" color="custom.grey.main">
                {blockTitle}
              </Typography>
            ) : (
              <Typography variant="buttonUnderlined">
                <JetLink
                  // eslint-disable-next-line max-len
                  to={`${ROUTES.MY_COURSES}/${courseId}${ROUTES.EXERCISES}/${unitId}?blockId=${blockId}&exerciseId=${exerciseId}`}
                  // to={`/learn/courses/${courseId}/units/${unitId}?block_id=${blockId}&exercise_id=${exerciseId}`}
                  external
                  newTab
                >
                  {blockTitle}
                </JetLink>
              </Typography>
            )}
          </TitleContainer>
          <ModuleTypeContainer>
            <IconContainer>
              <Icon
                type={moduleType}
                width={16}
                height={16}
                stroke={colorVariants[status]}
              />
            </IconContainer>
            <TypeTextContainer
              colorVariant={colorVariants[status]}
              theme={theme}
            >
              <Typography
                variant="regularText"
                sx={{ textTransform: "capitalize" }}
              >
                {" "}
                {blockType}{" "}
              </Typography>
            </TypeTextContainer>
          </ModuleTypeContainer>
        </ModuleTitleContainer>
        {showRating && (
          <RatingBlockContainer>
            <RatingCountWrapperContainer>
              <RatingCountContainer
                colorVariant={colorVariants[status]}
                theme={theme}
              >
                <Typography variant="button">{`${
                  completedExcercises + wrongCompletedExercises
                } / ${totalExercises}`}</Typography>
              </RatingCountContainer>
            </RatingCountWrapperContainer>
          </RatingBlockContainer>
        )}
      </ContentContainer>
    </ModuleItemContainer>
  );
}
