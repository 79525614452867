import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { AnswerModel } from "@packages/store/models/ExerciseAnswer/Answer";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun } from "mobx";

export const useEssayExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const {
        answer,
        firstQuestion: { id },
      } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty) {
        const initialState = [
          QuestionModel.create({
            id,
            answers: [AnswerModel.create({})],
          }),
        ];

        setQuestions(initialState);
      }
    })
  );
};
