import { styled, Modal } from "@mui/material";

export const StyledModal = styled(Modal)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  overflow: "auto",
  padding: "0 10px",
  [theme.breakpoints.down("desktop")]: {
    alignItems: "flex-start",
  },
}));
