import { styled, TabProps, Tab } from "@mui/material";

export const StyledTab = styled((props: TabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  ...theme.typography.regularText,
  flex: 1,
  maxWidth: "100%",
  textTransform: "none",
  backgroundColor: theme.palette.custom.grey["menu-tile-inactive"],

  "&.Mui-selected": {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));
