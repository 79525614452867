/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import React from "react";

import * as Icons from "assets/icons/index";

export type IconType =
  | "alarmClock"
  | "bag"
  | "bell"
  | "boock"
  | "boockmark"
  | "burgerMenu"
  | "camera"
  | "chat"
  | "check"
  | "checkSquare"
  | "exerciseCheckBtn"
  | "chevronDown"
  | "chevronUp"
  | "close"
  | "course"
  | "crown"
  | "dashboard"
  | "dislike"
  | "download"
  | "edit"
  | "copy"
  | "eye"
  | "elementary"
  | "empty"
  | "facebook"
  | "instagram"
  | "file"
  | "grammar"
  | "graph"
  | "headphones"
  | "like"
  | "link"
  | "mail"
  | "mic"
  | "next"
  | "pen"
  | "reload"
  | "rockеt"
  | "search"
  | "selectMode"
  | "sortAmountDown"
  | "sortAmountUp"
  | "twitter"
  | "vk"
  | "user"
  | "users"
  | "vocabulary"
  | "words"
  | "youtube"
  | "exerciseCheckBtnOn"
  | "exerciseCheckBtnOff"
  | "shape"
  | "azIconTooltip"
  | "scrollbar"
  | "null"
  | "logos"
  | "bookOutline"
  | "bxTrash"
  | "bxVolumeFull"
  | "engLang"
  | "arrowAccordion"
  | "test"
  | "video"
  | "speaking"
  | "reading"
  | "bookReader"
  | "docs"
  | "bookMark"
  | "readBook"
  | "reports"
  | "moduleHat"
  | "translationIcon"
  | "HRWidgetGroup"
  | "HRWidgetStudent"
  | "HRWidgetLicense"
  | "group"
  | "classReports"
  | "platformStatistics"
  | "platformProgress"
  | "bookWithDot"
  | "groupReportPresentation"
  | "add"
  | "arrowRight"
  | "arrowLeft"
  | "trash"
  | "sendMail"
  | "attendance"
  | "classes"
  | "checkmark"
  | "ruLang"
  | "loginIcon"
  | "academicCap"
  | "elementaryGrey"
  | "bxLibrary"
  | "penGrey"
  | "students"
  | "play"
  | "stop"
  | "phone"
  | "bookAdd"
  | "history"
  | "teachers"
  | "salaries"
  | "odds"
  | "bxArrowRight"
  | "xSquare"
  | "passwordShow"
  | "passwordHide"
  | "calendar"
  | "dotsVertical"
  | "summary"
  | "companies"
  | "cancellation";

export interface IconProps {
  type: IconType;
  color?: string;
  stroke?: string;
  width?: number | string;
  height?: number | string;
  onClick?: (e: React.MouseEvent) => void;
  sizeSquareIcon?: number | string;
}

type IIcon = {
  [key in IconType]: React.ReactElement;
};

export const Icon = ({
  type,
  color,
  stroke,
  onClick,
  width,
  height,
  sizeSquareIcon,
}: IconProps): JSX.Element => {
  const defaultProps = {
    stroke,
    color,
    onClick,
    width: width || sizeSquareIcon,
    height: height || sizeSquareIcon,
  };

  const icon: IIcon = {
    alarmClock: <Icons.AlarmClock {...defaultProps} />,
    bag: <Icons.Bag {...defaultProps} />,
    bell: <Icons.Bell {...defaultProps} />,
    boock: <Icons.Boock {...defaultProps} />,
    boockmark: <Icons.Boockmark {...defaultProps} />,
    burgerMenu: <Icons.BurgerMenu {...defaultProps} />,
    camera: <Icons.Camera {...defaultProps} />,
    chat: <Icons.Chat {...defaultProps} />,
    check: <Icons.Check {...defaultProps} />,
    checkSquare: <Icons.CheckSquare {...defaultProps} />,
    exerciseCheckBtn: <Icons.ExerciseCheckBtn {...defaultProps} />,
    chevronDown: <Icons.ChevronDown {...defaultProps} />,
    chevronUp: <Icons.ChevronUp {...defaultProps} />,
    copy: <Icons.Copy {...defaultProps} />,
    eye: <Icons.Eye {...defaultProps} />,
    close: <Icons.Close {...defaultProps} />,
    course: <Icons.Course {...defaultProps} />,
    crown: <Icons.Crown {...defaultProps} />,
    exerciseCheckBtnOn: <Icons.ExerciseCheckBtnOn {...defaultProps} />,
    exerciseCheckBtnOff: <Icons.ExerciseCheckBtnOff {...defaultProps} />,
    dashboard: <Icons.Dashboard {...defaultProps} />,
    dislike: <Icons.Dislike {...defaultProps} />,
    download: <Icons.Download {...defaultProps} />,
    edit: <Icons.Edit {...defaultProps} />,
    elementary: <Icons.Elementary {...defaultProps} />,
    empty: <Icons.Empty {...defaultProps} />,
    facebook: <Icons.Facebook {...defaultProps} />,
    file: <Icons.File {...defaultProps} />,
    grammar: <Icons.Grammar {...defaultProps} />,
    graph: <Icons.Graph {...defaultProps} />,
    instagram: <Icons.Instagram {...defaultProps} />,
    headphones: <Icons.Headphones {...defaultProps} />,
    like: <Icons.Like {...defaultProps} />,
    link: <Icons.Link {...defaultProps} />,
    mail: <Icons.Mail {...defaultProps} />,
    mic: <Icons.Mic {...defaultProps} />,
    next: <Icons.Next {...defaultProps} />,
    pen: <Icons.Pen {...defaultProps} />,
    reload: <Icons.Reload {...defaultProps} />,
    reports: <Icons.Reports {...defaultProps} />,
    rockеt: <Icons.Rockеt {...defaultProps} />,
    search: <Icons.Search {...defaultProps} />,
    selectMode: <Icons.SelectMode {...defaultProps} />,
    sortAmountDown: <Icons.SortAmountDown {...defaultProps} />,
    sortAmountUp: <Icons.SortAmountUp {...defaultProps} />,
    twitter: <Icons.Twitter {...defaultProps} />,
    vk: <Icons.Vk {...defaultProps} />,
    user: <Icons.User {...defaultProps} />,
    users: <Icons.Users {...defaultProps} />,
    vocabulary: <Icons.Vocubalury {...defaultProps} />,
    words: <Icons.Words {...defaultProps} />,
    youtube: <Icons.Youtube {...defaultProps} />,
    shape: <Icons.Shape {...defaultProps} />,
    azIconTooltip: <Icons.AzIconTooltip {...defaultProps} />,
    scrollbar: <Icons.ScrollBar {...defaultProps} />,
    logos: <Icons.Logos {...defaultProps} />,
    bxTrash: <Icons.BxTrash {...defaultProps} />,
    bookOutline: <Icons.BookOutline {...defaultProps} />,
    bxVolumeFull: <Icons.BxVolumeFull {...defaultProps} />,
    engLang: <Icons.EngLang {...defaultProps} />,
    arrowAccordion: <Icons.ArrowAccordion {...defaultProps} />,
    test: <Icons.Test {...defaultProps} />,
    video: <Icons.Video {...defaultProps} />,
    speaking: <Icons.Speaking {...defaultProps} />,
    reading: <Icons.Reading {...defaultProps} />,
    bookReader: <Icons.Reader {...defaultProps} />,
    docs: <Icons.Docs {...defaultProps} />,
    bookMark: <Icons.BookMark {...defaultProps} />,
    readBook: <Icons.ReadBook {...defaultProps} />,
    moduleHat: <Icons.ModuleHat {...defaultProps} />,
    translationIcon: <Icons.TranslationIcon {...defaultProps} />,
    HRWidgetGroup: <Icons.HRWidgetGroup {...defaultProps} />,
    HRWidgetStudent: <Icons.HRWidgetStudents {...defaultProps} />,
    HRWidgetLicense: <Icons.HRWidgetLicense {...defaultProps} />,
    group: <Icons.Group {...defaultProps} />,
    classReports: <Icons.ClassReports {...defaultProps} />,
    platformStatistics: <Icons.PlatformStatistics {...defaultProps} />,
    platformProgress: <Icons.PlatformProgress {...defaultProps} />,
    bookWithDot: <Icons.BookWithDot {...defaultProps} />,
    groupReportPresentation: (
      <Icons.GroupReportPresentation {...defaultProps} />
    ),
    add: <Icons.Add {...defaultProps} />,
    arrowRight: <Icons.ArrowRight {...defaultProps} />,
    arrowLeft: <Icons.ArrowLeft {...defaultProps} />,
    trash: <Icons.Trash {...defaultProps} />,
    sendMail: <Icons.SendMail {...defaultProps} />,
    attendance: <Icons.Attendance {...defaultProps} />,
    loginIcon: <Icons.LoginIcon {...defaultProps} />,
    academicCap: <Icons.AcademicCap {...defaultProps} />,
    ruLang: <Icons.RuLang {...defaultProps} />,
    classes: <Icons.Classes {...defaultProps} />,
    checkmark: <Icons.CheckMark {...defaultProps} />,
    elementaryGrey: <Icons.ElementaryGrey {...defaultProps} />,
    bxLibrary: <Icons.BxLibrary {...defaultProps} />,
    penGrey: <Icons.PenGrey {...defaultProps} />,
    students: <Icons.Students {...defaultProps} />,
    play: <Icons.Play {...defaultProps} />,
    stop: <Icons.Stop {...defaultProps} />,
    xSquare: <Icons.XSquare {...defaultProps} />,
    phone: <Icons.Phone {...defaultProps} />,
    bookAdd: <Icons.BookAdd {...defaultProps} />,
    history: <Icons.History {...defaultProps} />,
    teachers: <Icons.Teachers {...defaultProps} />,
    salaries: <Icons.Salaries {...defaultProps} />,
    odds: <Icons.Odds {...defaultProps} />,
    bxArrowRight: <Icons.BxArrowRight {...defaultProps} />,
    passwordShow: <Icons.PasswordShow {...defaultProps} />,
    passwordHide: <Icons.PasswordHide {...defaultProps} />,
    calendar: <Icons.Calendar {...defaultProps} />,
    dotsVertical: <Icons.DotsVertical {...defaultProps} />,
    summary: <Icons.Summary {...defaultProps} />,
    companies: <Icons.Companies {...defaultProps} />,
    cancellation: <Icons.Cancellation {...defaultProps} />,
    null: <></>,
  };

  return icon[type];
};
