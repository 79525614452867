import { useState } from "react";

import { Tag } from "@packages/store/models/Tag/Tag";
import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Table } from "components/Table";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { TableCell } from "pages/shared/styledComponents";
import { TagActionPopover } from "pages/TaggingList/components";
import { formatTag, FormattedTag } from "pages/TaggingList/helpers";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

interface TaggingListTableProps {
  tags: Tag[];
  onTagChange: (id: string) => void;
  onTagDelete: (id: string) => void;
}

export const TaggingListTable = observer(
  ({
    tags,
    onTagChange,
    onTagDelete,
  }: TaggingListTableProps): JSX.Element | null => {
    const { t } = useTranslation();

    const [popoverAnchorEl, setPopoverAnchorEl] = useState<Element | null>(
      null
    );
    const [selectedTagId, setSelectedTagId] = useState<string | null>(null);

    const handleActionClick = (el: Element | null, id: string) => {
      setSelectedTagId(id);
      setPopoverAnchorEl(el);
    };

    const handlePopoverClose = () => setPopoverAnchorEl(null);

    const handleTagChange = () => {
      if (!selectedTagId) return;

      handlePopoverClose();
      onTagChange(selectedTagId);
    };

    const handleTagDelete = () => {
      if (!selectedTagId) return;

      handlePopoverClose();
      onTagDelete(selectedTagId);
    };

    const columns = [
      {
        Header: t("TaggingList:TableTagName"),
        accessor: "name",
        Cell: ({ value }: Cell<FormattedTag, string>) => (
          <TableCell variant="buttonUnderlined" color="primary.main">
            {value}
          </TableCell>
        ),
      },
      {
        Header: "",
        accessor: "edit",
        style: {
          width: 20,
        },
        Cell: ({ row }: Cell<FormattedTag, string>) => (
          <TableCell
            color="info.main"
            width="28px"
            onClick={(e) =>
              handleActionClick(e.currentTarget.parentElement, row.original.id)
            }
          >
            <Button variant="iconBtn" sx={{ height: "28px", width: "28px" }}>
              <Icon type="dotsVertical" sizeSquareIcon={20} />
            </Button>
          </TableCell>
        ),
      },
    ];

    const formattedTags = computed(() => tags.map(formatTag)).get();

    return (
      <>
        <Table
          columns={columns}
          data={formattedTags}
          count={10}
          containerType="blue"
        />
        <TagActionPopover
          anchorEl={popoverAnchorEl}
          onClose={handlePopoverClose}
          onChangeClick={handleTagChange}
          onDeleteClick={handleTagDelete}
        />
      </>
    );
  }
);
