import { styled } from "@mui/material";

// https://kingsora.github.io/OverlayScrollbars/#!documentation/classnames
export const StyledScrollbarComponent = styled("div")<any>(() => ({
  ".os-host": {
    maxHeight: 400,
  },

  ".os-scrollbar-vertical > .os-scrollbar-track > .os-scrollbar-handle": {
    minHeight: "20%",
    maxHeight: "20%",
  },

  ".overflowed": {
    paddingRight: 16,
  },
}));
