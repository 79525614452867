import { CSSProperties } from "react";

import { Box } from "@mui/material";
import { useDragLayer, XYCoord } from "react-dnd";

import { useDragDrop } from "../../hooks/useDragDrop";
import { DragItemType } from "../../types";

import { AnswerView } from "../AnswerView";

const getItemStyles = (
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
): CSSProperties => {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
};

export const AnswerDragLayer = (): JSX.Element | null => {
  const {
    dragSize: { width, height },
  } = useDragDrop();

  const { itemType, isDragging, item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  const renderItem = (): JSX.Element | null => {
    if (itemType === DragItemType.ANSWER) {
      const { title } = item as { title: string };

      return <AnswerView title={title} status="default" fullSize />;
    }
    return null;
  };

  if (!isDragging) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "fixed",
        pointerEvents: "none",
        zIndex: 9999,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          ...getItemStyles(initialOffset, currentOffset),
          display: "flex",
          width,
          height,
        }}
      >
        {renderItem()}
      </Box>
    </Box>
  );
};
