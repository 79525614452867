import { styled, Typography } from "@mui/material";

export const Unit = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
}));

export const Exercises = styled("span")(({ theme }) => ({
  color: theme.palette.custom.grey.main,
  fontWeight: 600,
  marginLeft: theme.spacing(1),
}));

export const Results = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  marginTop: theme.spacing(2),
}));
