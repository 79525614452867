import { Box, styled } from "@mui/material";

export const FilterContainer = styled(Box)(() => ({
  display: "flex",
  marginBottom: "1rem",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "2rem",
  flexWrap: "wrap",
}));
