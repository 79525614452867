import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { AnswerDragLayer } from "../../../../../../components/AnswerDragLayer";
import { OrderQuestion } from "../../../../../../components/OrderQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Block = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, getCorrectAnswer } = exercise;

    const { getQuestion } = ensure(answer);

    return (
      <>
        <AnswerDragLayer />
        <ObserverFlexList
          items={questions}
          renderItem={(question: Question) => (
            <OrderQuestion
              key={question.id}
              question={question}
              answerData={ensure(getQuestion(question.id))}
              correctAnswer={getCorrectAnswer(question.id)}
            />
          )}
        />
      </>
    );
  }
);
