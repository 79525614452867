// import { useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
// TODO Доделать
// import { useLocation } from "react-router";

import { StyledNavigationPopover } from "./styledComponents/StyledNavigationPopover";
import { usePopover } from "../../hooks/usePopover";
import { ObserverFlexList } from "../FlexList";
import { IconType } from "../Icon";
import { NavigationWidget } from "../NavigationWidget";

interface NavigationPopoverProps<T> {
  widgetSubTitle: string;
  popoverTitle: string;
  iconType: IconType;
  currentItem: T | undefined;
  items: T[] | undefined;
  renderItem: (item: T, index: number, array: T[]) => JSX.Element | null;
  getItemName: (item: T | undefined) => string;
  disabled?: boolean;
  loading?: boolean;
}

export const NavigationPopover = observer(
  <T,>({
    widgetSubTitle,
    popoverTitle,
    iconType,
    currentItem,
    items,
    renderItem,
    getItemName,
    disabled,
    loading,
  }: NavigationPopoverProps<T>): JSX.Element | null => {
    // const location = useLocation();

    const { open, anchorEl, handleOpen, handleClose } = usePopover();

    // useEffect(() => {
    //   handleClose();
    // }, [handleClose, location]);

    const currentItemOrder =
      currentItem && items ? items.indexOf(currentItem) + 1 : -1;

    return (
      <Box sx={{ flex: 1, minWidth: 100 }}>
        <NavigationWidget
          title={`${currentItemOrder}. ${getItemName(currentItem)}`}
          subTitle={widgetSubTitle}
          iconType={iconType}
          onClick={handleOpen}
          loading={loading}
          disabled={disabled}
        />

        {!disabled && (
          <StyledNavigationPopover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transitionDuration={200}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h3" sx={{ mb: 3 }}>
                {popoverTitle}
              </Typography>

              {items && (
                <ObserverFlexList items={items} renderItem={renderItem} />
              )}
            </Box>
          </StyledNavigationPopover>
        )}
      </Box>
    );
  }
);
