const getBorder = ({ style = "thin" } = {}) => ({
  top: { style },
  bottom: { style },
  left: { style },
  right: { style },
});

export const getEmptyBorderedCells = (count: number) =>
  new Array(count).fill({ v: "", t: "s", s: { border: getBorder() } });

export const sheetStyle = {
  groupName: {
    font: { sz: 16, bold: true },
    fill: { fgColor: { rgb: "d2ffc6" } },
    border: getBorder(),
  },
  metaInfo: {
    font: { sz: 12, bold: true },
  },
  teacherLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  teacher: {
    font: { sz: 12 },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  scheduleDayLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  scheduleDay: {
    font: { sz: 12 },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  scheduleTimeLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  scheduleTime: {
    font: { sz: 12 },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  filler: {
    fill: { fgColor: { rgb: "c6d9f0" } },
  },
  lessonsLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "right",
    },
    border: getBorder(),
  },
  lessons: {
    font: { sz: 12 },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  monthesLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "right",
    },
    border: getBorder(),
  },
  monthes: {
    font: { sz: 14, bold: true },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  daysLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "right",
    },
    border: getBorder(),
  },
  days: {
    font: { sz: 12 },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
  studentNumber: {
    font: { sz: 12 },
    border: getBorder(),
  },
  studentName: {
    font: { sz: 12, bold: true },
    border: getBorder(),
  },
  studentAttendance: {
    font: { sz: 12 },
    border: getBorder(),
  },
  hoursLabel: {
    font: { sz: 12, bold: true },
    alignment: {
      horizontal: "right",
    },
    border: getBorder(),
  },
  hours: {
    font: { sz: 12, color: { rgb: "888888" } },
    alignment: {
      horizontal: "center",
    },
    border: getBorder(),
  },
};
