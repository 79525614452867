import { Tooltip, Typography, useTheme } from "@mui/material";
import { Course } from "@packages/store/models/Course/CourseModel";
import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { StyledButton } from "./styledComponents/StyledButton";
import { StyledToolPanel } from "./styledComponents/StyledToolPanel";
import { useTranslateMode } from "../../hooks/useTranslateMode";
import { Icon } from "../Icon";
import { TooltipTitle } from "../TooltipTitle";

interface ToolPanelProps {
  course: Course | undefined;
  exercise: Exercise | undefined;
  onNextExercise: () => void;
  onPrevExercise: () => void;
  onCheck: () => void;
}

export const ToolPanel = observer(
  ({
    course,
    exercise,
    onNextExercise,
    onPrevExercise,
    onCheck,
  }: ToolPanelProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const { translateMode, toggleTranslateMode } = useTranslateMode();

    const { isConfirmation, isCreative, isChecked, isPassed, isFilled, reset } =
      exercise || {};

    const { isForTesting } = course || {};

    const checkDisabled = isConfirmation ? isPassed : isChecked || !isFilled;

    const nextDisabled = !isChecked && isForTesting;

    const checkButtonTitle = isConfirmation
      ? t("Exercise:Ok")
      : t("Exercise:ToolCheck");

    return (
      <StyledToolPanel>
        {!isForTesting && (
          <>
            {!isCreative && (
              <StyledButton onClick={reset}>
                <Icon
                  type="reload"
                  sizeSquareIcon={16}
                  color={theme.palette.custom.grey.main}
                />
                <Typography
                  sx={{
                    "@container (max-width: 370px)": {
                      "&": { display: "none" },
                    },
                  }}
                >
                  {t("Exercise:ToolReload")}
                </Typography>
              </StyledButton>
            )}

            <StyledButton
              active={translateMode}
              onClick={toggleTranslateMode}
              sx={{
                "@container (max-width: 480px)": {
                  "&": { display: "none" },
                },
              }}
            >
              <Icon
                type="words"
                sizeSquareIcon={16}
                color={
                  translateMode
                    ? theme.palette.info.main
                    : theme.palette.custom.grey.main
                }
              />
              {t("Exercise:ToolTranslate")}
            </StyledButton>
          </>
        )}

        {checkDisabled ? (
          <Tooltip
            placement="top"
            title={
              <TooltipTitle
                variant="error"
                title={
                  isChecked
                    ? t("Exercise:IsCheckedWarning")
                    : t("Exercise:NotFilledWarning")
                }
              />
            }
          >
            <span style={{ width: "100%" }}>
              <StyledButton variant="check" disabled>
                {checkButtonTitle}
              </StyledButton>
            </span>
          </Tooltip>
        ) : (
          <StyledButton variant="check" onClick={onCheck}>
            {checkButtonTitle}
          </StyledButton>
        )}

        {!isForTesting && (
          <StyledButton
            small
            rotatedSvg
            onClick={onPrevExercise}
            disabled={nextDisabled}
          >
            <Icon type="next" sizeSquareIcon={16} />
          </StyledButton>
        )}

        {nextDisabled ? (
          <Tooltip
            placement="top"
            title={
              <TooltipTitle
                variant="error"
                title={t("Exercise:NextDisabledWarning")}
              />
            }
          >
            <span style={{ width: "100%" }}>
              <StyledButton onClick={onNextExercise} disabled>
                <Typography
                  sx={{
                    "@container (max-width: 290px)": {
                      "&": { display: "none" },
                    },
                  }}
                >
                  {t("Exercise:ToolNext")}
                </Typography>

                <Icon type="next" sizeSquareIcon={16} />
              </StyledButton>
            </span>
          </Tooltip>
        ) : (
          <StyledButton onClick={onNextExercise}>
            <Typography
              sx={{
                "@container (max-width: 290px)": {
                  "&": { display: "none" },
                },
              }}
            >
              {t("Exercise:ToolNext")}
            </Typography>
            <Icon type="next" sizeSquareIcon={16} />
          </StyledButton>
        )}
      </StyledToolPanel>
    );
  }
);
