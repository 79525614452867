import { Box, styled } from "@mui/material";

export const IconWrapper = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  "& svg": {
    transform: "translateX(25%)",
  },
}));
