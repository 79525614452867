import { useCurrentBreakpoint } from "@packages/shared/themes/breakpoints/breakpoints";

import { FooterDesktop } from "components/FooterDesktop";
import { FooterMobile } from "components/FooterMobile";

import { RouterLinkProps } from "./constants";

export interface FooterProps {
  routerLinks: RouterLinkProps;
}

export const Footer = ({ routerLinks }: FooterProps): JSX.Element => {
  const breakpoint = useCurrentBreakpoint();

  const footer = {
    zeroScreen: <FooterMobile />,
    mobile: <FooterMobile />,
    tablet: <FooterMobile />,
    laptop: <FooterMobile />,
    desktop: <FooterDesktop routerLinks={routerLinks} />,
  };

  return footer[breakpoint];
};
