/* eslint-disable no-console */
import { useEffect, useState } from "react";

import { Box, Snackbar, Alert } from "@mui/material";
import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { Cell } from "react-table";

import { useStores } from "@packages/store/models";
import { GenerateDistributorReport } from "@packages/store/models/GenerateReport/GenerateReport";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";
import { DistributorReportType } from "@packages/store/services/Api";

import { Checkbox } from "components/Checkbox";
import { Loading } from "components/Loading";
import { Table } from "components/Table";
import { useCancelableFetch } from "hooks";
import { ROUTES } from "router/constants";

import { ReportAction } from "./ReportAction";
import { ReportGroupAction } from "./ReportGroupAction";
import { ReportStatus } from "./ReportStatus";
import { ReportText } from "./ReportText";
import { ReportStatusType } from "./types";

export type TooltipValue<T> = {
  value?: T;
  tooltip: string;
};

export const ReportTable = observer((): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [actionMessage, setActionMessage] = useState<string | null>(null);
  const [currentType, setCurrentType] = useState(
    DistributorReportType.students
  );

  const { distributorReport, api } = useStores();

  const { items, removeItems, sendToEmails } = distributorReport;

  const {
    fetch: fetchStudentReports,
    loading: loadingStudentReports,
    error: studentReportsError,
  } = useCancelableFetch();
  const {
    fetch: fetchTeacherReports,
    loading: loadingTeacherReports,
    error: teacherReportsError,
  } = useCancelableFetch();

  const isLoading = loadingStudentReports || loadingTeacherReports;
  const isError = Boolean(studentReportsError || teacherReportsError);

  const reports = items.filter((item) => item.type === currentType);

  const data = reports.map((report) => ({
    id: report.id,
    checked: true,
    name: {
      value: report.name,
      tooltip: report.name,
    },
    createdAt: {
      value: report.createdAt
        ? format(report.createdAt, "dd.MM.yyyy h:m")
        : "-",
      tooltip: report.name,
    },
    dates: {
      value: `${report.dateFrom} - ${report.dateTo}`,
      tooltip: report.name,
    },
    status: {
      value: report.status,
      tooltip: report.name,
    },
    actions: {
      tooltip: report.name,
    },
  }));

  const isReportSelected = (report: GenerateDistributorReport) => {
    return !!selectedReports.find((id) => id === report.id);
  };

  const checkedCount = reports.filter(isReportSelected).length;

  const isAllChecked = checkedCount === reports.length && reports.length > 0;

  const clearActionMessage = () => {
    setActionMessage(null);
  };

  const isActiveCell = (row: number) => {
    return isReportSelected(reports[row]);
  };

  const handleAllReportsCheck = () => {
    const checkedList = isAllChecked ? [] : reports.map((report) => report.id);
    setSelectedReports(checkedList);
  };

  const handleReportCheck = (row: number) => () => {
    const report = reports[row];
    if (isReportSelected(reports[row])) {
      setSelectedReports(selectedReports.filter((id) => id !== report.id));
    } else {
      setSelectedReports([...selectedReports, report.id]);
    }
  };

  const hangeReportShow = (row: number) => () => {
    const { id, status } = reports[row];
    if (status !== ReportStatusType.ready) {
      return;
    }
    navigate(`detail/${id}`);
  };

  const downloadReportXlsx = (report: GenerateDistributorReport) => {
    if (report.type === DistributorReportType.teachers) {
      report.downloadXlsx("short");
      return;
    }

    report.downloadXlsx();
  };

  const handleReportMail = (row: number) => async () => {
    const report = reports[row];
    await sendToEmails([report.id]);
    setActionMessage("Email sent successfully!");
  };

  const handleReportDownload = (row: number) => () => {
    downloadReportXlsx(reports[row]);
  };

  const handleReportTrash = (row: number) => () => {
    const isDelete = window.confirm(t("Report:DeleteReport") ?? "");
    if (!isDelete) {
      return;
    }
    const report = reports[row];
    removeItems([report.id]);
  };

  const handleReportMailSelected = async () => {
    const ids = reports.filter(isReportSelected).map(({ id }) => id);
    await sendToEmails(ids);
    setActionMessage("Email sent successfully!");
  };

  const handleReportDownloadSelected = () => {
    reports.filter(isReportSelected).forEach(downloadReportXlsx);
  };

  const handleReportTrashSelected = () => {
    const isDelete = window.confirm(t("Report:DeleteReport") ?? "");
    if (!isDelete) {
      return;
    }
    const ids = reports.filter(isReportSelected).map(({ id }) => id);
    removeItems(ids);
  };

  const getBackgroundColor = (cell: Cell, defaultColor = "transparent") => {
    return isActiveCell(+cell.row.id) ? "#D7D7D7" : defaultColor;
  };

  useEffect(() => {
    const { students } = DistributorReportType;
    fetchStudentReports((token) =>
      api.getDistributorReportByFilter({ type: students }, token)
    );
  }, [api, fetchStudentReports]);

  useEffect(() => {
    const { teachers } = DistributorReportType;
    fetchTeacherReports((token) =>
      api.getDistributorReportByFilter({ type: teachers }, token)
    );
  }, [api, fetchTeacherReports]);

  useEffect(() => {
    const { pathname } = location;
    const types = [
      {
        path: ROUTES.STUDENTS_REPORTS,
        type: DistributorReportType.students,
      },
      {
        path: ROUTES.TEACHERS_REPORTS,
        type: DistributorReportType.teachers,
      },
    ];
    const { type = DistributorReportType.students } =
      types.find(({ path }) => pathname.includes(path)) ?? {};
    setCurrentType(type);
  }, [setCurrentType, location]);

  const columns = [
    {
      Header: "",
      accessor: "checked",
      filter: "checked",
      Cell: ({ row }: Cell<Lesson, boolean>) => (
        <Checkbox
          checked={isActiveCell(+row.id)}
          onChange={handleReportCheck(+row.id)}
        />
      ),
      cellStyles: (cell: Cell) => ({
        background: getBackgroundColor(cell, "#F7F7F7"),
        width: 0,
      }),
    },
    {
      Header: t("Report:Title"),
      accessor: "name",
      Cell: ({ row, value }: Cell<Lesson, TooltipValue<string>>) => (
        <ReportText
          text={value.value}
          tooltip={value.tooltip}
          onClick={hangeReportShow(+row.id)}
        />
      ),
      cellStyles: (cell: Cell) => ({
        background: getBackgroundColor(cell, "#F7F7F7"),
        textDecoration: "underline",
      }),
    },
    {
      Header: t("Report:CreationDate"),
      accessor: "createdAt",
      Cell: ({ row, value }: Cell<Lesson, TooltipValue<string>>) => (
        <ReportText
          text={value.value}
          tooltip={value.tooltip}
          onClick={hangeReportShow(+row.id)}
        />
      ),
      cellStyles: (cell: Cell) => ({
        background: getBackgroundColor(cell),
      }),
    },
    {
      Header: t("Report:Period"),
      accessor: "dates",
      Cell: ({ row, value }: Cell<Lesson, TooltipValue<string>>) => (
        <ReportText
          text={value.value}
          tooltip={value.tooltip}
          onClick={hangeReportShow(+row.id)}
        />
      ),
      cellStyles: (cell: Cell) => ({
        background: getBackgroundColor(cell),
      }),
    },
    {
      Header: t("Report:Status"),
      accessor: "status",
      Cell: ({ row, value }: Cell<Lesson, TooltipValue<ReportStatusType>>) => (
        <ReportStatus
          type={value.value ?? ReportStatusType.planned}
          tooltip={value.tooltip}
          onClick={hangeReportShow(+row.id)}
        />
      ),
      cellStyles: (cell: Cell) => ({
        background: getBackgroundColor(cell),
      }),
    },
    {
      Header: t("Report:Actions"),
      accessor: "actions",
      Cell: ({ row }: Cell<Lesson, TooltipValue<void>>) => (
        <Box display="flex" alignItems="center" gap="2rem">
          <ReportAction
            icon="sendMail"
            color="#5783F1"
            tooltip={t("Report:MailTooltip")}
            onClick={handleReportMail(+row.id)}
          />
          <ReportAction
            icon="download"
            color="#47B347"
            tooltip={t("Report:DownloadTooltip")}
            onClick={handleReportDownload(+row.id)}
          />
          <ReportAction
            icon="trash"
            color="#FF2E00"
            tooltip={t("Report:DeleteTooltip")}
            onClick={handleReportTrash(+row.id)}
          />
        </Box>
      ),
      cellStyles: (cell: Cell) => ({
        background: getBackgroundColor(cell),
        width: 0,
      }),
    },
  ];

  return (
    <Loading loading={isLoading} error={isError}>
      <Box mt="0.5rem">
        <Box display="flex" gap="1rem" alignItems="center" paddingX="1rem">
          <Checkbox onChange={handleAllReportsCheck} checked={isAllChecked} />
          <ReportGroupAction
            icon="sendMail"
            isActive={checkedCount !== 0}
            activeColor="#5783F1"
            inactiveColor="#D7D7D7"
            tooltip={t("Report:MailGroupTooltip")}
            onClick={handleReportMailSelected}
          />
          <ReportGroupAction
            icon="download"
            isActive={checkedCount !== 0}
            activeColor="#47B347"
            inactiveColor="#D7D7D7"
            tooltip={t("Report:DownloadGroupTooltip")}
            onClick={handleReportDownloadSelected}
          />
          <ReportGroupAction
            icon="trash"
            isActive={checkedCount !== 0}
            activeColor="#FF2E00"
            inactiveColor="#D7D7D7"
            tooltip={t("Report:DeleteGroupTooltip")}
            onClick={handleReportTrashSelected}
          />
        </Box>
        <Table data={data} columns={columns} count={10} containerType="blue" />
        <Snackbar
          open={!!actionMessage}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          onClose={clearActionMessage}
        >
          <Alert severity="info" onClose={clearActionMessage}>
            {actionMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Loading>
  );
});
