import { PropsWithChildren } from "react";

import {
  Box,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { LoaderWrapper } from "./styledComponents/LoaderWrapper";

export interface LoadingProps extends PropsWithChildren {
  error?: boolean;
  errorLabel?: string;
  centerError?: boolean;

  loading: boolean;
  cover?: boolean;
  wrapperStyles?: SxProps<Theme>;
}

export const Loading = ({
  error,
  errorLabel,
  centerError = true,
  loading,
  children,
  cover,
  wrapperStyles,
}: LoadingProps): JSX.Element | null => {
  const { t } = useTranslation();

  const getContent = () => {
    const getInnerContent = (cover?: boolean) => {
      if (error) {
        return (
          <Box textAlign={centerError ? "center" : undefined}>
            <Typography variant="largeText" color="error.main">
              {errorLabel || t("Loading:Error")}
            </Typography>
          </Box>
        );
      }

      if (loading) {
        return (
          <LoaderWrapper cover={cover} sx={wrapperStyles}>
            <CircularProgress />
          </LoaderWrapper>
        );
      }

      return children;
    };

    if (cover) {
      return <Box sx={{ position: "relative" }}>{getInnerContent(true)}</Box>;
    }

    return getInnerContent(false);
  };

  return <>{getContent()}</>;
};
