import { useCallback } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Text } from '../../../components/Text';

import styles from './SearchSuggestionItem.module.scss';

export interface SearchSuggestionItemProps {
	text: string;
  onClick?: (_: string) => void;
}

export const SearchSuggestionItem = observer(function SearchSuggestionItem(props: SearchSuggestionItemProps) {
  const {
		text,
    onClick
	} = props;

  const handleClick = useCallback(() => {
    onClick?.(text);
  }, [
    text,
    onClick
  ]);

	return (
		<div
      className={styles['container']}
      onClick={handleClick}
    >
			<Text variant='text-3-medium'>
        {text}
      </Text>
		</div>
	)
})
