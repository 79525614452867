/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-multi-assign */
/* eslint-disable jsx-a11y/media-has-caption */

import { memo, useEffect, useRef } from "react";

import { Box, Theme, SxProps } from "@mui/material";
import videojs, { VideoJsPlayerOptions, VideoJsPlayer } from "video.js";

import "video.js/dist/video-js.css";

export type VideoPlayerType = VideoJsPlayer;

export type VideoPlayerOptions = VideoJsPlayerOptions;

export type VideoPlayerSources = VideoJsPlayerOptions["sources"];

interface VideoPlayerProps {
  options: VideoPlayerOptions;
  containerStyles?: SxProps<Theme> | undefined;
  onReady?: (player: VideoPlayerType) => void;
}

export const VideoPlayer = memo(
  ({
    options,
    containerStyles,
    onReady,
  }: VideoPlayerProps): JSX.Element | null => {
    const videoRef = useRef(null);
    const playerRef = useRef<VideoPlayerType | null>(null);

    useEffect(() => {
      if (!playerRef.current) {
        const videoEl = videoRef.current;

        if (!videoEl) {
          return;
        }

        const player = (playerRef.current = videojs(
          videoEl,
          { controls: true, responsive: true, fluid: true, ...options },
          () => {
            onReady?.(player);
          }
        ));
      } else {
        // eslint-disable-next-line no-lonely-if
        if (options.sources) {
          playerRef.current.src(options.sources);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, videoRef]);

    useEffect(() => {
      const player = playerRef.current;
      return () => {
        if (player) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    return (
      <Box sx={containerStyles}>
        <div data-vjs-player>
          <video ref={videoRef} className="video-js vjs-big-play-centered" />
        </div>
      </Box>
    );
  }
);
