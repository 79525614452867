/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance, IAnyModelType } from "mobx-state-tree";
import { CustomerCompanyModel } from "../CustomerCompany/CustomerCompanyModel";

export const CompanyReportModel = types.model("CompanyReportModel").props({
  id: types.identifier,
  averagePrice: types.maybeNull(types.string),
  company: types.maybeNull(
    types.late(() =>
      types.reference(types.late((): IAnyModelType => CustomerCompanyModel))
    )
  ),
  totalAcademicHours: types.maybeNull(types.string),
  totalGroups: types.maybeNull(types.number),
  totalLessons: types.maybeNull(types.number),
  totalPayment: types.maybeNull(types.string),
});

type CompanyReportModelType = Instance<typeof CompanyReportModel>;
export interface CompanyReport extends CompanyReportModelType {}
type CompanyReportSnapshotType = SnapshotOut<typeof CompanyReportModel>;
export type CompanyReportSnapshot = CompanyReportSnapshotType;
