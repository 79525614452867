import { ComponentProps } from "react";

import {
  ModalProps as MuiModalProps,
  Box,
  BoxProps,
  Typography,
  SxProps,
} from "@mui/material";

import { StyledModal, ModalContainer } from "./styledComponents";
import { Button } from "../../Button";
import { Icon } from "../../Icon";

export interface ModalProps extends Omit<MuiModalProps, "children" | "title"> {
  title?: string | null;
  titleColor?: string;
  titleVariant?: ComponentProps<typeof Typography>["variant"];
  type?: "extraSmall" | "small" | "default" | "large";
  containerStyles?: SxProps;
  children: BoxProps["children"];
}

// TODO Переписать все модалки на эту
export const Modal = ({
  title = "",
  titleColor,
  titleVariant = "h2",
  type = "default",
  containerStyles,
  children,
  ...other
}: ModalProps): JSX.Element | null => {
  return (
    <StyledModal {...other}>
      <ModalContainer type={type} sx={containerStyles}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: title ? "1rem" : "0",
          }}
          {...other}
        >
          <Typography variant={titleVariant} color={titleColor}>
            {title}
          </Typography>
          {other?.onClose && (
            <Button
              variant="iconBtn"
              onClick={(e) => other.onClose?.(e, "escapeKeyDown")}
            >
              <Icon type="close" width={24} height={24} />
            </Button>
          )}
        </Box>
        {children}
      </ModalContainer>
    </StyledModal>
  );
};
