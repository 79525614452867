import { Box } from "@mui/material";
import { useStores } from "@packages/store/models";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ModalGroupReport } from "../components/ModalGroupReport";
// import { useNavigate, useParams } from "react-router";

import { getGroupClassPeriodDates } from "../helpers";

interface GroupReportProps {
  id: string | undefined;
  onClose: () => void;
}

export const GroupReport = observer(
  ({ id, onClose }: GroupReportProps): JSX.Element => {
    const { t } = useTranslation();

    // const navigate = useNavigate();

    const store = useStores();

    const { learningGroup } = store;

    // const { id } = useParams();

    const group = learningGroup.items.find((item) => item.id === id);

    if (!group) {
      return <Box />;
    }

    const { statistics } = group;

    const getWeekDay = (day: string) => {
      return t(`ShortWeekDay:${day}`);
    };

    const schedules = statistics.schedules.map((schedule) => {
      const { day, startTime, endTime } = schedule;
      const weekDay = getWeekDay(day);
      return `${weekDay} ${startTime} - ${endTime}`;
    });

    // const onClose = () => {
    //   navigate(-1);
    // };

    return (
      <ModalGroupReport
        isOpen
        onClose={onClose}
        groupName={group.name ?? ""}
        teacherName={statistics.teacherName}
        schedule={schedules}
        courseName={statistics.courses}
        classPeriod={getGroupClassPeriodDates(group)}
        totalHours={statistics.totalHours}
        completedHours={statistics.completedHours}
        leftHours={statistics.leftHours}
        lateCustomerCancelHours={statistics.lateCustomerCancelHours}
        lateSchoolCancelHours={statistics.lateSchoolCancelHours}
        compensationHours={statistics.compensationHours}
        earlyCustomerCancelHours={statistics.earlyCustomerCancelHours}
        earlySchoolCancelHours={statistics.earlySchoolCancelHours}
        students={statistics.students}
      />
    );
  }
);
