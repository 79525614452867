/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { PassingModel, PassingSnapshot } from "./Passing";
import { withEnvironment } from "../extensions/withEnvironment";

export const PassingStoreModel = types
  .model("PassingStore")
  .props({
    items: types.optional(types.array(PassingModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: PassingSnapshot) => {
      const instance = resolveIdentifier(PassingModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));

type PassingStoreType = Instance<typeof PassingStoreModel>;
export type PassingStore = PassingStoreType;
type PassingStoreSnapshotType = SnapshotOut<typeof PassingStoreModel>;
export type PassingStoreSnapshot = PassingStoreSnapshotType;
