import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const RightContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  [theme.breakpoints.down("tablet")]: {
    flex: 1,
  },
}));
