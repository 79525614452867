import { routerLinks } from "constants/constants";

import { Box, Typography, useTheme } from "@mui/material";
import { useStores } from "@packages/store/models";
import { LoginProps } from "@packages/store/models/AuthStore";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { JetLink } from "components/JetLink";
import { Logo } from "components/Logo";
import { TrimInput } from "components/TrimInput";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { ROUTES } from "router/constants";

import {
  FormWrapper,
  FormContainer,
  FormTitle,
  FormSubTitle,
  InputsContainer,
  CheckboxesContainer,
  StyledMuiLink,
  StyledRouterLink,
  LogoWrapper,
} from "../../styledComponents";

export const LoginForm = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const theme = useTheme();

  const { auth } = useStores();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginProps>();

  const onSubmit: SubmitHandler<LoginProps> = ({ email, password }) => {
    auth.login(email, password);
  };

  return (
    <FormWrapper>
      <FormContainer>
        <LogoWrapper>
          <Logo width={128} height={32} />
        </LogoWrapper>

        <form onSubmit={handleSubmit(onSubmit)}>
          <FormTitle>
            Welcome to <span>JetClass</span>!
          </FormTitle>

          <FormSubTitle>{t(`Login:subTitle`, { lng: "ru" })}</FormSubTitle>

          <InputsContainer>
            <TrimInput
              variant="standard"
              label={t(`Login:email`, { lng: "ru" })}
              {...register("email", { required: true })}
              error={!!errors.email}
            />
            <TrimInput
              type="password"
              variant="standard"
              label={t(`Login:password`, { lng: "ru" })}
              {...register("password", { required: true })}
              error={!!errors.password}
            />
          </InputsContainer>

          <Box sx={{ mb: 4 }}>
            <StyledRouterLink to={ROUTES.RESTORE_PASSWORD}>
              Не помню пароль
            </StyledRouterLink>
          </Box>

          <CheckboxesContainer>
            <Box>
              <Checkbox defaultChecked />
              <Typography variant="regularText">
                Я согласен с{" "}
                <StyledMuiLink href={routerLinks.Terms.url}>
                  обработкой персональных данных
                </StyledMuiLink>
              </Typography>
            </Box>
            <Box>
              <Checkbox defaultChecked />
              <Typography variant="regularText">
                Я согласен с{" "}
                <StyledMuiLink href={routerLinks.UserAgreement.url}>
                  пользовательским соглашением
                </StyledMuiLink>
              </Typography>
            </Box>
          </CheckboxesContainer>

          <Button
            type="submit"
            variant="primary"
            sx={{ width: "100%", mb: "0.5rem" }}
          >
            {t(`Login:title`, { lng: "ru" })}
          </Button>

          <Box display="flex" justifyContent="center">
            <JetLink
              to={{ pathname: ROUTES.SIGN_UP_PAGE, search: location.search }}
              sx={{ color: theme.palette.info.main }}
            >
              {t(`Login:toRegister`, { lng: "ru" })}
            </JetLink>
          </Box>
        </form>
      </FormContainer>
    </FormWrapper>
  );
};
