import { styled } from "@mui/material";

export const Container = styled("table")(({ theme }) => ({
  borderSpacing: 0,
  width: "100%",
  marginBottom: "1.438rem",
  overflowY: "auto",
  "& thead": {
    background: theme.palette.custom.grey["menu-tile-inactive"],
  },
  "& thead th": {
    ...theme?.typography?.button,
    background: theme.palette.custom.grey["menu-tile-inactive"],
    cursor: "pointer",
    color: theme?.palette?.custom.grey.main,
    padding: `1.125rem ${theme?.spacing(4) || "1.125rem"}`,
    borderBottom: `0.063rem solid ${theme?.palette?.custom.grey["emenu-tile-active"]}`,
    "&:first-of-type": {
      borderTopLeftRadius: "0.5rem",
    },
    "&:last-of-type": {
      borderTopRightRadius: "0.5rem",
    },
  },
  "& tbody td": {
    padding: `0.563rem ${theme.spacing(4)}`,
    cursor: "pointer",
    borderBottom: `0.063rem solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
  },
}));
