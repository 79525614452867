import { useEffect } from "react";

import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";
import { DistributorReportType } from "@packages/store/services/Api";

import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";
import {
  DistributorForm,
  DistributorReports,
} from "pages/Distributor/components";

export const DistributorReportsBlock = observer((): JSX.Element => {
  const { distributorReport, api } = useStores();
  const { t } = useTranslation();

  const {
    fetch: fetchReports,
    loading: loadingReports,
    error: reportsError,
  } = useCancelableFetch();

  const reports = distributorReport.lessonReports.slice(0, 10);

  useEffect(() => {
    const { lesson } = DistributorReportType;
    fetchReports((token) =>
      api.getDistributorReportByFilter({ type: lesson }, token)
    );
  }, [api, fetchReports]);

  return (
    <PageBlock title={t(`Distributor:Report`)}>
      <Box sx={{ margin: "1.5rem" }} />
      <DistributorForm />

      <Loading loading={loadingReports} error={!!reportsError}>
        <DistributorReports reports={reports} />
      </Loading>
    </PageBlock>
  );
});
