import { useEffect } from "react";

import { pickBy, mapValues } from "lodash";
import { useForm, FieldValues, UseFormProps } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";

import {
  buildSearchParams,
  parseSearchParams,
} from "@packages/shared/utils/searchParams";

// TODO Переделать все фильтры таблиц на этот хук
export const useFormSyncedWithURLParams = <
  TFieldValues extends FieldValues,
  TContext = unknown
>(
  formProps: UseFormProps<TFieldValues, TContext>,
  pagePathname: string
) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const parsedParams = parseSearchParams(searchParams);

  const defaultValues = mapValues(
    formProps.defaultValues,
    (value: any, key) => parsedParams[key] ?? value
  ) as any;

  const methods = useForm<TFieldValues>({
    ...formProps,
    defaultValues,
  });

  const data = methods.watch();

  useEffect(() => {
    if (pagePathname !== pathname) {
      return;
    }

    const filteredParams = pickBy(data, (value) => {
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }

      return Boolean(value);
    });

    const params = buildSearchParams(filteredParams);

    if (params.toString() !== searchParams.toString()) {
      setSearchParams(params);
    }
  }, [data, pagePathname, pathname, searchParams, setSearchParams]);

  return methods;
};
