import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import uniq from "lodash/uniq";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store";
import {
  GetLearningGroups,
  GetLearningGroupsParams,
} from "@packages/store/services/Api";

import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";

import {
  filterByApprove,
  filterByClassGroups,
  filterByTeacher,
} from "../../helpers/filterGroups";
import {
  ModalPayrollGroup,
  ModalPayrollGroupFormValues,
} from "../ModalPayrollGroup/ModalPayrollGroup";
import { PayrollGroupsFilters } from "../PayrollGroupsFilters";
import { PayrollGroupsTable } from "../PayrollGroupsTable";

const GROUPS_LIMIT = 100;

export interface PayrollGroupsFilters {
  status: string[];
}

export const PayrollGroups = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { api, learningGroup, learningGroupApprove, surcharge } = useStores();

  const [page, setPage] = useState(1);
  const [allGroupsFetched, setAllGroupsFetched] = useState(false);
  const [paginationPage, setPaginationPage] = useState(0);

  const [isGroupModalOpen, setGroupModalOpen] = useState(false);
  const openGroupModal = () => setGroupModalOpen(true);
  const closeGroupModal = () => setGroupModalOpen(false);

  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const selectedGroup = selectedGroupId
    ? learningGroup.getById(selectedGroupId)
    : undefined;

  // const methods = useFormSyncedWithURLParams<PayrollGroupsFilters>(
  //   {
  //     mode: "onChange",
  //     defaultValues: { status: [] },
  //   },
  //   ROUTES.HOME
  // );

  const { fetch: fetchGroups, loading: loadingGroups } = useCancelableFetch();
  const { fetch: fetchGroupApproves, loading: loadingGroupApproves } =
    useCancelableFetch();
  const { fetch: fetchGroupSurcharges, loading: loadingGroupSurcharges } =
    useCancelableFetch();
  const { fetch: fetchTeacherRates, loading: loadingTeacherRates } =
    useCancelableFetch();
  const { fetch: fetchTeacherRates2, loading: loadingTeacherRates2 } =
    useCancelableFetch();
  const { fetch: fetchClassGroups, loading: loadingClassGroups } =
    useCancelableFetch();
  const { fetch: fetchLanguages, loading: loadingLanguages } =
    useCancelableFetch();
  const { fetch: approveGroup } = useCancelableFetch();
  const { fetch: createSurcharge } = useCancelableFetch();

  const loading =
    loadingGroups ||
    loadingGroupApproves ||
    loadingGroupSurcharges ||
    loadingTeacherRates ||
    loadingTeacherRates2 ||
    loadingClassGroups ||
    loadingLanguages;

  const filteredPayrollGroups = computed(() =>
    learningGroup.items
      .filter(filterByTeacher)
      .filter(filterByClassGroups)
      .filter((group) => filterByApprove(group, learningGroupApprove))
  ).get();

  const handleApprove = async (groupId: string) => {
    await approveGroup(() => api.approveLearningGroup(groupId));
  };

  const handleChangePage = (newPage: number) => {
    const maximumPages = Math.ceil(filteredPayrollGroups.length / 10);

    // Дозапрашиваем ещё группы если попали на последнюю страницу
    if (newPage === maximumPages - 1 && !allGroupsFetched) {
      setPage((p) => p + 1);
    }

    setPaginationPage(newPage);
  };

  const handleRequestGroupEdit = (groupId: string) => {
    setSelectedGroupId(groupId);
    openGroupModal();
  };

  const handleSubmit = async ({
    surcharge: newSurcharges,
  }: ModalPayrollGroupFormValues) => {
    if (!selectedGroupId) return;

    const currentGroupSurcharges = surcharge.getByGroupId(selectedGroupId);

    const surchargesToDelete = currentGroupSurcharges.filter(
      (s) => !newSurcharges.some((ns) => ns.id === s.id)
    );
    const surchargesToCreate = newSurcharges.filter((s) => !s.id);
    const surchargesToUpdate = newSurcharges.filter((s) =>
      currentGroupSurcharges.some((ns) => ns.id === s.id)
    );

    const surchargesToDeleteRequests = surchargesToDelete.map((s) =>
      surcharge.deleteById(s.id)
    );
    const surchargesToUpdateRequests = surchargesToUpdate.map(
      ({ id, ...other }) =>
        surcharge.updateById(id as string, {
          ...other,
          groupId: selectedGroupId,
        })
    );
    const surchargesToCreateRequests = surchargesToCreate.map((s) =>
      createSurcharge(() =>
        api.createSurcharge({ groupId: selectedGroupId, ...s })
      )
    );

    await Promise.all([
      ...surchargesToDeleteRequests,
      ...surchargesToUpdateRequests,
      ...surchargesToCreateRequests,
    ]);

    await handleApprove(selectedGroupId);

    setSelectedGroupId(null);
    closeGroupModal();
  };

  useEffect(() => {
    fetchLanguages((token) => api.getLanguages(token));
  }, [api, fetchLanguages]);

  useEffect(() => {
    const filters: GetLearningGroupsParams = {
      page,
      limit: GROUPS_LIMIT,
      status: "learning",
    };

    // eslint-disable-next-line consistent-return
    const fn = async () => {
      const groups: GetLearningGroups = await fetchGroups((token) =>
        api.getLearningGroups(filters, token)
      );

      if (groups && groups.kind === "ok") {
        if (groups.data.learningGroups.length === 0) {
          return setAllGroupsFetched(true);
        }

        const groupIds = groups.data.learningGroups.map((group) => group.id);
        const classGroupIds = groups.data.learningGroups
          .map((group: any) =>
            group.learningCourses.map((course: any) => course.classGroupIds)
          )
          .flat(2);

        const teacherIds = uniq(
          groups.data.learningGroups
            .map(
              (group) =>
                group.teachers.find((teacher) => teacher.excludedAt === null)
                  ?.actualId
            )
            .filter(Boolean) as string[]
        );

        await Promise.all([
          fetchGroupApproves((token) =>
            api.getLearningGroupApproves({ groupIds }, token)
          ),
          fetchGroupSurcharges((token) =>
            api.getLearningGroupSurcharges({ groupIds }, token)
          ),
          fetchClassGroups((token) =>
            api.getClassGroups({ groupIds: classGroupIds }, token)
          ),
          fetchTeacherRates((token) =>
            api.getTeacherRates(
              {
                teacherId: teacherIds,
                classType: "webinar",
                limit: teacherIds.length * 2,
              },
              token
            )
          ),
          fetchTeacherRates2((token) =>
            api.getTeacherRates(
              {
                teacherId: teacherIds,
                classType: "face2face",
                limit: teacherIds.length * 2,
              },
              token
            )
          ),
        ]);
      }
    };

    fn();
  }, [
    api,
    page,
    fetchGroupApproves,
    fetchGroupSurcharges,
    fetchGroups,
    fetchTeacherRates,
    fetchTeacherRates2,
    fetchClassGroups,
  ]);

  // Это пока не надо
  // useEffect(() => {
  //   if (filteredPayrollGroups.length < GROUPS_LIMIT && !allGroupsFetched) {
  //     console.log("here", filteredPayrollGroups.length, allGroupsFetched);

  //     setPage((p) => p + 1);
  //   }
  // }, [filteredPayrollGroups.length, allGroupsFetched]);

  return (
    <PageBlock title={t("Payroll:Groups")}>
      <Loading loading={loading}>
        <Box sx={{ margin: "1.5rem" }} />
        {/* <PayrollGroupsFilters methods={methods} statuses={statuses} /> */}
        <PayrollGroupsTable
          groups={filteredPayrollGroups}
          onApprove={handleApprove}
          page={paginationPage}
          onChangePage={handleChangePage}
          onRequestGroupEdit={handleRequestGroupEdit}
        />
        <ModalPayrollGroup
          open={isGroupModalOpen}
          onClose={closeGroupModal}
          onSubmit={handleSubmit}
          group={selectedGroup}
        />
      </Loading>
    </PageBlock>
  );
});
