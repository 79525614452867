import {
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { Icon, IconType } from "../Icon";
import { Text } from "../Text";
import { useDropdown } from "../../hooks";

import styles from "./Select.module.scss";
import { SelectItem, SelectItemData } from "./SelectItem";

type SelectProps = {
  containerClassName?: string;
  placeholder?: string;
  items?: SelectItemData[];
  value?: string;
  onChange?: (_: string | undefined) => void;
  variant?: "primary" | "secondary";
  fullWidth?: boolean;
  error?: string;
} & Omit<SelectHTMLAttributes<string>, "onChange">;

export const Select = observer((props: SelectProps) => {
  const {
    containerClassName,
    placeholder,
    items = [],
    value,
    onChange,
    variant = "primary",
    fullWidth = false,
    error,
  } = props;

  const {
    containerRef: dropdownContainerRef,
    isOpen: isDropdownOpen,
    toggle: toggleDropdown,
    close: closeDropdown,
    styles: dropdownStyles,
  } = useDropdown<HTMLDivElement>();

  const [currentValue, setCurrentValue] = useState(value);

  const selectedItem = items?.find(({ value }) => value === currentValue);
  const selectedText = selectedItem?.label ?? placeholder;

  const hasError = !!error;

  const containerClasses = cn(styles.container, containerClassName);

  const selectedTextClasses = cn(styles["selected-text"], {
    [styles["selected-text-placeholder"]]: !selectedItem,
    [styles["selected-text-error"]]: hasError,
  });

  const selectIcon: IconType = isDropdownOpen ? "select-up" : "select-down";

  const handleItemClick = useCallback(
    (value: string | undefined) => {
      closeDropdown();
      setCurrentValue(value);
      onChange?.(value);
    },
    [closeDropdown, setCurrentValue, onChange]
  );

  useEffect(() => {
    setCurrentValue(value);
  }, [value, setCurrentValue]);

  const List = useMemo(() => {
    return items.map((item) => (
      <SelectItem
        key={item.value ?? ""}
        item={item}
        selected={item.value === currentValue}
        onClick={handleItemClick}
        variant={variant}
      />
    ));
  }, [items, currentValue, handleItemClick, variant]);

  return (
    <div ref={dropdownContainerRef} className={containerClasses}>
      <div
        className={`${styles[`select-${variant}`]} ${
          fullWidth ? styles["full-width"] : ""
        }`}
        onClick={toggleDropdown}
      >
        <Text containerClassName={selectedTextClasses} variant="text-3-medium">
          {selectedText}
        </Text>
        <Icon icon={selectIcon} />
      </div>
      {isDropdownOpen && (
        <div className={styles[`item-list-${variant}`]} style={dropdownStyles}>
          {List}
        </div>
      )}

      {hasError && (
        <div className={styles["error-wrapper"]}>
          <Icon icon="input-error" />
          <Text
            containerClassName={styles["error-text"]}
            variant="text-3-medium"
            color="accsent-wd"
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  );
});
