import { styled } from "@mui/material";

export const MarkContainer = styled("div")(({ theme }) => ({
  borderRight: "1px solid #E0E0E0",
  [theme.breakpoints.down("laptop")]: {
    border: "none",
  },
  "&::before": {
    content: '""',
    display: "block",
    height: "0.438rem",
    width: "0.438rem",
    position: "relative",
    top: "2.188rem",
    left: "0.25rem",
    background: "silver",
    borderRadius: "50%",
    [theme.breakpoints.down("laptop")]: {
      top: "0.7rem",
      left: "0.625rem",
      width: "0.25rem",
      height: "0.25rem",
    },
  },
}));
