/* eslint-disable func-names */
import { useCallback, useState } from "react";

export const usePopover = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = useCallback((event?: any) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    open,
    anchorEl,
    handleOpen,
    handleClose,
  };
};
