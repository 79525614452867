import { styled, Box } from "@mui/material";

import { StatusType } from "../../../types";

interface IconWrapperProps {
  status: StatusType;
}

export const IconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<IconWrapperProps>(({ theme, status }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(4),
  color: theme.palette.custom.colorVariant[status],

  "& svg": {
    color: "inherit",

    "& path": {
      stroke: "inherit",
    },

    "& line": {
      stroke: "inherit",
    },
  },
}));
