import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { ClassScheduleModel, ClassScheduleSnapshot } from "./ClassSchedule";

/**
 * Model description here for TypeScript hints.
 */
export const ClassScheduleStoreModel = types
  .model("ClassScheduleStore")
  .props({
    items: types.optional(types.array(ClassScheduleModel), []),
  })
  .views((self) => ({})) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((self) => ({
    addItem: (item: ClassScheduleSnapshot) => {
      if (resolveIdentifier(ClassScheduleModel, self, item.id)) return;
      self.items.push(item);
    },
  }));
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ClassScheduleStoreType = Instance<typeof ClassScheduleStoreModel>;
export type ClassScheduleStore = ClassScheduleStoreType;
type ClassScheduleStoreSnapshotType = SnapshotOut<
  typeof ClassScheduleStoreModel
>;
export type ClassScheduleStoreSnapshot = ClassScheduleStoreSnapshotType;
