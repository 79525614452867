import {
  Avatar as MaterialUIAvatar,
  AvatarProps as MaterialAvatarProps,
  Typography,
} from "@mui/material";

import { Icon } from "components/Icon/Icon";

export interface AvatarProps extends MaterialAvatarProps {
  isOutline?: boolean;
  width?: string | number;
  height?: string | number;
  countUsers?: number;
}

export function Avatar({
  src,
  alt,
  isOutline = false,
  width = 16,
  height = 16,
  countUsers,
}: AvatarProps): JSX.Element {
  const className = isOutline ? "outline" : "";

  return src ? (
    <MaterialUIAvatar
      src={src}
      alt={alt}
      className={className}
      sx={{ width, height }}
    />
  ) : (
    <MaterialUIAvatar
      src={src}
      alt={alt}
      className={className}
      sx={{ width, height }}
    >
      {countUsers ? (
        <Typography variant="h4">+ {countUsers}</Typography>
      ) : (
        <Icon type="user" width={16} height={15} />
      )}
    </MaterialUIAvatar>
  );
}
