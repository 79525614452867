import { Box, Typography } from "@mui/material";
import { Notification as INotification } from "@packages/store/models/Notifications/NotificationModel";
import { NotificationsStore } from "@packages/store/models/Notifications/NotificationsStore";
import { Icon } from "components/Icon";
import { Loading } from "components/Loading";
import { useTranslation } from "react-i18next";

import { NotificationsHeader } from "./components/NotificationsHeader";
import { NotificationsList } from "./components/NotificationsList";
import { NotificationsBody } from "./styledComponents/NotificationsBody";
import { NotificationsPopoverContainer } from "./styledComponents/NotificationsPopoverContainer";

interface NotificationsPopoverProps {
  anchorEl: Element | null;
  onClose: () => void;
  notifications: NotificationsStore;
}

export function NotificationsPopover({
  anchorEl,
  onClose,
  notifications,
}: NotificationsPopoverProps): JSX.Element | null {
  const { t } = useTranslation();

  const allNotifications: INotification[] | undefined =
    notifications?.groups?.reduce(
      (acc, cur) => acc.concat(cur.notifications!),
      [] as INotification[]
    );

  const notReadNotifications =
    allNotifications?.filter(({ read }) => !read) || [];
  const readNotifications = allNotifications?.filter(({ read }) => read) || [];

  const isNothingFound = notifications?.groups?.length;

  return (
    <NotificationsPopoverContainer
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <NotificationsHeader
        title={t("Notifications:Title")}
        unreadCount={notifications.unreadCount!}
        onClose={onClose}
      />
      <NotificationsBody>
        <Loading loading={notifications.loading}>
          <>
            {notReadNotifications && (
              <NotificationsList
                notifications={notReadNotifications}
                intersecting
              />
            )}

            {readNotifications?.length !== 0 && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  gap="0.5rem"
                  paddingTop={notReadNotifications.length === 0 ? 4 : 0}
                >
                  <Typography variant="h3" sx={{ paddingLeft: 4 }}>
                    {t("Notifications:HaveRead")}
                  </Typography>
                  <Icon
                    type="chevronDown"
                    width={16}
                    height={16}
                    stroke="currentColor"
                  />
                </Box>

                <NotificationsList notifications={readNotifications} />
              </>
            )}
          </>
        </Loading>

        {!isNothingFound && (
          <Box textAlign="center" p={4}>
            {t("Notifications:StandardHelper")}
          </Box>
        )}
      </NotificationsBody>
    </NotificationsPopoverContainer>
  );
}
