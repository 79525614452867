import { ExerciseUISubtype } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";

import {
  SubExerciseComponentProps,
  ExerciseComponentProps,
} from "../../../../types";

import * as subExercises from "./subExercises";

const subExerciseMap: {
  [key in ExerciseUISubtype]?: (
    props: SubExerciseComponentProps
  ) => JSX.Element | null;
} = {
  ...subExercises,
};

export const Confirmation = observer(
  ({ exercise }: ExerciseComponentProps): JSX.Element | null => {
    const { uiSubtype } = exercise;

    const SubExerciseComponent = subExerciseMap[uiSubtype];

    if (!SubExerciseComponent) {
      return <></>;
    }

    return <SubExerciseComponent exercise={exercise} />;
  }
);
