import React, { ReactNode } from 'react';

import { observer } from 'mobx-react';

import styles from './WordSpace.module.scss';

export const WordSpace = observer(() => {
  return (
    <>&nbsp;</>
  );
})
