import { IconProps } from "../Icon";

export interface RouterLinkProps {
  [key: string]: {
    url: string;
    icon: IconProps["type"];
  };
}

export const FOOTER_LINKS: RouterLinkProps = {
  SignUpClub: {
    url: "https://airtable.com/shrvkruG6a8SU5kmn",
    icon: "null",
  },
  SignUpClasses: {
    url: "https://airtable.com/shrvkruG6a8SU5kmn",
    icon: "null",
  },
  PricticeOnAi: {
    url: "https://online.dschool.ru/dialogs/ ",
    icon: "null",
  },
  About: {
    url: "https://progress.jetclass.ru/ ",
    icon: "null",
  },
  UserAgreement: {
    url: "https://progress.jetclass.ru/polzovatelskoe_soglashenie",
    icon: "null",
  },
  VKontakte: {
    url: "https://vk.com/denisschool_eng",
    icon: "vk",
  },
  Facebook: {
    url: "https://www.facebook.com/denisschool.moscow",
    icon: "facebook",
  },
  Instagram: {
    url: "https://www.instagram.com/denisschool/",
    icon: "instagram",
  },
  Terms: {
    url: "https://progress.jetclass.ru/page22850738.html",
    icon: "null",
  },
  Privacy: {
    url: "https://progress.jetclass.ru/page22850876.html",
    icon: "null",
  },
};
