import { styled } from "@mui/material";

export const FooterContainer = styled("footer")(({ theme }) => ({
  padding: theme.spacing(6, 0, 6, 0),
  [theme.breakpoints.down("laptop")]: {
    borderTop: `0.063rem solid ${theme.palette.custom.grey["emenu-tile-active"]}`,
    padding: "2.5rem 0 2.313rem 0",
  },
  [theme.breakpoints.down("laptop")]: {
    border: "none",
  },
}));
