import { styled } from "@mui/material";

export const BlockContainer = styled("div")(({ theme }) => ({
  margin: "2.5rem 0",
  "&:first-of-type": {
    borderBottom: "1px solid #E0E0E0",
    [theme.breakpoints.down("laptop")]: {
      border: "none",
    },
    [theme.breakpoints.between("tablet", "desktop")]: {
      margin: "2rem 0",
    },
    [theme.breakpoints.up("zeroScreen")]: {
      margin: "1.5rem 0",
    },
  },
}));
