import { useTranslation } from "react-i18next";
import { utils, writeFile } from "xlsx-js-style";

import { DownloadButton } from "./styledComponents";
import { formatGroups } from "../../helpers";

export interface GroupDownloadProps {
  data: ReturnType<typeof formatGroups>;
}

export const GroupDownload = ({
  data: entryData,
}: GroupDownloadProps): JSX.Element => {
  const { t } = useTranslation();

  const data = entryData.map((item) => ({
    [t("GroupList:group")]: item.group,
    [t("GroupList:teachers")]: item.teachers
      .map((teacher) => teacher.fullName)
      .join(", "),
    [t("GroupList:period")]: item.dates,
    [t("GroupList:lessonsCount")]: item.lessonsCount,
    [t("GroupList:total")]: item.total,
    [t("GroupList:finished")]: item.finished,
    [t("GroupList:left")]: item.left,
  }));

  const download = () => {
    const sheet = utils.json_to_sheet(data);
    const book = utils.book_new();
    utils.book_append_sheet(book, sheet, t("GroupDownload:Groups") ?? "");
    writeFile(book, `${t("GroupDownload:Groups")}.xls`);
  };

  return (
    <DownloadButton onClick={download}>
      {t("GroupDownload:Download")}
    </DownloadButton>
  );
};
