import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MobileHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.up("mobile")]: {
    display: "none",
  },
}));
