import { styled, Box } from "@mui/material";

export const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 40,
  height: 40,
  marginRight: theme.spacing(4),
  backgroundColor: theme.palette.info.light,
  borderRadius: theme.spacing(3),

  [theme.breakpoints.down("zeroScreen")]: {
    marginRight: theme.spacing(3),
  },
}));
