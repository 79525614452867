import { routerLinks } from "constants/constants";

import { Box, Typography, useTheme } from "@mui/material";
import { useStores } from "@packages/store/models";
import {
  RegisterProps,
  isRegisterValue,
} from "@packages/store/models/AuthStore";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { JetLink } from "components/JetLink";
import { Logo } from "components/Logo";
import { PasswordStrength } from "components/PasswordStrength";
import { TrimInput } from "components/TrimInput";
import {
  FormWrapper,
  FormContainer,
  FormTitle,
  FormSubTitle,
  InputsContainer,
  CheckboxesContainer,
  StyledMuiLink,
  LogoWrapper,
} from "pages/Login/styledComponents";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { useLocation } from "react-router-dom";
import { ROUTES } from "router/constants";

type RegisterFormProps = RegisterProps & { passwordConfirmation: string };

export const RegisterForm = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const theme = useTheme();

  const { auth } = useStores();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm<RegisterFormProps>({
    defaultValues: { phone: "", password: "" },
  });

  const onSubmit = handleSubmit((args) => {
    if (args.password !== args.passwordConfirmation) {
      return setError("passwordConfirmation", {
        type: "custom",
        message: "Passwords doesn't match",
      });
    }

    return auth.register(args).then((message) => {
      if (message?.error && typeof message?.error === "object") {
        Object.entries(message.error).forEach(([key, value]) => {
          if (
            isRegisterValue(key) &&
            Array.isArray(value) &&
            typeof value[0] === "string"
          ) {
            setError(key, { type: "server", message: value[0] });
          }
        });
      }
    });
  });

  const validateEmail = (value: string | undefined) => {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (value && !validRegex.test(value)) {
      return t("Register:invalidEmailError");
    }

    return true;
  };

  const validateName = (value: string | undefined) => {
    if (value && value.length < 3) {
      return t("Register:tooShortError");
    }

    return true;
  };

  const validatePhone = (value: string | undefined) => {
    const validRegex =
      // eslint-disable-next-line no-useless-escape
      /^[+]?(\d\-|\d\s|\d|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g;

    if (value && !validRegex.test(value)) {
      return t("Register:invalidPhoneError");
    }

    return true;
  };

  return (
    <FormWrapper>
      <FormContainer>
        <LogoWrapper>
          <Logo width={128} height={32} />
        </LogoWrapper>

        <form onSubmit={onSubmit}>
          <FormTitle>
            Welcome to <span>JetClass</span>!
          </FormTitle>

          <FormSubTitle>{t(`Register:subTitle`, { lng: "ru" })}</FormSubTitle>

          <InputsContainer>
            <TrimInput
              variant="standard"
              label={t(`Register:firstName`, { lng: "ru" })}
              {...register("firstName", {
                required: t("Register:requiredFieldError") ?? "",
                validate: validateName,
              })}
              error={!!errors.firstName}
              helperText={errors.firstName?.message || " "}
            />
            <TrimInput
              variant="standard"
              label={t(`Register:lastName`, { lng: "ru" })}
              {...register("lastName", {
                required: t("Register:requiredFieldError") ?? "",
                validate: validateName,
              })}
              error={!!errors.lastName}
              helperText={errors.lastName?.message || " "}
            />
            <TrimInput
              variant="standard"
              label={t(`Register:email`, { lng: "ru" })}
              {...register("email", {
                required: t("Register:requiredFieldError") ?? "",
                validate: validateEmail,
              })}
              error={!!errors.email}
              helperText={errors.email?.message || " "}
            />
            <Controller
              control={control}
              name="phone"
              rules={{
                required: t("Register:requiredFieldError") ?? "",
                validate: validatePhone,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InputMask
                  mask="+9-999-999-9999"
                  maskPlaceholder={null}
                  value={value}
                  onChange={onChange}
                >
                  <TrimInput
                    type="tel"
                    variant="standard"
                    label={t(`Register:phone`, { lng: "ru" })}
                    error={!!error}
                    helperText={error?.message || " "}
                  />
                </InputMask>
              )}
            />
            <Controller
              control={control}
              name="password"
              rules={{ required: t("Register:requiredFieldError") ?? "" }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Box position="relative">
                  <TrimInput
                    type="password"
                    variant="standard"
                    label={t(`Register:password`, { lng: "ru" })}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error?.message || " "}
                    sx={{ width: "100%" }}
                  />
                  <PasswordStrength password={value} prefferedLanguage="ru" />
                </Box>
              )}
            />
            <TrimInput
              type="password"
              variant="standard"
              label={t(`Register:passwordConfirmation`, { lng: "ru" })}
              {...register("passwordConfirmation", {
                required: t("Register:requiredFieldError") ?? "",
              })}
              error={!!errors.passwordConfirmation}
              helperText={errors.passwordConfirmation?.message || " "}
            />
          </InputsContainer>

          <CheckboxesContainer>
            <Box>
              <Checkbox defaultChecked />
              <Typography variant="regularText">
                Я согласен с{" "}
                <StyledMuiLink href={routerLinks.Terms.url}>
                  обработкой персональных данных
                </StyledMuiLink>
              </Typography>
            </Box>
            <Box>
              <Checkbox defaultChecked />
              <Typography variant="regularText">
                Я согласен с{" "}
                <StyledMuiLink href={routerLinks.UserAgreement.url}>
                  пользовательским соглашением
                </StyledMuiLink>
              </Typography>
            </Box>
          </CheckboxesContainer>

          <Button
            type="submit"
            variant="primary"
            sx={{ width: "100%", mb: "0.5rem" }}
          >
            {t(`Register:title`, { lng: "ru" })}
          </Button>

          <Box display="flex" justifyContent="center">
            <JetLink
              to={{ pathname: ROUTES.SIGN_IN_PAGE, search: location.search }}
              sx={{ color: theme.palette.info.main }}
            >
              {t(`Register:toLogin`, { lng: "ru" })}
            </JetLink>
          </Box>
        </form>
      </FormContainer>
    </FormWrapper>
  );
};
