export type StatusType = "default" | "passed" | "wrong" | "current";

export enum AnswerState {
  HAS_ERRORS = "hasErrors",
  VERIFIED = "verified",
}

export enum QuestionState {
  HAS_ERRORS = "hasErrors",
  VERIFIED = "verified",
  PASSED = "passed",
  STARTED = "started",
  NOT_STARTED = "notStarted",
}

export type ColorVariantType = {
  [key in StatusType]: string;
};

export interface ValueWithLabel {
  value: string;
  label: string;
}

export interface MenuItem {
  label: string;
  icon: React.ReactElement;
  path: string;
}

export enum UserRole {
  STUDENT = "student",
  TEACHER = "teacher",
  DISTRIBUTOR = "distributor",
  COORDINATOR = "coordinator",
  HR = "hr",
  PAYROLL_ACCOUNTANT = "payroll_accountant",
}

export class StoreMismatchError extends Error {}
