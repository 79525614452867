import { alpha, Popover } from "@mui/material";
import { styled } from "@mui/material/styles";

export const UserMenuPopoverContainer = styled(Popover)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "19rem",
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(3, 3, 4, 3),
    border: `0.0625rem solid ${theme.palette.custom?.grey?.["emenu-tile-active"]}`,
    boxShadow: `0.125rem 0.1875rem 0.1875rem ${alpha(
      theme.palette.custom?.primary?.black as string,
      0.08
    )}`,
    borderRadius: "0.5rem",
    top: "58px!important",
    marginLeft: "2.4rem",
    "& .label": {
      color: theme.palette.primary.dark,
      marginTop: theme.spacing(4),
      cursor: "pointer",
      marginLeft: "2.5rem",
    },
    [theme.breakpoints.down("tablet")]: {
      width: "100%",
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
    },
  },
}));
