/* eslint-disable import/no-extraneous-dependencies */
import { PropsWithChildren } from "react";

import { Box, CircularProgress, SxProps, Theme } from "@mui/material";

import { LoaderWrapper } from "./styledComponents/LoaderWrapper";

export interface LoadingProps extends PropsWithChildren {
  loading: boolean;
  cover?: boolean;
  wrapperStyles?: SxProps<Theme>;
}

export const Loading = ({
  loading,
  children,
  cover,
  wrapperStyles,
}: LoadingProps): JSX.Element | null => {
  if (cover) {
    return (
      <Box sx={{ position: "relative" }}>
        {loading && (
          <LoaderWrapper cover sx={wrapperStyles}>
            <CircularProgress />
          </LoaderWrapper>
        )}

        {children}
      </Box>
    );
  }

  return (
    <>
      {loading ? (
        <LoaderWrapper sx={wrapperStyles}>
          <CircularProgress />
        </LoaderWrapper>
      ) : (
        children
      )}
    </>
  );
};
