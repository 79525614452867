import { useMemo } from "react";

import { Box } from "@mui/material";
import { VideoTrack } from "@packages/store/models/AdditionalContent/VideoTrack";
import {
  VideoPlayerSources,
  VideoPlayerType,
  VideoPlayerOptions,
  VideoPlayer,
} from "../../components/VideoPlayer";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ToggleButton } from "../SharedStyledComponents";

interface VideoContentProps {
  videoTracks: VideoTrack[];
  hasScript: boolean;
  scriptHidden: boolean;
  onScriptHiddenToggle: () => void;
  onPlayerReady: (player: VideoPlayerType) => void;
}

export const VideoContent = observer(
  ({
    videoTracks,
    hasScript,
    scriptHidden,
    onScriptHiddenToggle,
    onPlayerReady,
  }: VideoContentProps): JSX.Element | null => {
    const { t } = useTranslation();

    const videoPlayerOptions = useMemo(() => {
      const sources: VideoPlayerSources = videoTracks.map((track) => ({
        src: String(track.url),
        type: String(track.format),
        res: parseInt(String(track.name || ""), 10),
        label: String(track.name),
      }));

      return {
        sources,
      } as VideoPlayerOptions;
    }, [videoTracks]);

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <VideoPlayer options={videoPlayerOptions} onReady={onPlayerReady} />

        {hasScript && (
          <ToggleButton active={!scriptHidden} onClick={onScriptHiddenToggle}>
            {scriptHidden ? t("Exercise:ShowScript") : t("Exercise:HideScript")}
          </ToggleButton>
        )}
      </Box>
    );
  }
);
