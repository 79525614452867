import Box from "@mui/material/Box";

import { AvatarPerson } from "components/AvatarPerson";
import { Button } from "components/Button";
import { HeadUserMenu } from "components/HeadUserMenu/HeadUserMenu";
import { Icon } from "components/Icon/Icon";
import { IUserMenuItem, UserMenuData } from "components/UserMenu/UserMenuData";

import { Line } from "./styledComponents/Line";
import { UserMenuPopoverContainer } from "./styledComponents/UserMenuPopoverContainer";

export interface UserMenuPopoverProps {
  anchorEl: HTMLDivElement | null;
  handleClose: () => void;
  name: string;
  userRole: string;
  menuItems: IUserMenuItem[];
}

export const UserMenuPopover = ({
  anchorEl,
  handleClose,
  userRole,
  name,
  menuItems,
}: UserMenuPopoverProps): JSX.Element => {
  return (
    <UserMenuPopoverContainer
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
          }}
        >
          <AvatarPerson name={name} fontSize="h5" width="2rem" height="2rem" />
          <HeadUserMenu
            userRole={userRole}
            name={name}
            handleClose={handleClose}
          />
        </Box>
        <Button variant="iconBtn" onClick={handleClose}>
          <Icon type="close" width={24} height={24} />
        </Button>
      </Box>
      <Line />
      <Box
        sx={{
          paddingLeft: "40px",
        }}
        onClick={handleClose}
      >
        <UserMenuData menuItems={menuItems} />
      </Box>
    </UserMenuPopoverContainer>
  );
};
