import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { getCurrentLocale } from "../../locales/dateFnsLocale";
import { Icon } from "../Icon";
import { JetLink } from "../JetLink";

export interface UsefulLinkProps {
  date?: Date | null;
  title: string;
  subTitle: string;
  url: string;
  iconColor: string;
  newTab: boolean;
  external: boolean;
}
export const UsefulLink = ({
  url,
  newTab,
  external,
  date,
  title,
  subTitle,
  iconColor,
}: UsefulLinkProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <JetLink underline="none" to={url} newTab={newTab} external={external}>
      <Box
        sx={{
          width: "15.625rem",
          height: "9.5rem",
          padding: "14px",
          marginRight: "10px",
          paddingTop: "10px",
          marginBottom: "10px",
          borderRadius: 2,
          boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.11)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Icon stroke={iconColor} type="link" width={36} height={36} />
          </Box>
          <Box>
            {date && (
              <Typography variant="smallText" color="custom.grey.main">
                {t(`UsefulLinks:added`)}{" "}
                {format(date, "dd MMMM yyyy", {
                  locale: getCurrentLocale(),
                })}
              </Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            height: "1.8rem",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-end",
          }}
        >
          <Typography mb={2} variant="h3" color="custom.grey.main">
            {title}
          </Typography>
          <Typography variant="smallText" color="custom.grey.main">
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </JetLink>
  );
};
