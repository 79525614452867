import { useRef, useEffect } from "react";

import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

import { useDragDrop } from "../../hooks/useDragDrop";
import { useTranslateMode } from "../../hooks/useTranslateMode";
import { DragItemType, StatusType } from "../../types";
import { AnswerView } from "../AnswerView";

export interface DragItem {
  id: string;
  title: string;
  question: string;
}

interface DraggableAnswerProps {
  id: string;
  title: string;
  question: string;
  status: StatusType;
  isChecked?: boolean;
  fullSize?: boolean;
  fullWidth?: boolean;
  count?: number;
  disabled?: boolean;
  onDrop?: (
    item: DragItem,
    monitor: DragSourceMonitor<DragItem, unknown>
  ) => void;
  onHover?: (
    item: DragItem,
    monitor: DropTargetMonitor<DragItem, unknown>,
    {
      element,
      disabled,
    }: { element: HTMLSpanElement | null; disabled?: boolean }
  ) => void;
}

export function DraggableAnswer({
  id,
  title,
  status,
  isChecked,
  question,
  fullSize,
  fullWidth,
  count,
  disabled,
  onDrop,
  onHover,
}: DraggableAnswerProps): JSX.Element | null {
  const dragRef = useRef<HTMLSpanElement | null>(null);

  const { setDragSize } = useDragDrop();
  const { translateMode } = useTranslateMode();

  const canDrag = !translateMode && !isChecked;

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragItemType.ANSWER,
      item: (): DragItem => ({
        id,
        title,
        question,
      }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: onDrop,
      canDrag,
    }),
    [id, title, question, canDrag, onDrop]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DragItemType.ANSWER,
      hover: (item: DragItem, monitor) =>
        onHover?.(item, monitor, { element: dragRef.current, disabled }),
    }),
    [onHover, disabled, dragRef]
  );

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dragEl = dragRef.current;
    if (isDragging && dragEl) {
      const { width, height } = dragEl.getBoundingClientRect();
      setDragSize({ width, height });
    }
  }, [isDragging, dragRef, setDragSize]);

  if (onHover) {
    drop(drag(dragRef));
  } else {
    drag(dragRef);
  }

  return (
    <AnswerView
      ref={dragRef}
      title={title}
      status={status}
      draggable={canDrag}
      dragging={isDragging}
      fullWidth={fullWidth}
      fullSize={fullSize}
      count={count}
      disabled={disabled}
    />
  );
}
