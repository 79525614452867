import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const StudentModel = types.model("Student", {
  id: types.identifier,
  studentId: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  greetedAt: types.maybeNull(types.Date),
  includedAt: types.maybeNull(types.Date),
  excludedAt: types.maybeNull(types.Date),
});

type StudentType = Instance<typeof StudentModel>;
export type Student = StudentType;
type StudentTypeSnapshotType = SnapshotOut<typeof StudentModel>;
export type StudentSnapshot = StudentTypeSnapshotType;
