type QuestionContent = Array<{
  text: string;
  image?: string;
  element: JSX.Element | null;
}>;

export const createQuestionContent = (
  text: string,
  limit: number,
  getPlace: (index: number) => number,
  renderElement: (index: number) => JSX.Element | null,
  image?: string
): QuestionContent => {
  const createContent = (
    content: QuestionContent = [],
    sliceIndex = 0,
    index = 0
  ): QuestionContent => {
    if (index === limit) {
      content.push({
        text: text.slice(sliceIndex).trim(),
        element: null,
      });
      return content;
    }

    const place = getPlace(index);

    content.push({
      text: text.slice(sliceIndex, place).trim(),
      image,
      element: renderElement(index),
    });

    return createContent(content, place, index + 1);
  };

  return createContent();
};
