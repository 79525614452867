import { styled, Box, BoxProps } from "@mui/material";

export interface ModalContainerProps extends BoxProps {
  type?: "extraSmall" | "small" | "default" | "large";
}

const size = {
  extraSmall: "34rem",
  small: "48rem",
  default: "67rem",
  large: "90rem",
};

export const ModalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "type",
})<ModalContainerProps>(({ theme, type = "default" }) => ({
  background: theme.palette.primary.contrastText,
  width: size[type],
  padding: `${theme.spacing(5)} 2.5rem ${theme.spacing(6)}`,
  borderRadius: "0.5rem",
  maxWidth: "100%",
  outline: "none",
}));
