import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { getPivotStudentAttendance } from "./getPivotStudentAttendance";

export const getPivotStudentLessonHours = (
  studentIds: number[],
  lesson: Lesson
) => {
  if (lesson.status === "notPerformedToPay") {
    return "ⓧ";
  }

  const isStudentsIncluded = !!lesson.students?.find((student) => {
    return studentIds.includes(student.id ?? 0);
  });
  if (!isStudentsIncluded) {
    return "X";
  }

  const attendance = getPivotStudentAttendance(studentIds, lesson);
  if (!attendance) {
    return "";
  }
  const { attended } = attendance;
  if (!attended && lesson?.status !== "lateCanceledByCustomer") {
    return "";
  }

  const { compensation } = lesson;
  if (compensation) {
    if (!attended) {
      return "";
    }
    return "0.00";
  }
  return lesson.exactDuration.toFixed(2);
};
