import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { AnswerDragLayer } from "../../../../../../components/AnswerDragLayer";
import { OrderQuestion } from "../../../../../../components/OrderQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Inline = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, getQuestionRaw, getCorrectAnswer } = exercise;

    const { getQuestion } = ensure(answer);

    return (
      <>
        <AnswerDragLayer />
        <ObserverFlexList
          gap={4}
          items={questions}
          renderItem={(question: Question, index) => (
            <OrderQuestion
              key={question.id}
              order={index + 1}
              variant="inline"
              question={question}
              answerData={ensure(getQuestion(question.id))}
              correctAnswer={getCorrectAnswer(question.id)}
              correctAnswerParts={getQuestionRaw(question.id)?.answerParts}
            />
          )}
        />
      </>
    );
  }
);
