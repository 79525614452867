import { ROUTES } from "router/constants";

export const getExerciseUrl = (
  courseId: string | number,
  unitId: string | number,
  blockId: string | number,
  exerciseId: string | number
) =>
  `${ROUTES.MY_COURSES}/${courseId}${ROUTES.EXERCISES}/${unitId}?blockId=${blockId}&exerciseId=${exerciseId}`;

export const getUnitUrl = (
  courseId: string | number,
  unitId: string | number
) => `${ROUTES.MY_COURSES}/${courseId}${ROUTES.EXERCISES}/${unitId}`;

export const getCourseUrl = (courseId: string | number) =>
  `${ROUTES.MY_COURSES}/${courseId}`;
