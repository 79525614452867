import React, { useContext } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";

import styles from './Statistics.module.scss';
import { Text } from '../../Text';
import { Icon } from '../../Icon';

import { TaskContext } from '../utils/context'; 
import { getComments, getGrammarErrors, getLexicalErrors } from '../utils/helpers';

export const Statistics = observer(() => {

  const { t } = useTranslation();

	const { errors } = useContext(TaskContext);

  const lexicalErrors = getLexicalErrors(errors ?? []);
  const grammarErrors = getGrammarErrors(errors ?? []);
  const comments = getComments(errors ?? []);

  return (
    <>
      <div className={styles["item"]}>
        <div className={styles["item-icon"]}>
          <Icon icon="vocubalury" />
        </div>
        <Text variant="text-2-regular" color="base-d">
          {t("CreativeTasks:Lexical")}
        </Text>
        <Text variant="text-2-regular" color="accsent-wd">
          {lexicalErrors.length}
        </Text>
      </div>

      <div className={styles["item"]}>
        <div className={styles["item-icon"]}>
          <Icon icon="grammar" />
        </div>
        <Text variant="text-2-regular" color="base-d">
          {t("CreativeTasks:Grammar")}
        </Text>
        <Text variant="text-2-regular" color="accsent-wd">
          {grammarErrors.length}
        </Text>
      </div>

      <div className={styles["item"]}>
        <div className={styles["item-icon"]}>
          <Icon icon="comments" />
        </div>
        <Text variant="text-2-regular" color="base-d">
          {t("CreativeTasks:Comment")}
        </Text>
        <Text variant="text-2-regular" color="accsent-1s">
          {comments?.length}
        </Text>
      </div>
    </>
  );
})
