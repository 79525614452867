import { useCallback, useEffect, useRef, useState } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { ScriptItem } from "@packages/store/models/AdditionalContent/ScriptItem";
import { ObserverFlexList } from "../../components/FlexList";
import {
  Scrollbar,
  ScrollbarComponent,
} from "../../components/ScrollbarComponent";
import { observer } from "mobx-react-lite";
import { maxScrollHeight, tablet } from "../../constants/styles";
import { useTranslation } from "react-i18next";

import { StyledScriptItem } from "./styledComponents/StyledScriptItem";

interface ScriptViewProps {
  currentScriptItem: string;
  script: ScriptItem[];
  onScriptItemChange: (scriptItem: ScriptItem) => void;
}

export const ScriptView = observer(
  ({
    currentScriptItem,
    script,
    onScriptItemChange,
  }: ScriptViewProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const [currentEl, setCurrentEl] = useState<HTMLSpanElement | null>(null);

    const scrollbarRef = useRef<Scrollbar | null>(null);

    useEffect(() => {
      const scrollbar = scrollbarRef.current;

      if (scrollbar && currentEl) {
        scrollbar.scroll(
          {
            el: currentEl,
          },
          300
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEl]);

    const handleClick = (scriptItem: ScriptItem) => () => {
      onScriptItemChange(scriptItem);
    };

    const handleScrollbarReady = useCallback((scrollbar: Scrollbar) => {
      scrollbarRef.current = scrollbar;
    }, []);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 4,
        }}
      >
        <Typography variant="h2" sx={{ mb: 3, pb: 2 }}>
          {t("Exercise:Script")}
        </Typography>

        <ScrollbarComponent
          onReady={handleScrollbarReady}
          containerStyles={{
            [theme.breakpoints.down(tablet)]: {
              ".os-host": {
                maxHeight: maxScrollHeight,
              },
            },
          }}
        >
          <ObserverFlexList
            items={script}
            renderItem={(scriptItem) => (
              <StyledScriptItem
                key={scriptItem.id}
                ref={(el) => {
                  if (currentScriptItem === scriptItem.id) {
                    setCurrentEl(el);
                  }
                }}
                active={currentScriptItem === scriptItem.id}
                onClick={handleClick(scriptItem)}
                dangerouslySetInnerHTML={{ __html: String(scriptItem.text) }}
              />
            )}
          />
        </ScrollbarComponent>
      </Box>
    );
  }
);
