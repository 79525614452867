import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "column",
  minWidth: "31.25rem",
  background: theme.palette.background.paper,
  borderRadius: "0.5rem",
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down("tablet")]: {
    wudth: "100%",
    minWidth: "initial",
  },
}));
