import { Box, styled } from "@mui/material";

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.down("tablet")]: {
    alignItems: "flex-start",
  },
}));
