import { styled } from "@mui/material";

import {
  Checkbox,
  CheckboxProps,
} from "../../../../../../../components/Checkbox";
import { StatusType } from "../../../../../../../types";

interface StyledCheckboxProps {
  status: StatusType;
}

export const StyledCheckbox = styled(
  (props: CheckboxProps) => <Checkbox size="small" {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "status",
  }
)<StyledCheckboxProps>(({ theme, status }) => ({
  marginRight: theme.spacing(3),
  padding: 0,
  color: `${theme.palette.custom.answerColorVariant.font.default} !important`,

  ...(status !== "default" && {
    color: `${theme.palette.custom.answerColorVariant.border[status]} !important`,
  }),
}));
