import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { CreateReportForm } from "./CreateReportForm";

export const CreateStudentReport = observer((): JSX.Element => {
  const { t } = useTranslation();

  return <CreateReportForm title={t("CreateReport:StudentTitle")} dateRange />;
});
