import { Box, styled } from "@mui/material";

export const LogoWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  marginBottom: theme.spacing(7),

  [theme.breakpoints.down(480)]: {
    marginBottom: theme.spacing(5),
  },
}));
