import { styled } from "@mui/material";

import { ModuleItemContainerProps } from "../BlockItem";

export const TypeTextContainer = styled("div")<ModuleItemContainerProps>(
  ({ colorVariant }: ModuleItemContainerProps) => {
    return {
      color: colorVariant,
      display: "flex",
    };
  }
);
