import { Box, useTheme } from "@mui/material";

import { StatusType } from "../../types";

interface QuestionOrderProps {
  status: StatusType;
  children: JSX.Element[] | JSX.Element | null;
  order?: number;
  variant?: "inline" | "block";
}

export const QuestionOrder = ({
  status,
  children,
  order,
  variant = "inline",
}: QuestionOrderProps): JSX.Element | null => {
  const theme = useTheme();

  if (!order) {
    return <>{children}</>;
  }

  return (
    <Box sx={{ position: "relative", pl: "40px" }}>
      <Box
        sx={{
          ...theme.typography.regularText,
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 26,
          height: "3em",
          color:
            status === "default"
              ? theme.palette.custom.answerColorVariant.border.current
              : theme.palette.custom.answerColorVariant.border[status],

          "&::before": {
            position: "absolute",
            content: "''",
            width: "100%",
            height: 26,
            border: "1px solid",
            borderRadius: "50%",

            borderColor:
              status === "default"
                ? theme.palette.custom.answerColorVariant.border.current
                : theme.palette.custom.answerColorVariant.border[status],
          },

          ...(variant === "block" && {
            height: 26,
          }),
        }}
      >
        {order}
      </Box>

      {children}
    </Box>
  );
};
