/* eslint-disable @typescript-eslint/no-empty-interface */
import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { DiffModel } from "./Diff";

export const AnswerModel = types.model({
  id: types.identifier,
  correctOptions: types.optional(
    types.array(types.union(types.string, types.number)),
    []
  ),
  diff: types.array(DiffModel),
});

type AnswerModelType = Instance<typeof AnswerModel>;
type AnswerModelSnapshotType = SnapshotOut<typeof AnswerModel>;

export interface Answer extends AnswerModelType {}
export type AnswerSnapshot = AnswerModelSnapshotType;
