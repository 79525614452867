/* eslint-disable import/no-extraneous-dependencies */
import { forwardRef } from "react";

import {
  FormControl,
  TextFieldProps,
  Typography,
  useTheme,
} from "@mui/material";

import { PasswordField } from "components/PasswordField";

type FormInputProps = {
  label: string;
} & TextFieldProps;

export const InputField = forwardRef(
  ({ label, ...other }: FormInputProps, ref): JSX.Element | null => {
    const theme = useTheme();

    return (
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "1rem",
        }}
      >
        <Typography variant="h3" sx={{ width: "120px" }}>
          {label}
          <Typography component="span" color="warning.main">
            *
          </Typography>
        </Typography>
        <PasswordField
          {...other}
          ref={ref}
          fullWidth
          variant="standard"
          sx={{
            borderBottom: "none",
            ".MuiInputBase-root": {
              borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
            },
          }}
        />
      </FormControl>
    );
  }
);
