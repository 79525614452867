import { styled } from "@mui/material";

export const Photo = styled("img")(() => ({
  width: "100%",
  backgroundColor: "gray",
  height: "200px",
  borderRadius: "6px",
  mb: "1.25rem",
  objectFit: "cover",
}));
