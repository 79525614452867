import { Box, styled, BoxProps } from "@mui/material";

export interface ContentContainerProps extends BoxProps {
  indented?: boolean;
}

export const ContentContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "indented",
})<ContentContainerProps>(({ theme, indented }) => ({
  maxWidth: "63.75rem",
  width: "100%",
  margin: theme.spacing(0, "auto"),

  ...(indented && {
    marginBottom: theme.spacing(4),
    marginTop: "0.85rem",
  }),

  [theme.breakpoints.down("tablet")]: {
    maxWidth: "100%",
    margin: theme.spacing(0),
  },
}));
