import { createContext } from "react";
import { ExerciseErrorType, ExerciseResultError } from "@packages/store/models/ExerciseResult/ExerciseResultError";

import { WordRange } from "../types";

export interface ErrorCreateContextProps {
  newErrorRange?: WordRange;
  newErrorType?: ExerciseErrorType;
  selectedError?: ExerciseResultError;
  setNewErrorType?: (_: ExerciseErrorType | undefined) => void;
  setNewErrorRange?: (_?: WordRange) => void;
  setSelectedError?: (_: ExerciseResultError | undefined) => void;
}

export const ErrorCreateContext = createContext<ErrorCreateContextProps>({});