/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  resolveIdentifier,
  SnapshotOut,
  types,
} from "mobx-state-tree";
import uniqBy from "lodash/uniqBy";

import { TeacherModel, TeacherSnapshot, Teacher } from "./Teacher";
import { withEnvironment } from "../extensions/withEnvironment";

export const TeacherStoreModel = types
  .model("TeacherStore")
  .props({
    items: types.optional(types.array(TeacherModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: TeacherSnapshot) => {
      const instance = resolveIdentifier(TeacherModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getById(teacherId: string): Teacher | undefined {
      return self.items.find((teacher) => teacher.actualId === teacherId);
    },
    get uniqueTeachers(): Teacher[] {
      return uniqBy(self.items, "actualId");
    },
  }));

type TeacherStoreType = Instance<typeof TeacherStoreModel>;
export type TeacherStore = TeacherStoreType;
type TeacherStoreSnapshotType = SnapshotOut<typeof TeacherStoreModel>;
export type TeacherStoreSnapshot = TeacherStoreSnapshotType;
export const createTeacherStoreDefaultModel = () =>
  types.optional(TeacherStoreModel, {} as TeacherStoreSnapshot);
