import { useState, useRef, useEffect, useCallback } from "react";

import { useStores } from "@packages/store/models";
import { CancelToken as CancelTokenGenerator } from "apisauce";
import { CancelToken, CancelTokenSource } from "axios";

export type FetchCallback = (token?: CancelToken) => Promise<any>;

export const useCancelableFetch = () => {
  const { fetchApi } = useStores();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const source = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => source.current?.cancel();
  }, []);

  const fetch = useCallback(async (callback: FetchCallback) => {
    source.current?.cancel();

    source.current = CancelTokenGenerator.source();

    const { token } = source.current;

    setLoading(true);
    setError(null);
    try {
      const response = await fetchApi(callback, true, token);
      if (response) {
        setLoading(false);
      }
      return response;
    } catch (error) {
      setError(error);
      setLoading(false);
      return null;
    }
  }, []);

  return { fetch, loading, error, source: source.current } as const;
};
