/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CancelToken } from "axios";
import {
  applySnapshot,
  flow,
  Instance,
  SnapshotOut,
  types,
} from "mobx-state-tree";

import {
  CheckExerciseProps,
  GetLearningGroupsParams,
} from "../../services/Api";
import { ErrorHandler } from "../../services/ErrorHandler/ErrorHandler";
import { mergeHelper } from "../../utils/basic";
import { AuthStoreModel } from "../AuthStore";
import { BlockStoreModel } from "../Block/BlockStore";
import { CancellationReportStoreModel } from "../CancellationReport/CancellationReportStore";
import { ClassGroupStoreModel } from "../ClassGroup/ClassGroupStore";
import { ClassGroupRateStoreModel } from "../ClassGroupRate/ClassGroupRateStore";
import { ClassScheduleStoreModel } from "../ClassSchedule/ClassScheduleStore";
import { CompanyReportStoreModel } from "../CompanyReport/CompanyReportStore";
import { CourseStoreModel } from "../Course/CourseStore";
import { CustomerCompanyStoreModel } from "../CustomerCompany/CustomerCompanyStore";
import { ExerciseStoreModel } from "../Exercise/ExerciseStore";
import { ExerciseAnswerStoreModel } from "../ExerciseAnswer/ExerciseAnswerStore";
import { ExerciseResultStoreModel } from "../ExerciseResult/ExerciseResultStore";
import { localStorageMixin } from "../extensions/localStorageMixin";
import { withEnvironment } from "../extensions/withEnvironment";
import { GenerateDistributorReportStoreModel } from "../GenerateReport/GenerateReportStore";
import { HRStatisticsStore } from "../HRStatistic/HRStatisticsStore";
import { KnowledgeLevelStoreModel } from "../KnowledgeLevel/KnowledgeLevelStore";
import { LanguageStoreModel } from "../Language/LanguageStore";
import { LearningGroupStoreModel } from "../LearningGroups/LearningGroupStore";
import { LearningGroupsApproveStoreModel } from "../LearningGroupsApproves/LearningGroupsApproveStore";
import { Lesson } from "../Lesson/LessonModel";
import { LessonStoreModel } from "../Lesson/LessonStore";
import { NotificationsStoreModel } from "../Notifications/NotificationsStore";
import { PassingStoreModel } from "../Passing/PassingStore";
import { ReportTestStoreModel } from "../ReportTest/ReportTestStore";
import { SectionsStoreModel } from "../Sections";
import { SummaryStoreModel } from "../Summary/SummaryStore";
import { SurchargeStoreModel } from "../Surcharges/SurchargesStore";
import { TagStoreModel } from "../Tag/TagStore";
import { TaggingListStoreModel } from "../TaggingList/TaggingListStore";
import { TeacherStoreModel } from "../Teacher/TeacherStore";
import { TeacherRateStoreModel } from "../TeacherRate/TeacherRateStore";
import { TranslatedWordStoreModel } from "../TranslatedWord/TranslatedWordStore";
import { UnitStoreModel } from "../Unit/UnitStore";
import { UsefulLinkStoreModel } from "../UsefulLink/UsefulLinkStore";
import { WordStoreModel } from "../Word/WordStore";
import { WordFilterStoreModel } from "../WordFilter/WordFilterStore";

/**
 * A RootStore model.
 */

export const RootStoreModel = types
  .model("RootStore")
  .props({
    auth: types.optional(AuthStoreModel, {} as any),
    course: types.optional(CourseStoreModel, {} as any),
    learningGroup: types.optional(LearningGroupStoreModel, {} as any),
    learningGroupApprove: types.optional(
      LearningGroupsApproveStoreModel,
      {} as any
    ),
    surcharge: types.optional(SurchargeStoreModel, {} as any),
    knowledgeLevel: types.optional(KnowledgeLevelStoreModel, {} as any),
    lesson: types.optional(LessonStoreModel, {} as Lesson),
    teacher: types.optional(TeacherStoreModel, {} as any),
    classSchedule: types.optional(ClassScheduleStoreModel, {} as any),
    usefulLink: types.optional(UsefulLinkStoreModel, {} as any),
    exercise: types.optional(ExerciseStoreModel, {} as any),
    exerciseAnswer: types.optional(ExerciseAnswerStoreModel, {} as any),
    exerciseResult: types.optional(ExerciseResultStoreModel, {} as any),
    classGroup: types.optional(ClassGroupStoreModel, {} as any),
    classGroupRate: types.optional(ClassGroupRateStoreModel, {} as any),
    hrStatistics: types.optional(HRStatisticsStore, {} as any),
    block: types.optional(BlockStoreModel, {} as any),
    passing: types.optional(PassingStoreModel, {} as any),
    word: types.optional(WordStoreModel, {} as any),
    wordFilter: types.optional(WordFilterStoreModel, {} as any),
    unit: types.optional(UnitStoreModel, {} as any),
    section: types.optional(SectionsStoreModel, {} as any),
    reportTest: types.optional(ReportTestStoreModel, {} as any),
    distributorReport: types.optional(
      GenerateDistributorReportStoreModel,
      {} as any
    ),
    customerCompany: types.optional(CustomerCompanyStoreModel, {} as any),
    companyReport: types.optional(CompanyReportStoreModel, {} as any),
    cancellationReport: types.optional(CancellationReportStoreModel, {} as any),
    notifications: types.optional(NotificationsStoreModel, {} as any),
    taggingList: types.optional(TaggingListStoreModel, {} as any),
    tag: types.optional(TagStoreModel, {} as any),
    teacherRate: types.optional(TeacherRateStoreModel, {} as any),
    language: types.optional(LanguageStoreModel, {} as any),
    translatedWord: types.optional(TranslatedWordStoreModel, {} as any),
    summary: types.optional(SummaryStoreModel, {} as any),
    loading: types.optional(types.boolean, false),
    error: types.optional(types.string, ""),
    success: types.optional(types.string, ""),
  })
  .extend(withEnvironment)
  .views((self) => ({
    get api() {
      return self.environment.api;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      self.auth.getCurrentUser();
    },
    setLoading(value: boolean) {
      self.loading = value;
    },
    addError(message: string) {
      self.error = message;
    },
    clearError() {
      self.error = "";
    },
    addSuccess(message: string) {
      self.success = message;
    },
    clearSuccess() {
      self.success = "";
    },
    merge(data: unknown) {
      return mergeHelper(self, data);
    },
  }))
  .actions((self) => ({
    cleanStore() {
      applySnapshot(self, {});
    },
  }))
  .actions((self) => ({
    fetchApi: flow(function* (
      method: (token?: CancelToken) => Promise<any>,
      disableLoading?: boolean,
      token?: CancelToken
    ) {
      if (!disableLoading) self.setLoading(true);
      const result = yield method(token);
      if (result.kind === "ok") {
        self.merge(result);
        if (!disableLoading) self.setLoading(false);
        return result;
      }
      if (result.kind === "cancel") {
        if (!disableLoading) self.setLoading(false);
        return false;
      }
      if (result.kind === "unauthorized") self.cleanStore();
      const error =
        JSON.stringify(result?.message, null, 2) || `API Error: ${result.kind}`;
      self.addError(error);
      ErrorHandler.logError(new Error(error));
      if (!disableLoading) self.setLoading(false);
      return false;
    }),
  }))
  .actions((self) => ({
    getLearningGroups: flow(function* (
      params: GetLearningGroupsParams = {},
      disableLoading = true
    ) {
      return yield self.fetchApi(
        () => self.environment.api.getLearningGroups(params),
        disableLoading
      );
    }),
    getCourse: flow(function* (courseId: string, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getCourse(courseId),
        disableLoading
      );
    }),
    getPassings: flow(function* (courseId: string, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getPassings(courseId),
        disableLoading
      );
    }),
    getCourses: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getCourses(),
        disableLoading
      );
    }),
    getCoursePassings: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getCoursePassings(),
        disableLoading
      );
    }),
    getWords: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getWords(),
        disableLoading
      );
    }),
    getUnit: flow(function* (
      { courseId, unitId }: { courseId: string; unitId: string },
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.getUnit(courseId, unitId),
        disableLoading
      );
    }),
    getUnits: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getUnits(),
        disableLoading
      );
    }),
    getTeacherCourses: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getTeacherCourses(),
        disableLoading
      );
    }),
    getBlocks: flow(function* (
      { courseId, unitId, sectionId },
      disableLoading?: boolean
    ) {
      if (!courseId || !unitId || !sectionId) return;
      return yield self.fetchApi(
        () => self.environment.api.getBlocks(courseId, unitId, sectionId),
        disableLoading
      );
    }),
    getUnitsByCourse: flow(function* ({ courseId }, disableLoading?: boolean) {
      if (!courseId) return;
      return yield self.fetchApi(
        () => self.environment.api.getUnitsByCourse(courseId),
        disableLoading
      );
    }),
    getCustomerCompanies: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getCustomerCompanies(),
        disableLoading
      );
    }),
    getReportTests: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getReportTests(),
        disableLoading
      );
    }),
    restoreMyWordsFilter: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getWordFilter(),
        disableLoading
      );
    }),
    generateDistributorReport: flow(function* (
      { dateFrom, dateTo },
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () =>
          self.environment.api.generateDistributorReport({
            dateFrom,
            dateTo,
          }),
        disableLoading
      );
    }),
    getDistributorReport: flow(function* (id, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getDistributorReport(id),
        disableLoading
      );
    }),
    getDistributorReportsByFilter: flow(function* (
      data,
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.getDistributorReportByFilter(data),
        disableLoading
      );
    }),
    processLessons: flow(function* (ids: string[], disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.processLessons(ids),
        disableLoading
      );
    }),
    getUnit_v3: flow(function* (unitId: string, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getUnit_v3(unitId),
        disableLoading
      );
    }),
    getBlock_v3: flow(function* (blockId: string, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getBlock_v3(blockId),
        disableLoading
      );
    }),
    getTeachersGroupsV3: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getTeachersGroupsV3(),
        disableLoading
      );
    }),
    getNotifications: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getNotifications(),
        disableLoading
      );
    }),
  }))
  .actions((self) => ({
    sendUserVisit() {
      const { id = "0" } = self.auth.user ?? {};
      const userId = Number(id);
      const url = window.location.href;
      self.environment.api.sendUserVisits({ userId, url });
    },
    sendUserActive(time: number, courseId: number) {
      const { id = "0" } = self.auth.user ?? {};
      const userId = Number(id);
      const { href, pathname } = window.location;
      const url = href;
      const isLearnPage = pathname.split("/")[1] === "learn";
      const pageTypeId = isLearnPage ? 2 : 1;
      const reportInterval = 2;
      self.environment.api.sendUserActivity({
        userId,
        url,
        time,
        courseId,
        pageTypeId,
        reportInterval,
      });
    },
    updateUserPassword: flow(function* (
      newPassword: string,
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.updateUserPassword(newPassword),
        disableLoading
      );
    }),
    checkExercise: flow(function* (
      data: CheckExerciseProps,
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.checkExercise(data),
        disableLoading
      );
    }),
    resetExercise: flow(function* (
      exerciseId: string,
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.resetExercise(exerciseId),
        disableLoading
      );
    }),
    markNotificationAsRead: flow(function* (
      notificationId: string,
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.markNotificationAsRead(notificationId),
        disableLoading
      );
    }),
    getTaggingLists: flow(function* (
      area?: "teacher",
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.getTaggingLists(area),
        disableLoading
      );
    }),
    getTaggingList: flow(function* (id: string, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getTaggingList(id),
        disableLoading
      );
    }),
    getTaggingTags: flow(function* (disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getTaggingTags(),
        disableLoading
      );
    }),
    getTeacherTags: flow(function* (
      teacherId: string,
      disableLoading?: boolean
    ) {
      return yield self.fetchApi(
        () => self.environment.api.getTeacherTags(teacherId),
        disableLoading
      );
    }),
    getTeacher: flow(function* (teacherId: string, disableLoading?: boolean) {
      return yield self.fetchApi(
        () => self.environment.api.getTeacher(teacherId),
        disableLoading
      );
    }),
  }))
  .actions((self) => ({
    getInitialStudentState: flow(function* () {
      self.setLoading(true);
      yield Promise.all([
        self.getUnits(false),
        self.getCourses(false),
        self.notifications.loadNotifications(),
      ]);
      self.getCoursePassings(true);
      self.setLoading(false);
    }),
    getInitialTeacherState: flow(function* () {
      self.setLoading(true);
      yield Promise.all([
        self.getCoursePassings(false),
        self.getTeacherCourses(false),
        self.notifications.loadNotifications(),
      ]);
      self.getCustomerCompanies(true);
      self.setLoading(false);
    }),
    getInitialDistributorState() {
      self.getCustomerCompanies(true);
    },
  }))
  .extend(
    localStorageMixin({
      throttle: 1000,
      filter: [
        "auth",
        "distributorReport",
        "wordFilter",
        "exerciseAnswer.items",
      ],
    })
  );

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>;

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>;
