import { observer } from 'mobx-react'

import styles from './Loading.module.scss'
import { ReactNode } from 'react';

interface LoadingProps {
  loading?: boolean;
	children?: ReactNode;
}

export const Loading = observer(function Loading(props: LoadingProps) {
  const { loading, children } = props

	if (!loading) {
		return <>{children}</>
	}

	return (
		<div className={styles.container}>
			<div className={styles.loading} />
		</div>
	)
})
