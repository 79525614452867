/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  getType,
  applySnapshot,
  addDisposer,
  onSnapshot,
} from "mobx-state-tree";
import { throttle } from "throttle-debounce";

import { flatPick } from "../../utils/basic";

type LocalStorageMixinOptions = {
  storage?: {
    getItem(key: string): string | null | Promise<string | null>;
    setItem(key: string, data: string): void;
  };
  throttle?: number; // How often the snapshot is written to local storage
  storageKey?: string;
  filter?: string[];
};
export function localStorageMixin(options: LocalStorageMixinOptions = {}) {
  if (typeof window === "undefined") {
    console.warn("Local storage is not available");
    return (self: any) => ({});
  }
  const storage = window.localStorage;
  const throttleInterval = options.throttle || 1000;
  const storageKey = options.storageKey || "jetclass";
  return (self: any) => ({
    actions: {
      async afterCreate() {
        const data = await storage.getItem(storageKey);
        if (data) {
          const json = JSON.parse(data);
          const selfType = getType(self);
          if (!selfType.is(json)) {
            console.warn(
              // eslint-disable-next-line max-len
              `Data in local storage does not conform the data shape specified by ${selfType.name}, ignoring the stored data`
            );
            return;
          }
          applySnapshot(self, json);
        }
        addDisposer(
          self,
          onSnapshot(
            self,
            throttle(throttleInterval, (data: any) => {
              if (options.filter) {
                data = flatPick(data, options.filter);
              }
              storage.setItem(storageKey, JSON.stringify(data));
            })
          )
        );
      },
    },
  });
}
