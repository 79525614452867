/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { types, SnapshotOut, Instance, flow } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { TagUpdateData } from "../../services/Api";

export const TagModel = types
  .model("TagModel")
  .props({
    id: types.identifier,
    listId: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    updateTag: flow(function* (data: TagUpdateData) {
      const response = yield self.environment.api.updateTag(self.id, data);

      if (response.kind !== "ok") {
        return false;
      }

      self.name = data.name;

      return true;
    }),
  }));

type TagModelType = Instance<typeof TagModel>;
export interface Tag extends TagModelType {}
type TagSnapshotType = SnapshotOut<typeof TagModel>;
export type TagSnapshot = TagSnapshotType;
