import { styled, Box } from "@mui/material";

export const FormContainer = styled(Box)(({ theme }) => ({
  maxWidth: "400px",
  width: "100%",
  paddingRight: "env(safe-area-inset-right)",
  paddingBottom: "env(safe-area-inset-bottom)",

  [theme.breakpoints.down("laptop")]: {
    maxWidth: "100%",
  },
}));
