import { useCallback, useLayoutEffect } from "react";

import { Stack } from "@mui/material";
import { TeacherRate } from "@packages/store/models/TeacherRate/TeacherRate";
import { observer } from "mobx-react-lite";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Modal, ModalProps } from "components/shared/Modal";
import { ValueWithLabel } from "types";

import { ActiveFromInput, AmountInput, ClassTypeSelect } from "../FormFields";

export interface ModalTeacherRateProps extends Omit<ModalProps, "children"> {
  onSubmit: (data: ModalTeacherRateFormValues) => void;
  initialData: TeacherRate | null;
  listClassTypes: ValueWithLabel[];
}

export interface ModalTeacherRateFormValues {
  classType: string;
  amount: string;
  activeFrom: Date;
}

export const ModalTeacherRate = observer(
  ({
    onSubmit,
    onClose,
    initialData,
    listClassTypes,
    ...other
  }: ModalTeacherRateProps) => {
    const { t } = useTranslation();

    const {
      handleSubmit,
      setValue,
      clearErrors,
      control,
      formState: { isSubmitting },
    } = useForm<ModalTeacherRateFormValues>();

    const resetToInitial = useCallback(() => {
      setValue("classType", initialData?.classType ?? "");
      setValue("amount", initialData?.amount ?? "");
      setValue(
        "activeFrom",
        (initialData?.activeFrom ?? null) as unknown as Date
      );

      clearErrors();
    }, [
      setValue,
      clearErrors,
      initialData?.activeFrom,
      initialData?.amount,
      initialData?.classType,
    ]);

    const handleClose: typeof onClose = (...args) => {
      onClose?.(...args);

      resetToInitial();
    };

    useLayoutEffect(() => {
      resetToInitial();
    }, [resetToInitial]);

    return (
      <Modal
        containerProps={{ type: "small" }}
        onClose={handleClose}
        {...other}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing="1rem"
            direction="row"
            alignItems="flex-end"
            mb="1rem"
            sx={{ "& > *": { flex: "auto" } }}
          >
            <ClassTypeSelect
              control={control}
              name="classType"
              data={listClassTypes}
              placeholder={t("TeacherDetails:RateModalFormClassType")}
              required={t("TeacherDetails:RequiredFieldError")}
            />

            <AmountInput
              control={control}
              name="amount"
              placeholder={t("TeacherDetails:RateModalFormAmount")}
              required={t("TeacherDetails:RequiredFieldError")}
            />

            <ActiveFromInput
              control={control}
              name="activeFrom"
              placeholder={t("TeacherDetails:RateModalFormActiveFrom")}
              required={t("TeacherDetails:RequiredFieldError")}
            />
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing="0.5rem">
            <Button
              variant="outlined"
              onClick={() => handleClose({}, "backdropClick")}
            >
              {t("TeacherDetails:RateModalCancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              sx={{ borderRadius: "4px" }}
              disabled={isSubmitting}
            >
              {t("TeacherDetails:RateModalSubmit")}
            </Button>
          </Stack>
        </form>
      </Modal>
    );
  }
);
