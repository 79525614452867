import { Box, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";

import { Icon } from "../../Icon";
import { StyledPaper } from "../styledComponents/StyledPaper";

interface SummaryProps {
  passedCount: number;
  wrongCount: number;
  skippedCount: number;
}

export const Summary = observer(
  ({
    passedCount,
    wrongCount,
    skippedCount,
  }: SummaryProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const data = [
      {
        value: passedCount,
        color: theme.palette.success.main,
      },
      {
        value: skippedCount,
        color: theme.palette.custom.grey.accentText,
      },
      {
        value: wrongCount,
        color: theme.palette.error.main,
      },
    ];

    return (
      <StyledPaper
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(170px, 1fr))",
          gap: "1rem",
        }}
      >
        <Box maxWidth="250px" maxHeight="250px">
          <PieChart lineWidth={35} data={data} />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            justifyContent="center"
          >
            <Box display="flex" alignItems="center" gap="6px">
              <Icon type="exerciseSuccess" sizeSquareIcon={22} />
              <Typography variant="h2" color="success.main" whiteSpace="nowrap">
                {t("ResultsModals:Passed", {
                  count: passedCount,
                })}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="6px">
              <Icon type="exerciseFail" sizeSquareIcon={22} />
              <Typography variant="h2" color="error.main" whiteSpace="nowrap">
                {t("ResultsModals:Wrong", {
                  count: wrongCount,
                })}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="6px">
              <Icon type="exerciseSkip" sizeSquareIcon={22} />
              <Typography
                whiteSpace="nowrap"
                variant="h2"
                color="custom.grey.accentText"
              >
                {t("ResultsModals:Skipped", {
                  count: skippedCount,
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </StyledPaper>
    );
  }
);
