import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

export function filterGroupsByGroups(
  groups: LearningGroup[],
  groupIds: string[]
): LearningGroup[] {
  return groups.filter((item) => {
    return groupIds.includes(item.id);
  });
}
