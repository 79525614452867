import { styled } from "@mui/material";

export const WrapperIconTitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  flexBasis: "30%",
  minWidth: 150,

  [theme.breakpoints.down("zeroScreen")]: {
    marginRight: theme.spacing(0),
  },
}));
