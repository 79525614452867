import { styled, Typography, TypographyProps } from "@mui/material";

import { StatusType } from "../../../../../../../types";

interface StyledLabelProps {
  status: StatusType;
}

export const StyledLabel = styled(
  (props: TypographyProps) => <Typography variant="regularText" {...props} />,
  {
    shouldForwardProp: (prop) => prop !== "status",
  }
)<StyledLabelProps>(({ theme, status }) => ({
  display: "flex",
  color: theme.palette.custom.answerColorVariant.font.default,

  ...(status !== "default" && {
    color: theme.palette.custom.answerColorVariant.border[status],
  }),
}));
