import { styled, Typography } from "@mui/material";

export const CopyrightContainer = styled(Typography)(({ theme }) => ({
  flexBasis: "17.5rem",
  flexGrow: 15,
  color: theme.palette.custom?.grey?.main,
  [theme.breakpoints.down("laptop")]: {
    flexBasis: "0",
    marginTop: theme.spacing(4),
  },
}));
