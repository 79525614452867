import type { Teacher } from "@packages/store/models/Teacher/Teacher";

export const formatTeacher = (teacher: Teacher) => {
  return {
    id: teacher.actualId,
    fullName: teacher.fullName,
    // email: teacher.email,
    // phone: teacher.phone,
    // langs: teacher.langs.map((lang) => lang.shortName),
  };
};

export type FormattedTeacher = ReturnType<typeof formatTeacher>;
