import { styled } from "@mui/material";
import { ResizableBox as ResizableBoxSource } from "react-resizable";

import { tablet } from "../../../constants/styles";

export const ResizableBox = styled(ResizableBoxSource)(({ theme }) => ({
  [theme.breakpoints.down(tablet)]: {
    minWidth: "100%",
    width: "100%",
  },
}));
