import { Box, Typography } from "@mui/material";
import { useStores } from "@packages/store/models";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { getFilteredWords } from "pages/Student/MyWords/helpers";

export const LearnWords = observer((): JSX.Element => {
  const { t } = useTranslation();

  const { word, wordFilter } = useStores();

  const words = getFilteredWords(word.items, wordFilter);

  const isEnoughWords = words.length >= 6;

  if (!words.length) {
    return <></>;
  }

  return (
    <Box alignItems="flex-end" display="flex" flexDirection="column">
      {!isEnoughWords && (
        <Typography color="red" fontSize="0.8rem" mt="0.5rem" textAlign="end">
          {t("MyWords:NotEnoughWords")}
        </Typography>
      )}
    </Box>
  );
});
