/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, SnapshotOut, Instance } from "mobx-state-tree";
import { QuestionState } from "../ExerciseAnswer/Question";

import { AnswerModel } from "./Answer";

export const PassingDataModel = types.model("PassingData", {
  id: types.identifier,
  mark: types.optional(types.number, 0),
  answers: types.array(AnswerModel),
  state: types.maybeNull(
    types.enumeration<QuestionState>(
      "PassingDataState",
      Object.values(QuestionState)
    )
  ),
});

type PassingDataModelType = Instance<typeof PassingDataModel>;
type PassingDataModelTypeSnapshotType = SnapshotOut<typeof PassingDataModel>;

export interface PassingData extends PassingDataModelType {}
export type PassingDataSnapshot = PassingDataModelTypeSnapshotType;
