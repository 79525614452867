import { useState, useEffect, useLayoutEffect } from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useStores } from "@packages/store/models";
import { Icon } from "../../components/Icon";
import { Loading } from "../../components/Loading";
import { TabPanel } from "../../components/TabPanel";
import { useFetch } from "../../hooks/useFetch";
import { usePopover } from "../../hooks/usePopover";
import { observer } from "mobx-react-lite";
import { tablet } from "../../constants/styles";
import { useTranslateMode } from "../../hooks/useTranslateMode";
import { useTranslation } from "react-i18next";
import { WordTranslation } from "@packages/store/services/Api";

import { IconWrapper } from "./styledComponents/IconWrapper";
import { StyledPopover } from "./styledComponents/StyledPopover";
import { Tab } from "./styledComponents/Tab";
import { Tabs } from "./styledComponents/Tabs";
import { Translation } from "./Translation";
import { Vocabulary } from "./Vocabulary";

interface AnchorPosition {
  top: number;
  left: number;
}

type Tab = "translation" | "vocabulary";

export const TranslationMenu = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const theme = useTheme();
  const isTouchScreen = useMediaQuery(theme.breakpoints.down(tablet));

  const [currentTab, setCurrentTab] = useState<Tab>("translation");
  const [selectedWord, setSelectedWord] = useState("");
  const [wordTranslation, setWordTranslation] =
    useState<WordTranslation | null>(null);
  const [anchorPosition, setAnchorPosition] = useState<AnchorPosition>({
    top: 0,
    left: 0,
  });

  const { open, handleOpen, handleClose } = usePopover();

  const rootStore = useStores();

  const {
    fetch: translateWord,
    loading: translateWordLoading,
    error: translateWordError,
  } = useFetch(
    (word: string) => {
      return rootStore.environment.api.getWordTranslation(word, "en-ru");
    },
    (data) => {
      setWordTranslation(data as WordTranslation);
    }
  );

  const { translateMode } = useTranslateMode();

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection();
      const selectedText = selection
        ?.toString()
        .replace(/[,.;]/g, " ")
        .replace(/ {2}/g, " ")
        .trim()
        .split(" ")
        .filter((word) => word !== "-")[0];

      if (selection && selectedText) {
        const word = selectedText;
        const { top, left, height } = selection
          .getRangeAt(0)
          .getBoundingClientRect();

        setSelectedWord(word);
        setAnchorPosition({ top: top + height, left });

        selection.removeAllRanges();

        if (isTouchScreen) {
          setTimeout(() => {
            handleOpen();
          }, 100);
        } else {
          handleOpen();
        }
      }
    };

    if (translateMode) {
      document.addEventListener("pointerup", handleSelectionChange);
      document.addEventListener("pointercancel", handleSelectionChange);
      document.addEventListener("dblclick", handleSelectionChange);
    }

    return () => {
      document.removeEventListener("pointerup", handleSelectionChange);
      document.removeEventListener("pointercancel", handleSelectionChange);
      document.removeEventListener("dblclick", handleSelectionChange);
    };
  }, [handleOpen, isTouchScreen, translateMode]);

  useEffect(() => {
    if (selectedWord) {
      translateWord(selectedWord);
    }
  }, [selectedWord]);

  useLayoutEffect(() => {
    if (open) {
      setCurrentTab("translation");
    }
  }, [open]);

  const handleTabChange = (tab: Tab) => () => {
    setCurrentTab(tab);
  };

  const handleVoice = () => {
    const utterance = new SpeechSynthesisUtterance(selectedWord);

    utterance.lang = "en";

    const voices = window.speechSynthesis.getVoices();
    const voice = voices.find(({ lang }) => lang === "en-US");

    if (voice) {
      utterance.voice = voice;
    }

    window.speechSynthesis.speak(utterance);
  };

  const renderTabPanel = (): JSX.Element | null => {
    if (translateWordError) {
      return (
        <Typography variant="regularText">
          {t("Exercise:TranslateWordFailed")}
        </Typography>
      );
    }

    const noTranslation =
      !wordTranslation ||
      wordTranslation.dictionaryWord.translations.length === 0;

    const {
      definitions = [],
      transcription = "",
      dictionaryWord: { translations = [] },
    } = wordTranslation || { dictionaryWord: {} };

    return (
      <Loading loading={translateWordLoading}>
        {noTranslation ? (
          <Typography variant="regularText">
            {t("Exercise:NoTranslation")}
          </Typography>
        ) : (
          <>
            <TabPanel remount value={currentTab} tabValue="translation">
              <Translation translations={translations} />
            </TabPanel>

            <TabPanel remount value={currentTab} tabValue="vocabulary">
              <Vocabulary
                word={selectedWord}
                definitions={definitions}
                transcription={transcription}
                onClose={handleClose}
              />
            </TabPanel>
          </>
        )}
      </Loading>
    );
  };

  const showTranslation = currentTab === "translation";
  const showVocabulary = currentTab === "vocabulary";

  return (
    <StyledPopover
      open={open}
      elevation={4}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
      onClose={handleClose}
    >
      <Tabs>
        <Tab active={showTranslation} onClick={handleTabChange("translation")}>
          <Icon
            type="bookMark"
            sizeSquareIcon={16}
            color={theme.palette.primary.contrastText}
          />

          {showTranslation && (
            <Typography
              variant="regularText"
              color="primary.contrastText"
              sx={{ ml: 4 }}
            >
              {t("Exercise:Translate")}
            </Typography>
          )}
        </Tab>

        <Tab active={showVocabulary} onClick={handleTabChange("vocabulary")}>
          <Icon
            type="words"
            sizeSquareIcon={16}
            color={theme.palette.primary.contrastText}
          />

          {showVocabulary && (
            <Typography
              variant="regularText"
              color="primary.contrastText"
              sx={{ ml: 4 }}
            >
              {t("Exercise:Vocabulary")}
            </Typography>
          )}
        </Tab>
      </Tabs>

      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 3,
            my: 2,
          }}
        >
          <IconWrapper>
            <Icon
              type="bxVolumeFull"
              sizeSquareIcon={16}
              onClick={handleVoice}
            />
          </IconWrapper>

          <Typography variant="h3">{selectedWord}</Typography>
        </Box>

        {renderTabPanel()}
      </Box>
    </StyledPopover>
  );
});
