import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Logo } from "components/Logo";
import { routerLinks } from "constants/constants";
import {
  FormContainer,
  FormSubTitle,
  FormTitle,
  FormWrapper,
  LogoWrapper,
  StyledMuiLink,
} from "pages/Login/styledComponents";
import { ROUTES } from "router/constants";

export const AccessDenied = () => {
  const { t } = useTranslation();

  return (
    <FormWrapper>
      <FormContainer>
        <LogoWrapper>
          <Logo width={128} height={32} />
        </LogoWrapper>
        <FormTitle>{t("AuthAccessDenied:Title", { lng: "ru" })}</FormTitle>
        <FormSubTitle variant="regularText">
          {t("AuthAccessDenied:Subtitle", { lng: "ru" })}
        </FormSubTitle>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap="1rem"
        >
          <Typography
            variant="regularText"
            fontSize="0.9rem"
            textAlign="center"
            display="flex"
            gap="0.3rem"
          >
            {t("AuthAccessDenied:Please", { lng: "ru" })}
            <StyledMuiLink href={ROUTES.SIGN_IN_PAGE} fontSize="0.9rem">
              {t("AuthAccessDenied:SignIn", { lng: "ru" })}
            </StyledMuiLink>
            {t("AuthAccessDenied:Or", { lng: "ru" })}
            <StyledMuiLink href="/" fontSize="0.9rem">
              {t("AuthAccessDenied:GoToMain", { lng: "ru" })}
            </StyledMuiLink>
          </Typography>
        </Box>
      </FormContainer>
    </FormWrapper>
  );
};
