import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./Task.module.scss";
import { Icon } from "../../Icon";
import { Button } from "../../Button";
import { Text } from "../../Text";
import { ExerciseResult } from "@packages/store/models/ExerciseResult/ExerciseResult";
import { getButtonType, getTypeIcon } from "./utils/helpers";
import { ButtonType, NEW_CHECK, PROCESSING } from "./utils/types";
import { useStores } from "@packages/store";
import { formatTime } from "../../../utils/helpers";

interface TaskProps {
  containerClassName?: string;
  result: ExerciseResult;
  onButtonClick?: (_: string) => void;
}

export const Task = observer((props: TaskProps) => {
  const {
    containerClassName,
    result,
    onButtonClick
  } = props;

  const { t } = useTranslation();

  const { auth } = useStores();

  const { user } = auth;

  const { isTeacher } = user ?? {};

  const username = isTeacher
    ? t("CreativeTasks:StudentName", {
      name: result.student
    })
    : t("CreativeTasks:TeacherName", {
      name: result.teacher
    })

  const handleButtonClick = useCallback(() => {
    if ([NEW_CHECK, PROCESSING].includes(result.status) && !isTeacher) {
      return;
    }
    onButtonClick?.(result.id);
  }, [result, isTeacher, onButtonClick]);

  const buttonType = getButtonType(result);

  const buttonClasses = cn(
    styles.btn,
    styles[buttonType],
    {
      [styles['for-teacher']]: isTeacher
    }
  );

  const buttonTexts: Record<ButtonType, string> = useMemo(() => ({
    checking: t("CreativeTasks:Checking"),
    error: isTeacher
      ? t("CreativeTasks:FixTeacher")
      : t("CreativeTasks:FixStudent"),
    success: t("CreativeTasks:Done")
  }), [t, isTeacher])

  const containerClasses = cn(styles.container, containerClassName);

  const typeIcon = getTypeIcon(result.type);

  const iconClasses = cn(
    styles.icon,
    {
      [styles.checking]: buttonType === "checking"
    }
  );

  const { subtitle } = result;

  const buttonText = buttonTexts[buttonType];

  return (
    <div className={containerClasses}>
      <div className={styles.top}>
        <Text variant="text-3-regular">
          {t("CreativeTasks:DoneTime", {
            time: formatTime(result.createdAt)
          })}
        </Text>
        {result.checkedAt && (
          <Text variant="text-3-regular" color="check-error">
            {t("CreativeTasks:CheckingTime", {
              time: formatTime(result.checkedAt)
            })}
          </Text>
        )}
        <Text variant="text-3-regular">
          {username}
        </Text>
      </div>
      <div className={styles.bottom}>
        <div className={styles["course-info-container"]}>
          <Icon
            containerClassName={iconClasses}
            icon={typeIcon}
          />
          <div className={styles["course-info"]}>
            <Text variant="text-2-semibold" color="main-color">
              {result.name}
            </Text>
            <Text variant="text-3-regular" color="blue">
              {subtitle}
            </Text>
          </div>
        </div>
        <Button
          containerClassName={buttonClasses}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
});
