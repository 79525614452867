import { styled } from "@mui/material";

export const ModuleItemContainer = styled("div")(({ theme }) => ({
  maxWidth: "31.5rem",
  padding: `${theme.spacing(4)} 1.5rem`,
  backgroundColor: theme.palette.primary.contrastText,
  borderTop: `0.07rem solid ${theme.palette.custom.grey?.["emenu-tile-active"]}`,
  [theme.breakpoints.up("desktop")]: {
    "&:nth-of-type(2)": {
      borderTop: "none",
    },
  },
}));
