import { Word } from "@packages/store/models/Word";
import { WordFilterStore } from "@packages/store/models/WordFilter/WordFilterStore";

export function getFilteredWords(words: Word[], filter: WordFilterStore) {
  const { addedToMe, fromUnit, selectedUnit, selectedType } = filter;

  const fromUnitWords = words
    .filter((word) => {
      return fromUnit && word.isFromUnits;
    })
    .filter((word) => {
      return word.courseUnitId === +selectedUnit;
    });

  const addedToMeWords = words.filter((word) => {
    return addedToMe && word.isAddedByMe;
  });

  return [...fromUnitWords, ...addedToMeWords].filter((word) => {
    switch (selectedType) {
      case "unlearned":
        return !word.isLearned;
      case "learned":
        return word.isLearned;
      default:
        return true;
    }
  });
}
