import { styled, Box } from "@mui/material";

export const PersonalInfoContainer = styled(Box)(({ theme }) => ({
  // display: "grid",
  // gridTemplateColumns: "repeat(2, 1fr)",
  // columnGap: "5rem",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
  maxWidth: "500px",
  marginBottom: theme.spacing(6),
}));
