import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

export function filterGroupsByStudents(
  groups: LearningGroup[],
  studentIds: string[]
): LearningGroup[] {
  return groups.filter((item) => {
    return item.students.find((student) => {
      const groupStudentIds = studentIds.map((id) => `${id}${item.id}`);
      return groupStudentIds.includes(`${student.id}`);
    });
  });
}
