import { Box, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Modal, ModalProps } from "components/shared/Modal";
import { StyledButton } from "pages/TeacherDetails/styledComponents";
import { ValueWithLabel } from "types";

import {
  ActiveFromInput,
  AmountInput,
  ClassTypeSelect,
  LangSelect,
  NativeSpeakerCheckbox,
} from "../FormFields";

export interface ModalTeacherLangProps extends Omit<ModalProps, "children"> {
  onSubmit: (data: ModalTeacherLangFormValues) => void;
  listClassTypes: ValueWithLabel[];
  listLangs: ValueWithLabel[];
}

export interface ModalTeacherLangFormValues {
  lang: string;
  isNativeSpeaker: boolean;
  rate: { classType: string; amount: string; activeFrom: Date }[];
}

export const ModalTeacherLang = observer(
  ({
    onSubmit,
    onClose,
    listClassTypes,
    listLangs,
    ...other
  }: ModalTeacherLangProps) => {
    const { t } = useTranslation();

    const { handleSubmit, reset, control } =
      useForm<ModalTeacherLangFormValues>({
        defaultValues: {
          isNativeSpeaker: false,
          rate: [{}],
        },
      });

    const { fields, append, remove } = useFieldArray({
      control,
      name: "rate",
    });

    const handleClose: typeof onClose = (...args) => {
      onClose?.(...args);
      reset();
    };

    const handleAddRate = () => {
      append({} as any);
    };

    const handleRemoveRate = (index: number) => () => {
      remove(index);
    };

    return (
      <Modal
        title={t("TeacherDetails:LangModalTitle")}
        containerProps={{ type: "small" }}
        onClose={handleClose}
        {...other}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="1rem" mb="1rem">
            <LangSelect
              control={control}
              data={listLangs}
              placeholder={t("TeacherDetails:LangModalFormLang")}
              required={t("TeacherDetails:RequiredFieldError")}
            />
            <NativeSpeakerCheckbox
              control={control}
              placeholder={t("TeacherDetails:LangModalFormNativeSpeaker")}
            />

            {fields.map((field, index) => (
              <Box
                key={field.id}
                display="grid"
                gridTemplateColumns="5fr 5fr 5fr 1fr"
                gap="1rem"
                alignItems="flex-end"
              >
                <ClassTypeSelect
                  control={control}
                  name={`rate.${index}.classType` as const}
                  data={listClassTypes}
                  placeholder={t("TeacherDetails:LangModalFormClassType")}
                  required={t("TeacherDetails:RequiredFieldError")}
                />

                <AmountInput
                  control={control}
                  name={`rate.${index}.amount` as const}
                  placeholder={t("TeacherDetails:LangModalFormAmount")}
                  required={t("TeacherDetails:RequiredFieldError")}
                />

                <ActiveFromInput
                  control={control}
                  name={`rate.${index}.activeFrom` as const}
                  placeholder={t("TeacherDetails:RateModalFormActiveFrom")}
                  required={t("TeacherDetails:RequiredFieldError")}
                />

                {index !== 0 && (
                  <Button
                    variant="iconBtn"
                    onClick={handleRemoveRate(index)}
                    sx={{ alignSelf: "center", flexGrow: "0" }}
                  >
                    <Icon type="close" width={24} height={24} />
                  </Button>
                )}
              </Box>
            ))}
            <StyledButton
              variant="add"
              sx={{ alignSelf: "flex-start" }}
              onClick={handleAddRate}
            >
              <Icon type="bookAdd" sizeSquareIcon={16} />
              {t("TeacherDetails:LangModalAddClassType")}
            </StyledButton>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" spacing="0.5rem">
            <Button
              variant="outlined"
              onClick={() => handleClose({}, "backdropClick")}
            >
              {t("TeacherDetails:LangModalCancel")}
            </Button>
            <Button
              variant="primary"
              type="submit"
              sx={{ borderRadius: "4px" }}
            >
              {t("TeacherDetails:LangModalSubmit")}
            </Button>
          </Stack>
        </form>
      </Modal>
    );
  }
);
