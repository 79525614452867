import { forwardRef, ForwardedRef } from "react";

import { styled, Typography, TypographyProps } from "@mui/material";

interface StyledScriptItemProps extends TypographyProps {
  active?: boolean;
}

export const StyledScriptItem = styled(
  forwardRef((props: TypographyProps, ref: ForwardedRef<HTMLSpanElement>) => (
    <Typography ref={ref} variant="regularText" {...props} />
  )),
  {
    shouldForwardProp: (prop) => prop !== "active",
  }
)<StyledScriptItemProps>(({ active, theme }) => ({
  cursor: "pointer",
  transition: theme.transitions.create(["color"], { duration: 100 }),

  "&:hover": {
    color: theme.palette.info.dark,
  },

  ...(active && {
    color: theme.palette.info.main,

    "&:hover": {
      color: theme.palette.info.main,
    },
  }),
}));
