import { Box, useTheme } from "@mui/material";
import { Block } from "@packages/store/models/Block/Block";
import { Icon, IconType } from "../../components/Icon";
import { StatusTypography } from "../../components/StatusTypography";
import { observer } from "mobx-react-lite";
import { StatusType } from "../../types";

import { IconWrapper } from "./styledComponents/IconWrapper";

interface BlockViewProps {
  block: Block;
  order: number;
  status: StatusType;
  onClick: () => void;
}

const iconTypeMap: { [key: string]: IconType } = {
  default: "vocabulary",
  Vocabulary: "vocabulary",
  Test: "test",
  Video: "video",
  Grammar: "grammar",
  Speaking: "speaking",
  Reading: "reading",
  Writing: "reading",
};

export const BlockView = observer(
  ({ block, order, status, onClick }: BlockViewProps): JSX.Element | null => {
    const theme = useTheme();

    const { name, type } = block;

    const iconType = iconTypeMap[String(type?.code)] || iconTypeMap.default;

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconWrapper status={status}>
          <Icon
            sizeSquareIcon={16}
            type={iconType}
            stroke={theme.palette.custom.colorVariant[status]}
          />
        </IconWrapper>

        <StatusTypography
          variant="regularText"
          status={status}
          onClick={onClick}
        >
          {`${order}. ${name}`}
        </StatusTypography>
      </Box>
    );
  }
);
