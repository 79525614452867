import React, { ChangeEvent, useCallback, useContext } from 'react';

import { useTranslation } from "react-i18next";

import { observer } from 'mobx-react';

import styles from './Comment.module.scss';
import { Text } from '../../Text';
import { UserRoleContext } from '../utils/context';
import { UserRole } from '../utils/types';

interface CommentProps {
  comment?: string | null;
	onChangeComment?: (_: string) => void;
}

export const Comment = observer((props: CommentProps) => {
	const {
    comment,
		onChangeComment
  } = props;

	const { t } = useTranslation();

	const { currentRole } = useContext(UserRoleContext);

	const isTeacher = currentRole !== UserRole.STUDENT

	const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		onChangeComment?.(event.target.value);
	}, [onChangeComment]);

  if (!comment && !isTeacher) {
		return null;
	}

  return (
		<div className={styles["container"]}>
			<Text variant="text-2-regular" color="base-d">
				{t("CreativeTasks:TeacherComment")}
			</Text>
			{isTeacher && (
				<textarea
					className={styles['input']}
					onChange={handleChange}
					value={comment ?? ""}
					placeholder="Вы можете оставить комментарий к работе здесь"
				/>
			)}
			{!isTeacher && (
				<div className={styles["comment"]}>
					<Text variant="text-2-regular" color="blue">
						{comment}
					</Text>
				</div>
			)}
		</div>
  );
})
