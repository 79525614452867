/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { types, SnapshotOut, Instance } from "mobx-state-tree";

export const ReportTestModel = types.model("ReportTest", {
  id: types.identifier,
  name: types.maybeNull(types.string),
  groupId: types.maybeNull(types.number),
});

type ReportTestType = Instance<typeof ReportTestModel>;
export interface ReportTest extends ReportTestType {}
type ReportTestSnapshotType = SnapshotOut<typeof ReportTestModel>;
export type ReportTestSnapshot = ReportTestSnapshotType;
