import { Typography } from "@mui/material";

import { FlexList } from "../../components/FlexList";

interface TranslationProps {
  translations: string[];
}

export const Translation = ({
  translations,
}: TranslationProps): JSX.Element | null => {
  return (
    <FlexList
      gap={0}
      items={translations}
      renderItem={(translation, index) => (
        <Typography key={index} variant="regularText">
          {`${index + 1}. ${translation.split(", ").slice(0, 3).join(", ")}`}
        </Typography>
      )}
    />
  );
};
