/* eslint-disable import/no-extraneous-dependencies */
import { forwardRef } from "react";

import {
  FormControl,
  TextFieldProps,
  Theme,
  Typography,
  SxProps,
} from "@mui/material";

import { TrimInput } from "components/TrimInput";

type FormInputProps = {
  label: string;
  containerStyles?: SxProps<Theme>;
} & TextFieldProps;

export const FormInput = forwardRef(
  (
    {
      label,
      required = false,
      disabled = false,
      containerStyles,
      ...other
    }: FormInputProps,
    ref
  ): JSX.Element | null => {
    return (
      <FormControl
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "1rem",
          ...containerStyles,
        }}
      >
        <Typography variant="h3" sx={{ width: "120px" }}>
          {label}
          {required && !disabled && (
            <Typography component="span" color="warning.main">
              *
            </Typography>
          )}
        </Typography>
        <TrimInput
          {...other}
          ref={ref}
          variant="standard"
          fullWidth
          disabled={disabled}
          sx={{
            borderBottom: disabled ? "initial" : "1px solid custom.grey.light",
            "&.Mui-disabled": {
              color: "custom.grey.main",
            },
          }}
        />
      </FormControl>
    );
  }
);
