import { Pagination, styled } from "@mui/material";

export const MaterialPagination = styled(Pagination)(({ theme }) => ({
  display: "flex",
  ".MuiPagination-ul": {
    border: `1px solid ${theme.palette.custom.grey.light}`,
    borderRadius: "0.6rem",
  },
  button: {
    margin: theme.spacing(0, "auto"),
    color: theme.palette.primary.main,
    padding: theme.spacing(4),
  },
}));
