import { useCallback, useEffect, useMemo, useState } from "react";

// TODO Доделать
// import { useLocation } from "react-router";

import {
  TranslateModeContext,
  TranslateModeContextType,
} from "../../context/TranslateModeContext";

interface TranslateModeProviderProps {
  children: JSX.Element[] | JSX.Element | null;
}

export const TranslateModeProvider = ({
  children,
}: TranslateModeProviderProps): JSX.Element | null => {
  // const location = useLocation();

  const [translateMode, setTranslateMode] = useState(false);

  // useEffect(() => {
  //   setTranslateMode(false);
  // }, [location]);

  const toggleTranslateMode = useCallback(() => {
    setTranslateMode((prev) => !prev);
  }, []);

  const value = useMemo<TranslateModeContextType>(
    () => ({
      translateMode,
      toggleTranslateMode,
    }),
    [toggleTranslateMode, translateMode]
  );

  return (
    <TranslateModeContext.Provider value={value}>
      {children}
    </TranslateModeContext.Provider>
  );
};
