import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MobileMenuWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  paddingTop: "8px",
  paddingLeft: "2.5rem",
}));
