import { forwardRef } from "react";

import { Input, InputAdornment, InputProps } from "@mui/material";

import { Icon } from "../Icon";

export const Search = forwardRef((props: InputProps, ref): JSX.Element => {
  return (
    <Input
      placeholder="Search"
      ref={ref}
      {...props}
      startAdornment={
        <InputAdornment position="start">
          <Icon type="search" width={24} height={24} />
        </InputAdornment>
      }
    />
  );
});
