import { Theme as ThemeProps, useTheme } from "@mui/material";

import { CardClassProps } from "components/CardClass";
import {
  IconContainer,
  TitleContainer,
  WrapperIconTitleContainer,
  UnitLessonContainer,
  LessonContainer,
  UnitContainer,
  ActionContainer,
} from "components/CardClass/styledComponents";
import { Icon } from "components/Icon";
import { JetLink } from "components/JetLink";

export interface CardClassDesktopProps {
  iconColor: CardClassProps["iconColor"];
  variant: CardClassProps["variant"];
  iconType: CardClassProps["iconType"];
  module: CardClassProps["module"];
  lesson: CardClassProps["lesson"];
  action: React.ReactElement;
}

export interface CardClassContainerProps {
  theme: ThemeProps;
  variant: CardClassDesktopProps["variant"];
  iconColor?: CardClassDesktopProps["iconColor"];
}

export const CardClassDesktop = ({
  iconColor,
  variant,
  iconType,
  module,
  lesson,
  action,
}: CardClassDesktopProps): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <WrapperIconTitleContainer>
        <IconContainer iconColor={iconColor} theme={theme} variant={variant}>
          <Icon type={iconType} width={22} height={22} />
        </IconContainer>

        <TitleContainer variant="h3Underlined">
          <JetLink to={`/my-courses/${module.id}`}>{module.title}</JetLink>
        </TitleContainer>
      </WrapperIconTitleContainer>

      <UnitLessonContainer>
        <LessonContainer variant="h3">{lesson.titleLesson}</LessonContainer>
        <UnitContainer variant="h5">{lesson.titleUnit}</UnitContainer>
      </UnitLessonContainer>

      <ActionContainer>{action}</ActionContainer>
    </>
  );
};
