import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { DatePicker } from "components/DatePicker";

import { ActiveGroupsContainer } from "./styledComponents/ActiveGroupsContainer";
import { DateRange } from "../../types";

export interface AttendanceReportsDateRangeFilterProps {
  dateRange?: DateRange;
  onDateRangeChange?: (_: DateRange) => void;
}

export const AttendanceReportsDateRangeFilter = observer(
  ({
    dateRange = { startDate: null, endDate: null },
    onDateRangeChange,
  }: AttendanceReportsDateRangeFilterProps): JSX.Element => {
    const { t } = useTranslation();

    const handleChange = (name: "start" | "end") => (date: Date | null) => {
      onDateRangeChange?.({
        ...dateRange,
        [`${name}Date`]: date,
      });
    };

    return (
      <ActiveGroupsContainer>
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <DatePicker
            value={dateRange?.startDate ?? null}
            onChange={handleChange("start")}
            label={t("GroupFilter:StartDate")}
          />

          <DatePicker
            value={dateRange?.endDate ?? null}
            onChange={handleChange("end")}
            label={t("GroupFilter:EndDate")}
          />
        </Box>
      </ActiveGroupsContainer>
    );
  }
);
