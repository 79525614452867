import { styled, Box } from "@mui/material";

export const Container = styled(Box)(
  ({ theme, width = "2.5rem", height = "2.5rem" }) => ({
    width: String(width),
    height: String(height),
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.primary.contrastText,
    border: `2px solid ${theme.palette.primary.contrastText}`,
    filter: `drop-shadow(2px 3px 6px rgba(0, 0, 0, 0.08))`,
  })
);
