import { Typography, Theme as ThemeProps, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  useCurrentBreakpoint,
  breakpoints,
} from "@packages/shared/themes/breakpoints/breakpoints";
import { Lesson } from "@packages/store/models/Lesson/LessonModel";

import { Button } from "components/Button";
import { CardClassDesktop } from "components/CardClassDesktop";
import { CardClassMobile } from "components/CardClassMobile";
import { IconType } from "components/Icon";
import { JetLink } from "components/JetLink";

import {
  ContentContainer,
  CardClassContainer,
  CancelMessageContainer,
} from "./styledComponents";

export interface CardClassProps {
  module: ModuleProps;
  lesson: LessonProps;
  variant: "default" | "success" | "cancel";
  iconType: IconType;
  iconColor: string;
}

export interface CardClassContainerProps {
  theme: ThemeProps;
  variant: CardClassProps["variant"];
  iconColor?: CardClassProps["iconColor"];
}

export interface ModuleProps {
  id: string;
  title: string;
  status: string;
  url: string;
}

export interface LessonProps {
  titleLesson: string;
  titleUnit: string;
  status: Lesson["status"];
}

export const CardClass = ({
  module,
  lesson,
  variant,
  iconType,
  iconColor,
}: CardClassProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const breakpoint = useCurrentBreakpoint();
  const variantRender = {
    success: module.url ? (
      <JetLink to={module.url} external newTab>
        <Button variant="secondary">
          <Typography variant="button">
            {t(`CardClass:WatchRecording`)}
          </Typography>
        </Button>
      </JetLink>
    ) : (
      <Typography variant="button" color="success.dark">
        {t(`LessonStatuses:${lesson.status}`)}
      </Typography>
    ),
    cancel: (
      <CancelMessageContainer>
        <Typography variant="smallTextUnderlined">
          {t(`LessonStatuses:${lesson.status}`)}
        </Typography>
      </CancelMessageContainer>
    ),
    default: module.url ? (
      <JetLink to={module.url} external newTab>
        <Button variant="primary">
          <Typography variant="button">{t(`CardClass:Join`)}</Typography>
        </Button>
      </JetLink>
    ) : (
      <Typography variant="button" color="custom.grey.main">
        {t(`LessonStatuses:${lesson.status}`)}
      </Typography>
    ),
  };

  const cards: { [key in breakpoints["types"]]: React.ReactElement } = {
    zeroScreen: (
      <CardClassMobile
        iconColor={iconColor}
        variant={variant}
        iconType={iconType}
        module={module}
        lesson={lesson}
        action={variantRender[variant]}
      />
    ),
    mobile: (
      <CardClassMobile
        iconColor={iconColor}
        variant={variant}
        iconType={iconType}
        module={module}
        lesson={lesson}
        action={variantRender[variant]}
      />
    ),
    tablet: (
      <CardClassMobile
        iconColor={iconColor}
        variant={variant}
        iconType={iconType}
        module={module}
        lesson={lesson}
        action={variantRender[variant]}
      />
    ),
    laptop: (
      <CardClassDesktop
        iconColor={iconColor}
        variant={variant}
        iconType={iconType}
        module={module}
        lesson={lesson}
        action={variantRender[variant]}
      />
    ),
    desktop: (
      <CardClassDesktop
        iconColor={iconColor}
        variant={variant}
        iconType={iconType}
        module={module}
        lesson={lesson}
        action={variantRender[variant]}
      />
    ),
  };

  return (
    <CardClassContainer variant={variant} theme={theme}>
      <ContentContainer>{cards[breakpoint]}</ContentContainer>
    </CardClassContainer>
  );
};
