import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardClassContainerProps } from "../CardClass";

export const CardClassContainer = styled(Box)(
  ({ theme, variant }: CardClassContainerProps) => {
    const colorVariantes = {
      default: theme.palette.custom.grey?.["menu-tile-inactive"],
      success: theme.palette.success.light,
      cancel: theme.palette.warning.light,
    };

    return {
      backgroundColor: colorVariantes[variant],
      maxWidth: "54rem",
      minWidth: "13rem",
      padding: theme.spacing(4),
      borderRadius: "0.5rem",
    };
  }
);
