import { RootStore } from "@packages/store";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

export const formatGroup = (group: LearningGroup, store: RootStore) => {
  const { teacherRate, language } = store;
  const { currentTeacher: teacher } = group;

  const rate = teacher && teacherRate.getLastByGroupId(group.id);
  const lang = rate && language.getById(rate.languageId || "");
  let classGroup: any;
  try {
    classGroup = group.relatedClassGroups?.[0];
  } catch {
    classGroup = undefined;
  }
  let classType = "-";
  if (classGroup?.type === "webinar") {
    classType = "online";
  } else if (classGroup?.type === "face2face") {
    classType = "ff";
  }

  return {
    id: group.id,
    name: group.name,
    classType: classType ?? "-",
    teacher: teacher?.fullName,
    lang: lang?.shortName ?? "-",
    // nativeSpeaker: group.nativeSpeaker ? "ДА" : "НЕТ",
    rate: rate?.amount ?? "-",
  };
};

export type FormattedGroup = ReturnType<typeof formatGroup>;
