import { Tag } from "@packages/store/models/Tag/Tag";

export const formatTag = (tag: Tag) => {
  return {
    id: tag.id,
    name: tag.name,
  };
};

export type FormattedTag = ReturnType<typeof formatTag>;
