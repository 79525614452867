import { ExerciseResultError } from "@packages/store/models/ExerciseResult/ExerciseResultError";

export function getUniqueErrors(errors: ExerciseResultError[]): ExerciseResultError[] {
  const duplicates = new Set()
  return errors.filter((error) => {
    const { startPosition, endPosition, errorTypeId } = error;
    const id = `${startPosition}-${endPosition}-${errorTypeId}`;
    if (duplicates.has(id)) {
      return false
    }
    duplicates.add(id);
    return error;
  }).filter(Boolean);
}
