import { CompanyReport } from "@packages/store/models/CompanyReport/CompanyReport";

export const formatCompanyReport = (report: CompanyReport) => {
  return {
    name: report.company.name,
    totalGroups: report.totalGroups,
    totalLessons: report.totalLessons,
    totalAcademicHours: report.totalAcademicHours,
    totalPayment: report.totalPayment,
    averagePrice: report.averagePrice,
  };
};

export type FormattedCompanyReport = ReturnType<typeof formatCompanyReport>;
