import { Typography, useTheme } from "@mui/material";
import { BoxWithLegend } from "../shared/BoxWithLegend";

interface ChatHelperProps {
  legend: string;
  text?: string | null;
  variants?: string[];
  currentVariant?: number;
}

export const ChatHelper = ({
  legend,
  text,
  variants = [],
  currentVariant = 0,
}: ChatHelperProps): JSX.Element | null => {
  const theme = useTheme();

  return (
    <BoxWithLegend
      legend={legend}
      sx={{
        maxWidth: "32rem",
        m: "0 auto 1rem",
        color: theme.palette.custom.grey.main,
      }}
    >
      <Typography>{text || variants[currentVariant]}</Typography>
    </BoxWithLegend>
  );
};
