import { styled, TabsProps, Tabs } from "@mui/material";

export const StyledTabs = styled((props: TabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(() => ({
  borderTopRightRadius: "inherit",
  borderTopLeftRadius: "inherit",

  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
  },
}));
