import { Instance, SnapshotOut, types } from "mobx-state-tree";

export enum ExerciseErrorType {
  COMMENT = 0,
  GRAMMAR = 1,
  LEXICAL = 2,
}

export const ExerciseResultErrorModel = types
  .model("ExerciseResultError", {
    currentId: types.optional(types.string, ""),
    startPosition: types.optional(types.number, 0),
    endPosition: types.optional(types.number, 0),
    comment: types.optional(types.string, ""),
    errorTypeId: types.optional(types.number, 0),
  });

type ExerciseResultErrorModelType = Instance<typeof ExerciseResultErrorModel>;
type ExerciseResultErrorModelTypeSnapshotType = SnapshotOut<typeof ExerciseResultErrorModel>;

export interface ExerciseResultError extends ExerciseResultErrorModelType {}
export type ExerciseResultErrorSnapshot = ExerciseResultErrorModelTypeSnapshotType;