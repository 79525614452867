import { Link, LinkProps, styled } from "@mui/material";

import { theme } from "@packages/shared/themes/default";

interface StyledMuiLinkProps extends LinkProps {
  colorVariant?: "light" | "info" | "primary";
}

const colorVariants = {
  light: theme.palette.primary.contrastText,
  info: theme.palette.info.main,
  primary: theme.palette.primary.main,
};

export const StyledMuiLink = styled(
  (props: StyledMuiLinkProps) => (
    <Link variant="regularText" target="_blank" underline="always" {...props} />
  ),
  { shouldForwardProp: (prop) => prop !== "colorVariant" }
)<StyledMuiLinkProps>(({ colorVariant = "info" }) => ({
  display: "inline-block",
  color: colorVariants[colorVariant],
  textDecorationColor: colorVariants[colorVariant],
}));
