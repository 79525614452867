import { useMemo, useState } from "react";

import {
  DragDropContext,
  DragDropContextType,
  DragSize,
} from "../../context/DragDropContext";

interface DragDropProviderProps {
  children: JSX.Element[] | JSX.Element | null;
}

export function DragDropProvider({ children }: DragDropProviderProps) {
  const [dragSize, setDragSize] = useState<DragSize>({
    width: 0,
    height: 0,
  });

  const value = useMemo<DragDropContextType>(
    () => ({
      dragSize,
      setDragSize,
    }),
    [dragSize]
  );

  return (
    <DragDropContext.Provider value={value}>
      {children}
    </DragDropContext.Provider>
  );
}
