import { Box, Typography, Checkbox, FormControlLabel } from "@mui/material";

import { AvatarPerson } from "components/AvatarPerson";
import { ModalAttendanceStudent } from "components/ModalAttendance/ModalAttendance";

interface StudentCheckboxProps {
  student: ModalAttendanceStudent;
  checked: boolean;
  onCheck: (value: number) => void;
  disabled?: boolean;
}

export const StudentCheckbox = ({
  student: { value, label },
  checked,
  onCheck,
  disabled = false,
}: StudentCheckboxProps): JSX.Element | null => {
  return (
    <Box
      key={value}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "19.063rem",
        cursor: "pointer",
      }}
    >
      <FormControlLabel
        disabled={disabled}
        control={<Checkbox checked={checked} onChange={() => onCheck(value)} />}
        label={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "0.75rem",
              }}
            >
              <AvatarPerson
                name={label}
                width="1.875rem"
                height="1.875rem"
                fontSize="h5"
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="h4"
                color={disabled ? "text.secondary" : "text.primary"}
              >
                {label}
              </Typography>
            </Box>
          </Box>
        }
      />
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "0.844rem",
        }}
      >
        <ButtonBase onClick={() => onComment(value)}>
          <Icon type="chat" width={15} height={15} stroke="primary.light" />
        </ButtonBase>
      </Box> */}
    </Box>
  );
};
