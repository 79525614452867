import { styled, Typography, TypographyProps } from "@mui/material";

export const FormSubTitle = styled((props: TypographyProps) => (
  <Typography variant="regularText" {...props} />
))(({ theme }) => ({
  display: "block",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  color: theme.palette.info.main,

  // [theme.breakpoints.down("tablet")]: {
  //   margin: theme.spacing(1),
  // },
}));
