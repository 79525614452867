import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { AnswerDragLayer } from "../../../../../../components/AnswerDragLayer";
import { MatchingQuestion } from "../../../../../../components/MatchingQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Word = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions } = exercise;

    const { getQuestion, swapAnswer } = ensure(answer);

    return (
      <>
        <AnswerDragLayer />
        <ObserverFlexList
          gap={4}
          items={questions}
          renderItem={(question: Question) => (
            <MatchingQuestion
              key={question.id}
              question={question}
              answerData={ensure(getQuestion(question.id))}
              swapAnswer={swapAnswer}
              fullSizeAnswer
            />
          )}
        />
      </>
    );
  }
);
