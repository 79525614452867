/* eslint-disable @typescript-eslint/no-shadow */
import { SyntheticEvent, useEffect } from "react";

import {
  Autocomplete,
  Box,
  CircularProgress,
  ListItem,
  TextField,
} from "@mui/material";
import { useStores } from "@packages/store/models";
import { uniqBy } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { getAutocompleteFieldValue } from "../../helpers/getAutocompleteFieldValue";
import { useCancelableFetch } from "../../hooks";
import { ValueWithLabel } from "../../types";

export interface TeachersAutcompleteFilterProps {
  teacherId: string;
  onChange?: (_: string) => void;
  error?: boolean;
  helperText?: string;
}

export const TeachersAutocompleteFilter = observer(
  ({
    teacherId,
    onChange,
    error,
    helperText,
  }: TeachersAutcompleteFilterProps): JSX.Element => {
    const { t } = useTranslation();
    const { teacher, api } = useStores();

    const { items: teachers } = teacher;

    const { fetch: fetchTeachers, loading: loadingTeachers } =
      useCancelableFetch();

    const uniqueTeachers = uniqBy(teachers, "fullName");

    const options: ValueWithLabel[] = uniqueTeachers
      .map((teacher) => ({
        value: teacher.actualId,
        label: teacher.fullName,
      }))
      .sort((prev, next) => (prev.label > next.label ? 1 : -1));

    const handleChange = (
      _event: SyntheticEvent<Element, Event>,
      item: ValueWithLabel | string | null
    ) => {
      const { value = "" } = (item ?? {}) as ValueWithLabel;
      onChange?.(value ?? "");
    };

    useEffect(() => {
      fetchTeachers((token) => api.getTeachers(token));
    }, []);

    return (
      <Box position="relative">
        <Autocomplete
          id="teachers"
          openOnFocus
          onChange={handleChange}
          noOptionsText={t("TeachersAutocompleteFilter:EnterTeacherName")}
          options={options}
          value={getAutocompleteFieldValue(options, teacherId)}
          sx={{ minWidth: 320, marginTop: "0.5rem" }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              error={error}
              helperText={helperText}
              variant="standard"
              placeholder={t("TeachersAutocompleteFilter:SelectTeacher")}
            />
          )}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.value}>
              {option.label}
            </ListItem>
          )}
        />
        {loadingTeachers && (
          <Box
            position="absolute"
            width="0.5rem"
            height="0.5rem"
            sx={{ right: "1rem", top: "calc(50% - 0.5rem)" }}
          >
            <CircularProgress size={25} />
          </Box>
        )}
      </Box>
    );
  }
);
