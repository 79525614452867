import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { PassingContentModel } from "./PassingContentModel";

export const PassingModel = types.model({
  id: types.identifier,
  content: types.maybeNull(PassingContentModel),
  mark: types.maybeNull(types.number),
  maxMark: types.maybeNull(types.number),
  successful: types.maybeNull(types.boolean),
});

type PassingModelType = Instance<typeof PassingModel>;
export type PassingModel = PassingModelType;
type PassingModelTypeSnapshotType = SnapshotOut<typeof PassingModel>;
export type PassingModelSnapshot = PassingModelTypeSnapshotType;
