import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const TranslationModel = types.model({
  locale: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  shortName: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
});

type TranslationType = Instance<typeof TranslationModel>;
export type Translation = TranslationType;
type TranslationSnapshotType = SnapshotOut<typeof TranslationModel>;
export type TranslationSnapshot = TranslationSnapshotType;
export const createTranslationDefaultModel = () =>
  types.optional(TranslationModel, {} as TranslationSnapshot);
