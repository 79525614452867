/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-shadow */
import { Fragment } from "react";

import { Typography, useTheme, SxProps, Theme } from "@mui/material";
import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { Question } from "@packages/store/models/Question/Question";
import { List } from "../../components/List";
import { observer } from "mobx-react-lite";
import { MovingDropzone } from "../../components/MovingDropzone";
import { QuestionOrder } from "../../components/QuestionOrder";
import { lineHeight } from "../../constants/styles";
import { createQuestionContent } from "../../helpers/createQuestionContent";
import { getItemStatus } from "../../helpers/getItemStatus";

import { AnswerView } from "../AnswerView";

export interface DropItem {
  id: string;
  place: number;
}

export interface MovingQuestionProps {
  question: Question;
  answerData: AnswerData;
  order?: number;
}

export const MovingQuestion = observer(
  ({
    question,
    answerData,
    order,
  }: MovingQuestionProps): JSX.Element | null => {
    const theme = useTheme();

    const { id, answerPlaces, text, image, isWithImage } = question;

    const { getAnswerByPlace } = answerData;

    const renderElement = (index: number): JSX.Element | null => {
      const place = answerPlaces[index];

      const answer = getAnswerByPlace(place);

      if (answer) {
        const { text, isChecked, remove } = answer;

        return (
          <AnswerView
            title={String(text)}
            status={isChecked ? getItemStatus(answer) : "current"}
            {...(!isChecked && {
              onRemove: remove,
            })}
          />
        );
      }

      return <MovingDropzone question={id} place={place} />;
    };

    const content = createQuestionContent(
      text,
      answerPlaces.length,
      (index) => answerPlaces[index],
      renderElement,
      image || undefined
    );

    const withoutImageStyles: SxProps<Theme> = {
      ...theme.typography.regularText,
      display: "inline",
      lineHeight,
    };

    const withImageStyles: SxProps<Theme> = {
      ...theme.typography.regularText,
      display: "flex",
      flexDirection: "column",
      gap: 3,
      lineHeight,
    };

    return (
      <QuestionOrder order={order} status={getItemStatus(answerData)}>
        <List
          items={content}
          renderItem={({ text, image, element }, index) => (
            <Fragment key={index}>
              {text && (
                <Typography
                  variant="regularText"
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              )}{" "}
              {image && <img src={image} alt="blu" height="110px" />} {element}
            </Fragment>
          )}
          containerStyles={isWithImage ? withImageStyles : withoutImageStyles}
        />
      </QuestionOrder>
    );
  }
);
