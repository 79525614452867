import "./scss/_main.scss";

import { useCallback, useState } from "react";

import { observer } from "mobx-react-lite";
import { I18nextProvider } from "react-i18next";

import { ExerciseResultStatus } from "@packages/store/models/ExerciseResult/ExerciseResult";

import { BlockWrapper } from "./components/BlockWrapper";
import { TaskDetails } from "./components/TaskDetails";
import { TaskList } from "./components/TaskList";
import { TasksFilters } from "./components/TasksFilters";
import { Title } from "./components/Title";
import i18n from "./locales/i18n";

// Если передать taskId то сразу откроется модака для этого задания,
// это передается из вне для того чтобы работать в разных средах (next и react-router)
interface CreativeTasksProps {
  taskId: string | undefined;
  setTaskId: (taskId: string | undefined) => void;
}

export const CreativeTasks = observer((props: CreativeTasksProps) => {
  const { taskId, setTaskId } = props;

  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState<
    ExerciseResultStatus | undefined
  >();

  const clearCurrentId = useCallback(() => {
    setTaskId(undefined);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <BlockWrapper>
        <Title />
        <TasksFilters
          name={nameFilter}
          onNameChange={setNameFilter}
          status={statusFilter}
          onStatusChange={setStatusFilter}
        />
        <TaskList
          nameFilter={nameFilter}
          statusFilter={statusFilter}
          onShowDetails={setTaskId}
        />
        <TaskDetails id={taskId} onClose={clearCurrentId} />
      </BlockWrapper>
    </I18nextProvider>
  );
});
