import { useEffect } from "react";

import { Box } from "@mui/material";
import { useStores } from "@packages/store";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { ContentContainer } from "components/ContentContainer";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch, useFormSyncedWithURLParams } from "hooks";
import { ROUTES } from "router/constants";

import { TeachersFilter, TeachersTable } from "./components";
import {
  // filterByEmail,
  filterByName,
  sortByName,
  // filterByLangs,
} from "./helpers/filterTeachers";

export interface TeachersFilters {
  name: string;
  // email: string;
  // langs: string[];
}

// const getShortLangsList = (teachers: Teacher[]) => {
//   return uniq(
//     teachers.flatMap((teacher) => teacher.langs).map((lang) => lang.shortName)
//   );
// };

export const Teachers = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { teacher: teacherStore, api, loading } = useStores();

  const { fetch: fetchTeachers } = useCancelableFetch();

  const methods = useFormSyncedWithURLParams<TeachersFilters>(
    {
      mode: "onChange",
      defaultValues: { name: "" /* email: "", langs: [] */ },
    },
    ROUTES.TEACHERS
  );

  const { name /* email, langs */ } = methods.watch();

  // const listLangs = getShortLangsList(mockTeachers).map((lang) => ({
  //   label: lang,
  //   value: lang,
  // }));

  const filteredTeachers = computed(
    () =>
      teacherStore.uniqueTeachers.filter(filterByName(name)).sort(sortByName)
    // .filter(filterByEmail(email))
    // .filter(filterByLangs(langs))
  ).get();

  useEffect(() => {
    fetchTeachers((token) => api.getTeachers(token));
  }, [api, fetchTeachers]);

  return (
    <Loading loading={loading}>
      <ContentContainer>
        <PageBlock title={t("Teachers:Title")}>
          <Box sx={{ mb: 4 }} />
          <TeachersFilter methods={methods} /* listLangs={listLangs} */ />
          <TeachersTable teachers={filteredTeachers} />
          <Outlet />
        </PageBlock>
      </ContentContainer>
    </Loading>
  );
});
