import { Badge, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useCurrentBreakpoint } from "@packages/shared/themes/breakpoints/breakpoints";
import { NotificationsStore } from "@packages/store/models/Notifications/NotificationsStore";

import { Icon } from "components/Icon/Icon";
import { Logo } from "components/Logo";
import { MobileMenu } from "components/MobileMenu/MobileMenu";
import { NotificationsPopover } from "components/NotificationsPopover";
import { UserMenu } from "components/UserMenu/UserMenu";
import { IUserMenuItem } from "components/UserMenu/UserMenuData";
import { usePopover } from "hooks/usePopover";
import { MenuItem } from "types";

import { LanguageSwitcher } from "./LanguageSwitcher";
import { ContentContainer } from "./styledComponents/ContentContainer";
import { HeaderContainer } from "./styledComponents/HeaderContainer";
import { IconsContainer } from "./styledComponents/IconsContainer";
import { LeftContainer } from "./styledComponents/LeftContainer";
import { RightContainer } from "./styledComponents/RightContainer";

export interface HeaderProps {
  firstName?: string | null;
  lastName?: string | null;
  isTeacher?: boolean | null;
  isHR?: boolean | null;
  isDistributor?: boolean | null;
  isCoordinator?: boolean | null;
  isPayrollAccountant?: boolean | null;
  userMenuItems: IUserMenuItem[];
  mainMenu: MenuItem[];
  notifications?: NotificationsStore;
  onVideoClick?: () => void;
}

export const ROLES = {
  STUDENT: "Student",
  TEACHER: "Teacher",
  HR: "HR",
  DISTRIBUTOR: "Distributor",
  COORDINATOR: "Coordinator",
  PAYROLL_ACCOUNTANT: "PayrollAccountant",
};

export const Header = observer(
  ({
    firstName,
    lastName,
    mainMenu,
    userMenuItems,
    isTeacher,
    isHR,
    isDistributor,
    isCoordinator,
    isPayrollAccountant,
    notifications,
    onVideoClick,
  }: HeaderProps): JSX.Element => {
    const {
      anchorEl: notificationsAnchorEl,
      handleClose: handleCloseNotificationsPopover,
      handleOpen: handleOpenNotificationsPopover,
    } = usePopover();

    const { t } = useTranslation();

    const getRole = (): string => {
      if (isTeacher) {
        return ROLES.TEACHER;
      }
      if (isHR) {
        return ROLES.HR;
      }
      if (isDistributor) {
        return ROLES.DISTRIBUTOR;
      }
      if (isCoordinator) {
        return ROLES.COORDINATOR;
      }
      if (isPayrollAccountant) {
        return ROLES.PAYROLL_ACCOUNTANT;
      }
      return ROLES.STUDENT;
    };
    const breakpoint = useCurrentBreakpoint();

    const onCloseNotificationsPopover = async () => {
      handleCloseNotificationsPopover();
      await notifications?.loadNotifications();
    };

    return (
      <HeaderContainer>
        <ContentContainer>
          <LeftContainer>
            <MobileMenu mainMenu={mainMenu} onVideoClick={onVideoClick} />
            <Link to="/">
              <Logo
                color="#fff"
                width={breakpoint === "desktop" ? 128 : 112}
                height={breakpoint === "desktop" ? 32 : 28}
              />
            </Link>
          </LeftContainer>

          <RightContainer>
            <IconsContainer>
              <LanguageSwitcher />
              {breakpoint !== "zeroScreen" && (
                <Badge
                  sx={{ ".MuiBadge-dot": { backgroundColor: "#FF6161" } }}
                  variant="dot"
                  invisible={!notifications?.unreadCount}
                  onClick={handleOpenNotificationsPopover}
                >
                  <Icon type="mail" width={22} height={19} />
                </Badge>
              )}
              <Icon
                type="youtube"
                width={22}
                height={19}
                onClick={onVideoClick}
              />
            </IconsContainer>

            <NotificationsPopover
              anchorEl={notificationsAnchorEl}
              onClose={onCloseNotificationsPopover}
              notifications={notifications!}
            />

            {breakpoint !== "zeroScreen" ? (
              <UserMenu
                name={`${firstName} ${lastName}`}
                userRole={t(`Menu:${getRole()}`)}
                menuItems={userMenuItems}
              />
            ) : (
              <Box display="flex" alignItems="center" gap="1.5rem">
                <Badge
                  sx={{
                    ".MuiBadge-dot": { backgroundColor: "#FF6161" },
                    cursor: "pointer",
                  }}
                  variant="dot"
                  invisible={!notifications?.unreadCount}
                  onClick={handleOpenNotificationsPopover}
                >
                  <Icon type="mail" width="1.7rem" height="1.7rem" />
                </Badge>
                <UserMenu
                  name={`${firstName} ${lastName}`}
                  userRole={t(`Menu:${getRole()}`)}
                  menuItems={userMenuItems}
                  extended={false}
                />
              </Box>
            )}
          </RightContainer>
        </ContentContainer>
      </HeaderContainer>
    );
  }
);
