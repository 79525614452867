import { styled, Box } from "@mui/material";

interface StyledMovingDropzoneProps {
  expandable: boolean;
  isActive: boolean;
  width?: number;
}

export const StyledMovingDropzone = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "expandable" && prop !== "isActive" && prop !== "width",
})<StyledMovingDropzoneProps>(({ theme, expandable, isActive, width }) => ({
  ...theme.typography.regularText,
  display: "inline-block",
  width: width || 100,
  padding: theme.spacing(2, 3),
  color: theme.palette.background.paper,
  backgroundColor: theme.palette.background.paper,
  border: "1px solid",
  borderColor: theme.palette.custom.grey.light,
  borderRadius: theme.spacing(3),
  userSelect: "none",
  transition: theme.transitions.create(["width", "borderLeftColor"]),
  marginRight: "0.2rem",

  ...(expandable &&
    !isActive && {
      width: "1rem",
      borderTop: "none",
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
    }),
}));
