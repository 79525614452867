import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { OptionModel } from "./Option";

export const AnswerModel = types.model({
  id: types.identifier,
  options: types.optional(types.array(OptionModel), []),
  place: types.maybeNull(types.number),
  text: types.maybeNull(types.string),
});

type AnswerModelType = Instance<typeof AnswerModel>;
type AnswerModelSnapshotType = SnapshotOut<typeof AnswerModel>;

export type Answer = AnswerModelType;
export type AnswerSnapshot = AnswerModelSnapshotType;
