import { Box, Typography } from "@mui/material";

import { Checkbox } from "./index";

export interface CheckboxWordProps {
  checked?: boolean;
  title?: string | null;
  onChange?: (value: boolean) => void;
}

export function CheckboxWord({
  title,
  checked,
  onChange,
}: CheckboxWordProps): JSX.Element {
  const handleWordClick = () => {
    onChange?.(!checked);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.5rem",
      }}
    >
      <Checkbox
        checked={!!checked}
        onChange={handleWordClick}
        size="medium"
        sx={{
          padding: 0,
        }}
      />
      <Typography
        variant="h4"
        sx={{
          cursor: "pointer",
        }}
        onClick={handleWordClick}
      >
        {title}
      </Typography>
    </Box>
  );
}
