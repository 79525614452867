import { Box, Typography } from "@mui/material";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import { Icon } from "components/Icon";

export type EmptyTableType = "default" | "distributor" | "classes";

type EmptyTableProps = {
  columnsLength: number | undefined;
  type?: EmptyTableType;
};

export const EmptyTable = ({
  columnsLength,
  type = "default",
}: EmptyTableProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      {columnsLength && columnsLength > 1 ? (
        <tbody>
          <tr>
            <td colSpan={columnsLength}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "13.625rem",
                }}
              >
                <Icon width={40} height={40} type="empty" />
                <Typography variant="h6" sx={{ lineHeight: "22px" }}>
                  {t(`EmptyTable:FirstText${startCase(type)}`)}
                </Typography>
                <Typography variant="h6" sx={{ lineHeight: "22px" }}>
                  {t(`EmptyTable:SecondText${startCase(type)}`)}
                </Typography>
              </Box>
            </td>
          </tr>
        </tbody>
      ) : null}
    </>
  );
};
