import { Box, useTheme } from "@mui/material";
import {
  eachYearOfInterval,
  format,
  getYear,
  isSameYear,
  setYear,
} from "date-fns";

import { CalendarCell } from "./styledComponents";

interface CalendarYearsProps {
  currentDate: Date;
  onYearChange: (year: number) => void;
}

export const CalendarYears = ({
  currentDate,
  onYearChange,
}: CalendarYearsProps): JSX.Element | null => {
  const theme = useTheme();

  const years = eachYearOfInterval({
    start: setYear(currentDate, Math.floor(getYear(currentDate) / 10) * 10),
    end: setYear(currentDate, Math.floor(getYear(currentDate) / 10) * 10 + 11),
  });

  const yearBg = (year: Date) => {
    if (isSameYear(year, new Date())) {
      return `${theme.palette.primary.main}!important`;
    }

    if (isSameYear(year, currentDate)) {
      return `${theme.palette.info.main}!important`;
    }

    return "transparent";
  };
  const yearColor = (year: Date) => {
    if (isSameYear(year, currentDate) || isSameYear(year, new Date())) {
      return theme.palette.primary.contrastText;
    }

    return theme.palette.common.black;
  };

  return (
    <Box
      width="222px"
      height="222px"
      display="grid"
      gridTemplateColumns="repeat(3, 1fr)"
      gap="2px"
    >
      {years.map((date) => (
        <CalendarCell
          key={getYear(date)}
          isMonth
          onClick={() => onYearChange(getYear(date))}
          sx={{
            backgroundColor: yearBg(date),
            color: yearColor(date),
          }}
        >
          {format(date, "yyyy")}
        </CalendarCell>
      ))}
    </Box>
  );
};
