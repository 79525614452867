import { styled, Box, Typography } from "@mui/material";

import { HRWidget } from "components/HRWidget";

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  height: "100%",
  alignItems: "flex-start",
  maxWidth: "63.75rem",
  padding: theme.spacing(0, 4),
  margin: theme.spacing(0, "auto"),
  width: "100%",
}));

export const Card = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  border: `1px solid ${theme.palette.custom.grey.light}`,
  height: "4.5rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flex: "1 1 40%",
  marginBottom: "1.5rem",
  maxWidth: "48%",
  padding: "1rem",

  [theme.breakpoints.down("tablet")]: {
    maxWidth: "100%",
    width: "100%",
  },
  button: {
    maxWidth: "7.5rem",
  },
}));

export const CardsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",

  [theme.breakpoints.down("tablet")]: {
    flexDirection: "column",
  },
}));

export const Title = styled(Typography)(() => ({
  fontWeight: "700",
  fontSize: "1.5rem",
}));

export const CompanyName = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: "1.5rem",
  color: theme.palette.custom.grey.dark,
}));

export const Info = styled(Typography)(({ theme }) => ({
  fontSize: "1.125rem",
  fontWeight: 700,
  color: theme.palette.custom.grey.main,
  marginBottom: theme.spacing(5),
}));

export const HRWidgetStyled = styled(HRWidget)(({ theme }) => ({
  width: "32%",

  [theme.breakpoints.down("mobile")]: {
    width: "90%",
  },
}));

export const HRWidgetsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "space-between",

  [theme.breakpoints.down("mobile")]: {
    flexDirection: "column",
  },
}));

export const ModuleName = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "0.875rem",
  textDecoration: "underline",
  color: theme.palette.primary.dark,
}));
