import { useState } from "react";

import { Box } from "@mui/material";
import { useStores } from "@packages/store";
import { format } from "date-fns";
import take from "lodash/take";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { Icon } from "components/Icon";
import { StyledPaper } from "components/StyledPaper";
import { Table } from "components/Table";
import { TableCell } from "pages/shared/styledComponents";
import { FormattedRate } from "pages/TeacherDetails/helpers/formatRate";
import { StyledButton } from "pages/TeacherDetails/styledComponents";

import { StyledLangName } from "./styledComponents";

interface LangDetailsProps {
  languageId: string;
  rates: FormattedRate[];
  onRequestRateCreate: () => void;
  onRequestRateEdit: (rateId: string) => void;
}

export const LangDetails = observer(
  ({
    languageId,
    rates,
    onRequestRateCreate,
    onRequestRateEdit,
  }: LangDetailsProps): JSX.Element | null => {
    const { t } = useTranslation();

    const { language: languageStore } = useStores();
    const languageName = languageStore.getById(languageId)?.name;

    const [showHistory, setShowHistory] = useState(false);
    const toggleHistory = () => setShowHistory((p) => !p);

    const columns = [
      {
        Header: t("TeacherDetails:RatesTableClassType"),
        accessor: "classType",
        Cell: ({ value }: Cell<FormattedRate, string>) => (
          <TableCell>{value}</TableCell>
        ),
      },
      {
        Header: t("TeacherDetails:RatesTableAmount"),
        accessor: "amount",
        Cell: ({ value }: Cell<FormattedRate, string>) => (
          <TableCell color="custom.grey.main">{value}</TableCell>
        ),
      },
      {
        Header: t("TeacherDetails:RatesTableActiveFrom"),
        accessor: "activeFrom",
        Cell: ({ value }: Cell<FormattedRate, Date>) => (
          <TableCell color="custom.grey.main">
            {format(value, "dd.MM.yyyy")}
          </TableCell>
        ),
      },
      {
        Header: t("TeacherDetails:RatesTableEdit"),
        accessor: "edit",
        Cell: ({ row }: Cell<FormattedRate>) => (
          <TableCell onClick={() => onRequestRateEdit(row.original.id)}>
            <Icon type="classes" sizeSquareIcon={20} />
          </TableCell>
        ),
      },
    ];

    const filteredRates = computed(() => {
      if (showHistory) {
        return rates;
      }

      return take(rates, 2);
    }).get();

    return (
      <StyledPaper sx={{ p: "1rem", mb: "1rem" }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="0.8125rem"
        >
          <StyledLangName>{t(`Languages:${languageName}`)}</StyledLangName>
          {/* {lang.nativeSpeaker && (
          <Box p="3px 8px 5px" bgcolor="#F3F6FA" borderRadius="4px">
            <Typography variant="button" color="custom.grey.accentText">
              носитель языка
            </Typography>
          </Box>
        )} */}
        </Box>
        <Table
          data={filteredRates}
          columns={columns}
          count={10}
          containerType="blue"
        />
        <Box display="flex" alignItems="center" gap="1rem">
          <StyledButton variant="add" onClick={onRequestRateCreate}>
            <Icon type="bookAdd" sizeSquareIcon={16} />
            {t("TeacherDetails:RatesAddRate")}
          </StyledButton>
          <StyledButton variant="history" onClick={toggleHistory}>
            <Icon type="history" sizeSquareIcon={16} />
            {showHistory
              ? t("TeacherDetails:RatesHideHistory")
              : t("TeacherDetails:RatesShowHistory")}
          </StyledButton>
        </Box>
      </StyledPaper>
    );
  }
);
