import {
  Box,
  SelectChangeEvent,
  Stack,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useStores } from "@packages/store/models";
import {
  Lesson,
  statuses as lessonStatuses,
} from "@packages/store/models/Lesson/LessonModel";

import { Autocomplete } from "components/Autocomplete";
import { DateRange, DateRangePicker } from "components/DateRangePicker";
import { GroupsAutocompleteFilter } from "components/GroupsAutocompleteFilter";
import { FilterSelect } from "components/shared/FilterSelect";
import { useLessonFilter } from "pages/Classes/hooks";
import { TeachersAutocompleteFilter } from "pages/Teacher/components";

import { LessonsDownload } from "../LessonsDownload";

interface LessonFilterProps extends ReturnType<typeof useLessonFilter> {
  lessonsToDownload: Lesson[];
  showTeacherFilter: boolean;
}

export const LessonFilter = observer(
  ({
    dateRange,
    company,
    teacher,
    group,
    status,
    lessonsToDownload,
    showTeacherFilter,
    sampleByStatus,
    onlyLessonsWithoutGroup,
    onDateRangeChange,
    onCompanyChange,
    onGroupChange,
    onTeacherChange,
    onStatusChange,
    onSampleByStatusChange,
    onOnlyLessonsWithoutGroupChange,
  }: LessonFilterProps): JSX.Element | null => {
    const { t } = useTranslation();

    const stores = useStores();

    const { customerCompany: customerCompanyStore } = stores;

    const companies = [
      ...customerCompanyStore.items
        .map(({ id, name }) => ({
          value: id,
          label: String(name),
        }))
        .sort((prev, next) => prev.label.localeCompare(next.label)),
    ];

    const statuses = [
      {
        value: "",
        label: t("Classes:None"),
      },
      ...lessonStatuses.map((status) => ({
        value: status,
        label: t(`LessonStatuses:${status}`),
      })),
    ];

    const handleDateRangeChange = (newDateRange: DateRange) => {
      onDateRangeChange?.(newDateRange);
    };

    const handleCompanyChange = (newCompany: string) => {
      onCompanyChange?.(newCompany);

      if (!companies.find(({ value }) => value === group)) {
        onGroupChange?.("");
      }
    };

    const handleGroupChange = (newGroup: string) => {
      onGroupChange?.(newGroup);
    };

    const handleTeacherChange = (newTeacher: string) => {
      onTeacherChange?.(newTeacher);
    };

    const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
      if (typeof event.target.value === "string") {
        onStatusChange?.(event.target.value);
      }
    };

    return (
      <Box>
        <Stack direction="row" gap="1rem" alignItems="center" mb={4}>
          <FormControlLabel
            control={
              <Switch
                checked={sampleByStatus}
                onChange={(_, value) => onSampleByStatusChange(value)}
              />
            }
            label={
              <Typography variant="regularText">
                {t("Classes:SampleByStatus")}
              </Typography>
            }
          />

          <FormControlLabel
            control={
              <Switch
                checked={onlyLessonsWithoutGroup}
                onChange={(_, value) => onOnlyLessonsWithoutGroupChange(value)}
              />
            }
            label={
              <Typography variant="regularText">
                {t("Classes:OnlyLessonsWithoutGroup")}
              </Typography>
            }
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap="1rem"
        >
          <DateRangePicker
            dateRange={dateRange}
            onChange={handleDateRangeChange}
          />
          <LessonsDownload lessons={lessonsToDownload} />
        </Stack>

        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: 4,
            py: 3,
          }}
        >
          <Autocomplete
            value={company}
            options={companies}
            onChange={handleCompanyChange}
            placeholder={t("Classes:Company") ?? ""}
          />

          <GroupsAutocompleteFilter
            group={group}
            company={company}
            onChange={handleGroupChange}
          />

          {showTeacherFilter && (
            <TeachersAutocompleteFilter
              teacherId={teacher}
              onChange={handleTeacherChange}
            />
          )}

          <FilterSelect
            value={status}
            data={statuses}
            onChange={handleStatusChange}
            placeholder={t("Classes:Status")}
          />
        </Box>
      </Box>
    );
  }
);
