import { Instance, SnapshotOut, types } from "mobx-state-tree";

import { AnswerModel } from "./Answer";
import { AnswerPartModel } from "./AnswerPart";

export const QuestionRawModel = types.model({
  id: types.identifier,
  questionString: types.maybeNull(types.string),
  originalQuestionString: types.maybeNull(types.string),
  answers: types.array(AnswerModel),
  answerParts: types.array(AnswerPartModel),
});

type QuestionRawModelType = Instance<typeof QuestionRawModel>;
type QuestionRawModelSnapshotType = SnapshotOut<typeof QuestionRawModel>;

export type QuestionRaw = QuestionRawModelType;
export type QuestionRawSnapshot = QuestionRawModelSnapshotType;
