import { ChangeEvent, useMemo } from "react";

// eslint-disable-next-line import/no-extraneous-dependencies
import { SxProps } from "@mui/material";

import { StyledInputAutoresize } from "./styledComponents/StyledInputAutoresize";

interface InputAutoresizeProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLSpanElement>) => void;
  styles?: SxProps;
}

export const InputAutoresize = ({
  value,
  onChange,
  styles,
}: InputAutoresizeProps): JSX.Element | null => {
  return useMemo(
    () => (
      <StyledInputAutoresize onInput={onChange} sx={styles}>
        {value}
      </StyledInputAutoresize>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
