import { styled } from "@mui/material/styles";

export const MiddleLine = styled("div")(({ theme }) => ({
  height: "100%",
  width: "0.100rem",
  opacity: "0.70",
  backgroundColor: theme.palette.custom.grey.light,
  position: "relative",
  "&:before": {
    position: "absolute",
    content: '""',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "0.438rem",
    height: "0.438rem",
    background: "inherit",
    borderRadius: "50%",
  },
  [theme.breakpoints.down(400)]: {
    display: "none",
  },
}));
