/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import {
  CancellationReportModel,
  CancellationReportSnapshot,
} from "./CancellationReport";

export const CancellationReportStoreModel = types
  .model("CancellationReportStore")
  .props({
    items: types.optional(types.array(CancellationReportModel), []),
  })
  .actions((self) => ({
    addItem: (item: CancellationReportSnapshot) => {
      const instance = resolveIdentifier(
        CancellationReportModel,
        self,
        item.id
      );
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));

type CancellationReportStoreType = Instance<
  typeof CancellationReportStoreModel
>;
export type CancellationReportStore = CancellationReportStoreType;
type CancellationReportStoreSnapshotType = SnapshotOut<
  typeof CancellationReportStoreModel
>;
export type CancellationReportStoreSnapshot =
  CancellationReportStoreSnapshotType;
