import React, { useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react';
import { useTranslation } from "react-i18next";

import styles from './CheckingHistory.module.scss';

import { ExerciseResultCheckingHistory } from '@packages/store/models/ExerciseResult/ExerciseResultCheckingHistory';
import { Icon } from '../../Icon';
import { Text } from '../../Text';

import { formatTime } from '../../../utils/helpers';

interface CheckingHistoryProps {
  history?: ExerciseResultCheckingHistory[];
  currentDate?: Date | null;
}

export const CheckingHistory = observer((props: CheckingHistoryProps) => {
	const { history, currentDate } = props;
  
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const toggleShow = useCallback(() => {
    setShow((show) => !show);
  }, [setShow])

  const History = useMemo(() => {
    return history?.map((history, index) => (
      <div
        key={`history_checking_item_${history.teacher}_${index}`}
        className={styles['checking-history-item']}
      >
        <Text
          color="base-dl"
          variant='text-2-regular'
        >
          {formatTime(history.originDate)}
        </Text>
        <Text
          color={
            history.hasErrors
              ? 'accsent-3s'
              : 'accsent-2s'
          }
          variant='text-2-regular'
          containerClassName={styles['checking-history-status']}
        >
          {
            history.hasErrors
              ? t("CreativeTasks:HasErrors")
              : t("CreativeTasks:WithoutErrors")
          }
        </Text>
        <Text
          color="base-dl"
          variant='text-2-regular'
        >
          {formatTime(history.checkDate)}
        </Text>
        <Text
          variant='text-2-regular'
          color='accsent-1s'
        >
          {history.teacher}
        </Text>
      </div>
    ));
  }, [history, t]);

  return (
    <div className={styles['checking-history']}>
      <div className={styles["accordion"]} onClick={toggleShow}>
        <Icon icon="accordion" />
        <Text variant="text-2-regular" color="accsent-1s">
          {t("CreativeTasks:CheckingHistory")}
        </Text>
      </div>
      {show && (
        <>
          {History}
          <div className={styles['checking-history-item']}>
            <Text
              color="base-dl"
              variant='text-2-regular'
            >
              {formatTime(currentDate)}
            </Text>
            <Text
              variant='text-2-regular'
            >
              {t("CreativeTasks:CurrentVersion")}
            </Text>
          </div>
        </>
      )}
    </div>
  );
})
