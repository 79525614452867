import React, { useState, ChangeEvent, ComponentType } from "react";

import { Box } from "@mui/material";
import { chunk } from "lodash";

import { Pagination } from "components/Pagination";

export interface ItemsWithPaginationProps {
  items: Array<React.ReactElement>;
  countPage: number;
  ContainerItems?: ComponentType;
}

export const ItemsWithPagination = ({
  items = [],
  countPage,
  ContainerItems,
}: ItemsWithPaginationProps): JSX.Element => {
  const [page, setPage] = useState(0);

  const getItems = (): Array<React.ReactElement> => {
    return chunk(items, countPage)[page];
  };

  const handleChangePage = (
    e: ChangeEvent<unknown>,
    newPage: number | null
  ): void => {
    if (newPage !== null) {
      const selectedPage = newPage ? newPage - 1 : newPage;
      setPage(selectedPage);
    }
  };

  const Container = ContainerItems || Box;

  return (
    <Box flex="1">
      <Container>{getItems()}</Container>
      {items.length > countPage && (
        <Box mt="1.625rem">
          <Pagination
            page={page + 1}
            pageSize={countPage}
            onChange={handleChangePage}
            dataLength={items.length}
          />
        </Box>
      )}
    </Box>
  );
};
