import { useEffect, useState } from "react";

import {
  ClickAwayListener,
  Typography,
  Box,
  Tooltip,
  useTheme,
} from "@mui/material";
import { CorrectAnswer } from "@packages/store/models/CorrectAnswer/CorrectAnswer";
import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { Question } from "@packages/store/models/Question/Question";
import { Icon } from "../../components/Icon";
import { StatusTypography } from "../../components/StatusTypography";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { QuestionOrder } from "../../components/QuestionOrder";
import { applyDiffPatch } from "../../helpers/applyDiffPatch";
import { diffWords } from "../../helpers/Diff/diffWords";
import { getItemStatus } from "../../helpers/getItemStatus";
import { DiffType } from "../../types";
import { useTranslation } from "react-i18next";

import { StyledButton } from "./styledComponents/StyledButton";
import { StyledTypography } from "./styledComponents/StyledTypography";
import { TextareaAutoresize } from "../TextareaAutoresize";
import { TooltipTitle } from "../TooltipTitle";

interface MistakesQuestionProps {
  question: Question;
  answerData: AnswerData;
  correctAnswer: CorrectAnswer | undefined;
  order?: number;
}

export const MistakesQuestion = observer(
  ({
    question,
    answerData,
    correctAnswer,
    order,
  }: MistakesQuestionProps): JSX.Element | null => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [editing, setEditing] = useState(false);

    const { originalText } = question;

    const { text, setText, noChanged, setNoChanged, isChecked, isWrong } =
      answerData;

    const isChanged = originalText.localeCompare(String(text)) !== 0;

    useEffect(() => {
      if (isChanged && !isChecked && noChanged !== false) {
        setNoChanged(false);
      }
    }, [isChanged, setNoChanged, noChanged, isChecked]);

    const handleEditingToggle = () => {
      if (!noChanged) {
        setEditing((prev) => !prev);
      }
    };

    const handleChangedToggle = () => {
      setNoChanged(noChanged ? null : true);
    };

    const handleReset = () => {
      setText(originalText || "");
      setNoChanged(null);
    };

    const renderCorrectAnswer = (str: string): JSX.Element | null => {
      const correctAnswerText = applyDiffPatch(
        originalText || "",
        toJS(correctAnswer?.firstAnswer?.diff || []).map(
          ({ action, element, position }) => ({
            action: String(action),
            position: Number(position),
            element: String(element),
          })
        )
      );

      const diff = diffWords(str, correctAnswerText) as DiffType;

      return (
        <span>
          {diff.map(({ added, removed, value }, index, arr) => {
            const { added: nextAdded = false, removed: nextRemoved = false } =
              arr[index + 1] || {};

            const nextChanged = nextAdded || nextRemoved;

            return (
              <Typography
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                component="span"
                variant="regularText"
                sx={{
                  ...(added && {
                    color: theme.palette.custom.answerColorVariant.font.passed,
                  }),
                  ...(removed && {
                    position: "relative",
                    color: theme.palette.custom.answerColorVariant.font.wrong,
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      width: "calc(100% - 3px)",
                      height: "1px",
                      backgroundColor:
                        theme.palette.custom.answerColorVariant.font.wrong,
                    },
                  }),
                }}
              >
                {value}
                {nextChanged && " "}
              </Typography>
            );
          })}
        </span>
      );
    };

    return (
      <QuestionOrder
        variant="block"
        order={order}
        status={getItemStatus(answerData)}
      >
        {isChecked ? (
          <>
            {isWrong ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div>
                  <StyledTypography italic>Исходный вариант:</StyledTypography>
                  <StyledTypography>{originalText}</StyledTypography>
                </div>

                <div>
                  <StyledTypography italic>
                    Правильный вариант:
                  </StyledTypography>
                  {renderCorrectAnswer(originalText || "")}
                </div>

                <div>
                  <StyledTypography italic>
                    Ваш ответ с исправлениями:
                  </StyledTypography>
                  {renderCorrectAnswer(text || "")}
                </div>
              </Box>
            ) : (
              <StatusTypography
                withoutPointer
                status={getItemStatus(answerData)}
                variant="regularText"
              >
                {text}
              </StatusTypography>
            )}
          </>
        ) : (
          <>
            {editing ? (
              <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={handleEditingToggle}
              >
                <TextareaAutoresize
                  value={text || ""}
                  onChange={setText}
                  minRows={0}
                  containerStyles={{ py: 2, px: "12px" }}
                />
              </ClickAwayListener>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
                <StyledTypography
                  onClick={handleEditingToggle}
                  sx={{
                    cursor: noChanged ? "text" : "pointer",
                  }}
                >
                  {text || "-"}
                </StyledTypography>

                <Box sx={{ flex: 1 }} />

                {!isChanged && (
                  <StyledButton
                    active={Boolean(noChanged)}
                    onClick={handleChangedToggle}
                  >
                    {t("Exercise:NoChange")}
                  </StyledButton>
                )}

                {isChanged && (
                  <Tooltip
                    placement="top"
                    title={
                      <TooltipTitle title={t("Exercise:ResetChangesTooltip")} />
                    }
                  >
                    <span style={{ cursor: "pointer" }}>
                      <Icon
                        type="reload"
                        sizeSquareIcon={16}
                        onClick={handleReset}
                      />
                    </span>
                  </Tooltip>
                )}
              </Box>
            )}
          </>
        )}
      </QuestionOrder>
    );
  }
);
