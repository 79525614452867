import { DateRange } from "../../models/LearningGroups/LearningGroupModel";
import { Lesson } from "../../models/Lesson/LessonModel";

export function filterLessonsByDateRange(
  lessons: Lesson[],
  dateRange: Partial<DateRange> | undefined
) {
  return lessons.filter((lesson) => {
    const { startDate, endDate } = dateRange ?? {};

    const startPeriodDate = new Date(startDate ?? new Date());
    const endPeriodDate = new Date(endDate ?? new Date());

    startPeriodDate.setHours(0);
    startPeriodDate.setMinutes(0);
    startPeriodDate.setSeconds(0);

    endPeriodDate.setHours(0);
    endPeriodDate.setMinutes(0);
    endPeriodDate.setSeconds(0);

    const lessonDay = lesson.day ?? new Date();

    const afterStart = startDate ? lessonDay >= startPeriodDate : true;
    const beforeEnd = endDate ? lessonDay <= endPeriodDate : true;

    return afterStart && beforeEnd;
  });
}
