import { format } from "date-fns";

import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

import { DateRange } from "../components/GroupFilter";

export const getGroupClassPeriodDates = (group: LearningGroup) => {
  const { from, to } = group.lessonsPeriod;
  if (from && !to) {
    return "-";
  }
  if (!from || !to) return "";
  return `${format(from, "dd.MM.yyyy")} - ${format(to, "dd.MM.yyyy")}`;
};

export const sortGroupsByAlphabet = (
  prev: LearningGroup,
  next: LearningGroup
) => {
  const prevName = prev.name ?? "";
  const nextName = next.name ?? "";
  return prevName > nextName ? 1 : -1;
};

export const formatGroups = (
  groups: LearningGroup[],
  dateRange?: DateRange
) => {
  return groups.map((group) => {
    const {
      totalHours,
      completedHours,
      leftHours,
      completedLessonsCountInThisPeriod,
    } = group.getLessonsDuration(dateRange);

    return {
      id: group.id,
      group: group.name,
      teachers: group.uniqueTeachers.map((teacher) => ({
        fullName: teacher.fullName,
      })),
      dates: getGroupClassPeriodDates(group),
      lessonsCount: completedLessonsCountInThisPeriod,
      total: totalHours ?? "-",
      finished: completedHours ?? "-",
      left: leftHours ?? "-",
    };
  });
};

export const convertObjToURLQueryParams = (obj: {
  [key: string]: string | boolean | number;
}) => {
  return Object.entries(obj)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");
};
