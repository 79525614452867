/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
} from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";
import { TranslatedWordModel, TranslatedWordSnapshot } from "./TranslatedWordModel";

export const TranslatedWordStoreModel = types
  .model("TranslatedTranslatedWordStore")
  .props({
    items: types.optional(types.array(TranslatedWordModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: TranslatedWordSnapshot) => {
      const instance = resolveIdentifier(TranslatedWordModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));

type TranslatedWordStoreType = Instance<typeof TranslatedWordStoreModel>;
export type TranslatedWordStore = TranslatedWordStoreType;
type TranslatedWordStoreSnapshotType = SnapshotOut<typeof TranslatedWordStoreModel>;
export type TranslatedWordStoreSnapshot = TranslatedWordStoreSnapshotType;
