import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { TranslationModel } from "../Translation/TranslationModel";

const CODE_TYPES: { [key: string]: string } = {
  "6": "Video",
  "7": "Vocabulary",
  "1": "Grammar",
  "3": "Reading",
  "8": "Writing",
  "4": "Speaking",
  "5": "Test",
};

export const TypeModel = types
  .model({
    id: types.identifier,
    translationsAttributes: types.optional(types.array(TranslationModel), []),
  })
  .views((self) => ({
    get code(): string {
      return CODE_TYPES[self.id] ?? "Unknown";
    },
  }));

type TypeModelType = Instance<typeof TypeModel>;
export type Type = TypeModelType;
type TypeModelTypeSnapshotType = SnapshotOut<typeof TypeModel>;
export type TypeModelSnapshot = TypeModelTypeSnapshotType;
