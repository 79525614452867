/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */
import { ForwardedRef, forwardRef, SyntheticEvent } from "react";

import { useTheme, Theme, SxProps } from "@mui/material";

import { IconWrapper } from "./styledComponents/IconWrapper";
import { StyledAnswerView } from "./styledComponents/StyledAnswerView";
import { StatusType } from "../../types";
import { Icon } from "../Icon";

interface AnswerViewProps {
  title: string;
  status: StatusType;
  styles?: SxProps<Theme>;
  clickable?: boolean;
  draggable?: boolean;
  dragging?: boolean;
  disabled?: boolean;
  fullSize?: boolean;
  fullWidth?: boolean;
  count?: number;
  onClick?: () => void;
  onRemove?: () => void;
}

export const AnswerView = forwardRef(
  (
    {
      title,
      styles,
      status,
      clickable,
      draggable,
      dragging,
      disabled,
      fullSize,
      fullWidth,
      count,
      onClick,
      onRemove,
    }: AnswerViewProps,
    ref: ForwardedRef<HTMLElement>
  ) => {
    const theme = useTheme();

    const handleRemove = (event: SyntheticEvent) => {
      event.stopPropagation();
      onRemove?.();
    };

    return (
      <StyledAnswerView
        ref={ref}
        status={status}
        clickable={clickable}
        draggable={draggable}
        dragging={dragging}
        disabled={disabled}
        fullSize={fullSize}
        fullWidth={fullWidth}
        count={count}
        sx={styles}
        onClick={onClick}
      >
        {onRemove && (
          <IconWrapper onClick={handleRemove}>
            <Icon
              type="close"
              color={theme.palette.error.contrastText}
              sizeSquareIcon={16}
            />
          </IconWrapper>
        )}

        <span dangerouslySetInnerHTML={{ __html: title }} />
      </StyledAnswerView>
    );
  }
);
