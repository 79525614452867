import { Box, styled } from "@mui/material";

export const IconWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: 18,
  height: 18,
  backgroundColor: theme.palette.error.main,
  borderRadius: "50%",
  transform: "translate(30%, -30%)",
  cursor: "pointer",
  zIndex: 1000,
}));
