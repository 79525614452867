/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
export const generateOptions = (options, defaults) => {
  if (typeof options === "function") {
    defaults.callback = options;
  } else if (options) {
    for (const name in options) {
      /* istanbul ignore else */
      if (options.hasOwnProperty(name)) {
        defaults[name] = options[name];
      }
    }
  }
  return defaults;
};
