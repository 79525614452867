import { ForwardedRef, forwardRef } from "react";

import { styled, Typography, TypographyProps } from "@mui/material";

export const TableCell = styled(
  forwardRef((props: TypographyProps, ref: ForwardedRef<HTMLSpanElement>) => (
    <Typography ref={ref} component="span" {...props} />
  ))
)(({ theme }) => ({
  ...theme.typography.regularText,
  display: "flex",
  alignItems: "center",
}));
