import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { useStores } from "@packages/store";
import { Summary } from "@packages/store/models/Summary/SummaryModel";

import summaryEmpty from "assets/img/summary-empty.svg";
import { Table } from "components/Table";
import { EmptyText, TableText } from "pages/Summary/styledComponents";

import { SummaryStatus } from "..";

function formatCount(count: number): number {
  return Math.round(count * 10) / 10;
}

export const SummaryTable = observer((): JSX.Element => {
  const { t } = useTranslation();
  const { summary } = useStores();
  const { items } = summary;
  const columns = [
    {
      Header: t("Summary:LessonStatus"),
      accessor: "status",
      Cell: ({ value }: Cell<Summary, Summary["status"]>) => (
        <SummaryStatus status={value} />
      ),
    },
    {
      Header: t("Summary:LessonCount"),
      accessor: "count",
      Cell: ({ value }: Cell<Summary, string>) => (
        <TableText>{formatCount(+value)}</TableText>
      ),
    },
    {
      Header: t("Summary:HoursCount"),
      accessor: "hours",
      Cell: ({ value }: Cell<Summary, string>) => (
        <TableText>{formatCount(+value)}</TableText>
      ),
    },
  ];

  return (
    <>
      {items.length === 0 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="1.5rem"
          mt="3rem"
        >
          <img src={summaryEmpty} alt="Empty summary" />
          <EmptyText>{t("Summary:Empty")}</EmptyText>
        </Box>
      )}
      {items.length > 0 && (
        <Box mt="2rem">
          <Table
            containerType="blue"
            data={items as any}
            columns={columns}
            count={items.length}
          />
        </Box>
      )}
    </>
  );
});
