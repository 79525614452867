/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable no-param-reassign */
import { isNull } from "lodash";
import { types, Instance, SnapshotOut, cast, getParent } from "mobx-state-tree";

import { Question } from "./Question";

export enum RecordType {
  EXISTENT = "existent",
}

export enum AnswerState {
  HAS_ERRORS = "hasErrors",
  VERIFIED = "verified",
}

export const AnswerModel = types
  .model("Answer", {
    id: types.maybeNull(types.string),
    options: types.array(types.string),
    text: types.maybeNull(types.string),
    place: types.maybeNull(types.number),
    state: types.maybeNull(
      types.enumeration<AnswerState>("AnswerState", Object.values(AnswerState))
    ),
    record: types.maybeNull(types.string),
    recordType: types.maybeNull(
      types.enumeration<RecordType>("RecordType", Object.values(RecordType))
    ),
    recordObjectURL: types.maybeNull(types.string),
  })
  .views((self) => ({
    get question(): Question {
      return getParent<Question>(self, 2);
    },
  }))
  .views((self) => ({
    get isFilled(): boolean {
      return Boolean(
        self.text || self.options.length || self.place || self.record
      );
    },
    get isChecked(): boolean {
      return isNull(self.state)
        ? self.question.isChecked
        : self.state === AnswerState.VERIFIED ||
            self.state === AnswerState.HAS_ERRORS;
    },
    get isWrong(): boolean {
      return isNull(self.state)
        ? self.question.isWrong
        : self.state === AnswerState.HAS_ERRORS;
    },
    get isPassed(): boolean {
      return isNull(self.state)
        ? self.question.isPassed
        : self.state === AnswerState.VERIFIED;
    },
  }))
  .actions((self) => ({
    addOption(value: string) {
      self.options = cast([value]);
    },
    toggleOption(value: string) {
      if (self.options.includes(value)) {
        self.options.splice(self.options.indexOf(value), 1);
        return;
      }
      self.options.push(value);
    },
    setText(value: string) {
      self.text = value;
    },
    setRecordObjectURL(url: string) {
      self.recordObjectURL = url;
    },
    remove() {
      self.question.removeAnswer(cast(self));
    },
  }));

type AnswerModelType = Instance<typeof AnswerModel>;
type AnswerModelSnapshotType = SnapshotOut<typeof AnswerModel>;

export interface Answer extends AnswerModelType {}
export type AnswerSnapshot = AnswerModelSnapshotType;
