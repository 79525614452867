/* eslint-disable @typescript-eslint/no-shadow */

import { CorrectAnswer } from "@packages/store/models/CorrectAnswer/CorrectAnswer";
import { Answer } from "@packages/store/models/ExerciseAnswer/Answer";
import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { AnswerPart } from "@packages/store/models/Question/AnswerPart";
import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../components/FlexList";
import { observer } from "mobx-react-lite";
import { QuestionOrder } from "../../components/QuestionOrder";
import { getItemStatus } from "../../helpers/getItemStatus";
import { XYCoord } from "react-dnd";
import { StatusType } from "../../types";
import { ensure } from "../../helpers/ensure";

import { DraggableAnswer, DragItem } from "../DraggableAnswer";

interface OrderQuestionProps {
  question: Question;
  answerData: AnswerData;
  correctAnswer: CorrectAnswer | undefined;
  correctAnswerParts?: AnswerPart[];
  order?: number;
  variant?: "list" | "inline";
}

export const OrderQuestion = observer(
  ({
    question,
    answerData,
    correctAnswer,
    correctAnswerParts,
    order,
    variant = "list",
  }: OrderQuestionProps): JSX.Element | null => {
    const { id: questionId, answerParts } = question;

    const { answers, isChecked, moveAnswer, getAnswerIndex } = answerData;

    const getAnswerStatus = (index: number): StatusType => {
      if (isChecked) {
        if (correctAnswerParts) {
          return answers[index].text === correctAnswerParts[index]?.value
            ? "passed"
            : "wrong";
        }
        return answers[index].id === correctAnswer?.answers[index].id
          ? "passed"
          : "wrong";
      }
      return "default";
    };

    const canBeMoved = (id: string | null) => {
      return answerParts.find((answerPart) => answerPart.id === id)?.canBeMoved;
    };

    return (
      <QuestionOrder
        variant="block"
        order={order}
        status={getItemStatus(answerData)}
      >
        <ObserverFlexList
          items={answers}
          renderItem={({ id: answerId, text }: Answer, index) => (
            <DraggableAnswer
              key={answerId}
              id={String(answerId)}
              title={String(text)}
              question={questionId}
              status={getAnswerStatus(index)}
              isChecked={isChecked}
              disabled={!canBeMoved(answerId)}
              onHover={(
                { id: draggedAnswer, question }: DragItem,
                monitor,
                { element, disabled }
              ) => {
                if (!element) {
                  return;
                }

                const dragIndex = ensure(getAnswerIndex(draggedAnswer));
                const hoverIndex = ensure(getAnswerIndex(String(answerId)));

                if (
                  disabled ||
                  dragIndex === hoverIndex ||
                  question !== questionId
                ) {
                  return;
                }

                const hoverBoundingRect = element.getBoundingClientRect();

                const hoverMiddle = {
                  x: (hoverBoundingRect.right - hoverBoundingRect.left) / 2,
                  y: (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2,
                };

                const clientOffset = monitor.getClientOffset();

                const hoverClient = {
                  x: (clientOffset as XYCoord).x - hoverBoundingRect.left,
                  y: (clientOffset as XYCoord).y - hoverBoundingRect.top,
                };

                const middle =
                  variant === "list" ? hoverMiddle.y : hoverMiddle.x;
                const client =
                  variant === "list" ? hoverClient.y : hoverClient.x;

                if (dragIndex < hoverIndex && client < middle) {
                  return;
                }

                if (dragIndex > hoverIndex && client > middle) {
                  return;
                }

                moveAnswer(dragIndex, hoverIndex);
              }}
            />
          )}
          {...(variant === "inline" && {
            direction: "row",
            wrap: true,
          })}
        />
      </QuestionOrder>
    );
  }
);
