import { CustomerCompany } from "@packages/store/models/CustomerCompany/CustomerCompanyModel";
import { ValueWithLabel } from "@packages/store/services/Api";

export function formatCompaniesForFilter(
  companies: CustomerCompany[]
): ValueWithLabel[] {
  return companies.map((company) => ({
    label: company?.name ?? "",
    value: String(company?.id ?? 0),
  }));
}
