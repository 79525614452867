import { styled, Typography } from "@mui/material";

interface StyledTypographyProps {
  italic?: boolean;
}

export const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "italic",
})<StyledTypographyProps>(({ theme, italic }) => ({
  ...theme.typography.regularText,
  display: "block",
  lineHeight: "26px",

  ...(italic && {
    fontStyle: "italic",
  }),
}));
