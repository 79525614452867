import { TextField } from "@mui/material";
import { ModalAttendanceFormValues } from "components/ModalAttendance";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getLessonDurationInAcademicHours } from "utils/helpers";

interface DurationProps {
  control: Control<ModalAttendanceFormValues>;
  disabled?: boolean;
}

export const Duration = ({
  control,
  disabled = false,
}: DurationProps): JSX.Element | null => {
  const { t } = useTranslation();

  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: "duration",
    rules: {
      required: t("ModalAttendance:requiredFieldError").toString(),
      min: 0,
    },
  });

  // Запрет на введение больше одного знака после точки
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e.target.value.match(/\.\d+/)?.[0].length || 0) > 2) {
      return;
    }

    onChange(e);
  };

  const helperText = `*(${getLessonDurationInAcademicHours(
    (value ?? 0) * 60
  )}${t("LessonDuration:Hours")}) ${error?.message || " "}`;

  return (
    <TextField
      id="time"
      type="number"
      disabled={disabled}
      InputLabelProps={{
        shrink: true,
        sx: {
          backgroundColor: "white",
        },
      }}
      label={`${t("TeacherMyClasses:duration")} ${t("LessonDuration:Minutes")}`}
      variant="outlined"
      InputProps={{
        sx: {
          fontSize: "13px",
          height: 40,
        },
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ step: "0.1", min: "0" }}
      value={value}
      onChange={handleChange}
      error={!!error}
      helperText={helperText}
    />
  );
};
