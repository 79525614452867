import { styled, Box } from "@mui/material";

export const ModalBody = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.contrastText,
  width: "30rem",
  borderRadius: "0.5rem",
  outline: "none",
  [theme.breakpoints.down("desktop")]: {
    maxWidth: "100vw",
  },
}));
