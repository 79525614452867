/* eslint-disable react/require-default-props */
import { Link as MaterialLink, SxProps, Theme } from "@mui/material";
import { To } from "react-router-dom";

import { Link } from "./styledComponents";

export interface JetLinkProps {
  to: To;
  newTab?: boolean;
  external?: boolean;
  children: React.ReactNode;
  underline?: "none" | "hover" | "always";
  sx?: SxProps<Theme>;
}

export const JetLink = ({
  to,
  newTab = false,
  external = false,
  children,
  underline,
  sx,
}: JetLinkProps): JSX.Element => {
  const target = newTab ? "_blank" : "";
  return external ? (
    <MaterialLink
      underline={underline}
      href={typeof to === "string" ? to : to.pathname}
      target={target}
      rel="noreferrer"
      sx={sx}
    >
      {children}
    </MaterialLink>
  ) : (
    <Link to={to} target={target} sx={sx}>
      {children}
    </Link>
  );
};
