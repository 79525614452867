/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-extraneous-dependencies */
import { SyntheticEvent } from "react";

import { Autocomplete as MUIAutocomplete, TextField } from "@mui/material";

interface ValueWithLabel {
  value: string;
  label: string;
}

interface AutocompleteProps {
  value: string;
  options: ValueWithLabel[];
  onChange: (newValue: string) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const Autocomplete = ({
  value,
  options,
  onChange,
  placeholder,
  disabled,
}: AutocompleteProps) => {
  const autocompleteValue = options.find((o) => o.value === value) || null;

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    newValue: ValueWithLabel | null
  ) => {
    if (newValue) {
      onChange(newValue.value);
    }
  };

  return (
    <MUIAutocomplete
      disablePortal
      blurOnSelect
      clearOnEscape
      autoHighlight
      value={autocompleteValue}
      options={options}
      isOptionEqualToValue={(option, { value }) => option.value === value}
      onChange={handleChange}
      onInputChange={(_event, _newValue, reason) => {
        if (reason === "clear") {
          onChange("");
        }
      }}
      renderInput={(params: any) => {
        return (
          <TextField {...params} variant="standard" placeholder={placeholder} />
        );
      }}
      sx={{
        minWidth: 200,
      }}
      disabled={disabled}
    />
  );
};
