/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { types, Instance, SnapshotOut, flow, cast } from "mobx-state-tree";

import { withEnvironment } from "../extensions/withEnvironment";

export const LanguageModel = types
  .model("Language", {
    id: types.identifier,
    name: types.maybeNull(types.string),
    shortName: types.maybeNull(types.string),
  })
  .extend(withEnvironment);

type LanguageType = Instance<typeof LanguageModel>;
export type Language = LanguageType;
type LanguageTypeSnapshotType = SnapshotOut<typeof LanguageModel>;
export type LanguageSnapshot = LanguageTypeSnapshotType;
