import { Question as AnswerData } from "@packages/store/models/ExerciseAnswer/Question";
import { Question } from "@packages/store/models/Question/Question";
import { observer } from "mobx-react-lite";
import { useDrop } from "react-dnd";

import { StyledMatchingQuestion } from "./styledComponents/StyledMatchingQuestion";
import { Text } from "./styledComponents/Text";
import { getItemStatus } from "../../helpers/getItemStatus";
import { DragItemType } from "../../types";
import { DraggableAnswer, DragItem } from "../DraggableAnswer";

interface MatchingQuestionProps {
  question: Question;
  answerData: AnswerData;
  swapAnswer: (
    source: { id: string; question: string },
    target: { id: string; question: string }
  ) => void;
  variant?: "horizontal" | "vertical";
  fullWidthAnswer?: boolean;
  fullSizeAnswer?: boolean;
}

export const MatchingQuestion = observer(
  ({
    question,
    answerData,
    swapAnswer,
    variant = "horizontal",
    fullWidthAnswer,
    fullSizeAnswer,
  }: MatchingQuestionProps): JSX.Element | null => {
    const { firstAnswer } = answerData;

    const { id, text } = question;

    const { id: answerId, text: answerText, isChecked } = firstAnswer || {};

    const [, drop] = useDrop(
      () => ({
        accept: DragItemType.ANSWER,
        drop: (): DragItem => ({
          id: String(answerId),
          title: String(answerText),
          question: id,
        }),
      }),
      [answerId, answerText, id]
    );

    return (
      <StyledMatchingQuestion ref={drop as any} variant={variant}>
        <Text
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />

        <DraggableAnswer
          id={String(answerId)}
          title={String(answerText)}
          question={id}
          isChecked={isChecked}
          status={getItemStatus(answerData)}
          fullWidth={fullWidthAnswer}
          fullSize={fullSizeAnswer}
          onDrop={(item, monitor) => {
            const dropResult = monitor.getDropResult<DragItem>();
            if (dropResult && item.question !== dropResult.question) {
              swapAnswer(item, dropResult);
            }
          }}
        />
      </StyledMatchingQuestion>
    );
  }
);
