import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const VideoTrackModel = types.model({
  format: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  store: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

type VideoTrackModelType = Instance<typeof VideoTrackModel>;
type VideoTrackModelSnapshotType = SnapshotOut<typeof VideoTrackModel>;

export type VideoTrack = VideoTrackModelType;
export type VideoTrackSnapshot = VideoTrackModelSnapshotType;
