import { useEffect } from "react";

import { Box } from "@mui/material";
import { useStores } from "@packages/store";
import { Teacher } from "@packages/store/models/Teacher/Teacher";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { ContentContainer } from "components/ContentContainer";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";

import { Rates, TeacherProfile } from "./components";

export const TeacherDetails = observer((): JSX.Element | null => {
  const id = useParams().id as string;

  const { t } = useTranslation();

  const {
    getTeacherTags,
    getTaggingLists,
    // getTeacher,
    teacher: teacherStore,
    loading,
    api,
  } = useStores();

  const { fetch: fetchTeacherTags } = useCancelableFetch();
  const { fetch: fetchTeacherRates } = useCancelableFetch();
  const { fetch: fetchTaggingLists } = useCancelableFetch();
  const { fetch: fetchLanguages } = useCancelableFetch();
  // TODO Переделать на запрос одного препода
  const { fetch: fetchTeachers } = useCancelableFetch();

  const teacher = teacherStore.getById(id);

  useEffect(() => {
    Promise.all([
      fetchTeachers((token) => api.getTeachers(token)),
      fetchTeacherRates(() => api.getTeacherRates({ teacherId: id })),
      fetchTeacherTags(() => getTeacherTags(id)),
      fetchTaggingLists(() => getTaggingLists("teacher")),
      fetchLanguages(() => api.getLanguages()),
    ]);
  }, [
    fetchTaggingLists,
    fetchTeachers,
    fetchTeacherRates,
    fetchTeacherTags,
    getTaggingLists,
    getTeacherTags,
    id,
    api,
    fetchLanguages,
  ]);

  return (
    <ContentContainer>
      <Loading loading={loading || !teacher}>
        <PageBlock title={t("TeacherDetails:Title")}>
          <Box sx={{ mb: 4 }} />

          <TeacherProfile teacher={teacher as Teacher} />

          <Rates teacher={teacher as Teacher} />
        </PageBlock>
      </Loading>
    </ContentContainer>
  );
});
