import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { statuses } from "../Lesson/LessonModel";

export const SummaryModel = types
  .model("SummaryModel", {
    status: types.enumeration("SummaryStatus", [
      ...statuses,
      "compensation"
    ]),
    count: types.maybeNull(types.number),
    hours: types.maybeNull(types.number),
  });


type SummaryType = Instance<typeof SummaryModel>;
export interface Summary extends SummaryType {}
type SummarySnapshotType = SnapshotOut<typeof SummaryModel>;
export type SummarySnapshot = SummarySnapshotType;