/* eslint-disable react/button-has-type */

import { useEffect } from "react";

import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Icon } from "../../components/Icon";
import { StyledPaper } from "../../components/StyledPaper";
import { useRecorder } from "../../hooks/useRecorder";

// import { VolumeIndicator } from "./styledComponents/VolumeIndicator";

interface AudioRecorderProps {
  onStartRecording?: () => void;
  onStopRecording?: (blob: Blob) => void;
  maxRecorderTimeInSeconds?: number;
}

// TODO complete audio volume indicator
export const AudioRecorder = ({
  onStartRecording,
  onStopRecording,
  maxRecorderTimeInSeconds = 360,
}: AudioRecorderProps): JSX.Element | null => {
  const theme = useTheme();
  const { t } = useTranslation();
  // const volumeDivRef = useRef<HTMLDivElement>(null);

  const {
    chunks,
    recordingSeconds,
    recordedSeconds,
    isRecording,
    startRecording,
    stopRecording,
  } = useRecorder(
    maxRecorderTimeInSeconds
    // , (volume: number) => {
    // volumeDivRef.current!.style.background = `linear-gradient(90deg, #FFD173 0%, #70FF4D 100%)`;
    // volumeDivRef.current!.textContent = `${volume * 1000}`;
    // }
  );

  const handleStartRecording = () => {
    startRecording();
    onStartRecording?.();
  };

  useEffect(() => {
    if (!isRecording && recordedSeconds > 0 && chunks.length > 0) {
      const blob = new Blob(chunks, {
        type: "audio/wav; codecs=MS_PCM",
      });

      onStopRecording?.(blob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chunks, recordedSeconds, isRecording]);

  return (
    <StyledPaper
      sx={{
        width: "fit-content",
        display: "flex",
        gap: 3,
        height: "2.5rem",
        padding: "10px",
        cursor: "pointer",
        backgroundColor: isRecording ? "warning.light" : "",
      }}
      onClick={isRecording ? stopRecording : handleStartRecording}
    >
      <Icon
        type="mic"
        stroke="#FF6161"
        color={isRecording ? "#FF6161" : "transparent"}
        width="1rem"
      />
      <Typography
        sx={{
          lineHeight: "1.25",
          color: isRecording ? "#FF6161" : "custom.grey.dark",
          [theme.breakpoints.down(400)]: {
            fontSize: "0.8rem",
          },
        }}
      >
        {t("Exercise:AudioRecorderText", {
          count: isRecording
            ? maxRecorderTimeInSeconds - recordingSeconds
            : maxRecorderTimeInSeconds,
        })}
      </Typography>
      {/* {isRecording && <VolumeIndicator ref={volumeDivRef} />} */}
    </StyledPaper>
  );
};
