import { Student, ValueWithLabel } from "@packages/store/services/Api";

export function formatStudentsForFilter(students: Student[]): ValueWithLabel[] {
  return students
    .map((student) => {
      const { firstName = "", lastName = "", id = "0" } = student ?? {};

      return {
        label: `${firstName} ${lastName}`,
        value: String(id),
      };
    })
    .filter((item, index, arr) => {
      const duplicateIndex = arr.findIndex((duplicate) => {
        const isSameLabels = duplicate.label === item.label;
        const isSameValues = duplicate.label === item.label;
        return isSameLabels || isSameValues;
      });
      return index === duplicateIndex;
    });
}
