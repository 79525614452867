import { types, SnapshotOut, Instance } from "mobx-state-tree";

import { withTranslation } from "../extensions/withTranslation";

export const CustomerCompanyModel = types
  .model("CustomerCompanyModel")
  .extend(withTranslation)
  .props({
    id: types.identifier,
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    lessonsAutoCancel: types.maybeNull(types.boolean),
  });

type CustomerCompanyModelType = Instance<typeof CustomerCompanyModel>;
export type CustomerCompany = CustomerCompanyModelType;
type CustomerCompanySnapshotType = SnapshotOut<typeof CustomerCompanyModel>;
export type CustomerCompanySnapshot = CustomerCompanySnapshotType;
