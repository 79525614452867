/* eslint-disable import/no-extraneous-dependencies */
import { useDrop } from "react-dnd";

import { StyledMovingDropzone } from "./styledComponents/StyledMovingDropzone";
import { useDragDrop } from "../../hooks/useDragDrop";
import { DragItemType } from "../../types";

export interface MovingDropItem {
  question: string;
  place: number;
}

interface MovingDropzoneProps {
  question: string;
  place: number;
  expandable?: boolean;
}

export const MovingDropzone = ({
  question,
  place,
  expandable = false,
}: MovingDropzoneProps): JSX.Element | null => {
  const {
    dragSize: { width },
  } = useDragDrop();

  const [{ isActive }, drop] = useDrop(
    () => ({
      accept: DragItemType.ANSWER,
      drop: (): MovingDropItem => ({
        question,
        place,
      }),
      collect: (monitor) => ({
        isActive: monitor.isOver(),
      }),
    }),
    [question, place]
  );

  return (
    <StyledMovingDropzone
      ref={drop as any}
      expandable={expandable}
      isActive={isActive}
      width={expandable ? width : undefined}
    >
      None
    </StyledMovingDropzone>
  );
};
