import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const IconsContainer = styled(Box)(({ theme }) => ({
  display: "flex",

  alignItems: "flex-end",
  marginRight: theme.spacing(7),
  "& svg": {
    marginLeft: "1.6875rem",
    cursor: "pointer",
  },
  [theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));
