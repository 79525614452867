/* eslint-disable @typescript-eslint/no-shadow */
import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { AnswerModel } from "@packages/store/models/ExerciseAnswer/Answer";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun } from "mobx";
import { ensure } from "../helpers/ensure";

export const useOrderExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions, passingData, isChecked } = exercise;

      if (!answer) {
        return;
      }

      const { setQuestions } = answer;

      if (passingData.length > 0 && isChecked) {
        const initialState = passingData.map(({ id, state, answers }) => {
          const { answerParts } = ensure(
            questions.find((question) => id === question.id)
          );

          return QuestionModel.create({
            id,
            state,
            answers: answers.map((answer) => {
              const { id, text } = ensure(
                answerParts.find(({ id }) => id === answer.id)
              );

              return AnswerModel.create({
                id,
                text,
              });
            }),
          });
        });

        setQuestions(initialState);
      }
    })
  );

  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty) {
        const initialState = questions.map(({ id, answerParts }) =>
          QuestionModel.create({
            id,
            answers: answerParts.map(({ id, text }) =>
              AnswerModel.create({
                id,
                text,
              })
            ),
          })
        );

        setQuestions(initialState);
      }
    })
  );
};
