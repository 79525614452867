import { VideoTrack } from "@packages/store/models/AdditionalContent/VideoTrack";
import {
  VideoPlayerSources,
  VideoPlayerOptions,
} from "../components/VideoPlayer";

export const getMediaPlayerOptions = (videoTracks: VideoTrack[]) => {
  const sources: VideoPlayerSources = videoTracks.map((track) => ({
    src: String(track.url),
    type: String(track.format),
    res: parseInt(String(track.name || ""), 10),
    label: String(track.name),
  }));

  return { sources } as VideoPlayerOptions;
};
