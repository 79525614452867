import { Box } from "@mui/material";
import { Unit } from "@packages/store/models/Unit/UnitModel";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Modal } from "../../shared/Modal";
import { TestItems } from "../components/TestItems";
import { TestSummary } from "../components/TestSummary";

interface ModalTestUnitResultProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  unit: Unit;
}

export const ModalTestUnitResult = observer(
  ({
    isOpen,
    onConfirm,
    onClose,
    unit,
  }: ModalTestUnitResultProps): JSX.Element | null => {
    const { t } = useTranslation();

    const scoredPoints = unit.passing?.mark ?? 0;
    const totalPoints = unit.passing?.maxMark ?? 0;
    const percentage = Number(((scoredPoints / totalPoints) * 100).toFixed(1));

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        type="extraSmall"
        title={t("ResultsModals:TestUnitTitle", { order: unit.order })}
        titleColor="primary.main"
        titleVariant="largeText"
        containerStyles={{ paddingBottom: "1.5rem" }}
      >
        <Box display="flex" flexDirection="column" gap="1.5rem">
          <TestSummary
            totalPoints={totalPoints}
            scoredPoints={scoredPoints}
            percentage={percentage}
          />

          <TestItems
            unitOrder={unit.order ?? 0}
            unitName={unit.nameWithoutUnit}
            totalProgress={unit.totalProgress}
            percentage={percentage}
            onConfirm={onConfirm}
          />
        </Box>
      </Modal>
    );
  }
);
