import { ExerciseErrorType } from "@packages/store/models/ExerciseResult/ExerciseResultError";

import { IconType } from "../../../Icon";

export function getErrorIcon(type: ExerciseErrorType): IconType {
  const icons: Record<ExerciseErrorType, IconType> = {
    [ExerciseErrorType.COMMENT]: "comments",
    [ExerciseErrorType.GRAMMAR]: "grammar",
    [ExerciseErrorType.LEXICAL]: "vocubalury",
  }

  return icons[type];
}
