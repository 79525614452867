export enum ROUTES {
  HOME = "/",
  PROFILE = "/profile",
  PASSWORD_SETUP = "/cabinet/forced_password_edit",
  ATTENDANCE = "/attendance",
  ATTENDANCE_REPORT = "/attendance-report",
  MY_WORDS = "/my-words",
  MY_COURSE = "/my-course",
  MY_COURSES = "/my-courses",
  MODAL_UNIT = "/modal-unit",
  STUDENT_PAGE = "/student-page",
  GROUPS_PAGE = "/groups",
  SIGN_IN_PAGE = "/",
  SIGN_UP_PAGE = "/sign-up",
  RESTORE_PASSWORD = "/restore-password",
  SETUP_PASSWORD = "/setup-password",
  CLASSES = "/classes",
  MODAL_GROUP = "/modal-group",
  EXERCISES = "/exercises",
  REPORTS = "/reports",
  STUDENTS_REPORTS = "/statistics-reports",
  TEACHERS_REPORTS = "/progress-reports",
  CANCELLATION_REPORT = "/cancellation-report",
  ENTRANCE_REPORTS = "/reports/entrance_reports",
  LICENSE_REPORTS = "/reports/license_reports",
  TEACHERS = "/teachers",
  TAGGING_LIST = "tagging-list",
  EMAIL_NOT_CONFIRMED = "/",
  SUMMARY = "/summary",
  COMPANIES = "/companies",
  PROMOCODE_EXPIRED = "/promocode-expired",
  ALL_PAYROLL_GROUPS = "/all-payroll-groups",
  CREATIVE_TASKS = "/creative",
}

export type RouteName = keyof typeof ROUTES;
