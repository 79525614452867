import { ComponentsOverrides, Theme } from "@mui/material";

interface muiRadioButtonProps {
  styleOverrides: ComponentsOverrides["MuiRadio"];
}

export const muiRadioButton = (theme: Theme): muiRadioButtonProps => ({
  styleOverrides: {
    root: {
      color: theme.palette.custom?.grey?.light,
      "&.Mui-checked": {
        color: theme.palette.info.main,
      },
    },
  },
});
