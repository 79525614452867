import { styled, Box, BoxProps } from "@mui/material";

export interface ModalContainerProps extends BoxProps {
  type?: "small" | "default";
}

export const ModalContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "type",
})<ModalContainerProps>(({ theme, type = "default" }) => ({
  background: theme.palette.primary.contrastText,
  width: type === "small" ? "48rem" : "67rem",
  padding: `${theme.spacing(5)} 2.5rem ${theme.spacing(6)}`,
  borderRadius: "0.5rem",
  [theme.breakpoints.down("desktop")]: {
    maxWidth: "100vw",
  },
  outline: "none",
}));
