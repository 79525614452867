import { styled } from "@mui/material/styles";

export const MenuContainer = styled("div")(({ theme }) => ({
  // Следующий за активным элемент
  '& > *[data-is-active="true"] + *': {
    borderTopRightRadius: "0.5rem",
  },

  // Предыдущий за активным элемент
  '& > *:has(+ *[data-is-active="true"])': {
    borderBottomRightRadius: "0.5rem",
  },

  [theme.breakpoints.down("tablet")]: {
    display: "none",
  },
}));
