import { Fragment, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useStores } from "@packages/store";
import { Teacher } from "@packages/store/models/Teacher/Teacher";
import DefaultTeacherPhoto from "assets/img/default-teacher-photo.jpg";
import { Button } from "components/Button";
import compact from "lodash/compact";
import { observer } from "mobx-react-lite";
import { Photo } from "pages/TeacherDetails/styledComponents";
import { useTranslation } from "react-i18next";

import {
  ModalTeacherProfile,
  ModalTeacherProfileFormValues,
} from "../ModalTeacherProfile";

interface TeacherProfileProps {
  teacher: Teacher;
}

export const TeacherProfile = observer(
  ({ teacher }: TeacherProfileProps): JSX.Element | null => {
    const { t } = useTranslation();

    const { taggingList: taggingListStore, addError } = useStores();

    const taggingLists = taggingListStore.getFilledWithTeacher(
      teacher?.actualId ?? ""
    );

    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleChangeProfile = async (data: ModalTeacherProfileFormValues) => {
      if (!teacher) return false;

      const isTagsUpdated = await teacher.updateTags(
        compact(Object.values(data))
      );

      if (isTagsUpdated) {
        return closeModal();
      }

      addError(t("TeacherDetails:ApiError"));

      return false;
    };

    return (
      <Box mb="1.8rem">
        <Typography variant="h2" color="custom.gray.dark" mb="1.25rem">
          {t("TeacherDetails:Profile")}
        </Typography>
        <Box display="flex" flexWrap="wrap" gap="1.5rem">
          <Box sx={{ flexBasis: "340px" }}>
            <Photo src={teacher.photo ?? DefaultTeacherPhoto} alt="Teacher" />
            <Button
              variant="secondary"
              sx={{ width: "100%" }}
              onClick={openModal}
            >
              {t("TeacherDetails:EditProfile")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "10rem, 1fr",
              gridTemplateRows: "repeat(7, 1fr)",
              gridColumnGap: "1.25rem",
              gridRowGap: "1rem",
            }}
          >
            <Typography
              variant="h2"
              color="custom.gray.dark"
              sx={{ gridArea: "1 / 1 / 2 / 3" }}
            >
              {teacher.fullName}
            </Typography>
            {/* <Box display="flex" alignItems="center" gap="10px">
                <Icon type="phone" width={24} height={24} />
                <Typography variant="button">{teacher.phone}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Icon type="mail" width={24} height={24} />
                <Typography variant="buttonUnderlined">
                  <JetLink to={`mailto:${teacher.email}`} external>
                    {teacher.email}
                  </JetLink>
                </Typography>
              </Box> */}

            {taggingLists.map(({ list, teacherValue }) => (
              <Fragment key={list.id}>
                <Typography
                  variant="h3"
                  color="custom.gray.dark"
                  fontWeight={500}
                >
                  {list.name}
                </Typography>
                <Typography variant="h3" color="info.main">
                  {list.tags.find((tag) => tag.id === teacherValue)?.name ??
                    "-"}
                </Typography>
              </Fragment>
            ))}
          </Box>
        </Box>

        <ModalTeacherProfile
          teacher={teacher}
          taggingLists={taggingLists}
          open={isModalOpen}
          onClose={closeModal}
          onSubmit={handleChangeProfile}
        />
      </Box>
    );
  }
);
