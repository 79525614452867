import { useLayoutEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useStores } from "@packages/store/models";
import { Button } from "components/Button";
import { Modal } from "components/shared/Modal";
import { useFetch } from "hooks/useFetch";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getExerciseUrl } from "utils/helpers";

export const ModalOfferEntranceTest = observer((): JSX.Element | null => {
  const { t } = useTranslation();
  const { api, auth } = useStores();
  const [isOpen, setIsOpen] = useState(false);
  const [testUrl, setTestUrl] = useState<string | null>(null);

  const navigate = useNavigate();

  const { fetch: fetchEntranceTest } = useFetch(
    (id: string) => api.entranceTest(id),
    (data: any) => {
      if (data && data.state !== "duplicate_course_and_testing") {
        const { courseId, unitId, blockId, exerciseId } = data;

        setTestUrl(getExerciseUrl(courseId, unitId, blockId, exerciseId));
        setIsOpen(true);
      }
    }
  );

  const onClose = () => {
    auth.setEntranceTestId(null);

    setIsOpen(false);
  };

  const handleConfirm = () => {
    if (testUrl) {
      navigate(testUrl);
    }

    onClose();
  };

  useLayoutEffect(() => {
    if (!auth.entranceTestId) return;

    fetchEntranceTest(auth.entranceTestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={t("EntranceTest:ModalTitle")}
      containerProps={{
        type: "small",
        paddingBottom: "1.5rem",
      }}
    >
      <Typography sx={{ marginBottom: "1.25rem" }}>
        {t("EntranceTest:ModalBody")}
      </Typography>
      <Box display="flex" justifyContent="flex-end" gap="1rem">
        <Button variant="text" onClick={onClose}>
          {t("EntranceTest:Cancel")}
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          {t("EntranceTest:Take")}
        </Button>
      </Box>
    </Modal>
  );
});
