/* eslint-disable @typescript-eslint/no-shadow */
import { useState, useCallback, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ensure } from "../../../../../../helpers/ensure";
import { SubExerciseComponentProps } from "../../../../../../types";
import { CreativeExercisePlug } from "../../../../../CreativeExercisePlug";
import { EssayStats, EssayStatsView } from "../../../../../EssayStatsView";
import { TextareaAutoresize } from "../../../../../TextareaAutoresize";

export const Essay = observer(({ exercise }: SubExerciseComponentProps) => {
  const { t } = useTranslation();

  const [stats, setStats] = useState<EssayStats>({
    numberOfLetters: 0,
    numberOfWords: 0,
  });

  const { answer, name, state, isChecked, checkingResultId } = exercise;

  const { firstQuestion } = ensure(answer);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { firstAnswer: { text = null, setText = () => {} } = {} } =
    ensure(firstQuestion);

  const handleStatsChange = (text: string) => {
    // Тут странная регулярка, зато она работает для любых языков
    const numberOfWords = text.match(/[\p{L}\p{M}]+/gu)?.length || 0;
    const numberOfLetters = text.length;
    setStats({ numberOfWords, numberOfLetters });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStatsChange = useCallback(
    debounce(handleStatsChange, 100),
    []
  );

  useEffect(() => {
    debouncedStatsChange(text || "");
  }, [text, debouncedStatsChange]);

  const renderDefault = (): JSX.Element | null => {
    return (
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h3"
          sx={{ mb: 3 }}
          dangerouslySetInnerHTML={{ __html: String(name) }}
        />

        <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <EssayStatsView stats={stats} />
          <TextareaAutoresize
            value={text || ""}
            onChange={setText}
            placeholder={t("Exercise:EnterText") ?? ""}
          />
        </Box>
      </Box>
    );
  };

  return isChecked ? (
    <CreativeExercisePlug state={state} checkingResultId={checkingResultId} />
  ) : (
    renderDefault()
  );
});
