import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const ExerciseResultCorrectionModel = types
  .model("ExerciseResultCorrection", {
    currentId: types.optional(types.string, ""),
    startPosition: types.optional(types.number, 0),
    endPosition: types.optional(types.number, 0),
    text: types.optional(types.string, ""),
  });

type ExerciseResultCorrectionModelType = Instance<typeof ExerciseResultCorrectionModel>;
type ExerciseResultCorrectionModelTypeSnapshotType = SnapshotOut<typeof ExerciseResultCorrectionModel>;

export interface ExerciseResultCorrection extends ExerciseResultCorrectionModelType {}
export type ExerciseResultCorrectionSnapshot = ExerciseResultCorrectionModelTypeSnapshotType;