import { styled } from "@mui/material";
import { Link as LinkStyled } from "react-router-dom";

export const Link = styled(LinkStyled)(() => ({
  margin: 0,
  color: "#562C8E",
  textDecoration: "underline",
  textDecorationColor: "rgba(86, 44, 142, 0.4)",
  ":hover": {
    textDecorationColor: "inherit",
  },
}));
