import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { Text } from '../Text';

import styles from './SelectItem.module.scss';

export interface SelectItemProps {
	item: SelectItemData;
  selected?: boolean;
  onClick?: (_: string | undefined) => void;
  variant: 'primary' | 'secondary';
}

export type SelectItemData = {
	value: string | undefined;
	label: string;
}

export const SelectItem = observer((props: SelectItemProps) => {
  const {
		item,
    selected,
    onClick,
    variant
	} = props;

  const {
		value,
		label
  } = item;

  const handleClick = useCallback(() => {
    onClick?.(value);
  }, [
    value,
    onClick
  ]);

  const containerClasses = cn(
    styles[`container-${variant}`],
    {
      [styles[`selected-${variant}`]]: selected
    }
  )

	return (
		<div
      className={containerClasses}
      onClick={handleClick}
    >
			<Text variant='text-3-medium'>
        {label}
      </Text>
		</div>
	)
})
