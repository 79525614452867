import { Typography, useTheme } from "@mui/material";

import { Icon } from "components/Icon";

import {
  IconBox,
  DesktopContainer,
  TabletContainer,
  WordContainer,
  WordGroup,
  WordSection,
} from "./styledComponents";

export interface WordProps {
  word?: string;
  translation?: string;
  translated?: boolean;
  learned?: boolean;
  onClickLanguage?: () => void;
  onClickBookmark?: () => void;
  onClickVoice?: () => void;
  onClickCheck?: () => void;
  onClickTrash?: () => void;
}

export const WordCard = ({
  word,
  translation,
  translated,
  learned,
  onClickLanguage,
  onClickBookmark,
  onClickVoice,
  onClickTrash,
}: WordProps): JSX.Element => {
  const theme = useTheme();

  const LangIcon = (
    <IconBox onClick={onClickLanguage}>
      <Icon type={translated ? "ruLang" : "engLang"} width={14} height={14} />
    </IconBox>
  );
  const CheckIcon = learned && (
    <IconBox sx={{ cursor: "default", pointerEvents: "none" }}>
      <Icon
        stroke={theme.palette.success.main}
        type="check"
        width={15}
        height={15}
      />
    </IconBox>
  );
  const BookmarkIcon = (
    <IconBox onClick={onClickBookmark}>
      <Icon type="bookOutline" width={14} height={14} />
    </IconBox>
  );
  const TrashIcon = (
    <IconBox onClick={onClickTrash}>
      <Icon type="bxTrash" width={15} height={15} />
    </IconBox>
  );
  const VoiceIcon = (
    <IconBox onClick={onClickVoice}>
      <Icon type="bxVolumeFull" width={14} height={14} />
    </IconBox>
  );
  const Word = (
    <Typography fontSize="0.8rem" maxHeight="2.3rem" overflow="hidden">
      {translated ? translation : word}
    </Typography>
  );
  return (
    <WordContainer>
      <DesktopContainer>
        <WordSection>
          <WordGroup>
            {LangIcon}
            {BookmarkIcon}
          </WordGroup>
          {CheckIcon}
        </WordSection>
        <WordSection alignItems="flex-end">
          <WordGroup>
            {Word}
            {VoiceIcon}
          </WordGroup>
          {TrashIcon}
        </WordSection>
      </DesktopContainer>
      <TabletContainer>
        <WordSection>
          <WordGroup>
            {CheckIcon}
            {LangIcon}
            {BookmarkIcon}
          </WordGroup>
          {TrashIcon}
        </WordSection>
        <WordSection alignItems="flex-end">
          {Word}
          {VoiceIcon}
        </WordSection>
      </TabletContainer>
    </WordContainer>
  );
};
