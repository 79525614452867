import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MenuResponsive = styled(Box)(({ theme }) => ({
  display: "none",
  alignItems: "center",
  cursor: "pointer",
  justifyContent: "center",
  [theme.breakpoints.down("tablet")]: {
    display: "flex",
  },
}));
