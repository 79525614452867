import { ButtonBaseProps, ButtonBase, styled } from "@mui/material";

interface StyledButtonProps extends ButtonBaseProps {
  variant: "add" | "history";
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "variant",
})<StyledButtonProps>(({ theme, variant }) => ({
  ...theme.typography.button,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
  minHeight: 32,
  padding: theme.spacing(2, 4),
  color: theme.palette.info.contrastText,
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.info.main,
  transition: theme.transitions.create(["background-color"]),

  "&:hover": {
    backgroundColor: theme.palette.info.dark,
  },

  ...(variant === "history" && {
    backgroundColor: theme.palette.custom.grey.accentText,

    "&:hover": {
      backgroundColor: theme.palette.custom.grey.accentText,
    },
  }),
}));
