import { observer } from 'mobx-react'
import cn from 'classnames'

import styles from './Pagination.module.scss';

import { useMemo } from 'react';
import { useCallback } from 'react';
import { PageItem } from './PageItem';
import { Icon } from '../Icon';

interface PaginationProps {
  containerClassName?: string
	totalCount?: number;
	pageSize?: number;
	siblingCount?: number;
	currentPage?: number;
	allText?: string;
	onAllClick?: () => void;
	onPageChange?: (_: number) => void;
}

const DOTS = '...'

export const Pagination = observer(function Pagination(props: PaginationProps) {
  const {
		containerClassName,
		totalCount = 1,
		pageSize = 1,
		siblingCount = 1,
		currentPage = 1,
		allText,
		onAllClick,
		onPageChange,
	} = props

  const containerClasses = cn(styles.container, containerClassName)

	const range = (start: number, end: number) => {
		let length = end - start + 1;
		return Array.from({ length }, (_, idx) => idx + start);
	};

	const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 1;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = currentPage + 1;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = totalPageCount - currentPage + 2;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }

		return [];
  }, [totalCount, pageSize, siblingCount, currentPage]);

	const lastPage = paginationRange[paginationRange.length - 1] ?? 1;

  const handlePrev = useCallback(() => {
		if (currentPage === 1) {
			return;
		}
    onPageChange?.(currentPage - 1);
  }, [onPageChange, currentPage]);

	const handleNext = useCallback(() => {
		if (currentPage === lastPage) {
			return;
		}
    onPageChange?.(currentPage + 1);
  }, [onPageChange, lastPage, currentPage]);

	const handlePageChange = useCallback((page: number) => () => {
		onPageChange?.(page);
	}, [onPageChange]);

	const Pages = useMemo(() => {
		return paginationRange.map((pageNumber, index) => {
			if (pageNumber === DOTS) {
				return (
					<PageItem
						key={`${pageNumber}${index}`}
						className={styles.page}
					>
						&#8230;
					</PageItem>
				)
			}

			const isActive = pageNumber === currentPage;

			const pageClass = cn(styles.page, {
				[styles.active]: isActive
			});

			return (
				<PageItem
					key={pageNumber}
					className={pageClass}
					variant={isActive ? 'text-2-medium' : 'text-3-semibold'}
					color={isActive ? 'base-w' : 'wet-asphalt'}
					onClick={handlePageChange(+pageNumber)}
				>
					{pageNumber}
				</PageItem>
			)
		})
	}, [
		paginationRange,
		handlePageChange,
		currentPage
	])

	if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

	return (
		<div className={containerClasses}>
			<PageItem
				className={styles.page}
				onClick={handlePrev}
			>
				<Icon icon='prev-arrow' />
			</PageItem>
			{Pages}
			<PageItem
				className={styles.page}
				onClick={handleNext}
			>
				<Icon icon='next-arrow' />
			</PageItem>
			{allText && (
				<PageItem
					className={styles.all}
					onClick={onAllClick}
				>
					{allText}
				</PageItem>
			)}
		</div>
	)
})
