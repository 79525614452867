/* eslint-disable react/destructuring-assignment */

import { Box, Typography } from "@mui/material";

import { useCurrentBreakpoint } from "@packages/shared/themes/breakpoints/breakpoints";

import { Icon, IconType } from "components/Icon";

import greenBg from "./images/green_bg.svg";
import redBg from "./images/red_bg.svg";
import violetBg from "./images/violet_bg.svg";

type bgType = "violet" | "red" | "green";

export interface HRWidgetProps {
  title: string;
  number: string;
  background: bgType;
  iconType: IconType;
  className?: string;
}

export const HRWidget = ({
  title,
  number,
  background,
  iconType,
  className,
}: HRWidgetProps): JSX.Element => {
  const breakpoint = useCurrentBreakpoint();
  const getBgType = (bg: bgType): void => {
    const defaultType = greenBg;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bgTypes: { [key: string]: any } = {
      violet: violetBg,
      red: redBg,
      green: greenBg,
    };
    return bgTypes[bg] || defaultType;
  };
  return (
    <Box
      sx={{
        width: "20.25rem",
        height: "8.125rem",
        background: `url(${getBgType(background)})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "end",
        padding: "1.25rem 1rem",
      }}
      className={className}
    >
      <Box>
        {breakpoint !== "zeroScreen" && (
          <Box>
            <Icon
              type={iconType}
              stroke="primary.contrastText"
              color="primary.contrastText"
              width={24}
              height={24}
            />
          </Box>
        )}

        <Typography
          variant="h1"
          sx={{
            fontSize: "30px",
            letterSpacing: "0.01em",
          }}
          color="primary.contrastText"
        >
          {number}
        </Typography>
        <Typography variant="h3" color="primary.contrastText" pt={2}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
