import { useEffect } from "react";

import { Box } from "@mui/material";
import { useStores } from "@packages/store/models";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";
import { observer } from "mobx-react-lite";
import { WrapperMainStudents } from "pages/Student/MainStudents/components/styledComponents";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";

import { PayrollGroups, ReferenceBooks } from "../components";

export const MainPayrollAccountant = observer((): JSX.Element => {
  const { t } = useTranslation();

  const { loading, getTaggingLists } = useStores();

  const { fetch: fetchTaggingLists } = useCancelableFetch();

  useEffect(() => {
    fetchTaggingLists(() => getTaggingLists());
  }, [fetchTaggingLists, getTaggingLists]);

  return (
    <Loading loading={loading}>
      <WrapperMainStudents>
        <PayrollGroups />
        <PageBlock title={t("Payroll:TeachersReferenceBooks")}>
          <Box sx={{ margin: "1.5rem" }} />
          <ReferenceBooks />
        </PageBlock>
        <Outlet />
      </WrapperMainStudents>
    </Loading>
  );
});
