import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { StickySection } from "../../../../../../components/StickySection";
import { observer } from "mobx-react-lite";
import { AnswerDragLayer } from "../../../../../../components/AnswerDragLayer";
import { MovingQuestion } from "../../../../../../components/MovingQuestion";
import { PossibleAnswers } from "../../../../../../components/PossibleAnswers";
import { tablet } from "../../../../../../constants/styles";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

export const Inline = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down(tablet));

    const {
      answer,
      possibleAnswers,
      questions,
      properties,
      isChecked,
      isMovingWithImages,
    } = exercise;

    const { allAnswerIds, insertAnswer, getQuestion } = ensure(answer);

    const {
      showPossibleAnswersForEachQuestion: separatedPossibleAnswers,
      reusableAnswers,
    } = properties || {};

    return (
      <Box
        sx={
          !isTablet
            ? {
                maxHeight: "600px",
                overflowY: "auto",
                position: "relative",
              }
            : {}
        }
      >
        <AnswerDragLayer />

        {!isChecked && !separatedPossibleAnswers && (
          <>
            {isTablet ? (
              <StickySection top={5}>
                <PossibleAnswers
                  answers={possibleAnswers}
                  insertAnswer={insertAnswer}
                  // prettier-ignore
                  {...(reusableAnswers ? { reusableAnswers } : { allAnswerIds })}
                />
              </StickySection>
            ) : (
              <PossibleAnswers
                answers={possibleAnswers}
                insertAnswer={insertAnswer}
                {...(reusableAnswers ? { reusableAnswers } : { allAnswerIds })}
                isSticky
              />
            )}
          </>
        )}

        <ObserverFlexList
          gap={4}
          items={questions}
          renderItem={(question: Question, index, array: Question[]) => (
            <div key={question.id}>
              {!isChecked && separatedPossibleAnswers && (
                <PossibleAnswers
                  allAnswerIds={allAnswerIds}
                  answers={question.possibleAnswers}
                  question={question.id}
                  insertAnswer={insertAnswer}
                  isSticky
                />
              )}

              <MovingQuestion
                question={question}
                answerData={ensure(getQuestion(question.id))}
                {...(array.length > 1 && {
                  order: index + 1,
                })}
              />
            </div>
          )}
          containerStyles={{
            flexDirection: isMovingWithImages ? "row" : "column",
            flexWrap: isMovingWithImages ? "wrap" : "nowrap",
          }}
        />
      </Box>
    );
  }
);
