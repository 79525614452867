// import { useCallback, useRef, useState } from "react";

import { createContext, Dispatch, SetStateAction } from "react";

// export const DragDropContext = <T extends HTMLElement>() => {
//   // const [dragRef, setDragRef] = useState<T | null>(null);
//   const [draggedSize, setDraggedSize] = useState<Size>({
//     width: 0,
//     height: 0,
//   });

//   // const handleSize = useCallback(() => {
//   //   if (dragRef) {
//   //     const { width, height } = dragRef.getBoundingClientRect();
//   //     setDraggedElSize({ width, height });
//   //   }
//   // }, [dragRef?.offsetHeight, dragRef?.of]);

//   return ;
// };

export interface DragSize {
  width: number;
  height: number;
}

export interface DragDropContextType {
  dragSize: DragSize;
  setDragSize: Dispatch<SetStateAction<DragSize>>;
}

export const DragDropContext = createContext<DragDropContextType | undefined>(
  undefined
);
