import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const OptionModel = types.model({
  id: types.identifier,
  text: types.maybeNull(types.string),
});

type OptionModelType = Instance<typeof OptionModel>;
type OptionModelSnapshotType = SnapshotOut<typeof OptionModel>;

export type Option = OptionModelType;
export type OptionSnapshot = OptionModelSnapshotType;
