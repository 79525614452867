import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { ScrollToTop } from "router/ScrollToTop";

import { App } from "./App";
import { initI18n } from "./locales/i18n";

import "./index.css";

initI18n();

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const element = (
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
);

root.render(element);
