import { styled } from "@mui/material/styles";

export const LeftCardContent = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "48%",
  [theme.breakpoints.down(400)]: {
    width: "100%",
  },
}));
