import { ChangeEvent, useEffect, useRef, useState } from "react";

import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Popover,
  Box,
} from "@mui/material";
import { format, isSameDay, parse } from "date-fns";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

import { Button } from "components/Button";
import { Icon } from "components/Icon";

import { Calendar } from "./Calendar";

type Value = Date | null;

const dateToString = (date: Value) => {
  return date ? format(date, "dd/MM/yyyy") : "";
};

export interface DatePickerProps {
  value: Value;
  onChange: (value: Value) => void;
  label?: string | null;
  textFieldProps?: TextFieldProps;
}

export const DatePicker = ({
  value,
  onChange,
  label,
  textFieldProps,
}: DatePickerProps): JSX.Element | null => {
  const { t } = useTranslation();
  const anchorElRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [innerValue, setInnerValue] = useState<string>(() =>
    dateToString(value)
  );
  // Этот стейт нужен что-бы ничего не показывать в календаре пока в инпуте не полная дата
  const [innerDate, setInnerDate] = useState<Date | null>(value ?? new Date());

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const dateRegex = /\d{2}\/\d{2}\/\d{4}/;
    const { value: inputValue } = event.target;

    setInnerValue(inputValue);

    // Вызов верхнего события только когда дата введена полностью
    if (dateRegex.test(inputValue)) {
      onChange(parse(inputValue, "dd/MM/yyyy", new Date()));
      setInnerDate(parse(inputValue, "dd/MM/yyyy", new Date()));
    } else {
      setInnerDate(null);
    }
  };

  // Ограничение на ввод только чисел
  const beforeMaskedStateChange = (state: any) => {
    const { nextState, previousState } = state;

    if (Number.isNaN(+nextState.enteredString)) {
      return {
        ...nextState,
        ...previousState,
      };
    }

    return nextState;
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleCalendarChange = (date: Date, shouldClose = false) => {
    onChange(date);
    setInnerDate(date);
    setInnerValue(dateToString(date));

    if (shouldClose) {
      handleClose();
    }
  };

  const handleClear = () => {
    onChange(null);
    setInnerDate(null);
    setInnerValue("");
    handleClose();
  };

  const handleClickToday = () => {
    onChange(new Date());
    setInnerDate(new Date());
    setInnerValue(dateToString(new Date()));
    handleClose();
  };

  useEffect(() => {
    if (value === innerDate) {
      return;
    }

    if (value === undefined) return;

    if (
      (value === null && innerDate !== null) ||
      (value !== null && innerDate === null) ||
      !isSameDay(value as Date, innerDate as Date)
    ) {
      setInnerValue(dateToString(value));
      setInnerDate(value);
    }
  }, [value, innerDate]);

  return (
    <>
      <InputMask
        mask="99/99/9999"
        alwaysShowMask
        maskPlaceholder="dd/mm/yyyy"
        beforeMaskedStateChange={beforeMaskedStateChange}
        value={innerValue}
        onChange={handleInputChange}
      >
        <TextField
          ref={anchorElRef}
          type="text"
          label={label}
          variant="outlined"
          sx={{ maxWidth: "10rem" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                <Button variant="iconBtn" onClick={handleOpen}>
                  <Icon
                    type="calendar"
                    sizeSquareIcon={20}
                    stroke="currentColor"
                  />
                </Button>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          {...textFieldProps}
        />
      </InputMask>
      <Popover
        open={isOpen}
        anchorEl={anchorElRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Calendar
          value={innerDate ?? new Date()}
          onChange={handleCalendarChange}
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="0.5rem"
          mx="0.5rem"
        >
          <Button onClick={handleClickToday}>{t("DatePicker:Today")}</Button>
          <Button onClick={handleClear}>{t("DatePicker:Clear")}</Button>
        </Box>
      </Popover>
    </>
  );
};
