import { ButtonBaseProps, ButtonBase, styled } from "@mui/material";

interface StyledButtonProps extends ButtonBaseProps {
  variant?: "confirm" | "reset";
}

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop) =>
    prop !== "variant" &&
    prop !== "active" &&
    prop !== "small" &&
    prop !== "rotatedSvg",
})<StyledButtonProps>(({ theme, variant = "confirm" }) => ({
  ...theme.typography.regularText,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(3),
  width: "100%",
  minHeight: 32,
  padding: theme.spacing(2, 3),
  color: theme.palette.success.contrastText,
  border: `1px solid transparent`,
  borderRadius: theme.spacing(3),
  backgroundColor: theme.palette.success.main,
  transition: theme.transitions.create(["background-color"]),

  "&:hover": {
    backgroundColor: theme.palette.success.dark,
  },

  ...(variant === "reset" && {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.info.contrastText,
    borderColor: theme.palette.info.main,

    "&:hover": {
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.main,
      borderColor: theme.palette.info.main,
    },
  }),
}));
