import { Box } from "@mui/material";
import { Block } from "@packages/store/models/Block/Block";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Modal } from "../../shared/Modal";
import { DontShowAgain } from "../components/DontShowAgain";
import { Items } from "../components/Items";
import { Summary } from "../components/Summary";

interface ModalBlockResultProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  block: Block;
  onExerciseChange: (exerciseId: string) => () => void;
}

export const ModalBlockResult = observer(
  ({
    isOpen,
    onConfirm,
    onClose,
    block,
    onExerciseChange,
  }: ModalBlockResultProps): JSX.Element | null => {
    const { t } = useTranslation();

    const handleRepeatClick = () => {
      if (!block.firstNotPassedExercise) return;

      onExerciseChange(block.firstNotPassedExercise.id)();
      onClose();
    };

    const handleContinueClick = () => {
      onConfirm();
      onClose();
    };

    const handleExerciseClick = (exerciseId: string) => {
      onExerciseChange(exerciseId)();
      onClose();
    };

    return (
      <Modal
        open={isOpen}
        onClose={onClose}
        type="small"
        title={t("ResultsModals:BlockTitle", { name: block.name })}
        titleColor="primary.main"
        containerStyles={{ paddingBottom: "1.5rem" }}
      >
        <Box display="flex" flexDirection="column" gap="24px">
          <Summary
            passedCount={block.totalExercisesPassed}
            wrongCount={block.wrongCompletedExercises}
            skippedCount={block.totalNotPassedWrongExercises}
          />

          <Items
            items={block.sortedExercises}
            getItemName={(id) => block.getExercise(id)?.name ?? ""}
            showRepeat={!block.isPassed}
            type="block"
            onRepeatClick={handleRepeatClick}
            onContinueClick={handleContinueClick}
            onItemClick={handleExerciseClick}
          />

          <DontShowAgain />
        </Box>
      </Modal>
    );
  }
);
