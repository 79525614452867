import React, { useEffect } from "react";

import { Box, Typography, TextField } from "@mui/material";
import { format, addDays } from "date-fns";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  notEditableAttendanceStatuses,
  notEditableDurationStatusesForTeacher,
  MAX_COMMENT_WORDS_COUNT,
  NotEditableDateStatusesForTeachersOutsideTheCurrentMonth,
} from "@packages/store/models/Lesson/LessonModel";

import { Button } from "components/Button";
import { Icon } from "components/Icon";
import { Select } from "components/Select";

import { Duration } from "./components/Duration";
import { Students } from "./components/Students";
import { UnitNumber } from "./components/UnitNumber";
import { ModalContainer, ModalBody, ModalForm } from "./styledComponents";

export interface ModalAttendanceStudent {
  label: string;
  value: number;
  checked: boolean;
}

export interface ModalAttendanceStatus {
  label: string;
  value: string;
}

export interface ModalAttendanceFormValues {
  comment: string | undefined;
  status: string | undefined;
  date: string | null;
  duration: number | undefined;
  actualUnitId: string;
  studentIds: number[];
}

export interface ModalAttendanceProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: ModalAttendanceFormValues) => void;
  unitId: string;
  allStatuses: ModalAttendanceStatus[];
  students: ModalAttendanceStudent[];
  isTeacher: boolean | undefined | null;
  comment?: string;
  status?: string;
  date?: Date | null;
  duration?: number;
  editable?: boolean | null;
  actualUnitId?: string;
  restrictiveDate?: Date | null;
  courseId?: string;
}

export const ModalAttendance: React.FC<ModalAttendanceProps> = ({
  isTeacher,
  isOpen,
  onClose,
  onSubmit,
  unitId = "",
  allStatuses = [],
  students = [],
  comment,
  status,
  date,
  duration,
  actualUnitId,
  editable,
  restrictiveDate,
  courseId,
}) => {
  const { t } = useTranslation();

  const localeDate = date
    ? `${format(date, "yyyy-MM-dd")}T${format(date, "HH:mm")}`
    : null;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<ModalAttendanceFormValues>({
    mode: "all",
    defaultValues: {
      comment,
      status,
      date: localeDate,
      duration,
      actualUnitId,
      studentIds: students
        .filter(({ checked }) => checked)
        .map(({ value }) => value),
    },
  });

  const { status: selectedStatus, date: selectedDate } = watch();

  const disabled = notEditableAttendanceStatuses.includes(selectedStatus || "");
  const disabledDurationChangeForTeacher =
    !!isTeacher &&
    notEditableDurationStatusesForTeacher.includes(selectedStatus || "");

  const validateDate = (value: string | null | undefined) => {
    // Эти проверки только для преподавателя
    if (!value || !isTeacher) return true;

    const selectedDateObj = new Date(value);

    // Сравнение без учёта времени
    const isBeforeOrSame = (date1: Date, date2: Date) => {
      return (
        new Date(
          date1.getFullYear(),
          date1.getMonth(),
          date1.getDate()
        ).getTime() <=
        new Date(
          date2.getFullYear(),
          date2.getMonth(),
          date2.getDate()
        ).getTime()
      );
    };

    if (
      restrictiveDate &&
      editable &&
      isBeforeOrSame(selectedDateObj, restrictiveDate)
    ) {
      return t("ModalAttendance:restrictiveBackDateError").toString();
    }

    if (!date) return true;
    // Проверка на дату больше чем на месяц от первоначальной
    if (selectedDateObj.getTime() > addDays(date, 30).getTime()) {
      return t("ModalAttendance:restrictiveForwardDateError").toString();
    }

    if (!selectedStatus) return true;
    if (
      NotEditableDateStatusesForTeachersOutsideTheCurrentMonth.includes(
        selectedStatus
      ) &&
      selectedDateObj.getMonth() > date.getMonth()
    ) {
      return t("ModalAttendance:restrictiveForwardDateError").toString();
    }

    return true;
  };

  const validateComment = (value: string | undefined) => {
    const size = (value?.match(/\S+/g) || []).length;

    if (size > MAX_COMMENT_WORDS_COUNT) {
      return t("ModalAttendance:commentSizeError", {
        count: MAX_COMMENT_WORDS_COUNT,
      }).toString();
    }

    return true;
  };

  /*
    Нужно сбрасывать значение duration на первоначальное чтобы когда меняется
    disabledDurationChangeForTeacher чтобы duration нельзя было случайно отправить измененным 
  */
  useEffect(() => {
    if (disabledDurationChangeForTeacher) {
      setValue("duration", duration);
    }
  }, [disabledDurationChangeForTeacher, duration, setValue]);

  return (
    <ModalContainer disableScrollLock={false} open={isOpen} onClose={onClose}>
      <ModalForm onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2">
              {unitId}
              {selectedDate
                ? ` - ${format(new Date(selectedDate), "dd.MM.yyyy-HH:mm")}`
                : ""}
            </Typography>
            <Button variant="iconBtn" onClick={onClose}>
              <Icon type="close" width={24} height={24} />
            </Button>
          </Box>

          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gap: "30px",
              mt: "2rem",
              mb: "1.5rem",
              alignItems: "flex-start",
            }}
          >
            <Select
              data={allStatuses}
              placeholder={t("ModalAttendance:status")}
              disabled={!editable}
              value={selectedStatus}
              sx={{
                fontSize: "13px",
                height: 40,
                marginTop: "0!important",
                "& .MuiSelect-select": { width: "100%!important" },
              }}
              menuItems={{
                sx: {
                  fontSize: "13px",
                },
              }}
              labelProps={{
                sx: {
                  top: "-0.5rem",
                },
              }}
              {...register("status", {
                required: t("ModalAttendance:requiredFieldError").toString(),
              })}
              error={!!errors.status}
              helperText={errors.status?.message || " "}
            />
            <TextField
              id="datetime-local"
              type="datetime-local"
              label={t("TeacherMyClasses:dateTime")}
              variant="outlined"
              disabled={!editable}
              value={selectedDate}
              InputLabelProps={{
                shrink: true,
                sx: {
                  backgroundColor: "white",
                },
              }}
              InputProps={{
                sx: {
                  fontSize: "13px",
                  height: 40,
                },
              }}
              {...register("date", {
                required: t("ModalAttendance:requiredDateError").toString(),
                validate: validateDate,
              })}
              error={!!errors.date}
              helperText={errors.date?.message || " "}
            />

            <Duration
              control={control}
              disabled={!editable || disabledDurationChangeForTeacher}
            />

            <UnitNumber
              control={control}
              disabled={!editable}
              courseId={courseId}
            />
          </Box>

          {students.length && (
            <Students
              control={control}
              students={students}
              disabled={!editable || disabled}
            />
          )}

          <TextField
            sx={{
              width: "100%",
              borderBottom: "none",
              marginBottom: "1.25rem",
            }}
            label={t("ModalAttendance:comment")}
            variant="outlined"
            disabled={!editable}
            multiline
            rows={3}
            {...register("comment", { validate: validateComment })}
            error={!!errors.comment}
            helperText={errors.comment?.message || " "}
          />

          <Box display="flex" justifyContent="flex-end">
            <Button variant="primary" type="submit">
              {t("ModalAttendance:submit")}
            </Button>
          </Box>
        </ModalBody>
      </ModalForm>
    </ModalContainer>
  );
};
