import { styled } from "@mui/material";

import { LogoContainerProps } from "../index";

export const LogoContainer = styled("div")<LogoContainerProps>(
  ({ theme, color = theme.palette.primary?.dark, width, height }) => {
    return {
      "& svg": {
        fill: color,
        width,
        height,
      },
    };
  }
);
