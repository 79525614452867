/* eslint-disable @typescript-eslint/no-shadow */
import { Box } from "@mui/material";
import { useStores } from "@packages/store/models";
import { ChangePasswordProps } from "@packages/store/models/AuthStore";
import { Button } from "components/Button";
import { PasswordStrength } from "components/PasswordStrength";
import { observer } from "mobx-react-lite";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputsContainer, PasswordField } from "./styledComponents";

export interface ChangePasswordFormProps {
  prefferedLanguage?: string;
  onPasswordChanged?: () => void;
}

export const ChangePasswordForm = observer(
  ({ prefferedLanguage, onPasswordChanged }: ChangePasswordFormProps) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setError,
      watch,
    } = useForm<ChangePasswordProps>();
    const { t } = useTranslation();

    const langSettings = { lng: prefferedLanguage };

    const { password } = watch();

    const { auth } = useStores();

    const { changePassword } = auth;

    const onSubmit: SubmitHandler<ChangePasswordProps> = async ({
      password,
      passwordRepeat,
    }) => {
      if (password.length < 6) {
        setError("password", {
          message: t(`ChangePassword:PasswordShortError`, langSettings) ?? "",
        });
        return;
      }
      if (password !== passwordRepeat) {
        setError("passwordRepeat", {
          message: t(`ChangePassword:PasswordMismatch`, langSettings) ?? "",
        });
        return;
      }
      const passwordChange = await changePassword(password);
      if (!passwordChange) {
        return;
      }
      onPasswordChanged?.();
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputsContainer>
          <Box position="relative">
            <PasswordField
              variant="standard"
              label={t(`ChangePassword:Password`, langSettings)}
              {...register("password", { required: true })}
              error={!!errors.password}
              helperText={errors.password?.message || " "}
            />
            <PasswordStrength
              password={password}
              prefferedLanguage={prefferedLanguage}
            />
          </Box>
          <PasswordField
            variant="standard"
            label={t(`ChangePassword:PasswordRepeat`, langSettings)}
            {...register("passwordRepeat", { required: true })}
            error={!!errors.passwordRepeat}
            helperText={errors.passwordRepeat?.message || " "}
          />
        </InputsContainer>
        <Button type="submit" variant="primary" sx={{ width: "100%" }}>
          {t(`ChangePassword:Confirm`)}
        </Button>
      </form>
    );
  }
);
