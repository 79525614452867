import { ReactNode } from "react";

import { Typography } from "@mui/material";

import { BlockContainer } from "components/Footer/styledComponents";

interface PageBlockProps {
  title: string;
  children: ReactNode;
}

export function PageBlock({ title, children }: PageBlockProps) {
  return (
    <BlockContainer>
      <Typography variant="h1" component="span">
        {title}
      </Typography>
      {children}
    </BlockContainer>
  );
}
