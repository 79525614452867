import i18n from "i18next";

const createRuWordDeclensionEndings = (
  titles: string[],
  number: number | undefined
) => {
  const cases = [2, 0, 1, 1, 1, 2];

  if (!number) {
    return titles[2];
  }

  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

const createEnWordDeclensionEndings = (
  titles: string[],
  number: number | undefined
) => {
  if (number === 1) {
    return titles[0];
  }

  return titles[1];
};

export const wordDeclensionEndings = (
  word: string,
  number: number | undefined
) => {
  if (word && !!word.length) {
    if (i18n.language === "ru") {
      return createRuWordDeclensionEndings(
        [
          i18n.t(`Module:${word}_one`),
          i18n.t(`Module:${word}_two`),
          i18n.t(`Module:${word}_few`),
        ],
        number
      );
    }
    return createEnWordDeclensionEndings(
      [i18n.t(`Module:${word}_one`), i18n.t(`Module:${word}_few`)],
      number
    );
  }
  return null;
};
