/* eslint-disable @typescript-eslint/no-empty-interface */
import { observer } from "mobx-react-lite";

import { ListProps, List, ObserverList } from "../List";

interface FlexListProps<T> extends ListProps<T> {
  direction?: "row" | "column";
  wrap?: boolean;
  gap?: number | string;
}

export const FlexList = <T,>({
  direction = "column",
  wrap,
  gap = 3,
  containerStyles,
  ...rest
}: FlexListProps<T>): JSX.Element | null => {
  return (
    <List
      containerStyles={{
        display: "flex",
        flexDirection: direction,
        flexWrap: wrap ? "wrap" : "nowrap",
        gap,
        ...containerStyles,
      }}
      {...rest}
    />
  );
};

export const ObserverFlexList = observer(
  <T,>({
    direction = "column",
    wrap,
    gap = 3,
    containerStyles,
    ...rest
  }: FlexListProps<T>): JSX.Element | null => {
    return (
      <ObserverList
        containerStyles={{
          display: "flex",
          flexDirection: direction,
          flexWrap: wrap ? "wrap" : "nowrap",
          gap,
          ...containerStyles,
        }}
        {...rest}
      />
    );
  }
);
