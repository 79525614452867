import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LeftContainer = styled(Box)(() => ({
  display: "flex",
  flex: "auto",
  alignItems: "center",
  width: "10.5rem",
  gap: "15px",
}));
