import { Box } from "@mui/material";
import { Question } from "@packages/store/models/Question/Question";
import { observer } from "mobx-react-lite";

import { ensure } from "../../../../../../helpers/ensure";
import { SubExerciseComponentProps } from "../../../../../../types";
import { AnswerDragLayer } from "../../../../../AnswerDragLayer";
import { MatchingQuestion } from "../../../../../MatchingQuestion";

export const Media = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions } = exercise;

    const { getQuestion, swapAnswer } = ensure(answer);

    return (
      <>
        <AnswerDragLayer />
        <Box
          display="flex"
          flexWrap="wrap"
          flex="1 1 0px"
          gap={4}
          justifyContent="center"
        >
          {questions.map((question: Question) => (
            <MatchingQuestion
              key={question.id}
              variant="vertical"
              question={question}
              answerData={ensure(getQuestion(question.id))}
              swapAnswer={swapAnswer}
              fullSizeAnswer
            />
          ))}
        </Box>
      </>
    );
  }
);
