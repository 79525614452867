import { Box, styled } from "@mui/material";

export const UnitLessonContainer = styled(Box)(({ theme }) => ({
  flexGrow: 2,
  margin: "0.25rem 3.25rem 0.25rem 0",
  flexBasis: "25%",

  [theme.breakpoints.down("tablet")]: {
    margin: theme.spacing(0),
    textDecoration: "none",
  },
}));
