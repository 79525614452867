import { styled } from "@mui/material/styles";

export const AvatarsContainer = styled("div")(() => ({
  display: "flex",
  flexBasis: "2.5rem",

  "& :nth-of-type(2)": {
    position: "relative",
    left: "-1.25rem",
  },
  "& :nth-of-type(3)": {
    position: "relative",
    left: "-2.5rem",
  },
}));
