import { Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { Icon } from "components/Icon";

import { CreateReportButton, HeaderContainer } from "./styledComponents";

export const ReportHeader = observer((): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const create = () => {
    navigate("create");
  };

  return (
    <HeaderContainer>
      <Typography variant="h1" component="span">
        {t("Report:HeaderTitle")}
      </Typography>

      <CreateReportButton
        startIcon={<Icon type="add" width={10} height={10} color="white" />}
        color="primary"
        variant="text"
        onClick={create}
      >
        {t("Report:CreateReport")}
      </CreateReportButton>
    </HeaderContainer>
  );
});
