import { ChangeEvent, ForwardedRef, forwardRef } from "react";

import { Box, TextareaAutosize, useTheme, SxProps, Theme } from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies

interface TextareaAutoresizeProps {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  minRows?: number;
  containerStyles?: SxProps<Theme>;
}

export const TextareaAutoresize = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      minRows = 10,
      containerStyles,
    }: TextareaAutoresizeProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ): JSX.Element | null => {
    const theme = useTheme();

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    };

    // const handleFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
    //   const parent = e.target.parentElement;

    //   if (parent && !parent.classList.contains("focused")) {
    //     parent.classList.add("focused");
    //   }
    // };

    // const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    //   const parent = e.target.parentElement;

    //   if (parent && parent.classList.contains("focused")) {
    //     parent.classList.remove("focused");
    //   }
    // };

    return (
      <Box
        sx={{
          flex: 1,
          py: 3,
          px: 4,
          border: "1px solid",
          borderColor: "custom.grey.light",
          borderRadius: 3,
          ...containerStyles,
          // transition: theme.transitions.create(["border-color", "box-shadow"]),
          // "&.focused": {
          //   borderColor: "info.light",
          //   boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
          // },
        }}
      >
        <TextareaAutosize
          ref={ref}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          minRows={minRows}
          spellCheck={false}
          style={{
            ...theme.typography.regularText,
            display: "flex",
            width: "100%",
            fontFamily: "Golos",
            border: "none",
            outline: "none",
            resize: "none",
          }}
        />
      </Box>
    );
  }
);
