import { ForwardedRef, forwardRef } from "react";

import { Typography, styled, TypographyProps } from "@mui/material";

import { StatusType } from "../../../types";

export interface StyledAnswerViewProps extends TypographyProps {
  status: StatusType;
  clickable?: boolean;
  draggable?: boolean;
  dragging?: boolean;
  disabled?: boolean;
  fullSize?: boolean;
  fullWidth?: boolean;
  count?: number;
}

export const StyledAnswerView = styled(
  forwardRef((props: TypographyProps, ref: ForwardedRef<HTMLSpanElement>) => (
    <Typography
      ref={ref}
      variant="regularText"
      align="center"
      component="span"
      {...props}
    />
  )),
  {
    shouldForwardProp: (prop) =>
      prop !== "status" &&
      prop !== "clickable" &&
      prop !== "draggable" &&
      prop !== "dragging" &&
      prop !== "disabled" &&
      prop !== "fullWidth" &&
      prop !== "fullSize" &&
      prop !== "count",
  }
)<StyledAnswerViewProps>(
  ({
    theme,
    clickable,
    draggable,
    dragging,
    disabled,
    fullSize,
    fullWidth,
    status = "default",
    count,
  }) => ({
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: 30,
    padding: theme.spacing(2, 3),
    marginRight: "0.2rem",
    border: `1px solid`,
    borderRadius: theme.spacing(3),
    userSelect: "text",

    color: theme.palette.custom.answerColorVariant.font[status],
    backgroundColor: theme.palette.custom.answerColorVariant.background[status],
    borderColor: theme.palette.custom.answerColorVariant.border[status],

    ...(dragging && {
      opacity: 0.5,
    }),

    ...(fullWidth && {
      width: "100%",
    }),

    ...(fullSize && {
      flex: 1,
      alignSelf: "stretch",
    }),

    ...(clickable && {
      cursor: "pointer",
    }),

    ...(draggable && {
      cursor: "move",
      userSelect: "none",
    }),

    ...(disabled &&
      status === "default" && {
        backgroundColor: theme.palette.custom.grey["emenu-tile-active"],
        cursor: "default",
      }),

    ...(count && {
      "&::before": {
        content: `"${count}"`,
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 19,
        height: 19,
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        borderRadius: "50%",
        transform: "translate(30%, -30%)",
      },
    }),
  })
);
