import { ReactNode } from 'react';
import { observer } from 'mobx-react'
import cn from 'classnames'

import styles from './BlockWrapper.module.scss'

interface BlockWrapperProps {
  containerClassName?: string;
	children?: ReactNode | ReactNode[];
}

export const BlockWrapper = observer(function BlockWrapper(props: BlockWrapperProps) {
  const {
		containerClassName,
		children
	} = props;

  const containerClasses = cn(
		containerClassName,
		styles.container
	);

	return (
		<div className={containerClasses}>
			{children}
		</div>
	)
})
