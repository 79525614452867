import { Button } from "@mui/material";
import { GenerateDistributorReport } from "@packages/store/models/GenerateReport/GenerateReport";
import { JetLink } from "components/JetLink";
import { Table } from "components/Table";
import { observer } from "mobx-react-lite";
import { formatReports } from "pages/Distributor/helpers";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { ReportCell } from "./DistributorReports.styled";

interface DistributorReportsProps {
  reports: GenerateDistributorReport[];
}

const REPORTS_PER_PAGE = 5;

export const DistributorReports = observer(
  ({ reports }: DistributorReportsProps) => {
    const { t } = useTranslation();
    const formattedReports = formatReports(reports);

    const columns = [
      {
        Header: t("Distributor:DateFrom"),
        accessor: "dateFrom",
        Cell: ({ value }: Cell<GenerateDistributorReport, string>) => (
          <ReportCell>{value}</ReportCell>
        ),
      },
      {
        Header: t("Distributor:DateTo"),
        accessor: "dateTo",
        Cell: ({ value }: Cell<GenerateDistributorReport, string>) => (
          <ReportCell>{value}</ReportCell>
        ),
      },
      {
        Header: t("Distributor:Status"),
        accessor: "status",
        Cell: ({ value }: Cell<GenerateDistributorReport, string>) => (
          <ReportCell>{t(`Distributor:Status${value}`)}</ReportCell>
        ),
      },
      {
        Header: t(""),
        accessor: "downloadUrl",
        Cell: ({ row, value }: Cell<GenerateDistributorReport, string>) => (
          <ReportCell sx={{ justifyContent: "flex-end" }}>
            {row.original.status === "Ready" && (
              <JetLink external to={value || ""}>
                <Button variant="secondary">{t("Button:Download")}</Button>
              </JetLink>
            )}
          </ReportCell>
        ),
      },
    ];

    return (
      <div>
        <Table
          columns={columns}
          data={formattedReports}
          count={REPORTS_PER_PAGE}
          containerType="blue"
          emptyTableType="distributor"
        />
      </div>
    );
  }
);
