/* eslint-disable @typescript-eslint/no-empty-interface */
import { uniqBy } from "lodash";
import { types, Instance, SnapshotOut, getRoot } from "mobx-state-tree";

import { RootStore } from "..";
import { PivotReportSchedule } from "../../services/Api";
import type { ClassGroupRate } from "../ClassGroupRate/ClassGroupRate";
import { ClassScheduleModel } from "../ClassSchedule/ClassSchedule";
import { LearningGroup } from "../LearningGroups/LearningGroupModel";
import { Lesson } from "../Lesson/LessonModel";

export const ClassGroupModel = types
  .model({
    id: types.identifier,
    countryCode: types.maybeNull(types.string),
    lessonsPerUnit: types.maybeNull(types.number),
    startAt: types.maybeNull(types.Date),
    timezone: types.maybeNull(types.string),
    totalMinutes: types.maybeNull(types.number),
    type: types.maybeNull(types.string),
    classSchedules: types.optional(
      types.array(types.reference(ClassScheduleModel)),
      []
    ),
  })
  .views((self) => ({
    get learningGroup(): LearningGroup | undefined {
      return getRoot<RootStore>(self).learningGroup.items.find(
        (item: LearningGroup) =>
          item.classGroups.map((x: ClassGroup) => x.id).includes(self.id)
      );
    },
    get lessons(): Lesson[] {
      return self.classSchedules.flatMap(
        (schedule) => schedule.lessons as Lesson[]
      );
    },
    get teachers() {
      return self.classSchedules.flatMap((schedule) => schedule.actualTeachers);
    },
    get rates(): ClassGroupRate[] {
      const { classGroupRate } = getRoot<RootStore>(self);
      const { items: rates } = classGroupRate;
      return rates.filter(({ classGroupId }) => classGroupId === self.id);
    },
  }))
  .views((self) => ({
    get lessonsGroupByDate() {
      const lessonsByDate = new Map();
      self.lessons.forEach((lesson) => {
        const date = lesson.day;
        if (!date) {
          return;
        }
        const lessons = lessonsByDate.get(date);
        if (!lessons) {
          lessonsByDate.set(date, [lesson]);
        } else {
          lessons.push(lesson);
        }
      });
      return lessonsByDate;
    },
  }))
  .views((self) => ({
    get students() {
      const { students = [] } = self.learningGroup ?? {};
      return uniqBy(students, ({ id }) => id);
    },
  }))
  .views((self) => ({
    get scheduleTimes(): PivotReportSchedule[] {
      const timeToMinutes = (time: string) => {
        const [hours = 0, minutes = 0] = time.split(":");
        return +hours * 60 + +minutes;
      };

      const minutesToTime = (totalMinutes: number) => {
        const hours = Math.floor(totalMinutes / 60)
          .toString()
          .padStart(2, "0");
        const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        return `${hours}:${minutes}`;
      };

      const times = self.classSchedules.flatMap((schedule) =>
        schedule.weekDays.map((day) => {
          const startTime = schedule.startTime ?? "";
          const minutes = timeToMinutes(startTime);
          const duration = schedule.durationInMinutes ?? 0;
          const endTime = minutesToTime(minutes + duration);
          return { day, startTime, endTime } as PivotReportSchedule;
        })
      );

      return uniqBy(
        times,
        ({ day, startTime, endTime }) => `${day} ${startTime} ${endTime}`
      );
    },
  }));

type ClassGroupType = Instance<typeof ClassGroupModel>;
export interface ClassGroup extends ClassGroupType {}
type ClassGroupSnapshotType = SnapshotOut<typeof ClassGroupModel>;
export type ClassGroupSnapshot = ClassGroupSnapshotType;
