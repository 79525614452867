import { useState } from "react";

import { Box, Typography, useTheme } from "@mui/material";

import { AvatarPerson } from "components/AvatarPerson";
import { Icon } from "components/Icon/Icon";
import { IUserMenuItem } from "components/UserMenu/UserMenuData";
import { UserMenuPopover } from "components/UserMenu/UserMenuPopover";

import { UserMenuContainer } from "./styledComponents/UserMenuContainer";

export interface UserMenuProps {
  name: string;
  userRole: string;
  menuItems: IUserMenuItem[];
  extended?: boolean;
}

export const UserMenu = ({
  userRole,
  name,
  menuItems,
  extended = true,
}: UserMenuProps): JSX.Element => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>): void =>
    setAnchorEl(event.currentTarget);

  const handleClose = (): void => setAnchorEl(null);

  return (
    <Box sx={{ paddingRight: `env(safe-area-inset-right)` }}>
      <UserMenuContainer aria-hidden="true" onClick={handleOpen}>
        <AvatarPerson
          name={name}
          fontSize="h5"
          width="1.8rem"
          height="1.8rem"
        />
        {extended && (
          <>
            <Typography
              variant="h5"
              component="span"
              sx={{ margin: "0 0.25rem 0 0.5rem!important" }}
            >
              {name}
            </Typography>
            <Icon
              type="chevronDown"
              stroke={theme.palette.primary.light}
              width={16}
              height={16}
            />
          </>
        )}
      </UserMenuContainer>

      <UserMenuPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        name={name}
        userRole={userRole}
        menuItems={menuItems}
      />
    </Box>
  );
};
