import { styled, Box } from "@mui/material";

export const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: "63.75rem",
  margin: theme.spacing(0, "auto"),
  width: "100%",
  [theme.breakpoints.down("tablet")]: {
    maxWidth: "100%",
    margin: theme.spacing(0),
  },
}));
