import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const AnswerPartModel = types.model({
  id: types.identifier,
  text: types.maybeNull(types.string),
  canBeMoved: types.maybeNull(types.boolean),
  value: types.maybeNull(types.string),
});

type AnswerPartModelType = Instance<typeof AnswerPartModel>;
type AnswerPartModelSnapshotType = SnapshotOut<typeof AnswerPartModel>;

export type AnswerPart = AnswerPartModelType;
export type AnswerSnapshot = AnswerPartModelSnapshotType;
