"use client";

import ReactModal, { Props } from "react-modal";
import { observer } from "mobx-react";

ReactModal.setAppElement("body");

export const Modal = observer((props: Props): JSX.Element => {
  const { isOpen, children, ...rest } = props;
  return (
    <ReactModal
      style={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1000,
          overflowY: "auto",
        },
        content: {
          inset: "unset",
          borderRadius: "1.25rem",
          width: "100%",
          maxWidth: "70rem",
          marginTop: "5rem",
        },
      }}
      isOpen={isOpen}
      {...rest}
    >
      {children}
    </ReactModal>
  );
});
