import { Select, SelectProps } from "components/Select";

export const FilterSelect = (props: SelectProps) => {
  return (
    <Select
      width="100%"
      color="info"
      sx={{
        ".MuiSelect-select.MuiInput-input": {
          width: "100%",
        },
        ".MuiSelect-select": {
          fontSize: "0.8rem",
        },
        ".MuiSelect-select:focus": {
          background: "none",
        },
        ".MuiMenuItem-gutters": {
          fontSize: "0.8rem",
        },
      }}
      labelProps={{
        sx: {
          fontSize: "1rem",

          "&.Mui-focused": {
            color: "#5783F1",
            transform: "",
            fontSize: "1rem",
          },
        },
      }}
      iconProps={{
        sx: {
          position: "absolute",
          right: "0",
          top: "5px",
          width: "16px",
          height: "16px",
          pointerEvents: "none",
        },
      }}
      menuItems={{
        sx: {
          fontSize: "0.8rem",
        },
      }}
      {...props}
    />
  );
};
