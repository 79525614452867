/* eslint-disable no-console */
import { Box, Tooltip } from "@mui/material";

import { Icon, IconType } from "components/Icon";

import { TooltipTitle } from "./styledComponents/TooltipTitle";

export interface ReportActionProps {
  tooltip: string;
  onClick: () => void;
  icon: IconType;
  color: string;
}

export const ReportAction = ({
  tooltip,
  onClick,
  icon,
  color,
}: ReportActionProps): JSX.Element => {
  const TooltipText = <TooltipTitle>{tooltip}</TooltipTitle>;

  return (
    <Tooltip placement="bottom" title={TooltipText}>
      <Box>
        <Icon
          type={icon}
          width={20}
          height={20}
          color={color}
          onClick={onClick}
        />
      </Box>
    </Tooltip>
  );
};
