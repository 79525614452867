/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance, SnapshotOut, cast, detach } from "mobx-state-tree";

import { Answer } from "./Answer";
import { Question, QuestionModel } from "./Question";

export const ExerciseAnswerModel = types
  .model("ExerciseAnswer", {
    id: types.identifier,
    questions: types.array(QuestionModel),
  })
  .views((self) => ({
    get isFilled(): boolean {
      const totalEmptyFields = self.questions.reduce(
        (sum, { emptyFieldsCount }) => sum + Number(emptyFieldsCount),
        0
      );

      const totalAnswers = self.questions.reduce(
        (sum, { answers }) => sum + answers.length,
        0
      );

      if (totalEmptyFields) {
        return totalEmptyFields === totalAnswers;
      }

      return self.questions.every((question) => question.isFilled);
    },
    get isEmpty(): boolean {
      return self.questions.length === 0;
    },
    get firstQuestion(): Question | undefined {
      return self.questions.slice()[0];
    },
    get firstUnfilledQuestion(): Question | undefined {
      return self.questions.find((q) => !q.isFilled);
    },
    get allAnswerIds(): string[] {
      return self.questions
        .flatMap(({ answers }) => answers)
        .map(({ id }) => String(id));
    },
    getQuestion(id: string) {
      return self.questions.find((question) => question.id === id);
    },
  }))
  .actions((self) => ({
    setQuestions(questions: Question[]) {
      self.questions = cast(questions);
    },
    clearQuestions() {
      self.questions.splice(0, self.questions.length);
    },
    swapAnswer(
      source: {
        id: string;
        question: string;
      },
      target: {
        id: string;
        question: string;
      }
    ) {
      const sourceAnswer = self
        .getQuestion(source.question)
        ?.getAnswer(source.id);

      const targetAnswer = self
        .getQuestion(target.question)
        ?.getAnswer(target.id);

      if (sourceAnswer && targetAnswer) {
        self.questions = cast(
          self.questions.map((question) => {
            if (question.id === source.question) {
              return {
                ...question,
                answers: [detach(targetAnswer)],
              };
            }
            if (question.id === target.question) {
              return {
                ...question,
                answers: [detach(sourceAnswer)],
              };
            }
            return question;
          })
        );
      }
    },
    insertAnswer(question: string, answer: Answer) {
      const { addAnswer } = self.getQuestion(question) || {};
      if (addAnswer) {
        addAnswer(answer);
      }
    },
  }));

type ExerciseAnswerType = Instance<typeof ExerciseAnswerModel>;
type ExerciseAnswerSnapshotType = SnapshotOut<typeof ExerciseAnswerModel>;

export interface ExerciseAnswer extends ExerciseAnswerType {}
export type ExerciseAnswerSnapshot = ExerciseAnswerSnapshotType;
