import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

interface ContentContainerProps {
  isProgress?: boolean;
}

export const ContentContainer = styled(Box)(
  ({ isProgress = true }: ContentContainerProps) => ({
    display: "flex",
    flexWrap: "wrap",
    flexShrink: 1,
    marginTop: isProgress ? "1.5rem" : "0",

    "&:first-of-type": {
      marginTop: "0",
    },
  })
);
