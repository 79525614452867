/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  getRoot,
} from "mobx-state-tree";

import { ReportTestModel, ReportTestSnapshot } from "./ReportTestModel";

/**
 * Model description here for TypeScript hints.
 */
export const ReportTestStoreModel = types
  .model("ReportTestStore")
  .props({
    items: types.optional(types.array(ReportTestModel), []),
  })
  .actions((self) => ({
    addItem: (item: ReportTestSnapshot) => {
      const instance = resolveIdentifier(ReportTestModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }));
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
  */

type ReportTestStoreType = Instance<typeof ReportTestStoreModel>;
export type ReportTestStore = ReportTestStoreType;
type ReportTestStoreSnapshotType = SnapshotOut<typeof ReportTestStoreModel>;
export type ReportTestStoreSnapshot = ReportTestStoreSnapshotType;
