/* eslint-disable @typescript-eslint/no-empty-interface */
import {
  Exercise,
  ExerciseUIType,
} from "@packages/store/models/Exercise/Exercise";
import { Loading } from "../../components/Loading";
import { observer } from "mobx-react-lite";
import { ExerciseComponentProps } from "../../types";

import * as exercises from "./exercises";

interface ExerciseFormProps {
  exercise: Exercise;
}

const exerciseMap: {
  [key in ExerciseUIType]?: (
    props: ExerciseComponentProps
  ) => JSX.Element | null;
} = {
  ...exercises,
};

export const ExerciseForm = observer(
  ({ exercise }: ExerciseFormProps): JSX.Element | null => {
    const { uiType, loading } = exercise;

    const ExerciseComponent = exerciseMap[uiType];

    if (!ExerciseComponent) {
      return null;
    }

    return (
      <Loading cover loading={loading}>
        <ExerciseComponent exercise={exercise} />
      </Loading>
    );
  }
);
