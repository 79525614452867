export function formatTime(time?: Date | null): string {
  if (!time) {
    return "";
  }

  const day = `${time.getDate()}`.padStart(2, "0");
  const month = `${time.getMonth() + 1}`.padStart(2, "0");
  const year = time.getFullYear();

  const hours = `${time.getHours()}`.padStart(2, "0");
  const minutes = `${time.getMinutes()}`.padStart(2, "0");

  return `${day}.${month}.${year} • ${hours}:${minutes}`;
}
