import { styled } from "@mui/material";

import { tablet } from "../../../constants/styles";
import { StyledPaper } from "../../StyledPaper";

interface StyledNavigationWidgetProps {
  disabled?: boolean;
}

export const StyledNavigationWidget = styled(StyledPaper, {
  shouldForwardProp: (prop) => prop !== "disabled",
})<StyledNavigationWidgetProps>(({ theme, disabled }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  maxWidth: 320,
  height: 76,
  padding: theme.spacing(3, 4),
  color: theme.palette.custom.grey.main,
  overflow: "hidden",
  cursor: "pointer",
  transition: theme.transitions.create(["background-color", "color"], {
    duration: 200,
  }),

  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "rgba(197, 171, 252, 0.3)",
  },

  [theme.breakpoints.down(tablet)]: {
    alignItems: "center",
    height: 40,
  },

  ...(disabled && {
    backgroundColor: theme.palette.custom.grey.light,
    cursor: "default",
    "&:hover": {},
  }),
}));
