import { types, Instance, SnapshotOut } from "mobx-state-tree";

export const LessonCredentialsModel = types.model({
  login: types.maybeNull(types.string),
  password: types.maybeNull(types.string),
});

type LessonCredentialsType = Instance<typeof LessonCredentialsModel>;
export type LessonCredentials = LessonCredentialsType;
type LessonCredentialsSnapshotType = SnapshotOut<typeof LessonCredentialsModel>;
export type LessonCredentialsSnapshot = LessonCredentialsSnapshotType;
export const createLessonCredentialsDefaultModel = () =>
  types.optional(LessonCredentialsModel, {} as LessonCredentialsSnapshot);
