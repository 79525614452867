import { createContext } from "react";

export interface TranslateModeContextType {
  translateMode: boolean;
  toggleTranslateMode: () => void;
}

export const TranslateModeContext = createContext<
  TranslateModeContextType | undefined
>(undefined);
