import { Box, Typography, useTheme, Theme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Button } from "../../Button";
import { Icon } from "../../Icon";
import { StyledButton } from "../styledComponents/StyledButton";
import { StyledPaper } from "../styledComponents/StyledPaper";

const getStatus = (item: Item) => {
  if (item.isPassed) {
    return "passed";
  }
  if (item.isWrong) {
    return "wrong";
  }
  return "default";
};

export const getColor = (theme: Theme, item: Item) => {
  const status = getStatus(item);

  if (status !== "default") {
    return theme.palette.custom.colorVariant[status];
  }

  return theme.palette.custom.grey.accentText;
};

const iconVariant = {
  passed: "exerciseSuccess",
  wrong: "exerciseFail",
  default: "exerciseSkip",
} as const;

interface Item {
  id: string;
  isPassed: boolean;
  isWrong: boolean;
}

interface ItemsProps {
  items: Item[];
  showRepeat: boolean;
  type: "unit" | "block";
  getItemName: (itemId: string) => string;
  onRepeatClick: () => void;
  onContinueClick: () => void;
  onItemClick: (itemId: string) => void;
}

export const Items = observer(
  ({
    items,
    getItemName,
    showRepeat,
    type,
    onRepeatClick,
    onContinueClick,
    onItemClick,
  }: ItemsProps): JSX.Element | null => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
      <StyledPaper
        sx={{
          padding: "0.75rem 1rem 0.625rem",
        }}
      >
        <Typography variant="h2" color="primary.main" mb="0.5rem">
          {t(`ResultsModals:${type === "block" ? "Exercises" : "Blocks"}`)}
        </Typography>
        <Box display="flex" flexDirection="column" gap="0.5rem" mb="20px">
          {items.map((item, i) => (
            <Button
              key={i}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                padding: "0",
                ":hover": {
                  backgroundColor: "transparent",
                },
              }}
              type="button"
              onClick={() => onItemClick(item.id)}
            >
              <Typography
                variant="buttonUnderlined"
                color={getColor(theme, item)}
                textAlign="left"
                sx={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 1,
                }}
              >
                {t(`ResultsModals:${type === "block" ? "Exercise" : "Block"}`, {
                  order: i + 1,
                  name: getItemName(item.id),
                })}
              </Typography>
              <Box width={22} height={22} flexShrink="0">
                <Icon type={iconVariant[getStatus(item)]} sizeSquareIcon={22} />
              </Box>
            </Button>
          ))}
        </Box>

        <Box display="flex" flexDirection="column" gap="0.5rem">
          {showRepeat && (
            <StyledButton colorVariant="repeat" onClick={onRepeatClick}>
              {t("ResultsModals:Repeat")}
            </StyledButton>
          )}
          <StyledButton colorVariant="continue" onClick={onContinueClick}>
            {t("ResultsModals:Continue")}
          </StyledButton>
        </Box>
      </StyledPaper>
    );
  }
);
