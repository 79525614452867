import { PossibleAnswer as PossibleAnswerType } from "@packages/store/models/Exercise/PossibleAnswer";
import {
  Answer,
  AnswerModel,
} from "@packages/store/models/ExerciseAnswer/Answer";
import { ObserverFlexList } from "../../components/FlexList";
import { observer } from "mobx-react-lite";

import { DraggableAnswer } from "../DraggableAnswer";
import { MovingDropItem } from "../MovingDropzone";

type CommonPossibleAnswersProps = {
  answers: PossibleAnswerType[];
  insertAnswer(question: string, answer: Answer): void;
  question?: string;
  isSticky?: boolean;
};

type PossibleAnswersPropsWithMoving = CommonPossibleAnswersProps & {
  allAnswerIds: (string | null | undefined)[];
  reusableAnswers?: never;
};

type PossibleAnswersPropsWithCoping = CommonPossibleAnswersProps & {
  reusableAnswers: true;
  allAnswerIds?: never;
};

type PossibleAnswersProps =
  | PossibleAnswersPropsWithMoving
  | PossibleAnswersPropsWithCoping;

export const PossibleAnswers = observer(
  ({
    allAnswerIds = [],
    reusableAnswers, // Если передать этот параметр то ответы будут копироваться а не двигаться
    answers,
    insertAnswer,
    question,
    isSticky = false,
  }: PossibleAnswersProps): JSX.Element | null => {
    const availableAnswers = answers.filter(
      ({ id }) => reusableAnswers || !allAnswerIds.includes(id)
    );

    const uniqueAnswers = availableAnswers.filter((item, index, array) => {
      const dublicateIndex = array.findIndex(
        ({ text }, idx) => text === item.text && idx > index
      );

      if (dublicateIndex !== -1) {
        const temp = array[dublicateIndex];
        // eslint-disable-next-line no-param-reassign
        array[dublicateIndex] = array[index];
        // eslint-disable-next-line no-param-reassign
        array[index] = temp;

        return false;
      }

      return true;
    });

    return (
      <ObserverFlexList
        direction="row"
        gap={3}
        wrap
        emptyNull
        items={uniqueAnswers}
        renderItem={({ id, text }, index) => {
          const count = availableAnswers.filter(
            (answer) => answer.text === text
          ).length;

          return (
            <DraggableAnswer
              key={index}
              id={String(id)}
              title={String(text)}
              question={question || ""}
              status="default"
              // eslint-disable-next-line @typescript-eslint/no-shadow
              onDrop={({ id, title }, monitor) => {
                const dropResult = monitor.getDropResult<MovingDropItem>();
                if (dropResult) {
                  if (question && dropResult.question !== question) {
                    return;
                  }
                  insertAnswer(
                    dropResult.question,
                    AnswerModel.create({
                      id,
                      text: title,
                      place: dropResult.place,
                    })
                  );
                }
              }}
              {...(count > 1 && {
                count,
              })}
            />
          );
        }}
        containerStyles={
          isSticky
            ? {
                mb: 3,
                p: 3,
                backgroundColor: "#FFF5E7",
                borderRadius: 3,
                position: "sticky",
                top: 0,
                zIndex: 500,
              }
            : {
                mb: 3,
                p: 3,
                backgroundColor: "#FFF5E7",
                borderRadius: 3,
              }
        }
      />
    );
  }
);
