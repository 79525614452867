import createPalette, {
  PaletteOptions,
} from "@mui/material/styles/createPalette";

type Custom = {
  primary: {
    black: string;
    transparent: string;
  };
  grey: {
    main: string;
    light: string;
    dark: string;
    lightGray: string;
    contrastText: string;
    "menu-tile-inactive": string;
    "emenu-tile-active": string;
    border: string;
    accentText: string; // wet asphalt
    accentBlock: string; // blue_lite
  };
  notification: string;

  // Это для exercises workspace
  answerColorVariant: {
    font: {
      default: string;
      wrong: string;
      passed: string;
      current: string;
    };
    background: {
      default: string;
      wrong: string;
      passed: string;
      current: string;
    };
    border: {
      default: string;
      wrong: string;
      passed: string;
      current: string;
    };
  };
  colorVariant: {
    default: string;
    passed: string;
    wrong: string;
    current: string;
  };
};

declare module "@mui/material" {
  interface Palette {
    custom: Custom;
  }
  interface PaletteOptions {
    custom: Custom;
  }
}

// TODO Тут надо доделать типизацию
const palette = {
  primary: {
    main: "#562C8E",
    dark: "#470073",
    light: "#C5ABFC",
    contrastText: "#ffffff",
  },
  success: {
    main: "#47B347",
    dark: "#5DDE3C",
    light: "#E8F8E4",
    contrastText: "#ffffff",
  },
  warning: {
    main: "#FF2E00",
    light: "#FFF1EE",
    dark: "#FF9A03",
    contrastText: "#ffffff",
  },
  info: {
    main: "#5783F1",
    light: "#69DAF2",
    dark: "#29A3CC",
    contrastText: "#ffffff",
  },
  error: {
    main: "#FF6B4A",
    contrastText: "#ffffff",
  },
  custom: {
    primary: {
      black: "#000000",
      transparent: "transparent",
    },
    grey: {
      main: "#747474",
      light: "#D7D7D7",
      dark: "#363636",
      lightGray: "#F7F7F7",
      contrastText: "#ffffff",
      "menu-tile-inactive": "#F4F4F4",
      "emenu-tile-active": "#E0E0E0",
      border: "#E9E9E9",
      accentText: "#78798B",
      accentBlock: "#F3F6FA",
    },
    notification: "#FF6161",
    answerColorVariant: {},
    colorVariant: {},
  },
};

palette.custom.answerColorVariant = {
  font: {
    default: palette.custom.grey.main,
    wrong: palette.warning.main,
    passed: palette.success.main,
    current: palette.info.contrastText,
  },
  background: {
    default: palette.custom.grey["menu-tile-inactive"],
    wrong: palette.warning.light,
    passed: palette.success.light,
    current: palette.info.main,
  },
  border: {
    default: palette.custom.grey.light,
    wrong: palette.warning.main,
    passed: palette.success.main,
    current: palette.info.main,
  },
};

palette.custom.colorVariant = {
  default: palette.custom.grey.main,
  passed: palette.success.main,
  wrong: palette.warning.main,
  current: palette.info.main,
};

export const defaultPalette = createPalette(palette as PaletteOptions);
