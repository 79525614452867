import {
  Box,
  CircularProgress as NativeCircularProgress,
  Typography,
  TypographyProps,
} from "@mui/material";

export interface CircularProgressProps {
  progress?: number;
  label?: string | null;
  circularSize?: number;
  circularThickness?: number;
  circularColor?: string;
  circularSecondaryColor?: string;
  progressTextColor?: string;
  labelColor?: string;
  progressTextProps?: TypographyProps;
}

export const CircularProgress = ({
  progress,
  label,
  circularSize = 128,
  circularColor = "#5783F1",
  circularSecondaryColor = "rgba(255, 255, 255, 0.5)",
  progressTextProps = {},
}: CircularProgressProps): JSX.Element => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <NativeCircularProgress
        variant="determinate"
        value={progress ?? 0}
        size={circularSize}
        thickness={2}
        sx={{
          boxShadow: `inset 0 0 0 5.5px ${circularSecondaryColor}`,
          borderRadius: "50%",
          circle: {
            strokeLinecap: "round",
            color: circularColor,
          },
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          color="white"
          fontSize="1.5rem"
          lineHeight="2rem"
          {...progressTextProps}
        >
          {`${Math.round(progress ?? 0)}%`}
        </Typography>
        {label && (
          <Typography color="white" fontSize="0.8rem">
            {label}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
