/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { getRoot } from "mobx-state-tree";

import { RootStoreModel } from "..";
import { getLocalName } from "../../utils/basic";

/**
 * Adds a environment property to the node for accessing our
 * Environment in strongly typed.
 */
export const withTranslation = (self: any) => ({
  views: {
    /**
     * The environment.
     */
    get nameTranslated(): string {
      const locale = getRoot<typeof RootStoreModel>(self)?.auth?.user?.locale;
      const { translationsAttributes } = self;
      return getLocalName(translationsAttributes, locale || "en");
    },
  },
});
