/* eslint-disable @typescript-eslint/no-shadow */
import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { AnswerModel } from "@packages/store/models/ExerciseAnswer/Answer";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun } from "mobx";
import { ensure } from "../helpers/ensure";

export const useMatchingExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const { answer, passingData, partGroups, isChecked } = exercise;

      if (!answer) {
        return;
      }

      const { setQuestions } = answer;

      if (passingData.length > 0 && isChecked) {
        const initialState = passingData.map(({ id, state, answers }) => {
          const firstAnswer = answers.slice()[0];
          const { text } = ensure(
            partGroups.find(({ id }) => id === firstAnswer.id)
          );

          return QuestionModel.create({
            id,
            state,
            answers: [
              AnswerModel.create({
                id: firstAnswer.id,
                text,
              }),
            ],
          });
        });

        setQuestions(initialState);
      }
    })
  );

  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions, partGroups } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty) {
        const initialState = questions.map(({ id }, index) =>
          QuestionModel.create({
            id,
            answers: [
              AnswerModel.create({
                id: partGroups[index].id,
                text: partGroups[index].text,
              }),
            ],
          })
        );

        setQuestions(initialState);
      }
    })
  );
};
