import React, { useMemo } from "react";

import { observer } from "mobx-react";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import styles from "./TaskList.module.scss";
import { useStores } from "@packages/store";
import { useCallback, useEffect } from "react";
import { Task } from "./Task";
import {
  ExerciseResult,
  ExerciseResultStatus,
} from "@packages/store/models/ExerciseResult/ExerciseResult";
import { List } from "../List";
import { Loading } from "../Loading";
import { useCancelableFetch } from "@packages/store/hooks";
import { useUser } from "../../hooks";
import { isStringsSimilar } from "../../utils/helpers";

interface TaskListProps {
  containerClassName?: string;
  onShowDetails?: (_: string) => void;
  nameFilter?: string;
  statusFilter?: ExerciseResultStatus;
}

const RESULTS_PER_PAGE = 6;

export const TaskList = observer((props: TaskListProps) => {
  const { containerClassName, onShowDetails, nameFilter, statusFilter } = props;

  const { t } = useTranslation();

  const containerClasses = cn(styles.container, containerClassName);

  const { api, exerciseResult } = useStores();

  const { isTeacher } = useUser();

  const { items: results } = exerciseResult;

  const filteredResults = useMemo(() => {
    return results
      .filter((result) => {
        if (isTeacher) {
          return isStringsSimilar(result.student ?? "", nameFilter);
        }
        return isStringsSimilar(result.teacher ?? "", nameFilter);
      })
      .filter((result) => {
        if (!statusFilter) {
          return true;
        }

        return result.status === statusFilter;
      })
      .sort((prev, next) => {
        if (!prev.createdAt || !next.createdAt) {
          return 1;
        }
        return prev.createdAt < next.createdAt ? 1 : -1;
      });
  }, [isTeacher, results, results.length, nameFilter, statusFilter]);

  const { fetch: fetchResults, loading: resultsLoading } = useCancelableFetch();

  const handleShowDetails = useCallback(
    (id: string) => {
      onShowDetails?.(id);
    },
    [onShowDetails]
  );

  const renderTask = useCallback(
    (task: ExerciseResult) => (
      <Task key={task.id} result={task} onButtonClick={handleShowDetails} />
    ),
    [handleShowDetails]
  );

  useEffect(() => {
    fetchResults((token) => {
      return api.getExerciseResults(
        {
          isTeacher: isTeacher ?? false,
        },
        token
      );
    });
  }, [api, isTeacher, fetchResults]);

  return (
    <Loading loading={resultsLoading}>
      <div className={containerClasses}>
        <List
          itemsPerPage={RESULTS_PER_PAGE}
          data={filteredResults}
          renderItem={renderTask}
          emptyClassName={styles.empty}
          emptyText={t("CreativeTasks:EmptyList") ?? ""}
        />
      </div>
    </Loading>
  );
});
