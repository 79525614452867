import { Typography } from "@mui/material";

import { StyledPaper } from "../../components/StyledPaper";

type Variant = "error" | "info" | "default";

interface TooltipTitleProps {
  title: string;
  variant?: Variant;
}

export const TooltipTitle = ({
  title,
  variant = "default",
}: TooltipTitleProps): JSX.Element | null => {
  return (
    <StyledPaper
      sx={{
        py: 2,
        px: 3,
        backgroundColor:
          variant === "default" ? "custom.grey.dark" : `${variant}.main`,
      }}
    >
      <Typography variant="regularText" color="primary.contrastText">
        {title}
      </Typography>
    </StyledPaper>
  );
};
