import { useLocation, useParams } from "react-router";

import { JetRedirect } from "components/JetRedirect";
import { ROUTES } from "router/constants";

export const DetailReportRedirect = (): JSX.Element => {
  const { pathname } = useLocation();
  const { id } = useParams();

  if (pathname.includes(ROUTES.STUDENTS_REPORTS)) {
    return <JetRedirect url={`/reports/students_reports/${id}`} />;
  }

  if (pathname.includes(ROUTES.TEACHERS_REPORTS)) {
    return <JetRedirect url={`/reports/teachers_reports/${id}`} />;
  }

  return <></>;
};
