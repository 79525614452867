import { TextField, TextFieldProps } from "@mui/material";

interface NumberInputProps {
  step: string;
  lengthAfterDot: number;
  min?: string;
}

export const NumberInput = ({
  onChange,
  step,
  lengthAfterDot,
  min,
  ...other
}: TextFieldProps & NumberInputProps): JSX.Element | null => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if ((e.target.value.match(/\.\d+/)?.[0].length || 0) > lengthAfterDot + 1) {
      return;
    }

    onChange?.(e);
  };

  return (
    <TextField
      type="number"
      inputProps={{ step, min }}
      onChange={handleChange}
      {...other}
    />
  );
};
