import { useLayoutEffect } from "react";

import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { QuestionModel } from "@packages/store/models/ExerciseAnswer/Question";
import { autorun, toJS } from "mobx";
import { ensure } from "../helpers/ensure";

import { applyDiffPatch } from "../helpers/applyDiffPatch";

export const useMistakesExercise = (exercise: Exercise) => {
  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions, passingData, isChecked } = exercise;

      if (!answer) {
        return;
      }

      const { setQuestions } = answer;

      if (passingData.length > 0 && isChecked) {
        const initialState = passingData.map(({ id, state, answers }) => {
          const { originalText } = ensure(
            questions.find((question) => question.id === id)
          );

          const patchedText = applyDiffPatch(
            originalText,
            toJS(answers).map(({ action, element, position }) => ({
              action: String(action),
              position: Number(position),
              element: String(element),
            }))
          );

          return QuestionModel.create({
            id,
            state,
            text: patchedText,
          });
        });

        setQuestions(initialState);
      }
    })
  );

  useLayoutEffect(() =>
    autorun(() => {
      const { answer, questions } = exercise;

      if (!answer) {
        return;
      }

      const { isEmpty, setQuestions } = answer;

      if (isEmpty) {
        const initialState = questions.map(({ id, originalText }) =>
          QuestionModel.create({
            id,
            text: originalText,
            withChangeState: true,
          })
        );

        setQuestions(initialState);
      }
    })
  );
};
