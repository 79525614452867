/**
 * Переделка ссылка в уведомлении на новй фронт
 */
export const getNotificationTaskId = (url: string | undefined | null) => {
  if (!url) return null;

  const parts = url.split("/");
  const lastPart = parts[parts.length - 1];
  if (lastPart === "edit") {
    return parts[parts.length - 2];
  }

  return lastPart;
};
