import { StatusType } from "../types";

export const getItemStatus = <
  T extends { id: string | null; isPassed?: boolean; isWrong?: boolean }
>(
  item: T,
  currentItem?: T
): StatusType => {
  if (item.id === currentItem?.id) {
    return "current";
  }
  if (item.isPassed) {
    return "passed";
  }
  if (item.isWrong) {
    return "wrong";
  }
  return "default";
};
