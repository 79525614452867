/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, ChangeEvent } from "react";

import { FormControlLabel, FormGroup, RadioGroup } from "@mui/material";
import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import {
  ChoiceRadioLineQuestion,
  ChoiceSelectProps,
} from "../../../../../../components/ChoiceQuestion";
import { SubExerciseComponentProps } from "../../../../../../types";
import { ensure } from "../../../../../../helpers/ensure";

import { StyledCheckbox } from "./styledComponents/StyledCheckbox";
import { StyledLabel } from "./styledComponents/StyledLabel";
import { StyledRadioButton } from "./styledComponents/StyledRadioButton";

export const RadioLine = observer(
  ({ exercise }: SubExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, getCorrectAnswer, properties } = exercise;

    const { getQuestion } = ensure(answer);

    const renderSelect = useCallback(
      ({
        value,
        onChange,
        options,
        isChecked,
        multipleAnswers,
        withTitle,
      }: ChoiceSelectProps): JSX.Element | null => {
        const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
          onChange((event.target as HTMLInputElement).value);
        };

        if (multipleAnswers) {
          return (
            <FormGroup sx={{ gap: 3, ml: withTitle ? 5 : 4 }}>
              {options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  label={
                    <StyledLabel status={option.status}>
                      {option.label}
                    </StyledLabel>
                  }
                  control={
                    <StyledCheckbox
                      value={option.value}
                      onChange={handleChange}
                      checked={value.some((v) => v === option.value)}
                      status={option.status}
                    />
                  }
                  disabled={isChecked}
                />
              ))}
            </FormGroup>
          );
        }

        return (
          <RadioGroup
            value={value[0] || ""}
            onChange={handleChange}
            sx={{
              gap: 1,
              mt: 1,
              ml: 5,
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                label={
                  <StyledLabel status={option.status}>
                    {option.label}
                  </StyledLabel>
                }
                control={<StyledRadioButton status={option.status} />}
                disabled={isChecked}
              />
            ))}
          </RadioGroup>
        );
      },
      []
    );

    const { multipleAnswers } = properties || {};

    return (
      <ObserverFlexList
        gap={4}
        items={questions}
        renderItem={(question: Question, index, array: Question[]) => (
          <ChoiceRadioLineQuestion
            key={question.id}
            question={question}
            answerData={ensure(getQuestion(question.id))}
            correctAnswer={getCorrectAnswer(question.id)}
            renderSelect={renderSelect}
            multipleAnswers={multipleAnswers}
            {...(array.length > 1 && {
              order: index + 1,
            })}
          />
        )}
      />
    );
  }
);
