import { isInRange } from "./isInRange";
import { getWordIndexesFromRange } from "./getWordIndexesFromRange";

import { WordRange } from "../types";

export function getWordHoveredList(list: WordRange[], wordIndex: number): number[] {
  const hoveredList = list.filter((item) => isInRange(item, wordIndex));

  if (!hoveredList.length) {
    return [];
  }

  const startPosition = Math.min(...hoveredList.map(({ startPosition }) => startPosition));
  const endPosition = Math.max(...hoveredList.map(({ endPosition }) => endPosition));

  return getWordIndexesFromRange({ startPosition, endPosition });
}
