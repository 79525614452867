import { format, isValid, parse } from "date-fns";
import { map, pickBy } from "lodash";

export const buildSearchParams = (data: Record<string, any>) => {
  const filteredParams = pickBy(data, (value) => {
    if (Array.isArray(value) && value.length === 0) {
      return false;
    }

    return Boolean(value);
  });

  const params = new URLSearchParams();

  map(filteredParams, (value, key) => {
    if (Array.isArray(value)) {
      map(value, (item) => params.append(key, item));
    } else if (value instanceof Date) {
      params.append(key, format(value, "dd.MM.yyyy"));
    } else {
      params.append(key, value);
    }
  });

  return params;
};

export const parseSearchParams = <
  T extends Record<string, unknown> = Record<string, unknown>
>(
  params: URLSearchParams
) => {
  const result: Record<string, unknown> = {};

  params.forEach((value, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty(key)) {
      result[key] = [result[key], value].flat();
    } else if (isValid(parse(value, "dd.MM.yyyy", new Date()))) {
      result[key] = parse(value, "dd.MM.yyyy", new Date());
    } else if (value === "true") {
      result[key] = true;
    } else if (value === "false") {
      result[key] = false;
    } else {
      result[key] = value;
    }
  });

  return result as Partial<T>;
};
