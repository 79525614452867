import { Box, Typography, useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { englishLevels } from "../../../constants/englishLevels";
import { Icon } from "../../Icon";

interface EnglishLevelProps {
  level: keyof typeof englishLevels;
  isHighlighted?: boolean;
  gap?: boolean;
}

export const EnglishLevel = observer(
  ({
    level,
    isHighlighted = false,
    gap = false,
  }: EnglishLevelProps): JSX.Element | null => {
    const { t } = useTranslation();

    const theme = useTheme();

    const { color, hours, name } = englishLevels[level];

    const height =
      15 + 1.25 * Object.keys(englishLevels).findIndex((l) => l === level);

    return (
      <>
        <Box textAlign="center" mb={gap ? "1rem" : "0"}>
          <Box
            height={`${height}rem`}
            bgcolor={color}
            borderRadius="1rem"
            mb="1.5rem"
            px="1.875rem"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              ...(isHighlighted
                ? {
                    outlineWidth: "5px",
                    outlineStyle: "solid",
                    outlineOffset: "5px",
                    outlineColor: theme.palette.error.main,
                  }
                : {}),
            }}
            position="relative"
          >
            {isHighlighted && (
              <Box
                color="error.main"
                position="absolute"
                top="0"
                left="50%"
                display="flex"
                flexDirection="column"
                alignItems="center"
                bgcolor={theme.palette.primary.contrastText}
                sx={{
                  transform: "translateX(-50%) translateY(-100%)",
                }}
              >
                <Icon type="profile" sizeSquareIcon="2.5rem" />
                <Icon type="chevronDown" sizeSquareIcon="1.5rem" />
              </Box>
            )}

            <Typography color="custom.grey.dark" variant="smallText">
              CEFR
            </Typography>
            <Typography color="custom.grey.dark" fontSize="1.25rem">
              {level}
            </Typography>
            <Typography
              color="custom.grey.dark"
              fontSize="1.4rem"
              fontWeight={500}
              lineHeight="2.3rem"
            >
              {name}
            </Typography>
            <Typography color="custom.grey.dark" fontSize="0.875rem">
              ({t(`ResultsModals:TestCourse${level}Short`)})
            </Typography>
          </Box>
          <Box
            bgcolor={color}
            borderRadius="1rem"
            py="1rem"
            display="flex"
            flexDirection="column"
          >
            <Typography variant="largeText" color="custom.grey.dark">
              {t("ResultsModals:TestCourseShowHourse")}
            </Typography>
            <Typography variant="largeText" color="custom.grey.dark">
              {hours}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap="0.75rem" gridRow={3} alignItems="stretch">
          <Box
            bgcolor={color}
            width="0.75rem"
            borderRadius="0.25rem"
            flexShrink={0}
          />
          <Typography>{t(`ResultsModals:TestCourse${level}Long`)}</Typography>
        </Box>
      </>
    );
  }
);
