import { styled } from "@mui/material";

import {
  OverflowTypography,
  OverflowTypographyProps,
} from "../../OverflowTypography";

export const ColumnTitle = styled((props: OverflowTypographyProps) => (
  <OverflowTypography variant="regularText" align="center" {...props} />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(3),
  whiteSpace: "normal",
  backgroundColor: theme.palette.primary.light,
  borderTopLeftRadius: theme.spacing(3),
  borderTopRightRadius: theme.spacing(3),

  img: {
    width: "100%",
    height: "auto",
  },
}));
