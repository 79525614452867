import { Teacher } from "@packages/store/models/Teacher/Teacher";

// export const filterByEmail = (email: string) => (teacher: Teacher) => {
//   return (
//     !email || teacher.email.toLowerCase().includes(email.toLocaleLowerCase())
//   );
// };

export const filterByName = (name: string) => (teacher: Teacher) => {
  return (
    (!name ||
      teacher.fullName?.toLowerCase().includes(name.toLocaleLowerCase())) ??
    ""
  );
};

// export const filterByLangs = (langs: string[]) => (teacher: Teacher) => {
//   return (
//     !langs.length ||
//     teacher.langs.some((lang) => langs.includes(lang.shortName))
//   );
// };

export const sortByName = (a: Teacher, b: Teacher) => {
  if (a.fullName < b.fullName) {
    return -1;
  }
  if (a.fullName > b.fullName) {
    return 1;
  }
  return 0;
};
