import { alpha, ComponentsOverrides, Theme } from "@mui/material";

interface muiAvatarProps {
  styleOverrides: ComponentsOverrides["MuiAvatar"];
}

export const muiAvatar = (theme: Theme): muiAvatarProps => ({
  styleOverrides: {
    root: {
      background: theme.palette.custom?.grey?.["emenu-tile-active"],
      color: theme.palette.primary.contrastText,
      width: theme.spacing(2),
      height: theme.spacing(2),
      "&.outline": {
        filter: `drop-shadow(0.125rem 0.1875rem 0.375rem ${alpha(
          theme.palette.custom?.primary?.black as string,
          0.08
        )})`,
        border: `0.125rem solid ${theme.palette.primary.contrastText} `,
      },
    },
  },
});
