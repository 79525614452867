import { Box, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { TooltipTitle } from "./styledComponents/TooltipTitle";
import { ReportStatusType } from "./types";

export interface ReportStatusProps {
  type: ReportStatusType;
  tooltip?: string;
  onClick?: () => void;
}

export const ReportStatus = observer(
  ({ type, tooltip, onClick }: ReportStatusProps): JSX.Element => {
    const { t } = useTranslation();

    const ReportStatusColors = {
      [ReportStatusType.planned]: "#FF9A03",
      [ReportStatusType.ready]: "#5DDE3C",
    };

    const ReportStatusTitles = {
      [ReportStatusType.planned]: t("Report:StatusPending"),
      [ReportStatusType.ready]: t("Report:StatusDone"),
    };

    return (
      <Tooltip
        placement="bottom-start"
        title={<TooltipTitle>{tooltip}</TooltipTitle>}
      >
        <Box display="flex" alignItems="center" gap="5px" onClick={onClick}>
          <Box
            sx={{
              width: 7,
              height: 7,
              borderRadius: "50%",
              backgroundColor: ReportStatusColors[type],
              overflow: "hidden",
            }}
          />
          <Typography fontSize="0.8rem">{ReportStatusTitles[type]}</Typography>
        </Box>
      </Tooltip>
    );
  }
);
