import { useState, MouseEvent } from "react";

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LanguageSwitcher } from "components/Header/LanguageSwitcher";
import { Icon } from "components/Icon/Icon";
import { MenuItem } from "types";

import { HeadContainer } from "./styledComponents/HeadContainer";
import { Label } from "./styledComponents/Label";
import { MenuResponsive } from "./styledComponents/MenuResponsive";
import { MobileMenuPopover } from "./styledComponents/MobileMenuPopover";
import { MobileMenuWrapper } from "./styledComponents/MobileMenuWrapper";

export const MobileMenu = ({
  mainMenu,
  onVideoClick,
}: {
  mainMenu: MenuItem[];
  onVideoClick?: () => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <MenuResponsive onClick={handleOpen}>
        <Icon type="burgerMenu" sizeSquareIcon={25} />
      </MenuResponsive>

      <MobileMenuPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box>
          <HeadContainer>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <LanguageSwitcher variant="menu" />
              <Icon
                type="youtube"
                width={22}
                height={19}
                onClick={onVideoClick}
              />
            </Box>

            <Icon
              color="gray"
              type="close"
              width={24}
              height={24}
              onClick={handleClose}
            />
          </HeadContainer>
          <MobileMenuWrapper>
            {mainMenu.map(({ label, path }, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <Link key={i} to={path} onClick={handleClose}>
                <Label>{t(`Menu:${label}`)}</Label>
              </Link>
            ))}
          </MobileMenuWrapper>
        </Box>
      </MobileMenuPopover>
    </div>
  );
};
