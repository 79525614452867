import { Box, useTheme } from "@mui/material";
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  addWeeks,
  eachDayOfInterval,
  isToday,
  isSameMonth,
  format,
  isSameDay,
} from "date-fns";

import { CalendarCell } from "./styledComponents";

interface CalendarDaysProps {
  currentDate: Date;
  onDayChange: (day: Date) => void;
}

export const CalendarDays = ({
  currentDate,
  onDayChange,
}: CalendarDaysProps): JSX.Element | null => {
  const theme = useTheme();

  const weekDays = eachDayOfInterval({
    start: startOfWeek(currentDate),
    end: endOfWeek(currentDate),
  }).map((date) => format(date, "EEEEE"));

  const startOfMonthDate = startOfMonth(currentDate);
  const startDate = startOfWeek(startOfMonthDate);
  const endDate = endOfWeek(addWeeks(startDate, 5));

  const days = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const dayBg = (day: Date) => {
    if (isToday(day)) {
      return `${theme.palette.primary.main}!important`;
    }

    if (isSameDay(day, currentDate)) {
      return `${theme.palette.info.main}!important`;
    }

    return "transparent";
  };
  const dayColor = (day: Date) => {
    if (isToday(day) || isSameDay(day, currentDate)) {
      return theme.palette.primary.contrastText;
    }

    if (isSameMonth(day, currentDate)) {
      return theme.palette.custom.primary.black;
    }

    return theme.palette.custom.grey.main;
  };

  return (
    <Box
      width="222px"
      height="222px"
      display="grid"
      gridTemplateRows="repeat(6, 1fr)"
      gridTemplateColumns="repeat(7, 1fr)"
      gap="2px"
    >
      {weekDays.map((day, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <CalendarCell key={i} isWeekDay>
          {day}
        </CalendarCell>
      ))}

      {days.map((day, i) => (
        <CalendarCell
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          onClick={() => onDayChange(day)}
          sx={{
            backgroundColor: dayBg(day),
            color: dayColor(day),
          }}
        >
          {format(day, "dd")}
        </CalendarCell>
      ))}
    </Box>
  );
};
