/* eslint-disable consistent-return */

import { useEffect } from "react";

import { AuthStore } from "@packages/store/models/AuthStore";
import { Api } from "@packages/store/services/Api";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { PROMOCODE_KEY } from "constants/constants";
import { ROUTES } from "router/constants";

import { useFetch } from "./useFetch";

export const usePromocodeAndConfirmation = (
  auth: AuthStore | undefined,
  api: Api | undefined
) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Проверка промокода, если всё хорошо то перекидываем на регистрацию
  // в случае если он истёк или пришла 404 ошибка то кидаем на страницу ошибки промокода
  const { fetch: checkPromocode } = useFetch(
    (promocode: string) => api!.checkPromocode(promocode),
    (data) => {
      if (data.expiredAt === null) {
        const { isSingIn } = auth!;

        localStorage.setItem(PROMOCODE_KEY, data.promocode);

        if (!isSingIn) {
          navigate(ROUTES.SIGN_UP_PAGE);
        }
      } else {
        navigate(ROUTES.PROMOCODE_EXPIRED);
      }
    },
    (error) => {
      if (error.kind !== "not-found") return;

      navigate(ROUTES.PROMOCODE_EXPIRED);
    }
  );

  useEffect(() => {
    if (!auth || !api) return;

    const { confirmEmail } = auth;

    const promocode = searchParams.get("promocode");
    const confirmationToken = searchParams.get("confirmation_token");

    if (promocode) {
      checkPromocode(promocode);
    }

    if (confirmationToken) {
      searchParams.delete("confirmation_token");
      setSearchParams(searchParams);

      confirmEmail(confirmationToken);

      localStorage.removeItem(PROMOCODE_KEY);

      navigate(ROUTES.HOME);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, api]);
};
