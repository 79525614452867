interface TabPanelProps<T> {
  value: T;
  tabValue: T;
  children?: React.ReactNode;
  remount?: boolean;
}

export const TabPanel = <T,>({
  value,
  tabValue,
  children,
  remount,
}: TabPanelProps<T>): JSX.Element | null => {
  if (remount) {
    return <>{value === tabValue && children}</>;
  }

  return <div hidden={value !== tabValue}>{children}</div>;
};
