import { Box, BoxProps, Typography, useTheme } from "@mui/material";

import { Audio } from "../../components/Audio";

interface MessageProps extends BoxProps {
  text: string;
  direction: "left" | "right";
  selected?: boolean;
  audioSrc?: string;
  order?: number;
  orderVariant?: "numeric" | "alphabetical";
}

export const Message = ({
  text,
  direction,
  selected = false,
  audioSrc,
  order,
  orderVariant = "numeric",
  ...other
}: MessageProps): JSX.Element | null => {
  const theme = useTheme();

  const bgColor = selected
    ? theme.palette.info.main
    : theme.palette.info.contrastText;
  const fgColor = selected
    ? theme.palette.info.contrastText
    : theme.palette.info.main;
  const textColor = selected ? theme.palette.info.contrastText : "unset";
  const borderColor = selected
    ? theme.palette.info.main
    : theme.palette.custom.grey.light;

  return (
    <Box
      sx={{
        background: bgColor,
        color: textColor,
        position: "relative",
        flexBasis: "content",
        display: "flex",
        alignItems: "center",
        border: `1px solid ${borderColor}`,
        borderRadius: "0.5rem",
        p: "0.5rem 1rem",
        // Примерно высота треугольника который торчит возле блока сообщения
        m: "0 7px",
        ":before": {
          content: "''",
          width: "10px",
          height: "10px",
          borderLeft: `1px solid ${borderColor}`,
          borderBottom: `1px solid ${borderColor}`,
          position: "absolute",
          left: 0,
          transform: "translate(-50%, -50%) rotate(45deg)",
          background: bgColor,
          top: "50%",
          ...(direction === "right"
            ? {
                left: "auto",
                right: 0,
                transform: "translate(50%, -50%) rotate(-135deg)",
              }
            : {}),
        },
      }}
      {...other}
    >
      {audioSrc && (
        <Audio
          src={audioSrc}
          timeLine={false}
          time={false}
          buttonStyles={{ p: 0, minWidth: "2.5rem", marginRight: "0.3rem" }}
        />
      )}

      {order && (
        <Box
          sx={{
            width: 26,
            height: 26,
            minWidth: 26,
            marginRight: "0.5rem",
            border: `1px solid ${fgColor}`,
            borderRadius: "50%",
            color: fgColor,
            textAlign: "center",
          }}
        >
          {orderVariant === "numeric" ? order : String.fromCharCode(64 + order)}
        </Box>
      )}

      <Typography key={text} variant="regularText">
        {text}
      </Typography>
    </Box>
  );
};
