import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardsContainerProps } from "../Cards";

export const IconContainerCard = styled(Box)(
  ({ iconColor }: CardsContainerProps) => ({
    minWidth: "2.5rem",
    height: "2.5rem",
    background: iconColor,
    borderRadius: "0.375rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexBasis: "2.5rem",
  })
);
