import { useEffect, useState } from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import type { MenuItem as MenuItemType } from "types";

import { MenuContainer } from "./styledComponents/MenuContainer";
import { MenuItem } from "./styledComponents/MenuItem";

interface ItemProps {
  label: string;
  isActive: boolean;
  onClick: () => void;
  icon: React.ReactElement;
  path: string;
}

export interface MenuProps {
  dataMenu: Array<MenuItemType>;
}
export interface NumberCardProps {
  numberCard: number;
}

const Item = ({
  icon,
  label,
  isActive,
  onClick,
  path,
}: ItemProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <MenuItem
      role="button"
      aria-hidden="true"
      onClick={onClick}
      to={path}
      isActive={isActive}
      data-is-active={isActive}
    >
      {icon}
      <Typography variant="h6">{t(`Menu:${label}`)}</Typography>
    </MenuItem>
  );
};

export const Menu = ({ dataMenu }: MenuProps): JSX.Element => {
  const [activeItem, setActiveItem] = useState<string | undefined>();
  const location = useLocation();
  const theme = useTheme();

  useEffect(() => {
    const currentPath = `/${location.pathname.split("/")[1]}`;
    setActiveItem(currentPath);
  }, []);

  return (
    <MenuContainer>
      {/* Декоративный элемент */}
      <Box
        display="block"
        height="0.75rem"
        bgcolor={theme.palette.custom?.grey?.lightGray}
      />

      {dataMenu.map((item, i) => (
        <Item
          {...item}
          isActive={activeItem === item.path}
          onClick={() => setActiveItem(item.path)}
          key={i}
        />
      ))}

      {/* Декоративный элемент */}
      <Box
        display="block"
        height="100%"
        minHeight="0.75rem"
        bgcolor={theme.palette.custom?.grey?.lightGray}
      />
    </MenuContainer>
  );
};
