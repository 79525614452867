import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { ReportTest } from "@packages/store/models/ReportTest/ReportTestModel";

export function filterGroupsByReportTests(
  groups: LearningGroup[],
  tests: ReportTest[]
): LearningGroup[] {
  const ids = tests.map((test) => (test.groupId ?? 0) as number);
  return groups.filter((item) => {
    return ids.includes(Number(item.id));
  });
}
