/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
import {
  Instance,
  resolveIdentifier,
  SnapshotOut,
  types,
} from "mobx-state-tree";

import { LanguageModel, LanguageSnapshot, Language } from "./Language";
import { withEnvironment } from "../extensions/withEnvironment";

export const LanguageStoreModel = types
  .model("LanguageStore")
  .props({
    items: types.optional(types.array(LanguageModel), []),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    addItem: (item: LanguageSnapshot) => {
      const instance = resolveIdentifier(LanguageModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getById(id: string): Language | undefined {
      return self.items.find((language) => language.id === id);
    },
  }));

type LanguageStoreType = Instance<typeof LanguageStoreModel>;
export type LanguageStore = LanguageStoreType;
type LanguageStoreSnapshotType = SnapshotOut<typeof LanguageStoreModel>;
export type LanguageStoreSnapshot = LanguageStoreSnapshotType;
export const createLanguageStoreDefaultModel = () =>
  types.optional(LanguageStoreModel, {} as LanguageStoreSnapshot);
