import { Box, styled } from "@mui/material";

export const FilterContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "260px",
  borderRadius: "8px",
  boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.11)",
  backgroundColor: "white",
  [theme.breakpoints.down("mobile")]: {
    width: "100vw",
  },
}));
