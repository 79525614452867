/* eslint-disable no-param-reassign */
import {
  Instance,
  SnapshotOut,
  types,
  resolveIdentifier,
  getRoot,
} from "mobx-state-tree";

import {
  TaggingList,
  TaggingListModel,
  TaggingListSnapshot,
} from "./TaggingList";
import { RootStore } from "../RootStore/RootStore";

export interface TaggingListsFilledWithTeacher {
  list: TaggingList;
  teacherValue?: string;
}

export const TaggingListStoreModel = types
  .model("TaggingListStore")
  .props({
    items: types.optional(types.array(TaggingListModel), []),
  })
  .actions((self) => ({
    addItem: (item: TaggingListSnapshot) => {
      const instance = resolveIdentifier(TaggingListModel, self, item.id);
      if (instance) {
        Object.assign(instance, item);
        return;
      }
      self.items.push(item);
    },
  }))
  .views((self) => ({
    getTaggingListById(id: string | undefined): TaggingList | undefined {
      return self.items.find((item) => item.id === id);
    },
    getTaggingListByArea(area: "teacher"): TaggingList[] {
      return self.items.filter((item) => item.appAreas.includes(area));
    },
  }))
  .views((self) => ({
    getFilledWithTeacher(teacherId: string): TaggingListsFilledWithTeacher[] {
      const teacherTaggingLists = self.getTaggingListByArea("teacher");
      const teacher = getRoot<RootStore>(self).teacher.getById(teacherId);

      return teacherTaggingLists.map((list) => ({
        list,
        teacherValue:
          list.tags.find((tag) => teacher?.tags.includes(tag.id))?.id ??
          undefined,
      }));
    },
  }));

type TaggingListStoreType = Instance<typeof TaggingListStoreModel>;
export type TaggingListStore = TaggingListStoreType;
type TaggingListStoreSnapshotType = SnapshotOut<typeof TaggingListStoreModel>;
export type TaggingListStoreSnapshot = TaggingListStoreSnapshotType;
