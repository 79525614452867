import { Popover, styled } from "@mui/material";

import { mobile } from "../../../constants/styles";

export const StyledNavigationPopover = styled(Popover)(({ theme }) => ({
  "&.MuiPopover-root": {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },

  ".MuiPopover-paper": {
    maxWidth: 368,
    width: "100%",
    padding: theme.spacing(3, 4),
    borderRadius: theme.spacing(3),

    [theme.breakpoints.down(mobile)]: {
      left: "50% !important",
      transform: "translateX(-50%) !important",
      maxHeight: 468,
    },
  },
}));
