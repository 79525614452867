import { useLayoutEffect, useState } from "react";

import { format } from "date-fns";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { useStores } from "@packages/store";

import { ContentContainer } from "components/ContentContainer";
import { Loading } from "components/Loading";
import { PageBlock } from "components/PageBlock";
import { useCancelableFetch } from "hooks";
import { ROUTES } from "router/constants";

import {
  CompaniesReportForm,
  CompaniesFormValues,
} from "./components/CompaniesReportForm";
import { CompaniesReportSummary } from "./components/CompaniesReportSummary";
import { CompaniesReportTable } from "./components/CompaniesReportTable";

const PAGE_SIZE = 10;

export const Companies = observer((): JSX.Element | null => {
  const { t } = useTranslation();

  const { api, companyReport } = useStores();

  const { pathname } = useLocation();

  const {
    loading: isCompaniesLoading,
    fetch: fetchCompanies,
    error: companiesError,
  } = useCancelableFetch();

  const [data, setData] = useState<CompaniesFormValues | null>(null);
  const [page, setPage] = useState(0);

  const handleFetchCompanies = (
    data: CompaniesFormValues | null,
    page: number
  ) => {
    if (!data) return;

    const { companyId = "", startDate, endDate } = data;

    companyReport.clear();
    fetchCompanies(() =>
      api.getCompaniesReport({
        companyId: companyId === "" ? undefined : companyId,
        offset: page * PAGE_SIZE,
        limit: PAGE_SIZE,
        dateFrom: format(startDate as Date, "yyyy-MM-dd"),
        dateTo: format(endDate as Date, "yyyy-MM-dd"),
      })
    );
  };

  const handleSubmit = (formData: CompaniesFormValues) => {
    setData(formData);
    handleFetchCompanies(formData, 0);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    handleFetchCompanies(data, newPage);
  };

  useLayoutEffect(() => {
    if (pathname === ROUTES.COMPANIES) {
      companyReport.clear();
    }
  }, [companyReport, pathname]);

  return (
    <ContentContainer>
      <PageBlock title={t("CompaniesReport:Title")}>
        <CompaniesReportForm onSubmit={handleSubmit} />

        <Loading loading={isCompaniesLoading} error={companiesError}>
          <>
            <CompaniesReportSummary />
            <CompaniesReportTable
              reports={companyReport.items}
              totalCompanies={companyReport.summary?.totalCompanies ?? 0}
              count={PAGE_SIZE}
              page={page}
              onChangePage={handleChangePage}
            />
          </>
        </Loading>
      </PageBlock>
    </ContentContainer>
  );
});
