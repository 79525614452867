import { styled, Modal } from "@mui/material";

export const ModalContainer = styled(Modal)(({ theme }) => ({
  display: "none",
  overflowY: "scroll",
  [theme.breakpoints.down("tablet")]: {
    display: "flex",
    alignItems: "flex-start",
  },
}));
