import { useEffect } from "react";

import { useParams, Outlet } from "react-router-dom";

import { useStores } from "@packages/store/models";

import { useCancelableFetch } from "hooks";

export function Module() {
  const { id: courseId } = useParams();

  const { api } = useStores();

  const { fetch: fetchUnits } = useCancelableFetch();
  const { fetch: fetchCourse } = useCancelableFetch();

  useEffect(() => {
    if (!courseId) {
      return;
    }
    fetchUnits((token) => api.getUnitsByCourse(courseId, token));
  }, [fetchUnits, api, courseId]);

  useEffect(() => {
    if (!courseId) {
      return;
    }
    fetchCourse((token) => api.getCourse(courseId, token));
  }, [fetchCourse, api, courseId]);

  return <Outlet />;
}
