/* eslint-disable @typescript-eslint/no-shadow */
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";

import { useStores } from "@packages/store/models";
import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";

import {
  ModalAttendance,
  ModalAttendanceStudent,
  ModalAttendanceFormValues,
} from "components/ModalAttendance";
import { getLessonDurationInSeconds } from "utils/helpers";

export const LessonAttendance = observer((): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const store = useStores();

  const { lesson: lessonStore, auth: authStore } = store;

  const { id } = useParams();

  const lesson = lessonStore.items.find((item) => item.id === id);

  const {
    learningGroup = {},
    durationInMinutes = 0,
    studentsAttendance = [],
    availableStatuses = [],
    startAtLocal,
    group,
    editable,
    currentStatus,
    timezone,
    comment,
    actualUnitId,
  } = lesson ?? {};

  const { isDurationAdditionPossible, restrictiveDate } =
    learningGroup as LearningGroup;

  const { isTeacher } = authStore.user ?? {};

  const students = studentsAttendance.map((student) => ({
    value: student.studentId || "",
    label: `${student.firstName} ${student.lastName}`,
    checked: student.attended || false,
  })) as ModalAttendanceStudent[];

  const statuses = availableStatuses.map((status) => ({
    value: status,
    label: t(`LessonStatuses:${status}`),
  }));

  const onClose = () => {
    navigate(-1);
  };

  const isStatusCompleted = (status: string | undefined) => {
    return status === "completed";
  };

  const checkIsDataHasStudents = (data: ModalAttendanceFormValues) => {
    if (!isStatusCompleted(data.status)) {
      return true;
    }

    return data.studentIds.length > 0;
  };

  const overflowMinutes = isStatusCompleted(currentStatus)
    ? durationInMinutes
    : 0;

  const onSubmit = async (data: ModalAttendanceFormValues) => {
    if (!lesson) return;

    if (
      isStatusCompleted(data.status) &&
      !isDurationAdditionPossible(+(data.duration ?? 0) - overflowMinutes)
    ) {
      store.addError(t("ModalAttendance:ExcessHoursWarning"));
      return;
    }

    if (!checkIsDataHasStudents(data)) {
      store.addError(t("ModalAttendance:MarkStudents"));
      return;
    }

    const timeZone = timezone ?? "";
    const date = new Date(data.date ?? "");
    const duration = getLessonDurationInSeconds(data.duration ?? 0);
    const attendance = studentsAttendance.map((student) => ({
      ...student,
      attended: data.studentIds.includes(student.studentId ?? 0),
    }));

    const { isUpdated: isLessonUpdated, errors } = await lesson.updateLesson({
      status: data.status,
      comment: data.comment || null,
      actualUnitId: data.actualUnitId ? Number(data.actualUnitId) : undefined,
      duration,
      date,
      timeZone,
      attendance,
    });

    if (isLessonUpdated) {
      onClose();
    } else {
      const errorStatus = errors?.status?.[0];
      const error = errorStatus && t(`ModalAttendance:${errorStatus}`);

      store.addError(error || t("ModalAttendance:apiError"));
    }
  };

  return (
    <>
      <ModalAttendance
        isOpen
        onClose={onClose}
        onSubmit={onSubmit}
        unitId={group?.name ?? ""}
        date={startAtLocal}
        isTeacher={isTeacher}
        allStatuses={statuses ?? []}
        status={currentStatus}
        duration={durationInMinutes}
        students={students}
        comment={comment ?? ""}
        actualUnitId={String(actualUnitId)}
        editable={editable}
        restrictiveDate={restrictiveDate}
        courseId={lesson?.course.id}
      />
    </>
  );
});
