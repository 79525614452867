import { HeadUserMenuContainer } from "./styledComponents/HeadUserMenuContainer";
import { Name } from "./styledComponents/Name";
import { RightContainer } from "./styledComponents/RightContainer";
import { Role } from "./styledComponents/Role";

export interface HeadUserMenuProps {
  name: string;
  userRole: string;
  handleClose: () => void;
}

export const HeadUserMenu = ({
  userRole,
  name,
}: HeadUserMenuProps): JSX.Element => {
  return (
    <HeadUserMenuContainer>
      <RightContainer>
        <Name variant="h2">{name}</Name>
        <Role variant="h4">{userRole}</Role>
      </RightContainer>
    </HeadUserMenuContainer>
  );
};
