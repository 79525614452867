import { Question } from "@packages/store/models/Question/Question";
import { ObserverFlexList } from "../../../../components/FlexList";
import { observer } from "mobx-react-lite";
import { MistakesQuestion } from "../../../../components/MistakesQuestion";
import { useMistakesExercise } from "../../../../hooks/useMistakesExercise";
import { ExerciseComponentProps } from "../../../../types";
import { ensure } from "../../../../helpers/ensure";

export const Mistakes = observer(
  ({ exercise }: ExerciseComponentProps): JSX.Element | null => {
    const { answer, questions, isChecked, getCorrectAnswer } = exercise;

    useMistakesExercise(exercise);

    if (!answer || answer.isEmpty) {
      return null;
    }

    const { getQuestion } = answer;

    return (
      <ObserverFlexList
        gap={isChecked ? "20px" : 4}
        items={questions}
        renderItem={(question: Question, index, array: Question[]) => (
          <MistakesQuestion
            key={question.id}
            question={question}
            answerData={ensure(getQuestion(question.id))}
            correctAnswer={getCorrectAnswer(question.id)}
            {...(array.length > 1 && {
              order: index + 1,
            })}
          />
        )}
      />
    );
  }
);
