import { useState, useRef, useEffect, useCallback } from "react";

import { CancelToken as CancelTokenGenerator } from "apisauce";
import { CancelToken, CancelTokenSource } from "axios";

import { useStores } from "@packages/store/models";

export type FetchCallback = (token?: CancelToken) => Promise<any>;

// TODO (bryzZz) На jetclass нужно тоже использовать этот хук
export const useCancelableFetch = () => {
  const { fetchApi } = useStores();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const source = useRef<CancelTokenSource | null>(null);

  useEffect(() => {
    return () => source.current?.cancel();
  }, []);

  const fetch = useCallback(
    async (callback: FetchCallback) => {
      source.current?.cancel();

      source.current = CancelTokenGenerator.source();

      const { token } = source.current;

      // Через таймаут чтобы работало с отмененными запросами
      setTimeout(() => {
        setLoading(true);
        setError(null);
      });

      try {
        const response = await fetchApi(callback, true, token);

        if (!response) {
          throw new Error("loading error");
        }

        return response;
      } catch (err) {
        setError(err);

        return null;
      } finally {
        setLoading(false);
      }
    },
    [fetchApi]
  );

  return { fetch, loading, error, source: source.current } as const;
};
