import { FocusEvent, forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import cn from "classnames";

import { BasicInput, BasicInputProps } from "../../BasicInput";
import { useDropdown } from "../../../hooks";

import { SearchSuggestionItem } from "./SearchSuggestionItem";

import styles from "./SearchInput.module.scss";

export type SearchInputProps = {
  type: "search";
  suggestions?: string[];
  value?: string;
  onValueChange?: (value: string) => void;
} & BasicInputProps;

export const SearchInput = observer(
  forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(props, ref) {
    const { value, suggestions = [], onValueChange, ...otherProps } = props;
  
    const {
      containerRef: dropdownContainerRef,
      open: openDropdown,
      close: closeDropdown,
      isOpen: isDropdownOpen,
      styles: dropdownStyles,
    } = useDropdown<HTMLDivElement>();
  
    const inputClasses = cn({
      [styles["focused"]]: isDropdownOpen,
    });

    const handleClear = useCallback(() => {
      onValueChange?.("");
    }, [onValueChange]);
  
    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement>) => {
        const { target } = event;
        if (dropdownContainerRef.current?.contains(target)) {
          return;
        }
        closeDropdown();
      },
      [dropdownContainerRef, closeDropdown]
    );
  
    const handleSuggestionSelect = useCallback(
      (text: string) => {
        onValueChange?.(text);
        closeDropdown();
      },
      [closeDropdown, onValueChange]
    );
  
    const SuggestionList = useMemo(() => {
      return suggestions.map((item) => (
        <SearchSuggestionItem
          key={item}
          text={item}
          onClick={handleSuggestionSelect}
        />
      ));
    }, [suggestions, handleSuggestionSelect]);
  
    return (
      <div ref={dropdownContainerRef}>
        <BasicInput
          ref={ref}
          containerClassName={inputClasses}
          leftIcon="input-search"
          onFocus={openDropdown}
          onBlur={handleBlur}
          onClick={openDropdown}
          onRightIconClick={handleClear}
          value={value}
          {...otherProps}
        />
        {isDropdownOpen && (
          <div className={styles["suggestion-list"]} style={dropdownStyles}>
            {SuggestionList}
          </div>
        )}
      </div>
    );
  })
);
