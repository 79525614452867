import React, { ReactNode, useContext, useMemo } from 'react';

import { observer } from 'mobx-react';

import styles from "./Answer.module.scss";

import { AnswerWord } from './AnswerWord';
import { ErrorContextMenu } from '../ErrorContextMenu';
import { ErrorCreateMenu } from '../ErrorCreateMenu';

import { getHoveredWordIndexes, getWordColor, getWordHightlight, isInRange, isWordContextVisible } from '../utils/helpers';
import { HoverContext, TaskContext, ErrorCreateContext, CorrectionCreateContext } from '../utils/context';
import { HoverableWord } from '../HoverableWord';

interface AnswerTextContentProps {
  startIndex?: number;
  words: string[];
  renderWord: (_word: string, index: number) => ReactNode
}

export const AnswerTextContent = observer(function AnswerTextContent(props: AnswerTextContentProps) {
	const {
    startIndex = 0,
    words,
    renderWord
  } = props;

  const {
    errors = [],
  } = useContext(TaskContext);

  const {
    hoveredWordIndex,
    hoveredError,
  } = useContext(HoverContext);

  const {
    newErrorRange,
    newErrorType
  } = useContext(ErrorCreateContext)

  const {
    editableCorrection
  } = useContext(CorrectionCreateContext);

  const currentHoveredError = useMemo(() => {
    return errors.find((error) => error === hoveredError)
  }, [errors, hoveredError]);

  const hoveredWordIndexes: number[] = useMemo(() => {
    return getHoveredWordIndexes(hoveredWordIndex ?? 0, errors, currentHoveredError);
  }, [hoveredWordIndex, currentHoveredError, errors]);

  const visibleErrors = useMemo(() => errors.filter((error) => {
    return hoveredWordIndexes.some((index) => isInRange(error, index));
  }), [errors, hoveredWordIndexes]);

  const WordItems = useMemo(() => words.map((word, index) => {
    const currentIndex = index + startIndex
    const wordIndex = hoveredWordIndex ?? 0;

    const isEditableMode = newErrorRange || editableCorrection;

    const isHovered = hoveredWordIndexes.includes(currentIndex) && wordIndex >= 0 && !isEditableMode;

    const isContextVisible = (
      isWordContextVisible(currentIndex, wordIndex, hoveredWordIndexes, currentHoveredError) && !isEditableMode
    );

    const isErrorCreateVisible = newErrorRange && newErrorRange.startPosition === currentIndex;

    const highlight = getWordHightlight(currentIndex, currentHoveredError, newErrorRange, newErrorType);

    const color = getWordColor(currentIndex, errors);
    
    return (
      <HoverableWord
        index={currentIndex}
        key={`${word}_${currentIndex}`}
        className={styles['word-container']}
      >
        <AnswerWord
          highlight={highlight}
          hovered={isHovered}
          color={color}
        >
          {renderWord(word, currentIndex)}
        </AnswerWord>
        {isContextVisible && (
          <ErrorContextMenu
            errors={visibleErrors}
            words={words}
            startIndex={startIndex}
          />
        )}
        {isErrorCreateVisible && (
          <ErrorCreateMenu />
        )}
      </HoverableWord>
    )
  }), [
    startIndex,
    words,
    errors,
    hoveredWordIndex,
    hoveredWordIndexes,
    visibleErrors,
    newErrorRange,
    newErrorType,
    currentHoveredError,
    editableCorrection,
    renderWord
  ]);

  return <>{WordItems}</>;
})
