import { LearningGroup } from "@packages/store/models/LearningGroups/LearningGroupModel";
import { LearningGroupsApproveStore } from "@packages/store/models/LearningGroupsApproves/LearningGroupsApproveStore";

export const filterByApprove = (
  group: LearningGroup,
  learningGroupApprove: LearningGroupsApproveStore
) => {
  return !learningGroupApprove.isApproved(group.id);
};

export const filterByClassGroups = (group: LearningGroup) => {
  try {
    return !!group.relatedClassGroups.length;
  } catch (e) {
    return false;
  }
};

export const filterByTeacher = (group: LearningGroup) => {
  return !!group.currentTeacher;
};

export const filterByGroupName = (name: string) => (group: LearningGroup) => {
  if (!group.name) return false;

  return group.name.trim().toLowerCase().includes(name.trim().toLowerCase());
};
