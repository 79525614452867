import { InputAdornment, InputProps } from "@mui/material";
import {
  FieldValues,
  useController,
  UseControllerProps,
} from "react-hook-form";

import { Button } from "../Button";
import { Icon } from "../Icon";
import { Search } from "../Search";

interface SearchFilterProps<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {
  inputProps: InputProps;
}

export const SearchFilter = <TFieldValues extends FieldValues>({
  inputProps,
  ...props
}: SearchFilterProps<TFieldValues>): JSX.Element => {
  const { field } = useController(props);

  return (
    <Search
      {...inputProps}
      {...field}
      sx={{
        // eslint-disable-next-line max-len
        input: {
          padding: "0.95rem 0",
        },
        "&.MuiInput-root:hover > .MuiInputAdornment-positionEnd > button, &.MuiInput-root.Mui-focused > .MuiInputAdornment-positionEnd > button":
          field.value !== ""
            ? {
                visibility: "visible",
              }
            : {},
      }}
      endAdornment={
        <InputAdornment position="end">
          <Button
            className="SearchInputClear"
            variant="iconBtn"
            sx={{
              visibility: "hidden",
              width: 24,
              height: 24,
            }}
            onClick={() => field.onChange("")}
          >
            <Icon type="close" width={20} height={20} />
          </Button>
        </InputAdornment>
      }
    />
  );
};
