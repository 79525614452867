import { FormControlLabel } from "@mui/material";
import { Control, Controller } from "react-hook-form";

import { Checkbox } from "components/Checkbox";

import { ModalTeacherLangFormValues } from "../ModalTeacherLang";

export interface NativeSpeakerCheckboxProps {
  control: Control<ModalTeacherLangFormValues>;
  placeholder: string;
}

export const NativeSpeakerCheckbox = ({
  control,
  placeholder,
}: NativeSpeakerCheckboxProps): JSX.Element | null => {
  return (
    <Controller
      control={control}
      name="isNativeSpeaker"
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          label={placeholder}
          control={<Checkbox checked={value} onChange={onChange} />}
        />
      )}
    />
  );
};
