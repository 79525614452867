import { Instance, SnapshotOut, types } from "mobx-state-tree";

export const PossibleAnswerModel = types.model("PossibleAnswer", {
  id: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
});

type PossibleAnswerModelType = Instance<typeof PossibleAnswerModel>;
type PossibleAnswerModelSnapshotType = SnapshotOut<typeof PossibleAnswerModel>;

export type PossibleAnswer = PossibleAnswerModelType;
export type PossibleAnswerSnapshot = PossibleAnswerModelSnapshotType;
