import { types, SnapshotOut, Instance } from "mobx-state-tree";

export const ExerciseGroupsModel = types.model({
  id: types.identifier,
  code: types.maybeNull(types.string),
  uuid: types.maybeNull(types.string),
});

type ExerciseGroupsModelType = Instance<typeof ExerciseGroupsModel>;
export type ExerciseGroupsModel = ExerciseGroupsModelType;
type ExerciseGroupsModelTypeSnapshotType = SnapshotOut<
  typeof ExerciseGroupsModel
>;
export type ExerciseGroupsModelSnapshot = ExerciseGroupsModelTypeSnapshotType;
