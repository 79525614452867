import { useTheme } from "@mui/material";
import { observer } from "mobx-react-lite";

import { Icon } from "../../components/Icon";
import { useTranslateMode } from "../../hooks/useTranslateMode";

import { StyledTranslationButton } from "./styledComponents/StyledTranslationButton";

export const TranslationButton = observer((): JSX.Element | null => {
  const theme = useTheme();

  const { translateMode, toggleTranslateMode } = useTranslateMode();

  return (
    <StyledTranslationButton
      active={translateMode}
      onClick={toggleTranslateMode}
    >
      <Icon
        type="words"
        sizeSquareIcon={16}
        color={
          translateMode
            ? theme.palette.info.contrastText
            : theme.palette.info.main
        }
      />
    </StyledTranslationButton>
  );
});
