import { ExerciseResultCorrection } from "@packages/store/models/ExerciseResult/ExerciseResultCorrection";
import { ExerciseResultError } from "@packages/store/models/ExerciseResult/ExerciseResultError";

export function isVisibleErrors(errors: ExerciseResultError[], editedWords: ExerciseResultCorrection[]): ExerciseResultError[] {
  return errors.filter(({ startPosition, endPosition }) => {
    const isFinded = !editedWords.find((word) => {
      return startPosition >= word.startPosition && endPosition <= word.endPosition;
    });
    return isFinded 
  })
}
