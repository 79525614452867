import { Box, BoxProps, styled } from "@mui/material";

interface LoaderWrapperProps extends BoxProps {
  cover?: boolean;
}

export const LoaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "cover",
})<LoaderWrapperProps>(({ theme, cover }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  padding: theme.spacing(3),

  ...(cover && {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  }),
}));
