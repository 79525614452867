import { Typography } from "@mui/material";
import { Exercise } from "@packages/store/models/Exercise/Exercise";
import { observer } from "mobx-react-lite";
import { useExerciseHelperText } from "../../hooks/useExerciseHelperText";

interface HelperTextProps {
  exercise: Exercise;
}

export const HelperText = observer(
  ({ exercise }: HelperTextProps): JSX.Element | null => {
    const helperText = useExerciseHelperText(exercise);

    if (helperText) {
      return <Typography variant="smallText">{helperText}</Typography>;
    }

    return null;
  }
);
