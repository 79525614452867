import { styled, TextFieldProps } from "@mui/material";

import { PasswordField as PasswordFieldBase } from "components/PasswordField";

export const PasswordField = styled(PasswordFieldBase)<TextFieldProps>(
  ({ theme }) => ({
    display: "flex",
    flex: 1,
    borderBottom: "none",
    ".MuiInputBase-root": {
      borderBottom: `0.063rem solid ${theme.palette.custom?.grey?.light}`,
    },
  })
);
